import React from 'react'
import { beautifulDate } from '../../../../services/helpers/dates'

const BodyJob = ({ element }) => {
  return (
    <>
      {/* profession */}
      <td className="tbody__tr__td">
        {!element.profession ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.profession
        )}
      </td>
      {/* familleCSP */}
      <td className="tbody__tr__td">
        {!element.familleCSP ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.familleCSP
        )}
      </td>
      {/* codeRubriqueCSP */}
      <td className="tbody__tr__td">
        {element.codeRubriqueCSP === '' || !element.codeRubriqueCSP ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.codeRubriqueCSP
        )}
      </td>
      {/* status */}
      <td className="tbody__tr__td">
        {!element.status ? <p className="informations__line__notxt">-</p> : element.status}
      </td>
      {/* dateDebut */}
      <td className="tbody__tr__td" data-label="Date début">
        {!element.dateDebut ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.dateDebut)
        )}
      </td>
      {/* dateFin */}
      <td className="tbody__tr__td" data-label="Date fin">
        {element.dateFin ? beautifulDate(element.dateFin) : 'en cours'}
      </td>
    </>
  )
}

export default BodyJob
