import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getFormIsActive } from '../../../../../redux/actions/actionFormIsActive'
import { postFinancier } from '../../../../../services/API/financier'
import { postSocial } from '../../../../../services/API/social'
import { beautifulDateNumbers } from '../../../../../services/helpers/dates'
import BlocTable from '../../../table/BlocTable'
import NotCorrect from '../../../Components/Modales/NotCorrect'
import FormTransactionInputs from './Form/FormTransactionInputs'
import { CATEGORIE, PRODUIT_TRANSACTIONS } from '../../../../../constants'
import TableBodyTransactions from './Table/TableBodyTransactions'
import usePersonData from '../../../../layout/usePersonData'

const TransactionsComponent = ({ categorie }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const financier = useSelector((state) => state.getFinancier.financier)
  const social = useSelector((state) => state.getSocial.social)
  const bien = useSelector((state) => state.getBien.bien)
  const bienToDisplay = useMemo(
    () =>
      categorie === CATEGORIE.vm
        ? financier.produitsVM.filter((el) => el.id === bien.id)[0]
        : social.produitsPER.filter((el) => el.id === bien.id)[0],
    [financier, social, bien]
  )
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddNotCorrect, setToggleNotCorrect] = useState(false)
  // toggle
  const [toggleAddTransaction, setToggleAddTransaction] = useState(false)
  //redirection
  useEffect(() => {
    !bien &&
      navigate(
        `/user/patrimoine/actifs/${
          categorie === CATEGORIE.per ? 'social/PER' : 'financier/valeurs-mobilieres'
        }`
      )
  }, [bien])
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    libelle: '',
    fournisseur: '',
    idCatalogue: '',
    dateOuverture: '',
    transactions: [],
    operations: [],
    montantTotalCalcule: 0,
    portefeuille: {},
    liquidite: 0,
    detenteurs: [],
    fichiers: [],
    historiquesFrais: [],
  })
  const {
    id,
    libelle,
    fournisseur,
    idCatalogue,
    dateOuverture,
    transactions,
    operations,
    portefeuille,
    montantTotalCalcule,
    liquidite,
    detenteurs,
    fichiers,
    historiquesFrais,
  } = information || {}
  // maj information
  useEffect(() => {
    bienToDisplay
      ? setInformation({
          ...information,
          id: bienToDisplay.id,
          libelle: bienToDisplay.libelle,
          fournisseur: bienToDisplay.fournisseur ? bienToDisplay.fournisseur : null,
          idCatalogue: bienToDisplay.idCatalogue,
          dateOuverture: bienToDisplay.dateOuverture ? bienToDisplay.dateOuverture : null,
          transactions: bienToDisplay.transactions,
          operations: bienToDisplay.operations,
          montantTotalCalcule: bienToDisplay.montantTotalCalcule,
          portefeuille: bienToDisplay.portefeuille,
          liquidite: bienToDisplay.liquidite,
          detenteurs: bienToDisplay.detenteurs,
          fichiers: bienToDisplay.fichiers,
          historiquesFrais: bienToDisplay.historiquesFrais ? bienToDisplay.historiquesFrais : [],
        })
      : setInformation(information)
  }, [bienToDisplay])
  // transaction
  const initialTransaction = {
    id: 0,
    dateTransaction: beautifulDateNumbers(new Date()),
    montant: 0,
    code: 999999,
  }
  const [newTransaction, setNewTransaction] = useState(initialTransaction)
  const { montant, code } = newTransaction || {}
  // form Bien financier is active
  useEffect(() => {
    if (dataChange) {
      dispatch(
        getFormIsActive({
          isActive: true,
          form: categorie === CATEGORIE.per ? 'TransactionPER' : 'TransactionVM',
        })
      )
    }
  }, [dataChange])
  // save Bien immo
  useEffect(() => {
    if (
      saveBien &&
      formIsActive.form === (categorie === CATEGORIE.per ? 'TransactionPER' : 'TransactionVM')
    ) {
      handlePreSubmit()
    }
  }, [saveBien])
  // preSubmit
  const handlePreSubmit = () => {
    if (!!montant) {
      handleSubmit()
    } else {
      setToggleNotCorrect(true)
    }
  }
  // data
  const data = {
    id: id,
    libelle: libelle,
    fournisseur: fournisseur,
    idCatalogue: idCatalogue,
    dateOuverture: dateOuverture,
    transactions: transactions.concat({
      ...newTransaction,
      montant: code === 999999 ? Number(montant) : Number(-montant),
    }),
    operations: operations,
    montantTotalCalcule:
      montantTotalCalcule + (code === 999999 ? Number(montant) : Number(-montant)),
    portefeuille: portefeuille,
    liquidite: liquidite + (code === 999999 ? Number(montant) : Number(-montant)),
    detenteurs: detenteurs,
    fichiers: fichiers,
    historiquesFrais: historiquesFrais,
  }
  // submit
  const handleSubmit = () => {
    categorie === CATEGORIE.per
      ? dispatch(postSocial('per', person.id, isPP, true, data, setStatus))
      : dispatch(postFinancier('produitFinancierVM', person.id, isPP, false, data, setStatus))
    setNewTransaction(initialTransaction)
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status) {
      if (status < 400) {
        toast.success('Nouvelle transaction enregistrée')
      }
      setStatus(null)
    }
  }, [status])
  // redirection
  useEffect(() => {
    if (bien === null) {
      navigate('/user/patrimoine/actifs/financier')
    }
  }, [bien])
  const calculLiquidites = (bien) => {
    return bien.transactions
      .map((transaction) => parseFloat(transaction.montant))
      .reduce((acc, curr) => acc + curr)
  }
  const titles = ['N°', 'Nature', 'Montant de la transaction', 'Date de la transaction']

  return (
    <>
      <div className="informations">
        {bienToDisplay?.transactions.length > 0 ? (
          <>
            <div className="customHead">
              <div className="customHead--values">
                <p>
                  Disponibilités :{' '}
                  <span>{` ${bienToDisplay.liquidite.toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} €`}</span>
                </p>
                <p>
                  Total des transactions :{' '}
                  <span>{` ${calculLiquidites(bienToDisplay).toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} €`}</span>
                </p>
              </div>
            </div>
            <BlocTable
              data={bienToDisplay.transactions}
              titles={titles}
              tableType={PRODUIT_TRANSACTIONS}
              children={<TableBodyTransactions />}
              title={'Transactions'}
              handleAdd={() => setToggleAddTransaction(true)}
            />
          </>
        ) : (
          <div className="informations__buttons">
            <button
              className="button button--red-red"
              onClick={() => setToggleAddTransaction(true)}
            >
              Effectuer une remise de fonds
            </button>
          </div>
        )}
        {toggleAddTransaction ? (
          <FormTransactionInputs
            setDataChange={setDataChange}
            setToggleAddTransaction={setToggleAddTransaction}
            disponibilite={liquidite}
            initialTransaction={initialTransaction}
            newTransaction={newTransaction}
            setNewTransaction={setNewTransaction}
            handlePreSubmit={handlePreSubmit}
            first={bienToDisplay && bienToDisplay.transactions.length === 0}
            information={information}
          />
        ) : null}
      </div>
      {/* modales */}
      {toggleAddNotCorrect && <NotCorrect setNotCorrect={setToggleNotCorrect} />}
    </>
  )
}

export default TransactionsComponent
