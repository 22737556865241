import React from 'react'
import Card from '../../../Components/Card'
import BlocTable from '../../../table/BlocTable'
import { ACTIFS_AUTRES } from '../../../../../constants'
import TableBodyBienAutre from './Table/TableBodyBienAutre'

const PatrimoineAutresCard = ({ data, handleAddAutre, title }) => {
  const titles = ['N°', 'Nom', 'Valeur', 'Voir', 'Modifier', 'Supprimer', 'Dupliquer']
  return (
    <Card title={title} h3={true} open={true}>
      <div className="informations">
        <BlocTable
          data={data}
          titles={titles}
          tableType={ACTIFS_AUTRES}
          children={<TableBodyBienAutre />}
          title={title}
          handleAdd={handleAddAutre}
        />
      </div>
    </Card>
  )
}

export default PatrimoineAutresCard
