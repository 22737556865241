import React from 'react'
import {
  getLibelleProduitFinancier,
  stringToCapitalize,
} from '../../../../../../../services/helpers/strings'
import { beautifulDate } from '../../../../../../../services/helpers/dates'

const BodyPF = ({ element, handleShow }) => {
  return (
    <>
      {/* libelle */}
      <td className="tbody__tr__td tbody__tr__td--pointer bold" onClick={() => handleShow(element)}>
        {!element.libelle ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          getLibelleProduitFinancier(element.libelle)
        )}
      </td>
      {/* descriptif */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.descriptif ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          stringToCapitalize(element.descriptif)
        )}
      </td>
      {/* Numéro */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.numero ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.numero.toUpperCase()
        )}
      </td>
      {/* etablissement */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.etablissement ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          stringToCapitalize(element.etablissement)
        )}
      </td>
      {/* solde */}
      {!element.clos ? (
        <>
          <td
            className="tbody__tr__td tbody__tr__td--pointer bold"
            onClick={() => handleShow(element)}
          >
            {!element.solde ? (
              <p className="informations__line__notxt">-</p>
            ) : (
              `${element.solde.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
            )}
          </td>
          {/* dateSolde */}
          <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
            {!element.dateSolde ? (
              <p className="informations__line__notxt">-</p>
            ) : (
              beautifulDate(element.dateSolde)
            )}
          </td>
        </>
      ) : (
        <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
          {!element.dateCloture ? (
            <p className="informations__line__notxt">-</p>
          ) : (
            beautifulDate(element.dateCloture)
          )}
        </td>
      )}
    </>
  )
}

export default BodyPF
