import React from 'react'
import InputsIdentity from './InputsIdentity'

const Identity = ({
  person,
  personInfos,
  setPersonInfos,
  fichiers,
  setDataChange,
  document,
}) => {
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      setPersonInfos({ ...personInfos, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      setPersonInfos({ ...personInfos, [name]: value })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setPersonInfos({ ...personInfos, [name]: date })
  }

  return (
    <InputsIdentity
      person={person}
      handleChange={handleChange}
      handleChangeDate={handleChangeDate}
      personInfos={personInfos}
      fichiers={fichiers}
      setDataChange={setDataChange}
      document={document}
    />
  )
}

export default Identity
