import React, { useEffect, useId } from 'react'
import ModaleSystem from './ModaleSystem'
import ModaleComponent from './ModaleComponent'
import { useDispatch } from 'react-redux'
import { addModal, removeModal } from '../../../redux/actions/actionModals'

const Modale = ({ close, sign, calendar, title, line1, line2, line3, line4, children }) => {
  const modalId = useId()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(addModal(modalId))
    return () => {
      dispatch(removeModal(modalId))
    }
  }, [modalId])

  return (
    <ModaleSystem close={close} modalId={modalId}>
      <ModaleComponent
        close={close}
        sign={sign}
        calendar={calendar}
        title={title}
        line1={line1}
        line2={line2}
        line3={line3}
        line4={line4}
        children={children}
      />
    </ModaleSystem>
  )
}

export default Modale
