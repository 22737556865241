import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
// import { useDispatch, useSelector } from 'react-redux'

const ModaleSystem = ({ close, modalId, children }) => {
  const modals = useSelector((state) => state.getModals.modals)
  const isLastModal = modals[modals.length - 1] === modalId
  // isSelectionInsideClass
  function isSelectionInsideClass(className) {
    let node = window.getSelection().anchorNode;
    while (node && node !== document.body) {
      if (node.nodeType === 1 && node.classList.contains(className)) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }
  // handleOutsideClick
  const handleOutsideClick = (e) => {
    if (isLastModal && e.target.classList.contains('modal-overlay')) {
      if (!isSelectionInsideClass('input__inputBloc__div')) {
        // Aucune sélection de texte, fermez le modal
        close()
      }
    }
  }
  // Gestionnaire d'événements pour détecter la touche Escape
  const handleKeyDown = (event) => {
    if (isLastModal && event.key === 'Escape') {
      close()
    }
  }
  // event listener click outside
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [modals, isLastModal])

  return createPortal(<div id={modalId}>{children}</div>, document.getElementById('modal'))
}

export default ModaleSystem
