// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
import {
    getSignaturesToClassifyLoad,
    getSignaturesToClassifySuccess,
    getSignaturesToClassifyError,
} from '../actions/actionGetSignaturesToClassify'
// actions imports

// REDUCER // ______________________________________________________________

// initial state
const initialStateGetSignaturesToclassify = {
  isLoading: false,
  signaturesToClassify: null,
  error: '',
}
// reducer
export const getSignaturesToClassifyReducer = createReducer(
  initialStateGetSignaturesToclassify,
  (builder) => {
    return builder
      .addCase(getSignaturesToClassifyLoad, (draft) => {
        draft.isLoading = true
        return
      })
      .addCase(getSignaturesToClassifySuccess, (draft, action) => {
        draft.isLoading = false
        draft.signaturesToClassify = action.payload
        draft.error = ''
        return
      })
      .addCase(getSignaturesToClassifyError, (draft, action) => {
        draft.isLoading = false
        draft.signaturesToClassify = null
        draft.error = action.payload
        return
      })
  }
)
