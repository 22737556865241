import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const Toast = ({ toastId, position, duration }) => {
  return (
    <>
      <ToastContainer
        position={position}
        autoClose={duration}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        componentRef={toastId}
      />
    </>
  )
}

export default Toast
