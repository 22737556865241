import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import SaveForm from '../../../layout/SaveForm'
import useFormIsActive from '../../../layout/useFormIsActive'

const NavPatrimoine = () => {
  // path
  const { pathname } = useLocation()
  // formIsActive
  const formIsActive = useFormIsActive()
  // modales
  const [toggleSaveForm, setToggleSaveForm] = useState(false)
  // handleClick
  const handleClick = () => {
    formIsActive && setToggleSaveForm(true)
  }
  // path match
  const handleMatch = (string) => {
    return pathname.includes(string) ? true : false
  }

  return (
    <>
      <nav className="secondNav" onClick={() => handleClick()}>
        <ul className="secondNav__menu">
          <li className="secondNav__menu__bloc">
            <ul>
              <li className="secondNav__menu__item">
                {formIsActive ? (
                  <div className={'secondNav__link button--grey'}>
                    {handleMatch('actifs') ? <h2>Actifs</h2> : <p>Actifs</p>}
                  </div>
                ) : (
                  <NavLink
                    to={'/user/patrimoine/actifs'}
                    className={'secondNav__link secondNav__link--actif'}
                  >
                    {({ isActive }) => <>{isActive ? <h2>Actifs</h2> : <p>Actifs</p>}</>}
                  </NavLink>
                )}
              </li>
              <li className="secondNav__menu__item">
                {formIsActive ? (
                  <div className={'secondNav__link button--grey'}>
                    {handleMatch('passifs') ? <h2>Passifs</h2> : <p>Passifs</p>}
                  </div>
                ) : (
                  <NavLink
                    to={'/user/patrimoine/passifs'}
                    className={'secondNav__link secondNav__link--passif'}
                  >
                    {({ isActive }) => <>{isActive ? <h2>Passifs</h2> : <p>Passifs</p>}</>}
                  </NavLink>
                )}
              </li>
            </ul>
          </li>
          <li className="secondNav__menu__bloc">
            <ul>
              <li className="secondNav__menu__item">
                {formIsActive ? (
                  <div className={'secondNav__link button--grey'}>
                    {handleMatch('revenus') ? <h2>Revenus</h2> : <p>Revenus</p>}
                  </div>
                ) : (
                  <NavLink
                    to={'/user/patrimoine/revenus'}
                    className={'secondNav__link secondNav__link--revenu'}
                  >
                    {({ isActive }) => <>{isActive ? <h2>Revenus</h2> : <p>Revenus</p>}</>}
                  </NavLink>
                )}
              </li>
              <li className="secondNav__menu__item">
                {formIsActive ? (
                  <div className={'secondNav__link button--grey'}>
                    {handleMatch('charges') ? <h2>Charges</h2> : <p>Charges</p>}
                  </div>
                ) : (
                  <NavLink
                    to={'/user/patrimoine/charges'}
                    className={'secondNav__link secondNav__link--charge'}
                  >
                    {({ isActive }) => <>{isActive ? <h2>Charges</h2> : <p>Charges</p>}</>}
                  </NavLink>
                )}
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      {toggleSaveForm && (
        <SaveForm toggleSaveForm={toggleSaveForm} setToggleSaveForm={setToggleSaveForm} />
      )}
    </>
  )
}

export default NavPatrimoine
