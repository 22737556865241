import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import { getCatalogues } from '../../../../../../services/API/catalogue'
import { getFinancier } from '../../../../../../services/API/financier'
import { PRODUITS_AV } from '../../../../../../constants'
import Loader from '../../../../../layout/Loader'
import usePersonData from '../../../../../layout/usePersonData'
import useCustomPrint from '../../../../../layout/useCustomPrint'
import PrintComponent from '../../../../Components/PrintComponent'
import Card from '../../../../Components/Card'
import BlocTable from '../../../../table/BlocTable'
import ButtonsRow from '../../../Patrimoine/ButtonsRow'
import PatrimoineChartCard from '../../../Patrimoine/PatrimoineChartCard'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import TableBodyAssurances from '../../Catalogue/Table/TableBodyAssurances'
import useUpdateProductIdCatalogue from '../../Produit/useUpdateProductIdCatalogue'
import AVPieChart from './AVPieChart'
import AVToPrint from './AVToPrint'
import LittleLoader from '../../../../../layout/LittleLoader'

const AVHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const financier = useSelector((state) => state.getFinancier.financier)
  const { produitsAV } = financier || {}
  const catalogues = useSelector((state) => state.getCatalogues.catalogues)
  const [cataloguesC, setCataloguesC] = useState(null)
  useEffect(() => {
    if (!catalogues) {
      dispatch(getCatalogues())
    } else {
      setCataloguesC(catalogues.filter((catalogue) => catalogue.categorie === 'AV'))
    }
  }, [catalogues, dispatch])
  // recharge
  useEffect(() => {
    if (!financier) {
      dispatch(getFinancier(person?.id, isPP))
    }
  }, [financier, person, isPP, dispatch])
  // hook
  const { postData, updateData } = useUpdateProductIdCatalogue('AV')
  useEffect(() => {
    if (cataloguesC && produitsAV) {
      if (produitsAV.filter((el) => el.idCatalogue === 0).length > 0) {
        postData(produitsAV, cataloguesC)
        updateData(produitsAV, cataloguesC)
      }
    }
  }, [produitsAV, cataloguesC])
  // redirections
  const formLink = '/user/patrimoine/actifs/financier/assurances-vie/ajouter'
  // maj bien
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // calcul valeurs
  const valeurPortefeuilles = useMemo(
    () =>
      produitsAV.map((produit) => produit.montantTotalCalcule).reduce((acc, curr) => acc + curr, 0),
    [produitsAV]
  )
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()
  // titles
  const titles = ['N°', 'Libellé', 'Portefeuille', 'Voir', 'Modifier', 'Supprimer']

  if (!financier) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <AVToPrint elementToDisplay={produitsAV} valeurPortefeuilles={valeurPortefeuilles} />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            <PatrimoineValueCard
              totalAmount={valeurPortefeuilles}
            />
            {valeurPortefeuilles !== 0 ? (
              <PatrimoineChartCard
                children={<AVPieChart produitsAV={produitsAV} />}
              />
            ) : null}
            <Card title={`Produits (${produitsAV.length})`} h5={true} open={true}>
              <div className="informations">
                <BlocTable
                  titles={titles}
                  data={produitsAV}
                  tableType={PRODUITS_AV}
                  children={<TableBodyAssurances />}
                  title={`Produits (${produitsAV.length})`}
                  handleAdd={() => navigate(formLink)}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default AVHome
