import React from 'react'
import Thread from './Thread'

const Title = ({ title, name }) => {
  return (
    <div className="section__title">
      <div className="section__title__content">
        <h1 className="section__title__content__h1">
          {title}{' '}<br/>
          {name && <span>{name}</span>}
        </h1>
        <Thread />
      </div>
    </div>
  )
}

export default Title
