import React, { useEffect, useState } from 'react'
import Modale from '../../../Components/Modale'
import Input from '../../../Components/Input'
import SelectComponent from '../../../Components/SelectComponent'
import { optionsTypePeriodicite } from '../../../../../data/options/options'
import { useSelector } from 'react-redux'

const ModalPerformanceFrais = ({
  setModal,
  data,
  setData,
  newData,
  setDataChange,
}) => {
  const bienType = useSelector((state) => state.getBienType.type)
  const type = bienType.type

  const [newFrais, setNewFrais] = useState({})
  const {
    annee,
    tauxFraisGestion,
    periodiciteFraisGestion,
    tauxDroitGarde,
    periodiciteDroitGarde,
    tauxPerformanceFE,
  } = newFrais || {}

  const [wrongYear, setWrongYear] = useState(false)
  // maj newFrais
  useEffect(() => {
    if (newData) {
      type !== 'produitFinancierAV' && type !== 'retraite'
        ? setNewFrais({
            id: newData.id,
            annee: newData.annee,
            tauxFraisGestion: newData.tauxFraisGestion,
            tauxDroitGarde: newData.tauxDroitGarde,
            periodiciteFraisGestion: newData.periodiciteFraisGestion,
            periodiciteDroitGarde: newData.periodiciteDroitGarde,
          })
        : setNewFrais({
            id: newData.id,
            annee: newData.annee,
            tauxPerformanceFE: newData.tauxPerformanceFE,
          })
    } else {
      type !== 'produitFinancierAV' && type !== 'retraite'
        ? setNewFrais({
            id: 0,
            annee: data.find(
              (el) => el.annee === new Date().getFullYear().toString()
            )
              ? ''
              : new Date().getFullYear(),
            tauxFraisGestion: '',
            tauxDroitGarde: '',
            periodiciteFraisGestion: '',
            periodiciteDroitGarde: '',
          })
        : setNewFrais({
            id: 0,
            annee: data.find(
              (el) => el.annee === new Date().getFullYear().toString()
            )
              ? ''
              : new Date().getFullYear(),
            tauxPerformanceFE: '',
          })
    }
  }, [])
  // change
  const [change, setChange] = useState(false)
  useEffect(() => {
    if (annee && !wrongYear) {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [annee, wrongYear])
  // handleChange
  const handleChange = (e, selectName) => {
    if (e.target !== undefined) {
      const { name, value } = e.target
      if (name === 'annee') {
        setWrongYear(false)
      }
      setNewFrais({ ...newFrais, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      setNewFrais({ ...newFrais, [name]: value })
    }
  }
  // handleSave
  const handleSave = (e) => {
    setDataChange(true)
    e.preventDefault()
    e.stopPropagation()
    if (!newData) {
      if (data.find((el) => el.annee === annee)) {
        setWrongYear(true)
      } else {
        setData([...data, newFrais])
        setModal(false)
      }
    } else {
      setData([
        ...data.filter((el) => el.annee !== annee),
        newFrais,
      ])
      setModal(false)
    }
  }

  return (
    <Modale
      close={() => setModal(false)}
      title={
        type !== 'produitFinancierAV' && type !== 'retraite'
          ? 'Performance et frais'
          : 'Performance des fonds'
      }
    >
      <div className="informations ">
        {type !== 'produitFinancierAV' && type !== 'retraite' ? (
          <>
            {/* annee */}
            <Input
              forAndId="annee"
              type="number"
              name="Année de référence :"
              value={annee}
              onChange={(e) => handleChange(e)}
              copy={true}
              disabled={newData}
            />
            {wrongYear ? (
              <p className="input--error">{`⚠️ Des données sont déjà enregistrées pour l'année ${annee}`}</p>
            ) : null}
            {/* tauxFraisGestion */}
            <Input
              forAndId="tauxFraisGestion"
              type="text"
              name="Frais de gestion (%) :"
              value={tauxFraisGestion}
              onChange={(e) => handleChange(e)}
              copy={true}
            />
            {/* periodiciteFraisGestion */}
            <SelectComponent
              forAndId="periodiciteFraisGestion"
              name="Periodicité :"
              isSearchable={false}
              options={optionsTypePeriodicite}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={optionsTypePeriodicite.find((c) => c.value === periodiciteFraisGestion)}
              copy={true}
            />
            {/* tauxDroitGarde */}
            <Input
              forAndId="tauxDroitGarde"
              type="text"
              name="Droit de garde (%) :"
              value={tauxDroitGarde}
              onChange={(e) => handleChange(e)}
              copy={true}
            />
            {/* periodiciteDroitGarde */}
            <SelectComponent
              forAndId="periodiciteDroitGarde"
              name="Periodicité :"
              isSearchable={false}
              options={optionsTypePeriodicite}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={optionsTypePeriodicite.find((c) => c.value === periodiciteDroitGarde)}
              copy={true}
            />
          </>
        ) : (
          <>
            {/* annee */}
            <Input
              forAndId="annee"
              type="number"
              name="Année de référence :"
              value={annee}
              onChange={(e) => handleChange(e)}
              copy={true}
              required={true}
              disabled={newData}
            />
            {wrongYear ? (
              <p className="input--error">{`⚠️ Des données sont déjà enregistrées pour l'année ${annee}`}</p>
            ) : null}
            {/* tauxPerformanceFE */}
            <Input
              forAndId="tauxPerformanceFE"
              type="text"
              name="Performance des fonds (%) :"
              value={tauxPerformanceFE}
              onChange={(e) => handleChange(e)}
              copy={true}
            />
          </>
        )}
        {change ? (
          <div className="informations__buttons">
            <div className="button button--red-red width--12" onClick={(e) => handleSave(e)}>
              Enregistrer
            </div>
          </div>
        ) : (
          <div className="informations__buttons">
            <div className="button button--grey width--12">Enregistrer</div>
          </div>
        )}
      </div>
    </Modale>
  )
}

export default ModalPerformanceFrais
