import React from 'react'

const PatrimoineChartCardPrint = ({ children, detenteurs }) => {
  return (
    <div className="print__charts">
      <div className="informations">{React.cloneElement(children, { print: true })}</div>
      {detenteurs ? (
        <div className="informations">
          {React.cloneElement(children, { detenteurs: true, print: true })}
        </div>
      ) : null}
    </div>
  )
}

export default PatrimoineChartCardPrint
