import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalSaveForm from './ModalSaveForm'
import { getFormIsActive } from '../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../redux/actions/actionSaveForm'
import { getBienSuccess } from '../../redux/actions/Patrimoine/actionGetBien'

const SaveForm = ({ toggleSaveForm, setToggleSaveForm }) => {
  const dispatch = useDispatch()
  // recup
  const form = useSelector((state) => state.formIsActive.isActive)

  // modales
  const [modaleSave, setModaleSave] = useState(false)

  // isActive ?
  useEffect(() => {
    if (toggleSaveForm) {
      form && setModaleSave(true)
    }
  }, [toggleSaveForm])

  // quit Form
  const handleQuit = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setModaleSave(false)
    dispatch(getBienSuccess(null))
    setToggleSaveForm(false)
  }

  // save Form
  const handleSave = () => {
    setModaleSave(false)
    dispatch(getSaveForm(true))
    setToggleSaveForm(false)
  }
  return (
    <>
      {modaleSave && (
        <ModalSaveForm
          setModaleSave={setModaleSave}
          handleSave={handleSave}
          handleQuit={handleQuit}
          setToggle={setToggleSaveForm}
        />
      )}
    </>
  )
}

export default SaveForm
