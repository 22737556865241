import React from 'react'
import InformationsPPPrintData from './InformationsCardPrint'
import { dataMainInfos, dataSociety } from '../../../data/informations/infosPP'
import TableToPrint from '../table/TableToPrint'
import { useSelector } from 'react-redux'
import { PM_BENEFICIAIRES, PM_ETABLISSEMENTS } from '../../../constants'
import TableBodyBeneficiaire from '../FormPM/Table/TableBodyBeneficiaire'
import TableBodyEtablissement from '../FormPM/Table/TableBodyEtablissement'

const InformationsPMPrint = () => {
  // person
  const person = useSelector((state) => state.getPerson.person)
  // destructuring person
  const { beneficiairesEffectifs, etablissements } = person || {}

  return (
    <>
      <InformationsPPPrintData data={dataMainInfos(person)} title={'Informations principales'} />
      <InformationsPPPrintData data={dataSociety(person)} title={'Informations principales'} />
      {beneficiairesEffectifs?.length ? (
        <TableToPrint
          data={beneficiairesEffectifs}
          tableType={PM_BENEFICIAIRES}
          title="Bénéficiaires effectifs"
          children={<TableBodyBeneficiaire />}
        />
      ) : null}
      {etablissements?.length ? (
        <TableToPrint
          data={etablissements}
          tableType={PM_ETABLISSEMENTS}
          title="Établissements"
          children={<TableBodyEtablissement />}
        />
      ) : null}
    </>
  )
}

export default InformationsPMPrint
