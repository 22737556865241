import React from 'react'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'
import SelectComponent from '../../../../Components/SelectComponent'
import { optionsDetention } from '../../../../../../data/options/options'

const GeneralInformationCard = ({ handleChange, information }) => {
  const { montantRestant, refDette, detention, tauxIndivision } = information || {}

  return (
    <Card title="Informations générales" h3={true} open={true}>
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* refDette */}
          <Input
            forAndId="refDette"
            errorLabel={refDette === ''}
            type="text"
            name="Référence de la dette :"
            value={refDette}
            onChange={(e) => handleChange(e)}
            placeholder="Libellé"
            copy={true}
            required
          />
          {/* montantRestant */}
          <Input
            forAndId="montantRestant"
            errorLabel={montantRestant === '' || montantRestant === 0}
            type="number"
            name="Montant restant dû :"
            value={montantRestant}
            onChange={(e) => handleChange(e)}
            copy={true}
            required
          />
        </div>

        {/* detention */}
        <SelectComponent
          forAndId="detention"
          errorLabel={detention === ''}
          name="Détention :"
          isSearchable={false}
          options={optionsDetention}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsDetention.find((c) => c.value === detention)}
          required={true}
          copy={true}
        />
        {/* tauxIndivision */}
        <Input
          forAndId="tauxIndivision"
          type="number"
          name="Taux d'indivision :"
          value={tauxIndivision}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
      </div>
    </Card>
  )
}

export default GeneralInformationCard
