import React from 'react'
import ModaleClassify from '../Signatures/ModaleClassify'
import ModaleUpdateContact from '../Contacts/ModaleUpdateContact'
import ModalPerformanceFrais from '../Patrimoine/Actifs/Produit/ModalPerformanceFrais'
import ModaleActivity from '../FormPP/Modales/ModaleActivity'
import ModaleRelationShip from '../FormPP/Modales/ModaleRelationShip'
import ModaleBeneficiaire from '../FormPM/Modales/ModaleBeneficiaire'
import ModaleEtablissement from '../FormPM/Modales/ModaleEtablissement'
import ModaleUpdateUser from '../User/ModaleUpdateUser'
import * as infos from '../../../constants'
import ModaleFiles from '../Files/ModaleFiles'

const ModalUpdate = ({ setModalUpdate, data, setData, newData, setDataChange, tableType }) => {
  // commonProps
  const commonProps = { data, setData, newData, setDataChange, setModal: setModalUpdate, tableType }
  const handleReturn = () => {
    switch (tableType) {
      case infos.PP_ACTIVITY:
        return <ModaleActivity {...commonProps} />
      case infos.PP_RELATIONS:
        return <ModaleRelationShip {...commonProps} />
      case infos.PM_BENEFICIAIRES:
        return <ModaleBeneficiaire {...commonProps} />
      case infos.PM_ETABLISSEMENTS:
        return <ModaleEtablissement {...commonProps} />
      case infos.USER_SIGNATURES_CLASSER:
        return <ModaleClassify {...commonProps} />
      case infos.PRODUIT_FRAIS:
        return <ModalPerformanceFrais {...commonProps} />
      case infos.ADMIN_USERS:
        return <ModaleUpdateUser {...commonProps} />
      case infos.USER_CONTACTS:
        return <ModaleUpdateContact {...commonProps} />
      default:
        return <ModaleFiles {...commonProps} />
    }
  }

  return handleReturn()
}

export default ModalUpdate
