import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { USER_DIRIGEANTS, USER_REFERENTS, USER_RELATIONS } from '../../../constants'
import Card from '../Components/Card'
import BlocTable from '../table/BlocTable'
import TableBodyPP from './TableBodyPP'
import TableBodyPM from './TableBodyPM'

const PersonsCard = ({ type }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // person
  const person = useSelector((state) => state.getPerson.person)
  // maj personne
  useEffect(() => {
    if (persons && person !== persons.referentPP[0]) {
      dispatch(getPerson(persons.referentPP[0]))
      // dispatch(getPersonId(persons.referentPP[0].id))
      dispatch(postPersonType('referentPP'))
    }
  }, [])
  // type
  const [typeToDisplay, setTypeToDisplay] = useState([])
  const [tableType, setTableType] = useState([])
  const [title, setTitle] = useState('')
  const [titles, setTitles] = useState([])
  const [children, setChildren] = useState(null)
  useEffect(() => {
    if (persons) {
      switch (type) {
        case 'referentPP':
          setTypeToDisplay(persons.referentPP)
          setTableType(USER_REFERENTS)
          setTitle('Référents')
          setTitles(['N°', 'Nom', 'Prénom', 'Mail', 'Lien', 'Voir', 'Mettre à jour', 'Supprimer'])
          setChildren(<TableBodyPP />)
          break
        case 'relationPP':
          setTypeToDisplay(persons.relationPP)
          setTableType(USER_RELATIONS)
          setTitle('Relations')
          setTitles(['N°', 'Nom', 'Prénom', 'Mail', 'Lien', 'Voir', 'Supprimer'])
          setChildren(<TableBodyPP />)
          break
        case 'dirigeantPM':
          setTypeToDisplay(persons.dirigeantPM)
          setTableType(USER_DIRIGEANTS)
          setTitle('Dirigeants')
          setTitles([
            'N°',
            'Dénomination sociale',
            'Forme juridique',
            'Dirigeant',
            'Voir',
            'Mettre à jour',
          ])
          setChildren(<TableBodyPM />)
          break
        default:
          return
      }
    }
  }, [type, persons])
  // handleClickAdd
  const handleClickAdd = (str) => {
    // dispatch(getPersonId(null))
    dispatch(getPerson(null))
    dispatch(postPersonType(null))
    navigate(`/user/comptes/ajouter-${str}`)
  }

  return persons ? (
    <>
      <Card title={`${title} (${typeToDisplay.length})`} open={true} h2={true}>
        <div className="informations">
          <BlocTable
            titles={titles}
            data={typeToDisplay}
            tableType={tableType}
            children={children}
            title={title}
            handleAdd={() =>
              handleClickAdd(type !== 'dirigeantPM' ? 'Personne-Physique' : 'Personne-Morale')
            }
          />
        </div>
      </Card>
    </>
  ) : null
}

export default PersonsCard
