import React, { useState } from 'react'
import Copy from './Copy'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import Icon from './Icon'
import SearchButton from './SearchButton'

const Input = ({
  sign,
  forAndId,
  name,
  placeholder,
  type,
  value,
  onChange,
  required,
  disabled,
  errorLabel,
  errorInput,
  message,
  children,
  pattern,
  min,
  max,
  rows,
  cols,
  maxLength,
  minLength,
  accept,
  multiple,
  file,
  bold,
  copy,
  noLabel,
  check,
  setValue,
  table,
  step,
  icon,
  inputRef,
  inputMode,
  search,
  action,
  defaultValue,
  locale,
  onInput
}) => {
  // visible
  const [visibility, setVisibility] = useState(false)
  return (
    <div
      className={
        sign
          ? 'input--sign'
          : noLabel
          ? 'input input--noLabel'
          : check
          ? 'input input--check'
          : 'input'
      }
    >
      <label
        htmlFor={forAndId}
        className={
          sign
            ? 'input__label--sign'
            : errorLabel
            ? 'input__label input__label--error'
            : file
            ? 'input__label input__label--file'
            : bold
            ? 'input__label input__label--bold'
            : noLabel
            ? 'sr-only'
            : 'input__label'
        }
      >
        {name}
      </label>
      {check ? (
        <div className="input__inputBloc input__inputBloc__checkbox">
          {value ? (
            <div onClick={() => setValue(!value)} className="input__inputBloc__checkbox--checked">
            <MdRadioButtonChecked />
            </div>
          ) : (
            <div onClick={() => setValue(!value)} className="input__inputBloc__checkbox--nochecked">
            <MdRadioButtonUnchecked />
            </div>
          )}
        </div>
      ) : (
        <div className="input__inputBloc">
          <div
            className={
              icon || copy || search
                ? 'input__inputBloc__div input__inputBloc__div--icon'
                : 'input__inputBloc__div'
            }
          >
            <input
              type={visibility ? 'text' : type}
              name={forAndId}
              id={forAndId}
              className={
                sign
                  ? 'input__inputBloc__input input__inputBloc__input--sign'
                  : errorInput
                  ? 'input__inputBloc__input outline'
                  : file
                  ? 'input__inputBloc__input input__inputBloc__input--file'
                  : disabled
                  ? 'input__inputBloc__input input__inputBloc__input--disabled'
                  : table
                  ? 'input__inputBloc__input input__inputBloc__input--table'
                  : 'input__inputBloc__input'
              }
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onInput={onInput}
              required={required ? true : false}
              disabled={disabled ? true : false}
              pattern={pattern}
              min={min}
              max={max}
              rows={rows}
              cols={cols}
              maxLength={maxLength}
              minLength={minLength}
              accept={accept}
              multiple={multiple}
              onWheel={(e) => e.target.blur()}
              step={step}
              ref={inputRef}
              inputMode={inputMode}
              defaultValue={defaultValue}
              locale={locale}
            ></input>
            {icon === true ? <Icon visibility={visibility} setVisibility={setVisibility} /> : null}

            {copy === true ? <Copy value={value} /> : null}
            {search === true ? <SearchButton action={action} /> : null}
          </div>
          {children}
          {errorInput && <p className="input__inputBloc__error">{message}</p>}
        </div>
      )}
    </div>
  )
}

export default Input
