import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { getBienSuccess } from '../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../redux/actions/actionGetBienType'
import Title from '../../layout/Title'
import NavDashboard from '../../components/Dashboard/NavDashboard'
import AddButtonsCard from '../../components/Dashboard/AddButtonsCard'
import MonEspaceCard from '../../components/Dashboard/MonEspaceCard'
import ProcurationCard from '../../components/Dashboard/ProcurationCard'
import RappelsCard from '../../components/Dashboard/RappelsCard'

const MyDashboard = () => {
  const dispatch = useDispatch()
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // person
  const person = useSelector((state) => state.getPerson.person)
  // maj personne
  useEffect(() => {
    if (persons && person !== persons.referentPP[0]) {
      dispatch(getPerson(persons.referentPP[0]))
      // dispatch(getPersonId(persons.referentPP[0].id))
      dispatch(postPersonType('referentPP'))
      dispatch(getBienSuccess(null))
      dispatch(getbienType(null))
    }
  }, [])

  return (
    <section className="section">
      <Title title="Administration des accès" />
      <div className="section__content">
        <NavDashboard />
        <div className="sousSection">
          <div className="cards">
            {/* mon espace */}
            <MonEspaceCard />
            {/* buttons */}
            <AddButtonsCard />
          </div>
          <div className="cards">
            {/* procuration */}
            <ProcurationCard />
            {/* rappels */}
            {persons ? <RappelsCard /> : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyDashboard
