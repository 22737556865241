import React, { useState, useEffect } from 'react'
import Card from '../Components/Card'
import SelectComponent from '../Components/SelectComponent'
import { optionsDistanceTrueOrFalse } from '../../../data/options/options'
import BlocFilesList from './BlocFilesList'
import BlocSignatairesList from './BlocSignatairesList'
import { shortFormeJuridique } from '../../../services/helpers/helpers'
import Signataire from './Signataire'
import { MdAddCircle } from 'react-icons/md'

const ToSignCard = ({
  personsToDisplay,
  signataires,
  setSignataires,
  contactsTodisplay,
  handleSuppr,
  setToggleAdd,
  files,
  setFiles,
  distance,
  setDistance,
  handleValidate,
  reset,
}) => {
  const [listPersons, setListPersons] = useState([])
  const [optionsListPersons, setOptionsListPersons] = useState(null)
  const [personsSelected, setPersonsSelected] = useState([])
  const [newSignataire, setNewSignataire] = useState(null)
  const [modalUpdate, setModalUpdate] = useState(false)
  // actualisation pp ou pm à afficher
  useEffect(() => {
    const array1 = personsToDisplay.referentPP.concat(personsToDisplay.relationPP)
    const array2 = array1.concat(personsToDisplay.dirigeantPM)
    setListPersons(contactsTodisplay ? array2.concat(contactsTodisplay) : array2)
  }, [personsToDisplay, contactsTodisplay])

  const [change, setChange] = useState(false)
  useEffect(() => {
    if (signataires.length !== 0 && files.length !== 0) {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [signataires, files])

  const handleCheckPhone = (signataire) => {
    // for (const signataire of signataires) {
    if (!signataire.telPortable || signataire.telPortable === '33333333333') {
      return true
    } else {
      return false
    }
    // }
  }

  useEffect(() => {
    if (newSignataire && newSignataire.telPortable && newSignataire.telPortable !== '33333333333') {
      setSignataires([
        ...signataires.filter((signataire) => signataire.nom !== newSignataire.nom),
        newSignataire,
      ])
    }
  }, [newSignataire])

  // maj optionsListPersons
  useEffect(() => {
    if (listPersons.length > 0) {
      setOptionsListPersons(
        listPersons.map((el) => ({
          value: el,
          label:
            el.denominationSociale !== undefined
              ? `${el.denominationSociale} (${shortFormeJuridique(
                  el.formeJuridique
                )}) - Personne Morale (PM)`
              : el.fichiers !== undefined
              ? `${el.nom} ${el.prenom} - Personne Physique (PP)`
              : `${el.nom} ${el.prenom} - Contact`,
        }))
      )
    }
  }, [listPersons])
  const createSignataire = (data) => {
    return {
      nom:
        data.denominationSociale !== undefined
          ? `${data.dirigeant};${shortFormeJuridique(data.formeJuridique)};${
              data.denominationSociale
            }`
          : `${data.nom};${data.prenom}`,
      mail: data.fichiers === undefined ? data.email : data.mailUsuel,
      telPortable: data.fichiers === undefined ? data.telephonePortable : data.telPortable,
    }
  }
  // handleChangeMulti
  const handleChangeMulti = (e) => {
    let array = []
    let signataireToAdd
    if (e.length > 0) {
      for (const item of e) {
        if (!personsSelected.includes((el) => el === item.value)) {
          signataireToAdd = createSignataire(item.value)
          setNewSignataire(signataireToAdd)
          if (handleCheckPhone(item.value)) {
            setModalUpdate(true)
          }
        }
        array.push(signataireToAdd)
      }
    }
    setSignataires(array)
    setPersonsSelected(array)
  }

  return (
    <>
      <Card title="Signer un document" open={true}>
        <div className="informations">
          {/* liste personnes */}
          <h3 className="hr__title">Sélection des signataires</h3>
          {/* PP ou PM */}
          <div className="informations">
            <SelectComponent
              forAndId="personsSelected"
              isSearchable={true}
              isMulti={true}
              options={optionsListPersons && optionsListPersons}
              placeholder="Sélectionner"
              onChange={(e) => handleChangeMulti(e)}
              value={
                optionsListPersons && optionsListPersons.find((c) => c.value === personsSelected)
              }
              bold={true}
              noLabel={true}
            />
          </div>
          {/* Add */}
          <div className="informations__line informations__line--short">
            <p>Saisir un nouveau signataire : </p>
            <button onClick={() => setToggleAdd(true)}>
              <MdAddCircle />
            </button>
          </div>
          {/* Liste */}
          <div className="informations">
            {/* signataires selectionnés */}
            {signataires.length > 0 && (
              <BlocSignatairesList signataires={signataires} handleSuppr={handleSuppr} />
            )}
          </div>
        </div>
        {/* Files */}
        {signataires.length > 0 ? (
          <div className="informations">
            <h3 className="hr__title">Sélection des fichiers</h3>
            <BlocFilesList files={files} setFiles={setFiles} />
          </div>
        ) : null}
        {/* distance */}
        {files.length > 0 ? (
          <div className="informations">
            <h3 className="hr__title">Distance</h3>
            <div className="informations__line  informations--border">
              <SelectComponent
                forAndId="distance"
                name="Lieu : "
                isSearchable={false}
                options={optionsDistanceTrueOrFalse}
                placeholder="Sélectionner"
                onChange={(e) => setDistance(e.value)}
                value={optionsDistanceTrueOrFalse.find((c) => c.value === distance)}
              />
            </div>
          </div>
        ) : null}
        {/* Validation */}
        <div className="informations">
          {/* <h3 className="hr__title">Validation</h3> */}
          {change ? (
            <div className="informations__buttons">
              <div className="button button--red-red width--12" onClick={() => handleValidate()}>
                Valider
              </div>
              <div className="button button--blue-red width--12" onClick={() => reset()}>
                Annuler
              </div>
            </div>
          ) : (
            <div className="informations__buttons">
              <div className="button button--grey width--12">Valider</div>
              <div className="button button--blue-red width--12" onClick={() => reset()}>
                Annuler
              </div>
            </div>
          )}
        </div>
      </Card>
      {modalUpdate && (
        <Signataire
          setModalUpdate={setModalUpdate}
          newData={newSignataire}
          setNewData={setNewSignataire}
          update={true}
          title={'Ajouter un numéro de téléphone portable'}
        />
      )}
    </>
  )
}

export default ToSignCard
