import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sortByType } from '../../../../../../services/helpers/sort'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import { supprId } from '../../../../../../services/helpers/helpers'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyDette from './BodyDette'
import BodyDettePrint from './BodyDettePrint'

const TableBodyDette = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // ouvrir infos passif
  const handleShow = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'PASSIFS', type: 'dette' }))
    navigate('/user/patrimoine/passifs/dettes/info')
  }
  // modifier passif
  const handleEdit = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'PASSIFS', type: 'dette' }))
    navigate('/user/patrimoine/passifs/dettes/ajouter')
  }
  // duplicate bien
  const handleDuplicate = (bien) => {
    const bienBis = {
      ...bien,
      id: 0,
      fichiers: [],
      refDette: `${bien.refDette} - copie`,
      detenteurs: bien.detenteurs.map((det) => supprId(det)),
    }
    dispatch(getBienSuccess(bienBis))
    dispatch(getbienType({ categorie: 'PASSIFS', type: 'dette' }))
    navigate('/user/patrimoine/passifs/dettes/ajouter')
  }
  // supprimer passif
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }

  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Référence dette':
          giveAType('refDette')
          break
        case 'Montant restant':
          giveAType('montantRestant')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyDettePrint /> : <BodyDette handleShow={handleShow} />}
      print={print}
      handleShow={handleShow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleDuplicate={handleDuplicate}
    />
  )
}

export default TableBodyDette
