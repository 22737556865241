import React, { useState } from 'react'
import InputsAutoCompleteAdresse from './InputsAutoCompleteAdresse'

const AutoCompleteAdresse = ({
  data,
  setData,
  etablissement,
  societe,
  adresseFiscale,
  cabinet,
  bienImmo,
  personInfos,
  setDataChange,
}) => {
  // affichage des résultat
  const [toggleAddress, setToggleAddress] = useState(false)
  const [toggleCode, setToggleCode] = useState(false)
  const [toggleCity, setToggleCity] = useState(false)
  const [toggleCountry, setToggleCountry] = useState(false)

  // autocomplétion qd select adresse/ville/CP/Country
  const handleSelectAddress = (address) => {
    etablissement
      ? setData({
          ...data,
          adresse_ligne_1: address.properties.name,
          code_postal: address.properties.postcode,
          ville: address.properties.city,
        })
      : societe
      ? setData({
          ...data,
          adresse1: address.properties.name,
          codePostal: address.properties.postcode,
          ville: address.properties.city,
        })
      : adresseFiscale
      ? setData({
          ...data,
          ad1: address.properties.name,
          codePostal: address.properties.postcode,
          ville: address.properties.city,
        })
      : cabinet
      ? setData({
          ...data,
          adresseCabinet: address.properties.name,
          cpCabinet: address.properties.postcode,
          villeCabinet: address.properties.city,
        })
      : bienImmo
      ? setData({
          ...data,
          adresse: address.properties.name,
          codePostal: address.properties.postcode,
          ville: address.properties.city,
        })
      : setData({
          ...data,
          libelle: address.properties.name,
          cp: address.properties.postcode,
          ville: address.properties.city,
        })

    setToggleAddress(false)
  }
  const handleSelectPostcode = (postcode) => {
    etablissement
      ? setData({
          ...data,
          code_postal: postcode.properties.postcode,
          ville: postcode.properties.city,
        })
      : societe || adresseFiscale || bienImmo
      ? setData({
          ...data,
          codePostal: postcode.properties.postcode,
          ville: postcode.properties.city,
        })
      : cabinet
      ? setData({
          ...data,
          cpCabinet: postcode.properties.postcode,
          villeCabinet: postcode.properties.city,
        })
      : setData({
          ...data,
          cp: postcode.properties.postcode,
          ville: postcode.properties.city,
        })
    setToggleCode(false)
  }
  const handleSelectCity = (city) => {
    etablissement
      ? setData({
          ...data,
          code_postal: city.properties.postcode,
          ville: city.properties.city,
        })
      : adresseFiscale || societe || bienImmo
      ? setData({
          ...data,
          codePostal: city.properties.postcode,
          ville: city.properties.city,
        })
      : cabinet
      ? setData({
          ...data,
          cpCabinet: city.properties.postcode,
          villeCabinet: city.properties.city,
        })
      : personInfos
      ? setData({
          ...data,
          villeNaissance: city.properties.city,
        })
      : setData({
          ...data,
          cp: city.properties.postcode,
          ville: city.properties.city,
        })
    setToggleCity(false)
  }
  const handleSelectCountry = (country) => {
    etablissement
      ? setData({
          ...data,
          pays: country.fields.libcog,
          code_pays: country.fields.codeiso3,
        })
      : setData({
          ...data,
          pays: country.fields.libcog,
        })
    setToggleCountry(false)
  }

  return (
    <InputsAutoCompleteAdresse
      adresseFiscale={adresseFiscale}
      societe={societe}
      etablissement={etablissement}
      cabinet={cabinet}
      bienImmo={bienImmo}
      personInfos={personInfos}
      data={data}
      setData={setData}
      toggleAddress={toggleAddress}
      setToggleAddress={setToggleAddress}
      toggleCode={toggleCode}
      setToggleCode={setToggleCode}
      toggleCity={toggleCity}
      setToggleCity={setToggleCity}
      toggleCountry={toggleCountry}
      setToggleCountry={setToggleCountry}
      handleSelectAddress={handleSelectAddress}
      handleSelectPostcode={handleSelectPostcode}
      handleSelectCity={handleSelectCity}
      handleSelectCountry={handleSelectCountry}
      setDataChange={setDataChange}
    />
  )
}

export default AutoCompleteAdresse
