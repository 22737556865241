import React from 'react'
import { CHARGES_PENSIONS } from '../../../../../constants'
import TableToPrint from '../../../table/TableToPrint'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../Patrimoine/PatrimoineChartCardPrint'
import TableBodyCharge from '../Charges/Table/TableBodyCharge'
import ChgPensionsPieChart from './ChgPensionsPieChart'

const ChargesPensionsToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurPensions } = elementToDisplay || {}

  return (
    <>
      <PatrimoineValueCard totalAmount={valeurPensions} values={values} />
      <PatrimoineChartCardPrint
        children={<ChgPensionsPieChart pensions={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={CHARGES_PENSIONS}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyCharge />}
      />
      <TableToPrint
        data={dataSold}
        tableType={CHARGES_PENSIONS}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyCharge />}
      />
    </>
  )
}

export default ChargesPensionsToPrint
