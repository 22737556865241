import React from 'react'
import { BsFillCartPlusFill, BsFillCartDashFill } from 'react-icons/bs'
import { FcDocument } from 'react-icons/fc'
import { FiEdit3 } from 'react-icons/fi'
import { FE } from '../../../../../../constants'
import { beautifulDate } from '../../../../../../services/helpers/dates'
import Copy from '../../../../Components/Copy'
import { useSelector } from 'react-redux'

const BodyPortefeuille = ({ element, handleclick }) => {
  const bienType = useSelector((state) => state.getBienType.type)
  const type = bienType.type

  // show DICI
  const handleShowDICI = (dici) => {
    dici !== undefined && window.open(dici.dici_url)
  }

  return (
    <>
      {/* Acheter*/}
      <td className="tbody__tr__td" onClick={() => handleclick(element, 'achat')}>
        <div className="tbody__tr__td__button">
          <BsFillCartPlusFill />
        </div>
      </td>
      {/* Vendre*/}
      <td className="tbody__tr__td" onClick={() => handleclick(element, 'vente')}>
        <div className="tbody__tr__td__button">
          <BsFillCartDashFill />
        </div>
      </td>
      {/* Nom */}
      <td className="tbody__tr__td bold">
        <div className="tbody__tr__td--divCopy">
          <Copy value={element.nomSP} little={true} />
          {element.nomSP}
        </div>
      </td>
      {/* ISIN */}
      <td className="tbody__tr__td">
        <div className="tbody__tr__td--divCopy">
          <Copy value={element.isin} little={true} />
          {element.isin}
        </div>
      </td>
      {/* montant */}
      <td className="tbody__tr__td bold">{`${element.montant.toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`}</td>
      {(element.dici && element.dici.c_nom !== FE) || !element.dici ? (
        <>
          {/* Quantité */}
          <td className="tbody__tr__td">
            {element.quantite.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })}
          </td>
          {/* Cours */}
          <td className="tbody__tr__td">{`${element.dernierCours.toLocaleString(undefined, {
            minimumFractionDigits: 4,
          })} €`}</td>
          {/* date */}
          <td className="tbody__tr__td">{`${beautifulDate(element.dateDernierCours)}`}</td>
        </>
      ) : (
        <>
          <td className="tbody__tr__td bold colSpan" colSpan={3}>{`- Fond euro -`}</td>
        </>
      )}
      {/* PAM */}
      <td className="tbody__tr__td">{`${element.pam.toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`}</td>
      {/* +/- value */}
      <td className="tbody__tr__td bold">{`${
        (element.dici && element.dici.c_nom !== FE) || !element.dici
          ? (((element.dernierCours - element.pam) / element.pam) * 100).toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })
          : (((element.montant - element.pam) / element.pam) * 100).toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })
      } %`}</td>
      {/* DICI */}
      {element.dici && element.dici.dici_url && element.dici.dici_url !== 'NULL' ? (
        <td className="tbody__tr__td" onClick={() => handleShowDICI(element.dici)}>
          <div className="tbody__tr__td__button">
            <FcDocument />
          </div>
        </td>
      ) : (
        <td className="tbody__tr__td colSpan"></td>
      )}
      {/* Régulariser*/}
      {type === 'produitFinancierAV' || type === 'retraite' ? (
        <td className="tbody__tr__td" onClick={() => handleclick(element, 'regulariser')}>
          <div className="tbody__tr__td__button">
            <FiEdit3 />
          </div>
        </td>
      ) : null}
      {/* Acheter*/}
      <td className="tbody__tr__td" onClick={() => handleclick(element, 'achat')}>
        <div className="tbody__tr__td__button">
          <BsFillCartPlusFill />
        </div>
      </td>
      {/* Vendre*/}
      <td className="tbody__tr__td" onClick={() => handleclick(element, 'vente')}>
        <div className="tbody__tr__td__button">
          <BsFillCartDashFill />
        </div>
      </td>
    </>
  )
}

export default BodyPortefeuille
