import React from 'react'
import { MdLocalPrintshop } from 'react-icons/md'
import Loader from '../../../layout/Loader'
import useCustomPrint from '../../../layout/useCustomPrint'
import PrintComponent from '../../Components/PrintComponent'
import Card from '../../Components/Card'
import ChartActifsPassifs from './ChartActifsPassifs'
import ChartRevenusCharges from './ChartRevenusCharges'

const PatrimoineBarChartCard = ({ title, actifsPassifs, actifs, passifs, revenus, charges }) => {
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <PrintComponent
        componentRef={componentRef}
        title={`Graphiques : ${titlePrint}`}
        children={
          <div className="print__charts">
            <div className="informations">
              {actifsPassifs ? (
                <ChartActifsPassifs
                  actifsToDisplay={actifs}
                  passifsToDisplay={passifs}
                  print={true}
                />
              ) : (
                <ChartRevenusCharges
                  revenusToDisplay={revenus}
                  chargesToDisplay={charges}
                  print={true}
                />
              )}
            </div>
          </div>
        }
      />

      <Card title={title} h3={true} open={true}>
        <button onClick={handlePrint} className="button--print button--print--square">
          <MdLocalPrintshop />
        </button>

        <div className="informations">
          {actifsPassifs ? (
            <ChartActifsPassifs actifsToDisplay={actifs} passifsToDisplay={passifs} print={false} />
          ) : (
            <ChartRevenusCharges
              revenusToDisplay={revenus}
              chargesToDisplay={charges}
              print={false}
            />
          )}
        </div>
      </Card>
    </>
  )
}

export default PatrimoineBarChartCard
