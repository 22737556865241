import React from 'react'
import { PASSIFS_DETTES } from '../../../../../../constants'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import TableToPrint from '../../../../table/TableToPrint'
import PassifsDettesPieChart from '../PassifsDettesPieChart'
import TableBodyDette from '../Table/TableBodyDette'

const PassifsDettesToPrint = ({ elementToDisplay, values }) => {
  const { ldettes, valeurDettes } = elementToDisplay || {}
  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurDettes}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<PassifsDettesPieChart dettes={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={ldettes}
        tableType={PASSIFS_DETTES}
        title={`Dettes (${ldettes.length})`}
        children={<TableBodyDette />}
      />
    </>
  )
}

export default PassifsDettesToPrint
