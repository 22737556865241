import React from 'react';
import { useNavigate } from 'react-router-dom';
import Modale from '../Components/Modale';

const ModalSignupNotOkAlreadyExist = ({handleRetry}) => {
    const navigate = useNavigate()

    return (
      <Modale
        close={() => handleRetry()}
        title="Erreur !"
        line1="Un problème est survenu"
        line2="Un compte existe déjà avec cette adresse mail"
      >
        <div className="button button--blue-red width--12" onClick={() => handleRetry()}>
          Réessayer
        </div>
        <div className="button button--blue-red width--12" onClick={() => navigate('/connexion')}>
          Se connecter
        </div>
      </Modale>
    )
  };

export default ModalSignupNotOkAlreadyExist;