import React from 'react'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import { optionsTypePeriodicite } from '../../../../../../data/options/options'
import { beautifulDate } from '../../../../../../services/helpers/dates'

const BodyCharge = ({ element, handleShow }) => {
  return (
    <>
      {/* nom */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.nom ? <p className="informations__line__notxt">-</p> : element.nom}
      </td>
      {/* montantAnnuel */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.montantAnnuel ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.montantAnnuel.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
        )}
      </td>
      {/* periodicite */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.periodicite ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          recupLabel(element.periodicite, optionsTypePeriodicite)
        )}
      </td>
      {/* dateDebut */}
      <td className="tbody__tr__td" data-label="Date début">
        {!element.dateDebut ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.dateDebut)
        )}
      </td>
      {/* dateFin */}
      <td className="tbody__tr__td" data-label="Date fin">
        {element.dateFin === '' ? (
          <p className="informations__line__notxt">-</p>
        ) : element.dateFin ? (
          beautifulDate(element.dateFin)
        ) : (
          'en cours'
        )}
      </td>
    </>
  )
}

export default BodyCharge
