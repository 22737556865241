import React from 'react'
import Modale from '../Modale'
import Input from '../Input'

const NotCorrect = ({ setNotCorrect, sign }) => {
  return (
    <Modale
      close={() => setNotCorrect(false)}
      title="Incorrect !"
      line1={
        !sign
          ? 'Veuillez remplir les champs obligatoires (encadré rouge)'
          : 'Veuillez remplir les champs correctement'
      }
    >
      {!sign ? (
        <div className="informations">
          <div className="informations--borderFull">
            <Input
              forAndId="exemple"
              type="text"
              name="Exemple :"
              placeholder="champ obligatoire"
              disabled={true}
            />
          </div>
        </div>
      ) : null}
    </Modale>
  )
}

export default NotCorrect
