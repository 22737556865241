import React from 'react'
import { getLibelleProduitFinancier } from '../../../../../../../services/helpers/strings'
import { dateFormateComplete } from '../../../../../../../services/helpers/dates'

const BodyPFPrint = ({ element, index }) => {
  return (
    <>
      {/* libelle */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Libellé</p>
        <p className="print__table__bloc__line--value">
          {!element.libelle ? '-' : getLibelleProduitFinancier(element.libelle)}
        </p>
      </div>
      {/* descriptif */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Descriptif</p>
        <p className="print__table__bloc__line--value">
          {!element.descriptif ? '-' : element.descriptif}
        </p>
      </div>
      {/* numero */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Numéro</p>
        <p className="print__table__bloc__line--value">{!element.numero ? '-' : element.numero}</p>
      </div>
      {/* etablissement */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Numéro</p>
        <p className="print__table__bloc__line--value">
          {!element.etablissement ? '-' : element.etablissement}
        </p>
      </div>
      {!element.clos ? (
        <>
          {/* solde */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Solde</p>
            <p className="print__table__bloc__line--value">
              {!element.solde
                ? '-'
                : `${element.solde.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
            </p>
          </div>
          {/* dateSolde */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Au...</p>
            <p className="print__table__bloc__line--value">
              {!element.dateSolde ? '-' : dateFormateComplete(element.dateSolde)}
            </p>
          </div>
        </>
      ) : (
        <>
          {/* dateCloture */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Date de clôture</p>
            <p className="print__table__bloc__line--value">
              {!element.dateCloture ? '-' : dateFormateComplete(element.dateCloture)}
            </p>
          </div>
        </>
      )}
    </>
  )
}

export default BodyPFPrint
