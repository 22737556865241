import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteContact, getContacts } from '../../../services/API/connexion'
import Modale from '../Components/Modale'
import NotOk from '../Components/Modales/NotOk'

const DeleteContact = ({ setModalDelete, newData }) => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(null)
  const [modalOk, setModalOk] = useState(null)
  const [modalNotOk, setModalNotOk] = useState(null)

  const handleDelete = () => {
    dispatch(deleteContact(newData, setStatus))
  }
  // response delete contact
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setModalOk(true)
      setStatus(null)
    }
    if (status >= 400) {
      setModalNotOk(true)
      setStatus(null)
    }
  }, [status])
  //close
  const handleClose = () => {
    dispatch(getContacts())
    setModalOk(false)
    setModalNotOk(false)
    setModalDelete(false)
  }

  return (
    <>
      <Modale
        close={() => setModalDelete(false)}
        title="Souhaitez-vous réellement supprimer ce contact ?"
      >
        <div className="informations">
          <div className="informations__blocTxt">
            <p>{`${newData.nom} ${newData.prenom}`}</p>
          </div>
          <div className="informations__buttons">
            <div className="button button--blue-red width--12" onClick={() => handleDelete()}>
              Supprimer
            </div>
            <div
              className="button button--blue-red width--12"
              onClick={() => setModalDelete(false)}
            >
              Annuler
            </div>
          </div>
        </div>
      </Modale>
      {modalOk && (
        <Modale
          close={() => handleClose()}
          title={`Contact supprimé`}
        >
          <div className="informations__buttons">
            <div className="button button--blue-red width--12" onClick={() => handleClose()}>
              Fermer
            </div>
          </div>
        </Modale>
      )}
      {modalNotOk && (
        <NotOk setNotOK={setModalNotOk} closeModale={handleClose} />
      )}
    </>
  )
}

export default DeleteContact
