import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import { postCharge } from '../../../../../../services/API/charges'
import { dateToCompare, getMonths } from '../../../../../../services/helpers/dates'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../services/helpers/detenteurs'
import {
  calculateAmountReverse,
  calculateAnnualAmount,
  supprId,
} from '../../../../../../services/helpers/helpers'
import { DOC_CHARGE_IMPOT } from '../../../../../../constants'
import usePersonData from '../../../../../layout/usePersonData'
import ModaleFiles from '../../../../Files/ModaleFiles'
import NotCorrect from '../../../../Components/Modales/NotCorrect'
import Ok from '../../../../Components/Modales/Ok'
import NotOk from '../../../../Components/Modales/NotOk'
import ModaleDivers from '../../../Patrimoine/ModaleDivers'
import FormPatrimoineContent from '../../../Patrimoine/FormPatrimoineContent'

const FormChgImpotTaxe = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const charges = useSelector((state) => state.getCharges.charges)
  const chargesDivers = charges ? charges.chargeDivers.montant : null
  const chargesImpotTaxeDivers = charges ? charges.impotEtTaxeDivers.montant : null
  const charge = useSelector((state) => state.getBien.bien)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddCharge, setToggleAddCharge] = useState(false)
  const [toggleAddChargeOk, setToggleAddChargeOk] = useState(false)
  const [toggleAddChargeNotOk, setToggleAddChargeNotOk] = useState(false)
  const [toggleAddChargeNotCorrect, setToggleAddChargeNotCorrect] = useState(false)
  const [toggleDivers, setToggleDivers] = useState(false)

  // maj charge
  useEffect(() => {
    charge && setFichiersToDisplay(charge.fichiers)
  }, [charge])
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    nom: '',
    // typeCharge: 'REMUNERATIONM',
    detail: '',
    montantAnnuel: 0,
    periodicite: '',
    dateDebut: '',
    dateFin: '',
    detention: 'CLIENT',
    tauxIndivision: 100,
    detenteurs: [],
    fichiers: [],
    typeActif: '',
    idActif: 0,
  })
  const {
    id,
    nom,
    // typeCharge,
    detail,
    montantAnnuel,
    periodicite,
    dateDebut,
    dateFin,
    detention,
    tauxIndivision,
    detenteurs,
    fichiers,
    typeActif,
    idActif,
  } = information || {}
  //
  const [divers, setDivers] = useState(true)
  const [fichiersToDisplay, setFichiersToDisplay] = useState([])
  const [detenteursToDisplay, setDetenteursToDisplay] = useState(detenteurs)
  const [newValeurTotale, setNewValeurTotale] = useState()
  // valeur totale de calcul
  useEffect(() => {
    if (montantAnnuel === 0 || periodicite === '') {
      setNewValeurTotale(0)
    } else {
      if (dateFin === '' || !dateFin) {
        setNewValeurTotale(
          (calculateAnnualAmount(montantAnnuel, periodicite) * tauxIndivision) / 100
        )
      } else {
        if (dateToCompare(dateFin) >= dateToCompare(new Date())) {
          const months = getMonths(dateFin)
          months > 12
            ? setNewValeurTotale(
                (calculateAnnualAmount(montantAnnuel, periodicite) * tauxIndivision) / 100
              )
            : setNewValeurTotale(
                (months *
                  (calculateAnnualAmount(montantAnnuel, periodicite) / 12) *
                  tauxIndivision) /
                  100
              )
        } else {
          setNewValeurTotale(0)
        }
      }
    }
  }, [montantAnnuel, dateFin, periodicite, tauxIndivision])
  // maj si passifToDisplay
  useEffect(() => {
    charge
      ? setInformation({
          ...information,
          id: charge.id,
          nom: charge.nom,
          // typeCharge: charge.typeCharge,
          detail: charge.detail && charge.detail,
          montantAnnuel: calculateAmountReverse(charge.montantAnnuel, charge.periodicite),
          periodicite: charge.periodicite,
          dateDebut: charge.dateDebut,
          dateFin: charge.dateFin,
          detention: charge.detention,
          tauxIndivision: charge.tauxIndivision,
          detenteurs: charge.detenteurs
            .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
            .reverse(),
          fichiers: charge.fichiers,
          typeActif: charge.typeActif,
          idActif: charge.idActif,
        })
      : setInformation({
          ...information,
          detenteurs: [
            {
              id: 0,
              nomDetenteur:
                personType === 'referentPP'
                  ? `${person.nom} ${person.prenom}`
                  : `${person.denominationSociale}`,
              mailDetenteur: person.mail,
              idPP: personType === 'referentPP' ? 1 : 0,
              idPM: personType === 'dirigeantPM' ? 1 : 0,
              dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
              dateContitutionUsufruit: '',
              dureeDemembrement: '',
              typeDetention: 'PLEINEPROPRIETE',
              tauxPleinePropriete: 100,
              tauxUsufruit: 0,
              tauxUsufruitCalcule: handleCalculateTaux(person.dateNaissance),
              tauxNuePropriete: 0,
              valeurDetenue: 0,
              valeurPP: 0,
              valeurUS: 0,
              valeurNP: 0,
              tempId: Date.now(),
            },
          ],
        })
  }, [])
  // form charge is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Charges Impots & Taxes' }))
    }
  }, [dataChange])
  // save charge
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Charges Impots & Taxes') {
      handlePreSubmit()
    }
  }, [saveBien])
  // Object to send
  const data = {
    id: id,
    nom: nom,
    // typeCharge: typeCharge,
    detail: detail,
    montantAnnuel: calculateAnnualAmount(montantAnnuel, periodicite),
    periodicite: periodicite,
    dateDebut: dateDebut,
    dateFin: dateFin,
    detention: detention,
    tauxIndivision: tauxIndivision,
    detenteurs:
      detenteursToDisplay &&
      detenteursToDisplay.map((det) => updateDetenteurs(det, newValeurTotale, detenteursToDisplay)),
    fichiers: fichiers,
    typeActif: typeActif,
    idActif: idActif,
  }
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])

  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      setInformation({ ...information, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      switch (name) {
        case 'divers':
          setDivers(value)
          break
        case 'periodicite':
          if (value === 'UNIQUE' && dateDebut) {
            setInformation({ ...information, [name]: value, dateFin: dateDebut })
          } else {
            setInformation({ ...information, [name]: value })
          }
          break
        default:
          setInformation({ ...information, [name]: value })
      }
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    if (name === 'dateDebut' && periodicite === 'UNIQUE') {
      setInformation({ ...information, [name]: date, dateFin: date })
    } else {
      setInformation({ ...information, [name]: date })
    }
  }
  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (nom !== '' && montantAnnuel !== 0 && montantAnnuel !== '' && periodicite !== '') {
      setDetenteursToDisplay(
        detenteursToDisplay.map((det) => (det.newDetenteur ? supprId(det) : det))
      )
      chargesDivers === 0 && chargesImpotTaxeDivers === 0 ? handleSubmit() : setToggleDivers(true)
    } else {
      setToggleAddChargeNotOk(true)
    }
  }
  // submit
  const handleSubmit = () => {
    dispatch(postCharge('impotEtTaxe', person.id, isPP, divers, data, setStatus))
    setToggleDivers(false)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (charge && charge.id !== 0) {
      navigate('/user/patrimoine/charges/impots-taxes/info')
    } else {
      navigate('/user/patrimoine/charges/impots-taxes')
    }
  }
  // response create passif status and modal
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setStatus(null)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
      setToggleAddChargeOk(true)
    }
    if (status >= 400) {
      setStatus(null)
      setToggleAddChargeNotOk(true)
    }
  }, [status])
  // maj detenteurs
  // useEffect(() => {
  //   if (detenteursToDisplay.length > 0) {
  //     setInformation({ ...information, detenteurs: detenteursToDisplay })
  //   }
  // }, [detenteursToDisplay])

  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setToggleAddChargeOk(false)
    navigate('/user/patrimoine/charges/impots-taxes')
  }
  // recuperation charges
  useEffect(() => {
    if (charges && charge) {
      const newBien = charges.impotEtTaxes.impotEtTaxes.filter((el) => el.id === charge.id)
      dispatch(getBienSuccess(newBien[0]))
    }
  }, [charges])

  return (
    <div className="section__content">
      {/* form */}
      <FormPatrimoineContent
        dataToDisplay={charge}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        information={information}
        setInformation={setInformation}
        detenteursToDisplay={detenteursToDisplay}
        setDetenteursToDisplay={setDetenteursToDisplay}
        fichiersToDisplay={fichiersToDisplay}
        setFichiersToDisplay={setFichiersToDisplay}
        setToggleAdd={setToggleAddCharge}
        handleSubmit={handlePreSubmit}
        newValeurTotale={newValeurTotale}
        handleGiveUp={handleGiveUp}
        tableType={DOC_CHARGE_IMPOT}
        foncier={false}
      />
      {/* modales */}
      {toggleAddCharge && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_CHARGE_IMPOT}
          setModal={setToggleAddCharge}
          setDataChange={setDataChange}
        />
      )}
      {toggleAddChargeOk && (
        <Ok
          text={
            charge
              ? 'Votre charge a été modifiée avec succès'
              : 'Votre charge a été ajoutée avec succès'
          }
          close={closeToggleAddBien}
        />
      )}
      {toggleAddChargeNotOk && (
        <NotOk setNotOK={setToggleAddChargeNotOk} closeModale={closeToggleAddBien} />
      )}
      {toggleAddChargeNotCorrect && <NotCorrect setNotCorrect={setToggleAddChargeNotCorrect} />}
      {toggleDivers && (
        <ModaleDivers
          setToggleDivers={setToggleDivers}
          handleChange={handleChange}
          divers={divers}
          setDivers={setDivers}
          handleSubmit={handleSubmit}
          montantCategorieDivers={chargesDivers}
          montantSousCategorieDivers={chargesImpotTaxeDivers}
          type={'charges'}
          sousType={'impot-taxe'}
        />
      )}
    </div>
  )
}

export default FormChgImpotTaxe
