import React from 'react'
import {
  optionsNatureBienPro,
  optionsRisque,
  optionsTypePro,
} from '../../../../../../data/options/optionsActifs'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'
import SelectComponent from '../../../../Components/SelectComponent'
import DateInputComponent from '../../../../Components/DateInputComponent'
import { optionsDetention } from '../../../../../../data/options/options'

const GeneralInformationCardBienPro = ({ handleChange, handleChangeDate, information }) => {
  const {
    typeBienPro,
    nom,
    nature,
    valeur,
    dateValeur,
    risque,
    codeImpot,
    detention,
    tauxIndivision,
  } = information || {}
  return (
    <Card title="Informations générales" h4={true} open={true}>
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* nom */}
          <Input
            forAndId="nom"
            errorLabel={nom === ''}
            type="text"
            name="Libellé :"
            value={nom}
            onChange={(e) => handleChange(e)}
            placeholder="Maison T5 150 m²"
            copy={true}
            required={true}
          />
          {/* typeBienPro */}
          <SelectComponent
            forAndId="typeBienPro"
            errorLabel={typeBienPro === ''}
            name="Catégorie :"
            isSearchable={false}
            options={optionsTypePro}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsTypePro.find((c) => c.value === typeBienPro)}
            copy={true}
            required={true}
          />
          {/* nature */}
          <SelectComponent
            forAndId="nature"
            errorLabel={nature === ''}
            name="Nature :"
            isSearchable={false}
            options={optionsNatureBienPro}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsNatureBienPro.find((c) => c.value === nature)}
            copy={true}
            required={true}
          />
          {/* valeur ==== valeur retenue !!! */}
          <Input
            forAndId="valeur"
            errorLabel={valeur === 0 || valeur === ''}
            type="number"
            name="Valeur :"
            value={valeur}
            onChange={(e) => handleChange(e)}
            onWeel={(e) => e.target.blur()}
            copy={true}
            required={true}
          />
        </div>
        {/* dateValeur */}
        <DateInputComponent
          name={'dateValeur'}
          label={"Date de l'estimation :"}
          value={dateValeur}
          handleChange={handleChangeDate}
        />
        {/* risque */}
        <SelectComponent
          forAndId="risque"
          name="Niveau de risque :"
          isSearchable={false}
          options={optionsRisque}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsRisque.find((c) => c.value === risque)}
          copy={true}
        />
        {/* codeImpot */}
        <Input
          forAndId="codeImpot"
          type="text"
          name="Code Impot :"
          value={codeImpot}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
        {/* detention */}
        <SelectComponent
          forAndId="detention"
          errorLabel={detention === ''}
          name="Détention :"
          isSearchable={false}
          options={optionsDetention}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsDetention.find((c) => c.value === detention)}
          required={true}
          copy={true}
        />
        {/* tauxIndivision */}
        <Input
          forAndId="tauxIndivision"
          type="number"
          name="Taux d'indivision :"
          value={tauxIndivision}
          onChange={(e, name) => handleChange(e)}
          copy={true}
        />
      </div>
    </Card>
  )
}

export default GeneralInformationCardBienPro
