import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { getActifs } from '../../../../../services/API/actifs'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import BiensAutresPieChart from './BiensAutresPieChart'
import ModalUpdateTotalValueAutres from './ModalUpdateTotalValueAutres'
import PatrimoineAutresCard from './PatrimoineAutresCard'
import ActifsAutresToPrint from './Print/ActifsAutresToPrint'
import LittleLoader from '../../../../layout/LittleLoader'

const AutresHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const properties = useSelector((state) => state.getProperties.properties)
  const { actifsAutres } = properties || {}
  const { autresActifs, valeurAutresActifs, autreActifDivers } = actifsAutres || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: autreActifDivers?.montant },
      { label: 'Affecté', value: valeurAutresActifs - autreActifDivers?.montant },
    ],
    [autreActifDivers, valeurAutresActifs]
  )
  // recharge
  useEffect(() => {
    if (!properties) {
      dispatch(getActifs(person?.id, isPP))
    }
  }, [properties, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () => autresActifs.filter((bien) => bien.closOuVendu),
    [autresActifs]
  )
  const dataToDisplayNotSold = useMemo(
    () => autresActifs.filter((bien) => !bien.closOuVendu),
    [autresActifs]
  )
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // redirections
  const formLink = '/user/patrimoine/actifs/autre/ajouterBien'
  // recuperation patrimoine
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!properties) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <ActifsAutresToPrint
              elementToDisplay={actifsAutres}
              values={values}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurAutresActifs}
              update={true}
              values={values}
            />
            {valeurAutresActifs > 0 ? (
              <PatrimoineChartCard
                children={<BiensAutresPieChart actifsAutres={actifsAutres} />}
                detenteurs={true}
              />
            ) : null}
          </div>
          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <PatrimoineAutresCard
              data={dataToDisplayNotSold}
              handleAddAutre={() => navigate(formLink)}
              title={`Patrimoine autre en cours (${dataToDisplayNotSold.length})`}
            />
            {dataToDisplaySold.length > 0 ? (
              <PatrimoineAutresCard
                data={dataToDisplaySold}
                handleAddAutre={() => navigate(formLink)}
                title={`Historique patrimoine autre (${dataToDisplaySold.length})`}
              />
            ) : null}
          </div>
          {toggleUpdateTotal && (
            <ModalUpdateTotalValueAutres
              setToggleUpdateTotal={setToggleUpdateTotal}
              person={person}
              properties={properties}
              pp={isPP}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default AutresHome
