// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import {
  getPersonsLoad,
  getPersonsSuccess,
  getPersonsError,
} from '../actions/actionGetPersons'

// REDUCER // ______________________________________________________________

// initial state
const initialStateGetPersons = {
  isLoading: false,
  persons: null,
  error: '',
}
// reducer
export const getPersonsReducer = createReducer(initialStateGetPersons, (builder) => {
  return builder
    .addCase(getPersonsLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getPersonsSuccess, (draft, action) => {
      draft.isLoading = false
      draft.persons = action.payload
      draft.error = ''
      return
    })
    .addCase(getPersonsError, (draft, action) => {
      draft.isLoading = false
      draft.persons = null
      draft.error = action.payload
      return
    })
})