// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import {
  postPersonType,
} from '../actions/actionPostPersonType'

// REDUCER // ______________________________________________________________

// initial state
const initialStatePostPersonType = {
  type: null,
}
// reducer
export const postPersonTypeReducer = createReducer(initialStatePostPersonType, (builder) => {
  return builder
    .addCase(postPersonType, (draft, action) => {
      draft.type = action.payload
      return
    })
})