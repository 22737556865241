import React from 'react'
import { stringToCapitalize } from '../../../../../../services/helpers/strings'
import { PRODUITS_VM } from '../../../../../../constants'

const BodyTitres = ({ element, tableType, handleShow }) => {
  // calculs
  const calculateTotalRemise = (produit) => {
    return produit.transactions.length > 0
      ? produit.transactions.map((el) => el.montant).reduce((acc, curr) => acc + curr)
      : 0
  }

  return (
    <>
      {/* libelle */}
      <td className="tbody__tr__td bold" onClick={() => handleShow(element)}>
        {!element.libelle ? <p>-</p> : stringToCapitalize(element.libelle)}
      </td>
      {/* categorie */}
      {tableType !== PRODUITS_VM ? (
        <td className="tbody__tr__td bold" onClick={() => handleShow(element)}>
          {!element.categorie ? (
            <p>-</p>
          ) : (
            stringToCapitalize(element.categorie)
          )}
        </td>
      ) : null}
      {/* liquidite */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {`${element.portefeuille.totalLiquidite.toLocaleString(undefined, {
          minimumFractionDigits: 4,
        })} €`}
      </td>
      {/* montantTotalCalcule */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {`${element.portefeuille.valeurPortefeuille.toLocaleString(undefined, {
          minimumFractionDigits: 4,
        })} €`}
      </td>
      {/* total */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {`${element.portefeuille.totalValeurPortefeuille.toLocaleString(undefined, {
          minimumFractionDigits: 4,
        })} €`}
      </td>
      {/* Gain / Perte */}
      <td className="tbody__tr__td bold" onClick={() => handleShow(element)}>
        {`${(
          element.portefeuille.totalValeurPortefeuille - calculateTotalRemise(element)
        ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
      </td>
    </>
  )
}

export default BodyTitres
