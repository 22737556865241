import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { postUser } from '../../../services/API/connexion'
import InputsUser from './InputsUser'
import { stringToCapitalize } from '../../../services/helpers/strings'

const AddUser = ({ setModalNotCorrect, setStatus }) => {
  const dispatch = useDispatch()
  // fields
  const [fields, setFields] = useState({
    nom: '',
    prenom: '',
    email: '',
    dateNaissance: '',
    password: 'DEFAULT',
  })
  const { nom, prenom, email, dateNaissance, password } = fields || {}

  // handleChange
  const handleChange = (e) => {
    const { name, value } = e.target
    switch (name) {
      case 'nom':
        setFields({ ...fields, [name]: value })
        break
      case 'prenom':
        setFields({ ...fields, [name]: value })
        break
      default:
        setFields({ ...fields, [name]: value })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setFields({ ...fields, [name]: date })
  }

  // submit
  const handleSubmit = (e) => {
    // Object to send
    const data = {
      nom: nom && nom.toUpperCase().trim(),
      prenom: prenom && stringToCapitalize(prenom).trim(),
      pseudo: nom && nom.trim(),
      email: email && email.trim(),
      dateNaissance: dateNaissance && dateNaissance.trim(),
      password: password && password.trim(),
    }
    e.preventDefault()
    if (nom !== '' && prenom !== '' && email !== '' && dateNaissance !== '') {
      dispatch(postUser(data, setStatus))
    } else {
      setModalNotCorrect(true)
    }
  }

  return <InputsUser handleSubmit={handleSubmit} handleChange={handleChange} handleChangeDate={handleChangeDate} fields={fields} />
}

export default AddUser
