import React from 'react'
import { beautifulDate, dateFormate } from '../../../../services/helpers/dates'

const BodyBeneficiaire = ({ element }) => {
  return (
    <>
      {/* nom */}
      <td className="tbody__tr__td" data-label="Nom">
        {!element.nom ? <p className="informations__line__notxt">-</p> : element.nom}
      </td>
      {/* prenom */}
      <td className="tbody__tr__td" data-label="Prenom">
        {!element.prenom ? <p className="informations__line__notxt">-</p> : element.prenom}
      </td>
      {/* dateDeNaissanceFormatee */}
      <td className="tbody__tr__td" data-label="Date de naissance">
        {!element.dateDeNaissanceFormatee ? (
          <p className="informations__line__notxt">-</p>
        ) : element.dateDeNaissanceFormatee.includes('/') ? (
          element.dateDeNaissanceFormatee
        ) : (
          dateFormate(element.dateDeNaissanceFormatee)
        )}
      </td>
      {/* date_greffe */}
      <td className="tbody__tr__td" data-label="Date greffe">
        {!element.date_greffe ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.date_greffe)
        )}
      </td>
      {/* pourcentage_parts */}
      <td className="tbody__tr__td" data-label="Parts">
        {!element.pourcentage_parts ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.pourcentage_parts}%`
        )}
      </td>
      {/* pourcentagePleinePropriete */}
      <td className="tbody__tr__td" data-label="Pleine proprieté">
        {!element.pourcentagePleinePropriete ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.pourcentagePleinePropriete}%`
        )}
      </td>
      {/* pourcentageNuePropriete */}
      <td className="tbody__tr__td" data-label="Nue proprieté">
        {!element.pourcentageNuePropriete ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.pourcentageNuePropriete}%`
        )}
      </td>
    </>
  )
}

export default BodyBeneficiaire
