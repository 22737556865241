import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getPappers } from '../../../../services/API/connexion'
import InputsSiren from '../Inputs/InputsSiren'

const Siren = ({
  siren,
  setSiren,
  errorSiren,
  setErrorSiren,
  setPappers,
  setStatus,
  messageSiren,
  setMessageSiren,
  status,
  pappers,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (status) {
      displaySirenMessage(status)
    }
  }, [status])

  const handleChangeSiren = (e) => {
    const value = e.target.value.replace(/\s/g, '')
    setErrorSiren(false)
    setSiren(value)
  }

  // requête Pappers avec SIREN
  const handleClickSiren = (e) => {
    e.preventDefault()
    const regex = /(\d{9}|\d{3}[ ]\d{3}[ ]\d{3})/
    if (regex.test(siren) && siren.length === 9) {
      setErrorSiren(false)
      dispatch(getPappers(siren, setPappers, setStatus))
    } else {
      setErrorSiren(true)
      setMessageSiren('Veuillez saisir le SIREN correctement (9 chiffres)')
    }
  }
  // Message d'erreur requete SIREN
  const displaySirenMessage = (status) => {
    if (status >= 500 || status === 400 || status === 404) {
      setErrorSiren(true)
      setMessageSiren("Ce SIREN n'est pas reconnu, veuillez remplir le formulaire")
      setStatus(null)
    }
    if (status === 401) {
      setErrorSiren(true)
      setMessageSiren(
        "Une erreur d'authorisation est survenue, veuillez remplir le formulaire. Merci de contacter C@D pour signaler ce problème"
      )
      setStatus(null)
    }
  }

  return (
    <InputsSiren
      handleClickSiren={handleClickSiren}
      handleChangeSiren={handleChangeSiren}
      siren={siren}
      errorSiren={errorSiren}
      messageSiren={messageSiren}
      status={status}
      pappers={pappers}
    />
  )
}

export default Siren
