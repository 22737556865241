import React from 'react'
import {
  dataBienAquisition,
  dataBienImmoAdditionnal,
  dataBienImmoGeneral,
} from '../../../../../../data/informations/actifs'
import InformationsCardPrint from '../../../../Informations/InformationsCardPrint'
import { DOC_BIEN_IMMO } from '../../../../../../constants'
import BienInfoPrintDetenteurEtDoc from '../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'

const BienImmoInfoPrint = ({ elementToDisplay }) => {
  const { detenteurs, fichiers } = elementToDisplay || {}
  return (
    <>
      <InformationsCardPrint
        data={dataBienImmoGeneral(elementToDisplay)}
        title={elementToDisplay.nom}
      />
      <InformationsCardPrint
        data={dataBienImmoAdditionnal(elementToDisplay)}
        title={'Données complémentaire'}
      />
      <InformationsCardPrint data={dataBienAquisition(elementToDisplay)} title={'Acquisition'} />
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={DOC_BIEN_IMMO}
      />
    </>
  )
}

export default BienImmoInfoPrint
