import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { TbArrowBackUp } from 'react-icons/tb'
import { FaUsers } from 'react-icons/fa'
import { AiFillHome } from 'react-icons/ai'
import { MdLocalPrintshop, MdModeEdit, MdAddCircle, MdDeleteForever } from 'react-icons/md'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import useFormIsActive from '../../layout/useFormIsActive'
import SaveForm from '../../layout/SaveForm'
import ButtonUserBack from '../Components/ButtonUserBack'
import DeletePP from '../Accounts/DeletePP'
import usePersonData from '../../layout/usePersonData'

const ButtonsRow = ({ handlePrint, formCreateLink, formEditLink, backLink }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formIsActive = useFormIsActive()
  const persons = useSelector((state) => state.getPersons.persons)
  const { person, personType } = usePersonData()
  // modales
  const [toggleSaveForm, setToggleSaveForm] = useState(false)
  const [toggleDeletePP, setToggleDeletePP] = useState(false)
  // handleClick
  const handleClick = () => {
    formIsActive && setToggleSaveForm(true)
  }
  // handleAdd
  const handleAdd = () => {
    navigate(formCreateLink)
  }
  // handleAddImmo
  const handleEdit = () => {
    navigate(formEditLink)
  }
  // handleDelete
  const handleDelete = () => {
    setToggleDeletePP(true)
  }
  // handleBack
  const handleBack = () => {
    navigate(backLink)
  }
  // handleBack
  const handleAccounts = () => {
    dispatch(getPerson(person))
    dispatch(postPersonType(personType))
    navigate('/user/comptes')
  }
  const [textLeft, setTextLeft] = useState('')
  const [textRight, setTextRight] = useState('')

  return person ? (
    <>
      <div className="informations__buttonsTop" onClick={() => handleClick()}>
        <div className="informations__buttons">
          {formIsActive ? (
            <div>
              <MdLocalPrintshop />
            </div>
          ) : (
            <button
              onClick={handlePrint}
              className="button"
              onMouseEnter={() => setTextLeft('Imprimer')}
              onMouseLeave={() => setTextLeft('')}
              value={'Imprimer'}
            >
              <MdLocalPrintshop />
            </button>
          )}
          {formIsActive ? (
            <div>
              <MdModeEdit />
            </div>
          ) : (
            <button
              className="button"
              onMouseEnter={() => setTextLeft('Modifier')}
              onMouseLeave={() => setTextLeft('')}
              onClick={handleEdit}
              value={'Modifier'}
            >
              <MdModeEdit />
            </button>
          )}
          {person !== persons.referentPP[0] ? (
            formIsActive ? (
              <div>
                <MdDeleteForever />
              </div>
            ) : (
              <button
                className="button"
                onMouseEnter={() => setTextLeft('Supprimer')}
                onMouseLeave={() => setTextLeft('')}
                onClick={handleDelete}
                value={'Supprimer'}
              >
                <MdDeleteForever />
              </button>
            )
          ) : null}
          {formEditLink ? (
            formIsActive ? (
              <div>
                <MdAddCircle />
              </div>
            ) : (
              <button
                className="button"
                onMouseEnter={() => setTextLeft('Ajouter')}
                onMouseLeave={() => setTextLeft('')}
                onClick={handleAdd}
                value={'Ajouter'}
              >
                <MdAddCircle />
              </button>
            )
          ) : handleAdd ? (
            formIsActive ? (
              <div>
                <MdAddCircle />
              </div>
            ) : (
              <button
                className="button"
                onMouseEnter={() => setTextLeft('Ajouter')}
                onMouseLeave={() => setTextLeft('')}
                onClick={handleAdd}
                value={'Ajouter'}
              >
                <MdAddCircle />
              </button>
            )
          ) : null}
          {textLeft ? <p className="informations__buttons--p">{textLeft}</p> : null}
        </div>
        <div className="informations__buttons">
          {textRight ? <p className="informations__buttons--p">{textRight}</p> : null}
          {person !== persons.referentPP[0] ? (
            <ButtonUserBack setText={setTextRight} formIsActive={formIsActive} />
          ) : null}
          {formIsActive ? (
            <div>
              <FaUsers />
            </div>
          ) : (
            <button
              className="button"
              onMouseEnter={() => setTextRight('Mes comptes')}
              onMouseLeave={() => setTextRight('')}
              onClick={handleAccounts}
              value={'Mes comptes'}
            >
              <FaUsers />
            </button>
          )}
          {formIsActive ? (
            <div>
              <AiFillHome />
            </div>
          ) : (
            <button
              className="button"
              onMouseEnter={() => setTextRight('Accueil')}
              onMouseLeave={() => setTextRight('')}
              onClick={() => navigate('/user/accueil')}
              value={'Accueil'}
            >
              <AiFillHome />{' '}
            </button>
          )}
          {formIsActive ? (
            <div>
              <TbArrowBackUp />
            </div>
          ) : (
            <button
              className="button"
              onMouseEnter={() => setTextRight('Retour')}
              onMouseLeave={() => setTextRight('')}
              onClick={handleBack}
              value={'Retour'}
            >
              <TbArrowBackUp />
            </button>
          )}
        </div>
      </div>
      {toggleDeletePP ? <DeletePP newData={person} setModalDelete={setToggleDeletePP} /> : null}
      {toggleSaveForm && (
        <SaveForm toggleSaveForm={toggleSaveForm} setToggleSaveForm={setToggleSaveForm} />
      )}
    </>
  ) : (
    <Navigate to={backLink} replace />
  )
}

export default ButtonsRow
