import React from 'react'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'

const AdditionnalInformationsCard = ({ handleChange, information }) => {
  const { interet, montantAssurance, differeAmortissement, montantInteret } = information || {}

  return (
    <Card title="Informations complémentaires" h3={true} open={true}>
      <div className="informations">
        {/* interet */}
        <Input
          forAndId="interet"
          type="number"
          name="Taux d'interêt (%):"
          value={interet}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
        {/* interet */}
        <Input
          forAndId="montantInteret"
          type="number"
          name="Montant des intérêts :"
          value={montantInteret}
          // onChange={(e) => handleChange(e)}
          copy={true}
          step={0.01}
          disabled={true}
        />
        {/* montantAssurance */}
        <Input
          forAndId="montantAssurance"
          type="number"
          name="Montant de l'assurance :"
          value={montantAssurance}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
        {/* differeAmortissement */}
        <Input
          forAndId="differeAmortissement"
          type="number"
          name="Différé d'amortissement (mois) :"
          value={differeAmortissement}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
      </div>
    </Card>
  )
}

export default AdditionnalInformationsCard
