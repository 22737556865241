import React from 'react'
import { COLORS } from '../../../../../constants'
import PieChartComponent from '../../../Charts/PieChartComponent'
import LegendPieChart from '../../../Charts/LegendPieChart'

const PassifsPieChart = ({ passifs, detenteurs, print }) => {
  const {
    valeurTotaleEmprunts,
    valeurTotaleDettes,
    passifDivers,
    valeurTotalePassif,
    valeurTotalePassifDetenue,
  } = passifs
  // data
  const data = !detenteurs
    ? [
        {
          name: 'Emprunts',
          value: valeurTotaleEmprunts,
          fill: COLORS.colorRepartition01,
          color: 'repartition01',
        },
        {
          name: 'Dette',
          value: valeurTotaleDettes,
          fill: COLORS.colorRepartition02,
          color: 'repartition02',
        },
        {
          name: 'Passif non affecté',
          value: passifDivers.montant,
          fill: COLORS.colorFontLight,
          color: 'divers',
        },
      ]
    : [
        {
          name: "Détenu par l'utilisateur",
          value: valeurTotalePassifDetenue,
          fill: COLORS.colorSecondary,
          color: 'detenu',
        },
        {
          name: "Non détenu par l'utilisateur",
          value: valeurTotalePassif - valeurTotalePassifDetenue - passifDivers.montant,
          fill: COLORS.colorPrimary,
          color: 'nonDetenu',
        },
        {
          name: 'Passif non affecté',
          value: passifDivers.montant,
          fill: COLORS.colorFontLight,
          color: 'divers',
        },
      ]
  // title
  const title = !detenteurs ? 'Répartition' : 'Détention'
  return (
    <>
      <PieChartComponent data={data} title={title} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title}/>
    </>
  )
}

export default PassifsPieChart
