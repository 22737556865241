// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get Bien
 * @reduxAction
 */

export const getBienLoad = createAction('get-bien-load')

export const getBienSuccess = createAction('get-bien-success', (bien) => {
  return {
    payload: bien,
  }
})

export const getBienError = createAction('get-bien-error', (error) => {
  return {
    payload: error,
  }
})
