import React from 'react'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import ChargesPieChart from '../ChargesPieChart'

const ChargesToPrint = ({ elementToDisplay, values }) => {
  const { valeurTotalCharge } = elementToDisplay || {}
  return (
    <>
      <PatrimoineValueCard totalAmount={valeurTotalCharge} values={values} />
      <PatrimoineChartCardPrint
        children={<ChargesPieChart charges={elementToDisplay} />}
        detenteurs={true}
      />
    </>
  )
}

export default ChargesToPrint
