import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getBienSuccess } from '../../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getFormIsActive } from '../../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../../redux/actions/actionSaveForm'
import { postFinancier } from '../../../../../../../services/API/financier'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../../services/helpers/detenteurs'
import { beautifulDateNumbers } from '../../../../../../../services/helpers/dates'
import { supprId } from '../../../../../../../services/helpers/helpers'
import NotCorrect from '../../../../../Components/Modales/NotCorrect'
import ModaleDivers from '../../../../Patrimoine/ModaleDivers'
import FormPFContent from './FormPFContent'
import { DOC_FINANCIER_COMPTE } from '../../../../../../../constants'
import ModaleFiles from '../../../../../Files/ModaleFiles'
import usePersonData from '../../../../../../layout/usePersonData'

const FormPF = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const personDateNaissance = person?.dateNaissance
  const properties = useSelector((state) => state.getProperties.properties)
  const actifsDivers = properties ? properties.actifsDivers.montant : null
  // financier
  const financier = useSelector((state) => state.getFinancier.financier)
  const pfDivers = financier ? financier.produitFinancierdivers.montant : null
  // bien
  const bien = useSelector((state) => state.getBien.bien)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddPatrimoine, setToggleAddPatrimoine] = useState(false)
  const [toggleAddNotCorrect, setToggleNotCorrect] = useState(false)
  const [toggleDivers, setToggleDivers] = useState(false)
  // maj bien
  useEffect(() => {
    bien && setFichiersToDisplay(bien.fichiers)
  }, [bien])
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    libelle: '',
    descriptif: '',
    etablissement: '',
    numero: '',
    solde: 0,
    dateSolde: beautifulDateNumbers(new Date()),
    clos: false,
    dateCloture: '',
    // detention: 'CLIENT',
    // tauxIndivision: 100,
    detenteurs: [],
    fichiers: [],
  })
  const {
    id,
    libelle,
    descriptif,
    etablissement,
    numero,
    solde,
    dateSolde,
    clos,
    dateCloture,
    // detention,
    // tauxIndivision,
    detenteurs,
    fichiers,
  } = information || {}
  //
  const [divers, setDivers] = useState(true)
  const [fichiersToDisplay, setFichiersToDisplay] = useState([])
  const [detenteursToDisplay, setDetenteursToDisplay] = useState(detenteurs)
  const [newValeurTotale, setNewValeurTotale] = useState()
  // valeur totale de calcul
  useEffect(() => {
    clos ? setNewValeurTotale(0) : setNewValeurTotale(solde)
  }, [clos, solde])
  // maj si bien
  useEffect(() => {
    bien
      ? setInformation({
          ...information,
          id: bien.id,
          libelle: bien.libelle,
          descriptif: bien.descriptif ? bien.descriptif : '',
          etablissement: bien.etablissement,
          numero: bien.numero,
          solde: bien.solde,
          dateSolde: beautifulDateNumbers(bien.dateSolde),
          clos: bien.clos,
          dateCloture: bien.dateCloture ? beautifulDateNumbers(bien.dateCloture) : '',
          detention: bien.detention !== undefined ? bien.detention : 'CLIENT',
          tauxIndivision: bien.tauxIndivision !== undefined ? bien.tauxIndivision : 100,
          detenteurs: bien.detenteurs
            .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
            .reverse(),
          fichiers: bien.fichiers,
        })
      : setInformation({
          ...information,
          detenteurs: [
            {
              id: 0,
              nomDetenteur:
                personType === 'referentPP'
                  ? `${person.nom} ${person.prenom}`
                  : `${person.denominationSociale}`,
              mailDetenteur: person.mail,
              idPP: personType === 'referentPP' ? 1 : 0,
              idPM: personType === 'dirigeantPM' ? 1 : 0,
              dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
              dateContitutionUsufruit: '',
              dureeDemembrement: '',
              typeDetention: 'PLEINEPROPRIETE',
              tauxPleinePropriete: 100,
              tauxUsufruit: 0,
              tauxUsufruitCalcule: handleCalculateTaux(personDateNaissance),
              tauxNuePropriete: 0,
              valeurDetenue: 0,
              valeurPP: 0,
              valeurUS: 0,
              valeurNP: 0,
              tempId: Date.now(),
            },
          ],
        })
  }, [])
  // form  is active
  useEffect(() => {
    dataChange && dispatch(getFormIsActive({ isActive: true, form: 'PF' }))
  }, [dataChange])
  // save form
  useEffect(() => {
    if (saveBien && formIsActive.form === 'PF') {
      handlePreSubmit()
    }
  }, [saveBien])

  // Object to send
  const data = {
    id: id,
    libelle: libelle,
    descriptif: descriptif,
    etablissement: etablissement,
    numero: numero,
    solde: solde,
    dateSolde: dateSolde,
    clos: clos,
    dateCloture: dateCloture,
    // detention: detention,
    // tauxIndivision: tauxIndivision,
    detenteurs:
      detenteursToDisplay &&
      detenteursToDisplay.map((det) =>
        updateDetenteurs(
          det.newDetenteur ? supprId(det) : det,
          newValeurTotale,
          detenteursToDisplay
        )
      ),
    fichiers: fichiers,
  }
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e) {
      if (e.target !== undefined) {
        const { name, value } = e.target
        setInformation({ ...information, [name]: value })
      } else {
        const name = selectName.name
        const value = e.value
        switch (name) {
          case 'divers':
            setDivers(value)
            break
          case 'clos':
            if (value) {
              setInformation({ ...information, [name]: value, solde: 0 })
            } else {
              setInformation({ ...information, [name]: value })
            }
            break
          default:
            setInformation({ ...information, [name]: value })
        }
      }
    } else {
      setInformation({ ...information, libelle: '' })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setInformation({ ...information, [name]: date })
  }

  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (
      libelle !== '' &&
      etablissement !== '' &&
      numero !== '' &&
      dateSolde !== '' &&
      dateSolde !== '' &&
      solde !== ''
    ) {
      actifsDivers === 0 && pfDivers === 0 ? handleSubmit() : setToggleDivers(true)
    } else {
      setToggleNotCorrect(true)
    }
  }
  // submit
  const handleSubmit = () => {
    dispatch(postFinancier('comptebancaire', person.id, isPP, divers, data, setStatus))
    setToggleDivers(false)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (bien) {
      navigate('/user/patrimoine/actifs/financier/produits-financiers/compte')
    } else {
      navigate('/user/patrimoine/actifs/financier/produits-financiers')
    }
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status) {
      if (status < 400) {
        navigate('/user/patrimoine/actifs/financier/produits-financiers')
      }
      setStatus(null)
    }
  }, [status])
  // maj detenteurs
  // useEffect(() => {
  //   if (detenteursToDisplay.length > 0) {
  //     setInformation({ ...information, detenteurs: detenteursToDisplay })
  //   }
  // }, [detenteursToDisplay])
  // recuperation patrimoine
  useEffect(() => {
    if (financier && bien) {
      const newBien = financier.comptesBancaires.filter((el) => el.id === bien.id)
      dispatch(getBienSuccess(newBien[0]))
    }
  }, [financier])

  return (
    <div className="section__content">
      {/* form */}
      <FormPFContent
        bienToDisplay={bien}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        information={information}
        setInformation={setInformation}
        divers={divers}
        detenteursToDisplay={detenteursToDisplay}
        setDetenteursToDisplay={setDetenteursToDisplay}
        fichiersToDisplay={fichiersToDisplay}
        setFichiersToDisplay={setFichiersToDisplay}
        setToggleAddPatrimoine={setToggleAddPatrimoine}
        handleSubmit={handlePreSubmit}
        newValeurTotale={newValeurTotale}
        handleGiveUp={handleGiveUp}
      />
      {/* modales */}
      {toggleAddPatrimoine && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_FINANCIER_COMPTE}
          setModal={setToggleAddPatrimoine}
          setDataChange={setDataChange}
        />
      )}
      {toggleAddNotCorrect && <NotCorrect setNotCorrect={setToggleNotCorrect} />}
      {toggleDivers && (
        <ModaleDivers
          setToggleDivers={setToggleDivers}
          handleChange={handleChange}
          divers={divers}
          setDivers={setDivers}
          handleSubmit={handleSubmit}
          montantCategorieDivers={actifsDivers}
          montantSousCategorieDivers={pfDivers}
          type={'actif'}
          sousType={'comptes-bancaires'}
        />
      )}
    </div>
  )
}

export default FormPF
