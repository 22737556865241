import React from 'react'
// import { useEffect } from 'react'

const FormOperationTitle = ({
  newOperation,
  categorie,
  dispo,
  quantity,
  fondEuro,
  achat,
  valeurAchat,
  oldMontant,
}) => {

  return (
    <div className="informations informations--borderFull modalContent__blocTxt">
      <p className="modalContent__blocTxt__p">
        <span className="modalContent__blocTxt__p__span">{newOperation.nom}</span>
      </p>
      {categorie !== 'AV' && categorie !== 'retraite' ? (
        <p
          className={
            dispo < 0
              ? 'modalContent__blocTxt__p modalContent__blocTxt__p--wrong'
              : 'modalContent__blocTxt__p'
          }
        >
          Disponibilités :
          <span className="modalContent__blocTxt__p__span">{` ${dispo && dispo.toLocaleString(
            undefined,
            { minimumFractionDigits: 4 }
          )} €`}</span>
        </p>
      ) : null}
      {!fondEuro ? (
        <p
          className={
            quantity < 0
              ? 'modalContent__blocTxt__p modalContent__blocTxt__p--wrong'
              : 'modalContent__blocTxt__p'
          }
        >
          Quantité détenue :
          <span className="modalContent__blocTxt__p__span">{` ${parseFloat(quantity).toLocaleString(
            undefined,
            { minimumFractionDigits: 4 }
          )}`}</span>
        </p>
      ) : (
        <p
          className={
            achat
              ? 'modalContent__blocTxt__p'
              : Math.abs(dispo) - parseFloat(valeurAchat) >= 0 || !valeurAchat
              ? 'modalContent__blocTxt__p'
              : 'modalContent__blocTxt__p modalContent__blocTxt__p--wrong'
          }
        >
          Montant investi :
          {
            <span className="modalContent__blocTxt__p__span">{` ${
              valeurAchat || valeurAchat === 0
                ? achat
                  ? newOperation.majRegularisation
                    ? Math.abs(oldMontant).toLocaleString(undefined, { minimumFractionDigits: 4 })
                    : (Math.abs(dispo) + parseFloat(valeurAchat)).toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                      })
                  : (Math.abs(dispo) - parseFloat(valeurAchat)).toLocaleString(undefined, {
                      minimumFractionDigits: 4,
                    })
                : Math.abs(dispo).toLocaleString(undefined, { minimumFractionDigits: 4 })
            } €`}</span>
          }
        </p>
      )}
    </div>
  )
}

export default FormOperationTitle
