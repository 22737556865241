import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import { getCatalogues } from '../../../../../../services/API/catalogue'
import { getSocial } from '../../../../../../services/API/social'
import { PRODUITS_PER } from '../../../../../../constants'
import Loader from '../../../../../layout/Loader'
import usePersonData from '../../../../../layout/usePersonData'
import useCustomPrint from '../../../../../layout/useCustomPrint'
import Card from '../../../../Components/Card'
import PrintComponent from '../../../../Components/PrintComponent'
import BlocTable from '../../../../table/BlocTable'
import ButtonsRow from '../../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../../Patrimoine/PatrimoineChartCard'
import TableBodyTitres from '../../Catalogue/Table/TableBodyTitres'
import useUpdateProductIdCatalogue from '../../Produit/useUpdateProductIdCatalogue'
import PERToPrint from './PERToPrint'
import PERPieChart from './PERPieChart'
import LittleLoader from '../../../../../layout/LittleLoader'

const PERHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const social = useSelector((state) => state.getSocial.social)
  const { produitsPER } = social || {}
  const catalogues = useSelector((state) => state.getCatalogues.catalogues)
  const [cataloguesC, setCataloguesC] = useState(null)
  useEffect(() => {
    if (!catalogues) {
      dispatch(getCatalogues())
    } else {
      setCataloguesC(catalogues.filter((catalogue) => catalogue.categorie === 'PER'))
    }
  }, [catalogues])
  // recharge
  useEffect(() => {
    if (!social) {
      dispatch(getSocial(person?.id, isPP))
    }
  }, [social, person, isPP, dispatch])
  // hook
  const { postData, updateData } = useUpdateProductIdCatalogue('PER')
  useEffect(() => {
    if (cataloguesC && produitsPER) {
      if (produitsPER.filter((el) => el.idCatalogue === 0).length > 0) {
        postData(produitsPER, cataloguesC)
        updateData(produitsPER, cataloguesC)
      }
    }
  }, [produitsPER, cataloguesC])
  // redirections
  const formLink = '/user/patrimoine/actifs/social/PER/formulaire'
  // maj bien
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // calcul valeurs
  const valeurPortefeuilles = useMemo(
    () =>
      produitsPER
        .map((produit) => produit.montantTotalCalcule)
        .reduce((acc, curr) => acc + curr, 0),
    [produitsPER]
  )
  const valeurLiquidites = useMemo(
    () => produitsPER.map((produit) => produit.liquidite).reduce((acc, curr) => acc + curr, 0),
    [produitsPER]
  )
  const values = useMemo(
    () => [
      { label: 'Liquidités', value: valeurLiquidites },
      { label: 'Portefeuilles', value: valeurPortefeuilles },
    ],
    [valeurLiquidites, valeurPortefeuilles]
  )
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()
  // titles
  const titles = [
    'N°',
    'Libellé',
    'Catégorie',
    'Disponibilités',
    'Portefeuille',
    'Total',
    'Gain / Perte',
    'Voir',
    'Modifier',
    'Supprimer',
  ]

  if (!social) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <PERToPrint
              elementToDisplay={produitsPER}
              valeurPortefeuilles={valeurPortefeuilles}
              valeurLiquidites={valeurLiquidites}
              values={values}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            <PatrimoineValueCard
              totalAmount={valeurPortefeuilles + valeurLiquidites}
              values={values}
            />
            {valeurPortefeuilles + valeurLiquidites !== 0 && valeurPortefeuilles > 0 ? (
              <PatrimoineChartCard
                children={<PERPieChart produitsPER={produitsPER} />}
              />
            ) : null}
            <Card title={`Produits (${produitsPER.length})`} h5={true} open={true}>
              <div className="informations">
                <BlocTable
                  data={produitsPER}
                  titles={titles}
                  tableType={PRODUITS_PER}
                  children={<TableBodyTitres />}
                  title={`Produits (${produitsPER.length})`}
                  handleAdd={() => navigate(formLink)}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default PERHome
