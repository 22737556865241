import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFormIsActive } from '../../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../../redux/actions/actionSaveForm'
import { getBienSuccess } from '../../../../../../../redux/actions/Patrimoine/actionGetBien'
import { postSocial } from '../../../../../../../services/API/social'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../../services/helpers/detenteurs'
import {
  beautifulDateNumbers,
  getDateEnd,
  getMonthsBothDate,
} from '../../../../../../../services/helpers/dates'
import { supprId } from '../../../../../../../services/helpers/helpers'
import NotCorrect from '../../../../../Components/Modales/NotCorrect'
import ModaleDivers from '../../../../Patrimoine/ModaleDivers'
import FormPrevoyanceContent from './FormPrevoyanceContent'
import { DOC_SOCIAL_PREVOYANCE } from '../../../../../../../constants'
import ModaleFiles from '../../../../../Files/ModaleFiles'
import usePersonData from '../../../../../../layout/usePersonData'

const FormPrevoyance = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const properties = useSelector((state) => state.getProperties.properties)
  const actifsDivers = properties ? properties.actifsDivers.montant : null
  const social = useSelector((state) => state.getSocial.social)
  const bien = useSelector((state) => state.getBien.bien)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // toggle
  const [toggleSubCategory, setToggleSubCategory] = useState(false)
  // modales
  const [toggleAddPatrimoine, setToggleAddPatrimoine] = useState(false)
  const [toggleAddNotCorrect, setToggleNotCorrect] = useState(false)
  const [toggleDivers, setToggleDivers] = useState(false)
  // maj bien
  useEffect(() => {
    bien && setFichiersToDisplay(bien.fichiers)
  }, [bien])
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    categorie: '',
    sousCategorie: '',
    assureur: '',
    nomContrat: '',
    numero: '',
    libelle: '',
    capital: 0,
    dateSouscription: beautifulDateNumbers(new Date()),
    duree: 0,
    dateFin: '',
    dateEcheance: '',
    taux: 0,
    frequenceCotisation: '',
    montantEcheance: 0,
    renteEstime: 0,
    periodiciteRente: '',
    detenteurs: [],
    fichiers: [],
  })
  const {
    id,
    categorie,
    sousCategorie,
    assureur,
    nomContrat,
    numero,
    libelle,
    capital,
    dateSouscription,
    duree,
    dateFin,
    dateEcheance,
    taux,
    frequenceCotisation,
    montantEcheance,
    renteEstime,
    periodiciteRente,
    detenteurs,
    fichiers,
  } = information || {}
  //
  const [divers, setDivers] = useState(true)
  const [fichiersToDisplay, setFichiersToDisplay] = useState([])
  const [detenteursToDisplay, setDetenteursToDisplay] = useState(detenteurs)
  const [newValeurTotale, setNewValeurTotale] = useState()
  // valeur totale de calcul
  useEffect(() => {
    setNewValeurTotale(capital)
  }, [capital])
  // maj si bien
  useEffect(() => {
    bien
      ? setInformation({
          ...information,
          id: bien.id,
          categorie: bien.categorie,
          sousCategorie: bien.sousCategorie,
          assureur: bien.assureur,
          nomContrat: bien.nomContrat,
          numero: bien.numero,
          libelle: bien.libelle,
          capital: bien.capital,
          dateSouscription: beautifulDateNumbers(bien.dateSouscription),
          duree: bien.duree,
          dateFin: beautifulDateNumbers(bien.dateFin),
          dateEcheance: beautifulDateNumbers(bien.dateEcheance),
          taux: bien.taux,
          frequenceCotisation: bien.frequenceCotisation,
          montantEcheance: bien.montantEcheance,
          renteEstime: bien.renteEstime,
          periodiciteRente: bien.periodiciteRente,
          detenteurs: bien.detenteurs
            .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
            .reverse(),
          fichiers: bien.fichiers,
        })
      : setInformation({
          ...information,
          detenteurs: [
            {
              id: 0,
              nomDetenteur:
                personType === 'referentPP'
                  ? `${person.nom} ${person.prenom}`
                  : `${person.denominationSociale}`,
              mailDetenteur: person.mail,
              idPP: personType === 'referentPP' ? 1 : 0,
              idPM: personType === 'dirigeantPM' ? 1 : 0,
              dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
              dateContitutionUsufruit: '',
              dureeDemembrement: '',
              typeDetention: 'PLEINEPROPRIETE',
              tauxPleinePropriete: 100,
              tauxUsufruit: 0,
              tauxUsufruitCalcule: handleCalculateTaux(person.dateNaissance),
              tauxNuePropriete: 0,
              valeurDetenue: 0,
              valeurPP: 0,
              valeurUS: 0,
              valeurNP: 0,
              tempId: Date.now(),
            },
          ],
        })
  }, [])
  // form is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Prevoyance' }))
    }
  }, [dataChange])
  // save form
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Prevoyance') {
      handlePreSubmit()
    }
  }, [saveBien])
  // Object to send
  const data = {
    id: id,
    categorie: categorie,
    sousCategorie: sousCategorie,
    assureur: assureur,
    nomContrat: nomContrat,
    numero: numero,
    libelle: libelle,
    capital: capital,
    dateSouscription: dateSouscription,
    duree: duree,
    dateFin: dateFin,
    dateEcheance: dateEcheance,
    taux: taux,
    frequenceCotisation: frequenceCotisation,
    montantEcheance: montantEcheance,
    renteEstime: renteEstime,
    periodiciteRente: periodiciteRente,
    detenteurs:
      detenteursToDisplay &&
      detenteursToDisplay.map((det) =>
        updateDetenteurs(
          det.newDetenteur ? supprId(det) : det,
          newValeurTotale,
          detenteursToDisplay
        )
      ),
    fichiers: fichiers,
  }
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    switch (name) {
      case 'dateSouscription':
        if (duree !== '') {
          setInformation({
            ...information,
            [name]: date,
            dateFin: getDateEnd(date, duree),
          })
        } else {
          setInformation({
            ...information,
            [name]: date,
          })
        }
        break
      case 'dateFin':
        if (dateSouscription !== '') {
          setInformation({
            ...information,
            [name]: date,
            duree: getMonthsBothDate(dateSouscription, date),
          })
        } else {
          setInformation({
            ...information,
            [name]: date,
          })
        }
        break
      default:
        setInformation({ ...information, [name]: date })
    }
    setInformation({ ...information, [name]: date })
  }
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e) {
      if (e.target !== undefined) {
        const { name, value } = e.target
        switch (name) {
          case 'duree':
            if (dateSouscription !== '') {
              setInformation({
                ...information,
                [name]: value,
                dateFin: getDateEnd(dateSouscription, value),
              })
            } else {
              setInformation({
                ...information,
                [name]: value,
              })
            }
            break
          default:
            setInformation({ ...information, [name]: value })
        }
      } else {
        const name = selectName.name
        const value = e.value
        switch (name) {
          case 'categorie':
            setToggleSubCategory(false)
            setInformation({ ...information, [name]: value, sousCategorie: null })
            break

          case 'divers':
            setDivers(value)
            break
          default:
            setInformation({ ...information, [name]: value })
        }
      }
    } else {
      const name = selectName.name
      setInformation({ ...information, [name]: '' })
    }
  }
  useEffect(() => {
    categorie !== '' && setToggleSubCategory(true)
  }, [categorie])
  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (categorie !== '' && sousCategorie !== '' && assureur !== '') {
      actifsDivers === 0 ? handleSubmit() : setToggleDivers(true)
    } else {
      setToggleNotCorrect(true)
    }
  }
  // submit
  const handleSubmit = () => {
    dispatch(postSocial('socialPrevoyance', person.id, isPP, divers, data, setStatus))
    setToggleDivers(false)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (bien) {
      navigate('/user/patrimoine/actifs/social/prevoyance/informations')
    } else {
      navigate('/user/patrimoine/actifs/social/prevoyance')
    }
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status) {
      if (status < 400) {
        navigate('/user/patrimoine/actifs/social/prevoyance')
      }
      setStatus(null)
    }
  }, [status])
  // maj detenteurs
  // useEffect(() => {
  //   if (detenteursToDisplay.length > 0) {
  //     setInformation({ ...information, detenteurs: detenteursToDisplay })
  //   }
  // }, [detenteursToDisplay])
  // recuperation patrimoine
  useEffect(() => {
    if (social && bien) {
      const newBien = social.produitsPrevoyance.filter((el) => el.id === bien.id)
      dispatch(getBienSuccess(newBien[0]))
    }
  }, [social])

  return (
    <div className="section__content">
      {/* form */}
      <FormPrevoyanceContent
        bienToDisplay={bien}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        information={information}
        setInformation={setInformation}
        divers={divers}
        detenteursToDisplay={detenteursToDisplay}
        setDetenteursToDisplay={setDetenteursToDisplay}
        fichiersToDisplay={fichiersToDisplay}
        setFichiersToDisplay={setFichiersToDisplay}
        setToggleAddPatrimoine={setToggleAddPatrimoine}
        handleSubmit={handlePreSubmit}
        newValeurTotale={newValeurTotale}
        handleGiveUp={handleGiveUp}
        toggleSubCategory={toggleSubCategory}
      />
      {/* modales */}
      {toggleAddPatrimoine && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_SOCIAL_PREVOYANCE}
          setModal={setToggleAddPatrimoine}
          setDataChange={setDataChange}
        />
      )}
      {toggleAddNotCorrect && <NotCorrect setNotCorrect={setToggleNotCorrect} />}
      {toggleDivers && (
        <ModaleDivers
          setToggleDivers={setToggleDivers}
          handleChange={handleChange}
          divers={divers}
          setDivers={setDivers}
          handleSubmit={handleSubmit}
          montantCategorieDivers={actifsDivers}
          type={'actif'}
        />
      )}
    </div>
  )
}

export default FormPrevoyance
