import React from 'react'
import Input from '../../../../Components/Input'
import DateInputComponent from '../../../../Components/DateInputComponent'

const FormOperationInputs = ({
  handlechange,
  newOperation,
  fondEuro,
  handleChangeDate,
  total,
  setTotal,
  categorie,
}) => {
  const { achat, dateOperation, support, montant, dateVersement, nombre, valeurAchat } =
    newOperation || {}
  const { dateAchat } = support || {}

  return (
    <>
      {!achat && (categorie === 'AV' || categorie === 'retraite') ? (
        <div className="informations informations--borderFull">
          {/* retrait total */}
          <Input
            forAndId="total"
            type="check"
            check={true}
            name={fondEuro ? 'Retrait total :' : 'Cession totale :'}
            value={total}
            setValue={setTotal}
          />
        </div>
      ) : null}
      <div className="informations informations--borderFull">
        {fondEuro ? (
          <>
            {/* dateAchat */}
            <DateInputComponent
              name={'dateAchat'}
              label={achat ? 'Date du versement :' : 'Date du retrait :'}
              value={dateAchat}
              handleChange={handleChangeDate}
            />
            {/* valeurAchat */}
            <Input
              forAndId="valeurAchat"
              type="text"
              name={'Montant (€) :'}
              value={valeurAchat}
              onChange={(e) => handlechange(e)}
              inputMode="decimal"
              required={true}
              disabled={total}
            />
          </>
        ) : (
          <>
            {/* nombre */}
            <Input
              forAndId="nombre"
              type="text"
              name="Quantité :"
              value={nombre}
              onChange={(e) => handlechange(e)}
              required={true}
              copy={true}
              inputMode="decimal"
            />
            <DateInputComponent
              name={'dateAchat'}
              label={achat ? "Date d'achat :" : 'Date de vente :'}
              value={dateAchat}
              handleChange={handleChangeDate}
            />
            {/* valeurAchat */}
            <Input
              forAndId="valeurAchat"
              type="text"
              name="Prix unitaire (€) :"
              value={valeurAchat}
              onChange={(e) => handlechange(e)}
              required={true}
              copy={true}
              inputMode="decimal"
            />
          </>
        )}
        {/* montant */}
        {!fondEuro ? (
          <Input
            forAndId="montant"
            type="text"
            name="Montant (€) :"
            value={montant}
            onChange={(e) => handlechange(e)}
            required={true}
            copy={true}
            disabled={true}
            inputMode="decimal"
          />
        ) : null}
        {/* dateOperation || dateVersement */}
        {!fondEuro ? (
          <DateInputComponent
            name={'dateOperation'}
            label={"Date de l'opération :"}
            value={dateOperation}
            handleChange={handleChangeDate}
          />
        ) : (
          <DateInputComponent
            name={'dateVersement'}
            label={"Date de l'opération :"}
            value={dateVersement}
            handleChange={handleChangeDate}
          />
        )}
      </div>
    </>
  )
}

export default FormOperationInputs
