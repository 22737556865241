import { RiDashboardLine } from 'react-icons/ri'
import { FaUsers, FaUsersCog, FaClipboardList } from 'react-icons/fa'

export const links = [
    {
      link: '/admin/dashboard',
      icon: <RiDashboardLine />,
      txt: 'Tableau de bord',
    },
    {
      link: '/admin/utilisateurs',
      icon: <FaUsers />,
      txt: 'Gestion des utilisateurs',
    },
    {
      link: '/admin/cabinets',
      icon: <FaClipboardList />,
      txt: 'Gestion des cabinets',
    },
    {
      link: '/admin/conseillers',
      icon: <FaUsersCog />,
      txt: 'Gestion des conseillers',
    },
  ]
