import React from 'react'

const BodyDettePrint = ({ element, index }) => {
  return (
    <>
      {/* refDette */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Réference</p>
        <p className="print__table__bloc__line--value">
          {!element.refDette ? '-' : element.refDette}
        </p>
      </div>
      {/* montantRestant */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Restant dû</p>
        <p className="print__table__bloc__line--value">
          {!element.montantRestant
            ? '-'
            : `${element.montantRestant.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
    </>
  )
}

export default BodyDettePrint
