import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import { getFinancier } from '../../../../../../services/API/financier'
import classNames from 'classnames'
import Loader from '../../../../../layout/Loader'
import usePersonData from '../../../../../layout/usePersonData'
import useCustomPrint from '../../../../../layout/useCustomPrint'
import PrintComponent from '../../../../Components/PrintComponent'
import ButtonsRow from '../../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../../Patrimoine/PatrimoineChartCard'
import PFPieChart from './PFPieChart'
import PFCard from './PFCard'
import PFToPrint from './Print/PFToPrint'
import LittleLoader from '../../../../../layout/LittleLoader'

const PFHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const financier = useSelector((state) => state.getFinancier.financier)
  const { comptesBancaires } = financier || {}
  // recharge
  useEffect(() => {
    if (!financier) {
      dispatch(getFinancier(person?.id, isPP))
    }
  }, [financier, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () => comptesBancaires.filter((bien) => bien.clos === true),
    [comptesBancaires]
  )
  const dataToDisplayNotSold = useMemo(
    () => comptesBancaires.filter((bien) => bien.clos === false),
    [comptesBancaires]
  )
  // redirections
  const formLink = '/user/patrimoine/actifs/financier/produits-financiers/ajouter'
  // maj bien
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  const totalPF = useMemo(
    () => comptesBancaires.map((el) => (!el.clos ? el.solde : 0)).reduce((sum, el) => sum + el, 0),
    [comptesBancaires]
  )
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!financier) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <PFToPrint
              elementToDisplay={comptesBancaires}
              totalPF={totalPF}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            <PatrimoineValueCard totalAmount={totalPF} />
            {totalPF > 0 ? (
              <PatrimoineChartCard children={<PFPieChart comptesBancaires={comptesBancaires} />} />
            ) : null}
          </div>
          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <PFCard
              data={dataToDisplayNotSold}
              handleAddPF={() => navigate(formLink)}
              sold={false}
              title={`En cours (${dataToDisplayNotSold.length})`}
            />
            {dataToDisplaySold.length > 0 ? (
              <PFCard
                data={dataToDisplaySold}
                handleAddPF={() => navigate(formLink)}
                sold={true}
                title={`Historique (${dataToDisplaySold.length})`}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default PFHome
