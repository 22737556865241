import React, { useEffect, useMemo, useState } from 'react'
import Pagination from './Pagination'
import Table from './Table'
import TableFilters from './TableFilters'
import useSearch from './useSearch'
import {
  DOC_PERSON_BIRTH,
  DOC_PERSON_DIVERS,
  DOC_PERSON_FAMILY,
  DOC_PERSON_HOME,
  DOC_PERSON_IDENTITY,
  DOC_PERSON_PATRIMOINE,
  FE,
  PP_ADDRESS,
  PRODUIT_OPERATIONS,
  PRODUIT_PORTEFEUILLE,
  SUPPORTS_BUY,
  SUPPORTS_DELETE,
  SUPPORTS_INFOS,
  SUPPORTS_SELECT,
  USER_MESSAGES,
  USER_SIGNATURES_CLASSER,
  USER_SIGNATURES_INSTANCE,
} from '../../../constants'
import { useSelector } from 'react-redux'
import { MdAdd } from 'react-icons/md'

const BlocTable = ({
  titles,
  data,
  setData,
  setDataChange,
  doc,
  inputSearch,
  entry,
  noLineNumber,
  children,
  tableType,
  read,
  title,
  handleAdd,
}) => {
  // person
  // const person = useSelector((state) => state.getPerson.person)
  const personType = useSelector((state) => state.postPersonType.type)
  // data
  const [dataToDisplay, setDataToDisplay] = useState(data)
  const [resultToDisplay, setResultToDisplay] = useState(dataToDisplay)
  // states
  const [targetToSort, setTargetToSort] = useState(null)
  const [sort, setSort] = useState(false)
  const [search, setSearch] = useState('')
  const [entries, setEntries] = useState(entry ? entry : 5)
  const [page, setPage] = useState(1)
  const [fondEuro, setFondEuro] = useState(true)
  const [uniteCompte, setUniteCompte] = useState(true)
  const [createdSupport, setCreatedSupport] = useState(true)
  const [catalogueSupport, setCatalogueSupport] = useState(true)
  // data to display
  const filterMapping = {
    [DOC_PERSON_IDENTITY]: (el) => el.typeFichierGED === 'IDENTITE',
    [DOC_PERSON_HOME]: (el) => el.typeFichierGED === 'DOMICILE',
    [DOC_PERSON_BIRTH]: (el) => el.typeFichierGED === 'NAISSANCE',
    [DOC_PERSON_FAMILY]: (el) => el.typeFichierGED === 'FAMILLE' || el.typeFichierGED === 'MARIAGE',
    [DOC_PERSON_PATRIMOINE]: (el) => el.typeFichierGED === 'PATRIMOINE',
    [DOC_PERSON_DIVERS]: (el) => el.typeFichierGED === 'DIVERS',
  }
  useEffect(() => {
    const filterFunction = filterMapping[tableType]
    if (filterFunction) {
      setDataToDisplay(data.filter(filterFunction))
    } else {
      setDataToDisplay(data)
    }
  }, [data, tableType])

  useEffect(() => {
    if (
      tableType === SUPPORTS_BUY ||
      tableType === SUPPORTS_DELETE ||
      tableType === SUPPORTS_INFOS ||
      tableType === SUPPORTS_SELECT ||
      tableType === PRODUIT_PORTEFEUILLE
    ) {
      if (fondEuro) {
        if (uniteCompte) {
          if (createdSupport) {
            setDataToDisplay(
              catalogueSupport
                ? data // tout
                : data.filter((el) =>
                    tableType === PRODUIT_PORTEFEUILLE
                      ? el.dici
                        ? el.dici.nonCote
                        : el
                      : el.nonCote
                  )
            )
          } else {
            setDataToDisplay(
              catalogueSupport
                ? data.filter((el) =>
                    tableType === PRODUIT_PORTEFEUILLE
                      ? el.dici
                        ? !el.dici.nonCote
                        : el
                      : !el.nonCote
                  )
                : [] // rien
            )
          }
        } else {
          if (createdSupport) {
            setDataToDisplay(
              catalogueSupport
                ? data.filter((el) =>
                    tableType === PRODUIT_PORTEFEUILLE
                      ? el.dici
                        ? el.dici.c_nom === FE
                        : el
                      : el.c_nom === FE
                  )
                : data.filter((el) =>
                    tableType === PRODUIT_PORTEFEUILLE
                      ? el.dici
                        ? el.dici.c_nom === FE && el.dici.nonCote
                        : el
                      : el.c_nom === FE && el.nonCote
                  )
            )
          } else {
            setDataToDisplay(
              catalogueSupport
                ? data.filter((el) =>
                    tableType === PRODUIT_PORTEFEUILLE
                      ? el.dici
                        ? el.dici.c_nom === FE && !el.dici.nonCote
                        : el
                      : el.c_nom === FE && !el.nonCote
                  )
                : [] // rien
            )
          }
        }
      } else {
        if (uniteCompte) {
          if (createdSupport) {
            setDataToDisplay(
              catalogueSupport
                ? data.filter((el) =>
                    tableType === PRODUIT_PORTEFEUILLE
                      ? el.dici
                        ? el.dici.c_nom !== FE
                        : el.dateDernierCours
                      : el.c_nom !== FE
                  )
                : data.filter((el) =>
                    tableType === PRODUIT_PORTEFEUILLE
                      ? el.dici
                        ? el.dici.c_nom !== FE && el.dici.nonCote
                        : el.dateDernierCours
                      : el.c_nom !== FE && el.nonCote
                  )
            )
          } else {
            setDataToDisplay(
              catalogueSupport
                ? data.filter((el) =>
                    tableType === PRODUIT_PORTEFEUILLE
                      ? el.dici
                        ? el.dici.c_nom !== FE && !el.dici.nonCote
                        : el.dateDernierCours
                      : el.c_nom !== FE && !el.nonCote
                  )
                : [] // rien
            )
          }
        } else {
          setDataToDisplay([]) // rien
        }
      }
      setPage(1)
    }
  }, [data, fondEuro, uniteCompte, createdSupport, catalogueSupport])
  // page
  useEffect(() => {
    setPage(1)
  }, [targetToSort])
  const division = useMemo(
    () => (entries !== 'MAX' ? dataToDisplay.length / entries : 1),
    [dataToDisplay, entries]
  )
  const pageCount = useMemo(() => Math.ceil(division), [division])
  // array of number of pages
  let pageCountRange = []
  for (let i = 0; i < pageCount; i++) {
    pageCountRange.push(i)
  }
  const lastDataOnPage = useMemo(
    () => (entries !== 'MAX' ? page * entries : page * dataToDisplay.length),
    [entries, page, dataToDisplay]
  )
  const firstDataOnPage = useMemo(
    () => (entries !== 'MAX' ? lastDataOnPage - entries : dataToDisplay[0]),
    [entries, page, dataToDisplay]
  )
  // search
  const searchedData = useSearch(dataToDisplay, search)
  useEffect(() => {
    if (search.length < 3) {
      setResultToDisplay(dataToDisplay)
    } else {
      setResultToDisplay(searchedData)
    }
  }, [dataToDisplay, search, searchedData])
  // data list to display on current page
  const listToDisplay = resultToDisplay.slice(firstDataOnPage, lastDataOnPage)
  // number of entries to display
  const length = resultToDisplay.length

  return data.length ? (
    <div className="blocTable">
      {inputSearch === undefined ? (
        <TableFilters
          data={data}
          dataToDisplay={dataToDisplay}
          setDataTodisplay={setDataToDisplay}
          listToDisplay={listToDisplay}
          tableType={tableType}
          children={children}
          read={read}
          title={title}
          entries={entries}
          setEntries={setEntries}
          search={search}
          setSearch={setSearch}
          setPage={setPage}
          handleAdd={handleAdd}
          setFondEuro={setFondEuro}
          setUniteCompte={setUniteCompte}
          setCreatedSupport={setCreatedSupport}
          setCatalogueSupport={setCatalogueSupport}
          fondEuro={fondEuro}
          uniteCompte={uniteCompte}
          createdSupport={createdSupport}
          catalogueSupport={catalogueSupport}
        />
      ) : null}
      {length ? (
        <>
          <Table
            data={data}
            dataToDisplay={dataToDisplay}
            setDataTodisplay={setDataToDisplay}
            listToDisplay={listToDisplay}
            children={children}
            tableType={tableType}
            read={read}
            titles={titles}
            setData={setData}
            sort={sort}
            setSort={setSort}
            targetToSort={targetToSort}
            setTargetToSort={setTargetToSort}
            setDataChange={setDataChange}
            noLineNumber={noLineNumber}
            doc={doc}
          />
          <div className="bonusEnd">
            <Pagination
              page={page}
              setPage={setPage}
              pageCount={pageCount}
              pageCountRange={pageCountRange}
            />
          </div>
        </>
      ) : (
        <p className="noResult">Aucun résultat</p>
      )}
    </div>
  ) : personType === 'relationPP' ? (
    <p>Rien à afficher</p>
  ) : !read &&
    tableType !== USER_MESSAGES &&
    tableType !== PP_ADDRESS &&
    tableType !== SUPPORTS_INFOS &&
    tableType !== SUPPORTS_SELECT &&
    tableType !== SUPPORTS_DELETE &&
    tableType !== SUPPORTS_BUY &&
    tableType !== PRODUIT_PORTEFEUILLE &&
    tableType !== PRODUIT_OPERATIONS &&
    tableType !== DOC_PERSON_PATRIMOINE &&
    tableType !== USER_SIGNATURES_INSTANCE &&
    tableType !== USER_SIGNATURES_CLASSER ? (
    <div className="informations--center">
      <button onClick={handleAdd} className="button--print button--print--add">
        <MdAdd />
      </button>
    </div>
  ) : null
}

export default BlocTable
