import React from 'react'
import { shortEffectif } from '../../../../services/helpers/helpers'
import { beautifulDate } from '../../../../services/helpers/dates'

const BodyEtablissements = ({ element }) => {
  return (
    <>
      {/* siret */}
      <td className="tbody__tr__td" data-label="Siret">
        {!element.siret ? (
          <p className="informations__line__notxt">-</p>
        ) : element.siret_formate ? (
          element.siret_formate
        ) : (
          element.siret
        )}
      </td>
      {/* nic */}
      <td className="tbody__tr__td" data-label="NIC">
        {!element.nic ? <p className="informations__line__notxt">-</p> : element.nic}
      </td>
      {/* adresse_ligne_1 */}
      <td className="tbody__tr__td" data-label="Adresse">
        {!element.adresse_ligne_1 ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.adresse_ligne_1
        )}
      </td>
      {/* adresse_ligne_2 */}
      <td className="tbody__tr__td" data-label="Complément">
        {!element.adresse_ligne_2 ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.adresse_ligne_2
        )}
      </td>
      {/* code_postal */}
      <td className="tbody__tr__td" data-label="Code postal">
        {!element.code_postal ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.code_postal
        )}
      </td>
      {/* ville */}
      <td className="tbody__tr__td" data-label="Ville">
        {!element.ville ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.ville
        )}
      </td>
      {/* pays */}
      <td className="tbody__tr__td" data-label="Pays">
        {!element.pays ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.pays
        )}
      </td>
      {/* code_pays */}
      <td className="tbody__tr__td" data-label="Code pays">
        {!element.code_pays ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.code_pays
        )}
      </td>
      {/* code_naf */}
      <td className="tbody__tr__td" data-label="Code NAF">
        {!element.code_naf ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.code_naf
        )}
      </td>
      {/* libelle_code_naf */}
      <td className="tbody__tr__td" data-label="Libellé code NAF">
        {!element.libelle_code_naf ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.libelle_code_naf
        )}
      </td>
      {/* etablissement_employeur */}
      <td className="tbody__tr__td" data-label="Établissement employeur">
        {element.etablissement_employeur === '' ? (
          <p className="informations__line__notxt">-</p>
        ) : element.etablissement_employeur === true ? (
          'oui'
        ) : (
          'non'
        )}
      </td>
      {/* effectif */}
      <td className="tbody__tr__td" data-label="Effectif">
        {!element.effectif ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          shortEffectif(element.effectif)
        )}
      </td>
      {/* effectif_min */}
      <td className="tbody__tr__td" data-label="Effectif min">
        {!element.effectif_min ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.effectif_min
        )}
      </td>
      {/* effectif_max */}
      <td className="tbody__tr__td" data-label="Effectif max">
        {!element.effectif_max ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.effectif_max
        )}
      </td>
      {/* date_de_creation */}
      <td className="tbody__tr__td" data-label="Date de création">
        {!element.date_de_creation ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.date_de_creation)
        )}
      </td>
      {/* etablissement_cesse */}
      <td className="tbody__tr__td" data-label="Cessation">
        {element.etablissement_cesse === '' ? (
          <p className="informations__line__notxt">-</p>
        ) : element.etablissement_cesse === true ? (
          'oui'
        ) : (
          'non'
        )}
      </td>
      {/* date_cessation */}
      <td className="tbody__tr__td" data-label="Date de cessation">
        {!element.date_cessation ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.date_cessation)
        )}
      </td>
      {/* siege */}
      <td className="tbody__tr__td" data-label="Siège">
        {element.siege === '' ? (
          <p className="informations__line__notxt">-</p>
        ) : element.siege === true ? (
          'oui'
        ) : (
          'non'
        )}
      </td>
      {/* enseigne */}
      <td className="tbody__tr__td" data-label="Enseigne">
        {!element.enseigne ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.enseigne
        )}
      </td>
      {/* nom_commercial */}
      <td className="tbody__tr__td" data-label="Nom commercial">
        {!element.nom_commercial ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.nom_commercial
        )}
      </td>
    </>
  )
}

export default BodyEtablissements
