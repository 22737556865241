// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
import {
  getCatalogueCommunError,
  getCatalogueCommunLoad,
  getCatalogueCommunSuccess,
} from '../../actions/Patrimoine/actionGetCatalogueCommun'
// actions imports

// REDUCER // ______________________________________________________________

// initial state
const initialStateCatalogueCommun = {
  isLoading: false,
  catalogue: null,
  error: '',
}
// reducer
export const getCatalogueCommunReducer = createReducer(initialStateCatalogueCommun, (builder) => {
  return builder
    .addCase(getCatalogueCommunLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getCatalogueCommunSuccess, (draft, action) => {
      draft.isLoading = false
      draft.catalogue = action.payload
      draft.error = ''
      return
    })
    .addCase(getCatalogueCommunError, (draft, action) => {
      draft.isLoading = false
      draft.catalogue = null
      draft.error = action.payload
      return
    })
})
