import React from 'react'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import { optionsTransaction } from '../../../../../../data/options/optionsFinancier'
import { beautifulDate } from '../../../../../../services/helpers/dates'

const BodyTransactions = ({ element }) => {
  return (
    <>
      {/* nature */}
      <td className="tbody__tr__td">{recupLabel(parseInt(element.code), optionsTransaction)}</td>
      {/* montant */}
      <td className="tbody__tr__td">
        {!element.montant ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
        )}
      </td>
      {/* dateTransaction */}
      <td className="tbody__tr__td">
        {!element.dateTransaction ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.dateTransaction)
        )}
      </td>
    </>
  )
}

export default BodyTransactions
