import React, { useEffect, useState } from 'react'
import useFormIsActive from '../../../../layout/useFormIsActive'
import { NavLink } from 'react-router-dom'
import SaveForm from '../../../../layout/SaveForm'

const NavProduct = ({ categorie }) => {
  const formIsActive = useFormIsActive()
  // modales
  const [toggleSaveForm, setToggleSaveForm] = useState(false)
  // cat
  const [cat, setCat] = useState(null)
  // redirection
  useEffect(() => {
    switch (categorie) {
      case 'VM':
        setCat('valeurs-mobilieres')
        break
      case 'AV':
        setCat('assurances-vie')
        break
      case 'PER':
        setCat('PER')
        break
      case 'retraite':
        setCat('retraite')
        break
      default:
        return
    }
  }, [])

  // handleClick
  const handleClick = () => {
    formIsActive && setToggleSaveForm(true)
  }

  return (
    <>
      <nav onClick={() => handleClick()}>
        <ul
          className={`card__title card__title__menu ${
            (categorie === 'AV' || categorie === 'retraite') && 'card__title__menu--AV'
          }`}
        >
          <li>
            {formIsActive ? (
              <div className={'card__title__menu__item card__title__menu__item--greyInactive'}>
                <h5>Produit</h5>
              </div>
            ) : (
              <NavLink
                to={
                  categorie === 'PER' || categorie === 'retraite'
                    ? `/user/patrimoine/actifs/social/${cat}/produit/produit`
                    : `/user/patrimoine/actifs/financier/${cat}/produit/produit`
                }
                className={({ isActive }) =>
                  isActive
                    ? 'card__title__menu__item card__title__menu__item--isActive'
                    : 'card__title__menu__item card__title__menu__item--isNotActive'
                }
              >
                <h5>Produit</h5>
              </NavLink>
            )}
          </li>

          {categorie !== 'AV' && categorie !== 'retraite' ? (
            <li>
              {formIsActive ? (
                <div className={'card__title__menu__item card__title__menu__item--greyInactive'}>
                  <h5>Transactions</h5>
                </div>
              ) : (
                <NavLink
                  to={
                    cat === 'PER'
                      ? `/user/patrimoine/actifs/social/${cat}/produit/transactions`
                      : `/user/patrimoine/actifs/financier/${cat}/produit/transactions`
                  }
                  className={({ isActive }) =>
                    isActive
                      ? 'card__title__menu__item card__title__menu__item--isActive'
                      : 'card__title__menu__item card__title__menu__item--isNotActive'
                  }
                >
                  <h5>Transactions</h5>
                </NavLink>
              )}
            </li>
          ) : null}

          <li>
            {formIsActive ? (
              <div className={'card__title__menu__item card__title__menu__item--greyInactive'}>
                <h5>Opérations</h5>
              </div>
            ) : (
              <NavLink
                to={
                  categorie === 'PER' || categorie === 'retraite'
                    ? `/user/patrimoine/actifs/social/${cat}/produit/operations`
                    : `/user/patrimoine/actifs/financier/${cat}/produit/operations`
                }
                className={({ isActive }) =>
                  isActive
                    ? 'card__title__menu__item card__title__menu__item--isActive'
                    : 'card__title__menu__item card__title__menu__item--isNotActive'
                }
              >
                <h5>Opérations</h5>
              </NavLink>
            )}
          </li>

          <li>
            {formIsActive ? (
              <div className={'card__title__menu__item card__title__menu__item--greyInactive'}>
                <h5>Portefeuille</h5>
              </div>
            ) : (
              <NavLink
                to={
                  categorie === 'PER' || categorie === 'retraite'
                    ? `/user/patrimoine/actifs/social/${cat}/produit/portefeuille`
                    : `/user/patrimoine/actifs/financier/${cat}/produit/portefeuille`
                }
                className={({ isActive }) =>
                  isActive
                    ? 'card__title__menu__item card__title__menu__item--isActive'
                    : 'card__title__menu__item card__title__menu__item--isNotActive'
                }
              >
                <h5>Portefeuille</h5>
              </NavLink>
            )}
          </li>
        </ul>
      </nav>
      {toggleSaveForm && (
        <SaveForm toggleSaveForm={toggleSaveForm} setToggleSaveForm={setToggleSaveForm} />
      )}
    </>
  )
}

export default NavProduct
