// IMPORTS // ______________________________________________________________
import { createReducer } from '@reduxjs/toolkit'
import { connectionFailed, connectionRestored } from '../actions/actionConnexionServeur'
// actions imports

// REDUCER // ______________________________________________________________

// initial state
const initialState = {
  isConnected: true,
  lastAttemptFailed: false,
}

// reducer
export const getConnectionServeurReducer = createReducer(initialState, (builder) => {
  return builder
    .addCase(connectionFailed, (draft) => {
      draft.isConnected = false
      draft.lastAttemptFailed = true
    })
    .addCase(connectionRestored, (draft) => {
        draft.isConnected = true
        draft.lastAttemptFailed = false
      })
})
