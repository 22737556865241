import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { links } from '../../../data/adminLinks'
import BackToTheTop from '../../layout/BackToTheTop'
import Header from '../../layout/Header'
import Nav from '../../layout/Nav'

const Admin = () => {
  const selectUser = (state) => state.getUser.user
  const user = useSelector(selectUser)

  let role
  if (user) {
    let roles = user.roles
    if (roles.find((el) => el.id === 2)) {
      role = 2
    } else if (roles.find((el) => el.id !== 2 && el.id === 3)) {
      role = 3
    } else {
      role = 1
    }
  }

  if (!user || role === 1 || role === 3) {
    return <Navigate to="/" replace />
  } else {
    return (
      <div>
        <Header links={links} />
        <main className="main">
          <Nav links={links} />
          <BackToTheTop />
          <Outlet />
        </main>
      </div>
    )
  }
}

export default Admin
