import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFormIsActive } from '../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../redux/actions/actionSaveForm'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { getPersonnes, postPM, updatePM } from '../../../services/API/personnes'
import { noTwice } from '../../../services/helpers/helpers'
import PMComponent from './PMComponent'
import usePersonData from '../../layout/usePersonData'

const PM = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const persons = useSelector((state) => state.getPersons.persons)
  const { person, personType } = usePersonData()
  // persons
  const personToDisplay = useMemo(() => (pathname.includes('comptes') ? null : person), [person])
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [submit, setSubmit] = useState(false)
  const [dataChange, setDataChange] = useState(false)
  // user créateur
  const user = useSelector((state) => state.getUser.user)
  // response
  const [statusPostPM, setStatusPostPM] = useState(null)
  const [statusUpdatePM, setStatusUpdatePM] = useState(null)
  // données formulaire
  const [sirenSearch, setSirenSearch] = useState('')
  const [pappers, setPappers] = useState(null)
  // Informations générales
  const initialStateMainData = {
    denominationSociale: '',
    formeJuridique: '',
    dateCreation: '',
    mail: '',
    // dirigeant: '',
    // date_naissance_dirigeant: '',
  }
  const [infosPrincipales, setInfosPrincipales] = useState(initialStateMainData)
  const { id, denominationSociale, formeJuridique, dateCreation, mail } = infosPrincipales || {}
  // Dirigeant
  const initialStateBoss = {
    dirigeant: '',
    date_naissance_dirigeant: '',
  }
  const [infoBoss, setInfoBoss] = useState(initialStateBoss)
  const { dirigeant, date_naissance_dirigeant } = infoBoss || {}
  // Société
  const initialStateCompany = {
    activite: '',
    capital: '',
    siren: '',
    telFixe: '',
    telPortable: '',
    adresse1: '',
    codePostal: '',
    ville: '',
  }
  const [infosSociete, setInfosSociete] = useState(initialStateCompany)
  const { capital, activite, siren, telPortable, telFixe, adresse1, codePostal, ville } =
    infosSociete
  // Bénéficiaires effectifs
  const [beneficiairesEffectifs, setBeneficiairesEffectifs] = useState([])

  // Etablissements
  const [etablissements, setEtablissements] = useState([])
  // Fichiers
  const [fichiers, setFichiers] = useState([])
  // modales
  const [modalOk, setModalOk] = useState(false)
  const [modalNotOk, setModalNotOk] = useState(false)
  const [modalUpdateOk, setModalUpdateOk] = useState(false)
  const [modalUpdateNotOk, setModalUpdateNotOk] = useState(false)
  const [modalNotCorrect, setModalNotCorrect] = useState(false)
  // maj person
  useEffect(() => {
    if (!pathname.includes('informations')) {
      dispatch(getPerson(null))
    }
  }, [])
  // maj
  useEffect(() => {
    if (persons && personType) {
      const newPerson = persons[personType].filter((el) => el.id === person.id)
      // dispatch(getPerson(newPerson[0]))
      setFichiers(newPerson[0].fichiers)
    }
  }, [persons])
  // Object to send
  const data = {
    id: id === undefined ? 0 : id,
    denominationSociale: denominationSociale && denominationSociale.toUpperCase(),
    formeJuridique: formeJuridique,
    dateCreation: dateCreation,
    mail: mail,
    dirigeant: dirigeant && dirigeant.toUpperCase(),
    date_naissance_dirigeant: date_naissance_dirigeant,
    capital: capital,
    activite: activite,
    siren: siren.toLocaleString(),
    telFixe: telFixe,
    telPortable: telPortable,
    adresse1: adresse1,
    codePostal: codePostal,
    ville: ville,
    beneficiairesEffectifs: beneficiairesEffectifs,
    etablissements: etablissements,
    user_id: {
      email: user.email,
    },
    fichiers: fichiers,
  }
  // form PM is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'PM' }))
    }
  }, [dataChange])
  // save PM
  useEffect(() => {
    if (saveBien && formIsActive.form === 'PM') {
      handleSubmit()
    }
  }, [saveBien])
  // champs préremplis si person selectionnée
  useEffect(() => {
    if (personToDisplay) {
      // setPappers(pappers)
      setInfosPrincipales({
        ...infosPrincipales,
        id: personToDisplay.id ? personToDisplay.id : 0,
        denominationSociale: personToDisplay.denominationSociale
          ? personToDisplay.denominationSociale
          : denominationSociale,
        formeJuridique: personToDisplay.formeJuridique
          ? personToDisplay.formeJuridique
          : formeJuridique,
        dateCreation: personToDisplay.dateCreation ? personToDisplay.dateCreation : dateCreation,
        mail: personToDisplay.mail ? personToDisplay.mail : mail,
        user_id: {
          email: user.email,
        },
      })
      setInfoBoss({
        ...infoBoss,
        dirigeant: personToDisplay.dirigeant ? personToDisplay.dirigeant : dirigeant,
        date_naissance_dirigeant: personToDisplay.date_naissance_dirigeant
          ? personToDisplay.date_naissance_dirigeant
          : date_naissance_dirigeant,
      })
      setInfosSociete({
        ...infosSociete,
        activite: personToDisplay.activite ? personToDisplay.activite : activite,
        capital: personToDisplay.capital ? personToDisplay.capital : capital,
        siren: personToDisplay.siren
          ? personToDisplay.siren.toLocaleString()
          : siren.toLocaleString(),
        telFixe: personToDisplay.telFixe ? personToDisplay.telFixe : telFixe,
        telPortable: personToDisplay.telPortable ? personToDisplay.telPortable : telPortable,
        adresse1: personToDisplay.adresse1 ? personToDisplay.adresse1 : adresse1,
        codePostal: personToDisplay.codePostal ? personToDisplay.codePostal : codePostal,
        ville: personToDisplay.ville ? personToDisplay.ville : ville,
      })
      setSirenSearch(personToDisplay.siren ? personToDisplay.siren : siren)
      setBeneficiairesEffectifs(
        personToDisplay.beneficiairesEffectifs && [...personToDisplay.beneficiairesEffectifs]
      )
      setEtablissements(personToDisplay.etablissements && [...personToDisplay.etablissements])
      setFichiers([...personToDisplay.fichiers])
    } else {
      setInfosPrincipales(initialStateMainData)
      setInfosSociete(initialStateCompany)
      setSirenSearch('')
      setBeneficiairesEffectifs([])
      setEtablissements([])
      setFichiers([])
    }
    if (beneficiairesEffectifs !== undefined && beneficiairesEffectifs.length > 0) {
      setBeneficiairesEffectifs(noTwice(beneficiairesEffectifs))
    }
    if (etablissements !== undefined && etablissements.length > 0) {
      setBeneficiairesEffectifs(noTwice(etablissements))
    }
  }, [personToDisplay])
  // champs préremplis si pappers
  useEffect(() => {
    if (pappers) {
      setPappers(pappers)
      setInfosPrincipales({
        ...infosPrincipales,
        denominationSociale: pappers.denominationSociale
          ? pappers.denominationSociale
          : denominationSociale,
        formeJuridique: pappers.formeJuridique ? pappers.formeJuridique : formeJuridique,
        dateCreation: pappers.dateCreation ? pappers.dateCreation : dateCreation,
        mail: pappers.mail ? pappers.mail : mail,
      })
      setInfoBoss({
        ...infoBoss,
        dirigeant: pappers.dirigeant ? pappers.dirigeant : dirigeant,
        date_naissance_dirigeant: pappers.date_naissance_dirigeant
          ? pappers.date_naissance_dirigeant
          : date_naissance_dirigeant,
      })
      setInfosSociete({
        ...infosSociete,
        activite: pappers.activite ? pappers.activite : activite,
        capital: pappers.capital ? pappers.capital : capital,
        siren: pappers.siren ? pappers.siren.toLocaleString() : siren.toLocaleString(),
        telFixe: pappers.telFixe ? pappers.telFixe : telFixe,
        telPortable: pappers.telPortablel ? pappers.telPortable : telPortable,
        adresse1: pappers.adresse1 ? pappers.adresse1 : adresse1,
        codePostal: pappers.codePostal ? pappers.codePostal : codePostal,
        ville: pappers.ville ? pappers.ville : ville,
      })
      setBeneficiairesEffectifs([
        ...beneficiairesEffectifs,
        pappers.beneficiairesEffectifs.map((el) => beneficiairesEffectifs.push(el)),
      ])
      setEtablissements([
        ...etablissements,
        pappers.etablissements.map((el) => etablissements.push(el)),
      ])
    }
    if (beneficiairesEffectifs.length > 0) {
      setBeneficiairesEffectifs(noTwice(beneficiairesEffectifs))
    }
    if (etablissements.length > 0) {
      setEtablissements(noTwice(etablissements))
    }
  }, [pappers])

  // submit
  const handleSubmit = (e) => {
    e && e.preventDefault()
    if (denominationSociale !== '' && formeJuridique !== '' && dateCreation !== '' && mail !== '') {
      if (!personToDisplay) {
        dispatch(postPM(data, setStatusPostPM))
      } else {
        dispatch(updatePM(data, setStatusUpdatePM))
      }
    } else {
      setModalNotCorrect(true)
    }
    setSubmit(true)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (personToDisplay) {
      navigate('/user/informations')
    } else {
      navigate('/user/comptes')
    }
  }

  // response create PM status and modal
  useEffect(() => {
    if (statusPostPM) {
      if (statusPostPM < 400) {
        setModalOk(true)
        dispatch(getFormIsActive(null))
        dispatch(getSaveForm(false))
        dispatch(getPersonnes())
        setStatusPostPM(null)
      }
      if (statusPostPM >= 400 && submit) {
        setModalNotOk(true)
        setStatusPostPM(null)
      }
    }
  }, [statusPostPM, submit])
  // response update PM status and modal
  useEffect(() => {
    if (statusUpdatePM >= 200 && statusUpdatePM < 300) {
      setModalUpdateOk(true)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
      dispatch(getPersonnes())
      setStatusUpdatePM(null)
    }
    if (statusUpdatePM >= 400 && submit) {
      setModalUpdateNotOk(true)
      setStatusUpdatePM(null)
    }
  }, [statusUpdatePM, submit])

  return (
    <PMComponent
      person={personToDisplay}
      handleSubmit={handleSubmit}
      sirenSearch={sirenSearch}
      setSirenSearch={setSirenSearch}
      pappers={pappers}
      setPappers={setPappers}
      infosPrincipales={infosPrincipales}
      setInfosPrincipales={setInfosPrincipales}
      infoBoss={infoBoss}
      setInfoBoss={setInfoBoss}
      infosSociete={infosSociete}
      setInfosSociete={setInfosSociete}
      beneficiairesEffectifs={beneficiairesEffectifs}
      setBeneficiairesEffectifs={setBeneficiairesEffectifs}
      etablissements={etablissements}
      setEtablissements={setEtablissements}
      // fichiers={fichiers}
      // setFichiers={setFichiers}
      modalOk={modalOk}
      modalNotOk={modalNotOk}
      setModalNotOk={setModalNotOk}
      modalUpdateOk={modalUpdateOk}
      modalUpdateNotOk={modalUpdateNotOk}
      setModalUpdateNotOk={setModalUpdateNotOk}
      modalNotCorrect={modalNotCorrect}
      setModalNotCorrect={setModalNotCorrect}
      setSubmit={setSubmit}
      dataChange={dataChange}
      setDataChange={setDataChange}
      handleGiveUp={handleGiveUp}
    />
  )
}

export default PM
