import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { shortFormeJuridique } from '../../../services/helpers/helpers'
import { standardizeAndLowerCase } from '../../../services/helpers/strings'
import Input from '../Components/Input'
import useSearch from '../table/useSearch'

const Search = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const persons = useSelector((state) => state.getPersons.persons)
  const [search, setSearch] = useState('')
  const [dataToDisplay, setDataTodisplay] = useState([])

  const personsToDisplay = useMemo(() => Object.values(persons).flat(), [persons]) // search
  let dataSearch = useSearch(personsToDisplay, search)
  useEffect(() => {
    setDataTodisplay(search.length < 3 ? [] : dataSearch)
  }, [search])

  // statut
  const findStatus = (mail) => {
    for (const type in persons) {
      if (persons[type].some((el) => el.mail === mail)) {
        return type
      }
    }
    return ''
  }
  // open informations
  const openPersonInfos = (person) => {
    dispatch(getPerson(person))
    const personType = findStatus(person.mail)
    dispatch(postPersonType(personType))
    navigate('/user/informations')
  }

  return (
    <div className="search informations">
      <Input
        forAndId="recherche"
        name="Recherche :"
        type="text"
        value={search}
        onChange={(e) => setSearch(standardizeAndLowerCase(e.target.value))}
        placeholder="Rechercher"
      />
      {dataToDisplay.length > 0 && (
        <ul className="informations--border">
          {dataToDisplay.map((data, index) => (
            <li
              key={index}
              className="informations__item informations__item--click"
              onClick={() => openPersonInfos(data)}
            >
              <div className="informations__item__txt">
                <p>{index + 1}</p>
                <p>
                  {data.nom
                    ? `${data.nom} ${data.prenom}`
                    : `${shortFormeJuridique(data.formeJuridique)} /  ${
                        data.denominationSociale
                      } / ${data.dirigeant}`}
                </p>
                <p>{findStatus(data.mail)}</p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Search
