import React from 'react'
import InformationsCardPrint from '../../../../../Informations/InformationsCardPrint'
import {
  dataSocialPrevoyanceGeneral,
  dataSocialPrevoyanceTerms,
  dataSocialPrevoyanceValue,
} from '../../../../../../../data/informations/social'
import BienInfoPrintDetenteurEtDoc from '../../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'
import { DOC_SOCIAL_PREVOYANCE } from '../../../../../../../constants'

const PrevoyanceInfoPrint = ({ elementToDisplay }) => {
  const { detenteurs, fichiers } = elementToDisplay || {}
  return (
    <>
      <InformationsCardPrint
        data={dataSocialPrevoyanceGeneral(elementToDisplay)}
        title={elementToDisplay.libelle}
      />
      <InformationsCardPrint
        data={dataSocialPrevoyanceTerms(elementToDisplay)}
        title={'Modalités du contrat'}
      />
      <InformationsCardPrint
        data={dataSocialPrevoyanceValue(elementToDisplay)}
        title={'Valeur du contrat'}
      />
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={DOC_SOCIAL_PREVOYANCE}
      />
    </>
  )
}

export default PrevoyanceInfoPrint
