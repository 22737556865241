import React from 'react';

const BodyFirms = ({element}) => {
    return (
        <>
            {/* nomCabinet */}
            <td className="tbody__tr__td">
              {!element.nomCabinet ? (
                <p className="informations__line__notxt">-</p>
              ) : (
                element.nomCabinet.toUpperCase()
              )}
            </td>
            {/* adresseCabinet */}
            <td className="tbody__tr__td">
              {!element.adresseCabinet ? (
                <p className="informations__line__notxt">-</p>
              ) : (
                element.adresseCabinet
              )}
            </td>
            {/* cpCabinet */}
            <td className="tbody__tr__td">
              {!element.cpCabinet ? (
                <p className="informations__line__notxt">-</p>
              ) : (
                element.cpCabinet
              )}
            </td>
            {/* villeCabinet */}
            <td className="tbody__tr__td">
              {!element.villeCabinet ? (
                <p className="informations__line__notxt">-</p>
              ) : (
                element.villeCabinet
              )}
            </td>
        </>
    );
};

export default BodyFirms;