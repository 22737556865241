import React from 'react'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import PERPieChart from './PERPieChart'
import TableToPrint from '../../../../table/TableToPrint'
import { PRODUITS_PER } from '../../../../../../constants'
import TableBodyTitres from '../../Catalogue/Table/TableBodyTitres'

const PERToPrint = ({ elementToDisplay, valeurPortefeuilles, valeurLiquidites, values }) => {
  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurPortefeuilles + valeurLiquidites}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<PERPieChart produitsPER={elementToDisplay} />}
      />
      <TableToPrint
        data={elementToDisplay}
        tableType={PRODUITS_PER}
        title={`Produits (${elementToDisplay.length})`}
        children={<TableBodyTitres />}
      />
    </>
  )
}

export default PERToPrint
