import React, { useState } from 'react'
import { dataProduit } from '../../../../../data/informations/financier'
import ModalInformationsCard from './ModalInformationsCard'
import MainInformationsCard from '../../../Components/MainInformationsCard'

const ProductInformationCard = ({
  information,
  setInformation,
  categorieP,
  setDataChange,
}) => {
  // modales
  const [openModalEdit, setOpenModalEdit] = useState(false)

  return (
    <>
      {/* Informations principales */}
      <MainInformationsCard
        title={'Informations principales'}
        h6={true}
        data={dataProduit}
        element={information}
        categorie={categorieP}
        redirection={() => setOpenModalEdit(true)}
      />
      {openModalEdit ? (
        <ModalInformationsCard
          setOpenModalEdit={setOpenModalEdit}
          information={information}
          setInformation={setInformation}
          categorieP={categorieP}
          setDataChange={setDataChange}
        />
      ) : null}
    </>
  )
}

export default ProductInformationCard
