import React from 'react'
import AutoCompleteAdresse from '../../AutoCompleteAddress/AutoCompleteAdresse'
import Card from '../../Components/Card'
import Input from '../../Components/Input'

const InputsSociete = ({ handleChange, infosSociete, setInfosSociete, setDataChange }) => {
  const { activite, capital, siren, telFixe, telPortable } = infosSociete || {}

  return (
    <Card title="Société" open={true}>
      <div className="informations">
        {/* activité */}
        <Input
          forAndId="activite"
          type="text"
          name="Activité :"
          value={activite}
          onChange={(e) => handleChange(e)}
          placeholder="Gestion de patrimoine"
          copy={true}
        />
        {/* capital */}
        <Input
          forAndId="capital"
          type="text"
          name="Capital :"
          value={capital}
          onChange={(e) => handleChange(e)}
          placeholder="25000"
          copy={true}
        />
        {/* siren */}
        <Input
          forAndId="siren"
          type="text"
          name="SIREN :"
          value={siren}
          onChange={(e) => handleChange(e)}
          placeholder="123456789"
          copy={true}
        />
        <h3 className="hr__title">Siège</h3>

        {/* Tel fixe */}

        <Input
          forAndId="telFixe"
          type="tel"
          pattern="[0-9]{10,12}"
          maxLength={12}
          minLength={10}
          name="Téléphone fixe :"
          value={telFixe}
          onChange={(e) => handleChange(e)}
          placeholder="0433221100"
          copy={true}
        />
        {/* Tel Mobile */}
        <Input
          forAndId="telPortable"
          type="tel"
          pattern="[0-9]{10,12}"
          maxLength={12}
          minLength={10}
          name="Téléphone Mobile :"
          value={telPortable}
          onChange={(e) => handleChange(e)}
          placeholder="0655443322"
          copy={true}
        />
        <AutoCompleteAdresse
          societe={true}
          data={infosSociete}
          setData={setInfosSociete}
          setDataChange={setDataChange}
        />
      </div>
    </Card>
  )
}

export default InputsSociete
