import React from 'react'
import Card from '../../../Components/Card'
import BlocTable from '../../../table/BlocTable'
import { PASSIFS_EMPRUNTS } from '../../../../../constants'
import TableBodyEmprunt from './Table/TableBodyEmprunt'

const PassifEmpruntCard = ({ data, handleAddEmprunt, title }) => {
  // titles
  const titles = [
    'N°',
    'Référence',
    'Emprunt',
    'Restant dû',
    'Mensualités',
    'Durée',
    'Début',
    'Fin',
    'Voir',
    'Modifier',
    'Supprimer',
    'Dupliquer',
  ]
  return (
    <Card title={title} h3={true} open={true}>
      <div className="informations">
        <BlocTable
          data={data}
          titles={titles}
          tableType={PASSIFS_EMPRUNTS}
          children={<TableBodyEmprunt />}
          title={title}
          handleAdd={() => handleAddEmprunt()}
        />
      </div>
    </Card>
  )
}

export default PassifEmpruntCard
