import React from 'react'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import TableToPrint from '../../../../table/TableToPrint'
import { PRODUITS_RETRAITE } from '../../../../../../constants'
import RetraitePieChart from './RetraitePieChart'
import TableBodyAssurances from '../../Catalogue/Table/TableBodyAssurances'

const RetraiteToPrint = ({ elementToDisplay, valeurPortefeuilles }) => {
  return (
    <>
      <PatrimoineValueCard totalAmount={valeurPortefeuilles} />
      <PatrimoineChartCardPrint
        children={<RetraitePieChart produitsRetraite={elementToDisplay} />}
      />
      <TableToPrint
        data={elementToDisplay}
        tableType={PRODUITS_RETRAITE}
        title={`Produits (${elementToDisplay.length})`}
        children={<TableBodyAssurances />}
      />
    </>
  )
}

export default RetraiteToPrint
