import React, { useState } from 'react'
import Card from '../../Components/Card'
import BlocTable from '../../table/BlocTable'
import { PM_BENEFICIAIRES } from '../../../../constants'
import TableBodyBeneficiaire from '../Table/TableBodyBeneficiaire'
import ModaleBeneficiaire from '../Modales/ModaleBeneficiaire'

const Beneficiaires = ({ beneficiairesEffectifs, setBeneficiairesEffectifs, setDataChange }) => {
  // modale
  const [modalAdd, setModalAdd] = useState(false)
  // titles
  const title = `Bénéficiaires effectifs (${beneficiairesEffectifs.length})`
  const titles = [
    'N°',
    'Nom',
    'Prénom',
    'Date de naissance',
    'Date greffe',
    'Parts',
    'Pleine propriété',
    'Nue propriété',
    'Modifier',
    'Supprimer',
  ]
  return (
    <>
      <Card title={title} open={true}>
          <div className="informations">
            <BlocTable
              titles={titles}
              data={beneficiairesEffectifs}
              setData={setBeneficiairesEffectifs}
              tableType={PM_BENEFICIAIRES}
              children={<TableBodyBeneficiaire />}
              setDataChange={setDataChange}
              title={title}
              handleAdd={() => setModalAdd(true)}
            />
          </div>
      </Card>
      {/* modal beneficiaire supplémentaire _________________________________*/}
      {modalAdd && (
        <ModaleBeneficiaire
          setModal={setModalAdd}
          data={beneficiairesEffectifs}
          setData={setBeneficiairesEffectifs}
          setDataChange={setDataChange}
        />
      )}
    </>
  )
}

export default Beneficiaires
