import React from 'react'
import { COLORS } from '../../../../../constants'
import PieChartComponent from '../../../Charts/PieChartComponent'
import LegendPieChart from '../../../Charts/LegendPieChart'

const RevenusAutresPieChart = ({ autresRevenus, detenteurs, print }) => {
  const { autreRevenusDivers, nomDetenteurs, valeurDetenteurs, autreRevenus } = autresRevenus || {}
  // colors
  const colors = [
    { fill: COLORS.colorRepartition01, color: 'repartition01' },
    { fill: COLORS.colorRepartition02, color: 'repartition02' },
    { fill: COLORS.colorRepartition03, color: 'repartition03' },
    { fill: COLORS.colorRepartition04, color: 'repartition04' },
    { fill: COLORS.colorRepartition05, color: 'repartition05' },
  ]
  // autres revenus
  const arrayAutresRevenus = autreRevenus
    .filter(
      (revenu) => new Date(revenu.dateFin) >= new Date() || revenu.dateFin === '' || !revenu.dateFin
    )
    .map((revenu) => ({ nom: revenu.libelle, valeur: revenu.montant }))
  arrayAutresRevenus.push({ nom: 'Non affecté', valeur: autreRevenusDivers.montant })
  const dataAutresRevenus = arrayAutresRevenus.map((det, index) =>
    det.nom !== 'Non affecté'
      ? {
          name: det.nom,
          value: det.valeur,
          fill: colors[index % colors.length].fill,
          color: colors[index % colors.length].color,
        }
      : {
          name: det.nom,
          value: det.valeur,
          fill: COLORS.colorFontLight,
          color: 'divers',
        }
  )
  // detenteurs
  const arrayDetenteurs = []
  for (let i = 0; i < nomDetenteurs.length; i++) {
    arrayDetenteurs.push({ nom: nomDetenteurs[i], valeur: valeurDetenteurs[i] })
  }
  arrayDetenteurs.push({ nom: 'Non affecté', valeur: autreRevenusDivers.montant })
  const dataDetenteurs = arrayDetenteurs.map((det) => ({ name: det.nom, value: det.valeur }))

  const data = !detenteurs ? dataAutresRevenus : dataDetenteurs
  // title
  const title = !detenteurs ? 'Répartition' : 'Détenteurs'
  return (
    <>
      <PieChartComponent data={data} title={title} detenteurs={detenteurs} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title} detenteurs={detenteurs} />
    </>
  )
}

export default RevenusAutresPieChart
