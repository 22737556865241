import React from 'react'
import Ok from '../Components/Modales/Ok'

const ModalAddFirmOK = ({ setModalOK, setModalAddFirm, setStatus }) => {
  const handleClick = () => {
    setStatus(null)
    setModalOK(false)
    setModalAddFirm(false)
  }

  return <Ok text={"Vous venez d'ajouter un nouveau cabinet !"} close={handleClick} />
}

export default ModalAddFirmOK
