import React from 'react'
import { REVENUS_FONCIERS } from '../../../../../../constants'
import TableToPrint from '../../../../table/TableToPrint'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import TableBodyRevenu from '../../Revenus/Table/TableBodyRevenu'
import RevenusFonciersPieChart from '../RevenusFonciersPieChart'

const RevenusFonciersToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurRevenusFonciers } = elementToDisplay || {}
  return (
    <>
      <PatrimoineValueCard totalAmount={valeurRevenusFonciers} values={values} />
      <PatrimoineChartCardPrint
        children={<RevenusFonciersPieChart revenusFonciers={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={REVENUS_FONCIERS}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyRevenu />}
      />
      <TableToPrint
        data={dataSold}
        tableType={REVENUS_FONCIERS}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyRevenu />}
      />
    </>
  )
}

export default RevenusFonciersToPrint
