import React from 'react'
import { getLocaleStringCustom } from '../../../../../services/helpers/strings'
import {
  calculValeurDetenue,
  calculateNP,
  calculateUS,
} from '../../../../../services/helpers/detenteurs'

const BodyDetentionPrint = ({ element, index, showUS, newValeurTotale, detenteurs }) => {
  return (
    <>
      {/* nomDetenteur */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">
          {!element.nomDetenteur ? '-' : element.nomDetenteur}
        </p>
      </div>
      {/* tauxPleinePropriete */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">% Pleine propriété</p>
        <p className="print__table__bloc__line--value">{`${element.tauxPleinePropriete} %`}</p>
      </div>
      {showUS ? (
        <>
          {/* tauxUsufruit */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">% Usufruit</p>
            <p className="print__table__bloc__line--value">{`${element.tauxUsufruit} %`}</p>
          </div>
          {/* tauxNuePropriete */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">% Nue propriété</p>
            <p className="print__table__bloc__line--value">{`${element.tauxNuePropriete} %`}</p>
          </div>
        </>
      ) : null}
      {(newValeurTotale || newValeurTotale === 0) && showUS ? (
        <>
          {/* valeur PP */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Valeur PP</p>
            <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
              (newValeurTotale * element.tauxPleinePropriete) / 100
            )} €`}</p>
          </div>
          {/* valeur US */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Valeur US</p>
            <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
              calculateUS(element, newValeurTotale)
            )} €`}</p>
          </div>
          {/* valeur NP */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Valeur NP</p>
            <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
              calculateNP(element, newValeurTotale, detenteurs)
            )} €`}</p>
          </div>
        </>
      ) : null}
      {/* valeurDetenue */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Valeur détenue</p>
        <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
          newValeurTotale
            ? calculValeurDetenue(element, newValeurTotale, detenteurs)
            : element.valeurDetenue
        )} €`}</p>
      </div>
    </>
  )
}

export default BodyDetentionPrint
