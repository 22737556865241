import React from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { isValid } from 'date-fns'
import Modale from './Modale'

const ModaleCalendar = ({ close, checkIsValidDate, value }) => {
  return (
    <Modale calendar={true} close={close}>
      <div className="date__icons__calendar">
        <Calendar
          onChange={(date) => checkIsValidDate(date)}
          value={isValid(value) ? value : ''}
          className={'custom-calendar'}
          onClickDay={close}
        />
      </div>
    </Modale>
  )
}

export default ModaleCalendar
