import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { sortByType } from '../../../services/helpers/sort'
import TableBodyBloc from '../table/TableBodyBloc'
import BodyPM from './BodyPM'
import BodyPMPrint from './BodyPMPrint'

const TableBodyPM = ({
  targetToSort,
  sort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // handleDispatchNewPersonInfos
  const handleDispatchNewPersonInfos = (personne) => {
    dispatch(getPerson(personne))
    // dispatch(getPersonId(personne.id))
    dispatch(postPersonType('dirigeantPM'))
  }
  // ouvrir infos personne
  const handleShow = (personne) => {
    handleDispatchNewPersonInfos(personne)
    navigate('/user/informations')
  }
  // ouvrir form personne
  const handleEdit = (personne) => {
    handleDispatchNewPersonInfos(personne)
    navigate('/user/informations/modifier-Personne-Morale')
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Dénomination sociale':
          giveAType('denominationSociale')
          break
        case 'Forme juridique':
          giveAType('formeJuridique')
          break
        case 'Dirigeant':
          giveAType('dirigeant')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyPMPrint /> : <BodyPM handleShow={handleShow} />}
      handleShow={handleShow}
      handleEdit={handleEdit}
      print={print}
    />
  )
}

export default TableBodyPM
