// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getFinancierError, getFinancierLoad, getFinancierSuccess } from '../../actions/Patrimoine/actionGetFinancier'

// REDUCER // ______________________________________________________________

// initial state
const initialStateFinancier = {
  isLoading: false,
  financier: null,
  error: '',
}
// reducer
export const getFinancierReducer = createReducer(initialStateFinancier, (builder) => {
  return builder
    .addCase(getFinancierLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getFinancierSuccess, (draft, action) => {
      draft.isLoading = false
      draft.financier = action.payload
      draft.error = ''
      return
    })
    .addCase(getFinancierError, (draft, action) => {
      draft.isLoading = false
      draft.financier = null
      draft.error = action.payload
      return
    })
})
