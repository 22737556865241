import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const usePersonData = () => {
  const person = useSelector((state) => state.getPerson.person)
  const personType = useSelector((state) => state.postPersonType.type)
  const isPP = useMemo(() => ['referentPP', 'relationPP'].includes(personType), [personType])
  return { person, personType, isPP }
}

export default usePersonData
