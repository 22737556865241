import React from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '../Components/Card'
import BlocTable from '../table/BlocTable'
import {
  PM_BENEFICIAIRES,
  PM_ETABLISSEMENTS,
  PP_ACTIVITY,
  PP_ADDRESS,
  PP_RELATIONS,
} from '../../../constants'
import TableBodyJob from '../FormPP/Table/TableBodyJob'
import TableBodyAddress from './TableBodyAddress'
import TableBodyBeneficiaire from '../FormPM/Table/TableBodyBeneficiaire'
import TableBodyRelation from '../FormPP/Table/TableBodyRelation'
import TableBodyEtablissement from '../FormPM/Table/TableBodyEtablissement'
import usePersonData from '../../layout/usePersonData'

const InformationsTableCard = ({ title, nothing, data, titles, read, tableType }) => {
  const navigate = useNavigate()
  const { personType, isPP } = usePersonData()
  const redirection = isPP ? 'Personne-Physique' : 'Personne-Morale'
  const returnGoodComponent = () => {
    switch (tableType) {
      case PP_ACTIVITY:
        return <TableBodyJob />
      case PP_ADDRESS:
        return <TableBodyAddress />
      case PP_RELATIONS:
        return <TableBodyRelation />
      case PM_BENEFICIAIRES:
        return <TableBodyBeneficiaire />
      case PM_ETABLISSEMENTS:
        return <TableBodyEtablissement />
      default:
        return
    }
  }
  return (
    <Card title={title} open={true}>
      {data && data.length > 0 ? (
        <div className="informations">
          <BlocTable
            titles={titles}
            data={data}
            tableType={tableType}
            children={returnGoodComponent(tableType)}
            read={read}
            title={title}
          />
        </div>
      ) : (
        <p>{nothing}</p>
      )}
      {personType !== 'relationPP' && tableType !== PP_ADDRESS && (
        <div className="informations__buttons">
          <div
            className="button button--update width--7"
            onClick={() => navigate(`/user/informations/modifier-${redirection}`)}
          >
            Modifier
          </div>
        </div>
      )}
    </Card>
  )
}

export default InformationsTableCard
