import React from 'react'
import Card from '../../../../Components/Card'
import BlocTable from '../../../../table/BlocTable'
import { PRODUITS_FINANCIERS } from '../../../../../../constants'
import TableBodyPF from './Table/TableBodyPF'

const PFCard = ({ data, handleAddPF, sold, title }) => {
  const titles = sold
    ? [
        'N°',
        'Libelle',
        'Descriptif',
        'Numéro',
        'Etablissement',
        'Date de clôture',
        'Voir',
        'Modifier',
        'Supprimer',
        'Dupliquer',
      ]
    : [
        'N°',
        'Libelle',
        'Descriptif',
        'Numéro',
        'Etablissement',
        'Solde',
        'Au...',
        'Voir',
        'Modifier',
        'Supprimer',
        'Dupliquer',
      ]

  return (
    <Card title={title} h3={true} open={true}>
      <div className="informations">
        <BlocTable
          data={data}
          titles={titles}
          tableType={PRODUITS_FINANCIERS}
          children={<TableBodyPF />}
          title={title}
          handleAdd={() => handleAddPF()}
        />
      </div>
    </Card>
  )
}

export default PFCard
