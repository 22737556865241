import React from 'react'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'
import DateInputComponent from '../../../../Components/DateInputComponent'
import SelectComponent from '../../../../Components/SelectComponent'
import { optionsDetention } from '../../../../../../data/options/options'

const GeneralInformationCard = ({ handleChange, handleChangeDate, information }) => {
  const { nom, valeur, dateValeur, detention, tauxIndivision } = information || {}

  return (
    <Card title="Informations générales" h3={true} open={true}>
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* nom */}
          <Input
            forAndId="nom"
            errorLabel={nom === ''}
            type="text"
            name="Libellé :"
            value={nom}
            onChange={(e) => handleChange(e)}
            placeholder="Maison T5 150 m²"
            copy={true}
            required={true}
          />
          {/* valeur */}
          <Input
            forAndId="valeur"
            errorLabel={valeur === '' || valeur === 0}
            type="number"
            name="Valeur estimée :"
            value={valeur}
            onChange={(e) => handleChange(e)}
            required={true}
            copy={true}
          />
        </div>
      </div>
      <div className="informations">
        {/* dateValeur */}
        <DateInputComponent
          name={'dateValeur'}
          label={"Date de l'estimation :"}
          value={dateValeur}
          handleChange={handleChangeDate}
        />
        {/* detention */}
        <SelectComponent
          forAndId="detention"
          errorLabel={detention === ''}
          name="Détention :"
          isSearchable={false}
          options={optionsDetention}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsDetention.find((c) => c.value === detention)}
          required={true}
          copy={true}
        />
        {/* tauxIndivision */}
        <Input
          forAndId="tauxIndivision"
          type="number"
          name="Taux d'indivision :"
          value={tauxIndivision}
          onChange={(e, name) => handleChange(e)}
          copy={true}
        />
      </div>
    </Card>
  )
}

export default GeneralInformationCard
