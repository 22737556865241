import React from 'react'
import { useSelector } from 'react-redux'
import { capitalizeFirstLetter } from '../../../services/helpers/strings'

const BodyPPPrint = ({ element, index }) => {
  // user
  const user = useSelector((state) => state.getUser.user)
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // relation
  const getRelation = (personne) => {
    const titulaire = persons.referentPP.filter((el) => el.mail === user.email)[0]
    const relationsTitulaire = titulaire.relations
    if (relationsTitulaire.find((el) => el.idPP2 === personne.id)) {
      return capitalizeFirstLetter(
        relationsTitulaire.filter((el) => el.idPP2 === personne.id)[0].typeRelation
      )
    } else if (personne === titulaire) {
      return 'Titulaire'
    } else {
      return null
    }
  }

  return (
    <>
      <div className={'print__table__bloc__line'}>
        {/* Nom */}
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">{!element.nom ? '-' : element.nom}</p>
      </div>
      <div className={'print__table__bloc__line'}>
        {/* prenom */}
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Prénom</p>
        <p className="print__table__bloc__line--value">{!element.prenom ? '-' : element.prenom}</p>
      </div>
      <div className={'print__table__bloc__line'}>
        {/* mail */}
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Mail</p>
        <p className="print__table__bloc__line--value">{!element.mail ? '-' : element.mail}</p>
      </div>
      <div className={'print__table__bloc__line'}>
        {/* lien */}
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Lien</p>
        <p className="print__table__bloc__line--value">
          {getRelation(element) ? getRelation(element) : '-'}
        </p>
      </div>
    </>
  )
}

export default BodyPPPrint
