import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import { postRevenu } from '../../../../../../services/API/revenus'
import { dateToCompare, getMonths } from '../../../../../../services/helpers/dates'
import {
  calculateAmountReverse,
  calculateAnnualAmount,
  supprId,
} from '../../../../../../services/helpers/helpers'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../services/helpers/detenteurs'
import ModaleDivers from '../../../Patrimoine/ModaleDivers'
import NotCorrect from '../../../../Components/Modales/NotCorrect'
import Ok from '../../../../Components/Modales/Ok'
import NotOk from '../../../../Components/Modales/NotOk'
import { DOC_REVENU_FONCIER } from '../../../../../../constants'
import ModaleFiles from '../../../../Files/ModaleFiles'
import FormPatrimoineContent from '../../../Patrimoine/FormPatrimoineContent'
import usePersonData from '../../../../../layout/usePersonData'

const FormRevenuFoncier = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const incomes = useSelector((state) => state.getIncomes.incomes)
  const revenusDivers = incomes ? incomes.revenuDivers.montant : null
  const foncierDivers = incomes ? incomes.revenuFoncierDivers.montant : null
  const revenu = useSelector((state) => state.getBien.bien)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddRevenuFoncier, setToggleAddRevenuFoncier] = useState(false)
  const [toggleAddRevenuFoncierOk, setToggleAddRevenuFoncierOk] = useState(false)
  const [toggleAddRevenuFoncierNotOk, setToggleAddRevenuFoncierNotOk] = useState(false)
  const [toggleAddRevenuFoncierNotCorrect, setToggleAddRevenuFoncierNotCorrect] = useState(false)
  const [toggleDivers, setToggleDivers] = useState(false)

  // maj bien
  useEffect(() => {
    if (revenu) {
      setFichiersToDisplay(revenu.fichiers)
    }
  }, [revenu])
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    libelle: '',
    typeRevenuMobilier: 'REVENUMOBILIER',
    detail: '',
    typeRevenu: '',
    montant: 0,
    typePeriodicite: '',
    dateDebut: '',
    dateFin: '',
    detention: 'CLIENT',
    tauxIndivision: 100,
    detenteurs: [],
    fichiers: [],
    typeActif: '',
    idActif: 0,
  })
  const {
    id,
    libelle,
    typeRevenuMobilier,
    detail,
    typeRevenu,
    montant,
    typePeriodicite,
    dateDebut,
    dateFin,
    detention,
    tauxIndivision,
    detenteurs,
    fichiers,
    typeActif,
    idActif,
  } = information || {}
  //
  const [divers, setDivers] = useState(true)
  const [fichiersToDisplay, setFichiersToDisplay] = useState([])
  const [detenteursToDisplay, setDetenteursToDisplay] = useState(detenteurs)
  const [newValeurTotale, setNewValeurTotale] = useState()
  // valeur totale de calcul
  useEffect(() => {
    if (montant === 0 || typePeriodicite === '') {
      setNewValeurTotale(0)
    } else {
      if (!dateFin) {
        setNewValeurTotale((calculateAnnualAmount(montant, typePeriodicite) * tauxIndivision) / 100)
      } else {
        if (dateToCompare(dateFin) >= dateToCompare(new Date())) {
          const months = getMonths(dateFin)
          months > 12
            ? setNewValeurTotale(
                (calculateAnnualAmount(montant, typePeriodicite) * tauxIndivision) / 100
              )
            : setNewValeurTotale(
                (months * (calculateAnnualAmount(montant, typePeriodicite) / 12) * tauxIndivision) /
                  100
              )
        } else {
          setNewValeurTotale(0)
        }
      }
    }
  }, [montant, dateFin, typePeriodicite, tauxIndivision])
  // maj si revenu
  useEffect(() => {
    revenu
      ? setInformation({
          ...information,
          id: revenu.id,
          libelle: revenu.libelle,
          typeRevenuMobilier: revenu.typeRevenuMobilier,
          detail: revenu.detail,
          typeRevenu: revenu.typeRevenu,
          montant: calculateAmountReverse(revenu.montant, revenu.typePeriodicite),
          typePeriodicite: revenu.typePeriodicite,
          dateDebut: revenu.dateDebut,
          dateFin: revenu.dateFin,
          detention: revenu.detention,
          tauxIndivision: revenu.tauxIndivision,
          detenteurs: revenu.detenteurs
          .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
          .reverse(),
              fichiers: revenu.fichiers,
          typeActif: revenu.typeActif,
          idActif: revenu.idActif,
        })
      : setInformation({
          ...information,
          detenteurs: [
            {
              id: 0,
              nomDetenteur:
                personType === 'referentPP'
                  ? `${person.nom} ${person.prenom}`
                  : `${person.denominationSociale}`,
              mailDetenteur: person.mail,
              idPP: personType === 'referentPP' ? 1 : 0,
              idPM: personType === 'dirigeantPM' ? 1 : 0,
              dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
              dateContitutionUsufruit: '',
              dureeDemembrement: '',
              typeDetention: 'PLEINEPROPRIETE',
              tauxPleinePropriete: 100,
              tauxUsufruit: 0,
              tauxUsufruitCalcule: handleCalculateTaux(person.dateNaissance),
              tauxNuePropriete: 0,
              valeurDetenue: 0,
              valeurPP: 0,
              valeurUS: 0,
              valeurNP: 0,
              tempId: Date.now(),
            },
          ],
        })
  }, [])
  // form Revenu Foncier is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Revenu Foncier' }))
    }
  }, [dataChange])
  // save Bien immo
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Revenu Foncier') {
      handlePreSubmit()
    }
  }, [saveBien])
  // Object to send
  const data = {
    id: id,
    libelle: libelle,
    typeRevenuMobilier: typeRevenuMobilier,
    detail: detail,
    typeRevenu: typeRevenu,
    montant: calculateAnnualAmount(montant, typePeriodicite),
    typePeriodicite: typePeriodicite,
    dateDebut: dateDebut,
    dateFin: dateFin,
    detention: detention,
    tauxIndivision: tauxIndivision,
    detenteurs:
      detenteursToDisplay &&
      detenteursToDisplay.map((det) => updateDetenteurs(det, newValeurTotale, detenteursToDisplay)),
    fichiers: fichiers,
    typeActif: typeActif,
    idActif: idActif,
  }
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    if (name === 'dateDebut' && typePeriodicite === 'UNIQUE') {
      setInformation({ ...information, [name]: date, dateFin: date })
    } else {
      setInformation({ ...information, [name]: date })
    }
  }
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      setInformation({ ...information, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      switch (name) {
        case 'divers':
          setDivers(value)
          break
        case 'typePeriodicite':
          if (value === 'UNIQUE' && dateDebut) {
            setInformation({ ...information, [name]: value, dateFin: dateDebut })
          } else {
            setInformation({ ...information, [name]: value })
          }
          break
        default:
          setInformation({ ...information, [name]: value })
      }
    }
  }
  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (libelle !== '' && detail !== '' && typePeriodicite !== '' && montant !== '') {
      setDetenteursToDisplay(
        detenteursToDisplay.map((det) => (det.newDetenteur ? supprId(det) : det))
      )
      revenusDivers === 0 && foncierDivers === 0 ? handleSubmit() : setToggleDivers(true)
    } else {
      setToggleAddRevenuFoncierNotOk(true)
    }
  }
  // submit
  const handleSubmit = () => {
    dispatch(postRevenu('revenuFoncier', person.id, isPP, divers, data, setStatus))
    setToggleDivers(false)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (revenu && revenu.id !== 0) {
      navigate('/user/patrimoine/revenus/foncier/info')
    } else {
      navigate('/user/patrimoine/revenus/foncier')
    }
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setStatus(null)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
      setToggleAddRevenuFoncierOk(true)
    }
    if (status >= 400) {
      setStatus(null)
      setToggleAddRevenuFoncierNotOk(true)
    }
  }, [status])
  // maj detenteurs
  // useEffect(() => {
  //   if (detenteursToDisplay.length > 0) {
  //     setInformation({ ...information, detenteurs: detenteursToDisplay })
  //   }
  // }, [detenteursToDisplay])

  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setToggleAddRevenuFoncierOk(false)
    navigate('/user/patrimoine/revenus/foncier')
  }
  // maj
  useEffect(() => {
    if (incomes && revenu) {
      const newBien = incomes.revenusFonciers.revenuFoncier.filter((el) => el.id === revenu.id)
      dispatch(getBienSuccess(newBien[0]))
    }
  }, [incomes])

  return (
    <div className="section__content">
      {/* form */}
      <FormPatrimoineContent
        dataToDisplay={revenu}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        information={information}
        setInformation={setInformation}
        detenteursToDisplay={detenteursToDisplay}
        setDetenteursToDisplay={setDetenteursToDisplay}
        fichiersToDisplay={fichiersToDisplay}
        setFichiersToDisplay={setFichiersToDisplay}
        setToggleAdd={setToggleAddRevenuFoncier}
        handleSubmit={handlePreSubmit}
        newValeurTotale={newValeurTotale}
        handleGiveUp={handleGiveUp}
        tableType={DOC_REVENU_FONCIER}
        foncier={true}
      />
      {/* modales */}
      {toggleAddRevenuFoncier && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_REVENU_FONCIER}
          setModal={setToggleAddRevenuFoncier}
          setDataChange={setDataChange}
        />
      )}
      {toggleAddRevenuFoncierOk && (
        <Ok
          text={
            revenu
              ? 'Votre revenu a été modifié avec succès'
              : 'Votre revenu a été ajouté avec succès'
          }
          close={closeToggleAddBien}
        />
      )}
      {toggleAddRevenuFoncierNotOk && (
        <NotOk setNotOK={setToggleAddRevenuFoncierNotOk} closeModale={closeToggleAddBien} />
      )}
      {toggleAddRevenuFoncierNotCorrect && (
        <NotCorrect setNotCorrect={setToggleAddRevenuFoncierNotCorrect} />
      )}
      {toggleDivers && (
        <ModaleDivers
          setToggleDivers={setToggleDivers}
          handleChange={handleChange}
          divers={divers}
          setDivers={setDivers}
          handleSubmit={handleSubmit}
          montantCategorieDivers={revenusDivers}
          montantSousCategorieDivers={foncierDivers}
          type={'revenus'}
          sousType={'foncier'}
        />
      )}
    </div>
  )
}

export default FormRevenuFoncier
