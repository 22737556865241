import React from 'react'
import PatrimoineValueCard from '../../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../../Patrimoine/PatrimoineChartCardPrint'
import PrevoyancePieChart from '../PrevoyancePieChart'
import TableToPrint from '../../../../../table/TableToPrint'
import TableBodyPrevoyance from '../Table/TableBodyPrevoyance'
import { PREVOYANCE } from '../../../../../../../constants'

const PrevoyanceToPrint = ({ elementToDisplay, totalPrevoyance, dataNotSold, dataSold }) => {
  return (
    <>
      <PatrimoineValueCard totalAmount={totalPrevoyance} />
      <PatrimoineChartCardPrint
        children={<PrevoyancePieChart produitsPrevoyance={elementToDisplay} />}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={PREVOYANCE}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyPrevoyance />}
      />
      <TableToPrint
        data={dataSold}
        tableType={PREVOYANCE}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyPrevoyance />}
      />
    </>
  )
}

export default PrevoyanceToPrint
