import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import { postFinancier } from '../../../../../../services/API/financier'
import { getCatalogues } from '../../../../../../services/API/catalogue'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../services/helpers/detenteurs'
import { supprId } from '../../../../../../services/helpers/helpers'
import FormSubmit from '../../../../Components/FormSubmit'
import NotCorrect from '../../../../Components/Modales/NotCorrect'
import DetentionCard from '../../../Detention/DetentionCard'
import AVAddProductPageContent from './AVAddProductPageContent'
import usePersonData from '../../../../../layout/usePersonData'

const AddProductPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const [optionsProducts, setOptionsProducts] = useState(null)
  const catalogues = useSelector((state) => state.getCatalogues.catalogues)
  const [cataloguesToDisplay, setCataloguesToDisplay] = useState(catalogues)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // responses
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddNotCorrect, setToggleNotCorrect] = useState(false)
  // maj catalogues
  useEffect(() => {
    !catalogues && dispatch(getCatalogues())
  }, [])
  useEffect(() => {
    if (catalogues) {
      setCataloguesToDisplay(catalogues.filter((catalogue) => catalogue.categorie === 'AV'))
    }
  }, [catalogues])
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    libelle: '',
    fournisseur: '',
    numeroContrat: '',
    dateEffet: '',
    beneficiaire: 'par défaut',
    detenteurs: [],
    operations: [],
    fichiers: [],
    montantTotalCalcule: 0,
    portefeuille: {},
    historiquesFrais: [],
    idCatalogue: 0,
    tauxFraisGestionUC: 0,
    tauxFraisGestionFE: 0,
  })
  const {
    id,
    libelle,
    numeroContrat,
    fournisseur,
    dateEffet,
    beneficiaire,
    detenteurs,
    operations,
    fichiers,
    montantTotalCalcule,
    portefeuille,
    historiquesFrais,
    tauxFraisGestionUC,
    tauxFraisGestionFE,
    idCatalogue,
  } = information || {}
  const [histoFrais, setHistoFrais] = useState({
    id: 0,
    annee: '',
    tauxPerformanceFE: 0,
  })
  const { annee } = histoFrais || {}
  const [detenteursToDisplay, setDetenteursToDisplay] = useState(detenteurs)
  // maj det
  useEffect(() => {
    setInformation({
      ...information,
      detenteurs: [
        {
          id: 0,
          nomDetenteur:
            personType === 'referentPP'
              ? `${person.nom} ${person.prenom}`
              : `${person.denominationSociale}`,
          mailDetenteur: person.mail,
          idPP: personType === 'referentPP' ? 1 : 0,
          idPM: personType === 'dirigeantPM' ? 1 : 0,
          dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
          dateContitutionUsufruit: '',
          dureeDemembrement: '',
          typeDetention: 'PLEINEPROPRIETE',
          tauxPleinePropriete: 100,
          tauxUsufruit: 0,
          tauxUsufruitCalcule: handleCalculateTaux(person.dateNaissance),
          tauxNuePropriete: 0,
          valeurDetenue: 0,
          valeurPP: 0,
          valeurUS: 0,
          valeurNP: 0,
          tempId: Date.now(),
        },
      ],
    })
  }, [])
  // form produit AV is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Produit AV' }))
    }
  }, [dataChange])
  // save produit AV
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Produit AV') {
      handlePreSubmit()
    }
  }, [saveBien])
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])
  // maj detenteurs
  // useEffect(() => {
  //   if (detenteursToDisplay.length > 0) {
  //     setInformation({ ...information, detenteurs: detenteursToDisplay })
  //   }
  // }, [detenteursToDisplay])
  // dataChange
  useEffect(() => {
    if (libelle !== '' && fournisseur !== '') {
      setDataChange(true)
    } else {
      setDataChange(false)
    }
  }, [libelle, fournisseur])

  // Object to send
  const data = {
    id: id,
    libelle: libelle,
    numeroContrat: numeroContrat,
    fournisseur: fournisseur,
    dateEffet: dateEffet,
    beneficiaire: beneficiaire,
    detenteurs:
      detenteursToDisplay &&
      detenteursToDisplay.map((det) =>
        updateDetenteurs(det.newDetenteur ? supprId(det) : det, 0, detenteursToDisplay)
      ),
    fichiers: fichiers,
    montantTotalCalcule: montantTotalCalcule,
    operations: operations,
    portefeuille: portefeuille,
    idCatalogue: idCatalogue,
    historiquesFrais: annee !== '' ? [...historiquesFrais, histoFrais] : historiquesFrais,
    tauxFraisGestionUC: tauxFraisGestionUC,
    tauxFraisGestionFE: tauxFraisGestionFE,
  }
  // optionsProducts
  useEffect(() => {
    if (cataloguesToDisplay) {
      setOptionsProducts(
        cataloguesToDisplay.map((product) => ({
          value: product,
          label: product.libelle,
        }))
      )
    }
  }, [cataloguesToDisplay])
  // handleChange
  const handleChange = (e, selectName) => {
    if (e) {
      if (e.target !== undefined) {
        const { name, value } = e.target
        setInformation({ ...information, [name]: value })
      } else {
        if (selectName.name === 'libelle') {
          setInformation({
            ...information,
            [selectName.name]: e.label,
            fournisseur: e.value.etablissement,
            idCatalogue: e.value.id,
            tauxFraisGestionUC: e.value.fraisGestion,
          })
        } else {
          setInformation({
            ...information,
            [selectName.name]: e.value,
          })
        }
      }
    } else {
      setInformation({
        ...information,
        libelle: '',
        fournisseur: '',
        tauxFraisGestionUC: 0,
        idCatalogue: 0,
      })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setInformation({ ...information, [name]: date })
  }
  // handleChangeFrais
  const handleChangeFrais = (e, selectName) => {
    if (e) {
      if (e.target !== undefined) {
        const { name, value } = e.target
        setHistoFrais({ ...histoFrais, [name]: value })
      } else {
        setHistoFrais({
          ...histoFrais,
          [selectName.name]: e.value,
        })
      }
    }
  }

  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (libelle !== '') {
      handleSubmit()
    } else {
      setToggleNotCorrect(true)
    }
  }
  // handleSubmit
  const handleSubmit = () => {
    dispatch(postFinancier('produitFinancierAV', person.id, isPP, true, data, setStatus))
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    navigate('/user/patrimoine/actifs/financier/assurances-vie')
  }
  useEffect(() => {
    if (status) {
      if (status < 400) {
        navigate('/user/patrimoine/actifs/financier/assurances-vie')
      }
      setStatus(null)
    }
  }, [status])

  return (
    <div className="section__content">
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handlePreSubmit}
        handleGiveUp={handleGiveUp}
        down={false}
      />
      <div className="sousSection">
        <AVAddProductPageContent
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
          handleChangeFrais={handleChangeFrais}
          optionsProducts={optionsProducts}
          information={information}
          histoFrais={histoFrais}
        />
        <div className={'cards cards--fullWidth'}>
          <DetentionCard
            information={information}
            setInformation={setInformation}
            detenteursToDisplay={detenteursToDisplay}
            setDetenteursToDisplay={setDetenteursToDisplay}
            setDataChange={setDataChange}
            newValeurTotale={0}
            showUS={true}
          />
        </div>
      </div>
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handlePreSubmit}
        handleGiveUp={handleGiveUp}
        down={true}
      />
      {/* modales */}
      {toggleAddNotCorrect && <NotCorrect setNotCorrect={setToggleNotCorrect} />}
    </div>
  )
}

export default AddProductPage
