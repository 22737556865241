import React from 'react'
import Card from '../../../Components/Card'
import BlocTable from '../../../table/BlocTable'
import { ACTIFS_PRO } from '../../../../../constants'
import TableBodyBienPro from './Table/TableBodyBienPro'

const PatrimoineProCard = ({ data, handleAddPro, title }) => {
  // titles
  const titles = [
    'N°',
    'Nom',
    'Valeur',
    'Catégorie',
    'Nature',
    'Montant détenu',
    'Voir',
    'Modifier',
    'Supprimer',
    'Dupliquer',
  ]
  return (
    <Card title={title} h4={true} open={true}>
      <div className="informations">
        <BlocTable
          data={data}
          titles={titles}
          tableType={ACTIFS_PRO}
          children={<TableBodyBienPro />}
          title={title}
          handleAdd={handleAddPro}
        />
      </div>
    </Card>
  )
}

export default PatrimoineProCard
