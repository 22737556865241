import React from 'react'
import { optionsFormeJuridique } from '../../../../../../data/options/optionsPP'
import AutoCompleteAdresse from '../../../../AutoCompleteAddress/AutoCompleteAdresse'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'
import SelectComponent from '../../../../Components/SelectComponent'

const SocietyInformationCardBienPro = ({
  handleChange,
  information,
  setInformation,
  setDataChange,
}) => {
  const { denominationSociale, formeJuridique, siret } = information || {}

  return (
    <Card title="Société" h4={true} open={true}>
      <div className="informations">
        {/* denominationSociale */}
        <Input
          forAndId="denominationSociale"
          type="text"
          name="Dénomination Sociale :"
          value={denominationSociale}
          onChange={(e) => handleChange(e)}
          placeholder="C@D"
          copy={true}
        />
        {/* Forme juridique */}
        <SelectComponent
          forAndId="formeJuridique"
          name="Forme Juridique :"
          options={optionsFormeJuridique}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsFormeJuridique.find((c) => c.value === formeJuridique)}
          copy={true}
        />
        {/* siret */}
        <Input
          forAndId="siret"
          type="text"
          name="SIRET :"
          value={siret}
          onChange={(e) => handleChange(e)}
          placeholder="1234567890000"
          pattern="[0-9]{13}"
          maxLength={13}
          minLength={9}
          copy={true}
        />

        {/* adresse, codePostal, ville */}
        <AutoCompleteAdresse
          data={information}
          setData={setInformation}
          bienImmo={true}
          setDataChange={setDataChange}
        />
      </div>
    </Card>
  )
}

export default SocietyInformationCardBienPro
