import React from 'react'
import { useNavigate } from 'react-router-dom'
import Ok from '../../Components/Modales/Ok'

const ModalCreatePMOk = () => {
  const navigate = useNavigate()
  const handleClose = () => {
    navigate('/user/comptes')
  }
  return <Ok text={'Vous venez de créer une nouvelle Personnes Morale'} close={handleClose} />
}

export default ModalCreatePMOk
