import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import logo from '../../assets/cap-mep-logo-couleur-contour.webp'
import { stringToCapitalize } from '../../services/helpers/strings'
import { MdOutlinePowerSettingsNew } from 'react-icons/md'
import { CgMenu } from 'react-icons/cg'
import Burger from './Burger'
import Modale from '../components/Components/Modale'
import { ACCESS_TOKEN } from '../../constants'
import { useDispatch } from 'react-redux'
import { resetStore } from '../../redux/actions/actionResetStore'

const HeaderActive = ({ commit, nom, prenom, links }) => {
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [toggle, setToggle] = useState(false)
  // handleClickLogout
  const handleClickLogout = () => {
    sessionStorage.removeItem(ACCESS_TOKEN)
    sessionStorage.removeItem('identifiant')
    dispatch(resetStore())
    return <Navigate to="/" replace />
  }

  return (
    <>
      <Link to="/" className="header__link">
        <div className="header__logo">
          <img src={logo} alt="logo Mon Espace Patrimoine" width={48} />
        </div>
        <p className="header__title">Mon Espace Patrimoine</p>
      </Link>
      {/* début partie à supprimer en prod */}
      <div>
        <p style={{ color: 'white', fontSize: '12px', padding: '0 .5rem' }}>
          {commit}
        </p>
      </div>
      {/* fin partie à supprimer en prod */}
      <div className="header__bloc">
        <p className="header__bloc__hello">
          Bonjour {stringToCapitalize(prenom)} {nom.toUpperCase()}{' '}
        </p>
        <div className="header__bloc__icon" onClick={() => setModal(!modal)}>
          <MdOutlinePowerSettingsNew />
        </div>
        <div className="header__bloc__icon" onClick={() => setToggle(!toggle)}>
          <CgMenu />
        </div>
      </div>
      {toggle && <Burger links={links} toggle={toggle} setToggle={setToggle} />}
      {modal && (
        <Modale
          close={() => setModal(false)}
          title="Attention"
          line1="Souhaitez vous vraiment vous déconnecter ?"
        >
          <div className="informations__buttons">
            <div className="button button--blue-red width--7" onClick={() => handleClickLogout()}>
              OUI
            </div>
            <div className="button button--blue-red width--7" onClick={() => setModal(false)}>
              NON
            </div>
          </div>
        </Modale>
      )}
    </>
  )
}

export default HeaderActive
