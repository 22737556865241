import React from 'react';
import { beautifulDate } from '../../../services/helpers/dates';

const BodyMessagesPrint = ({element, index}) => {
    return (
        <>
        <div className={'print__table__bloc__line'}>
          {/* denominationSociale */}
          <div className="print__table__bloc__line--number">{index + 1}</div>
          <p className="print__table__bloc__line--title">Date</p>
          <p className="print__table__bloc__line--value">
            {!element.createdAt ? '-' : beautifulDate(element.createdAt)}
          </p>
        </div>
        <div className={'print__table__bloc__line'}>
          {/* formeJuridique */}
          <div className="print__table__bloc__line--number"></div>
          <p className="print__table__bloc__line--title">Message</p>
          <p className="print__table__bloc__line--value">
            {!element.message ? '-' : element.message}
          </p>
        </div>
        <div className={'print__table__bloc__line'}>
          {/* dirigeant */}
          <div className="print__table__bloc__line--number"></div>
          <p className="print__table__bloc__line--title">Origine</p>
          <p className="print__table__bloc__line--value">
            {!element.origineMessage ? '-' : element.origineMessage}
          </p>
        </div>
      </>
      );
};

export default BodyMessagesPrint;