import React from 'react'
import { stringToCapitalize } from '../../../../../../services/helpers/strings'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import { optionsNatureBienPro, optionsTypePro } from '../../../../../../data/options/optionsActifs'

const BodyBienPro = ({ element, handleShow }) => {
  return (
    <>
      {/* nom */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.nom ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          stringToCapitalize(element.nom)
        )}
      </td>
      {/* valeur */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.valeur && element.valeur !== 0 ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.valeur.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
        )}
      </td>
      {/* typeBienPro */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.typeBienPro ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          recupLabel(element.typeBienPro, optionsTypePro)
        )}
      </td>
      {/* nature */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.nature ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          recupLabel(element.nature, optionsNatureBienPro)
        )}
      </td>
      {/* montantDetenu */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.montantDetenu ? (
          <p className="informations__line__notxt">{element.montantDetenu.toLocaleString()} €</p>
        ) : (
          `${element.montantDetenu.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
        )}
      </td>
    </>
  )
}

export default BodyBienPro
