import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BlocTable from '../../../table/BlocTable'
import { PRODUIT_OPERATIONS } from '../../../../../constants'
import TableBodyOperations from './Table/TableBodyOperations'

const OperationsComponent = ({ categorie }) => {
  const navigate = useNavigate()
  // financier
  const financier = useSelector((state) => state.getFinancier.financier)
  // social
  const social = useSelector((state) => state.getSocial.social)
  // bien
  const bien = useSelector((state) => state.getBien.bien)
  const bienToDisplay = useMemo(
    () =>
      bien && (financier || social)
        ? categorie === 'VM'
          ? financier.produitsVM.filter((el) => el.id === bien.id)[0]
          : categorie === 'AV'
          ? financier.produitsAV.filter((el) => el.id === bien.id)[0]
          : categorie === 'PER'
          ? social.produitsPER.filter((el) => el.id === bien.id)[0]
          : social.produitsRetraite.filter((el) => el.id === bien.id)[0]
        : null,
    [financier, social, bien]
  )
  // cat
  const cat =
    categorie === 'VM'
      ? 'valeurs-mobilieres'
      : categorie === 'AV'
      ? 'assurances-vie'
      : categorie === 'PER'
      ? 'PER'
      : 'retraite'
  //redirection
  useEffect(() => {
    !bien &&
      navigate(
        `/user/patrimoine/actifs/${
          categorie === 'PER' || categorie === 'retraite' ? 'social' : 'financier'
        }/${cat}`
      )
  }, [bien])

  // operations
  const calculOperations = (bien) => {
    return categorie === 'AV' || categorie === 'retraite'
      ? bien.liquidite
      : bien.operations.map((operation) => operation.montant).reduce((acc, curr) => acc + curr)
  }
  const titles = [
    'N°',
    'Nom',
    'ISIN',
    'Nature',
    'Montant',
    'Quantité',
    "Valeur d'achat",
    "Date d'achat",
    "Date de l'opération",
    'Supprimer',
  ]

  return (
    <>
      <div className="informations">
        {bienToDisplay && bienToDisplay.operations.length > 0 ? (
          <>
            <div className="customHead">
              <div className="customHead--values">
                {categorie === 'VM' || categorie === 'PER' ? (
                  <p>
                    Disponibilités :{' '}
                    <span>{` ${bienToDisplay.portefeuille.totalLiquidite.toLocaleString(undefined, {
                      minimumFractionDigits: 4,
                    })} €`}</span>
                  </p>
                ) : null}
                <p>
                  Opérations :
                  <span>{` ${calculOperations(bienToDisplay).toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} €`}</span>
                </p>
              </div>
            </div>
            <BlocTable
              data={bienToDisplay.operations}
              titles={titles}
              tableType={PRODUIT_OPERATIONS}
              children={<TableBodyOperations />}
              title={'Operations'}
            />
          </>
        ) : (
          <p>Aucune opération à afficher</p>
        )}
      </div>
    </>
  )
}

export default OperationsComponent
