// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions getBientype
 * @reduxAction
 */

// export const getBientype = createAction('getbienType')
export const getbienType = createAction('get-bien-type', (type) => {
  return {
    payload: type,
  }
})
