import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { optionsTrueOrFalse } from '../../../data/options/options'
import { getMandatSuccess } from '../../../redux/actions/actionGetMandat'
import { getUserMailSuccess } from '../../../redux/actions/actionGetUserMail'
import { getMandataire, postMandat } from '../../../services/API/connexion'
import { recupReferentPPUser } from '../../../services/helpers/helpers'
import { getPersonnes } from '../../../services/API/personnes'
import Input from '../Components/Input'
import Modale from '../Components/Modale'
import SelectComponent from '../Components/SelectComponent'

const ModalMandat = ({
  setToggleAddMandat,
  setToggleAddDroits,
  setToggleMailNotExist,
  setToggleMandatOk,
  setToggleMandatNotOk,
  url,
  setUrl,
  proc,
  droits,
}) => {
  const dispatch = useDispatch()
  // user connecté
  const user = useSelector((state) => state.getUser.user)
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // user mandataire
  const mandataire = useSelector((state) => state.getUserMail.mandataire)
  //
  const [mail, setMail] = useState('')
  const [change, setChange] = useState(false)
  const [statusMail, setStatusMail] = useState(null)
  const [statusMandat, setStatusMandat] = useState(null)

  const [mandat, setMandat] = useState({
    userDelegataire: mandataire ? mandataire : null,
    ppDelegue: recupReferentPPUser(user.email, persons.referentPP),
    procuration: proc ? true : false,
    information: false,
    document: false,
    patrimoine: false,
  })
  const { userDelegataire, ppDelegue, procuration, information, document, patrimoine } = mandat || {}
  // dataToSend
  const data = {
    userDelegataire: userDelegataire,
    ppDelegue: ppDelegue,
    procuration: procuration,
    information: information,
    document: document,
    patrimoine: patrimoine,
  }
  // button active
  useEffect(() => {
    if (mail !== undefined && mail !== '') {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [mail])
  // handlechange
  const handleChange = (e, selectName) => {
    const name = selectName.name
    const value = e.value
    setMandat({ ...mandat, [name]: value })
  }
  // envoyer = verification du mail
  const handleValidate = () => {
    if (mail !== '') {
      dispatch(getMandataire(mail, setStatusMail))
    }
  }
  // si mail exist actualisation userDelegataire
  useEffect(() => {
    if (statusMail >= 200 && statusMail < 300) {
      mandataire && setMandat({ ...mandat, userDelegataire: mandataire })
    }
    if (statusMail >= 400) {
      setStatusMail(null)
      setToggleMailNotExist(true)
    }
  }, [statusMail])

  // si userDelegataire non null, envoi mandat
  useEffect(() => {
    if (userDelegataire) {
      dispatch(postMandat(data, setStatusMandat))
      setStatusMail(null)
    }
  }, [userDelegataire])

  // reponse mandat
  useEffect(() => {
    if (mail !== '' && statusMandat >= 200 && statusMandat < 300) {
      setStatusMail(null)
      setStatusMandat(null)
      dispatch(getPersonnes())
      dispatch(getUserMailSuccess(null))
      // setToggleMandatOk(true)
    }
    if (mail !== '' && statusMandat === 301) {
      setStatusMail(null)
      setStatusMandat(null)
      dispatch(getPersonnes())
      dispatch(getUserMailSuccess(null))
      setToggleMandatOk(true)
    }
    if (mail !== '' && statusMandat >= 400) {
      setStatusMail(null)
      setStatusMandat(null)
      dispatch(getUserMailSuccess(null))
      setToggleMandatNotOk(true)
    }
  }, [statusMandat])
  // ouverture parapheur
  useEffect(() => {
    if (url) {
      window.open(url)
    }
  }, [url])
  const closeMandat = () => {
    dispatch(getMandatSuccess(null))
    setUrl(null)
    setToggleAddMandat(false)
  }
  const closeDroits = () => {
    dispatch(getMandatSuccess(null))
    setUrl(null)
    setToggleAddDroits(false)
  }

  return (
    <Modale
      close={() => {
        proc ? closeMandat() : closeDroits()
      }}
      title="Désignez un mandataire"
    >
      <div className="informations">
        {/* mail */}
        <Input
          errorLabel={mail === ''}
          forAndId="mail"
          type="email"
          name="Mail du mandataire :"
          required={true}
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          placeholder="exemple@gmail.com"
          copy={true}
          ok={true}
        />
        {/* droits d'accès */}
        {droits && (
          <>
            <SelectComponent
              forAndId="information"
              name="Accès aux informations :"
              options={optionsTrueOrFalse}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={
                optionsTrueOrFalse &&
                optionsTrueOrFalse !== undefined &&
                optionsTrueOrFalse.find((c) => c.value === information)
              }
            />
            <SelectComponent
              forAndId="document"
              name="Accès aux documents :"
              options={optionsTrueOrFalse}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={
                optionsTrueOrFalse &&
                optionsTrueOrFalse !== undefined &&
                optionsTrueOrFalse.find((c) => c.value === document)
              }
            />
            <SelectComponent
              forAndId="patrimoine"
              name="Accès au patrimoine :"
              options={optionsTrueOrFalse}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={
                optionsTrueOrFalse &&
                optionsTrueOrFalse !== undefined &&
                optionsTrueOrFalse.find((c) => c.value === patrimoine)
              }
            />
          </>
        )}
        {change ? (
          <div className="informations__buttons">
            <div className="button button--red-red width--12" onClick={() => handleValidate()}>
              Envoyer
            </div>
            <div
              className="button button--blue-red width--12"
              onClick={() => {
                proc ? closeMandat() : closeDroits()
              }}
            >
              Annuler
            </div>
          </div>
        ) : (
          <div className="informations__buttons">
            <div className="button button--grey width--12">Envoyer</div>
            <div
              className="button button--blue-red width--12"
              onClick={() => {
                proc ? closeMandat() : closeDroits()
              }}
            >
              Annuler
            </div>
          </div>
        )}
      </div>
    </Modale>
  )
}

export default ModalMandat
