import React, { useEffect } from 'react'
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaRegCopy } from 'react-icons/fa'
import { CgCheckO } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux'
import { copyValue } from '../../../redux/actions/actionCopy'

const Copy = ({ value, little }) => {
  const dispatch = useDispatch()
  // CopyIsActive
  const selectCopy = (state) => state.getCopyValue.value
  const copy = useSelector(selectCopy)
  const [copied, setCopied] = useState(false)
  const [full, setFull] = useState(false)

  // actualisation copy
  useEffect(() => {
    if (copy !== value && copy) {
      setCopied(false)
    }
  }, [copy])

  const handleCopy = () => {
    if (value && value !== undefined && value !== '') {
      setCopied(true)
      dispatch(copyValue(value))
    }
  }
  useEffect(() => {
    if (value && value !== undefined && value !== '') {
      setFull(true)
    } else {
      setFull(false)
    }
  }, [value])

  return (
    <>
      {full &&
        (!little ? (
          !copied ? (
            <button className="input__inputBloc__copy" tabIndex={-1}>
              <CopyToClipboard text={value} onCopy={() => handleCopy()}>
                <FaRegCopy />
              </CopyToClipboard>
            </button>
          ) : (
            <div className="input__inputBloc__copy input__inputBloc__copy--copied" tabIndex={-1}>
              <CgCheckO />
            </div>
          )
        ) : !copied ? (
          <div className="informations__line__data__copy" tabIndex={-1}>
            <CopyToClipboard text={value} onCopy={() => handleCopy()}>
              <FaRegCopy />
            </CopyToClipboard>
          </div>
        ) : (
          <div
            className="informations__line__data__copy informations__line__data__copy--copied"
            tabIndex={-1}
          >
            <CgCheckO />
          </div>
        ))}
    </>
  )
}

export default Copy
