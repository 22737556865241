import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Card from '../../Components/Card'
import SelectComponent from '../../Components/SelectComponent'
import { optionsTrueOrFalse } from '../../../../data/options/options'
import {
  optionsTypeActifs,
  optionsTypeActifspourFoncier,
} from '../../../../data/options/optionsActifs'

const LinkActifCard = ({ handleChange, information, setInformation, type, foncier }) => {
  const { typeActif, idActif } = information || {}
  // actifs
  const properties = useSelector((state) => state.getProperties.properties)
  const [dataConnected, setDataConnected] = useState(false)
  const [optionsActifs, setOptionsActifs] = useState(null)
  const [optionsType, setOptionsType] = useState(optionsTypeActifs)
  const [array, setArray] = useState(null)
  // maj optionsActifs
  useEffect(() => {
    if (typeActif) {
      switch (typeActif) {
        case 'immobilier':
          setArray(properties.actifsImmobilier.biensImmobilier)
          setOptionsActifs(null)
          break
        case 'professionnel':
          setArray(properties.actifsBienProfessionnel.biensProfessionnels)
          setOptionsActifs(null)
          break
        case 'financier':
          const arrayAV = properties.financiers.produitsAV
          const arrayVM = properties.financiers.produitsVM
          const arrayPF = properties.financiers.comptesBancaires
          setArray(Array.prototype.concat(arrayAV, arrayVM, arrayPF))
          setOptionsActifs(null)
          break
        case 'autre':
          setArray(properties.actifsAutres.autresActifs)
          setOptionsActifs(null)
          break
        default:
          return
      }
      setDataConnected(true)
    } else {
      setOptionsActifs(null)
    }
  }, [typeActif])

  const goodString = (bien) => {
    let string = `${bien.nom !== undefined ? bien.nom : bien.libelle} - ${
      bien.valeur !== undefined
        ? bien.valeur.toLocaleString(undefined, { minimumFractionDigits: 4 })
        : bien.montantTotalCalcule !== undefined
        ? (Math.round(bien.montantTotalCalcule * 100) / 100).toLocaleString(undefined, {
            minimumFractionDigits: 4,
          })
        : bien.solde.toLocaleString(undefined, { minimumFractionDigits: 4 })
    } €`
    return string
  }

  useEffect(() => {
    if (array) {
      setOptionsActifs(
        array.map((bien) => ({
          label: goodString(bien),
          value: bien.id,
        }))
      )
    }
  }, [array])

  useEffect(() => {
    !dataConnected && setInformation({ ...information, typeActif: '', idActif: 0 })
  }, [dataConnected])

  useEffect(() => {
    if (foncier) {
      setOptionsType(optionsTypeActifspourFoncier)
    } else {
      setOptionsType(optionsTypeActifs)
    }
  }, [])

  return (
    <Card title="Actif de référence" h3={true} open={true}>
      <div className="informations">
        {/* revenu lié à un actif ? */}
        <SelectComponent
          forAndId="dataConnected"
          name={`${type} ${type === 'Dette' || type === 'Charge' ? 'liée' : 'lié'} à un bien :`}
          isSearchable={false}
          options={optionsTrueOrFalse}
          placeholder="Sélectionner"
          onChange={(e) => setDataConnected(e.value)}
          value={optionsTrueOrFalse.find((c) => c.value === dataConnected)}
        />
        {dataConnected && (
          <>
            {/* typeActif */}
            <SelectComponent
              forAndId="typeActif"
              name="Type de bien :"
              isSearchable={false}
              options={optionsType}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={optionsType.find((c) => c.value === typeActif)}
              copy={true}
            />
            {/* idActif */}
            {optionsActifs && optionsActifs.length > 0 && (
              <SelectComponent
                forAndId="idActif"
                name="Bien :"
                isSearchable={false}
                options={optionsActifs}
                placeholder="Sélectionner"
                onChange={(e, name) => handleChange(e, name)}
                value={optionsActifs.find((c) => c.value === idActif)}
                copy={true}
              />
            )}
          </>
        )}
      </div>
    </Card>
  )
}

export default LinkActifCard
