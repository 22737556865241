import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import SaveForm from './SaveForm'
import useFormIsActive from './useFormIsActive'

const Nav = ({ links }) => {
  const formIsActive = useFormIsActive()
  // modales
  const [toggleSaveForm, setToggleSaveForm] = useState(false)
  // handleClick
  const handleClick = () => {
    formIsActive && setToggleSaveForm(true)
  }

  return (
    <>
      <nav className="nav" onClick={() => handleClick()}>
        <ul className="nav__list">
          {links.map((link, index) => (
            <li key={index} className="nav__item">
              {formIsActive ? (
                <div className="nav__link">
                    <div className={'nav__icon'}>{link.icon}</div>
                </div>
              ) : (
                <NavLink to={link.link} className="nav__link">
                    <div className={'nav__icon'}>{link.icon}</div>
                  <p className="nav__link__txt">{link.txt}</p>
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>
      {toggleSaveForm && (
        <SaveForm toggleSaveForm={toggleSaveForm} setToggleSaveForm={setToggleSaveForm} />
      )}
    </>
  )
}

export default Nav
