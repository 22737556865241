// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import {
  getUsersLoad,
  getUsersSuccess,
  getUsersError,
} from '../actions/actionGetUsers'

// REDUCER // ______________________________________________________________

// initial state
const initialStateUsers = {
  isLoading: false,
  users: null,
  error: '',
}
// reducer
export const getUsersReducer = createReducer(initialStateUsers, (builder) => {
  return builder
    .addCase(getUsersLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getUsersSuccess, (draft, action) => {
      draft.isLoading = false
      draft.users = action.payload
      draft.error = ''
      return
    })
    .addCase(getUsersError, (draft, action) => {
      draft.isLoading = false
      draft.users = null
      draft.error = action.payload
      return
    })
})