import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import { getCatalogues } from '../../../../../../services/API/catalogue'
import { getSocial } from '../../../../../../services/API/social'
import { PRODUITS_RETRAITE } from '../../../../../../constants'
import Loader from '../../../../../layout/Loader'
import usePersonData from '../../../../../layout/usePersonData'
import useCustomPrint from '../../../../../layout/useCustomPrint'
import Card from '../../../../Components/Card'
import PrintComponent from '../../../../Components/PrintComponent'
import BlocTable from '../../../../table/BlocTable'
import ButtonsRow from '../../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../../Patrimoine/PatrimoineChartCard'
import useUpdateProductIdCatalogue from '../../Produit/useUpdateProductIdCatalogue'
import TableBodyAssurances from '../../Catalogue/Table/TableBodyAssurances'
import RetraiteToPrint from './RetraiteToPrint'
import RetraitePieChart from './RetraitePieChart'
import LittleLoader from '../../../../../layout/LittleLoader'

const RetraiteHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const social = useSelector((state) => state.getSocial.social)
  const { produitsRetraite } = social || {}
  const catalogues = useSelector((state) => state.getCatalogues.catalogues)
  const [cataloguesC, setCataloguesC] = useState(null)
  useEffect(() => {
    if (!catalogues) {
      dispatch(getCatalogues())
    } else {
      setCataloguesC(catalogues.filter((catalogue) => catalogue.categorie === 'retraite'))
    }
  }, [catalogues])
  // recharge
  useEffect(() => {
    if (!social) {
      dispatch(getSocial(person?.id, isPP))
    }
  }, [social, person, isPP, dispatch])
  // hook
  const { postData, updateData } = useUpdateProductIdCatalogue('retraite')
  useEffect(() => {
    if (cataloguesC && produitsRetraite) {
      if (produitsRetraite.filter((el) => el.idCatalogue === 0).length > 0) {
        postData(produitsRetraite, cataloguesC)
        updateData(produitsRetraite, cataloguesC)
      }
    }
  }, [produitsRetraite, cataloguesC])
  // redirections
  const formLink = '/user/patrimoine/actifs/social/retraite/formulaire'
  // maj bien
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // calcul valeurs
  const valeurPortefeuilles = useMemo(
    () =>
      produitsRetraite
        .map((produit) => produit.montantTotalCalcule)
        .reduce((acc, curr) => acc + curr, 0),
    [produitsRetraite]
  )
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()
  // titles
  const titles = ['N°', 'Libellé', 'Portefeuille', 'Voir', 'Modifier', 'Supprimer']

  if (!social) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<RetraiteToPrint elementToDisplay={produitsRetraite} valeurPortefeuilles={valeurPortefeuilles} />}
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            <PatrimoineValueCard
              totalAmount={valeurPortefeuilles}
            />
            {valeurPortefeuilles !== 0 ? (
              <PatrimoineChartCard
                children={<RetraitePieChart produitsRetraite={produitsRetraite} />}
              />
            ) : null}
            <Card title={`Produits (${produitsRetraite.length})`} h5={true} open={true}>
              <div className="informations">
                <BlocTable
                  data={produitsRetraite}
                  titles={titles}
                  tableType={PRODUITS_RETRAITE}
                  children={<TableBodyAssurances />}
                  title={`Produits (${produitsRetraite.length})`}
                  handleAdd={() => navigate(formLink)}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default RetraiteHome
