import React from 'react'
import { COLORS } from '../../../../../constants'
import PieChartComponent from '../../../Charts/PieChartComponent'
import LegendPieChart from '../../../Charts/LegendPieChart'

const BiensAutresPieChart = ({ actifsAutres, detenteurs, print }) => {
  const { autreActifDivers, nomDetenteurs, valeurDetenteurs, autresActifs } = actifsAutres || {}
  // colors
  const colors = [
    { fill: COLORS.colorRepartition01, color: 'repartition01' },
    { fill: COLORS.colorRepartition02, color: 'repartition02' },
    { fill: COLORS.colorRepartition03, color: 'repartition03' },
    { fill: COLORS.colorRepartition04, color: 'repartition04' },
    { fill: COLORS.colorRepartition05, color: 'repartition05' },
  ]
  // actif immo
  const arrayActifAutres = autresActifs
    .filter((bien) => !bien.closOuVendu)
    .map((bien) => ({ nom: bien.nom, valeur: bien.valeur }))
  arrayActifAutres.push({ nom: 'Non affecté', valeur: autreActifDivers.montant })
  const dataBiensAutres = arrayActifAutres.map((det, index) =>
    det.nom !== 'Non affecté'
      ? {
          name: det.nom,
          value: det.valeur,
          fill: colors[index % colors.length].fill,
          color: colors[index % colors.length].color,
        }
      : {
          name: det.nom,
          value: det.valeur,
          fill: COLORS.colorFontLight,
          color: 'divers',
        }
  )
  // detenteurs
  const arrayDetenteurs = []
  for (let i = 0; i < nomDetenteurs.length; i++) {
    arrayDetenteurs.push({ nom: nomDetenteurs[i], valeur: valeurDetenteurs[i] })
  }
  arrayDetenteurs.push({ nom: 'Non affecté', valeur: autreActifDivers.montant })
  const dataDetenteurs = arrayDetenteurs.map((det) => ({ name: det.nom, value: det.valeur }))
  const data = !detenteurs ? dataBiensAutres : dataDetenteurs

  const title = !detenteurs ? 'Répartition' : 'Détenteurs'
  return (
    <>
      <PieChartComponent data={data} title={title} detenteurs={detenteurs} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title} detenteurs={detenteurs}/>
    </>
  )
}

export default BiensAutresPieChart
