import React from 'react'
import {
  dataBienAquisition,
  dataBienAutre,
} from '../../../../../../data/informations/actifs'
import InformationsCardPrint from '../../../../Informations/InformationsCardPrint'
import { DOC_BIEN_AUTRE } from '../../../../../../constants'
import BienInfoPrintDetenteurEtDoc from '../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'

const BienAutreInfoPrint = ({ elementToDisplay }) => {
  const { detenteurs, fichiers } = elementToDisplay || {}
  return (
    <>
      <InformationsCardPrint data={dataBienAutre(elementToDisplay)} title={elementToDisplay.nom} />
      <InformationsCardPrint data={dataBienAquisition(elementToDisplay)} title={'Acquisition'} />
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={DOC_BIEN_AUTRE}
      />
    </>
  )
}

export default BienAutreInfoPrint
