// ______________________________________ actifs
// biens professionnels
export const optionsTypePro = [
    { value: 'ENTREPRISE', label: 'Entreprise' },
    { value: 'IMMOBILIERPROFESSIONNEL', label: 'Immobilier professionnel' },
    { value: 'AUTRE', label: 'Autre' },
  ]
  export const optionsNatureBienPro = [
    { value: 'Clientèle / patientèle', label: 'Clientèle / patientèle' },
    { value: 'Entreprise individuelle', label: 'Entreprise individuelle' },
    { value: 'Fonds de commerce', label: 'Fonds de commerce' },
    { value: 'Droits sociaux', label: 'Droits sociaux' },
  ]
  export const optionsRisque = [
    { value: 'SECURITAIRE', label: 'Sécuritaire' },
    { value: 'PRUDENT', label: 'Prudent' },
    { value: 'MODERE', label: 'Modéré' },
    { value: 'EQUILIBRE', label: 'Equilibré' },
    { value: 'DYNAMIQUE', label: 'Dynamique' },
    { value: 'OFFENSIF', label: 'Offensif' },
    { value: 'AGRESSIF', label: 'Agressif' },
  ]
  // actifs
  export const optionsActifs = [
    { value: 'actifsBienProfessionnel', label: 'Biens Pro' },
    { value: 'actifsImmobilier', label: 'Biens Immo' },
    { value: 'actifsDivers', label: 'Non affecté' },
    { value: 'actifsAutres', label: 'Biens autre' },
  ]
  // type actifs
  export const optionsTypeActifs = [
    { value: 'professionnel', label: 'Bien Professionnel' },
    { value: 'immobilier', label: 'Bien Immobilier' },
    { value: 'financier', label: 'Bien financier' },
    { value: 'autre', label: 'Bien autre' },
  ]
  export const optionsTypeActifspourFoncier = [
    { value: 'professionnel', label: 'Bien Professionnel' },
    { value: 'immobilier', label: 'Bien Immobilier' },
    { value: 'autre', label: 'Bien autre' },
  ]
  // bien immobilier _______________________________________________________________________________
  export const optionsTypeImmo = [
    { value: 'BIENDUSAGE', label: "Bien d'usage" },
    { value: 'IMMOBILIERDERAPPORT', label: 'Immobilier de rapport' },
    { value: 'BOISFORET', label: 'Bois & forêt' },
    { value: 'BIENRURAUX', label: 'Biens ruraux' },
    { value: 'TERRAIN', label: 'Terrain' },
    { value: 'AUTRE', label: 'Autre' },
  ]
  export const optionsBiensImmo = [
    {
      typeImmobilier: 'BIENDUSAGE',
      typeBien: [
        { typeBien: 'Résidence principale', codeFiscal: '9AA' },
        { typeBien: 'Résidence secondaire', codeFiscal: '9AB' },
        { typeBien: 'Autre', codeFiscal: '9AB' },
      ],
    },
    {
      typeImmobilier: 'IMMOBILIERDERAPPORT',
      typeBien: [
        { typeBien: 'Location nue', codeFiscal: '9AB' },
        { typeBien: 'Location meublée', codeFiscal: '9AB' },
        { typeBien: 'Parts de SCPI', codeFiscal: '9CA' },
        { typeBien: 'Parts de SCI', codeFiscal: '9CA' },
        { typeBien: 'SICOMI', codeFiscal: '9CA' },
        { typeBien: 'SII', codeFiscal: '9CA' },
        { typeBien: 'Sociétés foncières', codeFiscal: '9CA' },
      ],
    },
    {
      typeImmobilier: 'BOISFORET',
      typeBien: [
        { typeBien: 'Bois', codeFiscal: '9AC' },
        { typeBien: 'Forêt', codeFiscal: '9AC' },
        { typeBien: 'Parts de groupement forestier', codeFiscal: '9AC' },
      ],
    },
    {
      typeImmobilier: 'BIENRURAUX',
      typeBien: [
        { typeBien: 'Sans bail', codeFiscal: '9BB' },
        { typeBien: 'Bail moins de 18 ans', codeFiscal: '9BB' },
        { typeBien: 'Bail long terme', codeFiscal: '9AD' },
      ],
    },
    {
      typeImmobilier: 'TERRAIN',
      typeBien: [
        { typeBien: 'Constructible', codeFiscal: '9BB' },
        { typeBien: 'Zone Agricole', codeFiscal: '9BB' },
        { typeBien: 'Zone naturelle ou protégée', codeFiscal: '9BB' },
      ],
    },
    {
      typeImmobilier: 'AUTRE',
      typeBien: [{ typeBien: 'Saisie libre (50 caractères)', codeFiscal: '9AB' }],
    },
  ]
  export const optionsRegimeFiscal = [
    'Aucun',
    'Revenus fonciers',
    'Location meublée non professionnelle',
    'Location meublée professionnelle',
    'Monuments historiques',
    'Loi Malraux',
    'Déficit foncier',
    'Loi Méhaignerie',
    'Loi Quilès-Méhaignerie',
    'Amortissement Perissol',
    'Amortissement Besson',
    'Loi Demessine',
    'Loi Girardin',
    "Loi Girardin pour l'ancien",
    'Loi Robien',
    'Loi Robien recentré',
    'Loi Borloo neuf',
    'Loi Borloo ancien',
    'Loi Censi-Bouvard',
    'Loi Scellier',
    'Loi Duflot',
    'Loi Pinel',
    'Loi Pinel Outre-Mer',
    'Loi Cosse',
    'Loi Denormandie',
  ]
  