import React, { useEffect, useState } from 'react'
import { optionsTrueOrFalse } from '../../../../data/options/options'
import AutoCompleteAdresse from '../../AutoCompleteAddress/AutoCompleteAdresse'
import Input from '../../Components/Input'
import SelectComponent from '../../Components/SelectComponent'
import DateInputComponent from '../../Components/DateInputComponent'

const InputsEtablissement = ({
  handleChange,
  handleChangeDate,
  handleAddEtablissement,
  optionsCodeNAF,
  optionsLibelleNAF,
  data,
  setData,
  setDataChange,
}) => {
  const {
    siret,
    nic,
    code_naf,
    libelle_code_naf,
    etablissement_employeur,
    effectif,
    effectif_min,
    effectif_max,
    date_de_creation,
    etablissement_cesse,
    date_cessation,
    siege,
    enseigne,
    nom_commercial,
  } = data || {}
  const [change, setChange] = useState(false)
  useEffect(() => {
    if (siret !== undefined && siret !== '') {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [siret])

  return (
    <div className="informations">
      <div className="informations">
        {/* Siret */}
        <Input
          forAndId="siret"
          errorLabel={siret === ''}
          type="number"
          name="SIRET :"
          value={siret}
          onChange={(e) => handleChange(e)}
          placeholder="12345678900045"
          maxLength="14"
          required={true}
          copy={true}
        />
        {/* nic */}
        <Input
          forAndId="nic"
          type="text"
          name="NIC :"
          value={nic}
          onChange={(e) => handleChange(e)}
          placeholder=""
          maxLength="5"
          copy={true}
        />
      </div>
      <div>
        {/* siege */}
        <SelectComponent
          forAndId="siege"
          name="Siège :"
          isSearchable={false}
          options={optionsTrueOrFalse}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsTrueOrFalse.find((c) => c.value === siege)}
          copy={true}
        />
      </div>
      <AutoCompleteAdresse
        etablissement={true}
        data={data}
        setData={setData}
        setDataChange={setDataChange}
      />
      <div className="informations">
        {/* code_naf */}
        <SelectComponent
          forAndId="code_naf"
          name="Code NAF :"
          isClearable={true}
          options={optionsCodeNAF}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsCodeNAF !== undefined && optionsCodeNAF.find((c) => c.value === code_naf)}
          copy={true}
        />
        {/* libelle_code_naf */}
        {code_naf && (
          <SelectComponent
            forAndId="libelle_code_naf"
            name="Libellé NAF :"
            isSearchable={false}
            isDisabled={true}
            options={optionsLibelleNAF}
            placeholder="Sélectionner"
            value={
              optionsLibelleNAF !== undefined &&
              optionsLibelleNAF.find((c) => c.value === libelle_code_naf)
            }
            copy={true}
          />
        )}
      </div>
      <div className="informations">
        {/* etablissement_employeur */}
        <SelectComponent
          forAndId="etablissement_employeur"
          name="Établissement employeur :"
          isSearchable={false}
          options={optionsTrueOrFalse}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsTrueOrFalse.find((c) => c.value === etablissement_employeur)}
          copy={true}
        />
        {/* effectif */}
        <Input
          forAndId="effectif"
          type="text"
          name="Effectif :"
          value={effectif}
          onChange={(e) => handleChange(e)}
          placeholder=""
          copy={true}
        />
        {/* effectif_min */}
        <Input
          forAndId="effectif_min"
          type="number"
          name="Effectif min :"
          value={effectif_min}
          onChange={(e) => handleChange(e)}
          placeholder=""
          copy={true}
        />
        {/* effectif_max */}
        <Input
          forAndId="effectif_max"
          type="number"
          name="Effectif max :"
          value={effectif_max}
          onChange={(e) => handleChange(e)}
          placeholder=""
          copy={true}
        />
      </div>
      <div className="informations">
        {/* date_de_creation */}
        <DateInputComponent
          name={'date_de_creation'}
          label={'Date de création :'}
          value={date_de_creation}
          handleChange={handleChangeDate}
        />
        {/* etablissement_cesse */}
        <SelectComponent
          forAndId="etablissement_cesse"
          name="Cessation :"
          isSearchable={false}
          options={optionsTrueOrFalse}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsTrueOrFalse.find((c) => c.value === etablissement_cesse)}
          copy={true}
        />
        {/* date_cessation */}
        {etablissement_cesse ? (
          <DateInputComponent
            name={'date_cessation'}
            label={'Date de cessation :'}
            value={date_cessation}
            handleChange={handleChangeDate}
          />
        ) : null}
      </div>
      <div className="informations">
        {/* enseigne */}
        <Input
          forAndId="enseigne"
          type="text"
          name="Enseigne :"
          value={enseigne}
          onChange={(e) => handleChange(e)}
          placeholder=""
          copy={true}
        />
        {/* nom_commercial */}
        <Input
          forAndId="nom_commercial"
          type="text"
          name="Nom commercial :"
          value={nom_commercial}
          onChange={(e) => handleChange(e)}
          placeholder=""
          copy={true}
        />
      </div>
      {change ? (
        <div className="informations__buttons">
          <div
            className="button button--red-red  width--18"
            onClick={() => handleAddEtablissement()}
          >
            Enregistrer
          </div>
        </div>
      ) : (
        <div className="informations__buttons">
          <div className="button button--grey width--12">Enregistrer</div>
        </div>
      )}
    </div>
  )
}

export default InputsEtablissement
