import React from 'react'
import Card from '../Components/Card'
import BlocTable from '../table/BlocTable'
import { USER_SIGNATURES_CLASSER } from '../../../constants'
import TableBodySignToClassify from './Table/TableBodySignToClassify'

const ToClassifyCard = ({ signaturesToClassifyToDisplay }) => {
  const title = `Documents à classer ${
    signaturesToClassifyToDisplay && `(${signaturesToClassifyToDisplay.length})`
  }`
  return (
    <Card
      title={title}
      open={
        signaturesToClassifyToDisplay && (signaturesToClassifyToDisplay.length === 0 ? false : true)
      }
    >
      <div className="informations">
        {signaturesToClassifyToDisplay && signaturesToClassifyToDisplay.length > 0 ? (
          <BlocTable
            titles={['N°', 'Classer', 'Fichier', 'Créé le', 'Voir', 'Classer']}
            data={signaturesToClassifyToDisplay}
            tableType={USER_SIGNATURES_CLASSER}
            children={<TableBodySignToClassify />}
            doc={true}
            title={title}
          />
        ) : (
          <p>Auncun document à afficher</p>
        )}
      </div>
    </Card>
  )
}

export default ToClassifyCard
