import React from 'react'
import { dateFormateComplete } from '../../../../services/helpers/dates'

const BodySignPendingPrint = ({ element, index }) => {
  return (
    <>
      {/* fichier */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Fichier</p>
        <p className="print__table__bloc__line--value">
          {!element.fichier_nom ? '-' : element.fichier_nom}
        </p>
      </div>
      {/* dateSignature */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Date</p>
        <p className="print__table__bloc__line--value">
          {!element.dateSignature ? '-' : dateFormateComplete(element.dateSignature)}
        </p>
      </div>
      {/* typeTransaction */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Type de transaction</p>
        <p className="print__table__bloc__line--value">
          {!element.typeTransaction ? '-' : element.typeTransaction}
        </p>
      </div>
    </>
  )
}

export default BodySignPendingPrint
