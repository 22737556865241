// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions formIsActive
 * @reduxAction
 */

// export const getFormIsActive = createAction('formIsActive')
export const getFormIsActive = createAction('get-formIsactive', (isActive) => {
  return {
    payload: isActive,
  }
})
