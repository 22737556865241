import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { getCatalogues } from '../../../../../services/API/catalogue'
import { CATEGORIE } from '../../../../../constants'
import CatalogueHomeContent from './CatalogueHomeContent'

const CatalogueHome = ({ categorie }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // catalogues
  const catalogues = useSelector((state) => state.getCatalogues.catalogues)
  const catalogueVM = useMemo(
    () =>
      catalogues ? catalogues.filter((catalogue) => catalogue.categorie === CATEGORIE.vm) : [],
    [catalogues]
  )
  const catalogueAV = useMemo(
    () =>
      catalogues ? catalogues.filter((catalogue) => catalogue.categorie === CATEGORIE.av) : [],
    [catalogues]
  )
  const cataloguePER = useMemo(
    () =>
      catalogues ? catalogues.filter((catalogue) => catalogue.categorie === CATEGORIE.per) : [],
    [catalogues]
  )
  const catalogueRetraite = useMemo(
    () =>
      catalogues
        ? catalogues.filter((catalogue) => catalogue.categorie === CATEGORIE.retraite)
        : [],
    [catalogues]
  )
  useEffect(() => {
    if (!catalogues) {
      dispatch(getCatalogues())
    }
  }, [catalogues, dispatch])
  // formToDisplay
  const [toggleSelectForm, setToggleSelectForm] = useState(false)
  const [categorieForm, setCategorieForm] = useState([])
  const optionsCategorieForm = useMemo(() => {
    return categorie === 'financier'
      ? [
          { label: 'Valeurs mobilières', value: 'VM' },
          { label: 'Assurances vie', value: 'AV' },
        ]
      : [
          { label: 'Epargne salariale & PER', value: 'PER' },
          { label: 'Retraite (assurance)', value: 'retraite' },
        ]
  }, [categorie])
  // remise à zero
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // handleChange
  const handleChange = useCallback(
    ({ value }) => {
      setCategorieForm(value)
      const basePath = `/user/patrimoine/actifs/${
        value === 'VM' || value === 'AV' ? 'financier' : 'social'
      }/catalogue/formulaire/`
      navigate(`${basePath}${value}`)
      setToggleSelectForm(false)
    },
    [navigate]
  )

  return (
    <CatalogueHomeContent
      catalogues={catalogues}
      categorie={categorie}
      toggleSelectForm={toggleSelectForm}
      setToggleSelectForm={setToggleSelectForm}
      catalogueVM={catalogueVM}
      catalogueAV={catalogueAV}
      cataloguePER={cataloguePER}
      catalogueRetraite={catalogueRetraite}
      optionsCategorieForm={optionsCategorieForm}
      handleChange={handleChange}
      categorieForm={categorieForm}
    />
  )
}

export default CatalogueHome
