import { recupBien } from '../../services/API/actifs'
import { beautifulDate } from '../../services/helpers/dates'
import { calculateAmountReverse, recupLabel } from '../../services/helpers/helpers'
import {
  optionsPRAge,
  optionsTypePR,
  optionsTypeRevenuProfessionnel,
} from '../options/optionsRevenus'
import { optionsDetenteur, optionsDetention, optionsTypePeriodicite } from '../options/options'
import { recupPM } from '../../services/API/personnes'

// valeur
export const dataRevenusValues = (revenu) => {
  const { revenuDivers, valeurTotaleRevenu } = revenu
  return [
    {
      label: 'Valeur totale des revenus',
      value: `${
        valeurTotaleRevenu &&
        valeurTotaleRevenu.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus divers',
      value: `${
        revenuDivers && revenuDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus affectés',
      value: `${(
        valeurTotaleRevenu &&
        revenuDivers &&
        valeurTotaleRevenu - revenuDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataRevenusFonciersValues = (revenu) => {
  const { revenusFonciers } = revenu
  const { valeurRevenusFonciers, revenuFoncierDivers } = revenusFonciers
  return [
    {
      label: 'Valeur totale des revenus fonciers',
      value: `${
        valeurRevenusFonciers &&
        valeurRevenusFonciers.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus fonciers divers',
      value: `${
        revenuFoncierDivers &&
        revenuFoncierDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus fonciers affectés',
      value: `${(
        valeurRevenusFonciers &&
        revenuFoncierDivers &&
        valeurRevenusFonciers - revenuFoncierDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataRevenusMobiliersValues = (revenu) => {
  const { revenusMobiliers } = revenu
  const { valeurRevenuMobilier, revenuMobilierDivers } = revenusMobiliers
  return [
    {
      label: 'Valeur totale des revenus mobiliers',
      value: `${
        valeurRevenuMobilier &&
        valeurRevenuMobilier.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus mobiliers divers',
      value: `${
        revenuMobilierDivers &&
        revenuMobilierDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus mobiliers affectés',
      value: `${(
        valeurRevenuMobilier &&
        revenuMobilierDivers &&
        valeurRevenuMobilier - revenuMobilierDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataRevenusProsValues = (revenu) => {
  const { revenusProfessionnels } = revenu
  const { valeurRevenusProfessionnels, revenuProfessionnelDivers } = revenusProfessionnels
  return [
    {
      label: 'Valeur totale des revenus professionnels',
      value: `${
        valeurRevenusProfessionnels &&
        valeurRevenusProfessionnels.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus professionnels divers',
      value: `${
        revenuProfessionnelDivers &&
        revenuProfessionnelDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus professionnels affectés',
      value: `${(
        valeurRevenusProfessionnels &&
        revenuProfessionnelDivers &&
        valeurRevenusProfessionnels - revenuProfessionnelDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataRevenusPensionsValues = (revenu) => {
  const { pensionsRentes } = revenu
  const { valeurPensionsRentes, pensionRenteDivers } = pensionsRentes
  return [
    {
      label: 'Valeur totale des revenus des pensions et rentes',
      value: `${
        valeurPensionsRentes &&
        valeurPensionsRentes.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus pensions et rentes divers',
      value: `${
        pensionRenteDivers &&
        pensionRenteDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus pensions et rentes affectés',
      value: `${(
        valeurPensionsRentes &&
        pensionRenteDivers &&
        valeurPensionsRentes - pensionRenteDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataRevenusAutresValues = (revenu) => {
  const { autresRevenus } = revenu
  const { valeurAutreRevenus, autreRevenusDivers } = autresRevenus
  return [
    {
      label: 'Valeur totale des revenus autres',
      value: `${
        valeurAutreRevenus &&
        valeurAutreRevenus.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus autres divers',
      value: `${
        autreRevenusDivers &&
        autreRevenusDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur revenus autres affectés',
      value: `${(
        valeurAutreRevenus &&
        autreRevenusDivers &&
        valeurAutreRevenus - autreRevenusDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}

// revenu Pro
export const dataRevenuProGeneral = (revenu, categorie, properties, pmList) => {
  return [
    {
      label: 'Libellé :',
      value: revenu.libelle,
    },
    {
      label: 'Catégorie :',
      value:
        revenu.typeRevenuProfessionnel &&
        recupLabel(revenu.typeRevenuProfessionnel, optionsTypeRevenuProfessionnel),
    },
    {
      label: 'Sous-catégorie :',
      value: revenu.detail,
    },
    {
      label: 'Détenteur :',
      value: revenu.typeRevenu && recupLabel(revenu.typeRevenu, optionsDetenteur),
    },
    {
      label: 'Montant :',
      value:
        revenu.montant &&
        `${calculateAmountReverse(revenu.montant, revenu.typePeriodicite).toLocaleString(
          undefined,
          { minimumFractionDigits: 4 }
        )} €`,
    },
    {
      label: 'Périodicité :',
      value: revenu.typePeriodicite && recupLabel(revenu.typePeriodicite, optionsTypePeriodicite),
    },
    {
      label: 'Date de début :',
      value: beautifulDate(revenu.dateDebut),
    },
    {
      label: 'Date de fin :',
      value: beautifulDate(revenu.dateFin),
    },
    {
      label: 'Employeur :',
      value: revenu.employeur,
      personne: revenu.lienPM ? recupPM(revenu.idPM, pmList) : null,
    },
    {
      label: "Date d'acquisition :",
      value: beautifulDate(revenu.dateAcquisition),
    },
    {
      label: 'Relié au bien :',
      value:
        revenu.idActif && revenu.idActif !== 0
          ? recupBien(revenu.typeActif, revenu.idActif, properties, false)
          : '',
      bien:
        revenu.idActif && revenu.idActif !== 0
          ? recupBien(revenu.typeActif, revenu.idActif, properties, true)
          : null,
    },
    {
      label: 'Détention :',
      value: revenu.detention && recupLabel(revenu.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${revenu.tauxIndivision} %`,
    },
  ]
}
// revenu mob + foncier
export const dataRevenuMobGeneral = (revenu, categorie, properties, pmList) => {
  return [
    {
      label: 'Libellé :',
      value: revenu.libelle,
    },
    {
      label: 'Catégorie :',
      value: revenu.detail,
    },
    {
      label: 'Détenteur :',
      value: revenu.typeRevenu && recupLabel(revenu.typeRevenu, optionsDetenteur),
    },
    {
      label: 'Montant :',
      value:
        revenu.montant &&
        `${calculateAmountReverse(revenu.montant, revenu.typePeriodicite).toLocaleString(
          undefined,
          { minimumFractionDigits: 4 }
        )} €`,
    },
    {
      label: 'Périodicité :',
      value: revenu.typePeriodicite && recupLabel(revenu.typePeriodicite, optionsTypePeriodicite),
    },
    {
      label: 'Date de début :',
      value: beautifulDate(revenu.dateDebut),
    },
    {
      label: 'Date de fin :',
      value: beautifulDate(revenu.dateFin),
    },
    {
      label: 'Relié au bien :',
      value:
        revenu.idActif && revenu.idActif !== 0
          ? recupBien(revenu.typeActif, revenu.idActif, properties, false)
          : '',
      bien:
        revenu.idActif && revenu.idActif !== 0
          ? recupBien(revenu.typeActif, revenu.idActif, properties, true)
          : null,
    },
    {
      label: 'Détention :',
      value: revenu.detention && recupLabel(revenu.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${revenu.tauxIndivision} %`,
    },
  ]
}
// PR
export const dataPRGeneral = (revenu, categorie, properties, pmList) => {
  return [
    {
      label: 'Libellé :',
      value: revenu.libelle,
    },
    {
      label: 'Catégorie :',
      value: revenu.typePensionRente && recupLabel(revenu.typePensionRente, optionsTypePR),
    },
    {
      label: 'Sous-catégorie :',
      value: revenu.detail,
    },
    {
      label: 'Age :',
      value: revenu.age === 'SANS' ? revenu.age : recupLabel(revenu.age, optionsPRAge),
    },
    {
      label: 'Détenteur :',
      value: revenu.typeRevenu && recupLabel(revenu.typeRevenu, optionsDetenteur),
    },
    {
      label: 'Montant :',
      value:
        revenu.montant &&
        `${calculateAmountReverse(revenu.montant, revenu.typePeriodicite).toLocaleString(
          undefined,
          { minimumFractionDigits: 4 }
        )} €`,
    },
    {
      label: 'Périodicité :',
      value: revenu.typePeriodicite && recupLabel(revenu.typePeriodicite, optionsTypePeriodicite),
    },
    {
      label: 'Date de début :',
      value: beautifulDate(revenu.dateDebut),
    },
    {
      label: 'Date de fin :',
      value: beautifulDate(revenu.dateFin),
    },
    {
      label: 'Relié au bien :',
      value:
        revenu.idActif && revenu.idActif !== 0
          ? recupBien(revenu.typeActif, revenu.idActif, properties, false)
          : '',
      bien:
        revenu.idActif && revenu.idActif !== 0
          ? recupBien(revenu.typeActif, revenu.idActif, properties, true)
          : null,
    },
    {
      label: 'Détention :',
      value: revenu.detention && recupLabel(revenu.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${revenu.tauxIndivision} %`,
    },
  ]
}
// All
export const dataRevenu = (revenu, categorie, properties, pmList) => {
  return [
    {
      label: 'Libellé :',
      value: revenu.libelle,
    },
    {
      label: 'Catégorie :',
      value: revenu.typePensionRente && recupLabel(revenu.typePensionRente, optionsTypePR),
    },
    {
      label: 'Sous-catégorie :',
      value: revenu.detail,
    },
    {
      label: 'Age :',
      value: revenu.age === 'SANS' ? revenu.age : recupLabel(revenu.age, optionsPRAge),
    },
    {
      label: 'Détenteur :',
      value: revenu.typeRevenu && recupLabel(revenu.typeRevenu, optionsDetenteur),
    },
    {
      label: 'Montant :',
      value:
        revenu.montant &&
        `${calculateAmountReverse(revenu.montant, revenu.typePeriodicite).toLocaleString(
          undefined,
          { minimumFractionDigits: 4 }
        )} €`,
    },
    {
      label: 'Périodicité :',
      value: revenu.typePeriodicite && recupLabel(revenu.typePeriodicite, optionsTypePeriodicite),
    },
    {
      label: 'Date de début :',
      value: beautifulDate(revenu.dateDebut),
    },
    {
      label: 'Date de fin :',
      value: beautifulDate(revenu.dateFin),
    },
    {
      label: 'Employeur :',
      value: revenu.employeur,
    },
    {
      label: "Date d'acquisition :",
      value: beautifulDate(revenu.dateAcquisition),
    },
    {
      label: 'Relié au bien :',
      value:
        revenu.idActif && revenu.idActif !== 0
          ? recupBien(revenu.typeActif, revenu.idActif, properties, false)
          : '',
      bien:
        revenu.idActif && revenu.idActif !== 0
          ? recupBien(revenu.typeActif, revenu.idActif, properties, true)
          : null,
    },
    {
      label: 'Détention :',
      value: revenu.detention && recupLabel(revenu.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${revenu.tauxIndivision} %`,
    },
  ]
}
// AutreRevenu
export const dataAutreRevenu = (revenu, categorie, properties, pmList) => {
  return [
    {
      label: 'Libellé :',
      value: revenu.libelle,
    },
    {
      label: 'Catégorie :',
      value: revenu.detail,
    },
    {
      label: 'Détenteur :',
      value: revenu.typeRevenu && recupLabel(revenu.typeRevenu, optionsDetenteur),
    },
    {
      label: 'Montant :',
      value:
        revenu.montant &&
        `${calculateAmountReverse(revenu.montant, revenu.typePeriodicite).toLocaleString(
          undefined,
          { minimumFractionDigits: 4 }
        )} €`,
    },
    {
      label: 'Périodicité :',
      value: revenu.typePeriodicite && recupLabel(revenu.typePeriodicite, optionsTypePeriodicite),
    },
    {
      label: 'Date de début :',
      value: beautifulDate(revenu.dateDebut),
    },
    {
      label: 'Date de fin :',
      value: beautifulDate(revenu.dateFin),
    },
    {
      label: 'Relié au bien :',
      value:
        revenu.idActif && revenu.idActif !== 0
          ? recupBien(revenu.typeActif, revenu.idActif, properties, false)
          : '',
      bien:
        revenu.idActif && revenu.idActif !== 0
          ? recupBien(revenu.typeActif, revenu.idActif, properties, true)
          : null,
    },
    {
      label: 'Détention :',
      value: revenu.detention && recupLabel(revenu.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${revenu.tauxIndivision} %`,
    },
  ]
}
