import React from 'react'
import { beautifulDateAndTime } from '../../../../services/helpers/dates'

const BodySignToClassifyPrint = ({ element, index }) => {
  const getBeautifulName = (str) => {
    return str.split('-').slice(1).join('-')
  }

  return (
    <>
      {/* fichier */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Fichier</p>
        <p className="print__table__bloc__line--value">
          {!element.fileName ? '-' : getBeautifulName(element.fileName)}
        </p>
      </div>
      {/* createdAt */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Créé le</p>
        <p className="print__table__bloc__line--value">
          {!element.createdAt ? '-' : beautifulDateAndTime(element.createdAt)}
        </p>
      </div>
    </>
  )
}

export default BodySignToClassifyPrint
