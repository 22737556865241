import React, { useEffect, useState } from 'react'
import {
  optionsTypeRevenuProfessionnel,
  optionsTypeRevenusNonSalarie,
  optionsTypeRevenusSalaires,
} from '../../../../../../data/options/optionsRevenus'
import {
  optionsDetenteur,
  optionsDetention,
  optionsTypePeriodicite,
} from '../../../../../../data/options/options'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'
import SelectComponent from '../../../../Components/SelectComponent'
import { useSelector } from 'react-redux'
import { normalizeAndLowerCase } from '../../../../../../services/helpers/strings'
import DateInputComponent from '../../../../Components/DateInputComponent'

const GeneralInformationCardPro = ({ handleChange, handleChangeDate, information }) => {
  const {
    libelle,
    typeRevenuProfessionnel,
    detail,
    typeRevenu,
    montant,
    typePeriodicite,
    dateDebut,
    dateFin,
    employeur,
    dateAcquisition,
    detention,
    tauxIndivision,
  } = information || {}
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  const PM = persons.dirigeantPM
  const [optionsPM, setOptionsPM] = useState([])
  useEffect(() => {
    if (persons) {
      setOptionsPM(PM.map((el) => ({ label: el.denominationSociale, value: el })))
    }
  }, [persons])

  const [optionsTodisplay, setOptionsTodisplay] = useState([])
  useEffect(() => {
    typeRevenuProfessionnel === 'TRAITEMENTSETSALAIRES'
      ? setOptionsTodisplay(optionsTypeRevenusSalaires)
      : setOptionsTodisplay(optionsTypeRevenusNonSalarie)
  }, [typeRevenuProfessionnel])

  return (
    <Card title="Informations générales" h3={true} open={true}>
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* libelle */}
          <Input
            forAndId="libelle"
            errorLabel={libelle === ''}
            type="text"
            name="Libellé :"
            value={libelle}
            onChange={(e) => handleChange(e)}
            placeholder="Libellé"
            copy={true}
            required
          />
          {/* typeImmobilier */}
          <SelectComponent
            forAndId="typeRevenuProfessionnel"
            errorLabel={typeRevenuProfessionnel === ''}
            name="Catégorie :"
            isSearchable={false}
            isClearable={true}
            options={optionsTypeRevenuProfessionnel}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsTypeRevenuProfessionnel.find((c) => c.value === typeRevenuProfessionnel)}
            copy={true}
            required={true}
          />
          {/* detail */}
          {typeRevenuProfessionnel ? (
            <SelectComponent
              forAndId="detail"
              errorLabel={detail === '' || !detail}
              name="Sous-catégorie :"
              isSearchable={true}
              isClearable={true}
              options={optionsTodisplay}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={detail && optionsTodisplay.find((c) => c.value === detail)}
              copy={true}
              required={true}
            />
          ) : null}
          {/* typeRevenu */}
          <SelectComponent
            forAndId="typeRevenu"
            errorLabel={typeRevenu === ''}
            name="Détenteur :"
            isSearchable={false}
            options={optionsDetenteur}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsDetenteur.find((c) => c.value === typeRevenu)}
            copy={true}
            required={true}
          />
          {/* typePeriodicite */}
          <SelectComponent
            forAndId="typePeriodicite"
            errorLabel={typePeriodicite === ''}
            name="Periodicité :"
            isSearchable={false}
            options={optionsTypePeriodicite}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsTypePeriodicite.find((c) => c.value === typePeriodicite)}
            copy={true}
            required={true}
          />
          {/* montant */}
          <Input
            forAndId="montant"
            errorLabel={montant === '' || montant === 0}
            type="number"
            name="Montant :"
            value={montant}
            onChange={(e) => handleChange(e)}
            required={true}
            copy={true}
          />
        </div>
        {/* employeur */}
        {employeur !== '' ? (
          optionsPM &&
          optionsPM.find(
            (c) =>
              normalizeAndLowerCase(c.value.denominationSociale) ===
              normalizeAndLowerCase(employeur)
          ) === undefined ? (
            <Input
              forAndId="employeur"
              type="text"
              name="Employeur :"
              value={employeur}
              onChange={(e) => handleChange(e)}
              copy={true}
            />
          ) : (
            <SelectComponent
              forAndId="employeur"
              name="Employeur :"
              isSearchable={true}
              isClearable={true}
              creatable={true}
              options={optionsPM}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={
                optionsPM &&
                optionsPM.find(
                  (c) =>
                    normalizeAndLowerCase(c.value.denominationSociale) ===
                    normalizeAndLowerCase(employeur)
                )
              }
              copy={true}
              formatCreateLabel={(value) => `Créer : ${value}`}
            />
          )
        ) : (
          <SelectComponent
            forAndId="employeur"
            name="Employeur :"
            isSearchable={true}
            isClearable={true}
            creatable={true}
            options={optionsPM}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={
              optionsPM &&
              optionsPM.find(
                (c) =>
                  normalizeAndLowerCase(c.value.denominationSociale) ===
                  normalizeAndLowerCase(employeur)
              )
            }
            copy={true}
            formatCreateLabel={(value) => `Créer : ${value}`}
          />
        )}
        {/* dateDebut */}
        <DateInputComponent
          name={'dateDebut'}
          label={'Date de début :'}
          value={dateDebut}
          handleChange={handleChangeDate}
        />
        {/* dateFin */}
        <DateInputComponent
          name={'dateFin'}
          label={'Date de fin :'}
          value={dateFin}
          handleChange={handleChangeDate}
        />
        {/* dateAcquisition */}
        <DateInputComponent
          name={'dateAcquisition'}
          label={"Date d'acquisition :"}
          value={dateAcquisition}
          handleChange={handleChangeDate}
        />

        {/* detention */}
        <SelectComponent
          forAndId="detention"
          errorLabel={detention === ''}
          name="Détention :"
          isSearchable={false}
          options={optionsDetention}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsDetention.find((c) => c.value === detention)}
          required={true}
          copy={true}
        />
        {/* tauxIndivision */}
        <Input
          forAndId="tauxIndivision"
          type="number"
          name="Taux d'indivision :"
          value={tauxIndivision}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
      </div>
    </Card>
  )
}

export default GeneralInformationCardPro
