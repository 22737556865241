import React, { useState } from 'react'
import Thread from '../../layout/Thread'
import NotCorrect from '../Components/Modales/NotCorrect'
import FormSubmit from '../Components/FormSubmit'
import ModalCreatePMNotOk from './Modales/ModalCreatePMNotOk'
import ModalCreatePMOk from './Modales/ModalCreatePMOk'
import ModalUpdatePMNotOk from './Modales/ModalUpdatePMNotOk'
import ModalUpdatePMOk from './Modales/ModalUpdatePMOk'
import Siren from './Cards/Siren'
import Infos from './Cards/Infos'
import Societe from './Cards/Societe'
import Beneficiaires from './Cards/Beneficiaires'
import Etablissements from './Cards/Etablissements'
import Boss from './Cards/Boss'

const PMComponent = ({
  person,
  handleSubmit,
  sirenSearch,
  setSirenSearch,
  pappers,
  setPappers,
  infosPrincipales,
  setInfosPrincipales,
  infoBoss,
  setInfoBoss,
  infosSociete,
  setInfosSociete,
  beneficiairesEffectifs,
  setBeneficiairesEffectifs,
  etablissements,
  setEtablissements,
  modalOk,
  modalNotOk,
  setModalNotOk,
  modalUpdateOk,
  modalUpdateNotOk,
  setModalUpdateNotOk,
  modalNotCorrect,
  setModalNotCorrect,
  setSubmit,
  dataChange,
  setDataChange,
  handleGiveUp,
}) => {
  const [status, setStatus] = useState(null)
  const [errorSiren, setErrorSiren] = useState(false)
  const [messageSiren, setMessageSiren] = useState('')
  // actualisation beneficiairesToDisplay
  const name = person ? `${person.denominationSociale}` : ''
  return (
    <>
      <div className="section__title">
        <div className="section__title__content">
          <h1 className="section__title__content__h1">Formulaire Personne Morale</h1>
          {person ? <p className="section__title__content__p">{name}</p> : null}
          <Thread />
        </div>
      </div>
      <div className="section__content">
        {/* Form PM ___________________________________________________________*/}
        <FormSubmit
          dataChange={dataChange}
          handleSubmit={handleSubmit}
          handleGiveUp={handleGiveUp}
        />
        <div className="sousSection">
          {/* Siren _____________________________________________________________*/}
          <div className="cards cards--fullWidth">
            <div className="informations">
              <Siren
                siren={sirenSearch}
                setSiren={setSirenSearch}
                errorSiren={errorSiren}
                setErrorSiren={setErrorSiren}
                setPappers={setPappers}
                setStatus={setStatus}
                messageSiren={messageSiren}
                setMessageSiren={setMessageSiren}
                status={status}
                pappers={pappers}
              />
            </div>
          </div>
          <div className="cards">
            <div className="cards cards--column">
              <Infos
                infosPrincipales={infosPrincipales}
                setInfosPrincipales={setInfosPrincipales}
                setDataChange={setDataChange}
              />
              <Boss infoBoss={infoBoss} setInfoBoss={setInfoBoss} setDataChange={setDataChange} />
            </div>
            <Societe
              infosSociete={infosSociete}
              setInfosSociete={setInfosSociete}
              setSirenSearch={setSirenSearch}
              setDataChange={setDataChange}
            />
          </div>
          <div className="cards cards--fullWidth">
            <Beneficiaires
              beneficiairesEffectifs={beneficiairesEffectifs}
              setBeneficiairesEffectifs={setBeneficiairesEffectifs}
              setDataChange={setDataChange}
            />
            <Etablissements
              etablissements={etablissements}
              setEtablissements={setEtablissements}
              setDataChange={setDataChange}
            />
          </div>
        </div>
        <FormSubmit
          dataChange={dataChange}
          handleSubmit={handleSubmit}
          handleGiveUp={handleGiveUp}
          down={true}
        />

        {/* modal response ok _________________________________________________*/}
        {modalOk && <ModalCreatePMOk />}
        {/* modal response not ok _____________________________________________*/}
        {modalNotOk && (
          <ModalCreatePMNotOk
            setModalNotOk={setModalNotOk}
            setSubmit={setSubmit}
            message={'Une personne existe déjà avec cette adresse mail'}
          />
        )}
        {/* modal response update ok __________________________________________*/}
        {modalUpdateOk && <ModalUpdatePMOk />}
        {/* modal response update not ok ______________________________________*/}
        {modalUpdateNotOk && (
          <ModalUpdatePMNotOk
            setModalUpdateNotOk={setModalUpdateNotOk}
            message={'Une personne existe déjà avec cette adresse mail'}
            setSubmit={setSubmit}
          />
        )}
        {/* modal not correct __________________________________________________*/}
        {modalNotCorrect && <NotCorrect setNotCorrect={setModalNotCorrect} />}
      </div>
    </>
  )
}

export default PMComponent
