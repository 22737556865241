import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getFormIsActive } from '../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../redux/actions/actionSaveForm'
import { beautifulDate, beautifulDateNumbers } from '../../../../../services/helpers/dates'
import { postFinancier } from '../../../../../services/API/financier'
import { postSocial } from '../../../../../services/API/social'
import { postNewVL } from '../../../../../services/API/catalogue'
import BlocTable from '../../../table/BlocTable'
import NotCorrect from '../../../Components/Modales/NotCorrect'
import NotOk from '../../../Components/Modales/NotOk'
import Card from '../../../Components/Card'
import FormOperation from './Form/FormOperation'
import NewOperationCustomHead from './NewOperationCustomHead'
import FormOperationRegularize from './Form/FormOperationRegularize'
import { FE, PRODUIT_PORTEFEUILLE } from '../../../../../constants'
import TableBodyPortefeuille from './Table/TableBodyPortefeuille'
import usePersonData from '../../../../layout/usePersonData'

const NewOperationComponent = ({ bienToDisplay, product, categorie }) => {
  const { portefeuille } = bienToDisplay || {}
  const { supportsP } = portefeuille || {}
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  const [supportToChange, setSupportTochange] = useState(null)
  const [fondEuro, setFondEuro] = useState(false)
  // toggle
  const [toggleBuy, setToggleBuy] = useState(false)
  const [toggleRegularize, setToggleRegularize] = useState(false)
  // cessation
  const [oldMontant, setOldMontant] = useState(null)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  const [statusNonCote, setStatusNonCote] = useState(null)
  const [dateIsOK, setDateIsOk] = useState(true)
  // modales
  const [toggleAddBienNotOk, setToggleAddBienNotOk] = useState(false)
  const [toggleAddNotCorrect, setToggleNotCorrect] = useState(false)
  // newOperation
  const initialNewOp = {
    id: 0,
    nom: '',
    dateOperation: '',
    dateVersement: '',
    nombre: '',
    isin: '',
    valeurAchat: '',
    montant: '',
    support: {},
    achat: true,
    majRegularisation: false,
    neutralise: false,
  }
  const [newOperation, setNewOperation] = useState(initialNewOp)
  const { support, montant, dateOperation, dateVersement, majRegularisation } = newOperation || {}
  const { nombre, dateAchat, valeurAchat, nonCote, isin, dernierCours } = support || {}
  // handleClick
  const handleClick = (support, string) => {
    setSupportTochange(support)
    setFondEuro(support.dici && support.dici.c_nom === FE)
    const fondEuroOK = support.dici && support.dici.c_nom === FE
    setNewOperation({
      ...newOperation,
      nom: support.nomSP,
      dateOperation: beautifulDateNumbers(new Date()),
      dateVersement: fondEuroOK ? beautifulDateNumbers(new Date()) : '',
      montant: string === 'regulariser' ? support.montant : '',
      achat:
        string === 'achat' || string === 'regulariser' ? true : string === 'vente' ? false : null,
      isin: support.isin,
      nombre: fondEuroOK ? 1 : support.nombre,
      valeurAchat: fondEuroOK ? '' : support.dernierCours,
      majRegularisation: string === 'regulariser',
      support: {
        ...newOperation.support,
        isin: support.isin,
        nom: support.nomSP,
        nombre: fondEuroOK ? 1 : support.nombre,
        achat:
          string === 'achat' || string === 'regulariser' ? true : string === 'vente' ? false : null,
        dici: support.dici,
        valeurAchat: fondEuroOK ? '' : support.dernierCours,
        dateAchat: fondEuroOK ? '' : beautifulDateNumbers(support.dateDernierCours),
        idProduit: product?.id,
        nonCote: support.dici && support.dici.nonCote ? true : false,
        dernierCours: fondEuroOK ? '' : support.dernierCours,
        dateDernierCours: fondEuroOK ? '' : support.dateDernierCours,
      },
    })
    setOldMontant(support.montant)
    string === 'regulariser' ? setToggleRegularize(true) : setToggleBuy(true)
  }
  // form is active
  useEffect(() => {
    if (dataChange) {
      dispatch(
        getFormIsActive({
          isActive: true,
          form:
            categorie === 'PER'
              ? 'OperationPER'
              : categorie === 'retraite'
              ? 'OperationRetraite'
              : categorie === 'AV'
              ? 'OperationAV'
              : 'OperationVM',
        })
      )
    }
  }, [dataChange])
  // save form
  useEffect(() => {
    if (
      saveBien &&
      formIsActive.form ===
        (categorie === 'PER'
          ? 'OperationPER'
          : categorie === 'retraite'
          ? 'OperationRetraite'
          : categorie === 'AV'
          ? 'OperationAV'
          : 'OperationVM')
    ) {
      handlePreSubmit()
    }
  }, [saveBien])
  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (!majRegularisation) {
      if (
        (valeurAchat || valeurAchat === 0) &&
        dateAchat &&
        (nombre || nombre === 0) &&
        (dateOperation || dateVersement) &&
        (montant || montant === 0)
      ) {
        if (nonCote && !fondEuro) {
          if (!dateIsOK) {
            dispatch(
              postNewVL(
                { isin: isin, dateVL: dateAchat, valeur: parseFloat(valeurAchat) },
                setStatusNonCote
              )
            )
            if (!dernierCours) {
              setNewOperation({
                ...newOperation,
                support: {
                  ...newOperation.support,
                  dernierCours: parseFloat(valeurAchat),
                  dateDernierCours: dateAchat,
                },
              })
            }
          } else {
            handleSubmit()
          }
        } else {
          handleSubmit()
        }
      } else {
        setToggleNotCorrect(true)
      }
    } else {
      if ((nombre || nombre === 0) && dateOperation && (montant || montant === 0)) {
        handleSubmit()
      } else {
        setToggleNotCorrect(true)
      }
    }
  }
  // statusNonCote
  useEffect(() => {
    if (statusNonCote) {
      switch (statusNonCote) {
        case 200:
          handleSubmit()
          break
        case 403:
          console.log('Cet ISIN correspond à un support coté')
          setToggleAddBienNotOk(true)
          break
        case 404:
          console.log('ISIN non reconnu')
          setToggleAddBienNotOk(true)
          break
        case 409:
          console.log('Une valeur existe déjà à cette date')
          setToggleAddBienNotOk(true)
          break
        default:
          console.log('Un problème est survenu, veuillez réessayer')
          setToggleAddBienNotOk(true)
      }
      setStatusNonCote(null)
    }
  }, [statusNonCote])

  // submit
  const handleSubmit = () => {
    const data = {
      ...bienToDisplay,
      // idCatalogue:product.id,
      operations: bienToDisplay.operations.concat(newOperation),
      montantTotalCalcule: bienToDisplay.montantTotalCalcule + montant,
      liquidite: fondEuro ? bienToDisplay.liquidite : bienToDisplay.liquidite + montant,
    }
    categorie === 'PER' || categorie === 'retraite'
      ? dispatch(
          postSocial(
            categorie === 'PER' ? 'per' : 'retraite',
            person.id,
            isPP,
            false,
            data,
            setStatus
          )
        )
      : dispatch(
          postFinancier(
            categorie === 'VM' ? 'produitFinancierVM' : 'produitFinancierAV',
            person.id,
            isPP,
            false,
            data,
            setStatus
          )
        )
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status) {
      if (status < 400) {
        majRegularisation
          ? toast.success(`Régularisation au ${beautifulDate(dateOperation)} enregistrée`)
          : toast.success('Nouvelle opération enregistrée')
      }
      setToggleBuy(false)
      setToggleRegularize(false)
      setStatus(null)
    }
  }, [status])
  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setToggleAddBienNotOk(false)
  }
  // titles
  const titles =
    categorie === 'AV' || categorie === 'retraite'
      ? [
          'N°',
          'Acheter',
          'Vendre',
          'Nom',
          'ISIN',
          'Montant',
          'Qté',
          'Cours',
          'Au...',
          'PAM',
          '+/- value',
          'DICI',
          'Régulariser',
          'Acheter',
          'Vendre',
        ]
      : [
          'N°',
          'Acheter',
          'Vendre',
          'Nom',
          'ISIN',
          'Montant',
          'Qté',
          'Cours',
          'Au...',
          'PAM',
          '+/- value',
          'DICI',
          'Acheter',
          'Vendre',
        ]
  const title = `Portefeuille (${supportsP?.length})`

  return (
    <>
      <Card title={title} open={true} h6={true}>
        <>
          <NewOperationCustomHead categorie={categorie} bienToDisplay={bienToDisplay} />
          {supportsP && (
            <BlocTable
              data={supportsP}
              titles={titles}
              tableType={PRODUIT_PORTEFEUILLE}
              children={<TableBodyPortefeuille handleClick={handleClick} />}
              entry={10}
              title={title}
            />
          )}
        </>
      </Card>
      {toggleBuy ? (
        <FormOperation
          setDataChange={setDataChange}
          setToggleBuy={setToggleBuy}
          initialNewOp={initialNewOp}
          newOperation={newOperation}
          setNewOperation={setNewOperation}
          handleSubmitNewOp={handlePreSubmit}
          supportToChange={supportToChange}
          disponibilite={bienToDisplay.portefeuille.totalLiquidite}
          newSupport={false}
          categorie={categorie}
          setDateIsOk={setDateIsOk}
          oldMontant={oldMontant}
          fondEuro={fondEuro}
        />
      ) : null}
      {toggleRegularize ? (
        <FormOperationRegularize
          setDataChange={setDataChange}
          setToggleRegularize={setToggleRegularize}
          initialNewOp={initialNewOp}
          newOperation={newOperation}
          setNewOperation={setNewOperation}
          handleSubmit={handlePreSubmit}
          supportToChange={supportToChange}
          categorie={categorie}
          oldMontant={oldMontant}
          fondEuro={fondEuro}
        />
      ) : null}
      {toggleAddBienNotOk && (
        <NotOk setNotOK={setToggleAddBienNotOk} closeModale={closeToggleAddBien} />
      )}
      {toggleAddNotCorrect && <NotCorrect setNotCorrect={setToggleNotCorrect} />}
    </>
  )
}

export default NewOperationComponent
