// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
import { getMandatError, getMandatLoad, getMandatSuccess } from '../actions/actionGetMandat'
// actions imports

// REDUCER // ______________________________________________________________

// initial state
const initialStateMandat = {
  isLoading: false,
  mandat: null,
  error: '',
}
// reducer
export const getMandatReducer = createReducer(initialStateMandat, (builder) => {
  return builder
    .addCase(getMandatLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getMandatSuccess, (draft, action) => {
      draft.isLoading = false
      draft.mandat = action.payload
      draft.error = ''
      return
    })
    .addCase(getMandatError, (draft, action) => {
      draft.isLoading = false
      draft.mandat = null
      draft.error = action.payload
      return
    })
})