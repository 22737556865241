// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get dicis
 * @reduxAction
 */

export const getDicisLoad = createAction('get-dicis-load')

export const getDicisSuccess = createAction('get-dicis-success', (dicis) => {
  return {
    payload: dicis,
  }
})

export const getDicisError = createAction('get-dicis-error', (error) => {
  return {
    payload: error,
  }
})
