import React from 'react'
import { COLORS } from '../../../../../constants'
import PieChartComponent from '../../../Charts/PieChartComponent'
import LegendPieChart from '../../../Charts/LegendPieChart'

const PassifsDettesPieChart = ({ dettes, detenteurs, print }) => {
  const { detteDivers, nomDetenteurs, valeurDetenteurs } = dettes || {}
  // colors
  const colors = [
    { fill: COLORS.colorRepartition01, color: 'repartition01' },
    { fill: COLORS.colorRepartition02, color: 'repartition02' },
  ]
  // dettes
  const arrayDettes = dettes.ldettes
    .filter(
      (passif) => new Date(passif.dateFin) >= new Date() || passif.dateFin === '' || !passif.dateFin
    )
    .map((passif) => ({ nom: passif.refDette, valeur: passif.montantRestant }))
  arrayDettes.push({ nom: 'Non affecté', valeur: detteDivers.montant })
  const dataDettes = arrayDettes.map((det, index) =>
    det.nom !== 'Non affecté'
      ? {
          name: det.nom,
          value: det.valeur,
          fill: colors[index % colors.length].fill,
          color: colors[index % colors.length].color,
        }
      : {
          name: det.nom,
          value: det.valeur,
          fill: COLORS.colorFontLight,
          color: 'divers',
        }
  )
  // detenteurs
  const arrayDetenteurs = []
  for (let i = 0; i < nomDetenteurs.length; i++) {
    arrayDetenteurs.push({ nom: nomDetenteurs[i], valeur: valeurDetenteurs[i] })
  }
  arrayDetenteurs.push({ nom: 'Non affecté', valeur: detteDivers.montant })
  const dataDetenteurs = arrayDetenteurs.map((det) => ({ name: det.nom, value: det.valeur }))
  const data = !detenteurs ? dataDettes : dataDetenteurs
  // title
  const title = !detenteurs ? 'Répartition' : 'Détenteurs'
  return (
    <>
      <PieChartComponent data={data} title={title} detenteurs={detenteurs} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title} detenteurs={detenteurs}/>
    </>
  )
}

export default PassifsDettesPieChart
