import React from 'react'
import { optionsRoles, optionsTrueOrFalse } from '../../../data/options/options'
import useFirms from '../Firm/useFirms'
import Input from '../Components/Input'
import SelectComponent from '../Components/SelectComponent'
import DateInputComponent from '../Components/DateInputComponent'

const InputsUser = ({ handleSubmit, handleChange, handleChangeDate, fields, update }) => {
  const { nom, prenom, email, dateNaissance, roles, actif, cabinet } = fields || {}
  const optionsFirms = useFirms()

  return (
    <div className="informations">
      {/* lastName */}
      <Input
        errorLabel={fields.nom === ''}
        required={true}
        forAndId="nom"
        type="text"
        name="Nom :"
        value={nom}
        onChange={(e) => handleChange(e)}
        placeholder="MARTIN"
      />
      {/* Prénom */}
      <Input
        errorLabel={fields.prenom === ''}
        required={true}
        forAndId="prenom"
        type="text"
        name="Prénom :"
        value={prenom}
        onChange={(e) => handleChange(e)}
        placeholder="Joël"
      />
      {/* mail */}
      <Input
        errorLabel={fields.email === ''}
        forAndId="email"
        type="email"
        name="Mail :"
        disabled={update ? true : false}
        required={true}
        value={email}
        onChange={(e) => handleChange(e)}
        placeholder="exemple@gmail.com"
      />
      {/* birthDate */}
      <DateInputComponent
        name={'dateNaissance'}
        label={'Date de naissance :'}
        value={dateNaissance}
        handleChange={handleChangeDate}
      />
      {/* si update user ajout select role et cabinet*/}
      {update && (
        <>
          {/* roles */}
          <SelectComponent
            forAndId="roles"
            name="Rôle :"
            isClearable={true}
            options={optionsRoles}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={
              optionsRoles !== undefined &&
              roles &&
              optionsRoles.find((c) => c.value.id === roles[0].id)
            }
          />
          {/* actif */}
          <SelectComponent
            forAndId="actif"
            name="Actif :"
            isSearchable={false}
            options={optionsTrueOrFalse}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsTrueOrFalse.find((c) => c.value === actif)}
          />
          {optionsFirms && (
            <SelectComponent
              forAndId="cabinet"
              name="Cabinet :"
              isClearable={true}
              isSearchable={true}
              options={optionsFirms}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={
                optionsFirms !== undefined &&
                cabinet &&
                optionsFirms.find((c) => c.value === cabinet.id)
              }
            />
          )}
        </>
      )}
      <div className="informations__buttons">
        <div className="button button--red-red width--18" onClick={(e) => handleSubmit(e)}>
          Enregistrer
        </div>
      </div>
    </div>
  )
}

export default InputsUser
