import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveFiles } from '../../../services/helpers/files'
import { getFormIsActive } from '../../../redux/actions/actionFormIsActive'
import { transformDateForFile } from '../../../services/helpers/dates'
import InputsAddFiles from './InputsAddFiles'
import ModalAddFileNotCorrect from './ModalAddFileNotCorrect'
import { getSaveForm } from '../../../redux/actions/actionSaveForm'
import * as doc from '../../../constants'
import Modale from '../Components/Modale'
import { toast } from 'react-toastify'
import ToastFileError from './ToastFileError'
import usePersonData from '../../layout/usePersonData'

const ModaleFiles = ({ data, tableType, setModal, newData, setDataChange }) => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  // bien
  const bien = useSelector((state) => state.getBien.bien)
  const bienType = useSelector((state) => state.getBienType.type)
  const categorie = bienType ? bienType.categorie : null
  const bienId = bien ? bien.id : null
  // file
  // maj typeFichierGED
  const getTypeFichierGed = (tableType) => {
    switch (tableType) {
      case doc.DOC_PERSON_IDENTITY:
        return 'IDENTITE'
      case doc.DOC_PERSON_HOME:
        return 'DOMICILE'
      case doc.DOC_PERSON_BIRTH:
        return 'NAISSANCE'
      case doc.DOC_PERSON_FAMILY:
        return 'FAMILLE'
      case doc.DOC_PERSON_DIVERS:
        return 'DIVERS'
      default:
        return 'PATRIMOINE'
    }
  }
  const typeFichierGED = useMemo(() => getTypeFichierGed(tableType), [tableType])
  const [newFile, setNewFile] = useState({
    typeFichierGED: getTypeFichierGed(tableType),
    dateDocument: newData ? newData.dateDocument : '',
    dateValidite: newData ? (newData.dateValidite ? newData.dateValidite : '') : '',
    typeGed: newData
      ? newData.typeGed
      : categorie === 'FINANCIER'
      ? 'docFinancier'
      : categorie === 'SOCIAL'
      ? 'docSocial'
      : '',
  })
  const { dateDocument, dateValidite, typeGed } = newFile || {}
  const [files, setFiles] = useState([])
  // urlConfig
  const urlConfig = {
    [doc.DOC_BIEN_IMMO]: {
      urlPart: '/patrimoine/saveFilesBien',
      idParamName: 'bienId',
    },
    [doc.DOC_BIEN_PRO]: {
      urlPart: '/patrimoine/saveFilesBienPro',
      idParamName: 'bienId',
    },
    [doc.DOC_BIEN_AUTRE]: {
      urlPart: '/patrimoine/saveFilesAutreActifs',
      idParamName: 'bienId',
    },
    [doc.DOC_PASSIF_EMPRUNT]: {
      urlPart: '/passif/saveFilesEmprunt',
      idParamName: 'idPassif',
    },
    [doc.DOC_PASSIF_DETTE]: {
      urlPart: '/passif/saveFilesDette',
      idParamName: 'idPassif',
    },
    [doc.DOC_REVENU_FONCIER]: {
      urlPart: '/revenu/saveFilesRevenuFoncier',
      idParamName: 'revenuId',
    },
    [doc.DOC_REVENU_MOBILIER]: {
      urlPart: '/revenu/saveFilesRevenuMobilier',
      idParamName: 'revenuId',
    },
    [doc.DOC_REVENU_PRO]: {
      urlPart: '/revenu/saveFilesRevenuProfessionnel',
      idParamName: 'revenuId',
    },
    [doc.DOC_REVENU_PENSION]: {
      urlPart: '/revenu/saveFilesPensionRente',
      idParamName: 'revenuId',
    },
    [doc.DOC_REVENU_AUTRE]: {
      urlPart: '/revenu/saveFilesAutreRevenu',
      idParamName: 'revenuId',
    },
    [doc.DOC_CHARGE_GENERALE]: {
      urlPart: '/charge/saveFilesChargeGenerale',
      idParamName: 'chargeId',
    },
    [doc.DOC_CHARGE_DEDUCTIBLE]: {
      urlPart: '/charge/saveFilesChargeDeductibleRI',
      idParamName: 'chargeId',
    },
    [doc.DOC_CHARGE_REDUCTION]: {
      urlPart: '/charge/saveFilesChargeOuvrantDroitReductionRI',
      idParamName: 'chargeId',
    },
    [doc.DOC_CHARGE_PENSION]: {
      urlPart: '/charge/saveFilesPension',
      idParamName: 'chargeId',
    },
    [doc.DOC_CHARGE_IMPOT]: {
      urlPart: '/charge/saveFilesImpotEtTaxe',
      idParamName: 'chargeId',
    },
    [doc.DOC_CHARGE_EMPRUNT]: {
      urlPart: '/charge/saveFilesEmpruntEtDette',
      idParamName: 'chargeId',
    },
    [doc.DOC_FINANCIER_AV]: {
      urlPart: '/financier/saveFilesAV',
      idParamName: 'Id',
    },
    [doc.DOC_FINANCIER_VM]: {
      urlPart: '/financier/saveFilesVM',
      idParamName: 'Id',
    },
    [doc.DOC_FINANCIER_COMPTE]: {
      urlPart: '/financier/saveFilesCB',
      idParamName: 'Id',
    },
    [doc.DOC_SOCIAL_PER]: {
      urlPart: '/social/saveFilesPER',
      idParamName: 'Id',
    },
    [doc.DOC_SOCIAL_RETRAITE]: {
      urlPart: '/social/saveFilesRetraite',
      idParamName: 'Id',
    },
    [doc.DOC_SOCIAL_PREVOYANCE]: {
      urlPart: '/social/saveFilesSocialPrevoyance',
      idParamName: 'Id',
    },
  }
  const setUrl = (tableType, file) => {
    const config = urlConfig[tableType]
    if (!config)
      return `${doc.API_BASE_URL}/fichiers/saveFiles?personneId=${
        person ? person.id : 0
      }&typeFichierGED=${typeFichierGED}&pp=${isPP}&dateDocument=${transformDateForFile(
        dateDocument
      )}&dateValidite=${dateValidite && transformDateForFile(dateValidite)}&typeGed=${makeFileName(
        file
      )}`
    return `${doc.API_BASE_URL}${config.urlPart}?${
      config.idParamName
    }=${bienId}&pp=${isPP}&typeGed=${makeFileName(file)}&dateCreation=${transformDateForFile(
      dateDocument
    )}`
  }
  // modales
  const [modalAddFileNotCorrect, setModalAddFileNotCorrect] = useState(false)
  // responses
  const [status, setStatus] = useState(null)
  // getFiles
  const getFiles = useCallback(
    (array) => {
      const validFiles = array.filter(
        (file) => file.type === 'application/pdf' && file.size <= 104857600
      )
      setFiles(validFiles)
      // const errors = []
      for (const file of array) {
        if (file.size > 104857600) {
          toast.error(<ToastFileError name={file.name} response={`est trop gros (100Mo max).`} />, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          })
        }
        if (file.type !== 'application/pdf') {
          toast.error(<ToastFileError name={file.name} response={`n'est pas un PDF.`} />, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          })
        }
      }
    },
    [setFiles]
  )
  // drag and drop
  const handleDrop = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      const filesEvent = Array.from(e.dataTransfer.files)
      getFiles(filesEvent)
    },
    [getFiles]
  )
  const handleDragOver = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])
  // handleChange pour les files
  const handleFileEvent = useCallback(
    (e) => {
      const filesEvent = Array.from(e.target.files)
      getFiles(filesEvent)
    },
    [getFiles]
  )
  // makeFileName
  const makeFileName = (file) => {
    return newData ? newData.typeGed : `${typeGed}-${libelle}-${file.name}`
  }
  // enregistrement
  const handleAddFiles = () => {
    setDataChange(true)
    if (typeGed && files) {
      for (const file of files) {
        dispatch(saveFiles(file, setUrl(tableType, file), person.id, isPP, setModal, categorie))
      }
    } else {
      setModalAddFileNotCorrect(true)
    }
  }

  // response saveFiles status
  useEffect(() => {
    if (status) {
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
      setStatus(null)
      setModal(false)
    }
  }, [status])

  // libelle supplementaire pur financier et social
  const [libelle, setLibelle] = useState('')

  return (
    <Modale close={() => setModal(false)} title={newData ? 'Modifier' : 'Ajouter'}>
      <div className="informations">
        <InputsAddFiles
          handleFileEvent={handleFileEvent}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          files={files}
          handleAddFiles={handleAddFiles}
          update={newData ? true : false}
          newFile={newFile}
          setNewFile={setNewFile}
          fichiers={data}
          categorie={categorie}
          libelle={libelle}
          setLibelle={setLibelle}
          typeFichierGED={typeFichierGED}
        />
        {modalAddFileNotCorrect && (
          <ModalAddFileNotCorrect setModalAddFileNotCorrect={setModalAddFileNotCorrect} />
        )}
      </div>
    </Modale>
  )
}

export default ModaleFiles
