import React from 'react'
import { dateFormateComplete } from '../../../services/helpers/dates'

const BodyUsersPrint = ({ element, index }) => {
  return (
    <>
      {/* nom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">{!element.nom ? '-' : element.nom}</p>
      </div>
      {/* prenom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Prénom</p>
        <p className="print__table__bloc__line--value">{!element.prenom ? '-' : element.prenom}</p>
      </div>
      {/* email */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Mail</p>
        <p className="print__table__bloc__line--value">{!element.email ? '-' : element.email}</p>
      </div>
      {/* dateNaissance */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Date de naissance</p>
        <p className="print__table__bloc__line--value">
          {!element.dateNaissance ? '-' : dateFormateComplete(element.dateNaissance)}
        </p>
      </div>
      {/* roles */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Rôle</p>
        <p className="print__table__bloc__line--value">
          {element.roles.length > 0 &&
            (element.roles[0].id === '' || !element.roles[0].id
              ? '-'
              : element.roles[0].id === 1
              ? 'USER'
              : element.roles[0].id === 2
              ? 'ADMIN'
              : 'CONSEILLER')}
        </p>
      </div>
      {/* createdAt */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Créé le</p>
        <p className="print__table__bloc__line--value">
          {!element.createdAt ? '-' : dateFormateComplete(element.createdAt)}
        </p>
      </div>
      {/* updatedAt */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Modifié le</p>
        <p className="print__table__bloc__line--value">
          {!element.updatedAt || element.updatedAt === element.createdAt
            ? '-'
            : dateFormateComplete(element.updatedAt)}
        </p>
      </div>
      {/* cabinet */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Cabinet</p>
        <p className="print__table__bloc__line--value">
          {!element.cabinet ? '-' : element.cabinet.nomCabinet}
        </p>
      </div>
    </>
  )
}

export default BodyUsersPrint
