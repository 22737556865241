import React from 'react'
import { stringToCapitalize } from '../../../../../../services/helpers/strings'

const BodyAssurances = ({ element, handleShow }) => {
  return (
    <>
      {/* libelle */}
      <td className="tbody__tr__td bold" onClick={() => handleShow(element)}>
        {!element.libelle ? <p>-</p> : stringToCapitalize(element.libelle)}
      </td>
      {/* montantTotalCalcule */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {`${element.montantTotalCalcule.toLocaleString(undefined, {
          minimumFractionDigits: 4,
        })} €`}
      </td>
    </>
  )
}

export default BodyAssurances
