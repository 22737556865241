import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { optionsTrueOrFalse } from '../../../../../../data/options/options'
import { optionsNoteSupport } from '../../../../../../data/options/optionsFinancier'
import SelectComponent from '../../../../Components/SelectComponent'
import Input from '../../../../Components/Input'
import DateInputComponent from '../../../../Components/DateInputComponent'

const CreationSupportInput = ({
  handleChange,
  handleChangeDate,
  handleSubmitNewSupp,
  categorie,
  support,
  fondEuro,
  cours,
  create,
}) => {
  const { isin, nom, dici_url, note, m_nom } = support || {}
  const { dateVL, valeur } = cours || {}
  // dataOperationChange
  const [dataNewSupportChange, setDataNewSupportChange] = useState(false)
  useEffect(() => {
    if (nom !== '' && m_nom !== 0 && isin !== '') {
      setDataNewSupportChange(true)
    } else {
      setDataNewSupportChange(false)
    }
  }, [nom, m_nom, isin, dateVL, valeur])
  return (
    <>
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* m_nom */}
          <Input
            forAndId="m_nom"
            errorLabel={m_nom === ''}
            type="text"
            name="Fournisseur :"
            value={m_nom}
            onChange={(e) => handleChange(e)}
            copy={true}
            required={true}
          />
          {/* nom */}
          <Input
            forAndId="nom"
            errorLabel={nom === ''}
            type="text"
            name="Libellé :"
            value={nom}
            onChange={(e) => handleChange(e)}
            copy={true}
            required={true}
          />
          {/* fondEnEuro */}
          {(categorie === 'AV' || categorie === 'retraite') && create ? (
            <SelectComponent
              forAndId="fondEnEuro"
              name="Fond en euro ?"
              options={optionsTrueOrFalse}
              onChange={(e, name) => handleChange(e, name)}
              value={optionsTrueOrFalse.find((c) => c.value === fondEuro)}
            />
          ) : null}

          {/* isin */}
          {create ? (
            <Input
              forAndId="isin"
              errorLabel={isin === ''}
              type="text"
              name="ISIN :"
              value={isin}
              onChange={(e) => handleChange(e)}
              copy={true}
            />
          ) : null}
          {!fondEuro && create ? (
            <>
              <DateInputComponent
                name={'dateVL'}
                label={'Date du cours :'}
                value={dateVL}
                handleChange={handleChangeDate}
              />
              {/* valeur */}
              <Input
                forAndId="valeur"
                type="text"
                name={`Cours au ${
                  dateVL ? format(new Date(dateVL), 'dd/MM/yyyy') : format(new Date(), 'dd/MM/yyyy')
                }:`}
                value={valeur}
                onChange={(e) => handleChange(e)}
                copy={true}
                inputMode="decimal"
              />
            </>
          ) : null}
        </div>

        <div className="informations">
          {/* dici */}
          <Input
            forAndId="dici_url"
            type="text"
            name="DICI (url) :"
            value={dici_url}
            onChange={(e) => handleChange(e)}
            copy={true}
          />
          {/* note */}
          <SelectComponent
            forAndId="note"
            name="Note :"
            isSearchable={true}
            options={optionsNoteSupport}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsNoteSupport.find((c) => c.value === note)}
            copy={true}
          />
        </div>
        <div className="informations__buttons">
          {dataNewSupportChange ? (
            <button
              className="button button--red-red width--7"
              onClick={(e) => handleSubmitNewSupp(e)}
            >
              {create ? 'Ajouter' : 'Enregistrer'}
            </button>
          ) : (
            <div className="button button--grey width--7">{create ? 'Ajouter' : 'Enregistrer'}</div>
          )}
        </div>
      </div>
    </>
  )
}

export default CreationSupportInput
