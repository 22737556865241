import React from 'react'
import { PiUserSwitchFill } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { getActifs } from '../../../services/API/actifs'
import { getPassifs } from '../../../services/API/passifs'
import { getRevenus } from '../../../services/API/revenus'
import { getCharges } from '../../../services/API/charges'
import { getFinancier } from '../../../services/API/financier'
import { getSocial } from '../../../services/API/social'
import { getBienSuccess } from '../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../redux/actions/actionGetBienType'

const ButtonUserBack = ({ setText, formIsActive }) => {
  const dispatch = useDispatch()
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // maj personne
  const handleGetMainUser = () => {
    dispatch(getPerson(persons.referentPP[0]))
    // dispatch(getPersonId(persons.referentPP[0].id))
    dispatch(postPersonType('referentPP'))
    dispatch(getActifs(1, true))
    dispatch(getPassifs(1, true))
    dispatch(getRevenus(1, true))
    dispatch(getCharges(1, true))
    dispatch(getFinancier(1, true))
    dispatch(getSocial(1, true))
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }
  return formIsActive ? (
    <div>
      <PiUserSwitchFill />
    </div>
  ) : (
    <button
      className="button"
      onMouseEnter={() => setText(persons.referentPP[0].prenom)}
      onMouseLeave={() => setText('')}
      value={persons.referentPP[0].prenom}
      onClick={handleGetMainUser}
    >
      <PiUserSwitchFill />
    </button>
  )
}

export default ButtonUserBack
