import React from 'react'
import Card from '../../components/Components/Card'
import BlocTable from '../../components/table/BlocTable'
import { ADMIN_FIRMS } from '../../../constants'
import TableBodyFirm from './TableBodyFirm'

const FirmsTableCard = ({ dataFirms, setDataFirms, setModalAddFirm }) => {

  return (
    <Card title={'Liste des cabinets'} open={true}>
      {dataFirms && dataFirms.length > 0 ? (
        <div className="informations">
          <BlocTable
            titles={['N°', 'Nom', 'Adresse', 'Code postal', 'Ville', 'Modifier', 'Supprimer']}
            data={dataFirms}
            setData={setDataFirms}
            tableType={ADMIN_FIRMS}
            children={<TableBodyFirm />}
            title={'Liste des cabinets'}
          />
        </div>
      ) : (
        <p>Aucun cabinet à afficher</p>
      )}
    </Card>
  )
}

export default FirmsTableCard
