import React from 'react'
import { ImDownload } from 'react-icons/im'
import { beautifulDateAndTime } from '../../../../services/helpers/dates'

const BodySignToClassify = ({ element, handleClassify }) => {
  const getBeautifulName = (str) => {
    return str.split('-').slice(1).join('-')
  }

  return (
    <>
      {/* classer */}
      <td
        className="tbody__tr__td  tbody__tr__td--pointer"
        data-label="Classer"
        onClick={() => handleClassify(element)}
      >
        <div className="tbody__tr__td__button">
          <ImDownload />
        </div>
      </td>
      {/* fichier */}
      <td className="tbody__tr__td">
        {!element.fileName ? (
          <p className="informations__line__notxt">non défini</p>
        ) : (
          getBeautifulName(element.fileName)
        )}
      </td>
      {/* date créé le */}
      <td className="tbody__tr__td">
        {!element.createdAt ? (
          <p className="informations__line__notxt">non défini</p>
        ) : (
          beautifulDateAndTime(element.createdAt)
        )}
      </td>
    </>
  )
}

export default BodySignToClassify
