import React, { useEffect } from 'react'
import { sortByType } from '../../../../../../services/helpers/sort'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyFrais from './BodyFrais'
import BodyFraisPrint from './BodyFraisPrint'

const TableBodyFrais = ({
  data,
  setData,
  setNewData,
  setModalUpdate,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  setDataChange,
  print,
}) => {
  // handleEdit
  const handleEdit = (prod) => {
    setDataChange(true)
    setNewData(prod)
    setModalUpdate(true)
  }
  // handleDelete
  const handleDelete = (prod) => {
    console.log('test')
    setDataChange(true)
    setData([...data.filter((el) => el.annee !== prod.annee)])
  }

  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Année':
          giveAType('annee')
          break
        case 'Performance Fonds':
          giveAType('tauxPerformanceFE')
          break
        case 'Frais de gestion':
          giveAType('tauxFraisGestion')
          break
        case 'Périodicité FG':
          giveAType('periodiciteFraisGestion')
          break
        case 'Droit de garde':
          giveAType('tauxDroitGarde')
          break
        case 'Périodicité DG':
          giveAType('periodiciteDroitGarde')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyFraisPrint /> : <BodyFrais />}
      print={print}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  )
}

export default TableBodyFrais
