import { useEffect, useState } from 'react'

const useMediaQueries = (width) => {
  // viewport width
  const [isMobile, setIsMobile] = useState(null)
  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${width}px)`)
    const handleMediaQueryChange = (e) => {
      setIsMobile(e.matches)
    }
    mediaQuery.addEventListener('change', handleMediaQueryChange)
    // Vérifie l'état initial
    setIsMobile(mediaQuery.matches)
    // Supprime l'écouteur lors du démontage
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
    }
  }, [width])

  return isMobile
}

export default useMediaQueries
