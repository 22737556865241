import React from 'react'
import { FE } from '../../../../../../constants'
import { dateFormateComplete } from '../../../../../../services/helpers/dates'
import { FaCheckSquare } from 'react-icons/fa'

const BodyPortefeuillePrint = ({ element, index }) => {
  return (
    <>
      {/* nomSP */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">{!element.nomSP ? '-' : element.nomSP}</p>
      </div>
      {/* isin */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">ISIN</p>
        <p className="print__table__bloc__line--value">{!element.isin ? '-' : element.isin}</p>
      </div>
      {/* montant */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Montant</p>
        <p className="print__table__bloc__line--value">
          {!element.montant
            ? '-'
            : `${element.montant.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
      {(element.dici && element.dici.c_nom !== FE) || !element.dici ? (
        <>
          {/* quantite */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Quantité</p>
            <p className="print__table__bloc__line--value">
              {!element.quantite
                ? '-'
                : element.quantite.toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })}
            </p>
          </div>
          {/* dernierCours */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Cours</p>
            <p className="print__table__bloc__line--value">
              {!element.dernierCours
                ? '-'
                : `${element.dernierCours.toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} €`}
            </p>
          </div>
          {/* dateDernierCours */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Au...</p>
            <p className="print__table__bloc__line--value">
              {!element.dateDernierCours ? '-' : dateFormateComplete(element.dateDernierCours)}
            </p>
          </div>
        </>
      ) : (
        <>
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Fond Euro</p>
            <p className="print__table__bloc__line--icon">
              <FaCheckSquare />
            </p>
          </div>
        </>
      )}
      {/* pam */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">PAM</p>
        <p className="print__table__bloc__line--value">
          {!element.pam
            ? '-'
            : `${element.pam.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
      {/* +/- value */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">+/- value</p>
        <p className="print__table__bloc__line--value">
          {`${
            (element.dici && element.dici.c_nom !== FE) || !element.dici
              ? (((element.dernierCours - element.pam) / element.pam) * 100).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 4,
                  }
                )
              : (((element.montant - element.pam) / element.pam) * 100).toLocaleString(undefined, {
                  minimumFractionDigits: 4,
                })
          } %`}
        </p>
      </div>
    </>
  )
}

export default BodyPortefeuillePrint
