import { getAge } from './dates'

// calculate taux usufruit
export const handleCalculateTaux = (dateNaissance) => {
  let age = getAge(new Date(dateNaissance))
  if (age < 12) {
    age = 12
  }
  return Math.ceil(11 - ((age - 1) / 10 + 1)) / 10
}
// calculate valeurDetenue
export const calculValeurDetenue = (
  det,
  newValeurTotale,
  detenteursToDisplay,
) => {
  return det.tauxUsufruit === 0 || det.tauxUsufruit === '' || det.tauxUsufruit === '0'
    ? det.tauxNuePropriete === 0 || det.tauxNuePropriete === '' || det.tauxNuePropriete === '0'
      ? (newValeurTotale * det.tauxPleinePropriete) / 100
      : (newValeurTotale * det.tauxPleinePropriete) / 100 +
        calculateNP(det, newValeurTotale, detenteursToDisplay)
    : (newValeurTotale * det.tauxPleinePropriete) / 100 + calculateUS(det, newValeurTotale)
}
// calculate US
export const calculateUS = (det, newValeurTotale) => {
  if (
    det.tauxUsufruit !== 0 &&
    det.tauxUsufruit !== '0' &&
    det.tauxUsufruit !== '' &&
    det.tauxUsufruit
  ) {
    return ((newValeurTotale * det.tauxUsufruit) / 100) * det.tauxUsufruitCalcule
  } else {
    return 0
  }
}
// calculate NP
export const calculateNP = (det, newValeurTotale, detenteursToDisplay) => {
  if (det.tauxNuePropriete !== 0 && det.tauxNuePropriete !== '0' && det.tauxNuePropriete !== '') {
    const usLength = detenteursToDisplay.filter(
      (el) => el.tauxUsufruit !== 0 && el.tauxUsufruit !== '' && el.tauxUsufruit !== '0'
    ).length
    if (usLength !== 0) {
      const totalUSCalcul = detenteursToDisplay.map((el) =>
        el.tauxUsufruit !== 0 && el.tauxUsufruit !== '' && el.tauxUsufruit !== '0'
          ? el.tauxUsufruitCalcule
          : 0
      )
      return (
        ((newValeurTotale * det.tauxNuePropriete) / 100) *
        (1 - totalUSCalcul.reduce((acc, curr) => acc + curr) / usLength)
      )
    } else {
      return 0
    }
  } else {
    return 0
  }
}
export const updateDetenteurs = (el, newValeurTotale, detenteursToDisplay) => {
  return {
    ...el,
    valeurDetenue: calculValeurDetenue(
      el,
      newValeurTotale,
      detenteursToDisplay,
    ),
    valeurPP: (newValeurTotale * parseInt(el.tauxPleinePropriete)) / 100,
    valeurUS: calculateUS(el, newValeurTotale),
    valeurNP: calculateNP(el, newValeurTotale, detenteursToDisplay),
  }
}
