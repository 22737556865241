import React from 'react'
import {
  dataEmpruntAdditionnal,
  dataEmpruntGeneral,
} from '../../../../../../data/informations/passifs'
import InformationsCardPrint from '../../../../Informations/InformationsCardPrint'
import BienInfoPrintDetenteurEtDoc from '../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'
import { DOC_PASSIF_EMPRUNT } from '../../../../../../constants'
import { useSelector } from 'react-redux'

const PassifEmpruntInfoToPrint = ({ elementToDisplay }) => {
  const properties = useSelector((state) => state.getProperties.properties)
  const { detenteurs, fichiers } = elementToDisplay || {}
  return (
    <>
      <InformationsCardPrint
        data={dataEmpruntGeneral(
          elementToDisplay,
          null,
          elementToDisplay.idActif ? properties : null,
          null
        )}
        title={elementToDisplay.refEmprunt}
      />
      <InformationsCardPrint
        data={dataEmpruntAdditionnal(elementToDisplay)}
        title={'Informations complémentaires'}
      />
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={DOC_PASSIF_EMPRUNT}
      />
    </>
  )
}

export default PassifEmpruntInfoToPrint
