import React from 'react'
import { FE } from '../../../../../../constants'
import { FaCheckSquare } from 'react-icons/fa'

const BodySupportsPrint = ({ element, index }) => {
  return (
    <>
      {/* m_nom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Fournisseur</p>
        <p className="print__table__bloc__line--value">{!element.m_nom ? '-' : element.m_nom}</p>
      </div>
      {/* nom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Libellé</p>
        <p className="print__table__bloc__line--value">{!element.nom ? '-' : element.nom}</p>
      </div>
      {/* isin */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">ISIN</p>
        <p className="print__table__bloc__line--value">{!element.isin ? '-' : element.isin}</p>
      </div>
      {/* note */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Note</p>
        <p className="print__table__bloc__line--value">{!element.note ? '-' : element.note}</p>
      </div>
      {element.c_nom === FE ? (
        <div className={'print__table__bloc__line'}>
          <div className="print__table__bloc__line--number"></div>
          <p className="print__table__bloc__line--title">Fond Euro</p>
          <p className="print__table__bloc__line--icon">
            <FaCheckSquare />
          </p>
        </div>
      ) : null}
    </>
  )
}

export default BodySupportsPrint
