import { format } from "date-fns"

/**
 * It takes a date, and returns a string with the date in a beautiful format.
 * @param date - The date to format.
 * @returns A function that takes a date as an argument and returns a string.
 */
export const beautifulDate = (date) => {
  if (date) {
    const theDate = new Date(date)
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    return theDate.toLocaleDateString('fr-CA', options)
  } else return
}
/**
 * It takes a date in the format of "2019-01-01" and returns it in the format of "1 janvier 2019".
 * @param date - the date you want to format
 * @returns A function that takes a date as an argument and returns a string.
 */
export const beautifulDateNumbers = (date) => {
  if (date) {
    const theDate = new Date(date)
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return theDate.toLocaleDateString('fr-CA', options)
  } else return
}
export const formatedDate = (date) => {
  if (date) {
    var year = date.getFullYear()
    var month = String(date.getMonth() + 1).padStart(2, '0') // Les mois vont de 0 à 11, donc ajoutez 1 et formatez sur 2 chiffres
    var day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  } else return
}
/**
 * It takes a date string and returns a formatted date string.
 * @param date - the date you want to format
 * @returns A string
 */
export const beautifulDateAndTime = (date) => {
  const theDate = new Date(date)
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }
  return theDate.toLocaleDateString('fr-CA', options)
}
/**
 * It takes a date string, converts it to a Date object, then converts it to a string in the format of
 * 'month/year' using the 'fr-CA' locale.
 * @param date - the date you want to format
 * @returns A function that takes a date as an argument and returns a string.
 */
export const dateFormate = (date) => {
  const theDate = new Date(date)
  const options = { month: 'numeric', year: 'numeric' }
  const newDate = theDate.toLocaleDateString('fr-CA', options)
  return newDate.split('-').reverse().join('/')
}
export const dateFormateComplete = (date) => {
  const theDate = new Date(date)
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  const newDate = theDate.toLocaleDateString('fr-CA', options)
  return newDate.split('-').reverse().join('/')
}
export const dateFormateCompleteUS = (date) => {
  const theDate = new Date(date)
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  const newDate = theDate.toLocaleDateString('fr-CA', options)
  return newDate.split('-').join('/')
}
/**
 * If the string doesn't start with a slash, split it on the slash, add a zero to the beginning of the
 * array, reverse the array, and join it with dashes.
 * @param str - the string to be formatted
 * @returns the array.reverse().join('-')
 */
export const dateDeformatee = (str) => {
  if (str.indexOf('/') !== 0) {
    const array = str.split('/')
    array.unshift('01')
    return array.reverse().join('-')
  } else {
    return
  }
}
/**
 * It takes a string in the format of "dd/mm/yyyy" and returns a string in the format of "yyyy-mm-dd"
 * @param str - the string to be converted
 * @returns A function that takes a string and returns a string.
 */
export const dateDetenteur = (str) => {
  return str.split('/').reverse().join('-')
}
/**
 * It takes a string in the format of YYYY-MM-DD and returns a string in the format of YYYY/MM/DD.
 * @param str - '2019-01-01'
 * @returns A function that takes a string and returns a string.
 */
export const transformDateForFile = (str) => {
  if (typeof str === 'string') {
    return str.split('-').join('/')
  } else {
    const date = format(str, 'yyyy-MM-dd')
    return date.split('-').join('/')
  }
}

// calculate Age
/**
 * It takes a date object and returns the age of the person in years.
 * @param date - The date of birth.
 * @returns The age of the person.
 */
export const getAge = (date) => {
  const diff = Date.now() - date.getTime()
  const age = new Date(diff)
  return Math.abs(age.getUTCFullYear() - 1970)
}

/**
 * It takes a date, formats it, formats today's date, subtracts the two, and returns the difference in
 * months.
 * @param date - The date of birth
 * @returns The number of months between the date and today.
 */
export const getMonths = (debut, date) => {
  const formatDate = new Date(date)
  const beginning = new Date(debut)
  const diff = formatDate.getTime() - beginning.getTime()
  return Math.ceil(diff * 3.80513 * Math.pow(10, -10))
}
/**
 * The function `getMonthsBothDate` calculates the number of months between two given dates.
 * @param date1 - The first date in the format "YYYY-MM-DD".
 * @param date2 - The `date2` parameter represents the later date for which you want to calculate the
 * number of months between `date1` and `date2`.
 * @returns the number of months between two given dates.
 */
export const getMonthsBothDate = (date1, date2) => {
  const formatDate1 = new Date(date1)
  const formatDate2 = new Date(date2)
  const diff = formatDate2.getTime() - formatDate1.getTime()
  return Math.ceil(diff * 3.80513 * Math.pow(10, -10))
}

/**
 * It takes a date and a duration in months and returns a date that is the duration in months after the
 * original date.
 * @param date - "2019-01-01"
 * @param duree - 1
 * @returns A string
 */
export const getDateEnd = (date, duree) => {
  const years = Math.floor(duree / 12)
  const months = duree - years * 12
  const formatDate = new Date(date)
  const dateEnd = new Date(
    formatDate.getFullYear() + years,
    formatDate.getMonth() + months - 1,
    formatDate.getDate()
  )
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  return dateEnd.toLocaleDateString('fr-CA', options)
}
/**
 * It takes a date string and returns a number that represents the number of milliseconds since January
 * 1, 1970, 00:00:00 UTC.
 * @param date - The date you want to convert to a number.
 * @returns A number.
 */
export const dateToCompare = (date) => {
  return new Date(date).getTime()
}
