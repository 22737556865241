import React from 'react'
import {
  dataProduitClos,
  dataProduitFinancier,
} from '../../../../../../../data/informations/financier'
import InformationsCardPrint from '../../../../../Informations/InformationsCardPrint'
import BienInfoPrintDetenteurEtDoc from '../../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'
import { DOC_FINANCIER_COMPTE } from '../../../../../../../constants'

const PFInfoPrint = ({ elementToDisplay }) => {
  const { clos, detenteurs, fichiers } = elementToDisplay || {}

  return (
    <>
      <InformationsCardPrint
        data={dataProduitFinancier(elementToDisplay)}
        title={elementToDisplay.libelle}
      />
      {clos ? (
        <InformationsCardPrint data={dataProduitClos(elementToDisplay)} title={'Clôture'} />
      ) : null}
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={DOC_FINANCIER_COMPTE}
      />
    </>
  )
}

export default PFInfoPrint
