import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sortByType } from '../../../../../../../services/helpers/sort'
import { getBienSuccess } from '../../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../../redux/actions/actionGetBienType'
import TableBodyBloc from '../../../../../table/TableBodyBloc'
import BodyPrevoyance from './BodyPrevoyance'
import { supprId } from '../../../../../../../services/helpers/helpers'
import BodyPrevoyancePrint from './BodyPrevoyancePrint'

const TableBodyPrevoyance = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // ouvrir infos bien
  const handleShow = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'SOCIAL', type: 'socialPrevoyance' }))
    navigate('/user/patrimoine/actifs/social/prevoyance/informations')
  }
  // modifier bien
  const handleEdit = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'SOCIAL', type: 'socialPrevoyance' }))
    navigate('/user/patrimoine/actifs/social/prevoyance/formulaire')
  }
  // supprimer bien
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }
  // duplicate bien
  const handleDuplicate = (bien) => {
    const bienBis = {
      ...bien,
      id: 0,
      fichiers: [],
      libelle: `${bien.libelle} - copie`,
      contrat: '',
      detenteurs: bien.detenteurs.map((det) => supprId(det)),
    }
    dispatch(getBienSuccess(bienBis))
    dispatch(getbienType({ categorie: 'SOCIAL', type: 'socialPrevoyance' }))
    navigate('/user/patrimoine/actifs/social/prevoyance/formulaire')
  }

  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Catégorie':
          giveAType('categorie')
          break
        case 'Sous-catégorie':
          giveAType('sousCategorie')
          break
        case 'Assureur':
          giveAType('assureur')
          break
        case 'Contrat':
          giveAType('nomContrat')
          break
        case 'Numéro':
          giveAType('numero')
          break
        case 'Libelle':
          giveAType('libelle')
          break
        case 'Souscription':
          giveAType('dateSouscription')
          break
        case 'Durée':
          giveAType('duree')
          break
        case 'Fin':
          giveAType('dateFin')
          break
        case 'Capital':
          giveAType('capital')
          break
        case 'Taux':
          giveAType('taux')
          break
        case 'Rente estimée':
          giveAType('renteEstime')
          break
        case 'Périodicité':
          giveAType('frequenceCotisation')
          break
        case 'Montant':
          giveAType('montantEcheance')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyPrevoyancePrint /> : <BodyPrevoyance handleShow={handleShow} />}
      print={print}
      handleShow={handleShow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleDuplicate={handleDuplicate}
    />
  )
}

export default TableBodyPrevoyance
