import React from 'react'
import { MdPlayArrow } from 'react-icons/md'

const TableRowNumber = ({ index, handleRowClick }) => {
  return (
    <th className="tbody__tr__td tbody__tr__td--pointer fixed" onClick={handleRowClick}>
      <p>{index + 1}</p>
      <MdPlayArrow />
    </th>
  )
}

export default TableRowNumber
