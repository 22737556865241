// ______________________________________ relation
export const optionsTypeRelation = [
  { value: 'CONJOINT', label: 'Conjoint-e' },
  { value: 'CONCUBIN', label: 'Concubin-e' },
  { value: 'PACS', label: 'Partenaire de Pacs' },
  { value: 'GRANDPERE', label: 'Grand-Père' },
  { value: 'GRANDMERE', label: 'Grand-Mère' },
  { value: 'PERE', label: 'Père' },
  { value: 'MERE', label: 'Mère' },
  { value: 'FILS', label: 'Fils' },
  { value: 'FILLE', label: 'Fille' },
  { value: 'PETITFILS', label: 'Petit-Fils' },
  { value: 'PETITEFILLE', label: 'Petite-Fille' },
  { value: 'ONCLE', label: 'Oncle' },
  { value: 'TANTE', label: 'Tante' },
  { value: 'NEVEU', label: 'Neveu' },
  { value: 'NIECE', label: 'Nièce' },
  { value: 'FRERE', label: 'Frère' },
  { value: 'SOEUR', label: 'Soeur' },
  { value: 'AUTRE', label: 'Autre' },
]
// ______________________________________ true or false
// options vrai ou faux / oui ou non
export const optionsTrueOrFalse = [
  { value: true, label: 'Oui' },
  { value: false, label: 'Non' },
]
// options vrai ou faux / distanciel-presentiel
export const optionsDistanceTrueOrFalse = [
  { value: true, label: 'Distance' },
  { value: false, label: 'Présentiel' },
]
// options personne
export const optionsPersonnes = [
  { value: 'personnePhysique', label: 'Physique' },
  { value: 'personneMorale', label: 'Morale' },
]
// ______________________________________ roles
export const optionsRoles = [
  { value: { id: 1, name: 'ROLE_USER' }, label: 'USER' },
  { value: { id: 2, name: 'ROLE_ADMIN' }, label: 'ADMIN' },
  { value: { id: 3, name: 'ROLE_CONSEILLER' }, label: 'CONSEILLER' },
]
// ______________________________________ entrées tableau
export const optionsEntriesNumber = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 15,
    value: 15,
  },
  {
    label: 20,
    value: 20,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
  {
    label: 'MAX',
    value: 'MAX',
  },
]
// ______________________________________ periodicite
export const optionsTypePeriodicite = [
  { value: 'ANNUEL', label: 'Annuel' },
  { value: 'TRIMESTRIEL', label: 'Trimestriel' },
  { value: 'MENSUEL', label: 'Mensuel' },
  { value: 'JOURNALIER', label: 'Journalier' },
  { value: 'UNIQUE', label: 'Versement unique' },
]
// ______________________________________ détention
export const optionsDetention = [
  { value: 'CLIENT', label: 'Utilisateur-trice' },
  { value: 'CONJOINT', label: 'Conjoint-e / partenaire' },
  { value: 'COMMUN', label: 'Commun / Indivis' },
]

// ______________________________________ beneficiaire/detenteur
export const optionsDetenteur = [
  { value: 'REMUNERATIONM', label: 'Utilisateur-trice' },
  { value: 'REMUNERATIONMME', label: 'Conjoint(e) / partenaire' },
  { value: 'AUTRE', label: 'Personne à charge' },
]
