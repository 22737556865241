import React from 'react'
import { useSelector } from 'react-redux'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { COLORS } from '../../../constants'
import { cleanConcatString, standardizeAndLowerCase } from '../../../services/helpers/strings'

const PieChartComponent = ({ data, title, detenteurs, print }) => {
  // person
  const person = useSelector((state) => state.getPerson.person)
  const str = `${person.nom} ${person.prenom}`

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radian = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * radian)
    const y = cy + radius * Math.sin(-midAngle * radian)
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {percent !== 0 ? `${(percent * 100).toFixed(0)}%` : ''}
      </text>
    )
  }

  return print ? (
    <PieChart title={title} width={280} height={280}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={'100%'}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}-${cleanConcatString(entry.name)}`}
            fill={
              entry.name === 'Non affecté' || entry.name === 'Disponibilités'
                ? COLORS.colorFontLight
                : detenteurs
                ? standardizeAndLowerCase(entry.name) === `${standardizeAndLowerCase(str)}`
                  ? COLORS.colorSecondary
                  : COLORS.colorPrimary
                : entry.fill
            }
          />
        ))}
      </Pie>
    </PieChart>
  ) : (
    <ResponsiveContainer width={'100%'} height={320}>
      <PieChart title={title}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={'100%'}
          fill="#8884d8"
          dataKey="value"
          nameKey={'name'}
          margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}-${cleanConcatString(entry.name)}`}
              fill={
                entry.name === 'Non affecté' || entry.name === 'Disponibilités'
                  ? COLORS.colorFontLight
                  : detenteurs
                  ? standardizeAndLowerCase(entry.name) === `${standardizeAndLowerCase(str)}`
                    ? COLORS.colorSecondary
                    : COLORS.colorPrimary
                  : entry.fill
              }
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default PieChartComponent
