import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { passwordNew } from '../../../services/API/connexion'
import Input from '../Components/Input'
import Modale from '../Components/Modale'

const ModalForgotPassword = ({ setModalForgotPassword }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState(null)
  const [info, setInfo] = useState(null)

  const handleChange = (e) => {
    setInfo(false)
    setEmail(e.target.value)
  }

  const sendMail = () => {
    if (email !== '') {
      dispatch(passwordNew(email, setStatus))
    } else {
      console.log('remplir champs')
    }
  }

  // response
  useEffect(() => {
    if (status) {
      setInfo(true)
      setStatus(null)
    }
  }, [status])

  useEffect(() => {
    if (info) {
      setTimeout(() => navigate('/'), 10000)
    }
  }, [info])

  return (
    <Modale
      close={() => setModalForgotPassword(false)}
      title="Mot de passe oublié ?"
      line1="Veuillez saisir votre adresse mail :"
    >
      <div className="informations">
        {/* Email */}
        <Input
          forAndId="email"
          type="email"
          name="Mail :"
          required={true}
          value={email}
          onChange={(e) => handleChange(e)}
          placeholder="exemple@gmail.com"
          message="Merci de remplir ce champ correctement (exemple: exemple@gmail.com)"
        />
        {info && (
          <div className="input__info">
            <p>Si un compte est associé à l'adresse</p>
            <p>{email}</p>
            <p>Vous allez recevoir un lien pour réinitialiser votre mot de passe.</p>
            <p>Le processus peut prendre quelques minutes...</p>
          </div>
        )}
        {!info ? (
          <div className="informations__buttons">
            <div className="button button--blue-red width--12" onClick={() => sendMail()}>
              Envoyer
            </div>
            <div className="button button--blue-red width--12" onClick={() => navigate('/')}>
              Annuler
            </div>
          </div>
        ) : (
          <div className="informations__buttons">
            <div className="button button--grey">Envoyer</div>
            <div className="button button--grey">Annuler</div>
          </div>
        )}
      </div>
    </Modale>
  )
}

export default ModalForgotPassword
