import React, { useEffect, useState } from 'react'
import SelectComponent from '../Components/SelectComponent'
import Input from '../Components/Input'
import { useSelector } from 'react-redux'
import { optionsEntriesNumber } from '../../../data/options/options'
import {
  DOC_PERSON_PATRIMOINE,
  FE,
  PP_ADDRESS,
  PP_RELATIONS,
  PRODUIT_OPERATIONS,
  PRODUIT_PORTEFEUILLE,
  SUPPORTS_BUY,
  SUPPORTS_DELETE,
  SUPPORTS_INFOS,
  SUPPORTS_SELECT,
  USER_MESSAGES,
  USER_SIGNATURES_CLASSER,
  USER_SIGNATURES_INSTANCE,
} from '../../../constants'
import { MdLocalPrintshop, MdAdd } from 'react-icons/md'
import PrintComponent from '../Components/PrintComponent'
import TableToPrint from './TableToPrint'
import Loader from '../../layout/Loader'
import useCustomPrint from '../../layout/useCustomPrint'
import usePersonData from '../../layout/usePersonData'

const TableFilters = ({
  entries,
  setEntries,
  search,
  setSearch,
  setPage,
  tableType,
  data,
  dataToDisplay,
  listToDisplay,
  title,
  children,
  handleAdd,
  read,
  setFondEuro,
  setUniteCompte,
  setCreatedSupport,
  setCatalogueSupport,
  fondEuro,
  uniteCompte,
  createdSupport,
  catalogueSupport,
}) => {
  // person type
  const { personType } = usePersonData()
  // bien
  const bienType = useSelector((state) => state.getBienType.type)
  const { type } = bienType || {}
  // filtres
  const [allFilters, setAllFilters] = useState(false)
  // nombre d'entrées à afficher
  const handleChangeEntries = (e) => {
    setEntries(e.value)
    setPage(1)
  }
  // search
  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    setPage(1)
  }
  // filtres
  useEffect(() => {
    if (
      tableType === SUPPORTS_BUY ||
      tableType === SUPPORTS_DELETE ||
      tableType === SUPPORTS_INFOS ||
      tableType === SUPPORTS_SELECT ||
      tableType === PRODUIT_PORTEFEUILLE
    ) {
      setAllFilters(data.some((obj) => obj.c_nom === FE))
    }
  }, [data])
  // print
  const { componentRef, handlePrint, loadingPrint } = useCustomPrint()
  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <PrintComponent
        componentRef={componentRef}
        title={title}
        children={
          <TableToPrint
            data={listToDisplay}
            tableType={tableType}
            title={title}
            children={children}
            read={read}
          />
        }
      />
      <div className="bonusStart">
        {tableType === SUPPORTS_BUY ||
        tableType === SUPPORTS_DELETE ||
        tableType === SUPPORTS_INFOS ||
        tableType === SUPPORTS_SELECT ||
        tableType === PRODUIT_PORTEFEUILLE ? (
          <div className="bonusStart__check">
            {type === 'produitFinancierAV' ||
            type === 'retraite' ||
            type === 'catalogue-AV' ||
            type === 'catalogue-retraite' ||
            allFilters ? (
              <div>
                <Input
                  forAndId="fondEuro"
                  name="Fonds euro"
                  value={fondEuro}
                  check={true}
                  setValue={setFondEuro}
                />
                <Input
                  forAndId="uniteCompte"
                  name="Unités de compte"
                  value={uniteCompte}
                  check={true}
                  setValue={setUniteCompte}
                />
              </div>
            ) : null}
            <div>
              <Input
                forAndId="createdSupport"
                name="Supports créés"
                value={createdSupport}
                check={true}
                setValue={setCreatedSupport}
              />
              <Input
                forAndId="calatogueSupport"
                name="Supports du catalogue"
                value={catalogueSupport}
                check={true}
                setValue={setCatalogueSupport}
              />
            </div>
          </div>
        ) : null}
        <div className="bonusStart__inputs">
          <div className="bonusStart__inputs__search">
            {listToDisplay.length > 0 ? (
              <>
                <button onClick={handlePrint} className="button--print button--print--square">
                  <MdLocalPrintshop />
                </button>
                <SelectComponent
                  forAndId="entries"
                  isSearchable={false}
                  options={optionsEntriesNumber}
                  placeholder="Sélectionner"
                  onChange={(e) => handleChangeEntries(e)}
                  value={optionsEntriesNumber.find((c) => c.value === entries)}
                  noLabel={true}
                  name={'Affichage'}
                />
              </>
            ) : null}
            {tableType !== PP_RELATIONS ? (
              <Input
                type="text"
                value={search}
                onChange={(e) => handleSearch(e)}
                placeholder="Rechercher"
                noLabel={true}
                name="Rechercher"
                forAndId="rechercher"
              />
            ) : null}
            <p>
              <span>{listToDisplay.length.toLocaleString()}</span>/
              <span>{dataToDisplay.length.toLocaleString()}</span> résultat(s)
            </p>
          </div>
          {!read &&
          tableType !== USER_MESSAGES &&
          tableType !== PP_ADDRESS &&
          tableType !== SUPPORTS_INFOS &&
          tableType !== SUPPORTS_SELECT &&
          tableType !== SUPPORTS_DELETE &&
          tableType !== SUPPORTS_BUY &&
          tableType !== PRODUIT_PORTEFEUILLE &&
          tableType !== PRODUIT_OPERATIONS &&
          tableType !== DOC_PERSON_PATRIMOINE &&
          tableType !== USER_SIGNATURES_INSTANCE &&
          tableType !== USER_SIGNATURES_CLASSER &&
          personType !== 'relationPP' ? (
            <button onClick={handleAdd} className="button--print button--print--add">
              <MdAdd />
            </button>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default TableFilters
