import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getToken } from '../../../services/API/connexion'
import HomePageComponent from '../../pages/HomePage/HomePageComponent'
import InputsSignin from './InputsSignin'
import ModalSigninNotOk from './ModalSigninNotOk'
import ModalForgotPassword from './ModalForgotPassword'

const Signin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [status, setStatus] = useState(null)
  const [retry, setRetry] = useState(false)
  let count = 0
  // fields
  const [signIn, setSignIn] = useState({
    identifiant: '',
    password: '',
  })
  const { identifiant, password } = signIn || {}
  // modales
  const [modalNotOk, setModalNotOk] = useState(false)
  const [modalForgotPassword, setModalForgotPassword] = useState(false)
  const [testConnection, setTestConnexion] = useState(false)
  // retrieve values
  const handleChange = (e) => {
    const { name, value } = e.target
    setSignIn({ ...signIn, [name]: value.trim() })
  }
  // submit
  const handleSubmit = (e) => {
    e && e.preventDefault()
    if (identifiant && password) {
      dispatch(getToken(identifiant, password, setStatus))
    }
    setRetry(false)
  }
  // retry
  const handleRetry = () => {
    setStatus(null)
    setModalNotOk(false)
    setRetry(true)
  }
  // close
  const handleClose = () => {
    navigate('/')
  }
  useEffect(() => {
    if (testConnection) {
      const interval = setInterval(() => {
        count++
        console.log(`${count} tentative(s) de connexion `)
        count < 30 ? handleSubmit() : navigate('/user/accueil')
      }, 6000) // Vérifier toutes les 6 secondes
      return () => clearInterval(interval)
    }
  }, [dispatch, testConnection])

  // response status and modal
  useEffect(() => {
    if (status) {
      if (status < 400) {
        setModalNotOk(false)
        navigate('/user/accueil')
        setTestConnexion(false)
      } else {
        if (status < 500) {
          setModalNotOk(true)
          setSignIn({ ...signIn, password: '' })
          setTestConnexion(false)
        } else {
          setTestConnexion(true)
        }
      }
      setStatus(null)
    }
  }, [status])

  return (
    <>
      <HomePageComponent
        children={
          <InputsSignin
            signIn={signIn}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            setModalForgotPassword={setModalForgotPassword}
            retry={retry}
            testConnection={testConnection}
          />
        }
      />
      {modalNotOk && <ModalSigninNotOk handleRetry={handleRetry} />}
      {modalForgotPassword && (
        <ModalForgotPassword setModalForgotPassword={setModalForgotPassword} />
      )}
    </>
  )
}

export default Signin
