import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import {
  getCatalogueCommun,
  getCatalogues,
  postCatalogue,
} from '../../../../../../services/API/catalogue'
import { getDICIList } from '../../../../../../services/API/financier'
import { removeDuplicates } from '../../../../../../services/helpers/helpers'
import FormSubmit from '../../../../Components/FormSubmit'
import FormProductCard from './FormProductCard'
import FormSupportsCard from './FormSupportsCard'
import Ok from '../../../../Components/Modales/Ok'
import NotOk from '../../../../Components/Modales/NotOk'
import NotCorrect from '../../../../Components/Modales/NotCorrect'
import LittleLoader from '../../../../../layout/LittleLoader'
import { dynamicSortUp } from '../../../../../../services/helpers/sort'

const FormCatalogue = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // categorie
  const param = useParams()
  const categorieParam = param.id
  // bien
  const bien = useSelector((state) => state.getBien.bien)
  // DICI
  const list = useSelector((state) => state.getDicis.dicis)
  const [diciList, setDiciList] = useState(null)
  // catalogue commun
  const catalogueCommun = useSelector((state) => state.getCatalogueCommun.catalogue)
  // options
  const [optionsLibelle, setOptionsLibelle] = useState(null)
  const [optionsEtablissement, setOptionsEtablissement] = useState(null)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddBienOk, setToggleAddBienOk] = useState(false)
  const [toggleAddBienNotOk, setToggleAddBienNotOk] = useState(false)
  const [toggleAddNotCorrect, setToggleNotCorrect] = useState(false)
  // information
  const initialState = {
    id: 0,
    libelle: '',
    etablissement: '',
    categorie: categorieParam,
    fraisGestion: 0,
    periodiciteFrais: '',
    issuCommun: null,
    dicis: [],
  }
  const [information, setInformation] = useState(initialState)
  const {
    id,
    libelle,
    etablissement,
    categorie,
    fraisGestion,
    periodiciteFrais,
    issuCommun,
    dicis,
  } = information || {}

  // maj options catalogueGeneral
  useEffect(() => {
    if (categorie === 'AV' || categorie === 'retraite') {
      if (catalogueCommun) {
        const newList = Array.from(catalogueCommun).sort(dynamicSortUp(`libelle`))
        setOptionsLibelle(
          newList.map((el) => ({
            label: el.libelle ? el.libelle : 'Inconnu',
            value: el,
          }))
        )
        const array1 = catalogueCommun.map((el) => ({
          label: el.etablissement ? el.etablissement : 'Inconnu',
          value: el.etablissement ? el.etablissement : 'Inconnu',
        }))
        setOptionsEtablissement(removeDuplicates(array1, 'label'))
      } else {
        dispatch(getCatalogueCommun())
      }
    }
  }, [categorie, catalogueCommun])
  // updateOptionsLibelle
  const updateOptionsLibelle = (value) => {
    const newList = Array.from(catalogueCommun).sort(dynamicSortUp(`libelle`))
    setOptionsLibelle(
      newList
        .map((el) => ({
          label: el.libelle,
          value: el,
        }))
        .filter((el) => el.value.etablissement === value)
    )
  }
  // recup dicisList
  useEffect(() => {
    !list && dispatch(getDICIList())
  }, [])
  // maj information
  useEffect(() => {
    if (bien) {
      setInformation({
        id: bien.id,
        libelle: bien.libelle,
        etablissement: bien.etablissement,
        categorie: bien.categorie,
        fraisGestion: bien.fraisGestion,
        periodiciteFrais: bien.periodiciteFrais,
        issuCommun: bien.issuCommun,
        dicis: bien.dicis,
      })
    } else {
      setInformation(initialState)
    }
    setDiciList(list)
  }, [bien])
  // dataChange
  useEffect(() => {
    if (libelle !== '' && etablissement !== '' && categorie !== '') {
      setDataChange(true)
    } else {
      setDataChange(false)
    }
  }, [libelle, etablissement, categorie, dicis])
  // handleChange
  const handleChange = (e, selectName) => {
    if (e) {
      if (e.target !== undefined) {
        const { name, value } = e.target
        setInformation({ ...information, [name]: value })
        setDiciList(list)
      } else {
        const name = selectName.name !== undefined ? selectName.name : selectName
        const value = e.value !== undefined ? e.value : e
        switch (name) {
          case 'libelle':
            setInformation({
              ...information,
              libelle: value.libelle ? value.libelle : value,
              etablissement: value.etablissement ? value.etablissement : etablissement,
              fraisGestion: value.fraisGestion ? value.fraisGestion * 100 : fraisGestion,
              periodiciteFrais: value.periodiciteFrais ? value.periodiciteFrais : periodiciteFrais,
              dicis: value.dicis ? value.dicis : dicis,
            })
            value.libelle ? setDiciList(value.dicis) : setDiciList(list)
            setOptionsEtablissement([{ label: value.etablissement, value: value.etablissement }])
            break
          case 'etablissement':
            setInformation({
              ...information,
              etablissement: value.etablissement ? value.etablissement : value,
            })
            value.etablissement ? setDiciList(dicis) : setDiciList(list)
            updateOptionsLibelle(value)
            break
          default:
            setInformation({ ...information, [name]: value })
        }
      }
    } else {
      const name = selectName.name
      const array1 = catalogueCommun.map((el) => ({
        label: el.etablissement,
        value: el.etablissement,
      }))
      switch (name) {
        case 'libelle':
          setInformation({
            ...information,
            libelle: '',
            etablissement: '',
            fraisGestion: 0,
            periodiciteFrais: '',
            dicis: [],
            issuCommun: false,
          })
          setDiciList(list)
          setOptionsEtablissement(removeDuplicates(array1, 'label'))
          etablissement !== ''
            ? updateOptionsLibelle(etablissement)
            : setOptionsLibelle(
                catalogueCommun.map((el) => ({
                  label: el.libelle,
                  value: el,
                }))
              )
          break
        case 'etablissement':
          setInformation({
            ...information,
            libelle: '',
            etablissement: '',
            fraisGestion: 0,
            periodiciteFrais: '',
            dicis: [],
            issuCommun: false,
          })
          setDiciList(list)
          setOptionsEtablissement(removeDuplicates(array1, 'label'))
          etablissement !== ''
            ? updateOptionsLibelle(etablissement)
            : setOptionsLibelle(
                catalogueCommun.map((el) => ({
                  label: el.libelle,
                  value: el,
                }))
              )
          break
        default:
          return
      }
    }
  }
  // form  is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Catalogue' }))
    }
  }, [dataChange])
  // save form
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Catalogue') {
      handlePreSubmit()
    }
  }, [saveBien])
  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (libelle && etablissement && categorie) {
      console.log(categorie)
      handleSubmit()
    } else {
      setToggleNotCorrect(true)
    }
  }
  // Object to send
  const data = {
    id: id,
    libelle: libelle,
    etablissement: etablissement,
    categorie: categorie,
    fraisGestion: fraisGestion,
    periodiciteFrais: periodiciteFrais,
    issuCommun: issuCommun,
    dicis: dicis,
  }
  // submit
  const handleSubmit = () => {
    dispatch(postCatalogue(data, setStatus))
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setInformation(initialState)
    navigate(-1)
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setStatus(null)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
      dispatch(getCatalogues())
      setToggleAddBienOk(true)
      setInformation(initialState)
    }
    if (status >= 400) {
      setStatus(null)
      setToggleAddBienNotOk(true)
    }
  }, [status])
  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setToggleAddBienOk(false)
    categorieParam === 'AV' || categorieParam === 'VM'
      ? navigate('/user/patrimoine/actifs/financier/catalogue')
      : navigate('/user/patrimoine/actifs/social/catalogue')
  }
  // maj issuCommun
  useEffect(() => {
    if (optionsLibelle && optionsEtablissement && optionsEtablissement.length === 1) {
      if (optionsLibelle.length !== 0) {
        setInformation({ ...information, issuCommun: true })
      } else {
        setInformation({ ...information, issuCommun: false })
      }
    }
  }, [optionsLibelle, optionsEtablissement])

  return (
    <div className="section__content">
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handleSubmit}
        handleGiveUp={handleGiveUp}
        down={false}
      />
      <div className="sousSection">
        <div className="cards cards--fullWidth">
          {(categorie === 'AV' || categorie === 'retraite') && !catalogueCommun ? (
            <div className="informations informations--center">
              <LittleLoader />
            </div>
          ) : (
            <FormProductCard
              handleChange={handleChange}
              information={information}
              optionsLibelle={optionsLibelle}
              optionsEtablissement={optionsEtablissement}
              bienToDisplay={bien}
            />
          )}
          {diciList ? (
            <FormSupportsCard
              information={information}
              setInformation={setInformation}
              diciList={diciList}
              list={list}
            />
          ) : (
            <LittleLoader />
          )}
        </div>
      </div>
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handleSubmit}
        handleGiveUp={handleGiveUp}
        down={true}
      />
      {toggleAddBienOk && (
        <Ok
          text={
            bien
              ? 'Votre catalogue a été modifié avec succès'
              : 'Votre catalogue a été ajouté avec succès'
          }
          close={closeToggleAddBien}
        />
      )}
      {toggleAddBienNotOk && <NotOk setNotOK={setToggleAddBienNotOk} close={closeToggleAddBien} />}
      {toggleAddNotCorrect && <NotCorrect setNotCorrect={setToggleNotCorrect} />}
    </div>
  )
}

export default FormCatalogue
