import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelection } from '../../../../../../redux/actions/actionGetSelection'
import Card from '../../../../Components/Card'
import SelectionCard from './SelectionCard'
import CreationCard from './CreationCard'
import ChoiceCard from './ChoiceCard'

const FormSupportsCard = ({ information, setInformation, diciList, list }) => {
  const dispatch = useDispatch()
  const { dicis, categorie } = information || {}
  // selection
  const selection = useSelector((state) => state.getSelection.selection)
  // maj dicis
  useEffect(() => {
    dispatch(getSelection(information.dicis))
  }, [information])
  // handleSelect
  const handleSelect = (dici) => {
    if (
      selection &&
      selection.find((el) => el.isin === dici.isin && el.nom === dici.nom) !== undefined
    ) {
      handleDelete(dici)
    } else {
      setInformation({ ...information, dicis: [...dicis, dici] })
      dispatch(getSelection([...dicis, dici]))
    }
  }
  // handleDelete
  const handleDelete = (dici) => {
    setInformation({ ...information, dicis: dicis.filter((el) => el.isin !== dici.isin) })
    dispatch(getSelection(dicis.filter((el) => el.isin !== dici.isin)))
  }

  return (
    <>
      <Card title="Supports" h5={true} open={true}>
        <div className="informations">
          {diciList ? (
            <>
              <ChoiceCard diciList={list} handleSelect={handleSelect} categorie={categorie} />
              <CreationCard
                information={information}
                handleSelect={handleSelect}
                categorie={categorie}
              />
            </>
          ) : null}
          <SelectionCard dicis={dicis} diciList={diciList} handleDelete={handleDelete} />
        </div>
      </Card>
    </>
  )
}

export default FormSupportsCard
