import React from 'react'

const THead = ({ doc, titles, sort, setSort, targetToSort, setTargetToSort }) => {
  // tri
  const toggleSort = (e) => {
    const target = e.target
    const value = target.innerHTML
    setSort(!sort)
    setTargetToSort(value)
  }
  // action title
  const getActionTitle = (title) => {
    return (
      title === 'Voir' ||
      title === 'Mettre à jour' ||
      title === 'Actualiser' ||
      title === 'Modifier' ||
      title === 'Sélection' ||
      title === 'Récupérer' ||
      title === 'Supprimer' ||
      title === 'Classer' ||
      title === 'Acheter' ||
      title === 'Vendre' ||
      title === 'Sélectionner' ||
      title === 'Dupliquer' ||
      title === 'N°' ||
      title === 'Régulariser'
    )
  }

  return (
    <thead className={doc ? 'thead thead--doc' : 'thead'}>
      <tr className={'thead__tr'}>
        {titles.map((title, index) => (
          <th
            key={index}
            className={
              title !== 'N°'
                ? getActionTitle(title)
                  ? 'thead__tr__th thead__tr__th--action'
                  : 'thead__tr__th unselectable'
                : 'thead__tr__th fixed'
            }
            scope="col"
            onClick={(e) => {
              toggleSort(e)
            }}
          >
            {title}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default THead
