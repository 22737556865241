import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { supprId } from '../../../../../services/helpers/helpers'
import { dataRevenuMobGeneral } from '../../../../../data/informations/revenus'
import { REVENUS_MOBILIERS } from '../../../../../constants'
import useCustomPrint from '../../../../layout/useCustomPrint'
import Loader from '../../../../layout/Loader'
import PrintComponent from '../../../Components/PrintComponent'
import DocInfoCard from '../../Patrimoine/DocInfoCard'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import DetentionInfoCard from '../../Detention/DetentionInfoCard'
import RevenuMobInfoPrint from './Print/RevenuMobInfoPrint'
import MainInformationsCard from '../../../Components/MainInformationsCard'

const RevenuMobilier = () => {
  const navigate = useNavigate()
  const { bien } = useSelector((state) => state.getBien)
  const newBien = bien
    ? {
        ...bien,
        id: 0,
        fichiers: [],
        libelle: `${bien.libelle} - copie`,
        detenteurs: bien.detenteurs.map((det) => supprId(det)),
      }
    : null
  // fichiers
  const [patrimoineFiles, setPatrimoineFiles] = useState([])
  // redirection
  const formRedirection = '/user/patrimoine/revenus/mobilier/ajouter-revenu'
  const backRedirection = '/user/patrimoine/revenus/mobilier'

  // actualisation bien
  useEffect(() => {
    bien && setPatrimoineFiles(bien.fichiers)
  }, [bien])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!bien) return <Navigate to={backRedirection} replace />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<RevenuMobInfoPrint elementToDisplay={bien} />}
        />
        <ButtonsRow
          handlePrint={handlePrint}
          formLink={formRedirection}
          backLink={backRedirection}
          newBien={newBien}
          tableType={REVENUS_MOBILIERS}
        />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            {/* Informations principales */}
            <MainInformationsCard
              title={'Informations principales'}
              h4={true}
              data={dataRevenuMobGeneral}
              element={bien}
              redirection={() => navigate(formRedirection)}
            />
          </div>
          <div className="cards">
            {/* Detenteurs */}
            <DetentionInfoCard
              bienToDisplay={bien}
              redirection={() => navigate(formRedirection)}
              h4={true}
              showUS={false}
            />
            {/* documents */}
            <DocInfoCard
              patrimoineFiles={patrimoineFiles}
              redirection={() => navigate(formRedirection)}
              h4={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RevenuMobilier
