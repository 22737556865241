import React from 'react'
import { useSelector } from 'react-redux'
import { dataDette } from '../../../../../../data/informations/passifs'
import { DOC_PASSIF_DETTE } from '../../../../../../constants'
import InformationsCardPrint from '../../../../Informations/InformationsCardPrint'
import BienInfoPrintDetenteurEtDoc from '../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'

const PassifDetteInfoToPrint = ({ elementToDisplay }) => {
  const properties = useSelector((state) => state.getProperties.properties)
  const { detenteurs, fichiers } = elementToDisplay || {}
  return (
    <>
      <InformationsCardPrint
        data={dataDette(elementToDisplay, null, elementToDisplay.idActif ? properties : null, null)}
        title={elementToDisplay.refDette}
      />
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={DOC_PASSIF_DETTE}
      />
    </>
  )
}

export default PassifDetteInfoToPrint
