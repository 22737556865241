import React from 'react'
import { MdLocalPrintshop } from 'react-icons/md'
import Loader from '../../../layout/Loader'
import useCustomPrint from '../../../layout/useCustomPrint'
import Card from '../../Components/Card'
import PrintComponent from '../../Components/PrintComponent'
import TableToPrint from '../../table/TableToPrint'
import DetentionTable from './Table/DetentionTable'
import TableBodyDetention from './Table/TableBodyDetention'
import useTotauxDetenteurs from './useTotauxDetenteurs'
import ConditionnalUpdateButton from '../../Components/ConditionnalUpdateButton'

const DetentionInfoCard = ({ bienToDisplay, redirection, showUS,h3, h4,h5, h6 }) => {
  const { detenteurs } = bienToDisplay || {}
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <PrintComponent
        componentRef={componentRef}
        title={titlePrint}
        children={
          <TableToPrint
            data={detenteurs}
            tableType={''}
            title={titlePrint}
            children={
              <TableBodyDetention
                showUS={showUS}
                detenteurs={detenteurs}
                total={useTotauxDetenteurs(detenteurs)}
              />
            }
          />
        }
      />

      <Card title="Détenteurs" h3={h3} h4={h4} h5={h5} h6={h6} open={true}>
        <div className="informations">
          <button onClick={handlePrint} className="button--print button--print--square">
            <MdLocalPrintshop />
          </button>
          {detenteurs && detenteurs?.length > 0 && (
            <DetentionTable detenteurs={detenteurs} showUS={showUS} />
          )}
        </div>
        <ConditionnalUpdateButton redirection={redirection} />
      </Card>
    </>
  )
}

export default DetentionInfoCard
