import React from 'react'
import Modale from '../Components/Modale'
import AddFirm from './AddFirm'

const ModalAddFirm = ({ setModalAddFirm, setModalNotCorrect, setStatus }) => {
  return (
    <Modale
      close={() => setModalAddFirm(false)}
      title="Ajouter un cabinet !"
    >
      <div className="informations">
        <AddFirm setModalNotCorrect={setModalNotCorrect} setStatus={setStatus} />
      </div>
    </Modale>
  )
}

export default ModalAddFirm
