import React from 'react'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import { optionsTransaction } from '../../../../../../data/options/optionsFinancier'
import { dateFormateComplete } from '../../../../../../services/helpers/dates'

const BodyTransactionsPrint = ({ element, index }) => {
  return (
    <>
      {/* nature */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nature</p>
        <p className="print__table__bloc__line--value">
          {!element.code ? '-' : recupLabel(parseInt(element.code), optionsTransaction)}
        </p>
      </div>
      {/* montant */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Montant</p>
        <p className="print__table__bloc__line--value">
          {!element.montant
            ? '-'
            : `${element.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
        </p>
      </div>
      {/* dateTransaction */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Date</p>
        <p className="print__table__bloc__line--value">
          {!element.dateTransaction ? '-' : dateFormateComplete(element.dateTransaction)}
        </p>
      </div>
    </>
  )
}

export default BodyTransactionsPrint
