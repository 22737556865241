import React from 'react'
import Input from '../../../Components/Input'
import { TiDelete } from 'react-icons/ti'
import { FiEdit3 } from 'react-icons/fi'
import {
  calculateNP,
  calculateUS,
  calculValeurDetenue,
} from '../../../../../services/helpers/detenteurs'
import classNames from 'classnames'

const DetentionTableInput = ({
  showUS,
  list,
  total,
  newValeurTotale,
  detenteursToDisplay,
  handleChangeDetenteur,
  handleEdit,
  handleSuppr,
}) => {
  const titles = showUS
    ? ['Nom', '% PP', '% US', '% NP', '€ PP', '€ US', '€ NP', 'Détenu', 'Modifier', 'Supprimer']
    : ['Nom', 'Détenu (%)', 'Détenu (€)', 'Modifier', 'Supprimer']
  // totaux
  const { totalTauxPP, totalTauxUS, totalTauxNP, totalPP, totalUS, totalNP, totalDetenu } =
    total || {}
  return (
    <>
      <div className="table">
        <div className="scrollwrapper scrollwrapper--noNumber">
          <table className="table__content">
            <thead className="thead">
              <tr className="thead__tr">
                {titles.map((title, index) => (
                  <th className="thead__tr__th" key={index}>
                    <p>{title}</p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="tbody">
              {list &&
                list.length > 0 &&
                list.map((det, index) => (
                  <tr className="tbody__tr" key={index}>
                    {/* nomDetenteur */}
                    <td className="tbody__tr__td">{det.nomDetenteur}</td>
                    {/* tauxPleinePropriete */}
                    <td className="tbody__tr__td">
                      <Input
                        forAndId={`tauxPleinePropriete-${index}`}
                        type="text"
                        value={det.tauxPleinePropriete}
                        onChange={(e) => handleChangeDetenteur(e, det)}
                        placeholder={'100'}
                        noLabel={true}
                        table={true}
                        inputMode="decimal"
                      />
                    </td>
                    {/* tauxUsufruit */}
                    {showUS ? (
                      <td className="tbody__tr__td">
                        <Input
                          forAndId={`tauxUsufruit-${index}`}
                          type="text"
                          value={det.tauxUsufruit}
                          onChange={(e) => handleChangeDetenteur(e, det)}
                          placeholder="0"
                          noLabel={true}
                          table={true}
                          disabled={
                            (det.tauxNuePropriete && det.tauxNuePropriete !== '0') ||
                            det.tauxPleinePropriete === 100 ||
                            det.tauxPleinePropriete === '100' ||
                            (!det.dateNaissance && !det.dateContitutionUsufruit)
                          }
                          inputMode="decimal"
                        />
                      </td>
                    ) : null}
                    {/* tauxNuePropriete */}
                    {showUS ? (
                      <td className="tbody__tr__td">
                        <Input
                          forAndId={`tauxNuePropriete-${index}`}
                          type="text"
                          value={det.tauxNuePropriete}
                          onChange={(e) => handleChangeDetenteur(e, det)}
                          placeholder="0"
                          noLabel={true}
                          table={true}
                          disabled={
                            det.tauxUsufruit ||
                            det.tauxPleinePropriete === 100 ||
                            det.tauxPleinePropriete === '100' ||
                            totalUS === 0
                          }
                          inputMode="decimal"
                        />
                      </td>
                    ) : null}
                    {/* valeur PP */}
                    {showUS ? (
                      <td className="tbody__tr__td">
                        {`${((newValeurTotale * det.tauxPleinePropriete) / 100).toLocaleString(
                          undefined,
                          { minimumFractionDigits: 4 }
                        )} €`}
                      </td>
                    ) : null}
                    {/* valeur US */}
                    {showUS ? (
                      <td className="tbody__tr__td">{`${calculateUS(
                        det,
                        newValeurTotale
                      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}</td>
                    ) : null}
                    {/* valeur NP */}
                    {showUS ? (
                      <td className="tbody__tr__td">{`${calculateNP(
                        det,
                        newValeurTotale,
                        detenteursToDisplay
                      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}</td>
                    ) : null}
                    {/* valeurDetenue */}
                    <td className="tbody__tr__td">
                      {`${calculValeurDetenue(
                        det,
                        newValeurTotale,
                        detenteursToDisplay
                      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
                    </td>
                    <td
                      className="tbody__tr__td  tbody__tr__td--pointer"
                      onClick={() => handleEdit(det)}
                    >
                      <div className="tbody__tr__td__button">
                        <FiEdit3 />
                      </div>
                    </td>
                    <td
                      className="tbody__tr__td  tbody__tr__td--pointer"
                      onClick={() => handleSuppr(det)}
                    >
                      <div className="tbody__tr__td__button">
                        <TiDelete />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot className="tfoot">
              <tr className="thead__tr">
                <th className="thead__tr__th">
                  <p>Total : </p>
                </th>
                <th className="thead__tr__th">
                  <p
                    className={classNames('', { red: totalTauxPP > 100 })}
                  >{`${totalTauxPP.toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} %`}</p>
                </th>
                {showUS ? (
                  <>
                    <th className="thead__tr__th">
                      <p>{`${totalTauxUS.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                      })} %`}</p>
                    </th>
                    <th className="thead__tr__th">
                      <p>{`${totalTauxNP.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                      })} %`}</p>
                    </th>
                    <th className="thead__tr__th">
                      <p>{`${totalPP.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                      })} €`}</p>
                    </th>
                    <th className="thead__tr__th">
                      <p>{`${totalUS.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                      })} €`}</p>
                    </th>
                    <th className="thead__tr__th">
                      <p>{`${totalNP.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                      })} €`}</p>
                    </th>
                  </>
                ) : null}
                <th className="thead__tr__th">
                  <p>{`${totalDetenu.toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} €`}</p>
                </th>
                <th className="thead__tr__th"></th>
                <th className="thead__tr__th"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  )
}

export default DetentionTableInput
