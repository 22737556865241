import axios from 'axios'
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants'
import {
  getPassifsError,
  getPassifsLoad,
  getPassifsSuccess,
} from '../../redux/actions/Patrimoine/actionGetPassifs'

// recupération liste passifs
export const getPassifs = (id, pp) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getPassifsLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/passif/passifs?personneId=${id}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getPassifsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getPassifsError(error.message))
      })
  }
}
// update valeur totale passifs
export const postPassifsTotalValue = (id, value, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/passif/majPassifsValeurTotale?personneId=${id}&montant=${value}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getPassifsSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// update valeur totale emprunts ou dette
export const postTotalValuePassif = (passifValueToPost, id, value, pp, divers, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/passif/${passifValueToPost}?personneId=${id}&montant=${value}&pp=${pp}&divers=${divers}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getPassifsSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// post emprunt ou dette
export const postPassif = (passifToPost, id, pp, divers, data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/passif/${passifToPost}?personneId=${id}&pp=${pp}&divers=${divers}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(getPassifsSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// delete emprunt ou dette
export const deletePassif = (passifToDelete, data, idPP, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${API_BASE_URL}/passif/${passifToDelete}/${data.id}/${idPP}/${pp}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
        dispatch(getPassifs(idPP, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
