// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
import { addModal, removeModal } from '../actions/actionModals'
// actions imports

// REDUCER // ______________________________________________________________

// initial state
const initialState = {
  modals: [],
}
// reducer
export const getModalsReducer = createReducer(initialState, (builder) => {
  return builder
    .addCase(addModal, (draft, action) => {
      draft.modals.push(action.payload)
    })
    .addCase(removeModal, (draft, action) => {
      const index = draft.modals.lastIndexOf(action.payload)
      if (index > -1) {
        draft.modals.splice(index, 1)
      }
    })
})
