import React from 'react'
import Card from './Card'
import GeneralInformationsCard from './GeneralInformationsCard'
import ConditionnalUpdateButton from './ConditionnalUpdateButton'

const MainInformationsCard = ({
  title,
  element,
  data,
  redirection,
  h3,
  h4,
  h5,
  h6,
  categorie,
}) => {

  return (
    <Card title={title} h3={h3} h4={h4} h5={h5} h6={h6} open={true}>
      <GeneralInformationsCard
        elementToDisplay={element}
        dataToDisplay={data}
        categorie={categorie}
      />
      <ConditionnalUpdateButton redirection={redirection} />
    </Card>
  )
}

export default MainInformationsCard
