import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { fetchNAF } from '../../../../services/API/connexion'
import InputsEtablissement from '../Inputs/InputsEtablissement'
import Modale from '../../Components/Modale'

const ModaleEtablissement = ({ newData, data, setData, setModal, setDataChange }) => {
  const [newEtablissement, setNewEtablissement] = useState({})
  const { siret, code_naf, libelle_code_naf } = newEtablissement || {}

  const etablissementToDelete = newData

  const [NAF, setNAF] = useState(null)
  const [newNAF, setNewNAF] = useState(null)
  const [optionsCodeNAF, setOptionsCodeNAF] = useState()
  const [optionsLibelleNAF, setOptionsLibelleNAF] = useState()

  // modif sur un etablissement
  useEffect(() => {
    newData
      ? setNewEtablissement({
          ...newData,
          code_naf: newData.code_naf.split('.').join(''),
        })
      : setNewEtablissement({
          ...newEtablissement,
          siret: '',
          nic: '',
          adresse_ligne_1: '',
          adresse_ligne_2: '',
          code_postal: '',
          ville: '',
          pays: '',
          code_pays: '',
          code_naf: '',
          libelle_code_naf: '',
          etablissement_employeur: '',
          effectif: '',
          effectif_min: '',
          effectif_max: '',
          date_de_creation: '',
          etablissement_cesse: '',
          date_cessation: '',
          siege: '',
          enseigne: '',
          nom_commercial: '',
        })
  }, [])

  // NAF
  // fetch NAF
  useEffect(() => {
    !NAF && fetchNAF(setNAF)
  }, [])
  // création d'un nouvel array d'objets dont les clés seront uniquement ce dont on a besoin
  useEffect(() => {
    if (NAF) {
      setNewNAF(
        Object.values(NAF).map((element) => ({
          code_naf: element.fields.code_naf,
          libelle_code_naf: element.fields.intitule_naf,
        }))
      )
    }
  }, [NAF])
  // à partir de ce nouvel array, création des options code et libellé pour les input select
  useEffect(() => {
    if (newNAF) {
      setOptionsCodeNAF(
        Object.values(newNAF).map((element) => ({
          value: element.code_naf,
          label: element.code_naf,
        }))
      )
      setOptionsLibelleNAF(
        Object.values(newNAF).map((element) => ({
          value: element.libelle_code_naf,
          label: element.libelle_code_naf,
        }))
      )
    }
  }, [newNAF])

  // enregistrement des modifs ou du nouvel etablissement
  const handleAddEtablissement = () => {
    if (newData) {
      setData([...data.filter((el) => el !== etablissementToDelete), newEtablissement])
    } else {
      setData([...data, newEtablissement])
    }
    setModal(false)
  }
  // saisie
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e) {
      if (e.target !== undefined) {
        const { name, value, maxLength } = e.target
        setNewEtablissement({ ...newEtablissement, [name]: value })
        switch (name) {
          case 'siret':
            setNewEtablissement({ ...newEtablissement, siret: value.slice(0, maxLength) })
            break
          case 'nic':
            setNewEtablissement({ ...newEtablissement, nic: value.slice(0, maxLength) })
            break
          default:
            return
        }
      } else {
        const name = selectName.name
        const value = e.value
        setNewEtablissement({ ...newEtablissement, [name]: value })
      }
    } else {
      const name = selectName.name
      setNewEtablissement({ ...newEtablissement, [name]: undefined })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setNewEtablissement({ ...newEtablissement, [name]: date })
  }

  // action sur nic quand modification siret
  useEffect(() => {
    if (siret && siret.length === 14) {
      setNewEtablissement({ ...newEtablissement, nic: siret.toString().slice(9) })
    }
  }, [siret])
  // action sur libelle_code_naf quand modif code_naf
  useEffect(() => {
    if (newNAF && code_naf) {
      const item = newNAF.filter((el) => el.code_naf === code_naf)
      setNewEtablissement({ ...newEtablissement, libelle_code_naf: item[0].libelle_code_naf })
    }
  }, [code_naf, libelle_code_naf])

  return (
    <Modale close={() => setModal(false)} title={newData ? 'Modifier' : 'Ajouter'}>
      <div className="informations">
        <InputsEtablissement
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
          handleAddEtablissement={handleAddEtablissement}
          optionsCodeNAF={optionsCodeNAF}
          optionsLibelleNAF={optionsLibelleNAF}
          data={newEtablissement}
          setData={setNewEtablissement}
          setDataChange={setDataChange}
        />
      </div>
    </Modale>
  )
}

export default ModaleEtablissement
