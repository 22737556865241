import React, { useEffect, useState } from 'react'
import Card from '../../../../../Components/Card'
import Input from '../../../../../Components/Input'
import SelectComponent from '../../../../../Components/SelectComponent'
import { optionsLivrets, optionsPF } from '../../../../../../../data/options/optionsFinancier'
import DateInputComponent from '../../../../../Components/DateInputComponent'

const GeneralInformationCard = ({
  handleChange,
  handleChangeDate,
  information,
  setInformation,
}) => {
  const { libelle, descriptif, etablissement, numero, solde, dateSolde } = information || {}
  const [category, setCategory] = useState('')
  const [subCategory, setSubCategory] = useState('')
  useEffect(() => {
    if (libelle.includes(';')) {
      let array = libelle.split(';')
      setCategory(array[0])
      setSubCategory(array[1])
    } else {
      setCategory(libelle)
    }
  }, [libelle])
  // handleChangeName
  const handleChangeName = (e, selectName) => {
    if (e) {
      const name = selectName.name
      const value = e.value
      switch (name) {
        case 'category':
          if (value !== 'Livret') {
            setInformation({ ...information, libelle: value })
          } else {
            setCategory(value)
          }
          break
        case 'subCategory':
          setSubCategory(value)
          setInformation({ ...information, libelle: `${category};${value}` })
          break
        default:
          return
      }
    } else {
      setInformation({ ...information, libelle: '' })
      setCategory('')
      setSubCategory('')
    }
  }

  return (
    <Card title="Informations générales" h3={true} open={true}>
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* libelle */}
          <SelectComponent
            forAndId="category"
            errorLabel={category === ''}
            name="Catégorie :"
            isClearable={true}
            isSearchable={true}
            options={optionsPF}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChangeName(e, name)}
            value={optionsPF.find((c) => c.value === category)}
            copy={true}
            required={true}
          />
          {category === 'Livret' ? (
            <SelectComponent
              forAndId="subCategory"
              errorLabel={subCategory === ''}
              name="Sous-catégorie :"
              isClearable={true}
              isSearchable={true}
              options={optionsLivrets}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChangeName(e, name)}
              value={optionsLivrets.find((c) => c.value === subCategory)}
              copy={true}
              required={true}
            />
          ) : null}
          {/* descriptif */}
          <Input
            forAndId="descriptif"
            type="text"
            name="Descriptif :"
            value={descriptif}
            onChange={(e) => handleChange(e)}
            copy={true}
            placeholder="Compte joint / Leasing voiture / Compte vacances ..."
          />
          {/* etablissement */}
          <Input
            forAndId="etablissement"
            errorLabel={etablissement === ''}
            type="text"
            name="Etablissement :"
            value={etablissement}
            onChange={(e) => handleChange(e)}
            copy={true}
            required={true}
          />
          {/* numero */}
          <Input
            forAndId="numero"
            errorLabel={numero === '' || numero === 0}
            type="text"
            name="Numéro :"
            value={numero}
            onChange={(e) => handleChange(e)}
            copy={true}
            required={true}
          />
          {/* solde */}
          <Input
            forAndId="solde"
            errorLabel={solde === '' || solde === 0}
            type="number"
            name="Solde :"
            value={solde}
            onChange={(e) => handleChange(e)}
            required={true}
            copy={true}
          />
          {/* dateSolde */}
          <DateInputComponent
            name={'dateSolde'}
            label={'Date du solde :'}
            value={dateSolde}
            handleChange={handleChangeDate}
          />
        </div>
      </div>
    </Card>
  )
}

export default GeneralInformationCard
