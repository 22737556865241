import React, { useEffect, useState } from 'react'
import { optionsDetention, optionsTypePeriodicite } from '../../../../../data/options/options'
import Card from '../../../Components/Card'
import Input from '../../../Components/Input'
import SelectComponent from '../../../Components/SelectComponent'
import {
  optionsDetailsChgED,
  optionsDetailsChgG,
  optionsDetailsChgIT,
  optionsDetailsChgP,
} from '../../../../../data/options/optionsCharges'
import DateInputComponent from '../../../Components/DateInputComponent'
import {
  DOC_CHARGE_EMPRUNT,
  DOC_CHARGE_GENERALE,
  DOC_CHARGE_IMPOT,
  DOC_CHARGE_PENSION,
} from '../../../../../constants'

const GeneralInformationCard = ({ handleChange, handleChangeDate, information, tableType }) => {
  const { nom, detail, montantAnnuel, periodicite, dateDebut, dateFin, detention, tauxIndivision } =
    information || {}
  const [optionsTodisplay, setOptionsTodisplay] = useState([])

  useEffect(() => {
    switch (tableType) {
      case DOC_CHARGE_GENERALE:
        setOptionsTodisplay(optionsDetailsChgG)
        break
      case DOC_CHARGE_IMPOT:
        setOptionsTodisplay(optionsDetailsChgIT)
        break
      case DOC_CHARGE_PENSION:
        setOptionsTodisplay(optionsDetailsChgP)
        break
      case DOC_CHARGE_EMPRUNT:
        setOptionsTodisplay(optionsDetailsChgED)
        break
      default:
        return
    }
  }, [tableType])

  return (
    <Card title="Informations générales" h3={true} open={true}>
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* refEmprunt */}
          <Input
            forAndId="nom"
            errorLabel={nom === ''}
            type="text"
            name="Nom :"
            value={nom}
            onChange={(e) => handleChange(e)}
            placeholder="Libellé"
            copy={true}
            required
          />
          {optionsTodisplay.length ? (
            <SelectComponent
              forAndId="detail"
              errorLabel={detail === '' || !detail}
              name="Catégorie :"
              isSearchable={true}
              options={optionsTodisplay}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={optionsTodisplay.find((c) => c.value === detail)}
              copy={true}
              required={true}
            />
          ) : null}
          {/* montantAnnuel */}
          <Input
            forAndId="montantAnnuel"
            errorLabel={montantAnnuel === '' || montantAnnuel === 0}
            type="number"
            name="Montant :"
            value={montantAnnuel}
            onChange={(e) => handleChange(e)}
            placeholder={1000}
            copy={true}
            required
          />
          {/* periodicite */}
          <SelectComponent
            forAndId="periodicite"
            errorLabel={periodicite === ''}
            name="Type periodicité :"
            isSearchable={false}
            options={optionsTypePeriodicite}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsTypePeriodicite.find((c) => c.value === periodicite)}
            copy={true}
            required={true}
          />
        </div>
        {/* dateDebut */}
        <DateInputComponent
          name={'dateDebut'}
          label={'Date de début :'}
          value={dateDebut}
          handleChange={handleChangeDate}
        />
        {/* dateFin */}
        <DateInputComponent
          name={'dateFin'}
          label={'Date de fin :'}
          value={dateFin}
          handleChange={handleChangeDate}
        />

        {/* detention */}
        <SelectComponent
          forAndId="detention"
          errorLabel={detention === ''}
          name="Détention :"
          isSearchable={false}
          options={optionsDetention}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsDetention.find((c) => c.value === detention)}
          required={true}
          copy={true}
        />
        {/* tauxIndivision */}
        <Input
          forAndId="tauxIndivision"
          type="number"
          name="Taux d'indivision :"
          value={tauxIndivision}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
      </div>
    </Card>
  )
}

export default GeneralInformationCard
