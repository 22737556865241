import React from 'react'

const FormOperationValidation = ({
  matchisOK,
  handlePreSubimt,
  handleClose,
  dataNewOperationChange,
  // vlDateList,
  message,
  checkDate,
  newOperation,
  fondEuro,
}) => {
  const { support } = newOperation || {}
  const { dateAchat } = support || {}
  return matchisOK || fondEuro ? (
    <>
      {message && <p className="informations__errorInputs">{message}</p>}
      <div className="informations__buttons">
        {dataNewOperationChange ? (
          <button className="button button--red-red width--12" onClick={(e) => handlePreSubimt(e)}>
            Enregistrer
          </button>
        ) : (
          <div className="button button--grey width--12">Enregistrer</div>
        )}
        <button className="button button--blue-red width--12" onClick={() => handleClose()}>
          Abandonner
        </button>
      </div>
    </>
  ) : dateAchat && !fondEuro ? (
    message === null ? (
      <div className="informations__buttons">
        <button className="button button--red-red width--12" onClick={(e) => checkDate(e)}>
          Verifier la valeur
        </button>
      </div>
    ) : (
      <p className="informations__errorInputs">{message}</p>
    )
  ) : (
    <p className="informations__errorInputs">Veuillez saisir une nouvelle valeur</p>
  )
}

export default FormOperationValidation
