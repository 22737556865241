// IMPORTS // ______________________________________________________________
import { createReducer } from '@reduxjs/toolkit'
import { getSaveForm } from '../actions/actionSaveForm'
// actions imports

// REDUCER // ______________________________________________________________

// initial state
const initialStateSaveForm = {
  save: false,
}
// reducer
export const getSaveFormReducer = createReducer(initialStateSaveForm, (builder) => {
  return builder.addCase(getSaveForm, (draft, action) => {
    draft.save = action.payload
    return
  })
})
