import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sortByType } from '../../../../../../services/helpers/sort'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import { PRODUITS_VM } from '../../../../../../constants'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyTitres from './BodyTitres'
import BodyTitresPrint from './BodyTitresPrint'

const TableBodyTitres = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
  tableType,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // ouvrir infos bien
  const handleShow = (produit) => {
    dispatch(getBienSuccess(produit))
    if (tableType === PRODUITS_VM) {
      dispatch(getbienType({ categorie: 'FINANCIER', type: 'produitFinancierVM' }))
      navigate('/user/patrimoine/actifs/financier/valeurs-mobilieres/produit/produit')
    } else {
      dispatch(getbienType({ categorie: 'SOCIAL', type: 'per' }))
      navigate('/user/patrimoine/actifs/social/PER/produit/produit')
    }
  }
  const handleEdit = (produit) => {
    dispatch(getBienSuccess(produit))
    tableType === PRODUITS_VM
      ? dispatch(getbienType({ categorie: 'FINANCIER', type: 'produitFinancierVM' }))
      : dispatch(getbienType({ categorie: 'SOCIAL', type: 'per' }))
    if (!produit.dateOuverture) {
      tableType === PRODUITS_VM
        ? navigate('/user/patrimoine/actifs/financier/valeurs-mobilieres/produit/produit')
        : navigate('/user/patrimoine/actifs/social/PER/produit/produit')
    } else {
      if (produit.transactions.length > 0) {
        tableType === PRODUITS_VM
          ? navigate('/user/patrimoine/actifs/financier/valeurs-mobilieres/produit/portefeuille')
          : navigate('/user/patrimoine/actifs/social/PER/produit/portefeuille')
      } else {
        tableType === PRODUITS_VM
          ? navigate('/user/patrimoine/actifs/financier/valeurs-mobilieres/produit/transactions')
          : navigate('/user/patrimoine/actifs/social/PER/produit/transactions')
      }
    }
  }
  // supprimer bien
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }
  const calculateTotalRemise = (produit) => {
    return produit.transactions.length > 0
      ? produit.transactions.map((el) => el.montant).reduce((acc, curr) => acc + curr)
      : 0
  }

  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Libellé':
          giveAType('libelle')
          break
        case 'Catégorie':
          giveAType('categorie')
          break
        case 'Disponibilités':
          giveAType('portefeuille.totalLiquidite')
          break
        case 'Portefeuille':
          giveAType('portefeuille.valeurPortefeuille')
          break
        case 'Total':
          giveAType('portefeuille.totalValeurPortefeuille')
          break
        case 'Gain / Perte':
          giveAType(
            (element) =>
              element.portefeuille.totalValeurPortefeuille - calculateTotalRemise(element)
          )
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyTitresPrint /> : <BodyTitres handleShow={handleShow} />}
      print={print}
      handleShow={handleShow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      tableType={tableType}
    />
  )
}

export default TableBodyTitres
