// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get mandat
 * @reduxAction
 */

export const getMandatLoad = createAction('get-mandat-load')

export const getMandatSuccess = createAction(
  'get-mandat-success',
  (mandat) => {
    return {
      payload: mandat,
    }
  }
)

export const getMandatError = createAction(
  'get-mandat-error', (error) => {
  return {
    payload: error,
  }
})