import React from 'react'
import Card from '../../../../Components/Card'
import BlocTable from '../../../../table/BlocTable'
import DetentionCard from '../../../Detention/DetentionCard'
import GeneralInformationCard from './GeneralInformationCard'
import LinkActifCard from '../../../Patrimoine/LinkActifCard'
import AdditionnalInformationsCard from './AdditionnalInformationsCard'
import FormSubmit from '../../../../Components/FormSubmit'
import { DOC_PASSIF_EMPRUNT, TITLES_DOC_UPDATE } from '../../../../../../constants'
import TableBodyDoc from '../../../../Files/TableBodyDoc'

const FormPassifEmpruntContent = ({
  empruntToDisplay,
  dataChange,
  setDataChange,
  handleChange,
  handleChangeDate,
  information,
  setInformation,
  detenteursToDisplay,
  setDetenteursToDisplay,
  fichiersToDisplay,
  setFichiersToDisplay,
  setToggleAddEmprunt,
  handleSubmit,
  newValeurTotale,
  handleGiveUp,
}) => {
  return (
    <>
      {/* Envoi du formulaire */}
      <FormSubmit dataChange={dataChange} handleSubmit={handleSubmit} handleGiveUp={handleGiveUp} />
      {/* form */}
      <div className="sousSection">
        <div className="cards">
          <GeneralInformationCard
            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            information={information}
          />
          <AdditionnalInformationsCard handleChange={handleChange} information={information} />
        </div>
        <div className="cards cards--fullWidth">
          <LinkActifCard
            handleChange={handleChange}
            information={information}
            setInformation={setInformation}
            type={'Emprunt'}
          />
        </div>
        <div className={empruntToDisplay ? 'cards' : 'cards cards--fullWidth'}>
          <DetentionCard
            information={information}
            setInformation={setInformation}
            detenteursToDisplay={detenteursToDisplay}
            setDetenteursToDisplay={setDetenteursToDisplay}
            newValeurTotale={newValeurTotale}
            setDataChange={setDataChange}
            showUS={false}
          />
          {empruntToDisplay && (
            <Card title={`Documents (${fichiersToDisplay.length})`} h3={true} open={true}>
              <div className="informations">
                <BlocTable
                  titles={TITLES_DOC_UPDATE}
                  data={fichiersToDisplay}
                  setData={setFichiersToDisplay}
                  tableType={DOC_PASSIF_EMPRUNT}
                  children={<TableBodyDoc />}
                  setDataChange={setDataChange}
                  doc={true}
                  title={`Documents (${fichiersToDisplay.length})`}
                  handleAdd={() => setToggleAddEmprunt(true)}
                />
              </div>
            </Card>
          )}
        </div>
      </div>

      {/* Envoi du formulaire */}
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handleSubmit}
        handleGiveUp={handleGiveUp}
        down={true}
      />
    </>
  )
}

export default FormPassifEmpruntContent
