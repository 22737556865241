import React from 'react'
import DateInputComponent from '../../../../Components/DateInputComponent'
import Input from '../../../../Components/Input'

const FormOperationRegularizeInputs = ({
  handlechange,
  newOperation,
  handleChangeDate,
  fondEuro,
}) => {
  const { dateOperation, valeurAchat, dateVersement, nombre } = newOperation || {}

  return (
    <>
      <div className="informations informations--borderFull">
        {/* dateOperation || dateVersement */}
        {!fondEuro ? (
          <DateInputComponent
            name={'dateOperation'}
            label={'Date :'}
            value={dateOperation}
            handleChange={handleChangeDate}
          />
        ) : (
          <DateInputComponent
            name={'dateVersement'}
            label={'Date :'}
            value={dateVersement}
            handleChange={handleChangeDate}
          />
        )}
        {fondEuro ? (
          <Input
            forAndId="valeurAchat"
            type="text"
            name={'Nouveau montant (€) :'}
            value={valeurAchat}
            onChange={(e) => handlechange(e)}
            inputMode="decimal"
            required={true}
          />
        ) : (
          <Input
            forAndId="nombre"
            type="text"
            name="Nouvelle quantité :"
            value={nombre}
            onChange={(e) => handlechange(e)}
            required={true}
            copy={true}
            inputMode="decimal"
          />
        )}
      </div>
    </>
  )
}

export default FormOperationRegularizeInputs
