import React from 'react'
import { PRODUITS_VM } from '../../../../../../constants'

const BodyTitresPrint = ({ element, tableType, index }) => {
  // calculs
  const calculateTotalRemise = (produit) => {
    return produit.transactions.length > 0
      ? produit.transactions.map((el) => el.montant).reduce((acc, curr) => acc + curr)
      : 0
  }

  return (
    <>
      {/* libelle */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Libellé</p>
        <p className="print__table__bloc__line--value">
          {!element.libelle ? '-' : element.libelle}
        </p>
      </div>
      {/* categorie */}
      {tableType !== PRODUITS_VM ? (
        <div className={'print__table__bloc__line'}>
          <div className="print__table__bloc__line--number"></div>
          <p className="print__table__bloc__line--title">Catégorie</p>
          <p className="print__table__bloc__line--value">
            {!element.categorie ? '-' : element.categorie}
          </p>
        </div>
      ) : null}
      {/* liquidite */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Disponibilités</p>
        <p className="print__table__bloc__line--value">
          {!element.portefeuille.totalLiquidite
            ? '-'
            : `${element.portefeuille.totalLiquidite.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
      {/* montantTotalCalcule */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Portefeuille</p>
        <p className="print__table__bloc__line--value">
          {!element.portefeuille.valeurPortefeuille
            ? '-'
            : `${element.portefeuille.totalLiquidite.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
      {/* total */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Total</p>
        <p className="print__table__bloc__line--value">
          {!element.portefeuille.totalValeurPortefeuille
            ? '-'
            : `${element.portefeuille.totalValeurPortefeuille.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
      {/* Gain / Perte */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Gain / Perte</p>
        <p className="print__table__bloc__line--value">
          {!element.portefeuille.totalValeurPortefeuille
            ? '-'
            : `${(
                element.portefeuille.totalValeurPortefeuille - calculateTotalRemise(element)
              ).toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
    </>
  )
}

export default BodyTitresPrint
