import React from 'react'
import { PRODUITS_AV } from '../../../../../../constants'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import AVPieChart from './AVPieChart'
import TableToPrint from '../../../../table/TableToPrint'
import TableBodyAssurances from '../../Catalogue/Table/TableBodyAssurances'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'

const AVToPrint = ({ elementToDisplay, valeurPortefeuilles }) => {
  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurPortefeuilles}
      />
      <PatrimoineChartCardPrint
        children={<AVPieChart produitsAV={elementToDisplay} />}
      />
      <TableToPrint
        data={elementToDisplay}
        tableType={PRODUITS_AV}
        title={`Produits (${elementToDisplay.length})`}
        children={<TableBodyAssurances />}
      />
    </>
  )
}

export default AVToPrint
