// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getChargesError, getChargesLoad, getChargesSuccess } from '../../actions/Patrimoine/actionGetCharges'

// REDUCER // ______________________________________________________________

// initial state
const initialStateCharges = {
  isLoading: false,
  charges: null,
  error: '',
}
// reducer
export const getChargesReducer = createReducer(initialStateCharges, (builder) => {
  return builder
    .addCase(getChargesLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getChargesSuccess, (draft, action) => {
      draft.isLoading = false
      draft.charges = action.payload
      draft.error = ''
      return
    })
    .addCase(getChargesError, (draft, action) => {
      draft.isLoading = false
      draft.charges = null
      draft.error = action.payload
      return
    })
})