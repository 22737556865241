// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getFileError, getFileLoad, getFileSuccess } from '../actions/actionGetFile'

// REDUCER // ______________________________________________________________

// initial state
const initialStateFile = {
  isLoading: false,
  file: null,
  error: '',
}
// reducer
export const getFileReducer = createReducer(initialStateFile, (builder) => {
  return builder
    .addCase(getFileLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getFileSuccess, (draft, action) => {
      draft.isLoading = false
      draft.file = action.payload
      draft.error = ''
      return
    })
    .addCase(getFileError, (draft, action) => {
      draft.isLoading = false
      draft.file = null
      draft.error = action.payload
      return
    })
})
