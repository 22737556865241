import React from 'react'
import { PASSIFS_DETTES } from '../../../../../constants'
import Card from '../../../Components/Card'
import BlocTable from '../../../table/BlocTable'
import TableBodyDette from './Table/TableBodyDette'

const PassifDetteCard = ({ data, handleAddDette, title }) => {
  const titles = [
    'N°',
    'Référence dette',
    'Montant restant',
    'Voir',
    'Modifier',
    'Supprimer',
    'Dupliquer',
  ]
  return (
    <Card title={title} h3={true} open={true}>
      <div className="informations">
        <BlocTable
          data={data}
          titles={titles}
          tableType={PASSIFS_DETTES}
          children={<TableBodyDette />}
          title={title}
          handleAdd={() => handleAddDette()}
        />
      </div>
    </Card>
  )
}

export default PassifDetteCard
