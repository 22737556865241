// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get person type
 * @reduxAction
 */

export const postPersonType = createAction(
  'post-person-type',
  (type) => {
    return {
      payload: type,
    }
  }
)
