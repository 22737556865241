import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import HeaderHomepage from '../../layout/HeaderHomepage'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import NewPassword from './NewPassword'

const Password = () => {
  const navigate = useNavigate()
  // user
  const user = useSelector((state) => state.getUser.user)
  // role
  // let role
  if (user) {
    let roles = user.roles
    if (roles.find((role) => role.id === 2)) {
      // role = 2
      return <Navigate to="/admin/dashboard" replace />
    } else if (roles.find((role) => role.id !== 2 && role.id === 3)) {
      // role = 3
      console.log('role = conseiller')
    } else {
      // role = 1
      return <Navigate to="/user/accueil" replace />
    }
  } else {
    return (
      <div className="homepage">
        <HeaderHomepage />

        <main className="sign__main bkg">
          <div className="sign__main__bloc">
            {/* Close _________________________________________________________*/}
              <div onClick={(e) => navigate('/')} className='sign__main__bloc__close'>
                <AiOutlineCloseCircle />
              </div>
            {/* Title _________________________________________________________*/}
            <h1 className="h1">Mot de passe</h1>

            {/* Form NewPassword ___________________________________________________*/}
            <NewPassword />
          </div>
        </main>
      </div>
    )
  }
}

export default Password
