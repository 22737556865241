import { combineReducers } from "redux";
// reducers imports
import { getTokenReducer } from './getTokenReducer'
import { getUserReducer } from './getUserReducer'
import { postUserReducer } from './postUserReducer'
import { getUsersReducer } from './getUsersReducer'
import { getFirmsReducer } from './getFirmsReducer'
import { getPersonsReducer } from './getPersonsReducer'
import { getPersonReducer } from './getPersonReducer'
import { postPersonTypeReducer } from './postPersonTypeReducer'
import { getFileReducer } from './getFileReducer'
import { getSignaturesPendingReducer } from './getSignaturesPendingReducer'
import { getParapheurReducer } from './getParapheurReducer'
import { getSignaturesToClassifyReducer } from './getSignaturesToClassifyReducer'
import { getIdTransactionReducer } from './getIdTransactionReducer'
import { getMessagesReducer } from './getMessagesReducer'
import { getContactsReducer } from './getContactsReducer'
import { getCopyValueReducer } from './getCopyValueReducer'
import { getPropertiesReducer } from './Patrimoine/getPropertiesReducer'
import { getUserMailReducer } from './getUserMailReducer'
import { getMandatReducer } from './getMandatReducer'
import { getIncomesReducer } from './Patrimoine/getIncomesReducer'
import { getChargesReducer } from './Patrimoine/getChargesReducer'
import { getPassifsReducer } from './Patrimoine/getPassifsReducer'
import { getFinancierReducer } from './Patrimoine/getFinancierReducer'
import { getFormIsActiveReducer } from './getFormIsActiveReducer'
import { getSaveFormReducer } from './getSaveFormReducer'
import { getCataloguesReducer } from './Patrimoine/getCataloguesReducer'
import { getBienReducer } from './Patrimoine/getBienReducer'
import { getSelectionReducer } from './getSelectionReducer'
import { getBienTypeReducer } from './getBienTypeReducer'
import { getCatalogueCommunReducer } from './Patrimoine/getCatalogueCommunReducer'
import { getDicisReducer } from './Patrimoine/getDicisReducer'
import { getSocialReducer } from './Patrimoine/getSocialReducer'
import { getModalsReducer } from "./getModalsReducer";
import { getConnectionServeurReducer } from "./getConnectionServeur";
import { resetStore } from "../actions/actionResetStore";

export const rootReducer = combineReducers({
    token: getTokenReducer,
    getUser: getUserReducer,
    postUser: postUserReducer,
    getUsers: getUsersReducer,
    getPersons: getPersonsReducer,
    getPerson: getPersonReducer,
    postPersonType: postPersonTypeReducer,
    getContacts: getContactsReducer,
    getProperties: getPropertiesReducer,
    getFinancier: getFinancierReducer,
    getSocial: getSocialReducer,
    getIncomes: getIncomesReducer,
    getCharges: getChargesReducer,
    getPassifs: getPassifsReducer,
    getBien: getBienReducer,
    getCatalogues: getCataloguesReducer,
    getDicis: getDicisReducer,
    getCatalogueCommun: getCatalogueCommunReducer,
    getFile: getFileReducer,
    getMessages: getMessagesReducer,
    getSignaturesPending: getSignaturesPendingReducer,
    getSignaturesToClassify: getSignaturesToClassifyReducer,
    getParapheur: getParapheurReducer,
    getIdTransaction: getIdTransactionReducer,
    getUserMail: getUserMailReducer,
    getMandat: getMandatReducer,
    getFirms: getFirmsReducer,
    getCopyValue: getCopyValueReducer,
    formIsActive: getFormIsActiveReducer,
    saveForm: getSaveFormReducer,
    getSelection: getSelectionReducer,
    getBienType: getBienTypeReducer,
    getModals: getModalsReducer,
    getConnection : getConnectionServeurReducer,
})
export const resettableRootReducer = (state, action) => {
    if (action.type === resetStore.type) {
      // Retourner l'état initial pour tous les réducteurs
      return rootReducer(undefined, { type: undefined });
    }
    return rootReducer(state, action);
  };