import React, { useEffect } from 'react'
import { sortByType } from '../../../services/helpers/sort'
import TableBodyBloc from '../../components/table/TableBodyBloc'
import BodyFirms from './BodyFirms'
import BodyFirmsPrint from './BodyFirmsPrint'

const TableBodyFirm = ({
  data,
  setData,
  setModalUpdate,
  setNewData,
  targetToSort,
  sort,
  setDataChange,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  //suppr
  const handleDelete = (element) => {
    setDataChange(true)
    setData(data.filter((el) => el !== element))
  }
  // edit
  const handleEdit = (element) => {
    setModalUpdate(true)
    setNewData(element)
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Nom':
          giveAType('nomCabinet')
          break
        case 'Adresse':
          giveAType('adresseCabinet')
          break
        case 'Date naissance':
          giveAType('dateNaissance')
          break
        case 'Code postal':
          giveAType('cpCabinet')
          break
        case 'Ville':
          giveAType('villeCabinet')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyFirmsPrint /> : <BodyFirms />}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      print={print}
    />
  )
}

export default TableBodyFirm
