import React from 'react'
import { ImEye, ImDownload } from 'react-icons/im'
import { MdUpdate } from 'react-icons/md'
import { TiDelete } from 'react-icons/ti'
import { IoDuplicate } from 'react-icons/io5'
import { AiFillFileZip } from 'react-icons/ai'

const TableActionButtons = ({
  handleShow,
  handleEdit,
  handleDelete,
  handleDuplicate,
  handleFetch,
  handleClassify,
}) => {
  return (
    <>
      {handleShow ? (
        <td className="tbody__tr__td" onClick={handleShow}>
          <div className="tbody__tr__td__button tbody__tr__td__button--blue">
            <ImEye />
          </div>
        </td>
      ) : null}
      {handleEdit ? (
        <td className="tbody__tr__td" onClick={handleEdit}>
          <div className="tbody__tr__td__button">
            <MdUpdate />
          </div>
        </td>
      ) : null}
      {handleDelete ? (
        <td className="tbody__tr__td" onClick={handleDelete}>
          <div className="tbody__tr__td__button">
            <TiDelete />
          </div>
        </td>
      ) : null}
      {handleDuplicate ? (
        <td className="tbody__tr__td" onClick={handleDuplicate}>
          <div className="tbody__tr__td__button">
            <IoDuplicate />
          </div>
        </td>
      ) : null}
      {/* recupérer */}
      {handleFetch ? (
        <td className="tbody__tr__td" onClick={handleFetch}>
          <div className="tbody__tr__td__button">
            <AiFillFileZip />
          </div>
        </td>
      ) : null}
      {/* classer */}
      {handleClassify ? (
        <td className="tbody__tr__td" data-label="Classer" onClick={handleClassify}>
          <div className="tbody__tr__td__button">
            <ImDownload />
          </div>
        </td>
      ) : null}
    </>
  )
}

export default TableActionButtons
