import React from 'react'
import Modale from '../../Components/Modale'
import SelectComponent from '../../Components/SelectComponent'
import { optionsRelationPP } from '../../../../data/options/optionsPP'

const ModalRelationSavePP = ({ setModalRelation, relation, setRelation, handleSave }) => {
  return (
    <Modale close={() => setModalRelation(false)} title="Enregistrement de la relation">
      <div className="informations">
        <div className="informations__blocTxt">
          <p className="informations__blocTxt__txt">
            <span className="informations__blocTxt__txt informations__blocTxt__txt--important">
              REFERENT :{' '}
            </span>
            accès aux informations et modifications{' '}
            <span className="informations__blocTxt__txt informations__blocTxt__txt--important">
              *
            </span>
          </p>
          <p className="informations__blocTxt__txt">
            <span className="informations__blocTxt__txt informations__blocTxt__txt--important">
              RELATION :{' '}
            </span>
            accès aux informations{' '}
            <span className="informations__blocTxt__txt informations__blocTxt__txt--important">
              *
            </span>
          </p>
          <p className="informations__blocTxt__txt informations__blocTxt__txt--note">
            <span className="informations__blocTxt__txt informations__blocTxt__txt--important">
              *{' '}
            </span>
            La personne ajoutée sera sollicitée <br />
            pour donner son consentement par signature électronique
          </p>
        </div>

        {/* relation */}
        <SelectComponent
          forAndId="relation"
          name="Type :"
          options={optionsRelationPP}
          placeholder="Sélectionner"
          onChange={(e) => setRelation(e.value)}
          value={optionsRelationPP.find((c) => c.value === relation)}
          copy={true}
        />
        <div className="informations__buttons">
          {relation !== null ? (
            <div className="button button--blue-red width--12" onClick={() => handleSave()}>
              Enregistrer
            </div>
          ) : (
            <div className="button button--grey width--12">Enregistrer</div>
          )}
        </div>
      </div>
    </Modale>
  )
}

export default ModalRelationSavePP
