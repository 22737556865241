// ______________________________________ financier
export const optionsTransaction = [
  { value: 999999, label: 'Remise de fonds' },
  { value: 888888, label: 'Retrait de fonds' },
  { value: 777777, label: 'Frais de gestion' },
  { value: 666666, label: 'Droit de garde' },
]
export const optionsOperation = [
  { value: true, label: 'Acheter' },
  { value: false, label: 'Vendre' },
]
export const optionsPF = [
  { value: 'Compte bancaire', label: 'Compte bancaire' },
  { value: 'Livret', label: 'Livret' },
  { value: 'CEL (compte épargne logement)', label: 'CEL (compte épargne logement)' },
  { value: 'PEL (Plan épargne logement)', label: 'PEL (Plan épargne logement)' },
  { value: 'Parts sociales et bons de caisse', label: 'Parts sociales et bons de caisse' },
  { value: 'CAT (compte à terme)', label: 'CAT (compte à terme)' },
  { value: 'Certificat de dépôt', label: 'Certificat de dépôt' },
  { value: 'Autre liquidité', label: 'Autre liquidité' },
]
export const optionsLivrets = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'LDD', label: 'LDD' },
  { value: 'Bleu', label: 'Bleu' },
]
export const optionsCategories = [
  { value: 'VM', label: 'Valeurs mobilières (VM)' },
  { value: 'AV', label: 'Assurances vie (AV)' },
  { value: 'PER', label: 'Epargne salariale & PER' },
  { value: 'retraite', label: 'Retraite (assurance)' },
]
export const optionsCatalogues = [
  { value: 'Personnel', label: 'Personnel' },
  { value: 'Général', label: 'Général' },
]
export const optionsNoteSupport = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
]
export const optionsBeneficiaires = [
  { value: 'par défaut', label: 'par défaut' },
  { value: 'voir documents', label: 'voir documents' },
]
export const optionsSupports = [
  { value: '---', label: '---' },
  { value: 'Fond euro', label: 'Fonds euro' },
  { value: 'Support créé', label: 'Support créé' },
]

