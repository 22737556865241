import axios from 'axios'
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants'
import {
  getFinancierError,
  getFinancierLoad,
  getFinancierSuccess,
} from '../../redux/actions/Patrimoine/actionGetFinancier'
import {
  getDicisError,
  getDicisLoad,
  getDicisSuccess,
} from '../../redux/actions/Patrimoine/actionGetDicis'
import { toast } from 'react-toastify'
import { addDays } from 'date-fns'
import { beautifulDate } from '../helpers/dates'
import { getFormIsActive } from '../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../redux/actions/actionSaveForm'
import ToastLastCours from '../../react/components/Patrimoine/Actifs/Catalogue/Table/ToastLastCours'

// financier _____________________________________________________________________________
// recup financier
export const getFinancier = (id, pp) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getFinancierLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/financier/financiers?personneId=${id}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getFinancierSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getFinancierError(error.message))
      })
  }
}
// update valeur totale financier
export const postFinancierTotalValue = (id, value, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getFinancierLoad())
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/financier/majFinancierValeurTotale?personneId=${id}&montant=${value}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getFinancierSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        dispatch(getFinancierError(error.message))
        setStatus(error.response.status)
      })
  }
}
// getCoursByDate
export const getCoursByDate = (isin, date, setCours, setStatus) => {
  const goodDate = beautifulDate(addDays(new Date(date), 1))
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  const toastLoading = toast.loading(`Récupération du dernier cours connu antérieur au ${goodDate}`)
  return () => {
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/financier/coursSupportByDate?isin=${isin}&debut=${date}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        toast.dismiss(toastLoading)
        setCours(response.data.valeur)
        setStatus(response.status)
        response.data.valeur
          ? toast.success('Donnée récupérée avec succès')
          : toast.warn('Aucune valeur enregistrée à cette date, saisir une valeur')
      })
      .catch((error) => {
        setCours(null)
        setStatus(error.response.status)
        toast.dismiss(toastLoading)
        toast.error(`Erreur ${error.response.status}`)
      })
  }
}
// getHistoSupport
export const getHistoSupport = (isin, setData) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    setData({ isLoading: true, data: null, error: null })
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/financier/histoSupport?isin=${isin}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setData({ isLoading: false, data: response.data, error: null })
      })
      .catch((error) => {
        setData({ isLoading: false, data: null, error: error.message })
      })
  }
}
// getLastCours
export const getLastCours = (isin, toastId, setCours = null) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  const toastLoading = toast.loading('Récupération du dernier cours connu')

  return () => {
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/financier/coursSupport?isin=${isin}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        toast.dismiss(toastLoading)
        if (setCours !== null) {
          response.data.valeur !== undefined
            ? setCours(response.data)
            : setCours({ valeur: '', dateVL: '' })
        }
        if (toastId) {
          toast.success(<ToastLastCours isin={isin} response={response} />, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 4000,
          })
        }
      })
      .catch((error) => {
        console.log(error)
        toast.dismiss(toastLoading)
        if (toastId) {
          toast.error(`Erreur ${error.response.status}`)
        }
      })
  }
}
// post financier
export const postFinancier = (type, id, pp, divers, data, setStatus) => {
  const toastLoading = toast.loading('Mise à jour du produit en cours...')
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getFinancierLoad())
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/financier/${type}?personneId=${id}&pp=${pp}&divers=${divers}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
        dispatch(getFinancierSuccess(response.data))
        dispatch(getFormIsActive(null))
        dispatch(getSaveForm(false))
        toast.dismiss(toastLoading)
        toast.success('Produit mis à jour avec succès !')
        // dispatch(getFinancier(id, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
        dispatch(getFinancierError(error.message))
        dispatch(getFormIsActive(null))
        dispatch(getSaveForm(false))
        toast.dismiss(toastLoading)
        toast.error(`Erreur ${error.response.status}`)
      })
  }
}
// delete produit
export const deleteProduct = (type, data, idPP, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${API_BASE_URL}/financier/${type}/${data.id}/${idPP}/${pp}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setStatus(response.status)
        dispatch(getFinancier(idPP, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// delete produit
export const deleteOperationFinancier = (type, data, idP, idPP, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${API_BASE_URL}/financier/${type}/${data.id}/${idP}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setStatus(response.status)
        dispatch(getFinancier(idPP, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// recup dicis
export const getDICIList = () => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getDicisLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/financier/dici`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getDicisSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getDicisError(error))
      })
  }
}
// recup dici
export const getDICISupport = (isin, setData) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/financier/diciSupport?isin=${isin}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setData(response.data)
      })
      .catch((error) => {
        setData(null)
      })
  }
}
// recup frais Maj Frais depuis le debut du contrat
// ou de l'année en cours si debut du contrat est posterieur
// au 1er janvier de l annee en cours
export const getMajFraisFinancier = (type, produitId, personId, pp, setStatus) => {
  const toastLoading = toast.loading('Calcul des frais en cours...')
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/financier/${type}?idProduit=${produitId}&personneId=${personId}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setStatus(response.status)
        toast.dismiss(toastLoading)
        toast.success('Donnée récupérée avec succès')
        dispatch(getFinancier(personId, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
        toast.dismiss(toastLoading)
        toast.error(`Erreur ${error.response.status}`)
      })
  }
}
