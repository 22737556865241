import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMandatSuccess } from '../../../redux/actions/actionGetMandat'
import { postPP, updatePP } from '../../../services/API/personnes'
import { optionsRegime, optionsSexe, optionsSituation } from '../../../data/options/optionsPP'
import { optionsTrueOrFalse } from '../../../data/options/options'
import ModalParapheur from '../Parapheur/ModalParapheur'
import BlocTable from '../table/BlocTable'
import Card from '../Components/Card'
import Input from '../Components/Input'
import SelectComponent from '../Components/SelectComponent'
import ModalCreatePPNotOK from './Modales/ModalCreatePPNotOK'
import ModalRelationSavePP from './Modales/ModalRelationSavePP'
import DateInputComponent from '../Components/DateInputComponent'
import { DOC_PERSON_FAMILY, TITLES_DOC_UPDATE } from '../../../constants'
import TableBodyDoc from '../Files/TableBodyDoc'
import NotCorrect from '../Components/Modales/NotCorrect'
import ModaleFiles from '../Files/ModaleFiles'

const InputsFamily = ({
  person,
  handleChange,
  handleChangeDate,
  family,
  setFamily,
  fichiers,
  setDataChange,
  document,
  setUpdateConjoint,
}) => {
  const {
    situationFam,
    idConjoint,
    dateSituationFam,
    regimeFam,
    dateregimeFam,
    donationEntreEpoux,
  } = family || {}
  const dispatch = useDispatch()
  // user
  const user = useSelector((state) => state.getUser.user)
  // url de parapher
  const url = useSelector((state) => state.getMandat.mandat)
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  const { referentPP, relationPP } = persons || {}
  const listPersons = useMemo(
    () => referentPP.filter((pers) => person && pers.mail !== person.mail).concat(relationPP),
    [persons]
  )
  const [optionsPersons, setOptionsPersons] = useState(null)
  const [personSelected, setPersonSelected] = useState(null)
  const [addSexe, setAddSexe] = useState(null)
  const [noSex, setNoSex] = useState(false)
  const [conjoint, setConjoint] = useState(null)
  const { nom, prenom, mail, dateNaissance, sexe } = conjoint || {}
  const [relation, setRelation] = useState(null)
  // post PP
  const dataPP = {
    id: 0,
    nom: nom,
    prenom: prenom,
    mail: mail,
    dateNaissance: dateNaissance,
    sexe: sexe,
    villeNaissance: '',
    telFixe: '',
    telPortable: '',
    typeRelation: 'AUTRE',
    adresseFiscale: {},
    coordonneesSupplementaires: [],
    nomNaissance: '',
    nationnalite1: '',
    nationnalite2: '',
    capaciteLegale: '',
    situationFam: situationFam,
    idConjoint: person ? person.id : 0,
    dateSituationFam: dateSituationFam,
    regimeFam: regimeFam,
    dateregimeFam: dateregimeFam,
    donationEntreEpoux: donationEntreEpoux,
    user_id: {
      email: user.email,
    },
    professions: [],
    relations: [],
    fichiers: [],
  }
  // modale
  const [modalAdd, setModalAdd] = useState(false)
  const [modalNotCorrect, setModalNotCorrect] = useState(false)
  const [modalCreateOk, setModalCreateOk] = useState(false)
  const [modalNotOk, setModalNotOk] = useState(false)
  const [modalRelation, setModalRelation] = useState(false)
  // toggle
  const [toggleAdd, setToggleAdd] = useState(false)
  // reponse
  const [status, setStatus] = useState(null)
  const [statusUpdate, setStatusUpdate] = useState(null)
  // files
  const [data, setData] = useState(fichiers)
  useEffect(() => {
    setData(
      fichiers.filter(
        (el) =>
          (el.typeFichierGED === 'FAMILLE' || el.typeFichierGED === 'MARIAGE') && el.dateDocument
      )
    )
  }, [fichiers])
  // optionsPersons
  useEffect(() => {
    setOptionsPersons([
      ...listPersons.map((item) => ({ value: item, label: `${item.prenom} ${item.nom}` })),
      { value: 'Ajouter', label: 'Ajouter' },
    ])
  }, [persons])
  // idConjoint
  useEffect(() => {
    if (personSelected) {
      personSelected !== 'Ajouter'
        ? testSexePersonSelected()
        : setFamily({ ...family, idConjoint: 0 })
    } else {
      setFamily({ ...family, idConjoint: 0 })
    }
  }, [personSelected])

  // test personSelected
  const testSexePersonSelected = () => {
    if (personSelected.sexe === '' || personSelected.sexe === undefined || !personSelected.sexe) {
      setNoSex(true)
    } else {
      setNoSex(false)
    }
    setFamily({ ...family, idConjoint: personSelected.id })
  }
  // handleSelectPerson
  const handleSelectPerson = (e) => {
    if (e) {
      // persons.relationPP.filter(pers => pers.id === e.value.id).length > 0 &&
      setUpdateConjoint(true)
      setPersonSelected(e.value)
    } else {
      setPersonSelected(null)
    }
    setDataChange(true)
  }

  // maj affichage si person et idConjoint ______________________________________
  useEffect(() => {
    if (person && idConjoint !== 0) {
      setPersonSelected(
        listPersons && listPersons.filter((personne) => personne.id === idConjoint)[0]
      )
    }
  }, [idConjoint])

  // maj personSelected si pers créée ____________________________________________
  useEffect(() => {
    if (nom && prenom && optionsPersons) {
      const newPartner = optionsPersons.filter((option) => option.label === `${prenom} ${nom}`)[0]
      newPartner !== undefined && setFamily({ ...family, idConjoint: newPartner.value.id })
    }
  }, [optionsPersons])

  // handleChangeConjoint
  const handleChangeConjoint = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      setConjoint({ ...conjoint, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      setConjoint({ ...conjoint, [name]: value })
    }
  }
  // handleChangeDateConjoint
  const handleChangeDateConjoint = (date, name) => {
    setConjoint({ ...conjoint, [name]: date })
  }

  const handleSavePerson = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (nom !== '' && prenom !== '' && mail !== '' && dateNaissance !== '') {
      // create PP
      setModalRelation(true)
    } else {
      setModalNotCorrect(true)
    }
  }
  const handleSave = () => {
    if (relation !== null) {
      dispatch(postPP(dataPP, setStatus))
      setModalRelation(false)
    }
  }
  // saveSex
  const handleSaveSex = (e) => {
    setAddSexe(e.value)
    setNoSex(false)
    dispatch(updatePP({ ...personSelected, sexe: e.value }, setStatusUpdate))
  }
  // response create PP status and modal
  useEffect(() => {
    if (status) {
      if (status <= 301) {
        if (url && url !== '') {
          setModalCreateOk(true)
          window.open(url)
          dispatch(getMandatSuccess(null))
        }
      } else {
        setModalNotOk(true)
      }
      setStatus(null)
    }
  }, [status])
  // response update conjoint add sex
  useEffect(() => {
    if (statusUpdate >= 200 && statusUpdate < 300) {
      setStatusUpdate(null)
    }
    if (statusUpdate >= 400) {
      setStatusUpdate(null)
    }
  }, [statusUpdate])

  // close Modal Parapheur
  const closeModalCreateOk = () => {
    setModalCreateOk(false)
  }

  return (
    <>
      <Card title="Situation maritale" open={true}>
        <div className="informations">
          {/* Situation */}
          <SelectComponent
            forAndId="situationFam"
            name="Situation :"
            isSearchable={false}
            options={optionsSituation}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsSituation.find((c) => c.value === situationFam)}
            copy={true}
          />
          {/* En date du */}
          <DateInputComponent
            name={'dateSituationFam'}
            label={'En date du :'}
            value={dateSituationFam}
            handleChange={handleChangeDate}
          />
          {/* Conjoint */}
          <SelectComponent
            forAndId="personSelected"
            name="Conjoint / partenaire :"
            isClearable={true}
            options={optionsPersons && optionsPersons}
            placeholder="Sélectionner"
            onChange={(e) => handleSelectPerson(e)}
            value={optionsPersons && optionsPersons.find((c) => c.value === personSelected)}
            // copy={true}
          />
          {personSelected && noSex && (
            /* sexe */
            <div className="informations informations--borderFull">
              <p>{`Sexe de naissance de ${personSelected.prenom} ${personSelected.nom} :`}</p>
              <SelectComponent
                forAndId="sexe"
                // name={`Sexe de naissance de ${personSelected.prenom} ${personSelected.nom} :`}
                options={optionsSexe}
                placeholder="Sélectionner"
                onChange={(e) => handleSaveSex(e)}
                value={optionsSexe.find((c) => c.value === addSexe)}
                copy={true}
                noLabel={true}
              />
            </div>
          )}
          {personSelected && personSelected === 'Ajouter' ? (
            <div className="informations informations--border">
              <div className="informations informations--borderFull">
                {/* nom */}
                <Input
                  forAndId="nom"
                  type="text"
                  name="Nom d'usage :"
                  value={nom}
                  onChange={(e) => handleChangeConjoint(e)}
                  placeholder="Nom"
                  copy={true}
                  required={true}
                />
                {/* prénom */}
                <Input
                  forAndId="prenom"
                  type="text"
                  name="Prénom :"
                  value={prenom}
                  onChange={(e) => handleChangeConjoint(e)}
                  placeholder="Prénom"
                  copy={true}
                  required={true}
                />
                {/* mail */}
                <Input
                  forAndId="mail"
                  type="email"
                  name="Mail :"
                  value={mail}
                  onChange={(e) => handleChangeConjoint(e)}
                  placeholder="exemple@gmail.com"
                  copy={true}
                  required={true}
                />
              </div>
              {/* Date de naissance */}
              <DateInputComponent
                name={'dateNaissance'}
                label={'Date de naissance :'}
                value={dateNaissance}
                handleChange={handleChangeDateConjoint}
              />
              {/* sexe */}
              <SelectComponent
                name="Sexe de naissance :"
                options={optionsSexe}
                placeholder="Sélectionner"
                onChange={(e, name) => handleChangeConjoint(e, name)}
                value={optionsSexe.find((c) => c.value === sexe)}
                copy={true}
                required={true}
              />
              {nom && prenom && mail ? (
                <div className="informations__buttons">
                  <div
                    className="button button--red-red width--15"
                    onClick={(e) => handleSavePerson(e)}
                  >
                    Créer une personne
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          {/* Regime */}
          <SelectComponent
            forAndId="regimeFam"
            name="Régime :"
            isSearchable={false}
            options={optionsRegime}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsRegime.find((c) => c.value === regimeFam)}
            copy={true}
          />
          {/* Date du contrat */}
          <DateInputComponent
            name={'dateregimeFam'}
            label={'Date du contrat :'}
            value={dateregimeFam}
            handleChange={handleChangeDate}
          />
          {/* donation */}
          <SelectComponent
            forAndId="donationEntreEpoux"
            name="Donation entre époux : "
            isSearchable={false}
            options={optionsTrueOrFalse}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsTrueOrFalse.find((c) => c.value === donationEntreEpoux)}
            copy={true}
          />
        </div>
        {/* fichiers */}
        {person && document === DOC_PERSON_FAMILY ? (
          !toggleAdd ? (
            <div
              className="informations__dropdown informations__dropdown--full"
              onClick={() => setToggleAdd(!toggleAdd)}
            >
              <p>{`Documents (${data.length})`}</p>
            </div>
          ) : (
            <div className="informations__dropdown--border">
              <p onClick={() => setToggleAdd(!toggleAdd)}>{`Documents (${data.length})`}</p>
              <div className="informations">
                <BlocTable
                  titles={TITLES_DOC_UPDATE}
                  data={data}
                  setData={setData}
                  tableType={DOC_PERSON_FAMILY}
                  children={<TableBodyDoc />}
                  setDataChange={setDataChange}
                  doc={true}
                  title={`Documents (${data.length})`}
                  handleAdd={() => setModalAdd(true)}
                />
              </div>
            </div>
          )
        ) : null}
      </Card>
      {modalAdd && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_PERSON_FAMILY}
          setModal={setModalAdd}
          setDataChange={setDataChange}
        />
      )}
      {/* modal response ok _____________________________________________________________*/}
      {modalCreateOk && <ModalParapheur closeModal={closeModalCreateOk} />}
      {/* modal response not ok _________________________________________________________*/}
      {modalNotOk && (
        <ModalCreatePPNotOK
          setModalNotOk={setModalNotOk}
          message={'Une personne existe déjà avec cette adresse mail'}
          conjointRelation={true}
          setModalRelation={setModalRelation}
        />
      )}
      {/* modal not correct _____________________________________________________________*/}
      {modalNotCorrect && <NotCorrect setNotCorrect={setModalNotCorrect} />}
      {/* modale relation or not ________________________________________________________*/}
      {modalRelation && (
        <ModalRelationSavePP
          setModalRelation={setModalRelation}
          relation={relation}
          setRelation={setRelation}
          handleSave={handleSave}
        />
      )}
    </>
  )
}

export default InputsFamily
