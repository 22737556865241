import React, { useEffect, useRef, useState } from 'react'
import { Page, Document } from 'react-pdf/dist/esm/entry.webpack5'
import { pdfjs } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

const MyDocument = ({ file, title }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }
  const previousPage = () => {
    changePage(-1)
  }
  const nextPage = () => {
    changePage(1)
  }
  const pdfContainerRef = useRef(null); // Référence pour le conteneur du document PDF
  const [pdfWidth, setPdfWidth] = useState(0); // État pour stocker la largeur actuelle du PDF
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPageNumber(1)
    updatePdfWidth(); // Mettre à jour la largeur au chargement du document
  }
  // Mise à jour de la largeur basée sur la largeur du conteneur du PDF
  const updatePdfWidth = () => {
    if (pdfContainerRef.current) {
      setPdfWidth(pdfContainerRef.current.offsetWidth);
    }
  };
  // Écouteur d'événements pour les changements de taille de la fenêtre
  useEffect(() => {
    window.addEventListener('resize', updatePdfWidth);
    return () => window.removeEventListener('resize', updatePdfWidth);
  }, []);

  return (
    <div className='myDocumentDiv' ref={pdfContainerRef}>
    <p className='myDocumentDiv--txtEllipsis' style={{
        maxWidth: pdfWidth
      }}>{title}</p>
      <Document
        title={title}
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        options={{ workerSrc: '/pdf.worker.js' }}
        externalLinkTarget="_blank"
      >
        <Page
          pageNumber={pageNumber}
          max-width={1000}
          style={{ display: 'flex', justifyContent: 'center' }}
        />
        <div className="document__pages">
          <p>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </p>
          <div className="document__pages__buttons">
            <button type="button" disabled={pageNumber <= 1} onClick={() => previousPage()}>
              Previous
            </button>
            <button type="button" disabled={pageNumber >= numPages} onClick={() => nextPage()}>
              Next
            </button>
          </div>
        </div>
      </Document>
    </div>
  )
}

export default MyDocument
