import React from 'react'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import { optionsTypeImmo } from '../../../../../../data/options/optionsActifs'

const BodyBienImmoPrint = ({ element, index }) => {
  return (
    <>
      {/* nom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">{!element.nom ? '-' : element.nom}</p>
      </div>
      {/* valeur */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Valeur</p>
        <p className="print__table__bloc__line--value">
          {!element.valeur && element.valeur !== 0
            ? '-'
            : `${element.valeur.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
        </p>
      </div>
      {/* typeImmobilier */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Catégorie</p>
        <p className="print__table__bloc__line--value">
          {!element.typeImmobilier ? '-' : recupLabel(element.typeImmobilier, optionsTypeImmo)}
        </p>
      </div>
      {/* adresse */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Adresse</p>
        <p className="print__table__bloc__line--value">
          {!element.adresse ? '-' : element.adresse}
        </p>
      </div>
      {/* codePostal */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Code postal</p>
        <p className="print__table__bloc__line--value">
          {!element.codePostal ? '-' : element.codePostal}
        </p>
      </div>
      {/* ville */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Ville</p>
        <p className="print__table__bloc__line--value">{!element.ville ? '-' : element.ville}</p>
      </div>
    </>
  )
}

export default BodyBienImmoPrint
