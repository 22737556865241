// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import {
  getDicisError,
  getDicisLoad,
  getDicisSuccess,
} from '../../actions/Patrimoine/actionGetDicis'

// REDUCER // ______________________________________________________________

// initial state
const initialStateDicis = {
  isLoading: false,
  dicis: null,
  error: '',
}
// reducer
export const getDicisReducer = createReducer(initialStateDicis, (builder) => {
  return builder
    .addCase(getDicisLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getDicisSuccess, (draft, action) => {
      draft.isLoading = false
      draft.dicis = action.payload
      draft.error = ''
      return
    })
    .addCase(getDicisError, (draft, action) => {
      draft.isLoading = false
      draft.dicis = null
      draft.error = action.payload
      return
    })
})
