import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { postFirm } from '../../../services/API/cabinets'
import InputsAddFirm from './InputsAddFirm'

const AddFirm = ({ setModalNotCorrect, setStatus }) => {
  const dispatch = useDispatch()
  // fields
  const [fields, setFields] = useState({
    nomCabinet: '',
    adresseCabinet: '',
    cpCabinet: '',
    villeCabinet: '',
  })
  const { nomCabinet, adresseCabinet, cpCabinet, villeCabinet } = fields || {}
  const [dataChange, setDataChange] = useState(false)

  // retrieve values
  const handleChange = (e) => {
    setDataChange(true)
    const { name, value } = e.target
    setFields({ ...fields, [name]: value })
  }

  // submit
  const handleSubmit = (e) => {
    // Object to send
    const data = {
      nomCabinet: nomCabinet && nomCabinet.toUpperCase().trim(),
      adresseCabinet: adresseCabinet && adresseCabinet.trim(),
      cpCabinet: cpCabinet && cpCabinet.trim(),
      villeCabinet: villeCabinet && villeCabinet.trim(),
    }
    e.preventDefault()
    if (
      dataChange &&
      nomCabinet !== '' &&
      adresseCabinet !== '' &&
      cpCabinet !== '' &&
      villeCabinet !== ''
    ) {
      dispatch(postFirm(data, setStatus))
    } else {
      setModalNotCorrect(true)
    }
  }

  return (
    <InputsAddFirm
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      fields={fields}
      setFields={setFields}
      setDataChange={setDataChange}
    />
  )
}

export default AddFirm
