import axios from "axios"
import { ACCESS_TOKEN, API_BASE_URL } from "../../constants"
import { getFirmsError, getFirmsLoad, getFirmsSuccess } from "../../redux/actions/actionGetFirms"

// ajout cabinet
export const postFirm = (data, setStatus) => {
    const token = sessionStorage.getItem(ACCESS_TOKEN)
    return () => {
      axios({
        method: 'POST',
        url: `${API_BASE_URL}/cabinets/add`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((response) => {
          setStatus(response.status)
        })
        .catch((error) => {
          setStatus(error.response.status)
        })
    }
  }
  
  // récupération liste cabinets
  export const getFirms = () => {
    const token = sessionStorage.getItem(ACCESS_TOKEN)
    return (dispatch) => {
      dispatch(getFirmsLoad())
      axios
        .get(API_BASE_URL + `/users/listecabinets`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          dispatch(getFirmsSuccess(response.data))
        })
        .catch((error) => {
          dispatch(getFirmsError(error.message))
        })
    }
  }
  