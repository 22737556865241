import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PRODUITS_AV, PRODUITS_PER, PRODUITS_RETRAITE, PRODUITS_VM } from '../../../../../constants'
import useFormIsActive from '../../../../layout/useFormIsActive'
import useCustomPrint from '../../../../layout/useCustomPrint'
import Loader from '../../../../layout/Loader'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import NavProduct from './NavProduct'
import ProductToPrint from './ProductToPrint'

const ProductHome = ({ categorie }) => {
  // nav
  const formIsActive = useFormIsActive()
  // bien
  const bien = useSelector((state) => state.getBien.bien)
  // redirection
  const formRedirection =
    categorie === 'VM'
      ? '/user/patrimoine/actifs/financier/valeurs-mobilieres/ajouter'
      : categorie === 'AV'
      ? '/user/patrimoine/actifs/financier/assurances-vie/ajouter'
      : categorie === 'PER'
      ? '/user/patrimoine/actifs/social/PER/formulaire'
      : '/user/patrimoine/actifs/social/retraite/formulaire'
  const backRedirection =
    categorie === 'VM'
      ? '/user/patrimoine/actifs/financier/valeurs-mobilieres'
      : categorie === 'AV'
      ? '/user/patrimoine/actifs/financier/assurances-vie'
      : categorie === 'PER'
      ? '/user/patrimoine/actifs/social/PER'
      : '/user/patrimoine/actifs/social/retraite'

  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!bien) return <Navigate to={backRedirection} />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<ProductToPrint elementToDisplay={bien} categorie={categorie} />}
        />
        <ButtonsRow
          handlePrint={handlePrint}
          formLink={formRedirection}
          backLink={backRedirection}
          tableType={
            categorie === 'AV'
              ? PRODUITS_AV
              : categorie === 'retraite'
              ? PRODUITS_RETRAITE
              : categorie === 'VM'
              ? PRODUITS_VM
              : PRODUITS_PER
          }
        />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            <article className="card">
              <NavProduct categorie={categorie} />
              <div
                className={
                  formIsActive
                    ? 'card__content card__content--multiActive'
                    : 'card__content card__content--multiInActive'
                }
              >
                <Outlet />
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductHome
