import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { AiFillHome } from 'react-icons/ai'
import { MdOutlineChevronRight } from 'react-icons/md'
import SaveForm from './SaveForm'
import useFormIsActive from './useFormIsActive'

const Thread = () => {
  const { pathname } = useLocation()
  const [arrayPath, setArrayPath] = useState([])
  const formIsActive = useFormIsActive()
  // modales
  const [toggleSaveForm, setToggleSaveForm] = useState(false)
  // handleClick
  const handleClick = () => {
    formIsActive && setToggleSaveForm(true)
  }
  // actualisation arrayPath
  useEffect(() => {
    setArrayPath(pathname.split('/').slice(1))
  }, [pathname])

  return (
    <>
      <div className="thread" onClick={() => handleClick()}>
        {formIsActive ? (
          <div className={'thread__formIsActive'}>
            <AiFillHome />
          </div>
        ) : (
          <NavLink
            to={'/'}
            className={({ isActive }) => (isActive ? 'thread__active' : 'thread__inactive')}
            end
          >
            <AiFillHome />
            <p className="sr-only">Accueil</p>
          </NavLink>
        )}
        <MdOutlineChevronRight />
        {arrayPath.length > 1 &&
          (formIsActive ? (
            <div className={'thread__formIsActive'}>{arrayPath[1]}</div>
          ) : (
            <NavLink
              to={`/user/${arrayPath[1]}`}
              className={({ isActive }) => (isActive ? 'thread__active' : 'thread__inactive')}
              end
            >
              {arrayPath[1]}
            </NavLink>
          ))}
        {arrayPath.length > 2 &&
          (formIsActive ? (
            <>
              <MdOutlineChevronRight />
              <div className={'thread__formIsActive'}>{arrayPath[2]}</div>
            </>
          ) : (
            <>
              <MdOutlineChevronRight />
              <NavLink
                to={`/user/${arrayPath[1]}/${arrayPath[2]}`}
                className={({ isActive }) => (isActive ? 'thread__active' : 'thread__inactive')}
                end
              >
                {arrayPath[2]}
              </NavLink>
            </>
          ))}
        {arrayPath.length > 3 &&
          (formIsActive ? (
            <>
              <MdOutlineChevronRight />
              <div className={'thread__formIsActive'}>{arrayPath[3]}</div>
            </>
          ) : (
            <>
              <MdOutlineChevronRight />
              <NavLink
                to={`/user/${arrayPath[1]}/${arrayPath[2]}/${arrayPath[3]}`}
                className={({ isActive }) => (isActive ? 'thread__active' : 'thread__inactive')}
                end
              >
                {arrayPath[3]}
              </NavLink>
            </>
          ))}
        {arrayPath.length > 4 &&
          (formIsActive ? (
            <>
              <MdOutlineChevronRight />
              <div className={'thread__formIsActive'}>{arrayPath[4]}</div>
            </>
          ) : (
            <>
              <MdOutlineChevronRight />
              <NavLink
                to={`/user/${arrayPath[1]}/${arrayPath[2]}/${arrayPath[3]}/${arrayPath[4]}`}
                className={({ isActive }) => (isActive ? 'thread__active' : 'thread__inactive')}
                end
              >
                {arrayPath[4]}
              </NavLink>
            </>
          ))}
        {arrayPath.length > 5 &&
          (formIsActive ? (
            <>
              <MdOutlineChevronRight />
              <div className={'thread__formIsActive'}>{arrayPath[5]}</div>
            </>
          ) : (
            <>
              <MdOutlineChevronRight />
              <NavLink
                to={`/user/${arrayPath[1]}/${arrayPath[2]}/${arrayPath[3]}/${arrayPath[4]}/${arrayPath[5]}`}
                className={({ isActive }) => (isActive ? 'thread__active' : 'thread__inactive')}
                end
              >
                {arrayPath[5]}
              </NavLink>
            </>
          ))}
        {arrayPath.length > 6 &&
          (formIsActive ? (
            <>
              <MdOutlineChevronRight />
              <div className={'thread__formIsActive'}>{arrayPath[6]}</div>
            </>
          ) : (
            <>
              <MdOutlineChevronRight />
              <NavLink
                to={`/user/${arrayPath[1]}/${arrayPath[2]}/${arrayPath[3]}/${arrayPath[4]}/${arrayPath[5]}/${arrayPath[6]}`}
                className={({ isActive }) => (isActive ? 'thread__active' : 'thread__inactive')}
                end
              >
                {arrayPath[6]}
              </NavLink>
            </>
          ))}
      </div>
      {toggleSaveForm && (
        <SaveForm toggleSaveForm={toggleSaveForm} setToggleSaveForm={setToggleSaveForm} />
      )}
    </>
  )
}

export default Thread
