import React, { useEffect } from 'react'
import { sortByType } from '../../../../../../services/helpers/sort'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyTransactions from './BodyTransactions'
import BodyTransactionsPrint from './BodyTransactionsPrint'

const TableBodyTransactions = ({
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Nature':
          giveAType('code')
          break
        case 'Montant de la transaction':
          giveAType('montant')
          break
        case 'Date de la transaction':
          giveAType('dateTransaction')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyTransactionsPrint /> : <BodyTransactions />}
      print={print}
    />
  )
}

export default TableBodyTransactions
