import React, { useState, useEffect } from 'react'
import Card from '../Components/Card'
import Input from '../Components/Input'
import SelectComponent from '../Components/SelectComponent'
import BlocTable from '../table/BlocTable'
import AutoCompleteAdresse from '../AutoCompleteAddress/AutoCompleteAdresse'
import { DOC_PERSON_BIRTH, TITLES_DOC_UPDATE } from '../../../constants'
import TableBodyDoc from '../Files/TableBodyDoc'
import ModaleFiles from '../Files/ModaleFiles'

const InputsBirth = ({
  person,
  handleChange,
  birth,
  setBirth,
  optionsCapacity,
  fichiers,
  document,
  setDataChange,
}) => {
  const { nomNaissance, nationnalite1, nationnalite2, capaciteLegale } = birth || {}
  // modale
  const [modalAdd, setModalAdd] = useState(false)
  // toggle
  const [toggleAdd, setToggleAdd] = useState(false)
  // files
  const [data, setData] = useState(fichiers)
  useEffect(() => {
    setData(fichiers.filter((el) => el.typeFichierGED === 'NAISSANCE' && el.dateDocument))
  }, [fichiers])
  return (
    <>
      <Card title="Naissance" open={true}>
        <div className="informations">
          {/* Nom de naissance */}
          <Input
            forAndId="nomNaissance"
            type="text"
            name="Nom de naissance :"
            value={nomNaissance}
            onChange={(e) => handleChange(e)}
            placeholder="Nom de naissance"
            copy={true}
          />
          {/* villeNaissance */}
          <AutoCompleteAdresse
            data={birth}
            setData={setBirth}
            personInfos={true}
            setDataChange={setDataChange}
          />
          {/* nationalité 1 */}
          <Input
            forAndId="nationnalite1"
            type="text"
            name="Nationnalité 1 :"
            value={nationnalite1}
            onChange={(e) => handleChange(e)}
            placeholder="français"
            copy={true}
          />
          {/* nationalité 2 */}
          <Input
            forAndId="nationnalite2"
            type="text"
            name="Nationnalité 2 :"
            value={nationnalite2}
            onChange={(e) => handleChange(e)}
            placeholder="canadien"
            copy={true}
          />
          {/* Capacité légale */}
          <SelectComponent
            forAndId="capaciteLegale"
            name="Capacité légale :"
            isSearchable={false}
            options={optionsCapacity}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsCapacity.find((c) => c.value === capaciteLegale)}
            copy={true}
          />
        </div>
        {/* fichiers */}
        {person && document === DOC_PERSON_BIRTH ? (
          !toggleAdd ? (
            <div
              className="informations__dropdown informations__dropdown--full"
              onClick={() => setToggleAdd(!toggleAdd)}
            >
              <p>{`Documents (${data.length})`}</p>
            </div>
          ) : (
            <div className="informations__dropdown--border">
              <p onClick={() => setToggleAdd(!toggleAdd)}>{`Documents (${data.length})`}</p>
              <div className="informations">
                <BlocTable
                  titles={TITLES_DOC_UPDATE}
                  data={data}
                  setData={setData}
                  tableType={DOC_PERSON_BIRTH}
                  children={<TableBodyDoc />}
                  setDataChange={setDataChange}
                  doc={true}
                  title={`Documents (${data.length})`}
                  handleAdd={() => setModalAdd(true)}
                />
              </div>
            </div>
          )
        ) : null}
      </Card>
      {modalAdd && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_PERSON_BIRTH}
          setModal={setModalAdd}
          setDataChange={setDataChange}
        />
      )}
    </>
  )
}

export default InputsBirth
