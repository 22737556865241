import React from 'react'
import { dateFormateComplete } from '../../../../../../../services/helpers/dates'
import { optionsTypePeriodicite } from '../../../../../../../data/options/options'
import { recupLabel } from '../../../../../../../services/helpers/helpers'

const BodyPrevoyancePrint = ({ element, index }) => {
  return (
    <>
      {/* libelle */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Libellé</p>
        <p className="print__table__bloc__line--value">
          {!element.libelle ? '-' : element.libelle}
        </p>
      </div>
      {/* assureur */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Assureur</p>
        <p className="print__table__bloc__line--value">
          {!element.assureur ? '-' : element.assureur}
        </p>
      </div>
      {/* nomContrat */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Contrat</p>
        <p className="print__table__bloc__line--value">
          {!element.nomContrat ? '-' : element.nomContrat}
        </p>
      </div>
      {/* numero */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Numéro</p>
        <p className="print__table__bloc__line--value">{!element.numero ? '-' : element.numero}</p>
      </div>
      {/* categorie */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Catégorie</p>
        <p className="print__table__bloc__line--value">
          {!element.categorie ? '-' : element.categorie}
        </p>
      </div>
      {/* sousCategorie */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Sous-catégorie</p>
        <p className="print__table__bloc__line--value">
          {!element.sousCategorie ? '-' : element.sousCategorie}
        </p>
      </div>
      {/* dateSouscription */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Souscription</p>
        <p className="print__table__bloc__line--value">
          {!element.dateSouscription ? '-' : dateFormateComplete(element.dateSouscription)}
        </p>
      </div>
      {/* duree */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Durée</p>
        <p className="print__table__bloc__line--value">
          {!element.duree
            ? '-'
            : `${element.duree.toLocaleString(undefined, { minimumFractionDigits: 4 })} mois`}
        </p>
      </div>
      {/* dateFin */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Fin</p>
        <p className="print__table__bloc__line--value">
          {!element.dateFin ? '-' : dateFormateComplete(element.dateFin)}
        </p>
      </div>
      {/* capital */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Capital</p>
        <p className="print__table__bloc__line--value">
          {!element.capital && element.capital !== 0
            ? '-'
            : `${element.capital.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
        </p>
      </div>
      {/* taux */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Taux</p>
        <p className="print__table__bloc__line--value">
          {!element.taux
            ? '-'
            : `${element.taux.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
        </p>
      </div>
      {/* renteEstime */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Rente estimée</p>
        <p className="print__table__bloc__line--value">
          {!element.renteEstime
            ? '-'
            : `${element.renteEstime.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
        </p>
      </div>
      {/* frequenceCotisation */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Periodicité</p>
        <p className="print__table__bloc__line--value">
          {!element.frequenceCotisation
            ? '-'
            : recupLabel(element.frequenceCotisation, optionsTypePeriodicite)}
        </p>
      </div>
      {/* montantEcheance */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Montant</p>
        <p className="print__table__bloc__line--value">
          {!element.montantEcheance
            ? '-'
            : `${element.montantEcheance.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
    </>
  )
}

export default BodyPrevoyancePrint
