import React, { useEffect } from 'react'
import { sortByType } from '../../../services/helpers/sort'
import TableBodyBloc from '../table/TableBodyBloc'
import BodyMessages from './BodyMessages'
import BodyMessagesPrint from './BodyMessagesPrint'

const TableBodyMessages = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  noLineNumber,
  print,
}) => {
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Date':
          giveAType('createdAt')
          break
        case 'Message':
          giveAType('message')
          break
        case 'Origine':
          giveAType('origineMessage')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  //suppr
  const handleDelete = (element) => {
    setNewData(element)
    setModalDelete(true)
  }

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      noLineNumber={noLineNumber}
      children={print ? <BodyMessagesPrint /> : <BodyMessages handleDelete={handleDelete} />}
      handleDelete={handleDelete}
      print={print}
    />
  )
}

export default TableBodyMessages
