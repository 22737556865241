import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'

const ModaleComponent = ({
  close,
  sign,
  calendar,
  title,
  line1,
  line2,
  line3,
  line4,
  children,
}) => {
  return (
    <div
      className={`modal-overlay modalBackground ${
        sign ? 'modalBackground--sign' : calendar ? 'modalBackground--calendar' : ''
      }`}
    >
      <div
        className={`modalBackground__modal ${
          sign
            ? 'modalBackground__modal--sign sign__main__bloc'
            : calendar
            ? 'modalBackground__modal--calendar'
            : ''
        }`}
      >
        {!calendar ? (
          <div className="modalBackground__modal__close" tabIndex={2} onClick={close}>
              <div
                className={
                  !sign
                    ? 'sign__main__bloc__close'
                    : 'sign__main__bloc__close sign__main__bloc__close--sign'
                }
              >
                <AiOutlineCloseCircle />
              </div>
          </div>
        ) : null}
        {sign ? (
          <h1 className="h1 sign__title">{title}</h1>
        ) : !calendar ? (
          <p className="modalBackground__modal__txt">{title}</p>
        ) : null}

        {line1 && (
          <div className="modalBackground__modal__paragraphe">
            {line1 && <p>{line1}</p>}
            {line2 && <p>{line2}</p>}
            {line3 && <p>{line3}</p>}
            {line4 && line4}
          </div>
        )}
        <div className="modalBackground__modal__content">{children}</div>
      </div>
    </div>
  )
}

export default ModaleComponent
