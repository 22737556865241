import React, { useEffect } from 'react'
import { sortByType } from '../../../../../../services/helpers/sort'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodySupports from './BodySupports'
import BodySupportsPrint from './BodySupportsPrint'
import { FE } from '../../../../../../constants'

const TableBodySupports = ({
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
  tableType,
  handleClick,
}) => {
  // tri croissant/decroissant
  const giveAType = (typeOrFunc) => {
    sortByType(sort, dataToDisplay, typeOrFunc, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Fournisseur':
          giveAType('m_nom')
          break
        case 'Libellé':
          giveAType('nom')
          break
        case 'Nom':
          giveAType('nom')
          break
        case 'ISIN':
          giveAType('isin')
          break
        case 'Cours':
          giveAType((element) => element.c_nom !== FE)
          break
        case 'DICI':
          giveAType((element) => (element.dici_url !== 'NULL' && element.dici_url ? true : false))
          break
        case 'Note':
          giveAType('note')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodySupportsPrint /> : <BodySupports handleclick={handleClick} />}
      print={print}
      tableType={tableType}
    />
  )
}

export default TableBodySupports
