// IMPORTS // ______________________________________________________________
import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { copyValue } from '../actions/actionCopy'

// REDUCER // ______________________________________________________________

// initial state
const initialStateCopy = {
  value: null,
}
// reducer
export const getCopyValueReducer = createReducer(initialStateCopy, (builder) => {
  return builder
    .addCase(copyValue, (draft, action) => {
      draft.value = action.payload
      return
    })
})