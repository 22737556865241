import React from 'react'
import Modale from '../../../Components/Modale'
import DateInputComponent from '../../../Components/DateInputComponent'

const ModaleDate = ({
  information,
  setInformation,
  setModaleDate,
  categorieP,
  handlePreSubmit,
}) => {
  const { dateEffet, dateOuverture } = information || {}
  console.log(dateEffet)
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setInformation({ ...information, [name]: date })
  }

  return (
    <Modale
      close={() => setModaleDate(false)}
      title={'Date manquante'}
    >
      <div className="informations">
        {categorieP === 'VM' || categorieP === 'PER' ? (
          <DateInputComponent
            name={'dateOuverture'}
            label={"Date d'ouverture :"}
            value={dateEffet}
            handleChange={handleChangeDate}
          />
        ) : (
          <DateInputComponent
            name={'dateEffet'}
            label={"Date d'effet :"}
            value={dateEffet}
            handleChange={handleChangeDate}
          />
        )}
        <div className="informations__buttons">
          {dateEffet || dateOuverture ? (
            <button
              className="button button--red-red width--12"
              onClick={(e) => handlePreSubmit(e)}
            >
              Enregistrer
            </button>
          ) : (
            <div className="button button--grey width--12">Enregistrer</div>
          )}
        </div>
      </div>
    </Modale>
  )
}

export default ModaleDate
