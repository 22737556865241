import React from 'react'

const Loader = ({ table }) => {
  return table === true ? (
    <tbody className="modalBackground modalBackground--loader">
      <tr className="loader"></tr>
    </tbody>
  ) : (
    <div className="modalBackground modalBackground--loader">
      <div className="loader"></div>
    </div>
  )
}

export default Loader
