import React from 'react'
import { useSelector } from 'react-redux'
import { dataRevenuMobGeneral } from '../../../../../../data/informations/revenus'
import { DOC_REVENU_MOBILIER } from '../../../../../../constants'
import InformationsCardPrint from '../../../../Informations/InformationsCardPrint'
import BienInfoPrintDetenteurEtDoc from '../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'

const RevenuMobInfoPrint = ({ elementToDisplay }) => {
  const properties = useSelector((state) => state.getProperties.properties)
  const { detenteurs, fichiers } = elementToDisplay || {}

  return (
    <>
      <InformationsCardPrint
        data={dataRevenuMobGeneral(
          elementToDisplay,
          null,
          elementToDisplay.idActif ? properties : null,
          null
        )}
        title={elementToDisplay.libelle}
      />
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={DOC_REVENU_MOBILIER}
      />
    </>
  )
}

export default RevenuMobInfoPrint
