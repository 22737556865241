import React, { useMemo } from 'react'
import { COLORS } from '../../../../../../constants'
import PieChartComponent from '../../../../Charts/PieChartComponent'
import LegendPieChart from '../../../../Charts/LegendPieChart'

const FinancierPieChart = ({ financier, detenteurs, print }) => {
  const { comptesBancaires, produitsAV, produitsVM, valeurDetenteurs, nomDetenteurs } =
    financier || {}
  const valeurLiquidites = useMemo(
    () => produitsVM.map((produit) => produit.liquidite).reduce((acc, curr) => acc + curr, 0),
    [produitsVM]
  )

  // data
  const dataFinancier = [
    {
      name: 'Portefeuilles Valeurs Mobilières',
      value:
        produitsVM.length > 0
          ? produitsVM
              .map((produit) => produit.montantTotalCalcule)
              .reduce((acc, current) => acc + current)
          : 0,
      fill: COLORS.colorRepartition01,
      color: 'repartition02',
    },
    {
      name: 'Portefeuilles Assurances vie',
      value:
        produitsAV.length > 0
          ? produitsAV
              .map((produit) => produit.montantTotalCalcule)
              .reduce((acc, current) => acc + current)
          : 0,
      fill: COLORS.colorRepartition02,
      color: 'repartition03',
    },
    {
      name: 'Produits Financiers',
      value:
        comptesBancaires.length > 0
          ? comptesBancaires
              .map((compte) => (!compte.clos ? compte.solde : 0))
              .reduce((acc, current) => acc + current)
          : 0,
      fill: COLORS.colorRepartition03,
      color: 'repartition03',
    },
    {
      name: 'Disponibilités',
      value: valeurLiquidites,
      fill: COLORS.colorPrimary,
      color: 'divers',
    },
  ]

  // detenteurs
  const arrayDetenteurs = []
  for (let i = 0; i < nomDetenteurs.length; i++) {
    arrayDetenteurs.push({ nom: nomDetenteurs[i], valeur: valeurDetenteurs[i] })
  }
  const dataDetenteurs = arrayDetenteurs.map((det) => ({ name: det.nom, value: det.valeur }))
  const data = !detenteurs ? dataFinancier : dataDetenteurs

  const title = !detenteurs ? 'Répartition' : 'Détenteurs'
  return (
    <>
      <PieChartComponent data={data} title={title} detenteurs={detenteurs} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title} detenteurs={detenteurs} />
    </>
  )
}

export default FinancierPieChart
