import React from 'react'
import AutoCompleteAdresse from '../AutoCompleteAddress/AutoCompleteAdresse'
import Input from '../Components/Input'

const InputsAddFirm = ({ handleSubmit, handleChange, fields, setFields, setDataChange }) => {
  const { nomCabinet } = fields || {}
  return (
    <div className="informations">
      {/* nomCabinet */}
      <Input
        errorLabel={fields.nomCabinet === ''}
        required={true}
        forAndId="nomCabinet"
        type="text"
        name="Nom du cabinet :"
        value={nomCabinet}
        onChange={(e) => handleChange(e)}
        placeholder="CABINET DE GESTION"
          copy={true}
      />
      <AutoCompleteAdresse
        data={fields}
        setData={setFields}
        cabinet={true}
        setDataChange={setDataChange}
      />
      <div className="informations__buttons">
        <div className="button button--red-red width--18" onClick={(e) => handleSubmit(e)}>
          Enregistrer
        </div>
      </div>
    </div>
  )
}

export default InputsAddFirm
