import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { getCharges } from '../../../../../services/API/charges'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { CHARGES_EMPRUNTS } from '../../../../../constants'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import ChargesCard from '../Charges/ChargesCard'
import ChargesEmpruntDetteToPrint from './ChargesEmpruntDetteToPrint'
import ModalUpdateTotalValueChgPensions from './ModalUpdateTotalValueChgEmpruntDette'
import ChgEmpruntDettePieChart from './ChgEmpruntDettePieChart'
import LittleLoader from '../../../../layout/LittleLoader'

const ChgEmpruntDettesHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const charges = useSelector((state) => state.getCharges.charges)
  const { empruntEtDettes } = charges || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: empruntEtDettes.empruntEtDetteDivers?.montant },
      {
        label: 'Affecté',
        value:
          empruntEtDettes.valeurEmpruntEtDettes - empruntEtDettes.empruntEtDetteDivers?.montant,
      },
    ],
    [empruntEtDettes.empruntEtDetteDivers, empruntEtDettes.valeurEmpruntEtDettes]
  )
  // recharge
  useEffect(() => {
    if (!charges) {
      dispatch(getCharges(person?.id, isPP))
    }
  }, [charges, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () =>
      empruntEtDettes.empruntEtDettes.filter(
        (revenu) => revenu.dateFin && new Date(revenu.dateFin) < new Date()
      ),
    [empruntEtDettes]
  )
  const dataToDisplayNotSold = useMemo(
    () =>
      empruntEtDettes.empruntEtDettes.filter(
        (revenu) => new Date(revenu.dateFin) >= new Date() || !revenu.dateFin
      ),
    [empruntEtDettes.empruntEtDettes]
  )
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // redirections
  const formLink = '/user/patrimoine/charges/emprunts-et-dettes/ajouter'
  // recuperation actifs
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!charges) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <ChargesEmpruntDetteToPrint
              elementToDisplay={empruntEtDettes}
              values={values}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={empruntEtDettes.valeurEmpruntEtDettes}
              update={true}
              values={values}
            />
            {empruntEtDettes.valeurEmpruntEtDettes > 0 && (
              <PatrimoineChartCard
                children={<ChgEmpruntDettePieChart empruntEtDettes={empruntEtDettes} />}
                detenteurs={true}
              />
            )}
          </div>
          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <ChargesCard
              data={dataToDisplayNotSold}
              handleAdd={() => navigate(formLink)}
              title={`Emprunts et dettes en cours (${dataToDisplayNotSold.length})`}
              tableType={CHARGES_EMPRUNTS}
            />
            {dataToDisplaySold.length > 0 && (
              <ChargesCard
                data={dataToDisplaySold}
                handleAdd={() => navigate(formLink)}
                title={`Historique des emprunts et dettes (${dataToDisplaySold.length})`}
                tableType={CHARGES_EMPRUNTS}
              />
            )}
          </div>
        </div>
        {toggleUpdateTotal && (
          <ModalUpdateTotalValueChgPensions
            setToggleUpdateTotal={setToggleUpdateTotal}
            person={person}
            charges={charges}
            pp={isPP}
          />
        )}
      </div>
    </>
  )
}

export default ChgEmpruntDettesHome
