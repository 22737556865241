import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCatalogues } from '../../../../../services/API/catalogue'
import { optionsPER } from '../../../../../data/options/optionsSocial'
import { optionsBeneficiaires } from '../../../../../data/options/optionsFinancier'
import SelectComponent from '../../../Components/SelectComponent'
import Modale from '../../../Components/Modale'
import Input from '../../../Components/Input'
import DateInputComponent from '../../../Components/DateInputComponent'

const ModalInformationsCard = ({
  setOpenModalEdit,
  information,
  setInformation,
  categorieP,
  setDataChange,
}) => {
  const dispatch = useDispatch()
  //
  const {
    libelle,
    fournisseur,
    categorie,
    dateEffet,
    dateOuverture,
    numeroContrat,
    beneficiaire,
    tauxFraisGestionUC,
    tauxFraisGestionFE,
  } = information
  // cataloguescatalogues
  const catalogues = useSelector((state) => state.getCatalogues.catalogues)
  const goodCatalogue = useMemo(
    () => (catalogues ? catalogues.filter((catalogue) => catalogue.categorie === categorieP) : []),
    [catalogues, categorieP]
  )
  const match = useMemo(
    () => goodCatalogue.filter((el) => el.libelle === libelle)[0],
    [goodCatalogue, libelle]
  )
  useEffect(() => {
    !catalogues && dispatch(getCatalogues())
  }, [catalogues])
  useEffect(() => {
    setInformation({
      ...information,
      fournisseur: match ? match.etablissement : '',
      dateOuverture: dateOuverture ? new Date(dateOuverture).toISOString().slice(0, 10) : '',
      dateEffet: dateEffet ? new Date(dateEffet).toISOString().slice(0, 10) : '',
    })
  }, [match])

  // change
  const [change, setChange] = useState(false)
  useEffect(() => {
    if (libelle !== '' && fournisseur !== '' && (dateEffet !== '' || dateOuverture !== '')) {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [information])

  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e) {
      if (e.target !== undefined) {
        const { name, value } = e.target
        setInformation({ ...information, [name]: value })
      } else {
        setInformation({
          ...information,
          [selectName.name]: e.label,
        })
      }
    } else {
      setInformation({ ...information, [selectName.name]: '', fournisseur: '' })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setInformation({ ...information, [name]: date })
  }
  // handleSave
  const handleSave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDataChange(true)
    setOpenModalEdit(false)
  }

  return (
    <Modale
      close={() => setOpenModalEdit(false)}
      title="Modifier les informations"
    >
      <div className="informations">
        <Input
          forAndId="fournisseur"
          type="text"
          name="Fournisseur :"
          value={fournisseur}
          copy={true}
          disabled={true}
        />
        <Input
          forAndId="libelle"
          type="text"
          name="Produit :"
          value={libelle}
          copy={true}
          disabled={true}
        />
        {/* Date d'ouverture */}
        {categorieP !== 'AV' && categorieP !== 'retraite' ? (
          <DateInputComponent
            name={'dateOuverture'}
            label={"Date d'ouverture :"}
            errorLabel={dateOuverture === ''}
            value={dateOuverture}
            handleChange={handleChangeDate}
          />
        ) : null}
        {/* categorie */}
        {categorieP === 'PER' ? (
          <SelectComponent
            forAndId="categorie"
            errorLabel={categorie === ''}
            name="Catégorie :"
            isSearchable={true}
            isClearable={true}
            options={optionsPER}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsPER && optionsPER.find((c) => c.label === categorie)}
            copy={true}
            required={true}
          />
        ) : null}
        {categorieP === 'AV' || categorieP === 'retraite' ? (
          <>
            {/* numeroContrat */}
            <Input
              errorLabel={!numeroContrat}
              forAndId="numeroContrat"
              type="text"
              name="Numéro de contrat :"
              value={numeroContrat}
              onChange={(e, name) => handleChange(e, name)}
              copy={true}
            />
            {/* Date d'effet */}
            <DateInputComponent
              name={'dateEffet'}
              label={"Date d'effet :"}
              value={dateEffet}
              handleChange={handleChangeDate}
            />
            {/* beneficiaire */}
            <SelectComponent
              forAndId="beneficiaire"
              errorLabel={!beneficiaire}
              name="Bénéficiaires :"
              options={optionsBeneficiaires}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={optionsBeneficiaires.find((c) => c.value === beneficiaire)}
              copy={true}
              required={true}
            />
            {/* tauxFraisGestionUC */}
            <Input
              forAndId="tauxFraisGestionUC"
              errorLabel={!tauxFraisGestionUC}
              type="text"
              name="Frais de gestion des UC (%) :"
              value={tauxFraisGestionUC}
              onChange={(e, name) => handleChange(e, name)}
              copy={true}
            />
            {/* tauxFraisGestionFE */}
            <Input
              forAndId="tauxFraisGestionFE"
              errorLabel={!tauxFraisGestionFE}
              type="text"
              name="Frais de gestion des fonds (%) :"
              value={tauxFraisGestionFE}
              onChange={(e, name) => handleChange(e, name)}
              copy={true}
            />
          </>
        ) : null}
        {change ? (
          <div className="informations__buttons">
            <button className="button button--red-red width--12" onClick={(e) => handleSave(e)}>
              Enregistrer
            </button>
          </div>
        ) : (
          <div className="informations__buttons">
            <div className="button button--grey width--12">Enregistrer</div>
          </div>
        )}
      </div>
    </Modale>
  )
}

export default ModalInformationsCard
