import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFile } from '../../../services/helpers/files'
import Modale from '../Components/Modale'
import * as doc from '../../../constants'
import usePersonData from '../../layout/usePersonData'

const DeleteDoc = ({ setModalDelete, newData, tableType, setDataChange }) => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  // bien
  const bien = useSelector((state) => state.getBien.bien)
  const bienType = useSelector((state) => state.getBienType.type)
  const categorie = bienType ? bienType.categorie : null
  // urlConfig
  const urlConfig = {
    [doc.DOC_BIEN_IMMO]: {
      urlPart: '/patrimoine/deleteFileBien',
    },
    [doc.DOC_BIEN_PRO]: {
      urlPart: '/patrimoine/deleteFileBienPro',
    },
    [doc.DOC_BIEN_AUTRE]: {
      urlPart: '/patrimoine/deleteFileAutreActif',
    },
    [doc.DOC_PASSIF_EMPRUNT]: {
      urlPart: '/passif/deleteFileEmprunt',
    },
    [doc.DOC_PASSIF_DETTE]: {
      urlPart: '/passif/deleteFileDette',
    },
    [doc.DOC_REVENU_FONCIER]: {
      urlPart: '/revenu/deleteFileRevenuFoncier',
    },
    [doc.DOC_REVENU_MOBILIER]: {
      urlPart: '/revenu/deleteFileRevenuMobilier',
    },
    [doc.DOC_REVENU_PRO]: {
      urlPart: '/revenu/deleteFileRevenuPro',
    },
    [doc.DOC_REVENU_PENSION]: {
      urlPart: '/revenu/deleteFilePensionRente',
    },
    [doc.DOC_REVENU_AUTRE]: {
      urlPart: '/revenu/deleteFileAutreRevenu',
    },
    [doc.DOC_CHARGE_GENERALE]: {
      urlPart: '/charge/deleteFileChargeGenerale',
    },
    [doc.DOC_CHARGE_DEDUCTIBLE]: {
      urlPart: '/charge/deleteFileChargeDeductibleRI',
    },
    [doc.DOC_CHARGE_REDUCTION]: {
      urlPart: '/charge/deleteFileChargeOuvrantDroitReductionRI',
    },
    [doc.DOC_CHARGE_PENSION]: {
      urlPart: '/charge/deleteFilePension',
    },
    [doc.DOC_CHARGE_IMPOT]: {
      urlPart: '/charge/deleteFileImpotEtTaxe',
    },
    [doc.DOC_CHARGE_EMPRUNT]: {
      urlPart: '/charge/deleteFileEmpruntEtDette',
    },
    [doc.DOC_FINANCIER_AV]: {
      urlPart: '/financier/deleteFileProduitAV',
    },
    [doc.DOC_FINANCIER_VM]: {
      urlPart: '/financier/deleteFileProduitVM',
    },
    [doc.DOC_FINANCIER_COMPTE]: {
      urlPart: '/financier/deleteFileProduitCB',
    },
    [doc.DOC_SOCIAL_PER]: {
      urlPart: '/social/deleteFilePER',
    },
    [doc.DOC_SOCIAL_RETRAITE]: {
      urlPart: '/social/deleteFileRetraite',
    },
    [doc.DOC_SOCIAL_PREVOYANCE]: {
      urlPart: '/social/deleteFileSocialPrevoyance',
    },
  }

  const setUrl = (tableType) => {
    const config = urlConfig[tableType]
    if (!config) return `${doc.API_BASE_URL}/fichiers/deleteFile/${newData.id}/${person.id}/${isPP}`
    return `${doc.API_BASE_URL}${config.urlPart}/${newData.id}/${bien.id}/${isPP}`
  }
  // delete
  const handleDelete = () => {
    setDataChange(true)
    dispatch(deleteFile(setUrl(tableType), setModalDelete, person.id, isPP, categorie))
  }

  return (
    <>
      <Modale
        close={() => setModalDelete(false)}
        title="Souhaitez-vous réellement supprimer définitivement ce fichier ?"
      >
        <div className="informations">
          <div className="informations__blocTxt">
            <p>{newData.fileName}</p>
          </div>
          <div className="informations__buttons">
            <div className="button button--blue-red width--12" onClick={() => handleDelete()}>
              Supprimer
            </div>
            <div
              className="button button--blue-red width--12"
              onClick={() => setModalDelete(false)}
            >
              Annuler
            </div>
          </div>
        </div>
      </Modale>
    </>
  )
}

export default DeleteDoc
