import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useFormIsActive = () => {
  // recup
  const isActive = useSelector((state) => state.formIsActive.isActive)

  // is active
  const [formIsActive, setFormIsActive] = useState(false)
  useEffect(() => {
    isActive ? setFormIsActive(true) : setFormIsActive(false)
  }, [isActive])

  return formIsActive
}

export default useFormIsActive
