import React, { useRef, useState } from 'react'
import { IoIosAdd, IoIosRemove } from 'react-icons/io'
import { NavLink } from 'react-router-dom'

const Burger = ({ links, setToggle }) => {
  const subMenuRef = useRef(null)
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null)
  const handleClick = (index) => {
    setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? null : index))
  }
  return (
    <nav className="burger" onMouseLeave={() => setToggle(false)}>
      <ul className="burger__list">
        {links.map((link, index) => (
          <li key={index} className="burger__item">
            <div className="burger__item__title">
              <NavLink to={link.link} className="burger__link" onClick={() => setToggle(false)}>
                  <div className={'burger__icon'}>{link.icon}</div>
                <p className="burger__txt">{link.txt}</p>
              </NavLink>
              {link.sub ? (
                <div className="burger__txtIcon" id={index} onClick={() => handleClick(index)}>
                  {openSubMenuIndex === index ? <IoIosRemove /> : <IoIosAdd />}
                </div>
              ) : null}
            </div>
            {openSubMenuIndex === index && link.sub ? (
              <ul className="burger__subLinks" ref={subMenuRef}>
                {link.subLinks.map((el, ind) => (
                  <li key={`sub1-${ind}`} onClick={() => setToggle(false)}>
                    <NavLink to={el.link}>
                      <p>{el.txt}</p>
                    </NavLink>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Burger
