import React from 'react'
import { recupLabelComplexWithOptions } from '../../../services/helpers/helpers'
import { optionsTypeGedPatrimoine } from '../../../data/options/optionsFiles'
import { beautifulDate } from '../../../services/helpers/dates'
import { DOC_PERSON_DIVERS, DOC_PERSON_IDENTITY } from '../../../constants'

const BodyDoc = ({ element, handleShow, tableType }) => {
  // selectTypeGed
  const selectTypeGed = (el) => {
    const str = el.typeGed
    const typeGed = str.includes('-') ? str.slice(0, str.indexOf('-')) : str
    return el.typeFichierGED === 'PATRIMOINE'
      ? recupLabelComplexWithOptions(typeGed, optionsTypeGedPatrimoine)
      : typeGed
  }
  // selectFileName
  const selectFileName = (el) => {
    const str = el.typeGed
    const fileName = str.includes('-') ? str.slice(str.indexOf('-') + 1, str.length) : str
    return fileName
  }

  return (
    <>
      {/* Type */}
      <td className="tbody__tr__td" onClick={() => element.createdAt && handleShow(element)}>
        {!element.typeGed ? (
          <p className="informations__line__notxt">-</p>
        ) : tableType !== DOC_PERSON_DIVERS ? (
          selectTypeGed(element)
        ) : (
          'Divers'
        )}
      </td>
      {/* Name */}
      <td className="tbody__tr__td" onClick={() => element.createdAt && handleShow(element)}>
        {!element.typeGed ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          selectFileName(element)
        )}
      </td>
      {/* dateDocument */}
      <td className="tbody__tr__td" onClick={() => element.createdAt && handleShow(element)}>
        {!element.dateDocument ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.dateDocument)
        )}
      </td>
      {/* dateValidite */}
      {tableType === DOC_PERSON_IDENTITY ? (
        <td className="tbody__tr__td" onClick={() => element.createdAt && handleShow(element)}>
          {!element.dateValidite ? (
            <p className="informations__line__notxt">-</p>
          ) : (
            beautifulDate(element.dateValidite)
          )}
        </td>
      ) : null}
      {/* updatedAt */}
      <td className="tbody__tr__td" onClick={() => element.createdAt && handleShow(element)}>
        {!element.updatedAt ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.updatedAt)
        )}
      </td>
    </>
  )
}

export default BodyDoc
