import React from 'react'
import Card from '../../../../Components/Card'
import SelectComponent from '../../../../Components/SelectComponent'
import Input from '../../../../Components/Input'
import { optionsBeneficiaires } from '../../../../../../data/options/optionsFinancier'
import DateInputComponent from '../../../../Components/DateInputComponent'

const AVAddProductPageContent = ({
  handleChange,
  handleChangeDate,
  handleChangeFrais,
  optionsProducts,
  information,
  histoFrais,
}) => {
  const {
    libelle,
    numeroContrat,
    fournisseur,
    dateEffet,
    beneficiaire,
    tauxFraisGestionUC,
    tauxFraisGestionFE,
  } = information || {}
  const { annee, tauxPerformanceFE } = histoFrais || {}

  return (
    <>
      <div className="cards cards--fullWidth">
        <Card title="Choisir un produit" h5={true} open={true}>
          <div className="informations">
            {/* product */}
            <SelectComponent
              forAndId="libelle"
              errorLabel={libelle === ''}
              name="Sélectionner un produit dans le catalogue :"
              isSearchable={true}
              isClearable={true}
              options={optionsProducts}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={optionsProducts && optionsProducts.find((c) => c.label === libelle)}
              copy={true}
              required={true}
            />
          </div>
        </Card>
      </div>
      {libelle ? (
        <div className="cards">
          <Card title="Informations" h5={true} open={true}>
            <div className="informations informations--borderFull">
              <Input
                forAndId="etablissement"
                type="text"
                name="Fournisseur :"
                value={fournisseur}
                copy={true}
                disabled={true}
              />
              <Input
                forAndId="libelle"
                type="text"
                name="Produit :"
                value={libelle}
                copy={true}
                disabled={true}
              />
              {/* numeroContrat */}
              <Input
                forAndId="numeroContrat"
                type="text"
                name="Numéro de contrat :"
                value={numeroContrat}
                onChange={(e, name) => handleChange(e, name)}
                copy={true}
              />
              {/* Date d'effet */}
              <DateInputComponent
                name={'dateEffet'}
                label={"Date d'effet :"}
                value={dateEffet}
                handleChange={handleChangeDate}
              />
              {/* beneficiaire */}
              <SelectComponent
                forAndId="beneficiaire"
                errorLabel={beneficiaire === ''}
                name="Bénéficiaires :"
                options={optionsBeneficiaires}
                placeholder="Sélectionner"
                onChange={(e, name) => handleChange(e, name)}
                value={optionsBeneficiaires.find((c) => c.value === beneficiaire)}
                copy={true}
                required={true}
              />
            </div>
          </Card>
          <div className="cards cards--column">
            <Card title="Frais annuels" h5={true} open={true}>
              <div className="informations">
                {/* tauxFraisGestionUC */}
                <Input
                  forAndId="tauxFraisGestionUC"
                  type="text"
                  name="Frais de gestion des UC (%) :"
                  value={tauxFraisGestionUC}
                  onChange={(e, name) => handleChange(e, name)}
                  copy={true}
                />
                {/* tauxFraisGestionFE */}
                <Input
                  forAndId="tauxFraisGestionFE"
                  type="text"
                  name="Frais de gestion des fonds (%) :"
                  value={tauxFraisGestionFE}
                  onChange={(e, name) => handleChange(e, name)}
                  copy={true}
                />
              </div>
            </Card>
            <Card title="Performance" h5={true} open={true}>
              <div className="informations">
                <div className="informations">
                  {/* annee */}
                  <Input
                    forAndId="annee"
                    type="number"
                    name="Année de référence :"
                    value={annee}
                    onChange={(e) => handleChangeFrais(e)}
                    copy={true}
                  />
                  {/* tauxPerformanceFE */}
                  <Input
                    forAndId="tauxPerformanceFE"
                    type="text"
                    name="Performance des fonds (%) :"
                    value={tauxPerformanceFE}
                    onChange={(e) => handleChangeFrais(e)}
                    copy={true}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default AVAddProductPageContent
