import React from 'react'
import Modale from '../components/Components/Modale'

const ModalSaveForm = ({ setModaleSave, handleSave, handleQuit, setToggle }) => {
  const handleClose = () => {
    setModaleSave(false)
    setToggle(false)
  }
  return (
    <Modale
      close={() => handleClose()}
      title="Attention !"
      line1="N'oubliez pas d'enregistrer avant de quitter le formulaire"
    >
      <div className="button button--blue-red width--12" onClick={() => handleSave()}>
        Enregistrer
      </div>
      <div className="button button--blue-red width--12" onClick={() => handleQuit()}>
        Abandonner
      </div>
    </Modale>
  )
}

export default ModalSaveForm
