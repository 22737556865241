import React from 'react'
import { REVENUS_PENSIONS } from '../../../../../../constants'
import TableToPrint from '../../../../table/TableToPrint'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import TableBodyRevenu from '../../Revenus/Table/TableBodyRevenu'
import PensionRentePieChart from '../PensionRentePieChart'

const RevenusPRToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurPensionsRentes } = elementToDisplay || {}

  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurPensionsRentes}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<PensionRentePieChart pensionsRentes={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={REVENUS_PENSIONS}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyRevenu />}
      />
      <TableToPrint
        data={dataSold}
        tableType={REVENUS_PENSIONS}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyRevenu />}
      />
    </>
  )
}

export default RevenusPRToPrint
