import React from 'react'

const LegendBarChart = ({ data }) => {
  return (
    <div className="legend">
      {data.map((element, index) => (
        <div key={index}>
          <h3 className="legend__title">{element.name}</h3>
          {element.array.map((el, ind) => (
            <div className="legend__item" key={ind}>
              <div className={`legend__item__color`} style={{backgroundColor: `${el.fill}`}}></div>
              <p className="legend__item__txt">{el.name}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default LegendBarChart
