import React, { useMemo } from 'react'
import InformationsCardPrint from './InformationsCardPrint'
import {
  dataBirth,
  dataFamily,
  dataFiscalAddress,
  dataIdentity,
} from '../../../data/informations/infosPP'
import TableToPrint from '../table/TableToPrint'
import { useSelector } from 'react-redux'
import TableBodyRelation from '../FormPP/Table/TableBodyRelation'
import { PP_ACTIVITY, PP_ADDRESS, PP_RELATIONS } from '../../../constants'
import TableBodyAddress from './TableBodyAddress'
import TableBodyJob from '../FormPP/Table/TableBodyJob'

const InformationsPPPrint = () => {
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  const listPPPersons = useMemo(() => persons.referentPP.concat(persons.relationPP), [persons])
  // person
  const person = useSelector((state) => state.getPerson.person)
  // destructuring person
  const { fichiers, relations, coordonneesSupplementaires, professions } = person || {}

  return (
    <>
      <InformationsCardPrint
        data={dataIdentity(person)}
        dataDoc={fichiers.filter((el) => el.typeFichierGED === 'IDENTITE')}
        title={'Informations essentielles'}
      />
      {relations?.length ? (
        <TableToPrint
          data={relations}
          tableType={PP_RELATIONS}
          title={'Relations'}
          children={<TableBodyRelation />}
        />
      ) : null}
      <InformationsCardPrint
        data={dataFiscalAddress(person)}
        dataDoc={fichiers.filter((el) => el.typeFichierGED === 'DOMICILE')}
        title={'Adresse fiscale'}
      />
      {/* <span className="breakAfter"></span> */}
      {coordonneesSupplementaires?.length ? (
        <TableToPrint
          data={coordonneesSupplementaires}
          tableType={PP_ADDRESS}
          title={'Historique des adresses fiscales'}
          children={<TableBodyAddress />}
        />
      ) : null}
      <InformationsCardPrint
        data={dataBirth(person)}
        dataDoc={fichiers.filter((el) => el.typeFichierGED === 'NAISSANCE')}
        title={'Naissance'}
      />
      <InformationsCardPrint
        data={dataFamily(person, listPPPersons)}
        dataDoc={fichiers.filter(
          (el) => el.typeFichierGED === 'FAMILLE' || el.typeFichierGED === 'MARIAGE'
        )}
        title="Situation maritale"
      />
      {professions?.length ? (
        <TableToPrint
          data={professions}
          tableType={PP_ACTIVITY}
          title="Activités"
          children={<TableBodyJob />}
        />
      ) : null}
    </>
  )
}

export default InformationsPPPrint
