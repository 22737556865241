import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { dataCatalogue } from '../../../../../data/informations/financier'
import { CATALOGUES, SUPPORTS_INFOS } from '../../../../../constants'
import useCustomPrint from '../../../../layout/useCustomPrint'
import Loader from '../../../../layout/Loader'
import Card from '../../../Components/Card'
import ConditionnalUpdateButton from '../../../Components/ConditionnalUpdateButton'
import PrintComponent from '../../../Components/PrintComponent'
import BlocTable from '../../../table/BlocTable'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import TableBodySupports from './Table/TableBodySupports'
import CatalogueInfoPrint from './Print/CatalogueInfoPrint'
import MainInformationsCard from '../../../Components/MainInformationsCard'

const CatalogueInfo = () => {
  const navigate = useNavigate()
  const { bien } = useSelector((state) => state.getBien)
  const { categorie } = bien
  // redirection
  const formRedirection = `/user/patrimoine/actifs/${
    categorie === 'PER' || categorie === 'retraite' ? 'social' : 'financier'
  }/catalogue/formulaire/${categorie}`
  const backRedirection = `/user/patrimoine/actifs/${
    categorie === 'PER' || categorie === 'retraite' ? 'social' : 'financier'
  }/catalogue`
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()
  // titles
  const titles = ['N°', 'Fournisseur', 'Libellé', 'ISIN', 'Cours', 'DICI', 'Note']

  if (!bien) return <Navigate to={backRedirection} />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<CatalogueInfoPrint elementToDisplay={bien} />}
        />
        <ButtonsRow
          handlePrint={handlePrint}
          formLink={formRedirection}
          backLink={backRedirection}
          tableType={CATALOGUES}
        />
        <div className="sousSection">
          <div className="cards">
            {/* Produit */}
            <MainInformationsCard
              title={'Informations principales'}
              h5={true}
              data={dataCatalogue}
              element={bien}
              redirection={() => navigate(formRedirection)}
            />
            {/* Supports */}
            <Card title="Supports" h5={true} open={true}>
              <BlocTable
                data={bien.dicis}
                titles={titles}
                tableType={SUPPORTS_INFOS}
                children={<TableBodySupports />}
                title={`${bien.libelle} - supports`}
              />
              <ConditionnalUpdateButton redirection={() => navigate(formRedirection)} />
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default CatalogueInfo
