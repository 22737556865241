// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get firms
 * @reduxAction
 */

export const getFirmsLoad = createAction('get-firms-load')

export const getFirmsSuccess = createAction(
  'get-firms-success',
  (firms) => {
    return {
      payload: firms,
    }
  }
)

export const getFirmsError = createAction(
  'get-firms-error', (error) => {
  return {
    payload: error,
  }
})