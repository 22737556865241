// autocomplétion formulaires

import { normalizeAndLowerCase } from './strings'

export const fetchAddress = async (address, postcode, setAddressResults) => {
  const data = await fetch(
    `https://api-adresse.data.gouv.fr/search/?q=${normalizeAndLowerCase(
      address
    )}&type=housenumber&postcode=${postcode}&autocomplete=1&limit=20`
  )
  const json = await data.json()
  const response = await json.features
  setAddressResults([...response])
}

export const fetchPostcode = async (postcode, setPostcodeResults) => {
  const data = await fetch(
    `https://api-adresse.data.gouv.fr/search/?q=postcode=${postcode}&type=municipality&autocomplete=1&limit=5`
  )
  const json = await data.json()
  const response = await json.features
  setPostcodeResults([...response])
}

export const fetchCity = async (city, setCityResults) => {
  const data = await fetch(
    `https://api-adresse.data.gouv.fr/search/?q=${normalizeAndLowerCase(
      city
    )}&type=municipality&autocomplete=1&limit=10`
  )
  const json = await data.json()
  const response = await json.features
  setCityResults([...response])
}

export const fetchCountry = async (country, setCountryResults) => {
  const data = await fetch(
    `https://data.gouv.nc/api/records/1.0/search/?dataset=liste-des-pays-et-territoires-etrangers&q=${country}&lang=fr&facet=cog`
  )
  const json = await data.json()
  const response = await json.records
  setCountryResults([...response])
}
