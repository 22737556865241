import React from 'react'
import InformationsCardPrint from '../../../Informations/InformationsCardPrint'
import { dataProduit, dataProduitFrais } from '../../../../../data/informations/financier'
import BienInfoPrintDetenteurEtDoc from '../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'
import TableToPrint from '../../../table/TableToPrint'
import {
  DOC_FINANCIER_VM,
  PRODUIT_FRAIS,
  PRODUIT_OPERATIONS,
  PRODUIT_PORTEFEUILLE,
  PRODUIT_TRANSACTIONS,
} from '../../../../../constants'
import TableBodyFrais from './Table/TableBodyFrais'
import ProductTable from './ProductTable'
import TableBodyTransactions from './Table/TableBodyTransactions'
import TableBodyOperations from './Table/TableBodyOperations'
import TableBodyPortefeuille from './Table/TableBodyPortefeuille'

const ProductToPrint = ({ elementToDisplay, categorie }) => {
  const { historiquesFrais, detenteurs, fichiers, transactions, operations, portefeuille } =
    elementToDisplay || {}
  return (
    <>
      <InformationsCardPrint
        data={dataProduit(elementToDisplay, categorie)}
        title={`Informations générales : ${elementToDisplay.libelle}`}
      />
      <ProductTable produit={elementToDisplay} categorie={categorie} print={true} />
      {historiquesFrais?.length > 0 ? (
        <TableToPrint
          data={historiquesFrais}
          tableType={PRODUIT_FRAIS}
          title={categorie !== 'AV' && categorie !== 'retraite' ? 'Frais' : 'Performance des fonds'}
          children={<TableBodyFrais />}
        />
      ) : null}
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={DOC_FINANCIER_VM}
      />
      {categorie !== 'AV' && categorie !== 'retraite' ? (
        <TableToPrint
          data={transactions}
          tableType={PRODUIT_TRANSACTIONS}
          title={`Transactions (${transactions.length})`}
          children={<TableBodyTransactions />}
        />
      ) : null}
      <TableToPrint
        data={operations}
        tableType={PRODUIT_OPERATIONS}
        title={`Operations (${operations.length})`}
        children={<TableBodyOperations />}
      />
      <InformationsCardPrint
        data={dataProduitFrais(portefeuille, categorie)}
        title={`Information portefeuille : ${elementToDisplay.libelle}`}
      />
      <TableToPrint
        data={portefeuille.supportsP}
        tableType={PRODUIT_PORTEFEUILLE}
        title={`Portefeuille (${portefeuille.supportsP.length})`}
        children={<TableBodyPortefeuille />}
      />
    </>
  )
}

export default ProductToPrint
