import React from 'react'
import Modale from '../Modale'

const NotOk = ({ setNotOK, closeModale }) => {
  return (
    <Modale
      close={() => closeModale()}
      title="Erreur !"
      line1={'Un problème est survenu, veuillez réessayer ou abandonner'}
      line2={'Si le problème persiste, veuillez contacter l\'administrateur du site'}
    >
      <div className="informations">
        <div className="informations__buttons">
          <div className="button button--blue-red width--12" onClick={() => setNotOK(false)}>
            Modifier
          </div>
          <div className="button button--blue-red width--12" onClick={() => closeModale()}>
            Abandonner
          </div>
        </div>
      </div>
    </Modale>
  )
}

export default NotOk
