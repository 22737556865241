import React from 'react'
import { FE } from '../../../../../../constants'
import classNames from 'classnames'
import { FaCheckSquare } from 'react-icons/fa'
import { AiOutlineCloseSquare } from 'react-icons/ai'
import { dateFormateComplete } from '../../../../../../services/helpers/dates'

const BodyOperationsPrint = ({ element, index }) => {
  return (
    <>
      {/* neutralise */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Opération neutralisée</p>
        {!element.neutralise ? (
          <p className="print__table__bloc__line--value">
            <AiOutlineCloseSquare />
          </p>
        ) : (
          <p className="print__table__bloc__line--icon">
            <FaCheckSquare />
          </p>
        )}
      </div>
      {/* nom */}
      <div
        className={classNames('print__table__bloc__line', {
          'print__table__bloc__line--grey': element.neutralise,
        })}
      >
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">{!element.nom ? '-' : element.nom}</p>
      </div>
      {/* isin */}
      <div
        className={classNames('print__table__bloc__line', {
          'print__table__bloc__line--grey': element.neutralise,
        })}
      >
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">ISIN</p>
        <p className="print__table__bloc__line--value">{!element.isin ? '-' : element.isin}</p>
      </div>
      {/* nature */}
      <div
        className={classNames('print__table__bloc__line', {
          'print__table__bloc__line--grey': element.neutralise,
        })}
      >
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Nature</p>
        <p className="print__table__bloc__line--value">
          {!element.montant
            ? '-'
            : `${
                element.majRegularisation
                  ? 'Régularisation'
                  : element.support.dici && element.support.dici.c_nom !== FE
                  ? element.achat
                    ? 'Achat'
                    : element.cession
                    ? 'Cession totale'
                    : 'Vente'
                  : element.achat
                  ? 'Versement'
                  : element.cession
                  ? 'Retrait total'
                  : 'Retrait'
              }`}
        </p>
      </div>
      {/* montant */}
      <div
        className={classNames('print__table__bloc__line', {
          'print__table__bloc__line--grey': element.neutralise,
        })}
      >
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Montant</p>
        <p className="print__table__bloc__line--value">
          {!element.montant
            ? '-'
            : `${element.montant.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
      {(element.support.dici && element.support.dici.c_nom !== FE) ||
      (!element.support.dici && element.support.dateDernierCours) ? (
        <>
          {/* support.nombre */}
          <div
            className={classNames('print__table__bloc__line', {
              'print__table__bloc__line--grey': element.neutralise,
            })}
          >
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Quantité</p>
            <p className="print__table__bloc__line--value">
              {!element.nombre
                ? (Math.abs(element.montant) / element.valeurAchat).toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })
                : element.nombre.toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })}
            </p>
          </div>
          {/* valeurAchat */}
          <div
            className={classNames('print__table__bloc__line', {
              'print__table__bloc__line--grey': element.neutralise,
            })}
          >
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Valeur d'achat</p>
            <p className="print__table__bloc__line--value">
              {!element.valeurAchat
                ? '-'
                : `${Math.abs(element.valeurAchat).toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} €`}
            </p>
          </div>
          {/* support.dateAchat */}
          <div
            className={classNames('print__table__bloc__line', {
              'print__table__bloc__line--grey': element.neutralise,
            })}
          >
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Date d'achat</p>
            <p className="print__table__bloc__line--value">
              {!element.support.dateAchat ? '-' : dateFormateComplete(element.support.dateAchat)}
            </p>
          </div>
        </>
      ) : (
        <div
          className={classNames('print__table__bloc__line', {
            'print__table__bloc__line--grey': element.neutralise,
          })}
        >
          <div className="print__table__bloc__line--number"></div>
          <p className="print__table__bloc__line--title">Fond Euro</p>
          <p className="print__table__bloc__line--icon">
            <FaCheckSquare />
          </p>
        </div>
      )}
    </>
  )
}

export default BodyOperationsPrint
