// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
import { getUserMailError, getUserMailLoad, getUserMailSuccess } from '../actions/actionGetUserMail'
// actions imports

// REDUCER // ______________________________________________________________

// initial state
const initialStateUserMail = {
  isLoading: false,
  mandataire: null,
  error: '',
}
// reducer
export const getUserMailReducer = createReducer(initialStateUserMail, (builder) => {
  return builder
    .addCase(getUserMailLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getUserMailSuccess, (draft, action) => {
      draft.isLoading = false
      draft.mandataire = action.payload
      draft.error = ''
      return
    })
    .addCase(getUserMailError, (draft, action) => {
      draft.isLoading = false
      draft.mandataire = null
      draft.error = action.payload
      return
    })
})