import React, { useEffect, useMemo } from 'react'
import Card from '../Components/Card'
import { useDispatch, useSelector } from 'react-redux'
import { getPersonnes } from '../../../services/API/personnes'
import { getSignaturesPending, getSignaturesToClassify } from '../../../services/helpers/files'
import { getMessages } from '../../../services/API/connexion'
import { useNavigate } from 'react-router-dom'
import { FaArrowCircleRight } from 'react-icons/fa'

const RappelsCard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // signatures en instance
  const signaturesPending = useSelector((state) => state.getSignaturesPending.signaturesPending)
  // signatures à classer
  const signaturesToClassify = useSelector(
    (state) => state.getSignaturesToClassify.signaturesToClassify
  )
  // messages
  const messages = useSelector((state) => state.getMessages.messages)
  // recuperation
  useEffect(() => {
    !persons && dispatch(getPersonnes())
  }, [persons])
  useEffect(() => {
    !signaturesPending && dispatch(getSignaturesPending())
  }, [signaturesPending])
  useEffect(() => {
    !signaturesToClassify && dispatch(getSignaturesToClassify())
  }, [signaturesToClassify])
  useEffect(() => {
    !messages && dispatch(getMessages())
  }, [messages])

  const rappelsLength = useMemo(
    () =>
      (signaturesPending && signaturesPending.length) +
      (signaturesToClassify && signaturesToClassify.length) +
      (messages && messages.length),
    [signaturesPending, signaturesToClassify, messages]
  )

  return (
    <Card title={`Rappels (${rappelsLength > 0 ? rappelsLength : ''})`} h2={true} open={true} >
      {rappelsLength > 0 ? (
        <div className="informations">
          {signaturesPending && signaturesPending.length > 0 ? (
            <div onClick={() => navigate('/user/signature')} className="rappel">
              <p className="rappel__txt">
                Vous avez{' '}
                <span className="rappel__txt__span">{signaturesPending.length}</span>{' '}
                {`${signaturesPending.length > 1 ? 'documents' : 'document'} à récupérer`}
              </p>
              <div className="rappel__icon">
                <FaArrowCircleRight />
              </div>
            </div>
          ) : null}
          {signaturesToClassify && signaturesToClassify.length > 0 ? (
            <div onClick={() => navigate('/user/signature')} className="rappel">
              <p className="rappel__txt">
                Vous avez{' '}
                <span className="rappel__txt__span">{signaturesToClassify.length}</span>{' '}
                {`${signaturesToClassify.length > 1 ? 'documents' : 'document'} à classer`}
              </p>
              <div className="rappel__icon">
                <FaArrowCircleRight />
              </div>
            </div>
          ) : null}
          {messages && messages.length > 0 ? (
            <div onClick={() => navigate('/user/comptes')} className="rappel">
              <p className="rappel__txt">
                Vous avez <span className="rappel__txt__span">{messages.length}</span>{' '}
                {`${messages.length > 1 ? 'messages' : 'message'} à consulter`}
              </p>
              <div className="rappel__icon">
                <FaArrowCircleRight />
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <p>Aucun rappel à afficher</p>
      )}
    </Card>
  )
}

export default RappelsCard
