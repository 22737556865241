import React from 'react'
import { ImArrowRight } from 'react-icons/im'
import { Link } from 'react-router-dom'

const PapperLink = ({ person }) => {
  const { siren, denominationSociale } = person || {}

  return (
    <div className="siren__linkContainer">
      <Link
        to={`//pappers.fr/entreprise/${siren}`}
        target="_blank"
        rel="noopener noreferrer"
        className="siren__link"
      >
        <div>
          Accéder aux informations de {denominationSociale ? denominationSociale : 'votre société'}
          sur Pappers.fr
        </div>
        <ImArrowRight />
      </Link>
    </div>
  )
}

export default PapperLink
