import React from 'react'
import Card from '../../../../../Components/Card'
import Input from '../../../../../Components/Input'
import SelectComponent from '../../../../../Components/SelectComponent'
import {
  optionsCategorie,
  optionsSousCategorieCollective,
  optionsSousCategorieIndividuelle,
} from '../../../../../../../data/options/optionsSocial'

const GeneralInformationCard = ({ handleChange, information, toggleSubCategory }) => {
  const { categorie, sousCategorie, assureur, nomContrat, numero, libelle } = information || {}

  return (
    <Card title="Informations essentielles" h3={true} open={true}>
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* categorie */}
          <SelectComponent
            forAndId="categorie"
            errorLabel={categorie === ''}
            name="Catégorie :"
            isClearable={true}
            options={optionsCategorie}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsCategorie.find((c) => c.value === categorie)}
            copy={true}
            required={true}
          />
          {/* sousCategorie */}
          {toggleSubCategory ? (
            <SelectComponent
              forAndId="sousCategorie"
              errorLabel={sousCategorie === ''}
              name="Sous-catégorie :"
              isClearable={true}
              options={
                categorie === 'Prévoyance Individuelle'
                  ? optionsSousCategorieIndividuelle
                  : optionsSousCategorieCollective
              }
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={
                categorie === 'Prévoyance Individuelle'
                  ? optionsSousCategorieIndividuelle.find((c) => c.value === sousCategorie)
                  : optionsSousCategorieCollective.find((c) => c.value === sousCategorie)
              }
              copy={true}
              required={true}
            />
          ) : null}
          {/* assureur */}
          <Input
            forAndId="assureur"
            type="text"
            name="Assureur :"
            value={assureur}
            onChange={(e) => handleChange(e)}
            copy={true}
            required={true}
          />
        </div>
        <div className="informations informations--borderFull">
          {/* nomContrat */}
          <Input
            forAndId="nomContrat"
            errorLabel={nomContrat === ''}
            type="text"
            name="Contrat :"
            value={nomContrat}
            onChange={(e) => handleChange(e)}
            copy={true}
            required={true}
          />
          {/* numero */}
          <Input
            forAndId="numero"
            errorLabel={numero === ''}
            type="text"
            name="Numéro :"
            value={numero}
            onChange={(e) => handleChange(e)}
            copy={true}
            required={true}
          />
          {/* libelle */}
          <Input
            forAndId="libelle"
            errorLabel={libelle === ''}
            type="text"
            name="Libellé :"
            value={libelle}
            onChange={(e) => handleChange(e)}
            copy={true}
            required={true}
          />
        </div>
      </div>
    </Card>
  )
}

export default GeneralInformationCard
