import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import Modale from '../../../../Components/Modale'
import FormOperationTitle from './FormOperationTitle'
import FormOperationValidation from './FormOperationValidation'
import FormOperationRegularizeInputs from './FormOperationRegularizeInputs'

const FormOperationRegularize = ({
  setDataChange,
  setToggleRegularize,
  initialNewOp,
  newOperation,
  setNewOperation,
  handleSubmit,
  supportToChange,
  categorie,
  oldMontant,
  fondEuro,
}) => {
  const dispatch = useDispatch()
  const { nombre, dateOperation, montant, support, valeurAchat } = newOperation || {}
  // dataOperationChange
  const [dataNewOperationChange, setDataNewOperationChange] = useState(false)
  // dataNewOperationChange
  useEffect(() => {
    // achat
    if (fondEuro) {
      dateOperation && (montant || montant === 0)
        ? setDataNewOperationChange(true)
        : setDataNewOperationChange(false)
    } else {
      dateOperation && (nombre || nombre === 0)
        ? setDataNewOperationChange(true)
        : setDataNewOperationChange(false)
    }
  }, [nombre, dateOperation, montant])
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    switch (name) {
      case 'dateOperation':
        setDataChange(true)
        setNewOperation({
          ...newOperation,
          dateOperation: date,
          dateVersement: fondEuro ? date : '',
        })
        break
      case 'dateVersement':
        setDataChange(true)
        setNewOperation({
          ...newOperation,
          dateOperation: date,
          dateVersement: date,
        })
        break
      default:
        return
    }
  }
  // handlechange
  const handlechange = (e) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      const newValue = value.replace(/,/g, '.')
      console.log(nombre)
      switch (name) {
        case 'nombre':
          setNewOperation({
            ...newOperation,
            [name]: newValue,
            montant: Math.abs(newOperation.montant) * -1,
            support: { ...support, [name]: newValue },
          })
          break
        case 'valeurAchat':
          setNewOperation({
            ...newOperation,
            nombre: 1,
            [name]: newValue,
            montant: parseFloat(newValue) * -1,
            support: { ...support, nombre: 1, [name]: newValue },
          })
          break
        default:
          return
      }
    }
  }
  // handleClose
  const handleClose = () => {
    setDataChange(false)
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setNewOperation(initialNewOp)
    // cours !== undefined && setCours(null)
    setToggleRegularize(false)
  }

  // handlePreSubimt
  const handlePreSubimt = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit()
  }

  return (
    <Modale
      close={() => handleClose()}
      title={'Régularisation'}
    >
      <div className="modalContent">
        {/* title */}
        <FormOperationTitle
          newOperation={newOperation}
          categorie={categorie}
          dispo={montant}
          quantity={supportToChange.quantite}
          fondEuro={fondEuro}
          achat={true}
          valeurAchat={valeurAchat}
          oldMontant={oldMontant}
        />
        <div className="informations">
          {/* inputs */}
          <FormOperationRegularizeInputs
            handlechange={handlechange}
            newOperation={newOperation}
            fondEuro={fondEuro}
            handleChangeDate={handleChangeDate}
          />
        </div>
        <div className="informations">
          {/* validation or not */}
          <FormOperationValidation
            matchisOK={true}
            handlePreSubimt={handlePreSubimt}
            handleClose={handleClose}
            dataNewOperationChange={dataNewOperationChange}
            newOperation={newOperation}
          />
        </div>
      </div>
    </Modale>
  )
}

export default FormOperationRegularize
