// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get-catalogue-commun
 * @reduxAction
 */

export const getCatalogueCommunLoad = createAction('get-catalogue-commun-load')

export const getCatalogueCommunSuccess = createAction(
  'get-catalogue-commun-success',
  (catalogue) => {
    return {
      payload: catalogue,
    }
  }
)

export const getCatalogueCommunError = createAction('get-catalogue-commun-error', (error) => {
  return {
    payload: error,
  }
})
