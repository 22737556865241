import React from 'react'
import Card from '../../components/Components/Card'

const AdminAdvisorManagement = () => {
  return (
    <section className="section">
      <h1 className="h1">Gestion des conseillers</h1>
      <p className="hr__title">Créer</p>
      <div className="cards cards--fullWidth">
        <div className="informations__buttons">
          <div
            className="button button--blue-red width--12"
            onClick={() => console.log('ajouter un conseiller')}
          >
            Ajouter
          </div>
        </div>
      </div>
      <p className="hr__title">Gérer</p>

      <div className="cards cards--fullWidth">
        <Card title="Liste des conseillers" open={true}>
          <ul>
            <li>conseiller 1</li>
            <li>conseiller 2</li>
            <li>conseiller 3</li>
          </ul>
        </Card>
      </div>
    </section>
  )
}

export default AdminAdvisorManagement
