import { useEffect, useState } from 'react'

const useTotauxDetenteurs = (detenteurs) => {
  // totaux
  const [total, setTotal] = useState({
    totalTauxPP: 0,
    totalTauxUS: 0,
    totalTauxNP: 0,
    totalDetenu: 0,
  })
  const { totalTauxPP, totalTauxUS, totalTauxNP, totalDetenu } = total || {}
  
  useEffect(() => {
    if (detenteurs.length) {
      const arrayTotalTauxPP = detenteurs.map((el) => parseFloat(el.tauxPleinePropriete))
      const resultTotalTauxPP = arrayTotalTauxPP.reduce((acc, curr) => acc + curr)
      const arrayTotalTauxUS = detenteurs.map((el) => parseFloat(el.tauxUsufruit))
      const resultTotalTauxUS = arrayTotalTauxUS.reduce((acc, curr) => acc + curr)
      const arrayTotalTauxNP = detenteurs.map((el) => parseFloat(el.tauxNuePropriete))
      const resultTotalTauxNP = arrayTotalTauxNP.reduce((acc, curr) => acc + curr)
      const arrayTotalDetenuCalcule = detenteurs.map((el) => parseFloat(el.valeurDetenue))
      const resultTotalDetenuCalcule = arrayTotalDetenuCalcule.reduce((acc, curr) => acc + curr)

      setTotal({
        ...total,
        totalTauxPP: resultTotalTauxPP,
        totalTauxUS: resultTotalTauxUS,
        totalTauxNP: resultTotalTauxNP,
        totalDetenu: resultTotalDetenuCalcule,
      })
    }
  }, [])
  
  return { totalTauxPP, totalTauxUS, totalTauxNP, totalDetenu }
}

export default useTotauxDetenteurs
