import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import { getSocial } from '../../../../../../services/API/social'
import classNames from 'classnames'
import usePersonData from '../../../../../layout/usePersonData'
import useCustomPrint from '../../../../../layout/useCustomPrint'
import Loader from '../../../../../layout/Loader'
import PrintComponent from '../../../../Components/PrintComponent'
import ButtonsRow from '../../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../../Patrimoine/PatrimoineChartCard'
import PrevoyancePieChart from './PrevoyancePieChart'
import PrevoyanceCard from './PrevoyanceCard'
import PrevoyanceToPrint from './Print/PrevoyanceToPrint'
import LittleLoader from '../../../../../layout/LittleLoader'

const PrevoyanceHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const social = useSelector((state) => state.getSocial.social)
  const { produitsPrevoyance } = social || {}
  // recharge
  useEffect(() => {
    if (!social) {
      dispatch(getSocial(person?.id, isPP))
    }
  }, [social, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () => produitsPrevoyance.filter((el) => el.dateFin && new Date(el.dateFin) < new Date()),
    [produitsPrevoyance]
  )
  const dataToDisplayNotSold = useMemo(
    () => produitsPrevoyance.filter((el) => !el.dateFin || new Date(el.dateFin) >= new Date()),
    [produitsPrevoyance]
  )

  const totalPrevoyance = useMemo(
    () => produitsPrevoyance.map((el) => el.capital).reduce((sum, el) => sum + el, 0),
    [produitsPrevoyance]
  )
  // redirections
  const formLink = '/user/patrimoine/actifs/social/prevoyance/formulaire'
  // maj bien
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!social) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <PrevoyanceToPrint
              elementToDisplay={produitsPrevoyance}
              totalPrevoyance={totalPrevoyance}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            <PatrimoineValueCard totalAmount={totalPrevoyance} />
            {totalPrevoyance > 0 ? (
              <PatrimoineChartCard
                children={<PrevoyancePieChart produitsPrevoyance={produitsPrevoyance} />}
              />
            ) : null}
          </div>
          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <PrevoyanceCard
              data={dataToDisplayNotSold}
              handleAdd={() => navigate(formLink)}
              title={`En cours (${dataToDisplayNotSold.length})`}
            />
            {dataToDisplaySold.length > 0 ? (
              <PrevoyanceCard
                data={dataToDisplaySold}
                handleAdd={() => navigate(formLink)}
                title={`Historique (${dataToDisplaySold.length})`}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default PrevoyanceHome
