import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ValideCompte from '../../components/Signup/ValideCompte'
import HomePageComponent from './HomePageComponent'
import HomePageMain from './HomePageMain'
import { ACCESS_TOKEN } from '../../../constants'
import { getUser } from '../../../services/API/connexion'
import Loader from '../../layout/Loader'

const Homepage = () => {
  const dispatch = useDispatch()
  const { isLoading, user } = useSelector((state) => state.getUser)
  const identifiant = sessionStorage.getItem('identifiant')
  const token = sessionStorage.getItem(ACCESS_TOKEN)

  useEffect(() => {
    if (!user && !isLoading && identifiant && token) {
      dispatch(getUser(identifiant))
    }
  }, [user, isLoading, dispatch, identifiant, token])
  const getRole = (role) => {
    if (role.name === 'ROLE_USER') {
      return <Navigate to="/user/accueil" />
    } else return <Navigate to="/admin/dashboard" />
  }
  if (user) {
    return user.actif ? getRole(user.roles[0]) : <ValideCompte />
  }
  if (isLoading) return <Loader />

  return <HomePageComponent children={<HomePageMain />} />
}

export default Homepage
