import React from 'react'
import InputsFamily from './InputsFamily'
// import { useSelector } from 'react-redux'

const Family = ({
  person,
  family,
  setFamily,
  fichiers,
  setDataChange,
  document,
  setUpdateConjoint,
}) => {
  const handleChange = (e, selectName) => {
    setDataChange(true)
    setUpdateConjoint(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      setFamily({ ...family, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      setFamily({ ...family, [name]: value })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setFamily({ ...family, [name]: date })
  }

  return (
    <InputsFamily
      person={person}
      handleChange={handleChange}
      handleChangeDate={handleChangeDate}
      family={family}
      setFamily={setFamily}
      fichiers={fichiers}
      setDataChange={setDataChange}
      document={document}
      setUpdateConjoint={setUpdateConjoint}
    />
  )
}

export default Family
