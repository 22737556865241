import React from 'react';
import { Link } from 'react-router-dom';

const HomePageMain = () => {
    return (
        <main className="homepage__title">
          <h1 className="homepage__title__h1">Mon Espace Patrimoine</h1>
          <h2 className="homepage__title__h2">Plateforme de gestion patrimoniale</h2>
          <section className="homepage__title__buttons">
            <Link to="connexion" className="button button--signIn width--12">
              Se connecter
            </Link>
            <Link to="inscription" className="button button--reverse button--signUp width--12">
              S'inscrire
            </Link>
          </section>
        </main>
      );
};

export default HomePageMain;