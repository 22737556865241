import React from 'react'
import Card from '../../../Components/Card'
import BlocTable from '../../../table/BlocTable'
import { TITLES_DOC_UPDATE } from '../../../../../constants'
import TableBodyDoc from '../../../Files/TableBodyDoc'

const DocumentsCard = ({
  fichiersToDisplay,
  setFichiersToDisplay,
  setToggleAdd,
  docType,
  h3,
  h2,
  h4,
  h5,
  h6,
  setDataChange,
}) => {
  return (
    <Card title={`Documents (${fichiersToDisplay.length})`} h2={h2} h3={h3} h4={h4} h5={h5} h6={h6}>
      <div className="informations">
        <BlocTable
          titles={TITLES_DOC_UPDATE}
          data={fichiersToDisplay}
          setData={setFichiersToDisplay}
          tableType={docType}
          children={<TableBodyDoc />}
          doc={true}
          setDataChange={setDataChange}
          title={`Documents (${fichiersToDisplay.length})`}
          handleAdd={() => setToggleAdd(true)}
        />
      </div>
    </Card>
  )
}

export default DocumentsCard
