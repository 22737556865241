import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { TbArrowBackUp } from 'react-icons/tb'
import { FaUsers } from 'react-icons/fa'
import { MdLocalPrintshop, MdModeEdit, MdDeleteForever, MdAddCircle } from 'react-icons/md'
import { IoDuplicateSharp } from 'react-icons/io5'
import { AiFillHome } from 'react-icons/ai'
import { getBienSuccess } from '../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../redux/actions/actionGetBienType'
import DeleteBien from './DeleteBien'
import ButtonUserBack from '../../Components/ButtonUserBack'
import useFormIsActive from '../../../layout/useFormIsActive'
import SaveForm from '../../../layout/SaveForm'
import { PRODUITS_AV, PRODUITS_PER, PRODUITS_RETRAITE, PRODUITS_VM } from '../../../../constants'
import usePersonData from '../../../layout/usePersonData'

const ButtonsRow = ({
  handlePrint,
  handleAdd,
  formLink,
  backLink,
  newBien,
  tableType,
}) => {
  const navigate = useNavigate()
  const { person, personType } = usePersonData()
  // formIsActive
  const formIsActive = useFormIsActive()
  // modales
  const [toggleSaveForm, setToggleSaveForm] = useState(false)
  const [toggleDeleteBien, setToggleDeleteBien] = useState(false)
  // redux
  const dispatch = useDispatch()
  const persons = useSelector((state) => state.getPersons.persons)
  const bien = useSelector((state) => state.getBien.bien)
  const bienType = useSelector((state) => state.getBienType.type)
  // texte
  const [textLeft, setTextLeft] = useState('')
  const [textRight, setTextRight] = useState('')
  // handleClick
  const handleClick = () => {
    formIsActive && setToggleSaveForm(true)
  }
  // handleAdd
  const handleAddBien = () => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
    navigate(formLink)
  }
  // handleAddImmo
  const handleEdit = () => {
    navigate(formLink)
  }
  // handleDelete
  const handleDelete = () => {
    setToggleDeleteBien(true)
  }
  // handleCopy
  const handleCopy = () => {
    const bienBis = newBien
    dispatch(getBienSuccess(bienBis))
    dispatch(getbienType(bienType))
    navigate(formLink)
  }
  // handleBack
  const handleBack = () => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
    navigate(backLink ? backLink : -1)
  }
  // handleBack
  const handleAccounts = () => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
    navigate('/user/comptes')
  }

  return persons ? (
    <>
      <div className="informations__buttonsTop" onClick={() => handleClick()}>
        <div className="informations__buttons">
          {handlePrint ? (
            formIsActive ? (
              <div>
                <MdLocalPrintshop />
              </div>
            ) : (
              <button
                onClick={handlePrint}
                className="button"
                onMouseEnter={() => setTextLeft('Imprimer')}
                onMouseLeave={() => setTextLeft('')}
                value={'Imprimer'}
              >
                <MdLocalPrintshop />
              </button>
            )
          ) : null}
          {personType !== 'relationPP' && bien ? (
            <>
              {tableType !== PRODUITS_AV &&
              tableType !== PRODUITS_RETRAITE &&
              tableType !== PRODUITS_VM &&
              tableType !== PRODUITS_PER ? (
                formIsActive ? (
                  <div>
                    <MdModeEdit />
                  </div>
                ) : (
                  <button
                    className="button"
                    onMouseEnter={() => setTextLeft('Modifier')}
                    onMouseLeave={() => setTextLeft('')}
                    onClick={handleEdit}
                    value={'Modifier'}
                  >
                    <MdModeEdit />
                  </button>
                )
              ) : null}
              {newBien ? (
                formIsActive ? (
                  <div>
                    <IoDuplicateSharp />
                  </div>
                ) : (
                  <button
                    className="button"
                    onMouseEnter={() => setTextLeft('Dupliquer')}
                    onMouseLeave={() => setTextLeft('')}
                    onClick={handleCopy}
                    value={'Dupliquer'}
                  >
                    <IoDuplicateSharp />
                  </button>
                )
              ) : null}
              {formIsActive ? (
                <div>
                  <MdDeleteForever />
                </div>
              ) : (
                <button
                  className="button"
                  onMouseEnter={() => setTextLeft('Supprimer')}
                  onMouseLeave={() => setTextLeft('')}
                  onClick={handleDelete}
                  value={'Supprimer'}
                >
                  <MdDeleteForever />
                </button>
              )}
            </>
          ) : null}
          {personType !== 'relationPP' ? (
            formLink ? (
              formIsActive ? (
                <div>
                  <MdAddCircle />
                </div>
              ) : (
                <button
                  className="button"
                  onMouseEnter={() => setTextLeft('Ajouter')}
                  onMouseLeave={() => setTextLeft('')}
                  onClick={handleAddBien}
                  value={'Ajouter'}
                >
                  <MdAddCircle />
                </button>
              )
            ) : handleAdd ? (
              formIsActive ? (
                <div>
                  <MdAddCircle />
                </div>
              ) : (
                <button
                  className="button"
                  onMouseEnter={() => setTextLeft('Ajouter')}
                  onMouseLeave={() => setTextLeft('')}
                  onClick={handleAdd}
                  value={'Ajouter'}
                >
                  <MdAddCircle />
                </button>
              )
            ) : null
          ) : null}
          {textLeft ? <p className="informations__buttons--p">{textLeft}</p> : null}
        </div>
        <div className="informations__buttons">
          {textRight ? <p className="informations__buttons--p">{textRight}</p> : null}
          {person !== persons.referentPP[0] ? (
            <ButtonUserBack setText={setTextRight} formIsActive={formIsActive} />
          ) : null}
          {formIsActive ? (
            <div>
              <FaUsers />
            </div>
          ) : (
            <button
              className="button"
              onMouseEnter={() => setTextRight('Mes comptes')}
              onMouseLeave={() => setTextRight('')}
              onClick={handleAccounts}
              value={'Mes comptes'}
            >
              <FaUsers />
            </button>
          )}
          {formIsActive ? (
            <div>
              <AiFillHome />
            </div>
          ) : (
            <button
              className="button"
              onMouseEnter={() => setTextRight('Accueil')}
              onMouseLeave={() => setTextRight('')}
              onClick={() => navigate('/user/accueil')}
              value={'Accueil'}
            >
              <AiFillHome />{' '}
            </button>
          )}
          {formIsActive ? (
            <div>
              <TbArrowBackUp />
            </div>
          ) : (
            <button
              className="button"
              onMouseEnter={() => setTextRight('Retour')}
              onMouseLeave={() => setTextRight('')}
              onClick={handleBack}
              value={'Retour'}
            >
              <TbArrowBackUp />
            </button>
          )}
        </div>
      </div>
      {toggleDeleteBien ? (
        <DeleteBien
          newData={bien}
          setModalDelete={setToggleDeleteBien}
          tableType={tableType}
        />
      ) : null}
      {toggleSaveForm && (
        <SaveForm toggleSaveForm={toggleSaveForm} setToggleSaveForm={setToggleSaveForm} />
      )}
    </>
  ) : null
}

export default ButtonsRow
