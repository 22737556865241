import React from 'react'
import { ACTIFS_IMMO } from '../../../../../../constants'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import TableToPrint from '../../../../table/TableToPrint'
import BiensImmoPieChart from '../BiensImmoPieChart'
import TableBodyBienImmo from '../Table/TableBodyBienImmo'

const ActifsImmoToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurImmobiliers } = elementToDisplay || {}

  return (
    <>
      <PatrimoineValueCard totalAmount={valeurImmobiliers} values={values} />
      <PatrimoineChartCardPrint
        children={<BiensImmoPieChart actifsImmobilier={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={ACTIFS_IMMO}
        title={`Immobilier en cours  (${dataNotSold.length})`}
        children={<TableBodyBienImmo />}
      />
      <TableToPrint
        data={dataSold}
        tableType={ACTIFS_IMMO}
        title={`Historique Immobilier (${dataSold.length})`}
        children={<TableBodyBienImmo />}
      />
    </>
  )
}

export default ActifsImmoToPrint
