import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { supprId } from '../../../../../services/helpers/helpers'
import { dataChargeGenerale } from '../../../../../data/informations/charges'
import { CHARGES_EMPRUNTS } from '../../../../../constants'
import useCustomPrint from '../../../../layout/useCustomPrint'
import Loader from '../../../../layout/Loader'
import PrintComponent from '../../../Components/PrintComponent'
import DetentionInfoCard from '../../Detention/DetentionInfoCard'
import DocInfoCard from '../../Patrimoine/DocInfoCard'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import ChgInfoPrint from '../Charges/Print/ChgInfoPrint'
import MainInformationsCard from '../../../Components/MainInformationsCard'

const ChgEmpruntDetteInfo = () => {
  const navigate = useNavigate()
 // bien
  const bien = useSelector((state) => state.getBien.bien)
  const newBien = bien
    ? {
        ...bien,
        id: 0,
        fichiers: [],
        nom: `${bien.nom} - copie`,
        detenteurs: bien.detenteurs.map((det) => supprId(det)),
      }
    : null
  // fichiers
  const [patrimoineFiles, setPatrimoineFiles] = useState([])
  // redirection
  const formRedirection = '/user/patrimoine/charges/emprunts-et-dettes/ajouter'
  const backRedirection = '/user/patrimoine/charges/emprunts-et-dettes'

  // actualisation bien
  useEffect(() => {
    bien && setPatrimoineFiles(bien.fichiers)
  }, [bien])

  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!bien) return <Navigate to={backRedirection} replace />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<ChgInfoPrint elementToDisplay={bien} />}
        />
        <ButtonsRow
          handlePrint={handlePrint}
          formLink={formRedirection}
          backLink={backRedirection}
          newBien={newBien}
          tableType={CHARGES_EMPRUNTS}
        />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            {/* Informations principales */}
            <MainInformationsCard
              title={'Informations principales'}
              h4={true}
              data={dataChargeGenerale}
              element={bien}
              redirection={() => navigate(formRedirection)}
            />
          </div>
          <div className="cards">
            {/* Detenteurs */}
            <DetentionInfoCard
              bienToDisplay={bien}
              redirection={() => navigate(formRedirection)}
              h4={true}
              showUS={false}
            />
            {/* documents */}
            <DocInfoCard
              patrimoineFiles={patrimoineFiles}
              redirection={() => navigate(formRedirection)}
              h4={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ChgEmpruntDetteInfo
