import React from 'react'
import { MdOutlineAccountBalance, MdHome } from 'react-icons/md'
import { FaUsers, FaAddressBook, FaFolderOpen, FaHandsHelping, FaSignature } from 'react-icons/fa'
import { BsFillPersonLinesFill, BsCurrencyExchange } from 'react-icons/bs'

export const allLinks = (type) => [
  {
    link: '/user/accueil',
    icon: <MdHome />,
    txt: 'Administration des accès',
    sub: false,
    user: true,
  },
  {
    link: '/user/comptes',
    icon: <FaUsers />,
    txt: 'Mes Comptes',
    sub: true,
    subLinks: [
      {
        link: '/user/comptes/ajouter-Personne-Physique',
        txt: 'Création PP',
      },
      {
        link: '/user/comptes/ajouter-Personne-Morale',
        txt: 'Création PM',
      },
    ],
    user: true,
  },
  {
    link: '/user/signature',
    icon: <FaSignature />,
    txt: 'Signature',
    sub: false,
    user: true,
  },
  {
    link: '/user/repertoire',
    icon: <FaAddressBook />,
    txt: 'Repertoire',
    sub: false,
    user: true,
  },
  type === 'referentPP'
    ? {
        link: '/user/informations',
        icon: <BsFillPersonLinesFill />,
        txt: 'Informations',
        sub: true,
        subLinks: [
          {
            link: '/user/informations/modifier-Personne-Physique',
            txt: 'Modifier',
          },
        ],
        user: false,
      }
    : {
        link: '/user/informations',
        icon: <BsFillPersonLinesFill />,
        txt: 'Informations',
        sub: false,
        user: false,
      },
  {
    link: '/user/documents',
    icon: <FaFolderOpen />,
    txt: 'Documents',
    sub: false,
    user: false,
  },
  {
    link: '/user/patrimoine',
    icon: <MdOutlineAccountBalance />,
    txt: 'Patrimoine',
    sub: true,
    subLinks: [
      {
        link: '/user/patrimoine/actifs',
        txt: 'Actif',
      },
      {
        link: '/user/patrimoine/passifs',
        txt: 'Passif',
      },
      {
        link: '/user/patrimoine/revenus',
        txt: 'Revenu',
      },
      {
        link: '/user/patrimoine/charges',
        txt: 'Charges',
      },
    ],
    user: false,
  },
  {
    link: '/user/investir',
    icon: <BsCurrencyExchange />,
    txt: 'Investir',
    sub: false,
    user: false,
  },
  {
    link: '/user/conseiller',
    icon: <FaHandsHelping />,
    txt: 'Conseiller financier',
    sub: false,
    user: false,
  },
]
