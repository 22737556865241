// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getPropertiesError, getPropertiesLoad, getPropertiesSuccess } from '../../actions/Patrimoine/actionGetPatrimoine'

// REDUCER // ______________________________________________________________

// initial state
const initialStateProperties = {
  isLoading: false,
  properties: null,
  error: '',
}
// reducer
export const getPropertiesReducer = createReducer(initialStateProperties, (builder) => {
  return builder
    .addCase(getPropertiesLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getPropertiesSuccess, (draft, action) => {
      draft.isLoading = false
      draft.properties = action.payload
      draft.error = ''
      return
    })
    .addCase(getPropertiesError, (draft, action) => {
      draft.isLoading = false
      draft.properties = null
      draft.error = action.payload
      return
    })
})