// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get file
 * @reduxAction
 */

export const getFileLoad = createAction('get-file-load')

export const getFileSuccess = createAction(
  'get-file-success',
  (file) => {
    return {
      payload: file,
    }
  }
)

export const getFileError = createAction(
  'get-file-error', (error) => {
  return {
    payload: error,
  }
})