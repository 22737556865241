import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { getCharges } from '../../../../../services/API/charges'
import { CHARGES_PENSIONS } from '../../../../../constants'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import ChargesCard from '../Charges/ChargesCard'
import ChgPensionsPieChart from './ChgPensionsPieChart'
import ModalUpdateTotalValueChgPensions from './ModalUpdateTotalValueChgPensions'
import ChargesPensionsToPrint from './ChargesPensionsToPrint'
import LittleLoader from '../../../../layout/LittleLoader'

const ChgPensionsHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const charges = useSelector((state) => state.getCharges.charges)
  const { pensions } = charges || {}
  const { lpensions, valeurPensions, pensionDivers } = pensions || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: pensionDivers?.montant },
      { label: 'Affecté', value: valeurPensions - pensionDivers?.montant },
    ],
    [pensionDivers, valeurPensions]
  )
  // recharge
  useEffect(() => {
    if (!charges) {
      dispatch(getCharges(person?.id, isPP))
    }
  }, [charges, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () => lpensions.filter((revenu) => revenu.dateFin && new Date(revenu.dateFin) < new Date()),
    [lpensions]
  )
  const dataToDisplayNotSold = useMemo(
    () => lpensions.filter((revenu) => new Date(revenu.dateFin) >= new Date() || !revenu.dateFin),
    [lpensions]
  )
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // redirections
  const formLink = '/user/patrimoine/charges/pensions/ajouter'
  // recuperation actifs
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!charges) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <ChargesPensionsToPrint
              elementToDisplay={pensions}
              values={values}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurPensions}
              update={true}
              values={values}
            />
            {valeurPensions > 0 && (
              <PatrimoineChartCard
                children={<ChgPensionsPieChart pensions={pensions} />}
                detenteurs={true}
              />
            )}
          </div>
          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <ChargesCard
              data={dataToDisplayNotSold}
              handleAdd={() => navigate(formLink)}
              title={`En cours (${dataToDisplayNotSold.length})`}
              tableType={CHARGES_PENSIONS}
            />
            {dataToDisplaySold.length > 0 && (
              <ChargesCard
                data={dataToDisplaySold}
                handleAdd={() => navigate(formLink)}
                title={`Historique (${dataToDisplaySold.length})`}
                tableType={CHARGES_PENSIONS}
              />
            )}
          </div>
          {toggleUpdateTotal && (
            <ModalUpdateTotalValueChgPensions
              setToggleUpdateTotal={setToggleUpdateTotal}
              person={person}
              charges={charges}
              pp={isPP}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ChgPensionsHome
