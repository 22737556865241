// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getbienType } from '../actions/actionGetBienType'

// REDUCER // ______________________________________________________________

// initial state
const initialStateBienType = {
  type: null,
}
// reducer
export const getBienTypeReducer = createReducer(initialStateBienType, (builder) => {
  return builder
    .addCase(getbienType, (draft, action) => {
      draft.type = action.payload
      return
    })
})
