import React from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

const Icon = ({visibility, setVisibility}) => {
  return (
    <div className="input__inputBloc__eye" tabIndex={-1} onClick={() => setVisibility(!visibility)}>
      {visibility ? <AiFillEyeInvisible /> : <AiFillEye />}
    </div>
  )
}

export default Icon
