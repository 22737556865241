// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
import { getParapheurError, getParapheurLoad, getParapheurSuccess } from '../actions/actionGetParapheur'
// actions imports

// REDUCER // ______________________________________________________________

// initial state
const initialStateParapheur = {
  isLoading: false,
  parapheur: null,
  error: '',
}
// reducer
export const getParapheurReducer = createReducer(initialStateParapheur, (builder) => {
  return builder
    .addCase(getParapheurLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getParapheurSuccess, (draft, action) => {
      draft.isLoading = false
      draft.parapheur = action.payload
      draft.error = ''
      return
    })
    .addCase(getParapheurError, (draft, action) => {
      draft.isLoading = false
      draft.parapheur = null
      draft.error = action.payload
      return
    })
})