import React from 'react'
import PatrimoineValueCard from '../../../../Patrimoine/PatrimoineValueCard'
import PFPieChart from '../PFPieChart'
import PatrimoineChartCardPrint from '../../../../Patrimoine/PatrimoineChartCardPrint'
import TableToPrint from '../../../../../table/TableToPrint'
import TableBodyPF from '../Table/TableBodyPF'
import { PRODUITS_FINANCIERS } from '../../../../../../../constants'

const PFToPrint = ({ elementToDisplay, totalPF, dataNotSold, dataSold }) => {
  return (
    <>
      <PatrimoineValueCard totalAmount={totalPF} />
      <PatrimoineChartCardPrint children={<PFPieChart comptesBancaires={elementToDisplay} />} />
      <TableToPrint
        data={dataNotSold}
        tableType={PRODUITS_FINANCIERS}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyPF />}
      />
      <TableToPrint
        data={dataSold}
        tableType={PRODUITS_FINANCIERS}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyPF />}
      />
    </>
  )
}

export default PFToPrint
