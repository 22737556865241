import React, { useMemo, useState } from 'react'
import BlocTable from '../../../../table/BlocTable'
import { FE, SUPPORTS_SELECT } from '../../../../../../constants'
import TableBodySupports from '../Table/TableBodySupports'

const ChoiceCard = ({ diciList, handleSelect, categorie }) => {
  const list = useMemo(
    () =>
      categorie === 'AV' || categorie === 'retraite'
        ? diciList
        : diciList.filter((el) => el.c_nom !== FE),
    [categorie, diciList]
  )
  // toggle
  const [toggleAdd, setToggleAdd] = useState(false)
  const titles = [
    'N°',
    'Sélectionner',
    'Fournisseur',
    'Libellé',
    'ISIN',
    'Cours',
    'DICI',
    'Note',
    'Sélectionner',
  ]
  
  return (
    <>
      {!toggleAdd ? (
        <div
          className="informations__dropdown informations__dropdown--full"
          onClick={() => setToggleAdd(!toggleAdd)}
        >
          <p>Choisir</p>
        </div>
      ) : (
        <div className="informations informations__dropdown--border">
          <p onClick={() => setToggleAdd(!toggleAdd)}>Choisir</p>
          <div className="informations">
            <BlocTable
              data={list ? list : []}
              titles={titles}
              tableType={SUPPORTS_SELECT}
              children={<TableBodySupports handleClick={handleSelect} />}
              title={'Supports'}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ChoiceCard
