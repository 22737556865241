import React from 'react'
import { DOC_PERSON_PATRIMOINE, TITLES_DOC_READ } from '../../../../constants'
import Card from '../../Components/Card'
import BlocTable from '../../table/BlocTable'
import TableBodyDoc from '../../Files/TableBodyDoc'
import ConditionnalUpdateButton from '../../Components/ConditionnalUpdateButton'

const DocInfoCard = ({ patrimoineFiles, redirection, h3, h4, h5, h6 }) => {
  return (
    <Card
      title={`Documents (${patrimoineFiles.length})`}
      h3={h3}
      h4={h4}
      h5={h5}
      h6={h6}
      open={true}
    >
      <div className="informations">
        {patrimoineFiles && patrimoineFiles.length > 0 ? (
          <BlocTable
            titles={TITLES_DOC_READ}
            data={patrimoineFiles}
            tableType={DOC_PERSON_PATRIMOINE}
            children={<TableBodyDoc />}
            read={true}
            doc={true}
            title={`Documents (${patrimoineFiles.length})`}
          />
        ) : (
          <p>Aucun document à afficher</p>
        )}
      </div>
      <ConditionnalUpdateButton redirection={redirection} />
    </Card>
  )
}

export default DocInfoCard
