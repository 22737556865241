import React from 'react'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import FinancierPieChart from './FinancierPieChart'

const FinancierToPrint = ({ elementToDisplay, values }) => {
  const {valeurTotaleFinancier} = elementToDisplay ||{}
  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurTotaleFinancier}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<FinancierPieChart financier={elementToDisplay} />}
        detenteurs={true}
      />
    </>
  )
}

export default FinancierToPrint
