import React from 'react'

const BodyAssurancesPrint = ({ element, index }) => {
  return (
    <>
      {/* libelle */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Libellé</p>
        <p className="print__table__bloc__line--value">
          {!element.libelle ? '-' : element.libelle}
        </p>
      </div>
      {/* montantTotalCalcule */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Portefeuille</p>
        <p className="print__table__bloc__line--value">
          {!element.montantTotalCalcule
            ? '-'
            : `${element.montantTotalCalcule.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
    </>
  )
}

export default BodyAssurancesPrint
