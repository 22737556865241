import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Title from '../../layout/Title'
import Search from '../Recherche/Search'
import ButtonsRow from '../Patrimoine/Patrimoine/ButtonsRow'
import Modale from '../Components/Modale'
import SelectComponent from '../Components/SelectComponent'
import PersonsCard from './PersonsCard'
import MessagesCard from './MessagesCard'

const AccountsHome = () => {
  const navigate = useNavigate()
  // formToDisplay
  const [toggleSelectForm, setToggleSelectForm] = useState(false)
  const [categorieForm, setCategorieForm] = useState(null)
  const optionsCategorieForm = [
    { label: 'Personne physique', value: 'Personne-Physique' },
    { label: 'Personne morale', value: 'Personne-Morale' },
  ]
  // handleChange
  const handleChange = (e) => {
    const value = e.value
    setCategorieForm(value)
    navigate(`/user/comptes/ajouter-${value}`)
    setToggleSelectForm(false)
  }
  // print

  return (
    <>
      <Title title="Comptes associés" />
      {/* buttons */}
      <div className="section__content">
        <ButtonsRow handleAdd={() => setToggleSelectForm(true)} />
        <div className="sousSection">
          {/* Messages */}
          <MessagesCard />
          {/* Recherche */}
          <Search />
          {/* Comptes */}
          <div className={'cards'}>
            <PersonsCard type={'referentPP'} />
            <PersonsCard type={'relationPP'} />
          </div>
          <div className="cards cards--fullWidth">
            <PersonsCard type={'dirigeantPM'} />
          </div>
        </div>
        {/* buttons */}
        {toggleSelectForm ? (
          <Modale close={() => setToggleSelectForm(false)} title={'Choisir un formulaire'}>
            <div className="informations">
              {/* categorieForm */}
              <SelectComponent
                forAndId="categorieForm"
                name="Formulaire :"
                isSearchable={false}
                options={optionsCategorieForm}
                placeholder="Sélectionner"
                onChange={(e) => handleChange(e)}
                value={optionsCategorieForm.find((c) => c.value === categorieForm)}
                copy={true}
              />

              <div className="informations__buttons">
                <div
                  className="button button--blue-red width--12"
                  onClick={() => setToggleSelectForm(false)}
                >
                  Fermer
                </div>
              </div>
            </div>
          </Modale>
        ) : null}
      </div>
    </>
  )
}

export default AccountsHome
