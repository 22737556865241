import React from 'react'

const BodyCataloguePrint = ({ element, index }) => {
  return (
    <>
      {/* libelle */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Libellé</p>
        <p className="print__table__bloc__line--value">
          {!element.libelle ? '-' : element.libelle}
        </p>
      </div>
      {/* etablissement */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Fournisseur</p>
        <p className="print__table__bloc__line--value">
          {!element.etablissement ? '-' : element.etablissement}
        </p>
      </div>
      {/* dicis */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Supports</p>
        <p className="print__table__bloc__line--value">
          {!element.dicis ? '-' : element.dicis.length}
        </p>
      </div>
    </>
  )
}

export default BodyCataloguePrint
