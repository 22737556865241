import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  dataProduitClos,
  dataProduitFinancier,
} from '../../../../../../data/informations/financier'
import { PRODUITS_FINANCIERS } from '../../../../../../constants'
import useCustomPrint from '../../../../../layout/useCustomPrint'
import Loader from '../../../../../layout/Loader'
import PrintComponent from '../../../../Components/PrintComponent'
import DocInfoCard from '../../../Patrimoine/DocInfoCard'
import ButtonsRow from '../../../Patrimoine/ButtonsRow'
import DetentionInfoCard from '../../../Detention/DetentionInfoCard'
import PFInfoPrint from './Print/PFInfoPrint'
import MainInformationsCard from '../../../../Components/MainInformationsCard'

const BienPF = () => {
  const navigate = useNavigate()
  // bien
  const { bien } = useSelector((state) => state.getBien)
  // fichiers
  const [patrimoineFiles, setPatrimoineFiles] = useState([])
  // redirection
  const formRedirection = '/user/patrimoine/actifs/financier/produits-financiers/ajouter'
  const backRedirection = `/user/patrimoine/actifs/financier/produits-financiers`
  // actualisation bien
  useEffect(() => {
    bien && setPatrimoineFiles(bien.fichiers)
  }, [bien])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!bien) return <Navigate to={backRedirection} replace />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<PFInfoPrint elementToDisplay={bien} />}
        />
        <ButtonsRow
          handlePrint={handlePrint}
          formLink={formRedirection}
          backLink={backRedirection}
          tableType={PRODUITS_FINANCIERS}
        />
        <div className="sousSection">
          <div className={bien.clos ? 'cards' : 'cards cards--fullWidth'}>
            {/* Informations principales */}
            <MainInformationsCard
              title={'Informations principales'}
              h5={true}
              data={dataProduitFinancier}
              element={bien}
              redirection={() => navigate(formRedirection)}
            />
            {/* cloture */}
            {bien.clos ? (
              <MainInformationsCard
                title={'Clôture'}
                h5={true}
                data={dataProduitClos}
                element={bien}
                redirection={() => navigate(formRedirection)}
              />
            ) : null}
          </div>
          <div className="cards">
            {/* Detenteurs */}
            <DetentionInfoCard
              bienToDisplay={bien}
              redirection={() => navigate(formRedirection)}
              showUS={true}
              h5={true}
            />
            {/* documents */}
            <DocInfoCard
              patrimoineFiles={patrimoineFiles}
              redirection={() => navigate(formRedirection)}
              h5={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default BienPF
