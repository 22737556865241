import React, { useEffect, useMemo, useState } from 'react'
import Modale from '../../Components/Modale'
import SelectComponent from '../../Components/SelectComponent'
import Input from '../../Components/Input'
import { optionsPersonOrContact } from '../../../../data/options/optionsPersonnes'
import { handleChange } from '../../../../services/helpers/helpers'
import DateInputComponent from '../../Components/DateInputComponent'
import { useSelector } from 'react-redux'

const ModaleAddDetenteur = ({
  setAddDetenteur,
  detenteurToEdit,
  personExist,
  setPersonExist,
  personnes,
  setPersonnes,
  listPersons,
  handleChangeNames,
  handleChangeDate,
  handleAddDetenteur,
  detenteur,
  setDetenteur,
  names,
  demembrement,
  setDemembrement,
  personType,
  setPersonType,
  showUS,
  newValeurTotale,
}) => {
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  const PP = useMemo(() => persons.referentPP.concat(persons.relationPP), [persons])
  const PM = useMemo(() => persons.dirigeantPM, [persons])
  // contacts
  const contacts = useSelector((state) => state.getContacts.contacts)

  const { nomDetenteur, mailDetenteur, dateNaissance, dateContitutionUsufruit, dureeDemembrement } =
    detenteur || {}
  const { nom, prenom } = names || {}
  const initialState = {
    id: 0,
    nomDetenteur: '',
    mailDetenteur: '',
    idPP: 0,
    idPM: 0,
    dateNaissance: '',
    dateContitutionUsufruit: '',
    dureeDemembrement: '',
    tauxPleinePropriete: 0,
    tauxUsufruit: 0,
    tauxUsufruitCalcule: 0,
    tauxNuePropriete: 0,
    valeurDetenue: newValeurTotale,
    valeurPP: 0,
    valeurUS: 0,
    valeurNP: 0,
    newDetenteur: true,
  }
  // form valide ou non
  const [change, setChange] = useState(false)
  useEffect(() => {
    if ((nomDetenteur) || (nom && prenom)) {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [nomDetenteur, mailDetenteur])
  // option liste personnes
  const [optionListe, setOptionListe] = useState([])
  useEffect(() => {
    switch (personnes) {
      case 'personnePhysique':
      case 'contact':
        setOptionListe(
          PP.map((personne) => ({ label: `${personne.nom} ${personne.prenom}`, value: personne }))
        )
        setDetenteur(initialState)
        break
      case 'personneMorale':
        setOptionListe(
          PM.map((personne) => ({
            label: `${personne.denominationSociale} ${personne.dirigeant}`,
            value: personne,
          }))
        )
        setDetenteur(initialState)
        break
      default:
        setOptionListe([])
        setDetenteur(initialState)
    }
    setDetenteur(initialState)
  }, [personnes, persons, contacts])
  // handleSelectPerson
  const handleSelectPerson = (e) => {
    if (e) {
      const element = e.value
      setDetenteur({
        ...detenteur,
        nomDetenteur:
          personnes === 'personnePhysique' || personnes === 'contact'
            ? `${element.nom} ${element.prenom}`
            : `${element.denominationSociale} ${element.dirigeant}`,
        mailDetenteur: PP || PM ? element.mail : element.email,
        dateNaissance: PP ? element.dateNaissance : '',
        idPP: PP ? element.id : 0,
        idPM: PM ? element.id : 0,
      })
    } else {
      setDetenteur(initialState)
    }
  }

  return (
    <Modale
      close={() => setAddDetenteur(false)}
      title="Ajouter un détenteur !"
    >
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* Personne existe ? */}
          {!detenteurToEdit && (
            <Input
              forAndId="personExist"
              name="Personne enregistrée : "
              value={personExist}
              check={true}
              setValue={setPersonExist}
            />
          )}
          {personExist && !detenteurToEdit && (
            <>
              {/* Personnes */}
              <SelectComponent
                forAndId="personnes"
                name="Sélectionner une liste :"
                isClearable={true}
                isSearchable={false}
                options={optionsPersonOrContact}
                placeholder="Sélectionner"
                onChange={(e) => (e ? setPersonnes(e.value) : setPersonnes(''))}
                value={optionsPersonOrContact.find((c) => c.value === personnes)}
              />
              {/* liste à afficher */}
              {personnes &&
                listPersons &&
                (listPersons.length === 0 ? (
                  <p>
                    {personnes === 'personnePhysique'
                      ? 'Auncune personne physique à afficher'
                      : personnes === 'personneMorale'
                      ? 'Auncune personne morale à afficher'
                      : 'Aucun contact à afficher'}
                  </p>
                ) : (
                  <SelectComponent
                    forAndId="detenteur"
                    name="Choisir une personne :"
                    isClearable={true}
                    isSearchable={true}
                    options={optionListe}
                    placeholder="Sélectionner"
                    onChange={(e) => handleSelectPerson(e)}
                    value={optionListe.find((c) => c.label === detenteur.nomDetenteur)}
                  />
                ))}
            </>
          )}
          {personExist && nomDetenteur ? (
            <Input
              forAndId="nomDetenteur"
              errorLabel={nomDetenteur === ''}
              type="text"
              name="Détenteur :"
              value={nomDetenteur}
              onChange={(e, selectName) => handleChange(e, selectName, detenteur, setDetenteur)}
              placeholder="Nom"
              copy={true}
              required={true}
              disabled={detenteurToEdit}
            />
          ):null}
          {!personExist ? (
            <>
              <Input
                forAndId="nom"
                errorLabel={nom === ''}
                type="text"
                name="Nom :"
                value={nom}
                onChange={(e) => handleChangeNames(e)}
                placeholder="Nom"
                copy={true}
                required={true}
                disabled={detenteurToEdit}
              />
              {/* prénom */}
              <Input
                errorLabel={prenom === ''}
                forAndId="prenom"
                type="text"
                name="Prénom :"
                required={true}
                value={prenom}
                onChange={(e) => handleChangeNames(e)}
                placeholder="Prénom"
                copy={true}
              />
              {/* mailDetenteur */}
              {nomDetenteur ? (
                <Input
                  forAndId="mailDetenteur"
                  errorLabel={mailDetenteur === ''}
                  type="mailDetenteur"
                  name="Mail :"
                  required={true}
                  value={mailDetenteur}
                  onChange={(e, selectName) => handleChange(e, selectName, detenteur, setDetenteur)}
                  placeholder="exemple@gmail.com"
                  copy={true}
                  disabled={detenteurToEdit}
                />
              ) : null}
            </>
          ):null}
        </div>
        <div className="informations">
          {nomDetenteur && (
            <>
              {/* demembrement */}
              {showUS && (
                <>
                  <Input
                    forAndId="demembrement"
                    name="Démembrement :"
                    value={demembrement}
                    check={true}
                    setValue={setDemembrement}
                  />
                  {demembrement && (
                    <>
                      {/* personType */}
                      {(personnes === 'contact' || personnes === '') && (
                        <div className="input">
                          <div className="input__radio">
                            <input
                              type="radio"
                              value="PP"
                              id="pp"
                              checked={personType === 'PP'}
                              onChange={(e) => setPersonType(e.target.value)}
                              className="input__radio__input option1"
                            />
                            <label htmlFor="pp">Personne physique</label>
                          </div>
                          <div className="input__radio">
                            <input
                              type="radio"
                              value="PM"
                              id="pm"
                              checked={personType === 'PM'}
                              onChange={(e) => setPersonType(e.target.value)}
                              className="input__radio__input option2"
                            />
                            <label htmlFor="pm">Personne morale</label>
                          </div>
                        </div>
                      )}
                      {/* dateNaissance */}
                      {personType === 'PP' && personnes !== 'personneMorale' && (
                        <DateInputComponent
                          name={'dateNaissance'}
                          label={'Date de naissance :'}
                          value={dateNaissance}
                          handleChange={handleChangeDate}
                        />
                      )}
                      {(personnes === 'personneMorale' || personType === 'PM') && (
                        <>
                          <DateInputComponent
                            name={'dateContitutionUsufruit'}
                            label={'Date de démembrement :'}
                            value={dateContitutionUsufruit}
                            handleChange={handleChangeDate}
                          />
                          <Input
                            forAndId="dureeDemembrement"
                            errorLabel={dureeDemembrement === ''}
                            type="number"
                            name="Durée du démembrement (années) :"
                            value={dureeDemembrement}
                            onChange={(e, selectName) =>
                              handleChange(e, selectName, detenteur, setDetenteur)
                            }
                            placeholder={21}
                            copy={true}
                            required={true}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {change ? (
            <div className="informations__buttons">
              <div
                className="button button--red-red  width--18"
                onClick={() => handleAddDetenteur()}
              >
                Enregistrer
              </div>
            </div>
          ) : (
            <div className="informations__buttons">
              <div className="button button--grey width--12">Enregistrer</div>
            </div>
          )}
        </div>
      </div>
    </Modale>
  )
}

export default ModaleAddDetenteur
