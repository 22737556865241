// IMPORTS // ______________________________________________________________

import React from 'react'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '../redux/store/store'
import MyDocuments from './pages/user/MyDocuments'
import Invest from './pages/user/Invest'
import MyAdvisor from './pages/user/MyAdvisor'
import Admin from './pages/admin/Admin'
import AdminDashboard from './pages/admin/AdminDashboard'
import AdminUserManagement from './pages/admin/AdminUserManagement'
import AdminFirmManagement from './pages/admin/AdminFirmManagement'
import AdminAdvisorManagement from './pages/admin/AdminAdvisorManagement'
import FormPatrimoineImmo from './components/Patrimoine/Actifs/Immobilier/Form/FormPatrimoineImmo'
import FormPatrimoinePro from './components/Patrimoine/Actifs/BiensPro/Form/FormPatrimoinePro'
import PatrimoineActif from './components/Patrimoine/Patrimoine/PatrimoineActif'
import PatrimoinePassif from './components/Patrimoine/Patrimoine/PatrimoinePassif'
import PatrimoineRevenu from './components/Patrimoine/Patrimoine/PatrimoineRevenu'
import PatrimoineCharge from './components/Patrimoine/Patrimoine/PatrimoineCharge'
import BienImmo from './components/Patrimoine/Actifs/Immobilier/BienImmo'
import BienPro from './components/Patrimoine/Actifs/BiensPro/BienPro'
import BienAutres from './components/Patrimoine/Actifs/Divers/BienAutres'
import FormPatrimoineAutre from './components/Patrimoine/Actifs/Divers/Form/FormPatrimoineAutre'
import RevenuProfessionnel from './components/Patrimoine/Revenus/Professionnel/RevenuProfessionnel'
import FormRevenuPro from './components/Patrimoine/Revenus/Professionnel/Form/FormRevenuPro'
import RevenuMobilier from './components/Patrimoine/Revenus/Mobilier/RevenuMobilier'
import FormRevenuMob from './components/Patrimoine/Revenus/Mobilier/Form/FormRevenuMob'
import PensionRente from './components/Patrimoine/Revenus/PensionRente/PensionRente'
import FormPensionRente from './components/Patrimoine/Revenus/PensionRente/Form/FormPensionRente'
import ChgGeneralesInfo from './components/Patrimoine/Charges/Generales/ChgGeneralesInfo'
import FormChgGenerales from './components/Patrimoine/Charges/Generales/Form/FormChgGenerales'
import PageNotFound from './pages/user/PageNotFound'
import RevenuAutreInfo from './components/Patrimoine/Revenus/Autres/RevenuAutreInfo'
import FormRevenuAutre from './components/Patrimoine/Revenus/Autres/Form/FormRevenuAutre'
import PassifEmpruntInfo from './components/Patrimoine/Passifs/Emprunts/PassifEmpruntInfo'
import FormPassifEmprunt from './components/Patrimoine/Passifs/Emprunts/Form/FormPassifEmprunt'
import PassifDetteInfo from './components/Patrimoine/Passifs/Dettes/PassifDetteInfo'
import FormPassifDette from './components/Patrimoine/Passifs/Dettes/Form/FormPassifDette'
import FormChgDeductibles from './components/Patrimoine/Charges/DeductiblesRI/Form/FormChgDeductibles'
import ChgDeductiblesInfo from './components/Patrimoine/Charges/DeductiblesRI/ChgDeductiblesInfo'
import ChgReductionInfo from './components/Patrimoine/Charges/ReductionRI/ChgReductionInfo'
import FormChgReduction from './components/Patrimoine/Charges/ReductionRI/Form/FormChgReduction'
import ChgImpotTaxeInfo from './components/Patrimoine/Charges/ImpotTaxe/ChgImpotTaxeInfo'
import FormChgImpotTaxe from './components/Patrimoine/Charges/ImpotTaxe/Form/FormChgImpotTaxe'
import ActifsFinancier from './components/Patrimoine/Actifs/Financier/Financier/ActifsFinancier'
import RevenuFoncierInfo from './components/Patrimoine/Revenus/Foncier/RevenuFoncierInfo'
import FormRevenuFoncier from './components/Patrimoine/Revenus/Foncier/Form/FormRevenuFoncier'
import FormChgPensions from './components/Patrimoine/Charges/Pensions/Form/FormChgPensions'
import ChgPensionInfo from './components/Patrimoine/Charges/Pensions/ChgPensionInfo'
import ChgEmpruntDetteInfo from './components/Patrimoine/Charges/EmpruntDette/ChgEmpruntDetteInfo'
import FormChgEmpruntDette from './components/Patrimoine/Charges/EmpruntDette/Form/FormChgEmpruntDette'
import ActifsSocial from './components/Patrimoine/Actifs/Social/Social/ActifsSocial'
import BienPF from './components/Patrimoine/Actifs/Financier/PF/BienPF'
import FormPF from './components/Patrimoine/Actifs/Financier/PF/Form/FormPF'
import FormCatalogue from './components/Patrimoine/Actifs/Catalogue/Form/FormCatalogue'
import CatalogueInfo from './components/Patrimoine/Actifs/Catalogue/CatalogueInfo'
import AddProductPage from './components/Patrimoine/Actifs/Financier/AV/AddProductPage'
import VMAddProductPage from './components/Patrimoine/Actifs/Financier/VM/VMAddProductPage'
import ProductHome from './components/Patrimoine/Actifs/Produit/ProductHome'
import ProductComponent from './components/Patrimoine/Actifs/Produit/ProductComponent'
import TransactionsComponent from './components/Patrimoine/Actifs/Produit/TransactionsComponent'
import OperationsComponent from './components/Patrimoine/Actifs/Produit/OperationsComponent'
import PortefeuilleComponent from './components/Patrimoine/Actifs/Produit/PortefeuilleComponent'
import PERAddProductPage from './components/Patrimoine/Actifs/Social/PER/PERAddProductPage'
import PrevoyanceInfo from './components/Patrimoine/Actifs/Social/Prevoyance/PrevoyanceInfo'
import FormPrevoyance from './components/Patrimoine/Actifs/Social/Prevoyance/Form/FormPrevoyance'
import RetraiteAddProductPage from './components/Patrimoine/Actifs/Social/Retraite/RetraiteAddProductPage'
import PassifsHome from './components/Patrimoine/Passifs/Passifs/PassifsHome'
import Signup from './components/Signup/Signup'
import ValideCompte from './components/Signup/ValideCompte'
import Signin from './components/Signin/Signin'
import Password from './components/Signin/Password'
import User from './pages/user/User'
import MyDashboard from './pages/user/MyDashboard'
import MyAccounts from './pages/user/MyAccounts'
import PP from './components/FormPP/PP'
import PM from './components/FormPM/PM'
import MySignatures from './pages/user/MySignatures'
import MyDirectory from './pages/user/MyDirectory'
import MyInformations from './pages/user/MyInformations'
import MyProperty from './pages/user/MyProperty'
import ActifsHome from './components/Patrimoine/Actifs/Actifs/ActifsHome'
import RevenusHome from './components/Patrimoine/Revenus/Revenus/RevenusHome'
import ChargesHome from './components/Patrimoine/Charges/Charges/ChargesHome'
import ImmoHome from './components/Patrimoine/Actifs/Immobilier/ImmoHome'
import ProHome from './components/Patrimoine/Actifs/BiensPro/ProHome'
import FinancierHome from './components/Patrimoine/Actifs/Financier/Financier/FinancierHome'
import CatalogueHome from './components/Patrimoine/Actifs/Catalogue/CatalogueHome'
import VMHome from './components/Patrimoine/Actifs/Financier/VM/VMHome'
import AVHome from './components/Patrimoine/Actifs/Financier/AV/AVHome'
import PFHome from './components/Patrimoine/Actifs/Financier/PF/PFHome'
import SocialHome from './components/Patrimoine/Actifs/Social/Social/SocialHome'
import PERHome from './components/Patrimoine/Actifs/Social/PER/PERHome'
import RetraiteHome from './components/Patrimoine/Actifs/Social/Retraite/RetraiteHome'
import PrevoyanceHome from './components/Patrimoine/Actifs/Social/Prevoyance/PrevoyanceHome'
import AutresHome from './components/Patrimoine/Actifs/Divers/AutresHome'
import PassifEmpruntHome from './components/Patrimoine/Passifs/Emprunts/PassifEmpruntHome'
import PassifDetteHome from './components/Patrimoine/Passifs/Dettes/PassifDetteHome'
import RevenuProHome from './components/Patrimoine/Revenus/Professionnel/RevenuProHome'
import RevenuMobHome from './components/Patrimoine/Revenus/Mobilier/RevenuMobHome'
import RevenuAutreHome from './components/Patrimoine/Revenus/Autres/RevenuAutreHome'
import RevenuFoncierHome from './components/Patrimoine/Revenus/Foncier/RevenuFoncierHome'
import PensionRenteHome from './components/Patrimoine/Revenus/PensionRente/PensionRenteHome'
import ChgGeneralesHome from './components/Patrimoine/Charges/Generales/ChgGeneralesHome'
import ChgPensionsHome from './components/Patrimoine/Charges/Pensions/ChgPensionsHome'
import ChgEmpruntDettesHome from './components/Patrimoine/Charges/EmpruntDette/ChgEmpruntDetteHome'
import ChgDeductiblesHome from './components/Patrimoine/Charges/DeductiblesRI/ChgDeductiblesHome'
import ChgReductionHome from './components/Patrimoine/Charges/ReductionRI/ChgReductionHome'
import ChgImpotTaxeHome from './components/Patrimoine/Charges/ImpotTaxe/ChgImpotTaxeHome'
import PatrimoineHome from './components/Patrimoine/Patrimoine/PatrimoineHome'
import AccountsHome from './components/Accounts/AccountsHome'
import InformationsHome from './components/Informations/InformationsHome'
import Homepage from './pages/HomePage/Homepage'

const App = () => {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="inscription" element={<Signup />} />
            <Route path="validmail/:id" element={<ValideCompte />} />
            <Route path="connexion" element={<Signin />} />
            <Route path="password/:id" element={<Password />} />
            {/* user */}
            <Route path="user" element={<User />}>
              <Route path="accueil" element={<MyDashboard />} />
              <Route path="comptes" element={<MyAccounts />}>
                <Route path="" element={<AccountsHome />} />
                <Route path="ajouter-Personne-Physique" element={<PP />} />
                <Route path="ajouter-Personne-Morale" element={<PM />} />
              </Route>
              <Route path="signature" element={<MySignatures />} />
              <Route path="repertoire" element={<MyDirectory />} />
              <Route path="informations" element={<MyInformations />}>
                <Route path="" element={<InformationsHome />} />
                <Route path="modifier-Personne-Physique" element={<PP />} />
                <Route path="modifier-Personne-Morale" element={<PM />} />
              </Route>
              {/* patrimoine */}
              <Route path="patrimoine" element={<MyProperty />}>
                {/* Home */}
                <Route path="" element={<PatrimoineHome />} />
                {/* actifs */}
                <Route path="actifs" element={<PatrimoineActif />}>
                  {/* Home */}
                  <Route path="" element={<ActifsHome />} />
                  {/* immobilier */}
                  <Route path="immobilier" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<ImmoHome />} />
                    <Route path="bien" element={<BienImmo />} />
                    <Route path="ajouterBien" element={<FormPatrimoineImmo />} />
                  </Route>
                  {/* professionnel */}
                  <Route path="professionnel" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<ProHome />} />
                    <Route path="bien" element={<BienPro />} />
                    <Route path="ajouterBien" element={<FormPatrimoinePro />} />
                  </Route>
                  {/* financier */}
                  <Route path="financier" element={<ActifsFinancier />}>
                    {/* Home */}
                    <Route path="" element={<FinancierHome />} />
                    <Route path="catalogue" element={<Outlet />}>
                      {/* Home */}
                      <Route path="" element={<CatalogueHome categorie={'financier'} />} />
                      <Route path="informations" element={<CatalogueInfo />} />
                      <Route path="formulaire/:id" element={<FormCatalogue />} />
                    </Route>
                    <Route path="valeurs-mobilieres" element={<Outlet />}>
                      {/* Home */}
                      <Route path="" element={<VMHome />} />
                      <Route path="ajouter" element={<VMAddProductPage />} />
                      <Route path="produit" element={<ProductHome categorie="VM" />}>
                        <Route path="produit" element={<ProductComponent categorieP="VM" />} />
                        <Route
                          path="transactions"
                          element={<TransactionsComponent categorie="VM" />}
                        />
                        <Route path="operations" element={<OperationsComponent categorie="VM" />} />
                        <Route
                          path="portefeuille"
                          element={<PortefeuilleComponent categorie="VM" />}
                        />
                      </Route>
                    </Route>
                    <Route path="assurances-vie" element={<Outlet />}>
                      {/* Home */}
                      <Route path="" element={<AVHome />} />
                      <Route path="ajouter" element={<AddProductPage />} />
                      <Route path="produit" element={<ProductHome categorie="AV" />}>
                        <Route path="produit" element={<ProductComponent categorieP="AV" />} />
                        <Route path="operations" element={<OperationsComponent categorie="AV" />} />
                        <Route
                          path="portefeuille"
                          element={<PortefeuilleComponent categorie="AV" />}
                        />
                      </Route>
                    </Route>
                    <Route path="produits-financiers" element={<Outlet />}>
                      {/* Home */}
                      <Route path="" element={<PFHome />} />
                      <Route path="compte" element={<BienPF />} />
                      <Route path="ajouter" element={<FormPF />} />
                    </Route>
                  </Route>
                  {/* social */}
                  <Route path="social" element={<ActifsSocial />}>
                    {/* Home */}
                    <Route path="" element={<SocialHome />} />
                    <Route path="catalogue" element={<Outlet />}>
                      {/* Home */}
                      <Route path="" element={<CatalogueHome categorie={'social'} />} />
                      <Route path="informations" element={<CatalogueInfo />} />
                      <Route path="formulaire/:id" element={<FormCatalogue />} />
                    </Route>
                    <Route path="PER" element={<Outlet />}>
                      {/* Home */}
                      <Route path="" element={<PERHome />} />
                      <Route path="formulaire" element={<PERAddProductPage />} />
                      <Route path="produit" element={<ProductHome categorie="PER" />}>
                        <Route path="produit" element={<ProductComponent categorieP="PER" />} />
                        <Route
                          path="transactions"
                          element={<TransactionsComponent categorie="PER" />}
                        />
                        <Route
                          path="operations"
                          element={<OperationsComponent categorie="PER" />}
                        />
                        <Route
                          path="portefeuille"
                          element={<PortefeuilleComponent categorie="PER" />}
                        />
                      </Route>
                    </Route>
                    <Route path="retraite" element={<Outlet />}>
                      {/* Home */}
                      <Route path="" element={<RetraiteHome />} />
                      <Route path="formulaire" element={<RetraiteAddProductPage />} />
                      <Route path="produit" element={<ProductHome categorie="retraite" />}>
                        <Route
                          path="produit"
                          element={<ProductComponent categorieP="retraite" />}
                        />
                        <Route
                          path="operations"
                          element={<OperationsComponent categorie="retraite" />}
                        />
                        <Route
                          path="portefeuille"
                          element={<PortefeuilleComponent categorie="retraite" />}
                        />
                      </Route>
                    </Route>
                    <Route path="Prevoyance" element={<Outlet />}>
                      {/* Home */}
                      <Route path="" element={<PrevoyanceHome />} />
                      <Route path="informations" element={<PrevoyanceInfo />} />
                      <Route path="formulaire" element={<FormPrevoyance />} />
                    </Route>
                  </Route>
                  {/* autres */}
                  <Route path="autre" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<AutresHome />} />
                    <Route path="bien" element={<BienAutres />} />
                    <Route path="ajouterBien" element={<FormPatrimoineAutre />} />
                  </Route>
                </Route>
                {/* passifs */}
                <Route path="passifs" element={<PatrimoinePassif />}>
                  {/* Home */}
                  <Route path="" element={<PassifsHome />} />
                  {/* emprunts */}
                  <Route path="emprunts" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<PassifEmpruntHome />} />
                    <Route path="info" element={<PassifEmpruntInfo />} />
                    <Route path="ajouter" element={<FormPassifEmprunt />} />
                  </Route>
                  {/* dettes */}
                  <Route path="dettes" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<PassifDetteHome />} />
                    <Route path="info" element={<PassifDetteInfo />} />
                    <Route path="ajouter" element={<FormPassifDette />} />
                  </Route>
                </Route>
                {/* revenus */}
                <Route path="revenus" element={<PatrimoineRevenu />}>
                  {/* Home */}
                  <Route path="" element={<RevenusHome />} />
                  {/* professionnel */}
                  <Route path="professionnel" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<RevenuProHome />} />
                    <Route path="revenu" element={<RevenuProfessionnel />} />
                    <Route path="ajouter-revenu" element={<FormRevenuPro />} />
                  </Route>
                  {/* mobilier */}
                  <Route path="mobilier" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<RevenuMobHome />} />
                    <Route path="revenu" element={<RevenuMobilier />} />
                    <Route path="ajouter-revenu" element={<FormRevenuMob />} />
                  </Route>
                  {/* pension-rente */}
                  <Route path="pension-rente" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<PensionRenteHome />} />
                    <Route path="revenu" element={<PensionRente />} />
                    <Route path="ajouter-revenu" element={<FormPensionRente />} />
                  </Route>
                  {/* autres */}
                  <Route path="autres" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<RevenuAutreHome />} />
                    <Route path="info" element={<RevenuAutreInfo />} />
                    <Route path="ajouter" element={<FormRevenuAutre />} />
                  </Route>
                  {/* foncier */}
                  <Route path="foncier" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<RevenuFoncierHome />} />
                    <Route path="info" element={<RevenuFoncierInfo />} />
                    <Route path="ajouter" element={<FormRevenuFoncier />} />
                  </Route>
                </Route>
                {/* charges */}
                <Route path="charges" element={<PatrimoineCharge />}>
                  {/* Home */}
                  <Route path="" element={<ChargesHome />} />
                  {/* generales */}
                  <Route path="depenses-courantes" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<ChgGeneralesHome />} />
                    <Route path="info" element={<ChgGeneralesInfo />} />
                    <Route path="ajouter" element={<FormChgGenerales />} />
                  </Route>
                  {/* pensions */}
                  <Route path="pensions" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<ChgPensionsHome />} />
                    <Route path="info" element={<ChgPensionInfo />} />
                    <Route path="ajouter" element={<FormChgPensions />} />
                  </Route>
                  {/* emprunts et dettes */}
                  <Route path="emprunts-et-dettes" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<ChgEmpruntDettesHome />} />
                    <Route path="info" element={<ChgEmpruntDetteInfo />} />
                    <Route path="ajouter" element={<FormChgEmpruntDette />} />
                  </Route>
                  {/* deductiblesRI */}
                  <Route path="deductibles-RI" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<ChgDeductiblesHome />} />
                    <Route path="info" element={<ChgDeductiblesInfo />} />
                    <Route path="ajouter" element={<FormChgDeductibles />} />
                  </Route>
                  {/* reductionRI */}
                  <Route path="reduction-RI" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<ChgReductionHome />} />
                    <Route path="info" element={<ChgReductionInfo />} />
                    <Route path="ajouter" element={<FormChgReduction />} />
                  </Route>
                  {/* impots-taxes */}
                  <Route path="impots-taxes" element={<Outlet />}>
                    {/* Home */}
                    <Route path="" element={<ChgImpotTaxeHome />} />
                    <Route path="info" element={<ChgImpotTaxeInfo />} />
                    <Route path="ajouter" element={<FormChgImpotTaxe />} />
                  </Route>
                </Route>
              </Route>
              <Route path="documents" element={<MyDocuments />} />
              <Route path="investir" element={<Invest />} />
              <Route path="conseiller" element={<MyAdvisor />} />
            </Route>
            {/* admin */}
            <Route path="admin" element={<Admin />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="utilisateurs" element={<AdminUserManagement />} />
              <Route path="cabinets" element={<AdminFirmManagement />} />
              <Route path="conseillers" element={<AdminAdvisorManagement />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  )
}

export default App
