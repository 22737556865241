import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Input from '../Components/Input'
import Modale from '../Components/Modale'

const InputsSignin = ({
  signIn,
  handleClose,
  handleSubmit,
  handleChange,
  setModalForgotPassword,
  retry,
  testConnection,
}) => {
  const navigate = useNavigate()
  const { identifiant, password } = signIn || {}
  // focus
  const identifiantRef = useRef(null)
  const motDePasseRef = useRef(null)
  useEffect(() => {
    if (identifiant === '') {
      identifiantRef.current.focus()
    } else if (password === '' && identifiant !== '') {
      motDePasseRef.current.focus()
    }
  }, [retry])

  return (
    <main className="sign__main bkg">
      <Modale close={handleClose} sign={true} title="Connexion">
        <form action="" method="POST" className="form" onSubmit={(e) => handleSubmit(e)}>
          <Input
            sign={true}
            forAndId="identifiant"
            type="texte"
            name="Identifiant"
            required={true}
            value={identifiant}
            onChange={(e) => handleChange(e)}
            placeholder="exemple@gmail.com"
            message="Veuillez remplir ce champ"
            inputRef={identifiantRef}
          />
          <Input
            sign={true}
            forAndId="password"
            type="password"
            name="Mot de passe"
            required={true}
            value={password}
            onChange={(e) => handleChange(e)}
            message="Veuillez remplir ce champ"
            icon={true}
            inputRef={motDePasseRef}
          />
          <div className="form__bloc__button">
            {password !== '' && identifiant !== '' ? (
              testConnection ? (
                <div className="informations informations__blocTxt">
                  <p className="informations__blocTxt__txt">
                    Problème de connexion au serveur. <br />
                    Tentative de reconnexion...
                  </p>
                  <button
                    type="button"
                    className={'button button--red-red width--12'}
                    onClick={() => navigate('/')}
                    tabIndex={1}
                  >
                    Arrêter
                  </button>
                </div>
              ) : (
                <button
                  type="submit"
                  className={'button button--red-red width--12'}
                  onClick={(e) => handleSubmit(e)}
                  tabIndex={1}
                >
                  Se connecter
                </button>
              )
            ) : (
              <div className={'button button--grey width--12'}>Se connecter</div>
            )}
          </div>
          <p
            className="form__bloc__note forgotten"
            onClick={() => setModalForgotPassword(true)}
            tabIndex={1}
          >
            Mot de passe oublié
          </p>
        </form>
      </Modale>
    </main>
  )
}

export default InputsSignin
