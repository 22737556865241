import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { getRevenus } from '../../../../../services/API/revenus'
import { REVENUS_PENSIONS } from '../../../../../constants'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import RevenusCard from '../Revenus/RevenusCard'
import ModalUpdateTotalValuePR from './ModalUpdateTotalValuePR'
import PensionRentePieChart from './PensionRentePieChart'
import RevenusPRToPrint from './Print/RevenusPRToPrint'
import LittleLoader from '../../../../layout/LittleLoader'

const PensionRenteHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const { incomes } = useSelector((state) => state.getIncomes)
  const { pensionsRentes } = incomes || {}
  const { pensionRentes, valeurPensionsRentes, pensionRenteDivers } = pensionsRentes || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: pensionRenteDivers?.montant },
      { label: 'Affecté', value: valeurPensionsRentes - pensionRenteDivers?.montant },
    ],
    [pensionRenteDivers, valeurPensionsRentes]
  )
  // recharge
  useEffect(() => {
    !incomes && dispatch(getRevenus(person?.id, isPP))
  }, [incomes, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () => pensionRentes.filter((revenu) => revenu.dateFin && new Date(revenu.dateFin) < new Date()),
    [pensionRentes]
  )
  const dataToDisplayNotSold = useMemo(
    () =>
      pensionRentes.filter((revenu) => new Date(revenu.dateFin) >= new Date() || !revenu.dateFin),
    [pensionRentes]
  )
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // redirections
  const formLink = '/user/patrimoine/revenus/pension-rente/ajouter-revenu'
  // recuperation actifs
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!incomes) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <RevenusPRToPrint
              elementToDisplay={pensionsRentes}
              values={values}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurPensionsRentes}
              update={true}
              values={values}
            />
            {valeurPensionsRentes > 0 ? (
              <PatrimoineChartCard
                children={<PensionRentePieChart pensionsRentes={pensionsRentes} />}
                detenteurs={true}
              />
            ) : null}
          </div>
          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <RevenusCard
              data={dataToDisplayNotSold}
              handleAdd={() => navigate(formLink)}
              title={`En cours  (${dataToDisplayNotSold.length})`}
              tableType={REVENUS_PENSIONS}
            />
            {dataToDisplaySold.length > 0 ? (
              <RevenusCard
                data={dataToDisplaySold}
                handleAdd={() => navigate(formLink)}
                title={`Historique (${dataToDisplaySold.length})`}
                tableType={REVENUS_PENSIONS}
              />
            ) : null}
          </div>
        </div>
        {toggleUpdateTotal ? (
          <ModalUpdateTotalValuePR
            setToggleUpdateTotal={setToggleUpdateTotal}
            person={person}
            incomes={incomes}
            pp={isPP}
          />
        ) : null}
      </div>
    </>
  )
}

export default PensionRenteHome
