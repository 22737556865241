import React, { useEffect, useState } from 'react'
import {
  optionsBiensImmo,
  optionsRegimeFiscal,
  optionsRisque,
  optionsTypeImmo,
} from '../../../../../../data/options/optionsActifs'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'
import SelectComponent from '../../../../Components/SelectComponent'
import DateInputComponent from '../../../../Components/DateInputComponent'
import { optionsDetention } from '../../../../../../data/options/options'

const GeneralInformationCard = ({
  handleChange,
  handleChangeDate,
  information,
  setInformation,
  bienToDisplay,
}) => {
  const {
    typeImmobilier,
    nom,
    typeBien,
    dispositif,
    risque,
    valeur,
    dateValeur,
    codeImpot,
    detention,
    tauxIndivision,
  } = information
  //
  const [optionsDispositif, setOptionsDispositif] = useState([])
  const [optionsTypeBien, setOptionsTypeBien] = useState(null)
  const [array, setArray] = useState(null)
  // dispositif
  useEffect(() => {
    setOptionsDispositif(optionsRegimeFiscal.map((el) => ({ value: el, label: el })))
  }, [])
  // typeBien
  useEffect(() => {
    setOptionsTypeBien(null)
    if (typeImmobilier !== '') {
      setArray(optionsBiensImmo.filter((el) => el.typeImmobilier === typeImmobilier)[0].typeBien)
    }
  }, [typeImmobilier])
  useEffect(() => {
    if (array)
      setOptionsTypeBien(
        array.map((el) => ({ value: el.typeBien, label: el.typeBien, codeImpot: el.codeFiscal }))
      )
  }, [array])
  // codeImpot
  useEffect(() => {
    if (typeBien !== '' && optionsTypeBien) {
      setInformation({
        ...information,
        codeImpot: optionsTypeBien
          ? optionsTypeBien.filter((el) => el.value === typeBien)[0].codeImpot
          : '',
      })
    }
  }, [typeBien])

  return (
    <Card
      title={bienToDisplay ? bienToDisplay.nom : 'Informations générales'}
      h4={true}
      open={true}
    >
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* nom */}
          <Input
            forAndId="nom"
            errorLabel={nom === ''}
            type="text"
            name="Libellé :"
            value={nom}
            onChange={(e) => handleChange(e)}
            placeholder="Maison T5 150 m²"
            copy={true}
            required={true}
          />
          {/* typeImmobilier */}
          <SelectComponent
            forAndId="typeImmobilier"
            errorLabel={typeImmobilier === ''}
            name="Catégorie :"
            isSearchable={false}
            options={optionsTypeImmo}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsTypeImmo.find((c) => c.value === typeImmobilier)}
            copy={true}
            required={true}
          />
          {/* typeBien */}
          <SelectComponent
            forAndId="typeBien"
            errorLabel={typeBien === ''}
            name="Sous-catégorie :"
            isDisabled={typeImmobilier === '' ? true : false}
            isSearchable={false}
            options={optionsTypeBien && optionsTypeBien}
            placeholder="Selection"
            onChange={(e, name) => handleChange(e, name)}
            value={
              optionsTypeBien && typeBien !== ''
                ? optionsTypeBien.find((c) => c.value === typeBien)
                : ''
            }
            copy={true}
            required={true}
          />
          {/* dispositif */}
          <SelectComponent
            forAndId="dispositif"
            errorLabel={dispositif === ''}
            name="Régime fiscal :"
            isSearchable={true}
            options={optionsDispositif.length > 0 && optionsDispositif}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={
              optionsDispositif.length > 0 && optionsDispositif.find((c) => c.value === dispositif)
            }
            copy={true}
            required={true}
          />
          {/* valeur */}
          <Input
            forAndId="valeur"
            errorLabel={valeur === '' || valeur === 0}
            type="number"
            name="Valeur estimée :"
            value={valeur}
            onChange={(e) => handleChange(e)}
            required={true}
            copy={true}
          />
        </div>
        {/* dateValeur */}
        <DateInputComponent
          name={'dateValeur'}
          label={"Date de l'estimation :"}
          value={dateValeur}
          handleChange={handleChangeDate}
        />
        {/* risque */}
        <SelectComponent
          forAndId="risque"
          name="Niveau de risque :"
          isSearchable={false}
          options={optionsRisque}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsRisque.find((c) => c.value === risque)}
          copy={true}
        />
        {/* codeImpot */}
        <Input
          forAndId="codeImpot"
          disabled={true}
          type="text"
          name="Code Impot :"
          value={codeImpot}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
        {/* detention */}
        <SelectComponent
          forAndId="detention"
          errorLabel={detention === ''}
          name="Détention :"
          isSearchable={false}
          options={optionsDetention}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsDetention.find((c) => c.value === detention)}
          required={true}
          copy={true}
        />
        {/* tauxIndivision */}
        <Input
          forAndId="tauxIndivision"
          type="number"
          name="Taux d'indivision :"
          value={tauxIndivision}
          onChange={(e, name) => handleChange(e)}
          copy={true}
        />
      </div>
    </Card>
  )
}

export default GeneralInformationCard
