import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { changePasword } from '../../../services/API/connexion'
import Input from '../Components/Input'
import Modale from '../Components/Modale'
import Ok from '../Components/Modales/Ok'

const NewPassword = () => {
  const dispatch = useDispatch()
  let { id } = useParams()
  const [status, setStatus] = useState(null)
  const [error_password, setError_password] = useState(false)
  const [modalOk, setModalOk] = useState(false)
  const [modalNotOk, setModalNotOk] = useState(false)

  const [newP, setNewP] = useState({
    pseudoOrEmail: '',
    password: '',
    newpassword: '',
  })
  const { newPassword, confirmPassword } = newP || {}

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'confirmPassword') {
      setError_password(false)
    }
    setNewP({ ...newP, [name]: value.trim() })
  }
  const validate = () => {
    if (newPassword !== '') {
      if (confirmPassword !== newPassword) {
        setError_password(true)
      } else {
        setError_password(false)
        const data = {
          pseudoOrEmail: id,
          password: newPassword,
          newpassword: newPassword,
        }
        dispatch(changePasword(data, setStatus))
      }
    }
  }
  // response
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setModalOk(true)
      setStatus(null)
    }
    if (status >= 400) {
      setModalNotOk(true)
      setStatus(null)
    }
  }, [status])

  //handleClose
  const handleClose = () => {
    setModalOk(false)
  }

  return (
    <div className="form">
      <Input
        sign={true}
        forAndId="newPassword"
        type="password"
        name="Nouveau mot de passe"
        required={true}
        value={newPassword}
        onChange={(e) => handleChange(e)}
        message="Veuillez remplir ce champ"
      />
      <Input
        sign={true}
        errorInput={error_password}
        forAndId="confirmPassword"
        type="password"
        name="Confirmation du mot de passe"
        required={true}
        value={confirmPassword}
        onChange={(e) => handleChange(e)}
        message="Le mot de passe ne correspond pas"
      />

      <div className="form__bloc__button">
        <button type="button" className="button button--dark-red" onClick={() => validate()}>
          Valider
        </button>
      </div>
      {modalOk && (
        <Ok text="Votre nouveau mot de passe a bien été enregistré" close={handleClose} />
      )}
      {modalNotOk && (
        <Modale
          close={() => setModalNotOk(false)}
          title="Un problème est survenu"
          line1="Veuillez saisir à nouveau votre nouveau mot de passe"
        >
          <div className="button button--blue-red width--12" onClick={() => setModalNotOk(false)}>
            Fermer
          </div>
        </Modale>
      )}
    </div>
  )
}

export default NewPassword
