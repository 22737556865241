import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import { postRevenu } from '../../../../../../services/API/revenus'
import { dateToCompare, getMonths } from '../../../../../../services/helpers/dates'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../services/helpers/detenteurs'
import {
  calculateAmountReverse,
  calculateAnnualAmount,
  supprId,
} from '../../../../../../services/helpers/helpers'
import ModaleDivers from '../../../Patrimoine/ModaleDivers'
import NotCorrect from '../../../../Components/Modales/NotCorrect'
import Ok from '../../../../Components/Modales/Ok'
import NotOk from '../../../../Components/Modales/NotOk'
import { DOC_REVENU_PENSION } from '../../../../../../constants'
import ModaleFiles from '../../../../Files/ModaleFiles'
import FormPatrimoineContent from '../../../Patrimoine/FormPatrimoineContent'
import usePersonData from '../../../../../layout/usePersonData'

const FormPensionRente = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const incomes = useSelector((state) => state.getIncomes.incomes)
  const revenusDivers = incomes ? incomes.revenuDivers.montant : null
  const pensionRenteDivers = incomes ? incomes.pensionRenteDivers.montant : null
  const revenu = useSelector((state) => state.getBien.bien)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddPR, setToggleAddPR] = useState(false)
  const [toggleAddPROk, setToggleAddPROk] = useState(false)
  const [toggleAddPRNotOk, setToggleAddPRNotOk] = useState(false)
  const [toggleAddPRNotCorrect, setToggleAddPRNotCorrect] = useState(false)
  const [toggleDivers, setToggleDivers] = useState(false)

  // maj bien
  useEffect(() => {
    if (revenu) {
      setFichiersToDisplay(revenu.fichiers)
    }
  }, [revenu])
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    libelle: '',
    typePensionRente: '',
    detail: '',
    age: 'SANS',
    typeRevenu: '',
    montant: 0,
    typePeriodicite: '',
    dateDebut: '',
    dateFin: '',
    detention: 'CLIENT',
    tauxIndivision: 100,
    detenteurs: [],
    fichiers: [],
    typeActif: '',
    idActif: 0,
  })
  const {
    id,
    libelle,
    typePensionRente,
    detail,
    age,
    typeRevenu,
    montant,
    typePeriodicite,
    dateDebut,
    dateFin,
    detention,
    tauxIndivision,
    detenteurs,
    fichiers,
    typeActif,
    idActif,
  } = information || {}
  //
  const [divers, setDivers] = useState(true)
  const [fichiersToDisplay, setFichiersToDisplay] = useState(fichiers)
  const [detenteursToDisplay, setDetenteursToDisplay] = useState(detenteurs)
  const [newValeurTotale, setNewValeurTotale] = useState()
  // valeur totale de calcul
  useEffect(() => {
    if (montant === 0 || typePeriodicite === '') {
      setNewValeurTotale(0)
    } else {
      if (dateFin === '' || !dateFin) {
        setNewValeurTotale((calculateAnnualAmount(montant, typePeriodicite) * tauxIndivision) / 100)
      } else {
        if (dateToCompare(dateFin) >= dateToCompare(new Date())) {
          const months = getMonths(dateFin)
          months > 12
            ? setNewValeurTotale(
                (calculateAnnualAmount(montant, typePeriodicite) * tauxIndivision) / 100
              )
            : setNewValeurTotale(
                (months * (calculateAnnualAmount(montant, typePeriodicite) / 12) * tauxIndivision) /
                  100
              )
        } else {
          setNewValeurTotale(0)
        }
      }
    }
  }, [montant, dateFin, typePeriodicite, tauxIndivision])
  // maj si revenu
  useEffect(() => {
    revenu
      ? setInformation({
          ...information,
          id: revenu.id,
          libelle: revenu.libelle,
          typePensionRente: revenu.typePensionRente,
          detail: revenu.detail,
          age: revenu.age,
          typeRevenu: revenu.typeRevenu,
          montant: calculateAmountReverse(revenu.montant, revenu.typePeriodicite),
          typePeriodicite: revenu.typePeriodicite,
          dateDebut: revenu.dateDebut,
          dateFin: revenu.dateFin,
          detention: revenu.detention,
          tauxIndivision: revenu.tauxIndivision,
          detenteurs: revenu.detenteurs
          .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
          .reverse(),
              fichiers: revenu.fichiers,
          typeActif: revenu.typeActif,
          idActif: revenu.idActif,
        })
      : setInformation({
          ...information,
          detenteurs: [
            {
              id: 0,
              nomDetenteur:
                personType === 'referentPP'
                  ? `${person.nom} ${person.prenom}`
                  : `${person.denominationSociale}`,
              mailDetenteur: person.mail,
              idPP: personType === 'referentPP' ? 1 : 0,
              idPM: personType === 'dirigeantPM' ? 1 : 0,
              dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
              dateContitutionUsufruit: '',
              dureeDemembrement: '',
              typeDetention: 'PLEINEPROPRIETE',
              tauxPleinePropriete: 100,
              tauxUsufruit: 0,
              tauxUsufruitCalcule: handleCalculateTaux(person.dateNaissance),
              tauxNuePropriete: 0,
              valeurDetenue: 0,
              valeurPP: 0,
              valeurUS: 0,
              valeurNP: 0,
              tempId: Date.now(),
            },
          ],
        })
  }, [])
  // form Revenu Mob is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Pension et Rente' }))
    }
  }, [dataChange])
  // save Bien immo
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Pension et Rente') {
      handlePreSubmit()
    }
  }, [saveBien])
  // Object to send
  const data = {
    id: id,
    libelle: libelle,
    typePensionRente: typePensionRente,
    detail: detail,
    age: age,
    typeRevenu: typeRevenu,
    montant: calculateAnnualAmount(montant, typePeriodicite),
    typePeriodicite: typePeriodicite,
    dateDebut: dateDebut,
    dateFin: dateFin,
    detention: detention,
    tauxIndivision: tauxIndivision,
    detenteurs:
      detenteursToDisplay &&
      detenteursToDisplay.map((det) => updateDetenteurs(det, newValeurTotale, detenteursToDisplay)),
    fichiers: fichiers,
    typeActif: typeActif,
    idActif: idActif,
  }
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])

  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    if (name === 'dateDebut' && typePeriodicite === 'UNIQUE') {
      setInformation({ ...information, [name]: date, dateFin: date })
    } else {
      setInformation({ ...information, [name]: date })
    }
  }
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e) {
      if (e.target !== undefined) {
        const { name, value } = e.target
        setInformation({ ...information, [name]: value })
      } else {
        const name = selectName.name
        const value = e.value
        switch (name) {
          case 'typePensionRente':
            setInformation({ ...information, [name]: value, detail: null, age: 'SANS' })
            break
          case 'detail':
            setInformation({ ...information, [name]: value, age: 'SANS' })
            break
          case 'divers':
            setDivers(value)
            break
          case 'typePeriodicite':
            if (value === 'UNIQUE' && dateDebut) {
              setInformation({ ...information, [name]: value, dateFin: dateDebut })
            } else {
              setInformation({ ...information, [name]: value })
            }
            break
          default:
            setInformation({ ...information, [name]: value })
        }
      }
    } else {
      const name = selectName.name
      setInformation({ ...information, [name]: null })
    }
  }
  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (libelle !== '' && typePensionRente !== '' && typePeriodicite !== '' && montant !== '') {
      setDetenteursToDisplay(
        detenteursToDisplay.map((det) => (det.newDetenteur ? supprId(det) : det))
      )
      revenusDivers === 0 && pensionRenteDivers === 0 ? handleSubmit() : setToggleDivers(true)
    } else {
      setToggleAddPRNotOk(true)
    }
  }
  // submit
  const handleSubmit = () => {
    dispatch(postRevenu('pensionRente', person.id, isPP, divers, data, setStatus))
    setToggleDivers(false)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (revenu && revenu.id !== 0) {
      navigate('/user/patrimoine/revenus/pension-rente/revenu')
    } else {
      navigate('/user/patrimoine/revenus/pension-rente')
    }
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setStatus(null)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
      setToggleAddPROk(true)
    }
    if (status >= 400) {
      setStatus(null)
      setToggleAddPRNotOk(true)
    }
  }, [status])
  // maj detenteurs
  // useEffect(() => {
  //   if (detenteursToDisplay.length > 0) {
  //     setInformation({ ...information, detenteurs: detenteursToDisplay })
  //   }
  // }, [detenteursToDisplay])

  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setToggleAddPROk(false)
    navigate('/user/patrimoine/revenus/pension-rente')
  }
  // recuperation patrimoine
  useEffect(() => {
    if (incomes && revenu) {
      const newBien = incomes.pensionsRentes.pensionRentes.filter((el) => el.id === revenu.id)
      dispatch(getBienSuccess(newBien[0]))
    }
  }, [incomes])

  return (
    <div className="section__content">
      {/* form */}
      <FormPatrimoineContent
        dataToDisplay={revenu}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        information={information}
        setInformation={setInformation}
        detenteursToDisplay={detenteursToDisplay}
        setDetenteursToDisplay={setDetenteursToDisplay}
        fichiersToDisplay={fichiersToDisplay}
        setFichiersToDisplay={setFichiersToDisplay}
        setToggleAdd={setToggleAddPR}
        handleSubmit={handlePreSubmit}
        newValeurTotale={newValeurTotale}
        handleGiveUp={handleGiveUp}
        tableType={DOC_REVENU_PENSION}
        foncier={false}
      />
      {/* modales */}
      {toggleAddPR && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_REVENU_PENSION}
          setModal={setToggleAddPR}
          setDataChange={setDataChange}
        />
      )}
      {toggleAddPROk && (
        <Ok
          text={
            revenu
              ? 'Votre revenu a été modifié avec succès'
              : 'Votre revenu a été ajouté avec succès'
          }
          close={closeToggleAddBien}
        />
      )}
      {toggleAddPRNotOk && (
        <NotOk setNotOK={setToggleAddPRNotOk} closeModale={closeToggleAddBien} />
      )}
      {toggleAddPRNotCorrect && <NotCorrect setNotCorrect={setToggleAddPRNotCorrect} />}
      {toggleDivers && (
        <ModaleDivers
          setToggleDivers={setToggleDivers}
          handleChange={handleChange}
          divers={divers}
          setDivers={setDivers}
          handleSubmit={handleSubmit}
          montantCategorieDivers={revenusDivers}
          montantSousCategorieDivers={pensionRenteDivers}
          type={'revenus'}
          sousType={'pension-rente'}
        />
      )}
    </div>
  )
}

export default FormPensionRente
