import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { getPassifs } from '../../../../../services/API/passifs'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import PassifDetteCard from './PassifDetteCard'
import PassifsDettesPieChart from './PassifsDettesPieChart'
import PassifsDettesToPrint from './Print/PassifsDettesToPrint'
import ModalUpdateTotalPassifDette from './ModalUpdateTotalPassifDette'
import LittleLoader from '../../../../layout/LittleLoader'

const PassifDetteHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const passifs = useSelector((state) => state.getPassifs.passifs)
  const { dettes } = passifs || {}
  const { valeurDettes, detteDivers, ldettes } = dettes || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: detteDivers?.montant },
      { label: 'Affecté', value: valeurDettes - detteDivers?.montant },
    ],
    [detteDivers, valeurDettes]
  )
  // recharge
  useEffect(() => {
    if (!passifs) {
      dispatch(getPassifs(person?.id, isPP))
    }
  }, [passifs, person, isPP, dispatch])
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // redirections
  const formLink = '/user/patrimoine/passifs/dettes/ajouter'
  // recuperation passifs
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!passifs) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<PassifsDettesToPrint elementToDisplay={dettes} values={values} />}
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurDettes}
              update={true}
              values={values}
            />
            {valeurDettes > 0 ? (
              <PatrimoineChartCard
                children={<PassifsDettesPieChart dettes={dettes} />}
                detenteurs={true}
              />
            ) : null}
          </div>
          <div className="cards cards--fullWidth">
            <PassifDetteCard
              data={ldettes}
              handleAddDette={() => navigate(formLink)}
              title={`Dettes (${ldettes.length})`}
            />
          </div>
        </div>
        {toggleUpdateTotal && (
          <ModalUpdateTotalPassifDette
            setToggleUpdateTotal={setToggleUpdateTotal}
            person={person}
            passifs={passifs}
            pp={isPP}
          />
        )}
      </div>
    </>
  )
}

export default PassifDetteHome
