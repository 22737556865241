import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import Title from '../../layout/Title'
import InformationsPP from './InformationsPP'
import InformationsPM from './InformationsPM'
import usePersonData from '../../layout/usePersonData'
import useName from '../../layout/useName'

const InformationsHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const persons = useSelector((state) => state.getPersons.persons)
  const { person, personType, isPP } = usePersonData()

  useEffect(() => {
    if (persons && personType && person) {
      const newPerson = persons[personType].filter((el) => el.id === person.id)
      dispatch(getPerson(newPerson[0]))
    } else {
      navigate('/user/comptes')
    }
  }, [persons, person])
  // name
  const name = useName()

  return person ? (
    <>
      <Title title="Informations" name={name} />
      {isPP ? (
        <InformationsPP type={personType} />
      ) : (
        <InformationsPM type={personType} name={name} />
      )}
    </>
  ) : (
    <Navigate to={'/user/comptes'} replace />
  )
}

export default InformationsHome
