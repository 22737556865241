import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { getContacts } from '../../../services/API/connexion'
import ContactsCard from '../../components/Contacts/ContactsCard'
import ModaleUpdateContact from '../../components/Contacts/ModaleUpdateContact'
import Title from '../../layout/Title'
import ButtonsRow from '../../components/Patrimoine/Patrimoine/ButtonsRow'
import { getBienSuccess } from '../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../redux/actions/actionGetBienType'

const MyDirectory = () => {
  const dispatch = useDispatch()
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // contacts
  const contacts = useSelector((state) => state.getContacts.contacts)
  const [modalUpdate, setModalUpdate] = useState(false)
  // recuperation des contacts
  useEffect(() => {
    !contacts && dispatch(getContacts())
    if (persons && persons.referentPP[0] !== undefined) {
      dispatch(getPerson(persons.referentPP[0]))
      dispatch(postPersonType('referentPP'))
    }
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])

  const openModalAddContact = () => {
    setModalUpdate(true)
  }

  return (
    <section className="section">
      <Title title="Repertoire" />
      <div className="section__content">
        <ButtonsRow handleAdd={() => openModalAddContact()} />
        {contacts ? (
          <div className="sousSection">
            <ContactsCard contacts={contacts} openModalAddContact={openModalAddContact} />
            {modalUpdate && <ModaleUpdateContact setModal={setModalUpdate} />}
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default MyDirectory
