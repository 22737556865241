import React, { useState } from 'react'
import Card from '../../../Components/Card'
import BlocTable from '../../../table/BlocTable'
import ModalPerformanceFrais from './ModalPerformanceFrais'
import { PRODUIT_FRAIS } from '../../../../../constants'
import TableBodyFrais from './Table/TableBodyFrais'

const PerformanceEtFraisCard = ({
  historiquesFraisToDisplay,
  setHistoriquesFraisToDisplay,
  categorieP,
  type,
  setDataChange,
}) => {
  // modales
  const [openModale, setOpenModale] = useState(false)
  // titles
  const title = categorieP !== 'AV' && categorieP !== 'retraite' ? 'Frais' : 'Performance des fonds'
  const titles =
    categorieP !== 'AV' && categorieP !== 'retraite'
      ? [
          'N°',
          'Année',
          'Frais de gestion',
          'Périodicité FG',
          'Droit de garde',
          'Périodicité DG',
          'Modifier',
          'Supprimer',
        ]
      : ['N°', 'Année', 'Performance Fonds', 'Modifier', 'Supprimer']
  return (
    <>
      <Card title={title} h6={true} open={true}>
        <div className="informations">
          <BlocTable
            titles={titles}
            data={historiquesFraisToDisplay}
            setData={setHistoriquesFraisToDisplay}
            setDataChange={setDataChange}
            tableType={PRODUIT_FRAIS}
            children={<TableBodyFrais />}
            title={title}
            handleAdd={() => setOpenModale(true)}
          />
        </div>
      </Card>
      {openModale ? (
        <ModalPerformanceFrais
          setModal={setOpenModale}
          data={historiquesFraisToDisplay}
          setData={setHistoriquesFraisToDisplay}
          newData={false}
          setDataChange={setDataChange}
        />
      ) : null}
    </>
  )
}

export default PerformanceEtFraisCard
