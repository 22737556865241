import axios from 'axios'
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants'
import {
  getCataloguesError,
  getCataloguesLoad,
  getCataloguesSuccess,
} from '../../redux/actions/Patrimoine/actionGetCatalogues'
import {
  getCatalogueCommunError,
  getCatalogueCommunLoad,
  getCatalogueCommunSuccess,
} from '../../redux/actions/Patrimoine/actionGetCatalogueCommun'
import { toast } from 'react-toastify'
// post financier
export const postCatalogue = (data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/financier/catalogue`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// delete catalogue
export const deleteCatalogue = (id, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'delete',
      url: `${API_BASE_URL}/financier/deleteCatalogue/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// recup catalogue
export const getCatalogues = () => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getCataloguesLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/financier/catalogues`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getCataloguesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getCataloguesError(error.message))
      })
  }
}

// recup catalogue Commun
export const getCatalogueCommun = () => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getCatalogueCommunLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/financier/cataloguesCommun`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getCatalogueCommunSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getCatalogueCommunError(error.message))
      })
  }
}

// create support
export const postNewSupport = (data, setStatus) => {
  const toastLoading = toast.loading('Connexion au serveur en cours ...')
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/financier/dici`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        toast.dismiss(toastLoading)
        toast.success('Nouveau support enregistré')
        setStatus(response.status)
      })
      .catch((error) => {
        toast.error(`Echec de l'enregistrement du nouveau support : ${error?.message}`)
        setStatus(error.response.status)
      })
  }
}
// create support
export const postNewVL = (data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  const toastLoading = toast.loading('Enregistrement de la nouvelle valeur en cours ...')

  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/financier/vl`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
        toast.dismiss(toastLoading)
        toast.success('Nouvelle valeur enregistrée !')
      })
      .catch((error) => {
        setStatus(error.response.status)
        toast.dismiss(toastLoading)
      })
  }
}
