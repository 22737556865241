import React from 'react'
import Card from '../../Components/Card'
import classNames from 'classnames'

const PatrimoineValueCard = ({ setToggleUpdate, totalAmount, update, values }) => {
  return (
    <Card valueCard={true} title={'Valeurs TOTALES'} open={true}>
      <div className="informations informations--center">
        <div className="informations__patrimoine">
          <h4 className="informations__patrimoine__h3">Valeurs TOTALES</h4>
          <p
            className={classNames('informations__patrimoine__h3--p', {
              'informations__patrimoine__h3--p--red': totalAmount < 0,
            })}
          >{`${totalAmount.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}</p>
        </div>
        {update ? (
          <div className="informations__patrimoine">
            <button
              type="button"
              className="button button--update button--update width--7"
              onClick={() => setToggleUpdate(true)}
            >
              Modifier
            </button>
          </div>
        ) : null}
        {values ? (
          <div className="informations__patrimoine">
            {values.map((item, index) => (
              <p key={index} className="informations__patrimoine__h4">
                {`${item.label} : `}
                <span
                  className={classNames('informations__patrimoine__h4--span', {
                    'informations__patrimoine__h4--span--red': item.value < 0,
                  })}
                >
                  {` ${item.value.toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} €`}
                </span>
              </p>
            ))}
          </div>
        ) : null}
      </div>
    </Card>
  )
}

export default PatrimoineValueCard
