import { toast } from 'react-toastify'
import { API_BASE_URL } from '../../constants'
import { getFileError, getFileLoad, getFileSuccess } from '../../redux/actions/actionGetFile'
import { getParapheurSuccess } from '../../redux/actions/actionGetParapheur'
import { getSignaturesPendingSuccess } from '../../redux/actions/actionGetSignaturesPending'
import { getSignaturesToClassifySuccess } from '../../redux/actions/actionGetSignaturesToClassify'
import { getFormIsActive } from '../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../redux/actions/actionSaveForm'
import { getFinancier } from '../API/financier'
import { getPersonnes } from '../API/personnes'
import { getActifs } from '../API/actifs'
import { getRevenus } from '../API/revenus'
import { getPassifs } from '../API/passifs'
import { getCharges } from '../API/charges'
import { getSocial } from '../API/social'
import { createRequestOptions } from '../API/requestConfig'

const updateStateAndToggle = (setModal) => async (dispatch) => {
  dispatch(getFormIsActive(null))
  dispatch(getSaveForm(false))
  setModal(false)
}
const createFormDataRequestOptions = (file) => {
  let formData = new FormData()
  formData.append('files', file)
  return createRequestOptions('POST', formData)
}
// Fonction utilitaire pour gérer les toasts et les erreurs
const handleApiResponse = async (
  response,
  toastLoading,
  successMessage,
  errorMessage,
  dispatch,
  setModal,
  onSuccess = () => {} // Ajoute une fonction vide par défaut
) => {
  if (!response.ok) {
    toast.dismiss(toastLoading)
    toast.error(errorMessage) // Utilisation correcte d'errorMessage
    updateStateAndToggle(setModal)(dispatch)
    throw new Error(`Erreur ${response.status}`)
  }
  toast.dismiss(toastLoading)
  toast.success(successMessage)
  onSuccess()
  updateStateAndToggle(setModal)(dispatch)
  return response.text()
}
// Définition de la fonction pour obtenir les actions de catégorie
export const getCategoryActions = (dispatch, personId, pp) => ({
  ACTIFS: () => dispatch(getActifs(personId, pp)),
  PASSIFS: () => dispatch(getPassifs(personId, pp)),
  REVENUS: () => dispatch(getRevenus(personId, pp)),
  CHARGES: () => dispatch(getCharges(personId, pp)),
  FINANCIER: () => dispatch(getFinancier(personId, pp)),
  SOCIAL: () => dispatch(getSocial(personId, pp)),
})
// post saveFiles
export const saveFiles = (file, url, personId, pp, setModal, categorie) => async (dispatch) => {
  const categoryActions = getCategoryActions(dispatch, personId, pp)
  const requestOptions = createFormDataRequestOptions(file)
  const toastLoading = toast.loading(`Enregistrement du fichier ${file.name} en cours...`)
  try {
    const response = await fetch(url, requestOptions)
    await handleApiResponse(
      response,
      toastLoading,
      `Fichier ${file.name} enregistré avec succès !`,
      `Erreur lors de l'enregistrement du fichier ${file.name}`,
      dispatch,
      setModal,
      () => {
        const action = categoryActions[categorie]
        if (action) {
          action()
        } else {
          dispatch(getPersonnes())
        }
      }
    )
  } catch (error) {
    toast.dismiss(toastLoading)
    toast.error(`Erreur de connexion`)
    updateStateAndToggle(setModal)(dispatch)
  }
}
// get file
export const getFile = (element) => {
  return (dispatch) => {
    const toastLoading = toast.loading('Récupération du fichier en cours...')
    dispatch(getFileLoad())
    const requestOptions = createRequestOptions('GET')
    const url = `${API_BASE_URL}/fichiers/downloadfile/${element.id}`
    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          const errorMsg = 'Échec du téléchargement du fichier'
          toast.dismiss(toastLoading)
          toast.error(errorMsg)
          dispatch(getFileError(errorMsg))
          throw new Error(errorMsg) // Empêche l'exécution de la chaîne de promesses suivante
        }
        return response.blob()
      })
      .then((blob) => {
        // Création d'un lien blob pour le téléchargement
        const url = window.URL.createObjectURL(new Blob([blob]))
        dispatch(getFileSuccess({ url: url, name: element.fileName }))
        toast.dismiss(toastLoading)
      })
      .catch((error) => {
        toast.dismiss(toastLoading)
        toast.error(`Erreur de connexion: ${error.message}`)
        dispatch(getFileError(error.message))
      })
  }
}
// supprimer fichier
export const deleteFile = (url, setModalDelete, personId, pp, categorie) => async (dispatch) => {
  const toastLoading = toast.loading('Suppression en cours...')
  const requestOptions = createRequestOptions('DELETE')
  fetch(url, requestOptions)
    .then((response) =>
      handleApiResponse(
        response,
        toastLoading,
        'Suppression réussie',
        'Erreur lors de la suppression',
        dispatch,
        setModalDelete,
        () => {
          // Success callback: actions spécifiques en cas de succès
          const categoryActions = getCategoryActions(dispatch, personId, pp)
          const action = categoryActions[categorie]
          if (action) {
            action()
          } else {
            dispatch(getPersonnes())
          }
          updateStateAndToggle(setModalDelete)(dispatch)
        }
      )
    )
    .catch((error) => {
      // Cette capture d'erreur est principalement pour les erreurs réseau / promesse rejetée avant que la réponse ne soit reçue
      toast.dismiss(toastLoading)
      toast.error(`Erreur de connexion: ${error.toString()}`)
      updateStateAndToggle(setModalDelete)(dispatch)
    })
}
// _____________________________________ SIGNATURES
// post sign
export const sign = async (url, files, signataires, setResult) => {
  const toastLoading = toast.loading('Enregistrement des signataires en cours...')
  let formData = new FormData()
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i])
  }
  formData.append('signataires', JSON.stringify(signataires))
  const requestOptions = createRequestOptions('POST', formData)
  try {
    const res = await fetch(url, requestOptions)
    toast.dismiss(toastLoading)
    if (!res.ok) {
      toast.error(`Un problème est survenu`)
      throw new Error('Network response was not ok')
    }
    const data = await res.text()
    setResult(data)
  } catch (error) {
    console.error('Error:', error)
    toast.dismiss(toastLoading)
    toast.error(`Erreur de connexion`)
  }
}
// get signatures en instance
export const getSignaturesPending = () => {
  return (dispatch) => {
    const requestOptions = createRequestOptions('GET')
    fetch(`${API_BASE_URL}/users/listeSignature`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          toast.error(`Echec du chargement des documents à récupérer`)
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getSignaturesPendingSuccess(data))
      })
      .catch((error) => {
        console.log(`Erreur : ${error.message} `)
        toast.error(`Echec du chargement des documents à récupérer`)
      })
  }
}
// get signatures à classer
export const getSignaturesToClassify = () => {
  return (dispatch) => {
    const requestOptions = createRequestOptions('GET')
    fetch(`${API_BASE_URL}/fichiers/getsignatureaclasser`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          toast.error(`Echec du chargement des documents à classer`)
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getSignaturesToClassifySuccess(data))
      })
      .catch((error) => {
        console.log(`Erreur : ${error.message} `)
        toast.error(`Echec du chargement des documents à classer`)
      })
  }
}
// get file Parapheur
export const getParapheur = (id) => {
  return (dispatch) => {
    const toastLoading = toast.loading('Récupération en cours...')
    const requestOptions = createRequestOptions('GET')
    fetch(`${API_BASE_URL}/fichiers/getParapheur?idTransaction=${id}`, requestOptions)
      .then((response) => {
        toast.dismiss(toastLoading)
        dispatch(getParapheurSuccess(response.status))
        if (!response.ok) {
          toast.error(`Echec de la récupération`)
          throw new Error('Network response was not ok')
        }
      })
      .catch((error) => {
        toast.dismiss(toastLoading)
        toast.error(`Echec de la récupération`)
      })
  }
}
// classement
export const postClassify = (data, setStatus) => {
  const requestOptions = createRequestOptions('POST', data)
  const toastLoading = toast.loading('Chargement en cours...')
  return async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/fichiers/classement`, requestOptions)
      toast.dismiss(toastLoading)
      if (!response.ok) {
        toast.error(`Echec de l'envoi du classement`)
        throw new Error('Network response was not ok')
      }
      toast.success('Document classé')
      setStatus(response.status)
    } catch (error) {
      toast.dismiss(toastLoading)
      toast.error(`Erreur : ${error.message}`)
    }
  }
}
// supprimer signature
export const deleteSign = (id, setStatus) => {
  const toastLoading = toast.loading('Suppression en cours...')
  const requestOptions = createRequestOptions('DELETE')

  return (dispatch) => {
    fetch(`${API_BASE_URL}/users/suppressionSignature/${id}`, requestOptions)
      .then((response) => {
        toast.dismiss(toastLoading)
        if (!response.ok) {
          toast.error(`Echec de la suppression de la signature`)
          throw new Error('Network response was not ok')
        }
        toast.success('Suppression réussie')

        dispatch(getSignaturesPending())
        setStatus(response.status)
      })
      .catch((error) => {
        toast.dismiss(toastLoading)
        console.log(`Erreur : ${error.message}`)
        toast.error(`Echec de la suppression de la signature`)
      })
  }
}
