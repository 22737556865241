import React from 'react'

const BodyFirmsPrint = ({ element, index }) => {
  return (
    <>
      {/* nomCabinet */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">
          {!element.nomCabinet ? '-' : element.nomCabinet}
        </p>
      </div>
      {/* adresseCabinet */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Adresse</p>
        <p className="print__table__bloc__line--value">
          {!element.adresseCabinet ? '-' : element.adresseCabinet}
        </p>
      </div>
      {/* cpCabinet */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Code postal</p>
        <p className="print__table__bloc__line--value">
          {!element.cpCabinet ? '-' : element.cpCabinet}
        </p>
      </div>
      {/* villeCabinet */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Ville</p>
        <p className="print__table__bloc__line--value">
          {!element.villeCabinet ? '-' : element.villeCabinet}
        </p>
      </div>
    </>
  )
}

export default BodyFirmsPrint
