import React, { useEffect, useRef, useState } from 'react'
import { beautifulDateNumbers } from '../../../services/helpers/dates'
import { format, isValid } from 'date-fns'
import { MdOutlineClear } from 'react-icons/md'
import { BsCalendar } from 'react-icons/bs'
import Copy from './Copy'
import SearchButton from './SearchButton'
import ModaleCalendar from './ModaleCalendar'

const DateInputComponent = ({
  label,
  value,
  handleChange,
  name,
  sign,
  errorLabel,
  search,
  action,
  disabled,
}) => {
  const [part1, setPart1] = useState('')
  const [part2, setPart2] = useState('')
  const [part3, setPart3] = useState('')
  const part1InputRef = useRef(null)
  const part2InputRef = useRef(null)
  const part3InputRef = useRef(null)
  // toggle
  const [toggle, setToggle] = useState(false)

  // maj if value already exist
  useEffect(() => {
    if (value) {
      let dateParts
      if (typeof value === 'object') {
        const momentDate = beautifulDateNumbers(value)
        dateParts = momentDate.split('-').reverse()
      } else {
        dateParts = value.split('-').reverse()
      }

      if (dateParts && dateParts.length === 3) {
        setPart1(dateParts[0].padStart(2, '0'))
        setPart2(dateParts[1].padStart(2, '0'))
        setPart3(dateParts[2])
      }
    }
  }, [value])

  // handleInputChange
  const handleInputChange = (part, value) => {
    if (
      (part === 'part1' || part === 'part2') &&
      (value.length === 2 || (value.length === 1 && /[0-9]/.test(value)))
    ) {
      if (part === 'part1') {
        setPart1(value)
        if (value.length === 2) {
          const date = new Date(parseInt(part3), parseInt(part2) - 1, parseInt(value))
          checkIsValidDate(date)
          part2InputRef.current.focus()
        }
      } else {
        setPart2(value)
        if (value.length === 2) {
          const date = new Date(parseInt(part3), parseInt(value) - 1, parseInt(part1))
          checkIsValidDate(date)
          part3InputRef.current.focus()
        }
      }
    } else if (part === 'part3' && /^[0-9]{1,4}$/.test(value)) {
      setPart3(value)
      if (value.length === 4) {
        const date = new Date(parseInt(value), parseInt(part2) - 1, parseInt(part1))
        checkIsValidDate(date)
      }
    }
  }
  // checkIsValidDate
  const checkIsValidDate = (date) => {
    if (isValid(date)) {
      const parsedDate = date
        ? format(new Date(date), 'yyyy-MM-dd', {
            useAdditionalDayOfYearTokens: true,
            useAdditionalWeekYearTokens: true,
          })
        : ''
      handleChange(parsedDate, name)
    } else {
      return
    }
  }
  // focus
  const handleFocus = (event) => {
    event.target.select()
  }
  const handleClickClear = () => {
    handleChange('', name)
    setPart1('')
    setPart2('')
    setPart3('')
  }

  return (
    <div className={sign ? 'input--sign' : 'input'}>
      <label
        className={
          errorLabel
            ? 'input__label input__label--error'
            : sign
            ? 'input__label--sign'
            : 'input__label'
        }
        htmlFor={name}
      >
        {label}
      </label>
      <div className="input__inputBloc">
        <div className="input__inputBloc__div">
          <div className={sign ? 'date date--sign' : !disabled ? 'date' : 'date date--disabled'}>
            <div className="date__inputs">
              <label className="sr-only" htmlFor={'jour'}>
                Jour
              </label>
              <input
                ref={part1InputRef}
                value={part1}
                onChange={(e) => handleInputChange('part1', e.target.value)}
                onFocus={(e) => handleFocus(e)}
                maxLength={2}
                className="input__inputBloc__input"
                placeholder="jj"
                inputMode="numeric"
                name="jour"
                id="jour"
                disabled={disabled}
              />
              <p>/</p>
              <label className="sr-only" htmlFor={'mois'}>
                Mois
              </label>
              <input
                ref={part2InputRef}
                value={part2}
                onChange={(e) => handleInputChange('part2', e.target.value)}
                onFocus={(e) => handleFocus(e)}
                maxLength={2}
                className="input__inputBloc__input"
                placeholder="mm"
                inputMode="numeric"
                name="mois"
                id="mois"
                disabled={disabled}
              />
              <p>/</p>
              <label className="sr-only" htmlFor={'annee'}>
                Année
              </label>
              <input
                ref={part3InputRef}
                value={part3}
                onChange={(e) => handleInputChange('part3', e.target.value)}
                onFocus={(e) => handleFocus(e)}
                maxLength={4}
                className="input__inputBloc__input"
                placeholder="aaaa"
                inputMode="numeric"
                name="annee"
                id="annee"
                disabled={disabled}
              />
            </div>
            {!disabled ? (
              <div className="date__icons">
                <div className="date__icons--clear" onClick={() => handleClickClear()}>
                  <MdOutlineClear />
                </div>
                <div className="date__icons--calendar" onClick={() => setToggle(!toggle)}>
                  <BsCalendar />
                </div>
                {toggle ? (
                  <ModaleCalendar
                    close={() => setToggle(false)}
                    checkIsValidDate={checkIsValidDate}
                    value={value}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
          {!sign ? <Copy value={value} /> : null}
          {search === true ? <SearchButton action={action} /> : null}
        </div>
      </div>
    </div>
  )
}

export default DateInputComponent
