import React from 'react'
import Input from '../Components/Input'
import { MdOutlinePictureAsPdf } from 'react-icons/md'

const BlocFilesList = ({ files, setFiles }) => {
  return (
    <div className="informations--border">
      <div className="informations__line">
        <Input
          forAndId="fichiers"
          type="file"
          accept=".pdf"
          name="Fichiers :"
          onChange={(e) => setFiles(e.target.files)}
          file={true}
          multiple={true}
        >
        </Input>
      </div>
          {files && Array.from(files).length > 0 && (
            <ul className="fichiersList">
              {Array.from(files).map((el, index) => (
                <li key={index} className="fichiersList__item">
                  <div className="fichiersList__item__icon">
                    <MdOutlinePictureAsPdf />
                  </div>
                  <p className="fichiersList__item__text">{el.name}</p>
                </li>
              ))}
            </ul>
          )}
    </div>
  )
}

export default BlocFilesList
