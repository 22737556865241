import React from 'react'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../Patrimoine/PatrimoineChartCardPrint'
import PassifsPieChart from './PassifsPieChart'

const PassifsToPrint = ({ elementToDisplay, values }) => {
  const { valeurTotalePassif } = elementToDisplay || {}
  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurTotalePassif}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<PassifsPieChart passifs={elementToDisplay} />}
        detenteurs={true}
      />
    </>
  )
}

export default PassifsToPrint
