import React from 'react'
import AutoCompleteAdresse from '../../../../AutoCompleteAddress/AutoCompleteAdresse'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'

const AdditionalInformationCard = ({
  handleChange,
  information,
  setInformation,
  setDataChange,
}) => {
  const { surface } = information

  return (
    <Card title="Informations complémentaires" h4={true} open={true}>
      <div className="informations">
        {/* adresse, codePostal, ville */}
        <AutoCompleteAdresse
          data={information}
          setData={setInformation}
          bienImmo={true}
          setDataChange={setDataChange}
        />
        {/* surface */}
        <Input
          forAndId="surface"
          type="number"
          name="Surface :"
          value={surface}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
      </div>
    </Card>
  )
}

export default AdditionalInformationCard
