import React from 'react'
import { useNavigate } from 'react-router-dom'
import Modale from '../Components/Modale'

const ModalSignupOk = ({ setModalOk }) => {
  const navigate = useNavigate()

  return (
    <Modale
      close={() => navigate('/')}
      title="Félicitation !"
      line1="Vous êtes maintenant inscrit à Mon Espace Patrimoine."
      line2="Vous allez pouvoir gérer votre patrimoine en toute simplicité."
    >
      <div className="informations">
        <div className="informations__blocTxt">
          <p className="informations__blocTxt__txt">
            Un mail vient de vous être envoyé pour activer votre compte
          </p>
          <p className="informations__blocTxt__txt informations__blocTxt__txt--important">
            Veuillez consulter votre messagerie et suivre le lien
          </p>
        </div>
        <div className="informations__buttons">
          <div className="button button--blue-red width--12" onClick={() => navigate('/')}>
            Fermer
          </div>
        </div>
      </div>
    </Modale>
  )
}

export default ModalSignupOk
