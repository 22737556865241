import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { getCharges } from '../../../../../services/API/charges'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { CHARGES_IMPOTS } from '../../../../../constants'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import ChargesCard from '../Charges/ChargesCard'
import ChgImpotTaxePieChart from './ChgImpotTaxePieChart'
import ModalUpdateTotalValueChgImpotTaxe from './ModalUpdateTotalValueChgImpotTaxe'
import ChargesImpotTaxeToPrint from './ChargesImpotTaxeToPrint'
import LittleLoader from '../../../../layout/LittleLoader'

const ChgImpotTaxeHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const { charges } = useSelector((state) => state.getCharges)
  const { impotEtTaxes: impotEtTaxesObject } = charges || {}
  const { impotEtTaxeDivers, valeurImpotEtTaxes, impotEtTaxes } = impotEtTaxesObject
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: impotEtTaxeDivers?.montant },
      {
        label: 'Affecté',
        value: valeurImpotEtTaxes - impotEtTaxeDivers?.montant,
      },
    ],
    [impotEtTaxeDivers, valeurImpotEtTaxes]
  )
  // recharge
  useEffect(() => {
    if (!charges) {
      dispatch(getCharges(person?.id, isPP))
    }
  }, [charges, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () => impotEtTaxes.filter((revenu) => revenu.dateFin && new Date(revenu.dateFin) < new Date()),
    [impotEtTaxes]
  )
  const dataToDisplayNotSold = useMemo(
    () =>
      impotEtTaxes.filter((revenu) => new Date(revenu.dateFin) >= new Date() || !revenu.dateFin),
    [impotEtTaxes]
  )
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // redirections
  const formLink = '/user/patrimoine/charges/impots-taxes/ajouter'
  // recuperation actifs
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!charges) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <ChargesImpotTaxeToPrint
              elementToDisplay={impotEtTaxesObject}
              values={values}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurImpotEtTaxes}
              update={true}
              values={values}
            />
            {valeurImpotEtTaxes > 0 && (
              <PatrimoineChartCard
                children={<ChgImpotTaxePieChart impotEtTaxes={impotEtTaxesObject} />}
                detenteurs={true}
              />
            )}
          </div>

          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <ChargesCard
              data={dataToDisplayNotSold}
              handleAdd={() => navigate(formLink)}
              title={`En cours (${dataToDisplayNotSold.length})`}
              tableType={CHARGES_IMPOTS}
            />
            {dataToDisplaySold.length > 0 && (
              <ChargesCard
                data={dataToDisplaySold}
                handleAdd={() => navigate(formLink)}
                title={`Historique (${dataToDisplaySold.length})`}
                tableType={CHARGES_IMPOTS}
              />
            )}
          </div>
        </div>
        {toggleUpdateTotal && (
          <ModalUpdateTotalValueChgImpotTaxe
            setToggleUpdateTotal={setToggleUpdateTotal}
            person={person}
            charges={charges}
            pp={isPP}
          />
        )}
      </div>
    </>
  )
}

export default ChgImpotTaxeHome
