// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get charges
 * @reduxAction
 */

export const getChargesLoad = createAction('get-charges-load')

export const getChargesSuccess = createAction('get-charges-success', (charges) => {
  return {
    payload: charges,
  }
})

export const getChargesError = createAction('get-charges-error', (error) => {
  return {
    payload: error,
  }
})
