import React from 'react'
import { beautifulDate } from '../../../services/helpers/dates'

const BodyMessages = ({ element }) => {
  return (
    <>
      {/* Date */}
      <td className="tbody__tr__td tbody__tr__td--longTxt">
        {!element.createdAt ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.createdAt)
        )}
      </td>
      {/* message */}
      <td className="tbody__tr__td tbody__tr__td--longTxt">
        {!element.message ? <p className="informations__line__notxt">-</p> : element.message}
      </td>
      {/* origineMessage */}
      <td className="tbody__tr__td tbody__tr__td--longTxt">
        {!element.origineMessage ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.origineMessage
        )}
      </td>
    </>
  )
}

export default BodyMessages
