import React from 'react'
import { Outlet } from 'react-router-dom'
import ResponsiveNavComponent from './ResponsiveNavComponent'

const PatrimoinePassif = () => {
  const links = [
    { name: 'Emprunt', link: '/user/patrimoine/passifs/emprunts', type: 'passif' },
    { name: 'Dette', link: '/user/patrimoine/passifs/dettes', type: 'passif' },
  ]

  return (
    <>
      <ResponsiveNavComponent links={links} type={'passif'} />
      <Outlet />
    </>
  )
}

export default PatrimoinePassif
