// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get social
 * @reduxAction
 */

export const getSocialLoad = createAction('get-social-load')

export const getSocialSuccess = createAction('get-social-success', (social) => {
  return {
    payload: social,
  }
})

export const getSocialError = createAction('get-social-error', (error) => {
  return {
    payload: error,
  }
})
