import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getParapheur } from '../../../../services/helpers/files'
import { sortByType } from '../../../../services/helpers/sort'
import { getIdTransaction } from '../../../../redux/actions/actionGetIdTransaction'
import TableBodyBloc from '../../table/TableBodyBloc'
import BodySignPending from './BodySignPending'
import BodySignPendingPrint from './BodySignPendingPrint'

const TableBodySignPending = ({
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  const dispatch = useDispatch()
  // voir
  const handleShow = (element) => {
    window.open(
      `https://www.parapheur.digital/WebSignaturePDFV3/faces/information.xhtml?id=${element.idTransaction}`
    )
    handleFetchId(element.idTransaction)
  }
  // recup
  const handleFetch = (element) => {
    dispatch(getParapheur(element.idTransaction))
    handleFetchId(element.id)
  }
  // recup idTransaction
  const handleFetchId = (id) => {
    dispatch(getIdTransaction(id))
  }

  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Fichier':
          giveAType('fichier_nom')
          break
        case 'Date':
          giveAType('dateSignature')
          break
        case 'Type de transaction':
          giveAType('typeTransaction')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodySignPendingPrint /> : <BodySignPending handleFetch={handleFetch} />}
      handleShow={handleShow}
      handleFetch={handleFetch}
      print={print}
    />
  )
}

export default TableBodySignPending
