import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getMandatSuccess } from '../../../redux/actions/actionGetMandat'
import { getPersonnes, postPP, updatePP } from '../../../services/API/personnes'
import { getFormIsActive } from '../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../redux/actions/actionSaveForm'
import { stringToCapitalize } from '../../../services/helpers/strings'
import PPComponent from './PPComponent'
import ModalCreatePPNotOK from './Modales/ModalCreatePPNotOK'
import ModalUpdatePPNotOk from './Modales/ModalUpdatePPNotOk'
import ModalParapheur from '../Parapheur/ModalParapheur'
import ModalUpdatePPOk from './Modales/ModalUpdatePPOk'
import NotCorrect from '../Components/Modales/NotCorrect'
import usePersonData from '../../layout/usePersonData'

const PP = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const user = useSelector((state) => state.getUser.user)
  const persons = useSelector((state) => state.getPersons.persons)
  const listPersons = persons.referentPP.concat(persons.relationPP)
  const { person, personType } = usePersonData()
  const personToDisplay = useMemo(() => (pathname.includes('comptes') ? null : person), [person])
  const url = useSelector((state) => state.getMandat.mandat)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // datas
  const [personInfos, setPersonInfos] = useState({})
  const { id, nom, prenom, mail, mailUsuel, dateNaissance, sexe, telFixe, telPortable } =
    personInfos || {}
  const [adresseFiscale, setAdresseFiscale] = useState({})
  const [coordonneesSupplementaires, setCoordonneesSupplementaires] = useState([])
  const [birth, setBirth] = useState({
    nomNaissance: '',
    villeNaissance: '',
    nationnalite1: '',
    nationnalite2: '',
    capaciteLegale: 'Majeur',
  })
  const { nomNaissance, villeNaissance, nationnalite1, nationnalite2, capaciteLegale } = birth || {}
  const [family, setFamily] = useState({
    situationFam: '',
    idConjoint: '',
    dateSituationFam: '',
    regimeFam: '',
    dateregimeFam: '',
    donationEntreEpoux: false,
  })
  const {
    situationFam,
    idConjoint,
    dateSituationFam,
    regimeFam,
    dateregimeFam,
    donationEntreEpoux,
  } = family || {}
  const [updateConjoint, setUpdateConjoint] = useState(false)
  const [professions, setProfessions] = useState([])
  const [relations, setRelations] = useState([])
  const [fichiers, setFichiers] = useState([])
  // response
  const [status, setStatus] = useState(null)
  const [statusUpdate, setStatusUpdate] = useState(null)
  const [statusUpdateConjoint, setStatusUpdateConjoint] = useState(null)
  // modales
  const [modalCreateOk, setModalCreateOk] = useState(false)
  const [modalNotOk, setModalNotOk] = useState(false)
  const [modalUpdateOk, setModalUpdateOk] = useState(false)
  const [modalUpdateNotOk, setModalUpdateNotOk] = useState(false)
  const [modalNotCorrect, setModalNotCorrect] = useState(false)
  // maj
  useEffect(() => {
    if (persons && personType) {
      const newPerson = persons[personType].filter((el) => el.id === person.id)
      setFichiers(newPerson[0].fichiers)
    }
  }, [persons])
  // Object to send
  const data = {
    id: id,
    nom: nom && nom.toUpperCase(),
    prenom: prenom && stringToCapitalize(prenom),
    mail: mail,
    mailUsuel: !personToDisplay ? mail : mailUsuel,
    dateNaissance: dateNaissance,
    sexe: sexe,
    telFixe: telFixe,
    telPortable: telPortable || '',
    adresseFiscale: adresseFiscale,
    coordonneesSupplementaires: coordonneesSupplementaires,
    nomNaissance: nomNaissance,
    villeNaissance: villeNaissance,
    nationnalite1: nationnalite1,
    nationnalite2: nationnalite2,
    capaciteLegale: capaciteLegale,
    situationFam: situationFam,
    idConjoint: idConjoint,
    dateSituationFam: dateSituationFam,
    regimeFam: regimeFam,
    dateregimeFam: dateregimeFam,
    donationEntreEpoux: donationEntreEpoux,
    user_id: {
      email: user.email,
    },
    professions: professions,
    relations: relations,
    fichiers: fichiers,
  }
  // form PP is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'PP' }))
    }
  }, [dataChange])

  // save PP
  useEffect(() => {
    if (saveBien && formIsActive.form === 'PP') {
      handleSubmit()
    }
  }, [saveBien])

  // champs préremplis si person selectionnée
  useEffect(() => {
    if (personToDisplay) {
      setPersonInfos({
        ...personInfos,
        id: personToDisplay.id,
        nom: personToDisplay.nom,
        prenom: personToDisplay.prenom,
        mail: personToDisplay.mail,
        mailUsuel: personToDisplay.mailUsuel,
        dateNaissance: personToDisplay.dateNaissance,
        sexe: personToDisplay.sexe,
        villeNaissance: personToDisplay.villeNaissance,
        telFixe: personToDisplay.telFixe,
        telPortable: personToDisplay.telPortable,
        user_id: {
          id: user.id,
          email: user.email,
        },
      })
      setAdresseFiscale({
        ...adresseFiscale,
        id: personToDisplay.adresseFiscale ? personToDisplay.adresseFiscale.id : 0,
        ad1: personToDisplay.adresseFiscale ? personToDisplay.adresseFiscale.ad1 : '',
        ad2: personToDisplay.adresseFiscale ? personToDisplay.adresseFiscale.ad2 : '',
        codePostal: personToDisplay.adresseFiscale ? personToDisplay.adresseFiscale.codePostal : '',
        ville: personToDisplay.adresseFiscale ? personToDisplay.adresseFiscale.ville : '',
        pays: personToDisplay.adresseFiscale ? personToDisplay.adresseFiscale.pays : '',
      })
      setCoordonneesSupplementaires([...personToDisplay.coordonneesSupplementaires])
      setBirth({
        ...birth,
        nomNaissance: personToDisplay.nomNaissance,
        villeNaissance: personToDisplay.villeNaissance,
        nationnalite1: personToDisplay.nationnalite1,
        nationnalite2: personToDisplay.nationnalite2,
        capaciteLegale: personToDisplay.capaciteLegale,
      })
      setFamily({
        ...family,
        situationFam: personToDisplay.situationFam,
        idConjoint: personToDisplay.idConjoint,
        dateSituationFam: personToDisplay.dateSituationFam,
        regimeFam: personToDisplay.regimeFam,
        dateregimeFam: personToDisplay.dateregimeFam,
        donationEntreEpoux: personToDisplay.donationEntreEpoux,
      })
      setProfessions([...personToDisplay.professions])
      setRelations([...personToDisplay.relations])
      setFichiers([...personToDisplay.fichiers])
    } else {
      setPersonInfos(personInfos)
      setAdresseFiscale(adresseFiscale)
      setCoordonneesSupplementaires([])
      setBirth(birth)
      setFamily(family)
      setProfessions([])
      setRelations([])
      setFichiers([])
    }
  }, [personToDisplay])
  // update conjoint
  const handleUpdateConjoint = () => {
    const conjointToUpdate = listPersons.filter((personne) => personne.id === idConjoint)[0]
    const dataToSend = {
      ...conjointToUpdate,
      situationFam: situationFam,
      idConjoint: person.id,
      dateSituationFam: dateSituationFam,
      regimeFam: regimeFam,
      dateregimeFam: dateregimeFam,
      donationEntreEpoux: donationEntreEpoux,
    }
    dispatch(updatePP(dataToSend, setStatusUpdateConjoint))
  }
  // submit
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (nom && prenom && mail && dateNaissance) {
      idConjoint && updateConjoint && handleUpdateConjoint()
      if (!personToDisplay) {
        // create PP
        dispatch(postPP(data, setStatus))
      } else {
        // update PP
        dispatch(updatePP(data, setStatusUpdate))
      }
    } else {
      setModalNotCorrect(true)
    }
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (personToDisplay) {
      navigate('/user/informations')
    } else {
      navigate('/user/comptes')
    }
  }

  // response create PP status and modal
  useEffect(() => {
    if (status) {
      if (status < 301) {
        dispatch(getFormIsActive(null))
        dispatch(getSaveForm(false))
      } else if (status === 301) {
        dispatch(getFormIsActive(null))
        dispatch(getSaveForm(false))
        if (url && url !== '') {
          setModalCreateOk(true)
          window.open(url)
          dispatch(getMandatSuccess(null))
        }
      } else {
        setModalNotOk(true)
      }
      setStatus(null)
    }
  }, [status])
  // response update PP status and modal
  useEffect(() => {
    if (statusUpdate) {
      if (statusUpdate < 400) {
        setModalUpdateOk(true)
        dispatch(getFormIsActive(null))
        dispatch(getSaveForm(false))
        dispatch(getPersonnes())
        setStatusUpdate(null)
      }
      if (statusUpdate >= 400) {
        setModalUpdateNotOk(true)
        setStatusUpdate(null)
      }
    }
  }, [statusUpdate])
  // response update Conjoint
  useEffect(() => {
    if (statusUpdateConjoint >= 200 && statusUpdateConjoint < 300) {
      setStatusUpdateConjoint(null)
    }
    if (statusUpdateConjoint >= 400) {
      setStatusUpdateConjoint(null)
    }
  }, [statusUpdateConjoint])

  // close Modal Parapheur
  const closeModalCreateOk = () => {
    setModalCreateOk(false)
    navigate('/user/comptes')
    // setModalOk(true)
  }

  return (
    <>
      <PPComponent
        handleSubmit={handleSubmit}
        person={personToDisplay}
        personInfos={personInfos}
        setPersonInfos={setPersonInfos}
        adresseFiscale={adresseFiscale}
        setAdresseFiscale={setAdresseFiscale}
        birth={birth}
        setBirth={setBirth}
        family={family}
        setFamily={setFamily}
        professions={professions}
        setProfessions={setProfessions}
        relations={relations}
        setRelations={setRelations}
        fichiers={fichiers}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleGiveUp={handleGiveUp}
        setUpdateConjoint={setUpdateConjoint}
      />
      {/* modal response ok _____________________________________________________________*/}
      {modalCreateOk && <ModalParapheur closeModal={closeModalCreateOk} />}
      {/* modal response not ok _________________________________________________________*/}
      {modalNotOk && (
        <ModalCreatePPNotOK
          setModalNotOk={setModalNotOk}
          message={'Une personne existe déjà avec cette adresse mail'}
        />
      )}
      {/* modal response update ok ______________________________________________________*/}
      {modalUpdateOk && <ModalUpdatePPOk />}
      {/* modal response update not ok __________________________________________________*/}
      {modalUpdateNotOk && (
        <ModalUpdatePPNotOk
          setModalUpdateNotOk={setModalUpdateNotOk}
          message={'Une personne existe déjà avec cette adresse mail'}
        />
      )}
      {/* modal not correct __________________________________________________*/}
      {modalNotCorrect && <NotCorrect setNotCorrect={setModalNotCorrect} />}
    </>
  )
}

export default PP
