import React from 'react'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../Patrimoine/PatrimoineChartCardPrint'
import RevenusPieChart from './RevenusPieChart'

const RevenusToPrint = ({ elementToDisplay, values }) => {
  const { valeurTotaleRevenu } = elementToDisplay || {}
  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurTotaleRevenu}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<RevenusPieChart incomes={elementToDisplay} />}
        detenteurs={true}
      />
    </>
  )
}

export default RevenusToPrint
