import React from 'react'
import Card from '../../../../Components/Card'
import SelectComponent from '../../../../Components/SelectComponent'
import Input from '../../../../Components/Input'
import { optionsTypePeriodicite } from '../../../../../../data/options/options'
import { optionsPER } from '../../../../../../data/options/optionsSocial'
import DateInputComponent from '../../../../Components/DateInputComponent'

const PERAddProductPageContent = ({
  handleChange,
  handleChangeDate,
  handleChangeFrais,
  optionsProducts,
  information,
  histoFrais,
}) => {
  const { libelle, fournisseur, categorie, dateOuverture } = information || {}
  const {
    annee,
    tauxFraisGestion,
    tauxDroitGarde,
    periodiciteFraisGestion,
    periodiciteDroitGarde,
  } = histoFrais || {}

  return (
    <>
      <div className="cards cards--fullWidth">
        <Card title="Choisir un produit" h5={true} open={true}>
          <div className="informations">
            {/* product */}
            <SelectComponent
              forAndId="libelle"
              errorLabel={libelle === ''}
              name="Sélectionner un produit dans le catalogue :"
              isSearchable={true}
              isClearable={true}
              options={optionsProducts}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={optionsProducts && optionsProducts.find((c) => c.label === libelle)}
              copy={true}
              required={true}
            />
          </div>
        </Card>
      </div>

      {libelle ? (
        <div className="cards">
          <Card title="Informations" h5={true} open={true}>
            <div className="informations">
              <div className="informations informations--borderFull">
                <Input
                  forAndId="etablissement"
                  type="text"
                  name="Fournisseur :"
                  value={fournisseur}
                  copy={true}
                  disabled={true}
                />
                <Input
                  forAndId="libelle"
                  type="text"
                  name="Produit :"
                  value={libelle}
                  copy={true}
                  disabled={true}
                />
                {/* categorie */}
                <SelectComponent
                  forAndId="categorie"
                  errorLabel={categorie === ''}
                  name="Catégorie :"
                  isSearchable={true}
                  isClearable={true}
                  options={optionsPER}
                  placeholder="Sélectionner"
                  onChange={(e, name) => handleChange(e, name)}
                  value={optionsPER && optionsPER.find((c) => c.label === categorie)}
                  copy={true}
                  required={true}
                />
                {/* Date d'ouverture */}
                <DateInputComponent
                  name={'dateOuverture'}
                  label={"Date d'ouverture :"}
                  value={dateOuverture}
                  handleChange={handleChangeDate}
                />
              </div>
            </div>
          </Card>
          <Card title="Frais" h5={true} open={true}>
            <div className="informations">
              <div className="informations">
                {/* annee */}
                <Input
                  forAndId="annee"
                  type="number"
                  name="Année de référence :"
                  value={annee}
                  onChange={(e) => handleChangeFrais(e)}
                  copy={true}
                />
                {/* tauxFraisGestion */}
                <Input
                  forAndId="tauxFraisGestion"
                  type="text"
                  name="Frais de gestion (%) :"
                  value={tauxFraisGestion}
                  onChange={(e) => handleChangeFrais(e)}
                  copy={true}
                />
                {/* periodiciteFraisGestion */}
                <SelectComponent
                  forAndId="periodiciteFraisGestion"
                  name="Periodicité FG :"
                  isSearchable={false}
                  options={optionsTypePeriodicite}
                  placeholder="Sélectionner"
                  onChange={(e, name) => handleChangeFrais(e, name)}
                  value={optionsTypePeriodicite.find((c) => c.value === periodiciteFraisGestion)}
                  copy={true}
                />
                {/* tauxDroitGarde */}
                <Input
                  forAndId="tauxDroitGarde"
                  type="text"
                  name="Droit de garde (%) :"
                  value={tauxDroitGarde}
                  onChange={(e) => handleChangeFrais(e)}
                  copy={true}
                />
                {/* periodiciteDroitGarde */}
                <SelectComponent
                  forAndId="periodiciteDroitGarde"
                  name="Periodicité DG :"
                  isSearchable={false}
                  options={optionsTypePeriodicite}
                  placeholder="Sélectionner"
                  onChange={(e, name) => handleChangeFrais(e, name)}
                  value={optionsTypePeriodicite.find((c) => c.value === periodiciteDroitGarde)}
                  copy={true}
                />
              </div>
            </div>
          </Card>
        </div>
      ) : null}
    </>
  )
}

export default PERAddProductPageContent
