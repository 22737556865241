import React from 'react'
import { shortFormeJuridique } from '../../../services/helpers/helpers'

const BodyPM = ({ element, handleShow }) => {
  return (
    <>
      {/* denominationSociale */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {!element.denominationSociale ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.denominationSociale
        )}
      </td>
      {/* formeJuridique */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {!element.formeJuridique ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          shortFormeJuridique(element.formeJuridique)
        )}
      </td>
      {/* dirigeant */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {!element.dirigeant ? <p className="informations__line__notxt">-</p> : element.dirigeant}
      </td>
    </>
  )
}

export default BodyPM
