export const optionsDetailsChgG = [
  { value: "Dépense d'usage", label: "Dépense d'usage" },
  { value: 'Dépenses de train de vie', label: 'Dépenses de train de vie' },
  { value: 'Cotisations, assurances', label: 'Cotisations, assurances' },
]
export const optionsDetailsChgP = [
  {
    value: 'Pension alimentaire versée à des enfants mineurs',
    label: 'Pension alimentaire versée à des enfants mineurs',
  },
  {
    value: 'Pension alimentaire versée à des enfants majeurs',
    label: 'Pension alimentaire versée à des enfants majeurs',
  },
  { value: 'Autres pensions alimentaires', label: 'Autres pensions alimentaires' },
]
export const optionsDetailsChgED = [
  { value: "Remboursement d'emprunt", label: "Remboursement d'emprunt" },
  { value: 'Remboursement de dette', label: 'Remboursement de dette' },
]
export const optionsDetailsChgIT = [
  { value: 'Impôt sur le revenu', label: 'Impôt sur le revenu' },
  {
    value: 'IFI (Impôt sur la Fortune Immobilière)',
    label: 'IFI (Impôt sur la Fortune Immobilière)',
  },
  { value: 'Taxe', label: 'Taxe' },
]
