import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdUpdate, MdUpdateDisabled } from 'react-icons/md'
import { ImEye } from 'react-icons/im'
import { FaEyeSlash } from 'react-icons/fa'
import { TiDelete } from 'react-icons/ti'
import { getFile } from '../../../services/helpers/files'
import { sortByType } from '../../../services/helpers/sort'
import { DOC_PERSON_PATRIMOINE } from '../../../constants'
import BodyDoc from './BodyDoc'
import TableRowNumber from '../table/TableRowNumber'
import BodyDocPrint from './BodyDocPrint'
import { recupLabelComplexWithOptions } from '../../../services/helpers/helpers'
import { optionsTypeGedPatrimoine } from '../../../data/options/optionsFiles'

const TableBodyDoc = ({
  setNewData,
  setModalUpdate,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  setDataChange,
  setShowDoc,
  tableType,
  read,
  print,
}) => {
  const dispatch = useDispatch()
  // person type
  const type = useSelector((state) => state.postPersonType.type)
  const [selectedRow, setSelectedRow] = useState(null)
  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index)
  }

  // modif
  const handleEdit = (element) => {
    setDataChange(true)
    setModalUpdate(true)
    setNewData(element)
  }
  // voir
  const handleShow = (element) => {
    dispatch(getFile(element))
    setShowDoc(true)
  }
  // suppr
  const handleDelete = (element) => {
    setNewData(element)
    setModalDelete(true)
  }
  // selectTypeGed
  const selectTypeGed = (el) => {
    const str = el.typeGed
    const typeGed = str.includes('-') ? str.slice(0, str.indexOf('-')) : str
    return el.typeFichierGED === 'PATRIMOINE'
      ? recupLabelComplexWithOptions(typeGed, optionsTypeGedPatrimoine)
      : typeGed
  }
  // selectFileName
  const selectFileName = (el) => {
    const str = el.typeGed
    const fileName = str.includes('-') ? str.slice(str.indexOf('-') + 1, str.length) : str
    return fileName
  }

  // tri croissant/decroissant
  const giveAType = (typeOrFunc) => {
    sortByType(sort, dataToDisplay, typeOrFunc, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Type':
        case 'Nom':
          giveAType(el =>selectTypeGed(el))
          break
        case 'Document':
          giveAType(el =>selectFileName(el))
          break
        case 'Date du document':
          giveAType('dateDocument')
          break
        case 'Date de validité':
          giveAType('dateValidite')
          break
        case 'Dernière mise à jour':
          giveAType('updatedAt')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return !print ? (
    <tbody className="tbody">
      {listToDisplay &&
        listToDisplay.map((element, index) => (
          <tr
            key={index}
            className={element.createdAt ? 'tbody__tr tbody__tr--valid' : 'tbody__tr'}
          >
            {/* numéro */}
            <TableRowNumber index={index} handleRowClick={() => handleRowClick(index)} />
            <BodyDoc element={element} handleShow={handleShow} tableType={tableType} />
            {/* Voir */}
            {element.id && element.id !== 0 ? (
              <td
                className="tbody__tr__td tbody__tr__td--pointer"
                onClick={() => handleShow(element)}
              >
                <div className="tbody__tr__td__button tbody__tr__td__button--blue">
                  <ImEye />
                </div>
              </td>
            ) : (
              <td className="tbody__tr__td">
                <div className="tbody__tr__td__button--grey">
                  <FaEyeSlash />
                </div>
              </td>
            )}
            {/* Modifier */}
            {!read && tableType !== DOC_PERSON_PATRIMOINE && type !== 'relationPP' ? (
              element.id && element.id !== 0 ? (
                <>
                  <td
                    className="tbody__tr__td tbody__tr__td--pointer"
                    onClick={() => handleEdit(element)}
                  >
                    <div className="tbody__tr__td__button">
                      <MdUpdate />
                    </div>
                  </td>
                  <td
                    className="tbody__tr__td tbody__tr__td--pointer"
                    onClick={() => handleDelete(element)}
                  >
                    <div className="tbody__tr__td__button">
                      <TiDelete />
                    </div>
                  </td>
                </>
              ) : (
                <>
                  <td className="tbody__tr__td">
                    <div className="tbody__tr__td__button--grey">
                      <MdUpdateDisabled />
                    </div>
                  </td>
                  <td className="tbody__tr__td">
                    <div className="tbody__tr__td__button--grey">
                      <TiDelete />
                    </div>
                  </td>
                </>
              )
            ) : null}
          </tr>
        ))}
    </tbody>
  ) : (
    listToDisplay &&
      listToDisplay.map((element, index) => (
        <div key={`data-${index}`} className="print__table__bloc">
          <BodyDocPrint element={element} tableType={tableType} index={index} />
        </div>
      ))
  )
}

export default TableBodyDoc
