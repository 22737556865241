import React, { useState } from 'react'
import useFormIsActive from '../../../../../layout/useFormIsActive'
import NavComponent from '../../../Patrimoine/NavComponent'
import SaveForm from '../../../../../layout/SaveForm'

const NavFinancier = () => {
  const formIsActive = useFormIsActive()
  // modales
  const [toggleSaveForm, setToggleSaveForm] = useState(false)
  // handleClick
  const handleClick = () => {
    formIsActive && setToggleSaveForm(true)
  }
  const links = [
    { name: 'Catalogue', link: '/user/patrimoine/actifs/financier/catalogue', type: 'financier' },
    {
      name: 'Valeurs Mobilières',
      link: '/user/patrimoine/actifs/financier/valeurs-mobilieres',
      type: 'financier',
    },
    {
      name: 'Assurances Vie / Capitalisation',
      link: '/user/patrimoine/actifs/financier/assurances-vie',
      type: 'financier',
    },
    {
      name: 'Produits Financiers',
      link: '/user/patrimoine/actifs/financier/produits-financiers',
      type: 'financier',
    },
  ]

  return (
    <>
      <nav className="navFinancier" onClick={() => handleClick()}>
        <NavComponent links={links} menu={'navFinancier'} />
      </nav>

      {toggleSaveForm && (
        <SaveForm toggleSaveForm={toggleSaveForm} setToggleSaveForm={setToggleSaveForm} />
      )}
    </>
  )
}

export default NavFinancier
