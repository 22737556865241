import React from 'react'
import Modale from '../Components/Modale'
import AddUser from './AddUser'

const ModalAddUser = ({ setModalAddUser, setModalNotCorrect, setStatus }) => {
  return (
    <Modale
      close={() => setModalAddUser(false)}
      title="Créer un user !"
    >
      <div className="informations">
        <AddUser setModalNotCorrect={setModalNotCorrect} setStatus={setStatus} />
      </div>
    </Modale>
  )
}

export default ModalAddUser
