import React from 'react'
import InputsSociete from '../Inputs/InputsSociete'

const Societe = ({ infosSociete, setInfosSociete, setSirenSearch, setDataChange }) => {
  const handleChange = (e) => {
    setDataChange(true)
    const { name, value } = e.target
    setInfosSociete({ ...infosSociete, [name]: value })
    if (name === 'siren') {
      setSirenSearch(value)
    }
  }

  return (
    <InputsSociete
      handleChange={handleChange}
      infosSociete={infosSociete}
      setInfosSociete={setInfosSociete}
      setDataChange={setDataChange}
    />
  )
}

export default Societe
