import React from 'react'
import { COLORS } from '../../../../../constants'
import PieChartComponent from '../../../Charts/PieChartComponent'
import LegendPieChart from '../../../Charts/LegendPieChart'

const ActifsPieChart = ({ properties, detenteurs, print }) => {
  const {
    actifsBienProfessionnel,
    actifsDivers,
    actifsAutres,
    actifsImmobilier,
    financiers,
    socials,
    valeurTotales,
    valeurTotaleDetenue,
  } = properties || {}
  // data
  const data = !detenteurs
    ? [
        {
          name: 'Immobilier',
          value: actifsImmobilier.valeurImmobiliers,
          fill: COLORS.colorRepartition01,
          color: 'repartition01',
        },
        {
          name: 'Professionnel',
          value: actifsBienProfessionnel.valeurBiensProfesionnel,
          fill: COLORS.colorRepartition02,
          color: 'repartition02',
        },
        {
          name: 'Financier',
          value: financiers.valeurTotaleFinancier,
          fill: COLORS.colorRepartition03,
          color: 'repartition03',
        },
        {
          name: 'Social',
          value: socials.valeurTotaleSociale,
          fill: COLORS.colorRepartition04,
          color: 'repartition04',
        },
        {
          name: 'Autre',
          value: actifsAutres.valeurAutresActifs,
          fill: COLORS.colorRepartition05,
          color: 'repartition05',
        },
        {
          name: 'Actif non affecté',
          value: actifsDivers.montant,
          fill: COLORS.colorFontLight,
          color: 'divers',
        },
      ]
    : [
        {
          name: "Détenu par l'utilisateur",
          value: valeurTotaleDetenue,
          fill: COLORS.colorSecondary,
          color: 'detenu',
        },
        {
          name: "Non détenu par l'utilisateur",
          value: valeurTotales - valeurTotaleDetenue - actifsDivers.montant,
          fill: COLORS.colorPrimary,
          color: 'nonDetenu',
        },
        {
          name: 'Actifs non affectés',
          value: actifsDivers.montant,
          fill: COLORS.colorFontLight,
          color: 'divers',
        },
      ]
  // title
  const title = !detenteurs ? 'Répartition' : 'Détention'
  return (
    <>
      <PieChartComponent data={data} title={title} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title} />
    </>
  )
}

export default ActifsPieChart
