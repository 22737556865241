import { beautifulDate } from '../../services/helpers/dates'
import { recupLabel } from '../../services/helpers/helpers'
import { optionsSexe } from '../options/optionsPP'

const recupConjoint = (id, array, link) => {
  const conjoint = array.filter((el) => el.id === id)[0]
  const objToDisplay = conjoint !== undefined ? `${conjoint.prenom} ${conjoint.nom}` : null
  return link ? conjoint : objToDisplay
}
// PP
export const dataIdentity = (person) => {
  return [
    {
      label: "Nom d'usage :",
      value: person.nom,
    },
    {
      label: 'Prénom :',
      value: person.prenom,
    },
    {
      label: 'Mail :',
      value: person.mailUsuel,
    },
    {
      label: 'Date de naissance :',
      value: person.dateNaissance ? beautifulDate(person.dateNaissance) : '',
    },
    {
      label: 'Sexe de naissance :',
      value: person.sexe ? recupLabel(person.sexe, optionsSexe) : '',
    },
    {
      label: 'Tel mobile :',
      value: person.telPortable ? person.telPortable : '',
    },
    {
      label: 'Tel fixe :',
      value: person.telFixe ? person.telFixe : '',
    },
  ]
}
export const dataFiscalAddress = (person) => {
  return [
    {
      label: 'Adresse :',
      value: person.adresseFiscale ? person.adresseFiscale.ad1 : '',
    },
    {
      label: 'Complément :',
      value: person.adresseFiscale ? person.adresseFiscale.ad2 : '',
    },
    {
      label: 'Code postal :',
      value: person.adresseFiscale ? person.adresseFiscale.codePostal : '',
    },
    {
      label: 'Ville :',
      value: person.adresseFiscale ? person.adresseFiscale.ville : '',
    },
    {
      label: 'Pays :',
      value: person.adresseFiscale ? person.adresseFiscale.pays : '',
    },
  ]
}
export const dataAddressSup = (data) => {
  return [
    {
      label: 'Type :',
      value: data ? data.type : '',
    },
    {
      label: 'Libellé :',
      value: data ? data.libelle : '',
    },
    {
      label: 'Code postal :',
      value: data ? data.cp : '',
    },
    {
      label: 'Ville :',
      value: data ? data.ville : '',
    },
  ]
}
export const dataBirth = (person) => {
  return [
    {
      label: 'Nom de naissance :',
      value: person.nomNaissance ? person.nomNaissance : '',
    },
    {
      label: 'Ville de naissance :',
      value: person.villeNaissance,
    },
    {
      label: 'Nationalité 1 :',
      value: person.nationnalite1 ? person.nationnalite1 : '',
    },
    {
      label: 'Nationalité 2 :',
      value: person.nationnalite2 ? person.nationnalite2 : '',
    },
    {
      label: 'Capacité légale :',
      value: person.capaciteLegale ? person.capaciteLegale : '',
    },
  ]
}
export const dataFamily = (person, listPPPersons) => {
  return [
    {
      label: 'Situation :',
      value: person.situationFam ? person.situationFam : '',
    },
    {
      label: 'En date du :',
      value: person.dateSituationFam ? beautifulDate(person.dateSituationFam) : '',
    },
    {
      label: 'Conjoint / partenaire :',
      value:
        person.idConjoint && person.idConjoint !== 0
          ? recupConjoint(person.idConjoint, listPPPersons, false)
          : '',
      conjoint:
        person.idConjoint && person.idConjoint !== 0
          ? recupConjoint(person.idConjoint, listPPPersons, true)
          : null,
    },
    {
      label: 'Régime :',
      value: person.regimeFam ? person.regimeFam : '',
    },
    {
      label: 'Date du contrat :',
      value: person.dateregimeFam ? beautifulDate(person.dateregimeFam) : '',
    },
    {
      label: 'Donation entre époux :',
      value: person.donationEntreEpoux === true ? 'oui' : 'non',
    },
  ]
}
export const dataProfessions = (data) => {
  return [
    {
      label: 'Profession :',
      value: data.profession,
    },
    {
      label: 'CSP :',
      value: data.familleCSP,
    },
    {
      label: 'Code CSP :',
      value: data.codeRubriqueCSP,
    },
    {
      label: 'Statut :',
      value: data.status,
    },
    {
      label: 'Date de début :',
      value: data.dateDebut,
    },
    {
      label: 'Date de fin :',
      value: data.dateFin,
    },
  ]
}
// PM
export const dataMainInfos = (person) => {
  return [
    {
      label: 'Dénomination sociale :',
      value: person.denominationSociale.toUpperCase(),
    },
    {
      label: 'Forme Juridique :',
      value: person.formeJuridique,
    },
    {
      label: 'Date de création :',
      value: beautifulDate(person.dateCreation),
    },
    {
      label: 'Mail :',
      value: person.mail,
    },
    {
      label: 'Nom du dirigeant:',
      value: person.dirigeant,
    },
    {
      label: 'Date de naissance du dirigeant :',
      value: beautifulDate(person.date_naissance_dirigeant),
    },
  ]
}
export const dataSociety = (person) => {
  return [
    {
      label: 'Activité :',
      value: person.activite,
    },
    {
      label: 'Capital :',
      value: person.capital,
    },
    {
      label: 'SIREN :',
      value: person.siren,
    },
    {
      label: 'Téléphone fixe :',
      value: person.telFixe,
    },
    {
      label: 'Téléphone Mobile:',
      value: person.telPortable,
    },
    {
      label: 'Adresse:',
      value: person.adresse1,
    },
    {
      label: 'Code Postal:',
      value: person.codePostal,
    },
    {
      label: 'Ville:',
      value: person.ville,
    },
  ]
}
