import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sortByType } from '../../../../../../services/helpers/sort'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyCatalogue from './BodyCatalogue'
import BodyCataloguePrint from './BodyCataloguePrint'

const TableBodyCatalogue = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // ouvrir infos bien
  const handleShow = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(
      getbienType({
        categorie:
          bien.categorie === 'PER' || bien.categorie === 'retraite' ? 'SOCIAL' : 'FINANCIER',
        type:
          bien.categorie === 'PER'
            ? 'catalogue-PER'
            : bien.categorie === 'retraite'
            ? 'catalogue-retraite'
            : bien.categorie === 'VM'
            ? 'catalogue-VM'
            : 'catalogue-AV',
      })
    )
    bien.categorie === 'PER' || bien.categorie === 'retraite'
      ? navigate('/user/patrimoine/actifs/social/catalogue/informations')
      : navigate('/user/patrimoine/actifs/financier/catalogue/informations')
  }
  // modifier bien
  const handleEdit = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(
      getbienType({
        categorie:
          bien.categorie === 'PER' || bien.categorie === 'retraite' ? 'SOCIAL' : 'FINANCIER',
        type:
          bien.categorie === 'PER'
            ? 'catalogue-PER'
            : bien.categorie === 'retraite'
            ? 'catalogue-retraite'
            : bien.categorie === 'VM'
            ? 'catalogue-VM'
            : 'catalogue-AV',
      })
    )
    bien.categorie === 'PER' || bien.categorie === 'retraite'
      ? navigate(`/user/patrimoine/actifs/social/catalogue/formulaire/${bien.categorie}`)
      : navigate(`/user/patrimoine/actifs/financier/catalogue/formulaire/${bien.categorie}`)
  }
  // supprimer bien
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Libellé':
          giveAType('libelle')
          break
        case 'Fournisseur':
          giveAType('etablissement')
          break
        case 'Supports':
          giveAType('dicis.length')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyCataloguePrint /> : <BodyCatalogue handleShow={handleShow} />}
      print={print}
      handleShow={handleShow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  )
}

export default TableBodyCatalogue
