import React from 'react'
import Card from '../../../../Components/Card'
import BlocTable from '../../../../table/BlocTable'
import GeneralInformationCardBienPro from './GeneralInformationCardBienPro'
import SocietyInformationCardBienPro from './SocietyInformationCardBienPro'
import PartsInformationsCardBienPro from './PartsInformationsCardBienPro'
import SaleInformationsCard from '../../Actifs/SaleInformationsCard'
import DetentionCard from '../../../Detention/DetentionCard'
import FormSubmit from '../../../../Components/FormSubmit'
import { DOC_BIEN_PRO, TITLES_DOC_UPDATE } from '../../../../../../constants'
import TableBodyDoc from '../../../../Files/TableBodyDoc'
import PMLinkCard from './PMLinkCard'

const FormPatrimoineProContent = ({
  bienToDisplay,
  dataChange,
  setDataChange,
  handleChange,
  handleChangeDate,
  information,
  setInformation,
  detenteursToDisplay,
  setDetenteursToDisplay,
  fichiersToDisplay,
  setFichiersToDisplay,
  setToggleAddPatrimoine,
  handleSubmit,
  newValeurTotale,
  handleGiveUp,
}) => {
  return (
    <>
      {/* Envoi du formulaire */}
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handleSubmit}
        handleGiveUp={handleGiveUp}
        down={false}
      />
      {/* form */}
      <div className="sousSection">
        <div className="cards cards--fullWidth">
          <PMLinkCard
            information={information}
            setInformation={setInformation}
            bienToDisplay={bienToDisplay}
            setDataChange={setDataChange}
          />
        </div>
        <div className="cards">
          <GeneralInformationCardBienPro
            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            information={information}
          />
          <SocietyInformationCardBienPro
            handleChange={handleChange}
            information={information}
            setInformation={setInformation}
            setDataChange={setDataChange}
          />
        </div>
        <div className="cards">
          <PartsInformationsCardBienPro handleChange={handleChange} information={information} />
          <SaleInformationsCard
            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            information={information}
          />
        </div>
        <div className={bienToDisplay ? 'cards' : 'cards cards--fullWidth'}>
          <DetentionCard
            information={information}
            setInformation={setInformation}
            detenteursToDisplay={detenteursToDisplay}
            setDetenteursToDisplay={setDetenteursToDisplay}
            newValeurTotale={newValeurTotale}
            setDataChange={setDataChange}
            showUS={true}
          />
          {bienToDisplay && fichiersToDisplay && (
            <Card title={`Documents (${fichiersToDisplay.length})`} h4={true} open={true}>
              <div className="informations">
                <BlocTable
                  titles={TITLES_DOC_UPDATE}
                  data={fichiersToDisplay}
                  setData={setFichiersToDisplay}
                  tableType={DOC_BIEN_PRO}
                  children={<TableBodyDoc />}
                  setDataChange={setDataChange}
                  doc={true}
                  title={`Documents (${fichiersToDisplay.length})`}
                  handleAdd={() => setToggleAddPatrimoine(true)}
                />
              </div>
            </Card>
          )}
        </div>
      </div>
      {/* Envoi du formulaire */}
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handleSubmit}
        handleGiveUp={handleGiveUp}
        down={true}
      />
    </>
  )
}

export default FormPatrimoineProContent
