import React from 'react'
import { recupLabel } from '../../../../services/helpers/helpers'
import { optionsTypeRelation } from '../../../../data/options/options'
import { useSelector } from 'react-redux'

const BodyRelation = ({ element, handleShow }) => {
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  const listPersons = persons.referentPP.concat(persons.relationPP)

  const getGoodPerson = (id) => {
    const personne = listPersons.filter((person) => person.id === id)[0]
    if (personne !== undefined) {
      const { nom, prenom } = personne || {}
      return { nom, prenom }
    } else {
      return { nom: '-', prenom: '-' }
    }
  }

  return (
    <>
      {/* idPP2 */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {!element.idPP2 ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${getGoodPerson(element.idPP2).nom} ${getGoodPerson(element.idPP2).prenom}`
        )}
      </td>
      {/* typeRelation */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {!element.typeRelation ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${recupLabel(element.typeRelation, optionsTypeRelation)}`
        )}
      </td>
      {/* acharge */}
      <td className="tbody__tr__td" data-label="A charge" onClick={() => handleShow(element)}>
        {element.acharge === '' ? (
          <p className="informations__line__notxt">-</p>
        ) : element.acharge === true ? (
          'oui'
        ) : (
          'non'
        )}
      </td>
    </>
  )
}

export default BodyRelation
