import React from 'react'
import { Outlet } from 'react-router-dom'
import ResponsiveNavComponent from './ResponsiveNavComponent'

const PatrimoineRevenu = () => {
  const links = [
    { name: 'Foncier', link: '/user/patrimoine/revenus/foncier', type: 'revenu' },
    { name: 'Mobilier', link: '/user/patrimoine/revenus/mobilier', type: 'revenu' },
    { name: 'Professionnel', link: '/user/patrimoine/revenus/professionnel', type: 'revenu' },
    { name: 'Pension / Rente', link: '/user/patrimoine/revenus/pension-rente', type: 'revenu' },
    { name: 'Autre', link: '/user/patrimoine/revenus/autres', type: 'revenu' },
  ]

  return (
    <>
      <ResponsiveNavComponent links={links} type={'revenu'}  />
      <Outlet />
    </>
  )
}

export default PatrimoineRevenu
