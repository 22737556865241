import { beautifulDate } from '../../services/helpers/dates'
import { recupLabel } from '../../services/helpers/helpers'
import { recupPM } from '../../services/API/personnes'
import {
  optionsNatureBienPro,
  optionsRisque,
  optionsTypeImmo,
  optionsTypePro,
} from '../options/optionsActifs'
import { optionsDetention } from '../options/options'

// valeur
export const dataActifsValues = (bien) => {
  const { actifsDivers, valeurTotales } = bien
  return [
    {
      label: 'Valeur totale des actifs',
      value: `${
        valeurTotales && valeurTotales.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur actif divers',
      value: `${
        actifsDivers && actifsDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur actifs affectés',
      value: `${(
        valeurTotales &&
        actifsDivers &&
        valeurTotales - actifsDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataActifsImmoValues = (bien) => {
  const { actifsImmobilier } = bien
  const { valeurImmobiliers, bienDivers } = actifsImmobilier
  return [
    {
      label: 'Valeur totale des biens immobiliers',
      value: `${
        valeurImmobiliers &&
        valeurImmobiliers.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur actif immobilier divers',
      value: `${
        bienDivers && bienDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur actif immobilier affecté',
      value: `${(
        valeurImmobiliers &&
        bienDivers &&
        valeurImmobiliers - bienDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataActifsProValues = (bien) => {
  const { actifsBienProfessionnel } = bien
  const { valeurBiensProfesionnel, bienProDivers } = actifsBienProfessionnel
  return [
    {
      label: 'Valeur totale des biens professionnels',
      value: `${
        valeurBiensProfesionnel &&
        valeurBiensProfesionnel.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur actif professionnel divers',
      value: `${
        bienProDivers &&
        bienProDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur actif professionnel affecté',
      value: `${(
        valeurBiensProfesionnel &&
        bienProDivers &&
        valeurBiensProfesionnel - bienProDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataActifsAutreValues = (bien) => {
  const { actifsAutres } = bien
  const { valeurAutresActifs, autreActifDivers } = actifsAutres

  return [
    {
      label: 'Valeur totale des autres biens',
      value: `${
        valeurAutresActifs &&
        valeurAutresActifs.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur actifs autres divers',
      value: `${
        autreActifDivers &&
        autreActifDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur actifs autres affectés',
      value: `${(
        valeurAutresActifs &&
        autreActifDivers &&
        valeurAutresActifs - autreActifDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
// aquisition
export const dataBienAquisition = (bien) => {
  return [
    {
      label: "Valeur d'acquisition :",
      value:
        bien.valeurAcquisition && bien.valeurAcquisition !== 0
          ? `${bien.valeurAcquisition.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
          : null,
    },
    {
      label: 'Date de création / acquisition :',
      value: beautifulDate(bien.dateCreation),
    },
    bien.closOuVendu
      ? {
          label: 'Valeur vente :',
          value:
            bien.valeurVente && bien.valeurVente !== 0
              ? `${bien.valeurVente.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
              : null,
        }
      : null,
    bien.closOuVendu
      ? {
          label: 'Date de vente / cloture :',
          value: beautifulDate(bien.dateVente),
        }
      : null,
  ]
}
// bien Immo
export const dataBienImmoGeneral = (bien) => {
  return [
    {
      label: 'Libellé :',
      value: bien.nom,
    },
    {
      label: 'Catégorie :',
      value: bien.typeImmobilier && recupLabel(bien.typeImmobilier, optionsTypeImmo),
    },
    {
      label: 'Sous-catégorie :',
      value: bien.typeBien && bien.typeBien,
    },
    {
      label: 'Régime fiscal :',
      value: bien.dispositif && bien.dispositif,
    },
    {
      label: 'Valeur estimée :',
      value:
        bien.valeur && `${bien.valeur.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
    {
      label: "Date de l'estimation :",
      value: beautifulDate(bien.dateValeur),
    },
    {
      label: 'Niveau de risque :',
      value: bien.risque && recupLabel(bien.risque, optionsRisque),
    },
    {
      label: 'Code Impot :',
      value: bien.codeImpot,
    },
    {
      label: 'Détention :',
      value: bien.detention && recupLabel(bien.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${bien.tauxIndivision} %`,
    },
  ]
}
export const dataBienImmoAdditionnal = (bien) => {
  return [
    {
      label: 'Adresse :',
      value: bien.adresse,
    },
    {
      label: 'Code postal :',
      value: bien.codePostal,
    },
    {
      label: 'Ville :',
      value: bien.ville,
    },
    {
      label: 'Surface :',
      value:
        bien.surface && bien.surface !== 0
          ? `${bien.surface.toLocaleString(undefined, { minimumFractionDigits: 4 })} m²`
          : null,
    },
  ]
}
// bien Pro
export const dataBienProGeneral = (bien, categorie, properties, pmList) => {
  return [
    {
      label: 'Libellé :',
      value: bien.nom,
    },
    {
      label: 'Catégorie :',
      value: bien.typeBienPro && recupLabel(bien.typeBienPro, optionsTypePro),
    },
    {
      label: 'Nature :',
      value: bien.nature && recupLabel(bien.nature, optionsNatureBienPro),
    },
    {
      label: 'Valeur estimée :',
      value:
        bien.valeur !== null &&
        `${bien.valeur.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
    {
      label: "Date de l'estimation :",
      value: beautifulDate(bien.dateValeur),
    },
    {
      label: 'Niveau de risque :',
      value: bien.risque && recupLabel(bien.risque, optionsRisque),
    },
    {
      label: 'Code Impot :',
      value: bien.codeImpot,
    },
    {
      label: 'Personne morale :',
      value: bien.lienPM ? recupPM(bien.idPM, pmList).denominationSociale : null,
      personne: bien.lienPM ? recupPM(bien.idPM, pmList) : null,
    },
    {
      label: 'Détention :',
      value: bien.detention && recupLabel(bien.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${bien.tauxIndivision} %`,
    },
  ]
}
export const dataBienProSociete = (bien) => {
  return [
    {
      label: 'Dénomination sociale :',
      value: bien.denominationSociale.toUpperCase(),
    },
    {
      label: 'Forme Juridique :',
      value: bien.formeJuridique,
    },
    {
      label: 'Siret :',
      value: bien.siret,
    },
    {
      label: 'Adresse :',
      value: bien.adresse,
    },
    {
      label: 'Code postal :',
      value: bien.codePostal,
    },
    {
      label: 'Ville :',
      value: bien.ville,
    },
  ]
}
export const dataBienProParts = (bien) => {
  return [
    {
      label: 'Nombre total de parts :',
      value: bien.nbPartsTotales && bien.nbPartsTotales !== 0 ? bien.nbPartsTotales : null,
    },
    {
      label: "Montant d'une part :",
      value:
        bien.montantPart && bien.montantPart !== 0
          ? `${bien.montantPart.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
          : null,
    },
    {
      label: 'Capital :',
      value:
        bien.montantCapitalSocial && bien.montantCapitalSocial !== 0
          ? `${bien.montantCapitalSocial.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
          : null,
    },
    {
      label: 'Nombre de parts détenues :',
      value: bien.nbPartsDetenus && bien.nbPartsDetenus !== 0 ? bien.nbPartsDetenus : null,
    },
    {
      label: 'Montant détenu :',
      value:
        bien.montantDetenu && bien.montantDetenu !== 0
          ? `${bien.montantDetenu.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
          : null,
    },
  ]
}
// bien autre
export const dataBienAutre = (bien) => {
  return [
    {
      label: 'Libellé :',
      value: bien.nom,
    },
    {
      label: 'Valeur estimée :',
      value:
        bien.valeur && `${bien.valeur.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
    {
      label: "Date de l'estimation :",
      value: beautifulDate(bien.dateValeur),
    },
    {
      label: 'Code Impot :',
      value: bien.codeImpot,
    },
    {
      label: 'Détention :',
      value: bien.detention && recupLabel(bien.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${bien.tauxIndivision} %`,
    },
  ]
}
