import React from 'react'
import { stringToCapitalize } from '../../../../../../services/helpers/strings'
import { FE } from '../../../../../../constants'
import { beautifulDate } from '../../../../../../services/helpers/dates'
import Copy from '../../../../Components/Copy'

const BodyOperations = ({ element }) => {
  return (
    <>
      {/* nom */}
      <td
        className={!element.neutralise ? 'tbody__tr__td bold' : 'tbody__tr__td tbody__tr__td--grey'}
      >
        {!element.nom ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          <div className="tbody__tr__td--divCopy">
            <Copy value={element.nom} little={true} />
            {stringToCapitalize(element.nom)}
          </div>
        )}
      </td>
      {/* isin */}
      <td className={!element.neutralise ? 'tbody__tr__td' : 'tbody__tr__td tbody__tr__td--grey'}>
        {!element.isin ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          <div className="tbody__tr__td--divCopy">
            <Copy value={element.isin} little={true} />
            {element.isin}
          </div>
        )}
      </td>
      {/* nature */}
      <td
        className={!element.neutralise ? 'tbody__tr__td bold' : 'tbody__tr__td tbody__tr__td--grey'}
      >
        {!element.montant ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${
            element.majRegularisation
              ? 'Régularisation'
              : element.support.dici && element.support.dici.c_nom !== FE
              ? element.achat
                ? 'Achat'
                : element.cession
                ? 'Cession totale'
                : 'Vente'
              : element.achat
              ? 'Versement'
              : element.cession
              ? 'Retrait total'
              : 'Retrait'
          }`
        )}
      </td>
      {/* montant */}
      <td
        className={!element.neutralise ? 'tbody__tr__td bold' : 'tbody__tr__td tbody__tr__td--grey'}
      >
        {!element.montant ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.montant.toLocaleString(undefined, {
            minimumFractionDigits: 4,
          })} €`
        )}
      </td>
      {(element.support.dici && element.support.dici.c_nom !== FE) ||
      (!element.support.dici && element.support.dateDernierCours) ? (
        <>
          {/* support.nombre */}
          <td
            className={
              !element.neutralise ? 'tbody__tr__td bold' : 'tbody__tr__td tbody__tr__td--grey'
            }
          >
            {!element.nombre
              ? (Math.abs(element.montant) / element.valeurAchat).toLocaleString(undefined, {
                  minimumFractionDigits: 4,
                })
              : element.nombre.toLocaleString(undefined, {
                  minimumFractionDigits: 4,
                })}
          </td>
          {/* support.valeurAchat */}
          <td
            className={!element.neutralise ? 'tbody__tr__td' : 'tbody__tr__td tbody__tr__td--grey'}
          >
            {!element.valeurAchat ? (
              <p className="informations__line__notxt">-</p>
            ) : (
              `${Math.abs(element.valeurAchat).toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`
            )}
          </td>
          {/* support.dateAchat */}
          <td
            className={!element.neutralise ? 'tbody__tr__td' : 'tbody__tr__td tbody__tr__td--grey'}
          >
            {!element.support.dateAchat ? (
              <p className="informations__line__notxt">-</p>
            ) : (
              beautifulDate(element.support.dateAchat)
            )}
          </td>
        </>
      ) : (
        <td
          className={
            !element.neutralise ? 'tbody__tr__td bold' : 'tbody__tr__td tbody__tr__td--grey'
          }
          colSpan={3}
        >{`- Fond euro -`}</td>
      )}
      {/* dateOperation || dateVersement */}
      <td className={!element.neutralise ? 'tbody__tr__td' : 'tbody__tr__td tbody__tr__td--grey'}>
        {!element.dateOperation && !element.dateVersement ? (
          <p className="informations__line__notxt">-</p>
        ) : element.dateOperation ? (
          beautifulDate(element.dateOperation)
        ) : (
          beautifulDate(element.dateVersement)
        )}
      </td>
    </>
  )
}

export default BodyOperations
