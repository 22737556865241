import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import HeaderHomepage from '../../layout/HeaderHomepage'
import { useDispatch } from 'react-redux'
import { valideCompte } from '../../../services/API/connexion'
import Modale from '../Components/Modale'

const ValideCompte = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [status, setStatus] = useState(null)
  const [text, setText] = useState('')
  const [actif, setActif] = useState(false)

  let { id } = useParams()

  useEffect(() => {
    dispatch(valideCompte(id, setStatus))
  }, [])

  // response status and modal
  useEffect(() => {
    if (status >= 200 && status <= 300) {
      setText('ACTIF')
      setActif(true)
    }
    if (status >= 400) {
      setText('NON ACTIF')
      setActif(false)
    }
  }, [status])

  return (
    <div className="homepage">
      <HeaderHomepage />
      <main className="sign__main bkg">
        <Modale
          close={() => navigate('/')}
          sign={true}
          title="Activation de compte"
          line1={`Votre compte est ${text}`}
          line2={!actif && 'Consultez votre boite mail ou contactez votre administrateur'}
        >
          {actif ? (
            <div className="informations">
              <button
                type="submit"
                className="button button--red-red"
                onClick={() => navigate('/connexion')}
              >
                Se connecter
              </button>
              {/* {!actif && (
              <div className="informations__blocTxt">
                <p>Consultez votre boite mail ou contactez votre administrateur</p>
              </div>
            )} */}
            </div>
          ):null}
        </Modale>
      </main>
    </div>
  )
}

export default ValideCompte
