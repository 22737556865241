import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../assets/cap-mep-logo-couleur.webp'

const HeaderHomepage = () => {
  return (
    <header>
      <Link to="/">
        <img src={logo} alt="logo Mon Espace Patrimoine" className="logo" width={128} />
      </Link>
    </header>
  )
}

export default HeaderHomepage
