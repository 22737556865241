import React, { useEffect, useState } from 'react'
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs'
import useMediaQueries from '../../layout/useMediaQueries'

const Card = ({ title, children, open, h2, h3, h4, h5, h6, valueCard, print }) => {
  const [toggle, setToggle] = useState(true)
  // viewport width
  const isMobile = useMediaQueries(600)

  useEffect(() => {
    open
      ? isMobile
        ? setToggle(false)
        : setToggle(true)
      : valueCard
      ? isMobile
        ? setToggle(false)
        : setToggle(true)
      : setToggle(false)
  }, [open, isMobile])

  return (
    <article className={print ? 'print__card' : 'card'}>
      {valueCard ? (
        toggle ? null : (
          <div className="card__title card__title--dropdown" onClick={() => setToggle(!toggle)}>
            <span className="chevron">
              {!toggle ? <BsChevronCompactDown /> : <BsChevronCompactUp />}
            </span>
            {h2 ? (
              <h2>{title}</h2>
            ) : h3 ? (
              <h3>{title}</h3>
            ) : h4 ? (
              <h4>{title}</h4>
            ) : h5 ? (
              <h5>{title}</h5>
            ) : h6 ? (
              <h6>{title}</h6>
            ) : (
              <p>{title}</p>
            )}
            <span className="chevron">
              {!toggle ? <BsChevronCompactDown /> : <BsChevronCompactUp />}
            </span>
          </div>
        )
      ) : (
        <div className="card__title card__title--dropdown" onClick={() => setToggle(!toggle)}>
          {!print ? (
            <span className="chevron">
              {!toggle ? <BsChevronCompactDown /> : <BsChevronCompactUp />}
            </span>
          ) : null}
          {h2 ? (
            <h2>{title}</h2>
          ) : h3 ? (
            <h3>{title}</h3>
          ) : h4 ? (
            <h4>{title}</h4>
          ) : h5 ? (
            <h5>{title}</h5>
          ) : h6 ? (
            <h6>{title}</h6>
          ) : (
            <p>{title}</p>
          )}
          {!print ? (
            <span className="chevron">
              {!toggle ? <BsChevronCompactDown /> : <BsChevronCompactUp />}
            </span>
          ) : null}
        </div>
      )}
      {toggle ? (
        <div
          className={
            valueCard
              ? 'card__content card__content--value'
              : print
              ? 'card__content card__content--print'
              : 'card__content card__content--dropdown'
          }
          onClick={() => (valueCard ? isMobile ?setToggle(false): setToggle(true) : setToggle(true))}
        >
          {children ? (
            <div className="card__content__container">
              {children.length
                ? children.map((child, index) => (child ? <div key={index}>{child}</div> : null))
                : children}
            </div>
          ) : null}
        </div>
      ) : null}
    </article>
  )
}

export default Card
