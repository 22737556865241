import React from 'react'
import Title from '../../layout/Title'

const MyAdvisor = () => {
  return (
    <section className="section">
      <Title title='Mon conseiller' />
    </section>
  )
}

export default MyAdvisor
