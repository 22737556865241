import React, { useEffect, useState } from 'react'
import Input from '../../Components/Input'
import DateInputComponent from '../../Components/DateInputComponent'

const InputsBeneficiaire = ({
  handleChange,
  handleAddBeneficiaire,
  newBeneficiaire,
  handleChangeDate,
}) => {
  const {
    nom,
    prenom,
    dateDeNaissanceFormatee,
    date_greffe,
    pourcentage_parts,
    pourcentagePleinePropriete,
    pourcentageNuePropriete,
  } = newBeneficiaire || {}
  const [change, setChange] = useState(false)
  useEffect(() => {
    if (nom !== undefined && nom !== '') {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [nom])

  return (
    <div className="informations">
      {/* Nom */}
      <Input
        forAndId="nom"
        type="text"
        name="Nom :"
        value={nom}
        onChange={(e) => handleChange(e)}
        placeholder="Nom"
        copy={true}
      />
      {/* Prénom */}
      <Input
        forAndId="prenom"
        type="text"
        name="Prénom :"
        value={prenom}
        onChange={(e) => handleChange(e)}
        placeholder="Prénom"
        copy={true}
      />
      {/* Date de naissance */}
      <DateInputComponent
        name={'dateDeNaissanceFormatee'}
        label={'Date de naissance :'}
        value={dateDeNaissanceFormatee}
        handleChange={handleChangeDate}
      />
      {/* Date greffe */}
      <DateInputComponent
        name={'date_greffe'}
        label={'Date greffe :'}
        value={date_greffe}
        handleChange={handleChangeDate}
      />
      {/* pourcentage_parts */}
      <Input
        forAndId="pourcentage_parts"
        type="number"
        name="Pourcentage parts :"
        value={pourcentage_parts}
        onChange={(e) => handleChange(e)}
        copy={true}
      />
      {/* pourcentagePleinePropriete */}
      <Input
        forAndId="pourcentagePleinePropriete"
        type="text"
        name="Pourcentage pleine proprieté :"
        value={pourcentagePleinePropriete}
        onChange={(e) => handleChange(e)}
        copy={true}
      />
      {/* pourcentageNuePropriete */}
      <Input
        forAndId="pourcentageNuePropriete"
        type="text"
        name="Pourcentage nue proprieté :"
        value={pourcentageNuePropriete}
        onChange={(e) => handleChange(e)}
        copy={true}
      />
      {change ? (
        <div className="informations__buttons">
          <div
            className="button button--red-red  width--18"
            onClick={() => handleAddBeneficiaire()}
          >
            Enregistrer
          </div>
        </div>
      ) : (
        <div className="informations__buttons">
          <div className="button button--grey width--12">Enregistrer</div>
        </div>
      )}
    </div>
  )
}

export default InputsBeneficiaire
