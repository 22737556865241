import React from 'react'
import { useSelector } from 'react-redux'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import { optionsTypePeriodicite } from '../../../../../../data/options/options'

const BodyFrais = ({ element }) => {
  const bienType = useSelector((state) => state.getBienType.type)
  const type = bienType.type
  return (
    <>
      {/* annee */}
      <td className="tbody__tr__td">
        {!element.annee ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.annee
        )}
      </td>
      {type !== 'produitFinancierAV' && type !== 'retraite' ? (
        <>
          {/* tauxFraisGestion */}
          <td className="tbody__tr__td">
            {!element.tauxFraisGestion ? (
              <p className="informations__line__notxt">-</p>
            ) : (
              `${element.tauxFraisGestion} %`
            )}
          </td>
          {/* periodiciteFraisGestion */}
          <td className="tbody__tr__td">
            {!element.periodiciteFraisGestion ? (
              <p className="informations__line__notxt">-</p>
            ) : (
              recupLabel(element.periodiciteFraisGestion, optionsTypePeriodicite)
            )}
          </td>
          {/* tauxDroitGarde */}
          <td className="tbody__tr__td">
            {!element.tauxDroitGarde ? (
              <p className="informations__line__notxt">-</p>
            ) : (
              `${element.tauxDroitGarde} %`
            )}
          </td>
          {/* periodiciteDroitGarde */}
          <td className="tbody__tr__td">
            {!element.periodiciteDroitGarde ? (
              <p className="informations__line__notxt">-</p>
            ) : (
              recupLabel(element.periodiciteDroitGarde, optionsTypePeriodicite)
            )}
          </td>
        </>
      ) : (
        <>
          {/* tauxPerformanceFE */}
          <td className="tbody__tr__td">
            {!element.tauxPerformanceFE ? (
              <p className="informations__line__notxt">-</p>
            ) : (
              `${element.tauxPerformanceFE} %`
            )}
          </td>
        </>
      )}
    </>
  )
}

export default BodyFrais
