import React from 'react'
import { DOC_PERSON_DIVERS, DOC_PERSON_IDENTITY } from '../../../constants'
import { recupLabelComplexWithOptions } from '../../../services/helpers/helpers'
import { optionsTypeGedPatrimoine } from '../../../data/options/optionsFiles'
import { dateFormateComplete } from '../../../services/helpers/dates'

const BodyDocPrint = ({ element, tableType, index }) => {
  // selectTypeGed
  const selectTypeGed = (el) => {
    const str = el.typeGed
    const typeGed = str.includes('-') ? str.slice(0, str.indexOf('-')) : str
    return el.typeFichierGED === 'PATRIMOINE'
      ? recupLabelComplexWithOptions(typeGed, optionsTypeGedPatrimoine)
      : typeGed
  }
  // selectFileName
  const selectFileName = (el) => {
    const str = el.typeGed
    const fileName = str.includes('-') ? str.slice(str.indexOf('-') + 1, str.length) : str
    return fileName
  }

  return (
    <>
      <div className={'print__table__bloc__line'}>
        {/* typeGed */}
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Type</p>
        <p className="print__table__bloc__line--value">
          {!element.typeGed
            ? '-'
            : tableType !== DOC_PERSON_DIVERS
            ? selectTypeGed(element)
            : 'Divers'}
        </p>
      </div>
      <div className={'print__table__bloc__line'}>
        {/* Name */}
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Document</p>
        <p className="print__table__bloc__line--value">
          {!element.typeGed ? '-' : selectFileName(element)}
        </p>
      </div>
      <div className={'print__table__bloc__line'}>
        {/* dateDocument */}
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Date du document</p>
        <p className="print__table__bloc__line--value">
          {!element.dateDocument ? '-' : dateFormateComplete(element.dateDocument)}
        </p>
      </div>
      {tableType === DOC_PERSON_IDENTITY ? (
        <div className={'print__table__bloc__line'}>
          {/* dateDocument */}
          <div className="print__table__bloc__line--number"></div>
          <p className="print__table__bloc__line--title">Date de validité</p>
          <p className="print__table__bloc__line--value">
            {!element.dateValidite ? '-' : dateFormateComplete(element.dateValidite)}
          </p>
        </div>
      ) : null}
      <div className={'print__table__bloc__line'}>
        {/* dateDocument */}
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Dernière mise à jour</p>
        <p className="print__table__bloc__line--value">
          {!element.updatedAt ? '-' : dateFormateComplete(element.updatedAt)}
        </p>
      </div>
    </>
  )
}

export default BodyDocPrint
