import React from 'react'
import Card from '../Components/Card'
import BlocTable from '../table/BlocTable'
import { USER_CONTACTS } from '../../../constants'
import TableBodyContacts from './TableBodyContacts'

const ContactsCard = ({ contacts, openModalAddContact }) => {
  const title = `Contacts (${contacts?.length})`
  const titles = ['N°', 'Nom', 'Prenom', 'Mail', 'Mobile', 'Modifier', 'Supprimer']

  return (
    <div className="cards cards--fullWidth">
      <Card title={title} h2={true} open={true}>
        <div className="informations">
          <BlocTable
            titles={titles}
            data={contacts}
            tableType={USER_CONTACTS}
            children={<TableBodyContacts />}
            title={title}
            handleAdd={() => openModalAddContact()}
          />
        </div>
      </Card>
    </div>
  )
}

export default ContactsCard
