import React, { useState } from 'react'
import Card from '../../Components/Card'
import BlocTable from '../../table/BlocTable'
import { PM_ETABLISSEMENTS } from '../../../../constants'
import TableBodyEtablissement from '../Table/TableBodyEtablissement'
import ModaleEtablissement from '../Modales/ModaleEtablissement'

const Etablissements = ({ etablissements, setEtablissements, setDataChange }) => {
  // modale
  const [modalAdd, setModalAdd] = useState(false)
  // titles
  const title = `Établissements (${etablissements.length})`
  const titles = [
    'N°',
    'Siret',
    'NIC',
    'Adresse',
    'Complément',
    'Code postal',
    'Ville',
    'Pays',
    'Code pays',
    'Code NAF',
    'Libellé code NAF',
    'Établissement employeur',
    'Effectif',
    'Effectif min',
    'Effectif max',
    'Date de création',
    'Cessation',
    'Date de cessation',
    'Siège',
    'Enseigne',
    'Nom commercial',
    'Modifier',
    'Supprimer',
  ]

  return (
    <>
      <Card title={title} open={true}>
        <div className="informations">
          <BlocTable
            titles={titles}
            data={etablissements}
            setData={setEtablissements}
            tableType={PM_ETABLISSEMENTS}
            children={<TableBodyEtablissement />}
            setDataChange={setDataChange}
            title={title}
            handleAdd={() => setModalAdd(true)}
          />
        </div>
      </Card>
      {/* modal établissement supplémentaire ________________________________*/}
      {modalAdd && (
        <ModaleEtablissement
          setModal={setModalAdd}
          data={etablissements}
          setData={setEtablissements}
          setDataChange={setDataChange}
        />
      )}
    </>
  )
}

export default Etablissements
