import { beautifulDate } from '../../services/helpers/dates'
import { capitalizeFirstLetter } from '../../services/helpers/strings'

// user
export const espaceDatas = (user) => {
  return [
    {
      label: '',
      value: `${capitalizeFirstLetter(user.prenom)} ${capitalizeFirstLetter(user.nom)}`,
    },
    {
      label: 'Né(e) le :',
      value: user.dateNaissance ? beautifulDate(user.dateNaissance) : '',
    },
    {
      label: 'Identifiant :',
      value: user.email,
    },
  ]
}
// admin
export const espaceDatasAdmin = (user) => {
  return [
    {
      label: '',
      value: `${capitalizeFirstLetter(user.prenom)} ${capitalizeFirstLetter(user.nom)}`,
    },
    {
      label: 'Identifiant :',
      value: user.email,
    },
  ]
}
