import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { postUser } from '../../../services/API/connexion'
import InputsSignup from './InputsSignup'
import ModalSignupOk from './ModalSignupOk'
import NotCorrect from '../Components/Modales/NotCorrect'
import ModalSignupNotOkAlreadyExist from './ModalSignupNotOkAlreadyExist'
import NotOk from '../Components/Modales/NotOk'
import HomePageComponent from '../../pages/HomePage/HomePageComponent'

const Signup = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [status, setStatus] = useState(null)
  const [submit, setSubmit] = useState(false)
  // fields
  const [fields, setFields] = useState({
    lastName: '',
    firstName: '',
    email: '',
    birthDate: '',
    password: '',
    confirmPassword: '',
  })
  const { lastName, firstName, email, birthDate, password, confirmPassword } = fields || {}
  // modales
  const [modalOk, setModalOk] = useState(false)
  const [modalNotOk, setModalNotOk] = useState(false)
  const [modalNotOkAlreadyExist, setModalNotOkAlreadyExist] = useState(false)
  const [modalNotCorrect, setModalNotCorrect] = useState(false)
  // progress
  const [progress, setProgress] = useState(0)
  const [checks, setChecks] = useState({
    length: false,
    digit: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
  })
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  // Object to send
  const data = {
    nom: lastName,
    prenom: firstName,
    pseudo: email,
    email: email,
    mailUsuel: email,
    dateNaissance: birthDate,
    password: password,
  }
  useEffect(() => {
    const newChecks = {
      length: password.length >= 6 && password.length <= 50,
      digit: /[0-9]+/.test(password),
      uppercase: /[A-Z]+/.test(password),
      lowercase: /[a-z]+/.test(password),
      specialChar: /[^A-Za-z0-9\s]+/.test(password) && !/\s/.test(password),
    }
    setChecks(newChecks)
    const progressValue = Object.values(newChecks).filter(Boolean).length * 20
    setProgress(progressValue)
  }, [password])
  // handleChange
  const handleChange = (e) => {
    const { name, value } = e.target
    setFields({
      ...fields,
      [name]:
        name === lastName || name === firstName || name === email || name === password
          ? value.trim()
          : value,
    })
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setFields({ ...fields, [name]: date })
  }
  // submit
  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmit(true)
    if (
      lastName &&
      lastName.length >= 4 &&
      firstName &&
      firstName.length >= 3 &&
      email &&
      regexEmail.test(email) &&
      password &&
      confirmPassword === password &&
      progress === 100
    ) {
      dispatch(postUser(data, setStatus))
    } else {
      console.log(lastName, firstName, email, birthDate, password, confirmPassword, progress)
      setModalNotCorrect(true)
    }
  }
  // retry
  const handleRetry = () => {
    setStatus(null)
    setModalNotOk(false)
  }
  // close
  const handleClose = () => {
    navigate('/')
  }

  // response status and modal
  useEffect(() => {
    if (status) {
      status < 400
        ? setModalOk(true)
        : status === 400
        ? setModalNotOkAlreadyExist(true)
        : setModalNotOk(true)
      setSubmit(false)
    }
  }, [status])

  return (
    <>
      {submit && !status ? (
        <div className="modalBackground modalBackground--loader">
          <div className="loader"></div>
        </div>
      ) : (
        <HomePageComponent
          children={
            <InputsSignup
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleChangeDate={handleChangeDate}
              fields={fields}
              progress={progress}
              submit={submit}
              checks={checks}
            />
          }
        />
      )}
      {/* modal response ok _________________________________________________*/}
      {modalOk && <ModalSignupOk />}
      {/* modal response not ok _____________________________________________*/}
      {modalNotOk && <NotOk setNotOK={setModalNotOk} closeModale={handleClose} />}
      {modalNotOkAlreadyExist && <ModalSignupNotOkAlreadyExist handleRetry={handleRetry} />}
      {/* modal not correct __________________________________________________*/}
      {modalNotCorrect && <NotCorrect setNotCorrect={setModalNotCorrect} sign={true} />}
    </>
  )
}
// }

export default Signup
