import React from 'react'

const FormSubmit = ({ dataChange, handleSubmit, handleGiveUp, down }) => {
  return dataChange ? (
    <div className={`accountForm__buttons ${down ? 'accountForm__buttons--down' : ''}`}>
      <button type="submit" className="button button--red-red" onClick={(e) => handleSubmit(e)}>
        Enregistrer
      </button>
      <button type="button" className="button button--white-red" onClick={(e) => handleGiveUp(e)}>
        Abandonner
      </button>
    </div>
  ) : (
    <div className={`accountForm__buttons ${down ? 'accountForm__buttons--down' : ''}`}>
      <button className="button button--grey">Enregistrer</button>
      <button type="button" className="button button--white-red" onClick={(e) => handleGiveUp(e)}>
        Retour
      </button>
    </div>
  )
}

export default FormSubmit
