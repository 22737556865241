import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sortByType } from '../../../../../../services/helpers/sort'
import { supprId } from '../../../../../../services/helpers/helpers'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyBienPro from './BodyBienPro'
import BodyBienProPrint from './BodyBienProPrint'

const TableBodyBienPro = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // ouvrir infos bien
  const handleShow = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'ACTIFS', type: 'bienPro' }))
    navigate('/user/patrimoine/actifs/professionnel/bien')
  }
  // modifier bien
  const handleEdit = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'ACTIFS', type: 'bienPro' }))
    navigate('/user/patrimoine/actifs/professionnel/ajouterBien')
  }
  // supprimer bien
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }
  // duplicate bien
  const handleDuplicate = (bien) => {
    const bienBis = {
      ...bien,
      id: 0,
      fichiers: [],
      nom: `${bien.nom} - copie`,
      detenteurs: bien.detenteurs.map((det) => supprId(det)),
    }
    dispatch(getBienSuccess(bienBis))
    dispatch(getbienType({ categorie: 'ACTIFS', type: 'bienPro' }))
    navigate('/user/patrimoine/actifs/professionnel/ajouterBien')
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Nom':
          giveAType('nom')
          break
        case 'Valeur':
          giveAType('valeur')
          break
        case 'Catégorie':
          giveAType('typeBienPro')
          break
        case 'Nature':
          giveAType('nature')
          break
        case 'Montant détenu':
          giveAType('montantDetenu')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyBienProPrint /> : <BodyBienPro handleShow={handleShow} />}
      print={print}
      handleShow={handleShow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleDuplicate={handleDuplicate}
    />
  )
}

export default TableBodyBienPro
