import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { getFormIsActive } from './../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from './../../../../../redux/actions/actionSaveForm'
import { getLastCours, postFinancier } from './../../../../../services/API/financier'
import { postSocial } from '../../../../../services/API/social'
import { postNewVL } from './../../../../../services/API/catalogue'
import { beautifulDateNumbers } from '../../../../../services/helpers/dates'
import Loader from './../../../../layout/Loader'
import NotOk from './../../../Components/Modales/NotOk'
import NotCorrect from './../../../Components/Modales/NotCorrect'
import Card from './../../../Components/Card'
import BlocTable from './../../../table/BlocTable'
import FormOperation from './Form/FormOperation'
import { FE, SUPPORTS_BUY } from '../../../../../constants'
import TableBodySupports from '../Catalogue/Table/TableBodySupports'
import usePersonData from '../../../../layout/usePersonData'

const NewSupportComponent = ({ bienToDisplay, categorie, product, list }) => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  // toggle
  const [toggleBuy, setToggleBuy] = useState(false)
  // cours
  const [cours, setCours] = useState(null)
  const [fondEuro, setFondEuro] = useState(false)
  // newOperation
  const initialNewOp = {
    id: 0,
    nom: '',
    dateOperation: '',
    dateVersement: '',
    nombre: 0,
    isin: '',
    valeurAchat: '',
    montant: 0,
    support: {},
    achat: true,
    majRegularisation: false,
    neutralise: false,
  }
  const [newOperation, setNewOperation] = useState(initialNewOp)
  const { support, montant, dateOperation, dateVersement } = newOperation || {}
  const { nombre, dateAchat, valeurAchat, nonCote, isin, dernierCours } = support || {}
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  const [statusNonCote, setStatusNonCote] = useState(null)
  const [dateIsOK, setDateIsOk] = useState(true)
  // modales
  const [toggleAddBienNotOk, setToggleAddBienNotOk] = useState(false)
  const [toggleAddNotCorrect, setToggleNotCorrect] = useState(false)
  // form is active
  useEffect(() => {
    if (dataChange) {
      dispatch(
        getFormIsActive({
          isActive: true,
          form:
            categorie === 'PER'
              ? 'SupportPER'
              : categorie === 'retraite'
              ? 'SupportRetraite'
              : categorie === 'AV'
              ? 'SupportAV'
              : 'SupportVM',
        })
      )
    }
  }, [dataChange])
  // save form
  useEffect(() => {
    if (
      saveBien &&
      formIsActive.form ===
        (categorie === 'PER'
          ? 'SupportPER'
          : categorie === 'retraite'
          ? 'SupportRetraite'
          : categorie === 'AV'
          ? 'SupportAV'
          : 'SupportVM')
    ) {
      handlePreSubmit()
    }
  }, [saveBien])

  // handleSelectSupport
  const handleSelectSupport = (op) => {
    const date = new Date()
    setFondEuro(op.c_nom === FE)
    const fondEuroOK = op.c_nom === FE
    setDataChange(true)
    setToggleBuy(true)
    op.c_nom !== FE
      ? dispatch(getLastCours(op.isin, false, setCours))
      : setCours({ valeur: '', dateVL: '' })
    setNewOperation({
      ...newOperation,
      nom: op.nom,
      isin: op.isin,
      dateOperation: beautifulDateNumbers(date),
      dateVersement: fondEuroOK ? beautifulDateNumbers(date) : '',
      achat: true,
      nombre: fondEuroOK ? 1 : 0,
      montant: 0,
      support: {
        ...newOperation.support,
        isin: op.isin,
        nom: op.nom,
        nombre: fondEuroOK ? 1 : 0,
        achat: true,
        dateAchat: beautifulDateNumbers(date),
        nonCote: op.nonCote,
        idProduit: product.id,
        historiqueFrais: [],
        historiqueFraisUC: [],
        historiqueSituationQuotidienne: [],
        dici: op,
      },
    })
  }
  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (
      valeurAchat &&
      dateAchat &&
      nombre &&
      (dateOperation || dateVersement) &&
      (montant || montant === 0)
    ) {
      if (nonCote && !fondEuro) {
        if (!dateIsOK) {
          dispatch(
            postNewVL(
              { isin: isin, dateVL: dateAchat, valeur: parseFloat(valeurAchat) },
              setStatusNonCote
            )
          )
          if (!dernierCours) {
            setNewOperation({
              ...newOperation,
              support: {
                ...newOperation.support,
                dernierCours: parseFloat(valeurAchat),
                dateDernierCours: dateAchat,
              },
            })
          }
        } else {
          handleSubmit()
        }
      } else {
        handleSubmit()
      }
    } else {
      console.log(valeurAchat, dateAchat, nombre, dateOperation, dateVersement, montant)
      setToggleNotCorrect(true)
    }
  }
  // statusNonCote
  useEffect(() => {
    if (statusNonCote) {
      switch (statusNonCote) {
        case 200:
          handleSubmit()
          break
        case 403:
          console.log('Cet ISIN correspond à un support coté')
          setToggleAddBienNotOk(true)
          break
        case 404:
          console.log('ISIN non reconnu')
          setToggleAddBienNotOk(true)
          break
        case 409:
          console.log('Une valeur existe déjà à cette date')
          setToggleAddBienNotOk(true)
          break
        default:
          console.log('Un problème est survenu, veuillez réessayer')
          setToggleAddBienNotOk(true)
      }
      setStatusNonCote(null)
    }
  }, [statusNonCote])
  // submit
  const handleSubmit = () => {
    const data = {
      ...bienToDisplay,
      operations: bienToDisplay.operations.concat(newOperation),
      montantTotalCalcule: bienToDisplay.montantTotalCalcule + montant,
      // liquidite:
      //   categorie === 'retraite' || categorie === 'AV'
      //     ? bienToDisplay.liquidite
      //     : bienToDisplay.liquidite + montant,
    }
    categorie === 'PER' || categorie === 'retraite'
      ? dispatch(
          postSocial(
            categorie === 'PER' ? 'per' : 'retraite',
            person.id,
            isPP,
            false,
            data,
            setStatus
          )
        )
      : dispatch(
          postFinancier(
            categorie === 'VM' ? 'produitFinancierVM' : 'produitFinancierAV',
            person.id,
            isPP,
            false,
            data,
            setStatus
          )
        )
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status) {
      if (status < 400) {
        toast.success('Nouveau support ajouté au portefeuille')
      }
      setStatus(null)
    }
  }, [status])
  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setToggleAddBienNotOk(false)
  }
  const handleSubmitNewOp = () => {
    setToggleBuy(false)
    setCours(null)
    handlePreSubmit()
  }
  useEffect(() => {
    if (cours && cours.valeur) {
      setNewOperation({
        ...newOperation,
        valeurAchat: cours.valeur,
        dateOperation: beautifulDateNumbers(cours.dateVL),
        support: {
          ...newOperation.support,
          valeurAchat: cours.valeur,
          dernierCours: cours.valeur,
          dateDernierCours: beautifulDateNumbers(cours.dateVL),
          dateAchat: beautifulDateNumbers(cours.dateVL),
        },
      })
    }
  }, [cours])
  // titles
  const titles = ['N°', 'Acheter', 'Fournisseur', 'Nom', 'ISIN', 'Cours', 'DICI', 'Note', 'Acheter']
  const title = `Supports disponibles (${list.length})`
  return bienToDisplay.transactions.length > 0 || categorie === 'AV' || categorie === 'retraite' ? (
    <>
      {product ? (
        <Card title={title} h6={true} open={false}>
          {list?.length > 0 ? (
            <BlocTable
              titles={titles}
              data={list}
              tableType={SUPPORTS_BUY}
              children={<TableBodySupports handleClick={handleSelectSupport} />}
              title={title}
            />
          ) : (
            <p>Aucun nouveau support disponible</p>
          )}
        </Card>
      ) : null}
      {/* modales */}
      {toggleBuy ? (
        cours ? (
          <FormOperation
            setDataChange={setDataChange}
            setToggleBuy={setToggleBuy}
            initialNewOp={initialNewOp}
            newOperation={newOperation}
            setNewOperation={setNewOperation}
            handleSubmitNewOp={handleSubmitNewOp}
            disponibilite={bienToDisplay.liquidite}
            categorie={categorie}
            setDateIsOk={setDateIsOk}
            fondEuro={fondEuro}
          />
        ) : (
          <Loader />
        )
      ) : null}
      {toggleAddBienNotOk && (
        <NotOk setNotOK={setToggleAddBienNotOk} closeModale={closeToggleAddBien} />
      )}
      {toggleAddNotCorrect && <NotCorrect setNotCorrect={setToggleNotCorrect} />}
    </>
  ) : (
    <p>Une remise de fond est necessaire pour accéder aux supports</p>
  )
}

export default NewSupportComponent
