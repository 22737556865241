import React, { useState } from 'react'
import { CgMenuGridR } from 'react-icons/cg'
import useFormIsActive from '../../../layout/useFormIsActive'
import NavComponent from './NavComponent'
import SaveForm from '../../../layout/SaveForm'

const ResponsiveNavComponent = ({links, type}) => {
  // formIsActive
  const formIsActive = useFormIsActive()
  // modales
  const [toggleSaveForm, setToggleSaveForm] = useState(false)
  // burger
  const [openBurger, setOpenBurger] = useState(false)

  // handleClick
  const handleClick = () => {
    formIsActive && setToggleSaveForm(true)
  }
  // handleClickLittle
  const handleClickLittle = () => {
    setOpenBurger(!openBurger)
    formIsActive && setToggleSaveForm(true)
  }

  return (
    <>
      <div className="noBurgerMobile">
        <nav className="thirdNav" onClick={() => handleClick()}>
          <NavComponent links={links} menu={'thirdNav'} h3={true} />
        </nav>
      </div>

      <div className="burgerMobile">
        {openBurger ? (
          <nav className="thirdNav thirdNav--mobile" onClick={() => handleClick()}>
            <NavComponent
              links={links}
              menu={'thirdNav'}
              h3={true}
              handleclick={handleClickLittle}
            />
          </nav>
        ) : formIsActive ? (
          <div className="burgerMobile__icon burgerMobile__icon--inactif">
            <CgMenuGridR />
          </div>
        ) : (
          <div
            className={`burgerMobile__icon burgerMobile__icon--${type}`}
            onClick={() => setOpenBurger(!openBurger)}
          >
            <CgMenuGridR />
          </div>
        )}
      </div>

      {toggleSaveForm && (
        <SaveForm toggleSaveForm={toggleSaveForm} setToggleSaveForm={setToggleSaveForm} />
      )}
    </>
  )
}

export default ResponsiveNavComponent
