import React from 'react'
import Loader from '../../../../layout/Loader'
import useCustomPrint from '../../../../layout/useCustomPrint'
import LittleLoader from '../../../../layout/LittleLoader'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import CatalogueToPrint from './Print/CatalogueToPrint'
import CatalogueCard from './CatalogueCard'
import CatalogueUpdateSupport from './CatalogueUpdateSupport'
import ModaleSelectForm from './Form/ModaleSelectForm'

const CatalogueHomeContent = ({
  catalogues,
  categorie,
  toggleSelectForm,
  setToggleSelectForm,
  catalogueVM,
  catalogueAV,
  cataloguePER,
  catalogueRetraite,
  optionsCategorieForm,
  handleChange,
  categorieForm,
}) => {
  const renderCatalogueCards = () => {
    const categoriesMap = {
      financier: [
        {
          data: catalogueVM,
          title: `Catalogue Valeurs Mobilières`,
          categorie: 'VM',
        },
        {
          data: catalogueAV,
          title: `Catalogue Assurances Vie`,
          categorie: 'AV',
        },
      ],
      social: [
        { data: cataloguePER, title: `Catalogue Epargne salariale & PER`, categorie: 'PER' },
        { data: catalogueRetraite, title: `Catalogue Retraite`, categorie: 'retraite' },
      ],
    }

    return (categoriesMap[categorie] || []).map(
      ({ data, title, categorie }) =>
        data && (
          <CatalogueCard
            key={categorie}
            catalogues={data}
            title={`${title} (${data.length})`}
            categorie={categorie}
          />
        )
    )
  }
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!catalogues) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<CatalogueToPrint catalogueVM={catalogueVM} catalogueAV={catalogueAV} />}
        />
        <ButtonsRow handlePrint={handlePrint} handleAdd={() => setToggleSelectForm(true)} />
        <div className="sousSection">
          <div className="cards">{renderCatalogueCards()}</div>
          <CatalogueUpdateSupport categorie={categorie} />
        </div>
      </div>
      {toggleSelectForm ? (
        <ModaleSelectForm
          setToggleSelectForm={setToggleSelectForm}
          optionsCategorieForm={optionsCategorieForm}
          handleChange={handleChange}
          categorieForm={categorieForm}
        />
      ) : null}
    </>
  )
}

export default CatalogueHomeContent
