import React from 'react'
import { shortFormeJuridique } from '../../../services/helpers/helpers'

const BodyPMPrint = ({ element, index }) => {
  return (
    <>
      <div className={'print__table__bloc__line'}>
        {/* denominationSociale */}
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Dénomination sociale</p>
        <p className="print__table__bloc__line--value">
          {!element.denominationSociale ? '-' : element.denominationSociale}
        </p>
      </div>
      <div className={'print__table__bloc__line'}>
        {/* formeJuridique */}
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Forme Juridique</p>
        <p className="print__table__bloc__line--value">
          {!element.formeJuridique ? '-' : shortFormeJuridique(element.formeJuridique)}
        </p>
      </div>
      <div className={'print__table__bloc__line'}>
        {/* dirigeant */}
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Dirigeant</p>
        <p className="print__table__bloc__line--value">
          {!element.dirigeant ? '-' : element.dirigeant}
        </p>
      </div>
    </>
  )
}

export default BodyPMPrint
