import React from 'react'
import { ACTIFS_AUTRES } from '../../../../../../constants'
import TableToPrint from '../../../../table/TableToPrint'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import TableBodyBienAutre from '../Table/TableBodyBienAutre'
import BiensAutresPieChart from '../BiensAutresPieChart'

const ActifsAutresToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurAutresActifs } = elementToDisplay || {}
  return (
    <>
      <PatrimoineValueCard totalAmount={valeurAutresActifs} values={values} />
      <PatrimoineChartCardPrint
        children={<BiensAutresPieChart actifsAutres={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={ACTIFS_AUTRES}
        title={`En cours  (${dataNotSold.length})`}
        children={<TableBodyBienAutre />}
      />
      <TableToPrint
        data={dataSold}
        tableType={ACTIFS_AUTRES}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyBienAutre />}
      />
    </>
  )
}

export default ActifsAutresToPrint
