// export const API_BASE_URL = 'http://www.monespacepatrimoine.fr//api'
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8082/api'
export const ACCESS_TOKEN = 'accessToken'
export const COLORS = {
  // bases
  colorPrimary: '#002e2b',
  colorSecondary: '#007c60',
  colorTertiary: '#962222',
  colorBackground: '#f7c59f',
  colorFontDark: '#0d1518',
  colorFontLight: '#405258',
  colorNeutral: '#f5fcfb',
  // patrimoine
  colorActifs: '#962222',
  colorPassifs: '#B75E0B',
  colorRevenus: '#002e2b',
  colorCharges: '#007c60',
  // repartition
  colorRepartition06: '#110404',
  colorRepartition05: '#330C0C',
  colorRepartition04: '#541313',
  colorRepartition03: '#962222',
  colorRepartition02: '#A2381B',
  colorRepartition01: '#AE4D13',
  // detention
  colorDetention06: '#001413',
  colorDetention05: '#00211F',
  colorDetention04: '#002E2B',
  colorDetention03: '#06453C',
  colorDetention02: '#0B5B4D',
  colorDetention01: '#15886E',
}
export const CATEGORIE = {
  vm: 'VM',
  av: 'AV',
  per: 'PER',
  retraite: 'retraite',
}
// constantes String
export const FE = 'Support euro assurance vie'
// constantes Array
export const ACTIFS_IMMO = 'actifs-immobiliers'
export const ACTIFS_PRO = 'actifs-professionnels'
export const ACTIFS_AUTRES = 'actifs-autres'
export const PRODUITS_VM = 'produits-vm'
export const PRODUITS_AV = 'produits-av'
export const PRODUITS_FINANCIERS = 'produits-financiers'
export const PRODUITS_PER = 'produits-per'
export const PRODUITS_RETRAITE = 'produits-retraite'
export const PREVOYANCE = 'prevoyance'
export const PRODUIT_TRANSACTIONS = 'produit-transactions'
export const PRODUIT_OPERATIONS = 'produit-operations'
export const PRODUIT_PORTEFEUILLE = 'produit-portefeuille'
export const PRODUIT_FRAIS = 'produit-frais'
export const CATALOGUES = 'catalogues'
export const SUPPORTS_INFOS = 'supports-infos'
export const SUPPORTS_SELECT = 'supports-selectionner'
export const SUPPORTS_DELETE = 'supports-supprimer'
export const SUPPORTS_BUY = 'supports-acheter'
export const PASSIFS_EMPRUNTS = 'passifs-emprunts'
export const PASSIFS_DETTES = 'passifs-dettes'
export const REVENUS_FONCIERS = 'revenus-fonciers'
export const REVENUS_MOBILIERS = 'revenus-mobiliers'
export const REVENUS_PROS = 'revenus-professionnels'
export const REVENUS_PENSIONS = 'revenus-pensions'
export const REVENUS_AUTRES = 'revenus-autres'
export const CHARGES_GENERALES = 'charges-generales'
export const CHARGES_DEDUCTIBLES = 'charges-deductibles'
export const CHARGES_EMPRUNTS = 'charges-emprunts'
export const CHARGES_PENSIONS = 'charges-pensions'
export const CHARGES_IMPOTS = 'charges-impots'
export const CHARGES_REDUCTIONS = 'charges-credits-impots'
export const PP_ACTIVITY = 'pp-activites'
export const PP_RELATIONS = 'pp-relations'
export const PP_ADDRESS = 'pp-address'
export const PM_BENEFICIAIRES = 'pm-beneficiaires'
export const PM_ETABLISSEMENTS = 'pm-etablissements'
export const ADMIN_USERS = 'admin-users'
export const ADMIN_FIRMS = 'admin-firms'
export const USER_REFERENTS = 'user-referents'
export const USER_RELATIONS = 'user-relations'
export const USER_DIRIGEANTS = 'user-dirigeants'
export const USER_SIGNATURES_INSTANCE = 'user-signatures-instance'
export const USER_SIGNATURES_CLASSER = 'user-signatures-classer'
export const USER_MESSAGES = 'user-messages'
export const USER_CONTACTS = 'user-contacts'
// constantes Object
export const ACTIF_IMMO = 'bien-immobilier'
export const ACTIF_PRO = 'bien-professionnel'
export const ACTIF_AUTRE = 'bien-autre'
export const PASSIF_EMPRUNT = 'passif-emprunt'
export const PASSIF_DETTE = 'passif-dette'
export const REVENU_FONCIER = 'revenu-foncier'
export const REVENU_MOBILIER = 'revenu-mobilier'
export const REVENU_PRO = 'revenu-professionnel'
export const REVENU_PR = 'revenu-pension'
export const REVENU_AUTRE = 'revenu-autre'
export const CHARGE_GENERALE = 'charge-generale'
export const CHARGE_DEDUCTIBLE = 'charge-deductible'
export const CHARGE_EMRUNT = 'charge-emprunt'
export const CHARGE_PENSION = 'charge-pension'
export const CHARGE_IMPOT = 'charge-impot'
export const CHARGE_REDUCTION = 'charge-reduction'
// documents type
export const DOC_PERSON_IDENTITY = 'document-personne-identity'
export const DOC_PERSON_HOME = 'document-personne-home'
export const DOC_PERSON_BIRTH = 'document-personne-birth'
export const DOC_PERSON_FAMILY = 'document-personne-family'
export const DOC_PERSON_PATRIMOINE = 'document-personne-patrimoine'
export const DOC_PERSON_DIVERS = 'document-personne-divers'
export const DOC_BIEN_IMMO = 'document-bien-immobilier'
export const DOC_BIEN_PRO = 'document-bien-professionnel'
export const DOC_BIEN_AUTRE = 'document-bien-autre'
export const DOC_REVENU_FONCIER = 'document-revenu-foncier'
export const DOC_REVENU_MOBILIER = 'document-revenu-mobilier'
export const DOC_REVENU_PRO = 'document-revenu-professionnel'
export const DOC_REVENU_PENSION = 'document-revenu-pension'
export const DOC_REVENU_AUTRE = 'document-revenu-autre'
export const DOC_PASSIF_EMPRUNT = 'document-passif-emprunt'
export const DOC_PASSIF_DETTE = 'document-passif-dette'
export const DOC_CHARGE_GENERALE = 'document-charge-generale'
export const DOC_CHARGE_DEDUCTIBLE = 'document-charge-deductible'
export const DOC_CHARGE_REDUCTION = 'document-charge-reduction'
export const DOC_CHARGE_PENSION = 'document-charge-pension'
export const DOC_CHARGE_IMPOT = 'document-charge-impot'
export const DOC_CHARGE_EMPRUNT = 'document-charge-emprunt'
export const DOC_FINANCIER_AV = 'document-financier-av'
export const DOC_FINANCIER_VM = 'document-financier-vm'
export const DOC_FINANCIER_COMPTE = 'document-financier-compte'
export const DOC_SOCIAL_PER = 'document-social-per'
export const DOC_SOCIAL_RETRAITE = 'document-social-retraite'
export const DOC_SOCIAL_PREVOYANCE = 'document-social-prevoyance'

export const TITLES_DOC_READ = [
  'N°',
  'Type',
  'Document',
  'Date du document',
  'Dernière mise à jour',
  'Voir',
]
export const TITLES_DOC_UPDATE = [
  'N°',
  'Type',
  'Document',
  'Date du document',
  'Dernière mise à jour',
  'Voir',
  'Mettre à jour',
  'Supprimer',
]
