import React from 'react'
import { optionsDetailsRevenuMobilier } from '../../../../../../data/options/optionsRevenus'
import {
  optionsDetenteur,
  optionsDetention,
  optionsTypePeriodicite,
} from '../../../../../../data/options/options'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'
import SelectComponent from '../../../../Components/SelectComponent'
import DateInputComponent from '../../../../Components/DateInputComponent'

const GeneralInformationCardMobilier = ({ handleChange, handleChangeDate, information }) => {
  const {
    libelle,
    detail,
    typeRevenu,
    montant,
    typePeriodicite,
    dateDebut,
    dateFin,
    detention,
    tauxIndivision,
  } = information || {}

  return (
    <Card title="Informations générales" h3={true} open={true}>
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* libelle */}
          <Input
            forAndId="libelle"
            errorLabel={libelle === ''}
            type="text"
            name="Libellé :"
            value={libelle}
            onChange={(e) => handleChange(e)}
            placeholder="Libellé"
            copy={true}
            required
          />
          {/* detail */}
          <SelectComponent
            forAndId="detail"
            errorLabel={detail === '' || !detail}
            name="Catégorie :"
            isSearchable={false}
            options={optionsDetailsRevenuMobilier}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={detail && optionsDetailsRevenuMobilier.find((c) => c.value === detail)}
            copy={true}
            required={true}
          />
          {/* typeRevenu */}
          <SelectComponent
            forAndId="typeRevenu"
            errorLabel={typeRevenu === ''}
            name="Détenteur :"
            isSearchable={false}
            options={optionsDetenteur}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsDetenteur.find((c) => c.value === typeRevenu)}
            copy={true}
            required={true}
          />
          {/* montant */}
          <Input
            forAndId="montant"
            errorLabel={montant === '' || montant === 0}
            type="number"
            name="Montant :"
            value={montant}
            onChange={(e) => handleChange(e)}
            required={true}
            copy={true}
          />
          {/* typePeriodicite */}
          <SelectComponent
            forAndId="typePeriodicite"
            errorLabel={typePeriodicite === ''}
            name="Type periodicité :"
            isSearchable={false}
            options={optionsTypePeriodicite}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsTypePeriodicite.find((c) => c.value === typePeriodicite)}
            copy={true}
            required={true}
          />
        </div>
        {/* dateDebut */}
        <DateInputComponent
          name={'dateDebut'}
          label={'Date de début :'}
          value={dateDebut}
          handleChange={handleChangeDate}
        />
        {/* dateFin */}
        <DateInputComponent
          name={'dateFin'}
          label={'Date de fin :'}
          value={dateFin}
          handleChange={handleChangeDate}
        />

        {/* detention */}
        <SelectComponent
          forAndId="detention"
          errorLabel={detention === ''}
          name="Détention :"
          isSearchable={false}
          options={optionsDetention}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsDetention.find((c) => c.value === detention)}
          required={true}
          copy={true}
        />
        {/* tauxIndivision */}
        <Input
          forAndId="tauxIndivision"
          type="number"
          name="Taux d'indivision :"
          value={tauxIndivision}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
      </div>
    </Card>
  )
}

export default GeneralInformationCardMobilier
