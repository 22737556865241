import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { getFormIsActive } from '../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../redux/actions/actionSaveForm'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { postFinancier } from '../../../../../services/API/financier'
import { postSocial } from '../../../../../services/API/social'
import { updateDetenteurs } from '../../../../../services/helpers/detenteurs'
import { supprId } from '../../../../../services/helpers/helpers'
import FormSubmit from '../../../Components/FormSubmit'
import NotCorrect from '../../../Components/Modales/NotCorrect'
import DocumentsCard from './DocumentsCard'
import DetentionCard from '../../Detention/DetentionCard'
import ProductTable from './ProductTable'
import PerformanceEtFraisCard from './PerformanceEtFraisCard'
import ProductInformationCard from './ProductInformationCard'
import ModaleDate from './ModaleDate'
import {
  DOC_FINANCIER_AV,
  DOC_FINANCIER_VM,
  DOC_SOCIAL_PER,
  DOC_SOCIAL_RETRAITE,
} from '../../../../../constants'
import ModaleFiles from '../../../Files/ModaleFiles'
import usePersonData from '../../../../layout/usePersonData'

const ProductComponent = ({ categorieP }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const financier = useSelector((state) => state.getFinancier.financier)
  const social = useSelector((state) => state.getSocial.social)
  const bien = useSelector((state) => state.getBien.bien)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // responses
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddPatrimoine, setToggleAddPatrimoine] = useState(false)
  const [toggleAddNotCorrect, setToggleNotCorrect] = useState(false)
  // cat
  const cat =
    categorieP === 'VM'
      ? 'valeurs-mobilieres'
      : categorieP === 'AV'
      ? 'assurances-vie'
      : categorieP === 'PER'
      ? 'PER'
      : 'retraite'

  // Informations
  const [information, setInformation] = useState({
    id: 0,
    libelle: '',
    fournisseur: '',
    idCatalogue: 0,
    categorie: '',
    numeroContrat: '',
    dateEffet: '',
    dateOuverture: '',
    beneficiaire: '',
    detenteurs: [],
    fichiers: [],
    montantTotalCalcule: 0,
    liquidite: 0,
    transactions: [],
    operations: [],
    portefeuille: {},
    historiquesFrais: [],
    tauxFraisGestionUC: 0,
    tauxFraisGestionFE: 0,
  })
  const {
    id,
    libelle,
    fournisseur,
    idCatalogue,
    categorie,
    numeroContrat,
    dateEffet,
    dateOuverture,
    beneficiaire,
    detenteurs,
    fichiers,
    montantTotalCalcule,
    liquidite,
    transactions,
    operations,
    portefeuille,
    historiquesFrais,
    tauxFraisGestionUC,
    tauxFraisGestionFE,
  } = information || {}
  const [fichiersToDisplay, setFichiersToDisplay] = useState([])
  const [detenteursToDisplay, setDetenteursToDisplay] = useState(detenteurs)
  const [historiquesFraisToDisplay, setHistoriquesFraisToDisplay] = useState(historiquesFrais)
  const [newValeurTotale, setNewValeurTotale] = useState(null)
  const [modaleDate, setModaleDate] = useState(false)
  useEffect(() => {
    let newBien
    categorieP === 'VM'
      ? (newBien = financier.produitsVM.filter((el) => el.id === bien.id)[0])
      : categorieP === 'AV'
      ? (newBien = financier.produitsAV.filter((el) => el.id === bien.id)[0])
      : categorieP === 'PER'
      ? (newBien = social.produitsPER.filter((el) => el.id === bien.id)[0])
      : (newBien = social.produitsRetraite.filter((el) => el.id === bien.id)[0])
    dispatch(getBienSuccess(newBien))
  }, [financier])
  // valeur totale de calcul
  useEffect(() => {
    setNewValeurTotale(bien ? montantTotalCalcule + liquidite : 0)
  }, [montantTotalCalcule, liquidite])
  // maj si bien
  useEffect(() => {
    bien &&
      setInformation({
        ...information,
        id: bien.id,
        libelle: bien.libelle,
        fournisseur: bien.fournisseur,
        categorie: bien.categorie,
        idCatalogue: bien.idCatalogue,
        numeroContrat: bien.numeroContrat ? bien.numeroContrat : '',
        dateEffet: bien.dateEffet ? bien.dateEffet : '',
        dateOuverture: bien.dateOuverture ? bien.dateOuverture : '',
        beneficiaire: bien.beneficiaire ? bien.beneficiaire : '',
        detenteurs: bien.detenteurs
          .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
          .reverse(),
        fichiers: bien.fichiers,
        montantTotalCalcule: bien.montantTotalCalcule,
        liquidite: bien.liquidite,
        transactions: bien.transactions ? bien.transactions : '',
        operations: bien.operations,
        portefeuille: bien.portefeuille,
        historiquesFrais: bien.historiquesFrais ? bien.historiquesFrais : [],
        tauxFraisGestionUC: bien.tauxFraisGestionUC ? bien.tauxFraisGestionUC : 0,
        tauxFraisGestionFE: bien.tauxFraisGestionFE ? bien.tauxFraisGestionFE : 0,
      })
    setDetenteursToDisplay(bien.detenteurs)
    setHistoriquesFraisToDisplay(bien.historiquesFrais)
    setFichiersToDisplay(bien.fichiers)
    // setDisplay(true)
  }, [bien])
  // maj bien et typeBien si update
  useEffect(() => {
    if ((financier || social) && bien) {
      const array =
        categorieP === 'VM'
          ? financier.produitsVM
          : categorieP === 'AV'
          ? financier.produitsAV
          : categorieP === 'PER'
          ? social.produitsPER
          : social.produitsRetraite
      const newBien = array.filter((el) => el.id === bien.id)
      dispatch(getBienSuccess(newBien[0]))
      dispatch(
        getbienType({
          categorie: categorieP === 'VM' || categorieP === 'AV' ? 'FINANCIER' : 'SOCIAL',
          type:
            categorieP === 'VM'
              ? 'produitFinancierVM'
              : categorieP === 'AV'
              ? 'produitFinancierAV'
              : categorieP === 'PER'
              ? 'per'
              : 'retraite',
        })
      )
    }
  }, [financier, social, bien])
  // form is active
  useEffect(() => {
    // console.log(count)
    if (dataChange) {
      dispatch(
        getFormIsActive({
          isActive: true,
          form: `${
            categorieP === 'VM'
              ? 'Produit VM'
              : categorieP === 'AV'
              ? 'Produit AV'
              : categorieP === 'PER'
              ? 'Produit PER'
              : 'Produit Retraite'
          }`,
        })
      )
    }
  }, [dataChange])
  // save produit
  useEffect(() => {
    if (
      saveBien &&
      ((categorieP === 'VM' && formIsActive.form === 'Produit VM') ||
        (categorieP === 'AV' && formIsActive.form === 'Produit AV') ||
        (categorieP === 'PER' && formIsActive.form === 'Produit PER') ||
        (categorieP === 'retraite' && formIsActive.form === 'Produit retraite'))
    ) {
      handlePreSubmit()
    }
  }, [saveBien])
  // Object to send
  const data = {
    id: id,
    libelle: libelle,
    fournisseur: fournisseur,
    idCatalogue: idCatalogue,
    numeroContrat: numeroContrat,
    categorie: categorie,
    dateEffet: dateEffet,
    dateOuverture: dateOuverture,
    beneficiaire: beneficiaire,
    detenteurs:
      detenteursToDisplay &&
      detenteursToDisplay.map((det) =>
        updateDetenteurs(
          det.newDetenteur ? supprId(det) : det,
          newValeurTotale,
          detenteursToDisplay
        )
      ),
    fichiers: fichiers,
    montantTotalCalcule: montantTotalCalcule,
    liquidite: liquidite,
    transactions: transactions,
    operations: operations,
    portefeuille: portefeuille,
    historiquesFrais: historiquesFrais,
    tauxFraisGestionUC: tauxFraisGestionUC,
    tauxFraisGestionFE: tauxFraisGestionFE,
  }
  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (libelle !== '') {
      handleSubmit()
    } else {
      setToggleNotCorrect(true)
    }
  }
  // handleSubmit
  const handleSubmit = () => {
    setModaleDate(false)
    categorieP === 'PER' || categorieP === 'retraite'
      ? dispatch(
          postSocial(
            categorieP === 'PER' ? 'per' : 'retraite',
            person.id,
            isPP,
            false,
            data,
            setStatus
          )
        )
      : dispatch(
          postFinancier(
            categorieP === 'VM' ? 'produitFinancierVM' : 'produitFinancierAV',
            person.id,
            isPP,
            false,
            data,
            setStatus
          )
        )
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    navigate(
      `/user/patrimoine/actifs/${
        categorieP === 'VM' || categorieP === 'AV' ? 'financier' : 'social'
      }/${cat}`
    )
  }
  // status
  useEffect(() => {
    if (status) {
      if (status < 400) {
        setDataChange(false)
      }
      setStatus(null)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
    }
  }, [status])
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])
  // maj fichiers
  useEffect(() => {
    bien && setFichiersToDisplay(bien.fichiers)
  }, [bien])
  // actualisation historiquesFrais
  useEffect(() => {
    setHistoriquesFraisToDisplay(historiquesFrais)
  }, [historiquesFrais])
  // maj historiquesFrais
  useEffect(() => {
    if (historiquesFraisToDisplay.length > 0) {
      setInformation({ ...information, historiquesFrais: historiquesFraisToDisplay })
    }
  }, [historiquesFraisToDisplay])
  //
  useEffect(() => {
    if (bien && !bien.dateEffet && !bien.dateOuverture) {
      setModaleDate(true)
    }
  }, [bien])

  if (!bien) return <Navigate to={`/user/patrimoine/actifs/${cat}`} replace />

  return (
    <div className="cards cards--fullWidth">
      <>
        <div className="cards cards--fullWidth">
          <ProductTable produit={bien} categorie={categorieP} print={false} />
        </div>
        <div className="cards">
          <ProductInformationCard
            information={information}
            setInformation={setInformation}
            categorieP={categorieP}
            setDataChange={setDataChange}
          />
          <PerformanceEtFraisCard
            historiquesFraisToDisplay={historiquesFraisToDisplay}
            setHistoriquesFraisToDisplay={setHistoriquesFraisToDisplay}
            categorieP={categorieP}
            setDataChange={setDataChange}
          />
        </div>
      </>
      <div className="cards cards--fullWidth">
        <DetentionCard
          information={information}
          setInformation={setInformation}
          detenteursToDisplay={detenteursToDisplay}
          setDetenteursToDisplay={setDetenteursToDisplay}
          newValeurTotale={newValeurTotale}
          setDataChange={setDataChange}
          showUS={true}
        />
      </div>
      <div className="cards cards--fullWidth">
        <DocumentsCard
          fichiersToDisplay={fichiersToDisplay}
          setFichiersToDisplay={setFichiersToDisplay}
          setToggleAdd={setToggleAddPatrimoine}
          docType={
            categorieP === 'VM'
              ? DOC_FINANCIER_VM
              : categorieP === 'AV'
              ? DOC_FINANCIER_AV
              : categorieP === 'PER'
              ? DOC_SOCIAL_PER
              : DOC_SOCIAL_RETRAITE
          }
          setDataChange={setDataChange}
          h6={true}
        />
      </div>
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handlePreSubmit}
        handleGiveUp={handleGiveUp}
        down={false}
      />
      {/* modales */}
      {toggleAddPatrimoine && (
        <ModaleFiles
          data={fichiers}
          tableType={
            categorieP === 'VM'
              ? DOC_FINANCIER_VM
              : categorieP === 'AV'
              ? DOC_FINANCIER_AV
              : categorieP === 'PER'
              ? DOC_SOCIAL_PER
              : DOC_SOCIAL_RETRAITE
          }
          setModal={setToggleAddPatrimoine}
          setDataChange={setDataChange}
        />
      )}
      {toggleAddNotCorrect && <NotCorrect setNotCorrect={setToggleNotCorrect} />}
      {modaleDate && (
        <ModaleDate
          information={information}
          setInformation={setInformation}
          categorieP={categorieP}
          handlePreSubmit={handlePreSubmit}
          setModaleDate={setModaleDate}
        />
      )}
    </div>
  )
}

export default ProductComponent
