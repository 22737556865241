import React from 'react'
import Card from '../../../../../Components/Card'
import SelectComponent from '../../../../../Components/SelectComponent'
import { optionsTrueOrFalse } from '../../../../../../../data/options/options'
import DateInputComponent from '../../../../../Components/DateInputComponent'

const ClosingComponent = ({ handleChange, handleChangeDate, information }) => {
  const { clos, dateCloture } = information || {}

  return (
    <Card title="Clôture" h3={true} open={true}>
      <div className="informations">
        {/* closOuVendu */}
        <SelectComponent
          forAndId="clos"
          name="Compte clos :"
          options={optionsTrueOrFalse}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsTrueOrFalse.find((c) => c.value === clos)}
          copy={true}
        />
        {clos && (
          <>
            {/* dateCloture */}
            <DateInputComponent
              name={'dateCloture'}
              label={'Date de clôture :'}
              value={dateCloture}
              handleChange={handleChangeDate}
            />
          </>
        )}
      </div>
    </Card>
  )
}

export default ClosingComponent
