import React from 'react'
import DeletePP from '../Accounts/DeletePP'
import DeleteContact from '../Contacts/DeleteContact'
import DeleteBien from '../Patrimoine/Patrimoine/DeleteBien'
import DeleteDoc from '../Files/DeleteDoc'
import DeleteMessage from '../UserMessages/DeleteMessage'
import * as data from '../../../constants'

const ModalDelete = ({ setModalDelete, newData, setDataChange, tableType }) => {
  const commonProps = {
    setModalDelete,
    newData,
    setDataChange,
    tableType,
  }
  const componentMap = {
    [data.USER_REFERENTS]: DeletePP,
    [data.USER_RELATIONS]: DeletePP,
    [data.USER_CONTACTS]: DeleteContact,
    [data.USER_MESSAGES]: DeleteMessage,
    [data.ACTIFS_IMMO]: DeleteBien,
    [data.ACTIFS_PRO]: DeleteBien,
    [data.ACTIFS_AUTRES]: DeleteBien,
    [data.REVENUS_AUTRES]: DeleteBien,
    [data.REVENUS_FONCIERS]: DeleteBien,
    [data.REVENUS_MOBILIERS]: DeleteBien,
    [data.REVENUS_PENSIONS]: DeleteBien,
    [data.REVENUS_PROS]: DeleteBien,
    [data.PASSIFS_EMPRUNTS]: DeleteBien,
    [data.PASSIFS_DETTES]: DeleteBien,
    [data.CHARGES_GENERALES]: DeleteBien,
    [data.CHARGES_DEDUCTIBLES]: DeleteBien,
    [data.CHARGES_REDUCTIONS]: DeleteBien,
    [data.CHARGES_PENSIONS]: DeleteBien,
    [data.CHARGES_EMPRUNTS]: DeleteBien,
    [data.CHARGES_IMPOTS]: DeleteBien,
    [data.CATALOGUES]: DeleteBien,
    [data.PRODUITS_AV]: DeleteBien,
    [data.PRODUITS_RETRAITE]: DeleteBien,
    [data.PRODUITS_VM]: DeleteBien,
    [data.PRODUITS_PER]: DeleteBien,
    [data.PRODUITS_FINANCIERS]: DeleteBien,
    [data.PREVOYANCE]: DeleteBien,
    [data.PRODUIT_OPERATIONS]: DeleteBien,
  }
  const DefaultComponent = DeleteDoc // Composant par défaut si tableType ne correspond à aucun cas

  const handleReturn = () => {
    // Récupère le composant basé sur tableType, sinon utilise DefaultComponent
    const ComponentToRender = componentMap[tableType] || DefaultComponent
    return <ComponentToRender {...commonProps} />
  }

  return handleReturn()
}

export default ModalDelete
