import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../redux/actions/actionGetBienType'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { optionsAddTypeFichierGed } from '../../../data/options/optionsFiles'
import Title from '../../layout/Title'
import Card from '../../components/Components/Card'
import BlocTable from '../../components/table/BlocTable'
import Modale from '../../components/Components/Modale'
import SelectComponent from '../../components/Components/SelectComponent'
import ButtonsRow from '../../components/Patrimoine/Patrimoine/ButtonsRow'
import {
  DOC_PERSON_BIRTH,
  DOC_PERSON_DIVERS,
  DOC_PERSON_FAMILY,
  DOC_PERSON_HOME,
  DOC_PERSON_IDENTITY,
  DOC_PERSON_PATRIMOINE,
  TITLES_DOC_READ,
  TITLES_DOC_UPDATE,
} from '../../../constants'
import TableBodyDoc from '../../components/Files/TableBodyDoc'
import ModaleFiles from '../../components/Files/ModaleFiles'
import usePersonData from '../../layout/usePersonData'
import useName from '../../layout/useName'

const MyDocuments = () => {
  const dispatch = useDispatch()
  const persons = useSelector((state) => state.getPersons.persons)
  const { person, personType, isPP } = usePersonData()
  // Fichiers
  const [fichiers, setFichiers] = useState(person.fichiers)
  // dataChange
  const [dataChange, setDataChange] = useState(false)
  // modales add doc
  const [toggleAddDoc, setToggleAddDoc] = useState(false)
  const [toggleAddIdentity, setToggleAddIdentity] = useState(false)
  const [toggleAddHome, setToggleAddHome] = useState(false)
  const [toggleAddBirth, setToggleAddBirth] = useState(false)
  const [toggleAddFamily, setToggleAddFamily] = useState(false)
  const [toggleAddVarious, setToggleAddVarious] = useState(false)
  // Files
  const [identityFiles, setIdentityFiles] = useState([])
  const [homeFiles, setHomeFiles] = useState([])
  const [birthFiles, setBirthFiles] = useState([])
  const [familyFiles, setFamilyFiles] = useState([])
  const [patrimoineFiles, setPatrimoineFiles] = useState([])
  const [variousFiles, setVariousFiles] = useState([])
  // remise à zero
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // typeFichierGED
  const [typeFichierGED, setTypeFichierGED] = useState(undefined)
  useEffect(() => {
    const newPerson = persons[personType].filter((el) => el.id === person.id)
    dispatch(getPerson(newPerson[0]))
  }, [persons])

  // mise à jour files à afficher dans chaque tableau
  useEffect(() => {
    setFichiers(person.fichiers)
    setIdentityFiles(person.fichiers.filter((el) => el.typeFichierGED === 'IDENTITE'))
    setHomeFiles(person.fichiers.filter((el) => el.typeFichierGED === 'DOMICILE'))
    setBirthFiles(person.fichiers.filter((el) => el.typeFichierGED === 'NAISSANCE'))
    setFamilyFiles(
      person.fichiers.filter(
        (el) => el.typeFichierGED === 'FAMILLE' || el.typeFichierGED === 'MARIAGE'
      )
    )
    setPatrimoineFiles(person.fichiers.filter((el) => el.typeFichierGED === 'PATRIMOINE'))
    setVariousFiles(person.fichiers.filter((el) => el.typeFichierGED === 'DIVERS'))
    if (dataChange) {
      setDataChange(false)
      closeModals()
    }
  }, [person])

  // fermeture des modales
  const closeModals = () => {
    setToggleAddIdentity(false)
    setToggleAddHome(false)
    setToggleAddBirth(false)
    setToggleAddFamily(false)
    setToggleAddVarious(false)
  }
  // infos à afficher par carte
  const commonIdentityTitles = [
    'N°',
    'Type',
    'Nom',
    'Date du document',
    'Date de validité',
    'Dernière mise à jour',
    'Voir',
  ]
  const allIdentityTitles = [...commonIdentityTitles, 'Mettre à jour', 'Supprimer']
  let documents = [
    {
      name: 'Identité',
      files: identityFiles,
      setFiles: setIdentityFiles,
      titles: personType === 'relationPP' ? commonIdentityTitles : allIdentityTitles,
      tableType: DOC_PERSON_IDENTITY,
      setToggle: setToggleAddIdentity,
    },
    {
      name: 'Domicile',
      files: homeFiles,
      setFiles: setHomeFiles,
      titles: personType === 'relationPP' ? TITLES_DOC_READ : TITLES_DOC_UPDATE,
      tableType: DOC_PERSON_HOME,
      setToggle: setToggleAddHome,
    },
    {
      name: 'Naissance',
      files: birthFiles,
      setFiles: setBirthFiles,
      titles: personType === 'relationPP' ? TITLES_DOC_READ : TITLES_DOC_UPDATE,
      tableType: DOC_PERSON_BIRTH,
      setToggle: setToggleAddBirth,
    },
    {
      name: 'Famille',
      files: familyFiles,
      setFiles: setFamilyFiles,
      titles: personType === 'relationPP' ? TITLES_DOC_READ : TITLES_DOC_UPDATE,
      tableType: DOC_PERSON_FAMILY,
      setToggle: setToggleAddFamily,
    },
    {
      name: 'Patrimoine',
      files: patrimoineFiles,
      setFiles: setPatrimoineFiles,
      titles: TITLES_DOC_READ,
      tableType: DOC_PERSON_PATRIMOINE,
    },
    {
      name: 'Divers',
      files: variousFiles,
      setFiles: setVariousFiles,
      titles: personType === 'relationPP' ? TITLES_DOC_READ : TITLES_DOC_UPDATE,
      tableType: DOC_PERSON_DIVERS,
      setToggle: setToggleAddVarious,
    },
  ]

  const selectToggle = (typeFichierGED) => {
    setToggleAddDoc(false)
    documents.map((el) => el.name === typeFichierGED && el.setToggle(true))
    setTypeFichierGED(undefined)
  }
  const name = useName()

  return (
    <section className="section">
      <Title title="Documents" name={name} />
      <div className="section__content">
        <ButtonsRow handleAdd={() => setToggleAddDoc(true)} />
        <div className="sousSection">
          {isPP &&
            (personType === 'referentPP' || (personType === 'relationPP' && person.document) ? (
              <div className="cards cards--wrap">
                {documents?.map((el, index) => (
                  <div key={index} className="card">
                    <Card title={`${el.name} (${el.files.length})`} h2={true}>
                      <div className="informations">
                        <BlocTable
                          titles={el.titles}
                          data={el.files}
                          setFiles={el.setFiles}
                          tableType={el.tableType}
                          children={<TableBodyDoc />}
                          setDataChange={setDataChange}
                          doc={true}
                          title={`${el.name} (${el.files.length})`}
                          handleAdd={() => el.setToggle(true)}
                        />
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            ) : (
              <div className="informations">
                <p className="informations__error">
                  {`Vous n'avez pas les droits d'accès aux documents de ${name}`}
                </p>
              </div>
            ))}
        </div>
        {toggleAddDoc && (
          <Modale
            close={() => setToggleAddDoc(false)}
            title="Type de fichier"
            line1="Veuillez choisir le type de fichier que vous souhaitez ajouter :"
          >
            <div className="informations">
              {/* Options type fichier ged */}
              <SelectComponent
                forAndId="typedFichierGED"
                isSearchable={true}
                isClearable={true}
                options={optionsAddTypeFichierGed}
                placeholder="Sélectionner"
                onChange={(e) => setTypeFichierGED(e.value)}
                value={optionsAddTypeFichierGed.find((c) => c.value === typeFichierGED)}
                copy={true}
                noLabel={true}
              />
              <div className="informations__buttons">
                <div
                  className="button button--blue-red width--12"
                  onClick={() => selectToggle(typeFichierGED)}
                >
                  Enregistrer
                </div>
                <div
                  className="button button--blue-red width--12"
                  onClick={() => setToggleAddDoc(false)}
                >
                  Annuler
                </div>
              </div>
            </div>
          </Modale>
        )}
        {toggleAddIdentity && (
          <ModaleFiles
            data={fichiers}
            tableType={DOC_PERSON_IDENTITY}
            setModal={setToggleAddIdentity}
            setDataChange={setDataChange}
          />
        )}
        {toggleAddHome && (
          <ModaleFiles
            data={fichiers}
            tableType={DOC_PERSON_HOME}
            setModal={setToggleAddHome}
            setDataChange={setDataChange}
          />
        )}
        {toggleAddBirth && (
          <ModaleFiles
            data={fichiers}
            tableType={DOC_PERSON_BIRTH}
            setModal={setToggleAddBirth}
            setDataChange={setDataChange}
          />
        )}
        {toggleAddFamily && (
          <ModaleFiles
            data={fichiers}
            tableType={DOC_PERSON_FAMILY}
            setModal={setToggleAddFamily}
            setDataChange={setDataChange}
          />
        )}
        {toggleAddVarious && (
          <ModaleFiles
            data={fichiers}
            tableType={DOC_PERSON_DIVERS}
            setModal={setToggleAddVarious}
            setDataChange={setDataChange}
          />
        )}
      </div>
    </section>
  )
}

export default MyDocuments
