import React from 'react'
import { useSelector } from 'react-redux'
import { beautifulDate } from '../../../services/helpers/dates'

const PrintComponent = ({ componentRef, title, children }) => {
  const { user } = useSelector((state) => state.getUser)
  // person
  const { person } = useSelector((state) => state.getPerson)
  // today
  const date = new Date()
  const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

  return (
    <main style={{ display: 'none' }} className="print-hidden">
      <div className="print__ref" ref={componentRef}>
        <div className="informations">
          <h1 className="print__h1">
            <p>Mon Espace Patrimoine</p>
            <p>{`${person ? person.prenom : user.prenom} ${
              person ? person.nom : user.nom
            } - ${title}`}</p>
            <p>{beautifulDate(today)}</p>
          </h1>
          <hr className="hr" />
          <div className="cards">{children}</div>
        </div>
      </div>
    </main>
  )
}

export default PrintComponent
