import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import { postRevenu } from '../../../../../../services/API/revenus'
import { getMonthsBothDate } from '../../../../../../services/helpers/dates'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../services/helpers/detenteurs'
import {
  calculateAmountReverse,
  calculateAnnualAmount,
  supprId,
} from '../../../../../../services/helpers/helpers'
import { DOC_REVENU_AUTRE } from '../../../../../../constants'
import NotCorrect from '../../../../Components/Modales/NotCorrect'
import Ok from '../../../../Components/Modales/Ok'
import NotOk from '../../../../Components/Modales/NotOk'
import ModaleFiles from '../../../../Files/ModaleFiles'
import ModaleDivers from '../../../Patrimoine/ModaleDivers'
import FormPatrimoineContent from '../../../Patrimoine/FormPatrimoineContent'
import usePersonData from '../../../../../layout/usePersonData'
import useIncomesData from '../../Revenus/useIncomesData'
import useModalStates from '../../../Patrimoine/useModalStates'

const FormRevenuAutre = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const { incomes, revenusDivers, revenu } = useIncomesData()
  const autreRevenuDivers = incomes?.autreRevenuDivers.montant ?? null
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  const [status, setStatus] = useState(null)
  const modalStates = useModalStates()
  const [divers, setDivers] = useState(true)
  const [fichiersToDisplay, setFichiersToDisplay] = useState([])
  const [detenteursToDisplay, setDetenteursToDisplay] = useState([])
  const [newValeurTotale, setNewValeurTotale] = useState()
  // maj bien
  useEffect(() => {
    if (revenu) {
      setFichiersToDisplay(revenu.fichiers)
    }
  }, [revenu])
  // Informations
  const [information, setInformation] = useState({})
  // valeur totale de calcul
  const calculateValue = (info) => {
    if (!info.montant || !info.typePeriodicite) return 0
    const annualAmount = calculateAnnualAmount(info.montant, info.typePeriodicite)
    if (!info.dateFin) return (annualAmount * info.tauxIndivision) / 100
    const months = info.dateDebut
      ? getMonthsBothDate(info.dateDebut, info.dateFin)
      : getMonthsBothDate(new Date(), info.dateFin)
    return months > 12
      ? (annualAmount * info.tauxIndivision) / 100
      : (months * (annualAmount / 12) * info.tauxIndivision) / 100
  }
  useEffect(() => {
    setNewValeurTotale(calculateValue(information))
  }, [information])
  // maj si revenu
  const getDefaultDetenteurs = () => ({
    id: 0,
    nomDetenteur:
      personType === 'referentPP'
        ? `${person.nom} ${person.prenom}`
        : `${person.denominationSociale}`,
    mailDetenteur: person.mail,
    idPP: personType === 'referentPP' ? 1 : 0,
    idPM: personType === 'dirigeantPM' ? 1 : 0,
    dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
    dateContitutionUsufruit: '',
    dureeDemembrement: '',
    typeDetention: 'PLEINEPROPRIETE',
    tauxPleinePropriete: 100,
    tauxUsufruit: 0,
    tauxUsufruitCalcule: handleCalculateTaux(person.dateNaissance),
    tauxNuePropriete: 0,
    valeurDetenue: 0,
    valeurPP: 0,
    valeurUS: 0,
    valeurNP: 0,
    tempId: Date.now(),
  })
  const updateInformationFromRevenu = (revenu) => ({
    id: revenu.id,
    libelle: revenu.libelle,
    detail: revenu.detail,
    typeRevenu: revenu.typeRevenu,
    montant: calculateAmountReverse(revenu.montant, revenu.typePeriodicite),
    typePeriodicite: revenu.typePeriodicite,
    dateDebut: revenu.dateDebut,
    dateFin: revenu.dateFin,
    detention: revenu.detention,
    tauxIndivision: revenu.tauxIndivision,
    detenteurs: revenu.detenteurs
      .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
      .reverse(),
    fichiers: revenu.fichiers,
    typeActif: revenu.typeActif,
    idActif: revenu.idActif,
  })
  useEffect(() => {
    setInformation(
      revenu
        ? updateInformationFromRevenu(revenu)
        : {
            ...information,
            detention: 'CLIENT',
            tauxIndivision: 100,
            detenteurs: [getDefaultDetenteurs()],
          }
    )
  }, [revenu, person, personType])
  // form Revenu Autre is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Revenu autre' }))
    }
  }, [dataChange])
  // save Bien immo
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Revenu autre') {
      handlePreSubmit()
    }
  }, [saveBien])
  // Object to send
  const data = {
    ...information,
    montant: calculateAnnualAmount(information.montant, information.typePeriodicite),
    // montant: newValeurTotale,
    detenteurs: detenteursToDisplay?.map((det) =>
      updateDetenteurs(det, newValeurTotale, detenteursToDisplay)
    ),
  }
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(information.detenteurs)
  }, [information.detenteurs])
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    if (name === 'dateDebut' && information.typePeriodicite === 'UNIQUE') {
      setInformation({ ...information, [name]: date, dateFin: date })
    } else {
      setInformation({ ...information, [name]: date })
    }
  }
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    const { name, value } = e.target ? e.target : { name: selectName.name, value: e.value }
    if (name === 'typePeriodicite' && value === 'UNIQUE' && information.dateDebut) {
      setInformation((inf) => ({ ...inf, typePeriodicite: value, dateFin: inf.dateDebut }))
    } else {
      setInformation((inf) => ({ ...inf, [name]: value }))
    }
    if (name === 'divers') {
      setDivers(value)
    }
  }
  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (
      information.libelle !== '' &&
      information.detail !== '' &&
      information.typePeriodicite !== '' &&
      information.montant !== ''
    ) {
      setDetenteursToDisplay(
        detenteursToDisplay.map((det) => (det.newDetenteur ? supprId(det) : det))
      )
      revenusDivers === 0 && autreRevenuDivers === 0
        ? handleSubmit()
        : modalStates.setToggleDivers(true)
    } else {
      modalStates.setToggleAddNotOk(true)
    }
  }
  // submit
  const handleSubmit = () => {
    dispatch(postRevenu('autre', person.id, isPP, divers, data, setStatus))
    modalStates.setToggleDivers(false)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (revenu && revenu.id !== 0) {
      navigate('/user/patrimoine/revenus/autres/info')
    } else {
      navigate('/user/patrimoine/revenus/autres')
    }
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status) {
      if (status < 400) {
        dispatch(getFormIsActive(null))
        dispatch(getSaveForm(false))
        modalStates.setToggleAddOk(true)
      } else {
        modalStates.setToggleAddNotOk(true)
      }
      setStatus(null)
    }
  }, [status])
  // maj detenteurs
  // useEffect(() => {
  //   if (detenteursToDisplay?.length > 0) {
  //     setInformation({ ...information, detenteurs: detenteursToDisplay })
  //   }
  // }, [detenteursToDisplay])

  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    modalStates.setToggleAddOk(false)
    navigate('/user/patrimoine/revenus/autres')
  }
  // maj
  useEffect(() => {
    if (incomes && revenu) {
      const newBien = incomes.autresRevenus.autreRevenus.filter((el) => el.id === revenu.id)
      dispatch(getBienSuccess(newBien[0]))
    }
  }, [incomes])

  return (
    <div className="section__content">
      {/* form */}
      <FormPatrimoineContent
        dataToDisplay={revenu}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        information={information}
        setInformation={setInformation}
        detenteursToDisplay={detenteursToDisplay}
        setDetenteursToDisplay={setDetenteursToDisplay}
        fichiersToDisplay={fichiersToDisplay}
        setFichiersToDisplay={setFichiersToDisplay}
        setToggleAdd={modalStates.setToggleAdd}
        handleSubmit={handlePreSubmit}
        newValeurTotale={newValeurTotale}
        handleGiveUp={handleGiveUp}
        tableType={DOC_REVENU_AUTRE}
        foncier={false}
      />
      {/* modales */}
      {modalStates.toggleAdd && (
        <ModaleFiles
          data={information.fichiers}
          tableType={DOC_REVENU_AUTRE}
          setModal={modalStates.setToggleAdd}
          setDataChange={setDataChange}
        />
      )}
      {modalStates.toggleAddOk && (
        <Ok
          text={
            revenu
              ? 'Votre revenu a été modifié avec succès'
              : 'Votre revenu a été ajouté avec succès'
          }
          close={closeToggleAddBien}
        />
      )}
      {modalStates.toggleAddNotOk && (
        <NotOk setNotOK={modalStates.setToggleAddNotOk} closeModale={closeToggleAddBien} />
      )}
      {modalStates.toggleAddNotCorrect && (
        <NotCorrect setNotCorrect={modalStates.setToggleAddNotCorrect} />
      )}
      {modalStates.toggleDivers && (
        <ModaleDivers
          setToggleDivers={modalStates.setToggleDivers}
          handleChange={handleChange}
          divers={divers}
          setDivers={setDivers}
          handleSubmit={handleSubmit}
          montantCategorieDivers={revenusDivers}
          montantSousCategorieDivers={autreRevenuDivers}
          type={'revenus'}
          sousType={'autre'}
        />
      )}
    </div>
  )
}

export default FormRevenuAutre
