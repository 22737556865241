import React, { useEffect } from 'react'
import { sortByType } from '../../../../services/helpers/sort'
import TableBodyBloc from '../../table/TableBodyBloc'
import BodyJob from './BodyJob'
import BodyJobPrint from './BodyJobPrint'

const TableBodyJob = ({
  data,
  setData,
  setNewData,
  setModalUpdate,
  sort,
  targetToSort,
  read,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  setDataChange,
  print,
}) => {
  //suppr
  const handleDelete = (element) => {
    setData(data.filter((el) => el !== element))
    setDataChange(true)
  }
  // edit
  const handleEdit = (element) => {
    setNewData(element)
    setModalUpdate(true)
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Profession':
          giveAType('profession')
          break
        case 'CSP':
          giveAType('familleCSP')
          break
        case 'Code CSP':
          giveAType('codeRubriqueCSP')
          break
        case 'Statut':
          giveAType('status')
          break
        case 'Début':
          giveAType('dateDebut')
          break
        case 'Fin':
          giveAType('dateFin')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyJobPrint /> : <BodyJob />}
      read={read}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      print={print}
    />
  )
}

export default TableBodyJob
