import React from 'react'
import Modale from '../../../../Components/Modale'
import SelectComponent from '../../../../Components/SelectComponent'

const ModaleSelectForm = ({
  setToggleSelectForm,
  optionsCategorieForm,
  handleChange,
  categorieForm,
}) => {
  return (
    <Modale close={() => setToggleSelectForm(false)} title={'Choisir une catégorie'}>
      <div className="informations">
        {/* categorieForm */}
        <SelectComponent
          forAndId="categorieForm"
          name="Categorie :"
          isSearchable={false}
          options={optionsCategorieForm}
          placeholder="Sélectionner"
          onChange={(e) => handleChange(e)}
          value={optionsCategorieForm.find((c) => c.value === categorieForm)}
          copy={true}
        />

        <div className="informations__buttons">
          <div
            className="button button--blue-red width--12"
            onClick={() => setToggleSelectForm(false)}
          >
            Fermer
          </div>
        </div>
      </div>
    </Modale>
  )
}

export default ModaleSelectForm
