import React, { useEffect } from 'react'
import Card from '../Components/Card'
import BlocTable from '../table/BlocTable'
import { useDispatch, useSelector } from 'react-redux'
import { getMessages } from '../../../services/API/connexion'
import { USER_MESSAGES } from '../../../constants'
import TableBodyMessages from './TableBodyMessages'

const MessagesCard = () => {
  const dispatch = useDispatch()
  const messages = useSelector((state) => state.getMessages.messages)
  // recuperation des messages
  useEffect(() => {
    !messages && dispatch(getMessages())
  }, [])
  // titles
  const title = `Messages (${messages.length})`
  const titles = ['Date', 'Message', 'Origine', 'Supprimer']

  return messages && messages.length > 0 ? (
    <div className="cards cards--fullWidth ">
      <Card title={title} h2={true} open={true}>
        <div className="informations">
          <BlocTable
            titles={titles}
            data={messages}
            tableType={USER_MESSAGES}
            children={<TableBodyMessages />}
            noLineNumber={true}
            title={title}
          />
        </div>
      </Card>
    </div>
  ) : null
}

export default MessagesCard
