import React from 'react'
import { useSelector } from 'react-redux'
import InformationLine from './InformationLine'

const GeneralInformationsCard = ({ elementToDisplay, dataToDisplay, categorie }) => {
  // store
  const { properties } = useSelector((state) => state.getProperties)
  const { dirigeantPM } = useSelector((state) => state.getPersons.persons)

  const renderInformationLine = (data, index) => {
    if (!data) {
      return null
    }
    if (data.label === 'Age :' && data.value === 'SANS') {
      return null
    }
    if (data.label === 'Relié au bien :' && data.value === '') {
      return null
    }
    const selectBien = data.label === 'Relié au bien :' && data.value !== 'bien non retrouvé'
    const selectPM =
      (data.label === 'Personne morale :' || data.label === 'Employeur :') && dirigeantPM

    return (
      <div key={data.label + index}>
        <InformationLine data={data} selectBien={selectBien} selectPM={selectPM} />
      </div>
    )
  }

  return (
    <div className={'informations'}>
      {elementToDisplay &&
        dataToDisplay(
          elementToDisplay,
          categorie || null,
          elementToDisplay.idActif ? properties : null,
          elementToDisplay.lienPM ? dirigeantPM : null
        ).map(renderInformationLine)}
    </div>
  )
}

export default GeneralInformationsCard
