import React from 'react'
import { capitalizeFirstLetter } from '../../../services/helpers/strings'
import { useSelector } from 'react-redux'

const BodyPP = ({ element, handleShow }) => {
  // user
  const user = useSelector((state) => state.getUser.user)
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // relation
  const getRelation = (personne) => {
    const titulaire = persons.referentPP.filter((el) => el.mail === user.email)[0]
    const relationsTitulaire = titulaire.relations
    if (relationsTitulaire.find((el) => el.idPP2 === personne.id)) {
      return capitalizeFirstLetter(
        relationsTitulaire.filter((el) => el.idPP2 === personne.id)[0].typeRelation
      )
    } else if (personne === titulaire) {
      return 'Titulaire'
    } else {
      return null
    }
  }

  return (
    <>
      {/* Nom */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {element.nom === '' || !element.nom ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.nom
        )}
      </td>
      {/* prenom */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {element.prenom === '' || !element.prenom ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.prenom
        )}
      </td>
      {/* mail */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {element.mail === '' || !element.mail ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.mail
        )}
      </td>
      {/* lien */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {getRelation(element) ? (
          getRelation(element)
        ) : (
          <p className="informations__line__notxt">-</p>
        )}
      </td>
    </>
  )
}

export default BodyPP
