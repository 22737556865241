import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { getFinancier } from '../../../../../../services/API/financier'
import NavFinancier from './NavFinancier'
import usePersonData from '../../../../../layout/usePersonData'
import LittleLoader from '../../../../../layout/LittleLoader'

const ActifsFinancier = () => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  // financier
  const financier = useSelector((state) => state.getFinancier.financier)
  // recuperation financier
  useEffect(() => {
    dispatch(getFinancier(person.id, isPP))
  }, [person, dispatch])

  if (!financier) return <LittleLoader />

  return (
    <>
      <NavFinancier />
      <Outlet />
    </>
  )
}

export default ActifsFinancier
