import React from 'react'
import Input from '../Components/Input'
import DateInputComponent from '../Components/DateInputComponent'
import { RxCheck } from 'react-icons/rx'
import { IoClose } from 'react-icons/io5'
import Modale from '../Components/Modale'

const InputsSignup = ({
  handleClose,
  handleSubmit,
  handleChange,
  handleChangeDate,
  fields,
  progress,
  submit,
  checks,
}) => {
  const { lastName, firstName, email, birthDate, password, confirmPassword } = fields || {}
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return (
    <main className="sign__main bkg">
      <Modale close={handleClose} sign={true} title="Inscription">
        <form action="" method="POST" className="form">
          {/* Nom */}
          <Input
            sign={true}
            forAndId="lastName"
            type="texte"
            name="Nom "
            required={true}
            value={lastName}
            onChange={(e) => handleChange(e)}
            placeholder="Nom (4 lettres minimum)"
          />
          {/* Prénom */}
          <Input
            sign={true}
            forAndId="firstName"
            type="texte"
            name="Prénom"
            required={true}
            value={firstName}
            onChange={(e) => handleChange(e)}
            placeholder="Prénom (3 lettres minimum)"
          />
          {/* Email */}
          <Input
            sign={true}
            forAndId="email"
            type="email"
            name="Mail"
            required={true}
            value={email}
            onChange={(e) => handleChange(e)}
            placeholder="exemple@gmail.com"
          />
          {/* Date de naissance */}
          <DateInputComponent
            sign={true}
            name={'birthDate'}
            label={'Date de naissance :'}
            value={birthDate}
            handleChange={handleChangeDate}
          />
          {/* Mot de passe */}
          <Input
            sign={true}
            forAndId="password"
            type={'password'}
            name="Mot de passe"
            required={true}
            value={password}
            onChange={(e) => handleChange(e)}
            min={6}
            max={50}
            icon={true}
          />
          {progress !== 100 && password ? (
            <div className="input--progress">
              <progress value={progress} max="100" className="progress"></progress>
              <ul className="progress__list">
                <li className={`progress__item${checks.uppercase ? '--checked' : '--unchecked'}`}>
                  <span>{checks.uppercase ? <RxCheck /> : <IoClose />}</span> Au moins 1 majuscule
                </li>
                <li className={`progress__item${checks.lowercase ? '--checked' : '--unchecked'}`}>
                  <span>{checks.lowercase ? <RxCheck /> : <IoClose />}</span> Au moins 1 minuscule
                </li>
                <li className={`progress__item${checks.digit ? '--checked' : '--unchecked'}`}>
                  <span>{checks.digit ? <RxCheck /> : <IoClose />}</span> Au moins 1 chiffre
                </li>
                <li className={`progress__item${checks.specialChar ? '--checked' : '--unchecked'}`}>
                  <span>{checks.specialChar ? <RxCheck /> : <IoClose />}</span> Au moins 1 caractère
                  spécial (sans espaces)
                </li>
                <li className={`progress__item${checks.length ? '--checked' : '--unchecked'}`}>
                  <span>{checks.length ? <RxCheck /> : <IoClose />}</span> Au moins 6 caractères
                </li>
              </ul>
            </div>
          ) : null}
          {/* Confirmation mot de passe */}
          <Input
            sign={true}
            errorInput={submit && confirmPassword !== password}
            forAndId="confirmPassword"
            type="password"
            name="Confirmation du mot de passe"
            required={true}
            value={confirmPassword}
            onChange={(e) => handleChange(e)}
            message="Le mot de passe ne correspond pas"
            icon={true}
          />
          {/* Envoi du formulaire */}
          <div className="form__bloc__button">
            {lastName &&
            lastName.length >= 4 &&
            firstName &&
            firstName.length >= 3 &&
            email &&
            regexEmail.test(email) &&
            password &&
            progress === 100 &&
            confirmPassword === password ? (
              <button
                type="submit"
                className={'button button--red-red width--12'}
                onClick={(e) => handleSubmit(e)}
                tabIndex={1}
              >
                S'inscrire
              </button>
            ) : (
              <div className={'button button--grey width--12'}>S'inscrire</div>
            )}
          </div>
        </form>
      </Modale>
    </main>
  )
}

export default InputsSignup
