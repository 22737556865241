import React, { useState } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Copy from './Copy'

const SelectComponent = ({
  forAndId,
  name,
  errorLabel,
  options,
  placeholder,
  onChange,
  value,
  isClearable,
  isSearchable,
  isDisabled,
  bold,
  copy,
  long,
  noLabel,
  isMulti,
  creatable,
  formatCreateLabel,widthAuto
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [newValue, setNewValue] = useState(value)
  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      width: '100%',
    }),
  }
  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  })
  const handleCreate = (inputValue) => {
    setIsLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)
      setIsLoading(false)
      setNewValue(newOption)
      onChange(inputValue, forAndId)
    }, 200)
  }

  return (
    <div className={noLabel ? widthAuto ? 'input input--noLabel input--widthAuto':'input input--noLabel' : 'input'}>
      <label
        className={
          errorLabel
            ? 'input__label input__label--error'
            : bold
            ? 'input__label input__label--bold'
            : long
            ? 'input__label input__label--long'
            : noLabel
            ? 'sr-only'
            : 'input__label'
        }
        htmlFor={forAndId}
      >
        {name}
      </label>
      <div className={'input__inputBloc'}>
        <div className="input__inputBloc__divSelect">
          {creatable ? (
            <CreatableSelect
              classNamePrefix="react-select"
              options={options}
              value={newValue}
              name={forAndId}
              htmlFor={forAndId}
              // htmlFor={'demo-simple-select-placeholder-label'}
              isClearable={isClearable}
              isSearchable={isSearchable}
              onChange={onChange}
              placeholder={placeholder}
              inputProps={{ id: forAndId }}
              classNames="react-select-container"
              isDisabled={isDisabled}
              isMulti={isMulti}
              menuPosition={'fixed'}
              styles={customStyles}
              formatCreateLabel={formatCreateLabel}
              onCreateOption={handleCreate}
              isLoading={isLoading}
              inputId={forAndId}
              id={forAndId}
            />
          ) : (
            <Select
              classNamePrefix="react-select"
              options={options}
              value={value}
              name={forAndId}
              isClearable={isClearable}
              isSearchable={isSearchable}
              onChange={onChange}
              placeholder={placeholder}
              inputProps={{ id: { forAndId } }}
              classNames="react-select-container"
              isDisabled={isDisabled}
              isMulti={isMulti}
              menuPosition={'fixed'}
              styles={customStyles}
              formatCreateLabel={formatCreateLabel}
              menuPortalTarget={document.body}
              closeMenuOnScroll={(event) => {
                return event.target.id === 'scrollContainer'
              }}
            />
          )}
          {copy && <Copy value={value && value.value} />}
        </div>
      </div>
    </div>
  )
}

export default SelectComponent
