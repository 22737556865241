import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from '../../../services/API/connexion'
import ModalAddUser from '../../components/User/ModalAddUser'
import ModalAddUserNotOk from '../../components/User/ModalAddUserNotOk'
import ModalAddUserOk from '../../components/User/ModalAddUserOk'
import UsersTableCard from './UsersTableCard'
import NotCorrect from '../../components/Components/Modales/NotCorrect'

const AdminUserManagement = () => {
  const dispatch = useDispatch()
  const [statusAddUser, setStatusAddUser] = useState(null)
  // const [statusUpdateUser, setStatusUpdateUser] = useState(null)
  // users
  const [dataUsers, setDataUsers] = useState([])

  const selectUser = (state) => state.getUsers.users
  const users = useSelector(selectUser)

  // modales
  const [modalAddUser, setModalAddUser] = useState(false)
  const [modalAddUserOk, setModalAddUserOk] = useState(false)
  const [modalAddUserNotOk, setModalAddUserNotOk] = useState(false)
  const [modalAddUserNotCorrect, setModalAddUserNotCorrect] = useState(false)

  useEffect(() => {
    users ? setDataUsers(users) : dispatch(getUsers())
  }, [users])
  // response status and modal
  useEffect(() => {
    if (statusAddUser) {
      if (statusAddUser < 400) {
        setModalAddUserOk(true)
        dispatch(getUsers())
      } else {
        setModalAddUserNotOk(true)
      }
      setStatusAddUser(null)
    }
  }, [statusAddUser])

  return (
    <section className="section">
      <h1 className="h1">Gestion des utilisateurs</h1>
      <div className="cards cards--fullWidth">
        <UsersTableCard
          dataUsers={dataUsers}
          setDataUsers={setDataUsers}
          setModalAddUser={setModalAddUser}
        />
      </div>
      {modalAddUser && (
        <ModalAddUser
          setModalAddUser={setModalAddUser}
          setModalNotCorrect={setModalAddUserNotCorrect}
          setStatus={setStatusAddUser}
        />
      )}
      {/* modal response ok _________________________________________________*/}
      {modalAddUserOk && (
        <ModalAddUserOk
          setModalOK={setModalAddUserOk}
          setModalAddUser={setModalAddUser}
          setStatus={setStatusAddUser}
        />
      )}
      {/* modal response not ok _____________________________________________*/}
      {modalAddUserNotOk && (
        <ModalAddUserNotOk
          setModalNotOk={setModalAddUserNotOk}
          setModalAddUser={setModalAddUser}
          setStatus={setStatusAddUser}
        />
      )}
      {/* modal not correct __________________________________________________*/}
      {modalAddUserNotCorrect && <NotCorrect setNotCorrect={setModalAddUserNotCorrect} />}
    </section>
  )
}

export default AdminUserManagement
