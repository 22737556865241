import React from 'react'
import Title from '../../layout/Title'
import PatrimoinePatrimoine from '../../components/Patrimoine/Patrimoine/PatrimoinePatrimoine'
import usePersonData from '../../layout/usePersonData'
import useName from '../../layout/useName'

const MyProperty = () => {
  const { person, personType, isPP } = usePersonData()
  const name = useName()

  return (
    <section className="section">
      <Title title="Patrimoine" name={name} />
      {personType === 'referentPP' ||
      (personType === 'relationPP' && person.information) ||
      !isPP ? (
        <>
          <PatrimoinePatrimoine />
        </>
      ) : (
        <div className="informations">
          <p className="informations__error">
            Vous n'avez pas les droits d'accès aux informations de {name}
          </p>
        </div>
      )}
    </section>
  )
}

export default MyProperty
