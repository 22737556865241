import React from 'react'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import SocialPieChart from './SocialPieChart'

const SocialToPrint = ({ elementToDisplay, values }) => {
  const { valeurTotaleSociale } = elementToDisplay || {}
  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurTotaleSociale}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<SocialPieChart social={elementToDisplay} />}
        detenteurs={true}
      />
    </>
  )
}

export default SocialToPrint
