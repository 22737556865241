import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getCSP } from '../../../../services/API/connexion'
import InputsActivity from '../InputsActivity'
import Modale from '../../Components/Modale'

const ModaleActivity = ({ data, setData, setModal, newData, setDataChange }) => {
  const dispatch = useDispatch()
  //
  const [CSP, setCSP] = useState(null)
  const [newProfession, setNewProfession] = useState({})
  const { familleCSP, codeRubriqueCSP } = newProfession || {}
  const [optionsCSP, setOptionsCSP] = useState()
  const [optionsCodeCSP, setOptionsCodeCSP] = useState()
  // delete
  const jobToDelete = newData

  useEffect(() => {
    dispatch(getCSP(setCSP))
  }, [])
  // Maj newProfession
  useEffect(() => {
    newData
      ? setNewProfession({
          profession: !newData.profession ? '' : newData.profession,
          familleCSP: !newData.familleCSP ? '' : newData.familleCSP,
          codeRubriqueCSP: !newData.codeRubriqueCSP ? '' : newData.codeRubriqueCSP,
          status: !newData.status ? '' : newData.status,
          dateDebut: !newData.dateDebut ? '' : newData.dateDebut,
          dateFin: !newData.dateFin ? '' : newData.dateFin,
        })
      : setNewProfession({
          ...newProfession,
          profession: '',
          familleCSP: '',
          codeRubriqueCSP: '',
          status: '',
          dateDebut: '',
          dateFin: '',
        })
  }, [])
  // Maj options CSP & code CSP
  useEffect(() => {
    if (CSP) {
      setOptionsCSP(
        Object.keys(CSP)
          .sort()
          .reverse()
          .map((element) => ({ value: element, label: element }))
      )
      setOptionsCodeCSP(
        Object.values(CSP)
          .flat()
          .sort()
          .map((element) => ({ value: element, label: element }))
      )
    }
    if (CSP && familleCSP) {
      setOptionsCodeCSP(CSP[familleCSP].map((element) => ({ value: element, label: element })))
    }
    if (CSP && codeRubriqueCSP) {
      for (let i in CSP) {
        if (CSP[i].indexOf(codeRubriqueCSP) !== -1) {
          setNewProfession({ ...newProfession, familleCSP: i })
        }
      }
    }
  }, [CSP, familleCSP, codeRubriqueCSP])
  // handleAddJob
  const handleAddJob = () => {
    setDataChange(true)
    if (newData) {
      setData([...data.filter((el) => el !== jobToDelete), newProfession])
    } else {
      setData([...data, newProfession])
    }
    setModal(false)
  }
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e) {
      if (e.target !== undefined) {
        const { name, value } = e.target
        setNewProfession({ ...newProfession, [name]: value })
      } else {
        const name = selectName.name
        const value = e.value
        setNewProfession({ ...newProfession, [name]: value })
      }
    } else {
      const name = selectName.name
      setNewProfession({ ...newProfession, [name]: undefined })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setNewProfession({ ...newProfession, [name]: date })
  }

  return (
    <Modale close={() => setModal(false)} title={newData ? 'Modifier' : 'Ajouter'}>
      <div className="informations">
        <InputsActivity
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
          handleAddJob={handleAddJob}
          optionsCSP={optionsCSP}
          optionsCodeCSP={optionsCodeCSP}
          data={newProfession}
        />
      </div>
    </Modale>
  )
}

export default ModaleActivity
