import React, { useEffect, useState } from 'react'
import Modale from '../Components/Modale'
import Signataire from './Signataire'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteSign,
  getSignaturesPending,
  getSignaturesToClassify,
} from '../../../services/helpers/files'
import { getParapheurSuccess } from '../../../redux/actions/actionGetParapheur'
import { getPersonnes } from '../../../services/API/personnes'
import { getContacts } from '../../../services/API/connexion'
import ModalParapheur from '../Parapheur/ModalParapheur'
import PendingCard from './PendingCard'
import ToClassifyCard from './ToClassifyCard'
import ToSignCard from './ToSignCard'
import Title from '../../layout/Title'
import ButtonsRow from '../Patrimoine/Patrimoine/ButtonsRow'

const Signatures = ({
  personnes,
  personsToDisplay,
  signataires,
  setSignataires,
  setToggleAdd,
  files,
  setFiles,
  distance,
  setDistance,
  handleValidate,
  signaturesPendingToDisplay,
  signaturesToClassifyToDisplay,
  toggleAdd,
  newData,
  setNewData,
  toggleSign,
  setToggleSign,
  setToggleFetchOk,
  toggleFetchOk,
  toggleValidate,
  setToggleValidate,
  contactsTodisplay,
  reset,
  result,
}) => {
  const dispatch = useDispatch()
  const id = useSelector((state) => state.getIdTransaction.id)
  const [status, setStatus] = useState(null)
  //suppr signataire
  const handleSuppr = (element) => {
    setSignataires(signataires.filter((el) => el !== element))
    handleCloseFetchOk()
  }
  //close modal parapheur
  const closeParapheur = () => {
    dispatch(getParapheurSuccess(null))
    setToggleSign(false)
  }
  //add signataire
  useEffect(() => {
    if (newData !== undefined && newData.telPortable !== '' && newData.telPortable) {
      setSignataires([...signataires, newData])
    }
  }, [newData])
  // handleCloseFetchOk
  const handleCloseFetchOk = () => {
    dispatch(getSignaturesPending())
    dispatch(getSignaturesToClassify())
    dispatch(getParapheurSuccess(null))
    dispatch(getPersonnes())
    setToggleFetchOk(false)
  }
  // deleteSignature
  const deleteSignature = () => {
    dispatch(deleteSign(id, setStatus))
    dispatch(getParapheurSuccess(null))
    dispatch(getPersonnes())
  }
  // response suppr signature
  useEffect(() => {
    if (status) {
      setToggleSign(false)
      setStatus(null)
    }
  }, [status])
  // closeToggleValidate
  const closeToggleValidate = () => {
    dispatch(getContacts())
    setToggleValidate(false)
  }

  return (
    <section className="section">
      <Title title="Signatures" />
      <div className="section__content">
        <ButtonsRow />
        <div className="sousSection">
          {signaturesPendingToDisplay &&
            signaturesToClassifyToDisplay &&
            (signaturesPendingToDisplay.length > 0 || signaturesToClassifyToDisplay.length > 0) && (
              <div
                className={
                  signaturesPendingToDisplay &&
                  signaturesToClassifyToDisplay &&
                  (signaturesPendingToDisplay.length === 0 ||
                    signaturesToClassifyToDisplay.length === 0)
                    ? 'cards cards--fullWidth'
                    : 'cards'
                }
              >
                {/* en instance */}
                {signaturesPendingToDisplay.length > 0 && (
                  <PendingCard signaturesPendingToDisplay={signaturesPendingToDisplay} />
                )}
                {/* à classer */}
                {signaturesToClassifyToDisplay.length > 0 && (
                  <ToClassifyCard signaturesToClassifyToDisplay={signaturesToClassifyToDisplay} />
                )}
              </div>
            )}
          {/* signer */}
          <div className={'cards cards--fullWidth'}>
            <ToSignCard
              personnes={personnes}
              personsToDisplay={personsToDisplay}
              signataires={signataires}
              setSignataires={setSignataires}
              contactsTodisplay={contactsTodisplay}
              handleSuppr={handleSuppr}
              setToggleAdd={setToggleAdd}
              files={files}
              setFiles={setFiles}
              distance={distance}
              setDistance={setDistance}
              handleValidate={handleValidate}
              reset={reset}
            />
          </div>
          {/* ajouter signataire */}
          {toggleAdd && (
            <Signataire
              setModalUpdate={setToggleAdd}
              newData={newData}
              setNewData={setNewData}
              title="Ajouter un signataire"
            />
          )}
          {/* doc non signé */}
          {toggleSign && (
            <Modale
              close={() => closeParapheur()}
              title={'Document non signé !'}
              line1="Les documents doivent être signés pour pouvoir les récupérer"
            >
              <div className="informations__buttons">
                <div className="button button--red-red width--18" onClick={() => deleteSignature()}>
                  Supprimer la transaction
                </div>
                <div className="button button--blue-red width--12" onClick={() => closeParapheur()}>
                  Fermer
                </div>
              </div>
            </Modale>
          )}
          {/* recup ok */}
          {toggleFetchOk && (
            <Modale
              close={() => handleCloseFetchOk()}
              title="Document récupéré avec succès !"
              line1="Vous allez maintenant pouvoir classer ce document"
            >
              <div className="informations__buttons">
                <div
                  className="button button--blue-red width--12"
                  onClick={() => handleCloseFetchOk()}
                >
                  Fermer
                </div>
              </div>
            </Modale>
          )}
          {/* validate ok */}
          {toggleValidate && <ModalParapheur closeModal={closeToggleValidate} result={result} />}
        </div>
      </div>
    </section>
  )
}

export default Signatures
