import React from 'react'
import Card from '../Components/Card'
import BlocTable from '../table/BlocTable'
import { USER_SIGNATURES_INSTANCE } from '../../../constants'
import TableBodySignPending from './Table/TableBodySignPending'

const PendingCard = ({ signaturesPendingToDisplay }) => {
  const title = `Documents en instance ${
    signaturesPendingToDisplay && `(${signaturesPendingToDisplay.length})`
  }`
  const titles = ['N°', 'Récupérer', 'Fichier', 'Date', 'Type de transaction', 'Voir', 'Récupérer']
  return (
    <Card
      title={title}
      open={signaturesPendingToDisplay && (signaturesPendingToDisplay.length === 0 ? false : true)}
    >
      <div className="informations">
        {signaturesPendingToDisplay && signaturesPendingToDisplay.length > 0 ? (
          <BlocTable
            titles={titles}
            data={signaturesPendingToDisplay}
            tableType={USER_SIGNATURES_INSTANCE}
            children={<TableBodySignPending />}
            doc={true}
            title={title}
          />
        ) : (
          <p>Auncun document à afficher</p>
        )}
      </div>
    </Card>
  )
}

export default PendingCard
