import { useLocation } from 'react-router-dom'

const useCustomPathName = () => {
  const { pathname } = useLocation()

  const getPathName = (pathname) => {
    const arr = pathname.split('/')
    const newArr = arr.map((el) => (el !== 'user' ? `${el}` : ''))
    return newArr.filter((el) => el && el).join(' - ')
  }

  const titlePrint = getPathName(pathname)

  return titlePrint
}

export default useCustomPathName
