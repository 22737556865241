import React from 'react'
import { Outlet } from 'react-router-dom'
import ResponsiveNavComponent from './ResponsiveNavComponent'

const PatrimoineCharge = () => {
  const links = [
    {
      name: 'Dépense courante',
      link: '/user/patrimoine/charges/depenses-courantes',
      type: 'charge',
    },
    { name: 'Pension', link: '/user/patrimoine/charges/pensions', type: 'charge' },
    {
      name: 'Emprunt / Dette',
      link: '/user/patrimoine/charges/emprunts-et-dettes',
      type: 'charge',
    },
    { name: 'Charge déductible', link: '/user/patrimoine/charges/deductibles-RI', type: 'charge' },
    { name: "Crédit d'impôt", link: '/user/patrimoine/charges/reduction-RI', type: 'charge' },
    { name: 'Impôt / Taxe', link: '/user/patrimoine/charges/impots-taxes', type: 'charge' },
  ]

  return (
    <>
      <ResponsiveNavComponent links={links} type={'charge'} />
      <Outlet />
    </>
  )
}

export default PatrimoineCharge
