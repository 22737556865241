import React from 'react'
import InputsBoss from '../Inputs/InputsBoss'

const Boss = ({ infoBoss, setInfoBoss, setDataChange }) => {
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      setInfoBoss({ ...infoBoss, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      setInfoBoss({ ...infoBoss, [name]: value })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setInfoBoss({ ...infoBoss, [name]: date })
  }

  return (
    <InputsBoss
      handleChange={handleChange}
      handleChangeDate={handleChangeDate}
      infoBoss={infoBoss}
    />
  )
}

export default Boss
