import React from 'react'
import {
  REVENUS_AUTRES,
  REVENUS_FONCIERS,
  REVENUS_MOBILIERS,
  REVENUS_PENSIONS,
} from '../../../../../../constants'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import {
  optionsTypePR,
  optionsTypeRevenuProfessionnel,
} from '../../../../../../data/options/optionsRevenus'

const BodyRevenuPrint = ({ element, index, tableType }) => {
  // categorie
  const handleSelectProperty = (element) => {
    return tableType === REVENUS_AUTRES ||
      tableType === REVENUS_FONCIERS ||
      tableType === REVENUS_MOBILIERS
      ? element.detail
      : tableType === REVENUS_PENSIONS
      ? element.typePensionRente
      : element.typeRevenuProfessionnel
  }

  return (
    <>
      {/* libelle */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Libellé</p>
        <p className="print__table__bloc__line--value">
          {!element.libelle ? '-' : element.libelle}
        </p>
      </div>
      {/* Catégorie */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Catégorie</p>
        <p className="print__table__bloc__line--value">
          {!handleSelectProperty(element)
            ? '-'
            : tableType === REVENUS_AUTRES ||
              tableType === REVENUS_FONCIERS ||
              tableType === REVENUS_MOBILIERS
            ? element.detail
            : tableType === REVENUS_PENSIONS
            ? recupLabel(element.typePensionRente, optionsTypePR)
            : recupLabel(element.typeRevenuProfessionnel, optionsTypeRevenuProfessionnel)}
        </p>
      </div>
      {/* montant */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Montant annuel</p>
        <p className="print__table__bloc__line--value">
          {!element.montant && element.montant !== 0
            ? '-'
            : `${element.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
        </p>
      </div>
      {/* typePeriodicite */}
      {/* <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Periodicité</p>
        <p className="print__table__bloc__line--value">
          {!element.typePeriodicite
            ? '-'
            : recupLabel(element.typePeriodicite, optionsTypePeriodicite)}
        </p>
      </div> */}
    </>
  )
}

export default BodyRevenuPrint
