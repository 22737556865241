import React, { useEffect, useState } from 'react'
import Card from '../Components/Card'
import { useDispatch, useSelector } from 'react-redux'
import { getMandatSuccess } from '../../../redux/actions/actionGetMandat'
import ModalMandat from '../Mandat/ModalMandat'
import Modale from '../Components/Modale'
import ModalParapheur from '../Parapheur/ModalParapheur'
import { useNavigate } from 'react-router-dom'
import NotOk from '../Components/Modales/NotOk'

const ProcurationCard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // url de parapher
  const mandat = useSelector((state) => state.getMandat.mandat)
  const [url, setUrl] = useState(null)
  // modale
  const [toggleAddMandat, setToggleAddMandat] = useState(false)
  const [toggleAddDroits, setToggleAddDroits] = useState(false)
  const [toggleMailNotExist, setToggleMailNotExist] = useState(false)
  const [toggleMandatOk, setToggleMandatOk] = useState(false)
  const [toggleMandatNotOk, setToggleMandatNotOk] = useState(false)
  // maj url
  useEffect(() => {
    setUrl(mandat)
  }, [mandat])

  // openMandat
  const openMandat = (type) => {
    dispatch(getMandatSuccess(null))
    setUrl(null)
    type === 'mandat' ? setToggleAddMandat(true) : setToggleAddDroits(true)
  }
  // fermeture mail doesn't exist
  const closeModal = () => {
    setToggleMailNotExist(false)
    setToggleMandatNotOk(false)
    setToggleAddMandat(false)
    setToggleAddDroits(false)
    if (toggleMandatOk) {
      setToggleMandatOk(false)
      navigate('/user/signature')
    }
  }

  return (
    <>
      <Card title="Procuration" h2={true} open={true}>
        <div className="informations informations--center">
          <div className="informations__buttons informations__buttons--noBorder">
            <button className="button button--red-red" onClick={() => openMandat('mandat')}>
              Donner procuration
            </button>
            <button className="button button--yellow-yellow" onClick={() => openMandat('droits')}>
              Droits d'accès
            </button>
          </div>
        </div>
      </Card>
      {/* modales */}
      {toggleAddMandat && (
        <ModalMandat
          setToggleAddMandat={setToggleAddMandat}
          setToggleMailNotExist={setToggleMailNotExist}
          setToggleMandatOk={setToggleMandatOk}
          setToggleMandatNotOk={setToggleMandatNotOk}
          url={url}
          setUrl={setUrl}
          proc={true}
          droits={false}
        />
      )}
      {toggleAddDroits && (
        <ModalMandat
          setToggleAddDroits={setToggleAddDroits}
          setToggleMailNotExist={setToggleMailNotExist}
          setToggleMandatOk={setToggleMandatOk}
          setToggleMandatNotOk={setToggleMandatNotOk}
          url={url}
          setUrl={setUrl}
          proc={false}
          droits={true}
        />
      )}
      {toggleMailNotExist && (
        <Modale
          close={() => setToggleMailNotExist(false)}
          title="Email non trouvé !"
          line1="Veuillez vous rapprocher de votre mandataire"
        >
          <div className="informations__buttons">
            <div
              className="button button--blue-red width--12"
              onClick={() => setToggleMailNotExist(false)}
            >
              Réessayer
            </div>
            <div className="button button--blue-red width--12" onClick={() => closeModal()}>
              Abandonner
            </div>
          </div>
        </Modale>
      )}
      {toggleMandatOk && <ModalParapheur closeModal={closeModal} result={url} />}
      {toggleMandatNotOk && <NotOk setNotOK={setToggleMandatNotOk} closeModale={closeModal} />}
    </>
  )
}

export default ProcurationCard
