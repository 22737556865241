import React, { useState } from 'react'
import Card from '../Components/Card'
import BlocTable from '../table/BlocTable'
import { PP_ACTIVITY } from '../../../constants'
import TableBodyJob from './Table/TableBodyJob'
import ModaleActivity from './Modales/ModaleActivity'

const Activity = ({ professions, setProfessions, setDataChange }) => {
  // modale
  const [modalAddJob, setModalAddJob] = useState(false)
  // titles
  const title = 'Activités'
  const titles = ['N°', 'Profession', 'CSP', 'Code CSP', 'Statut','Début', 'Fin', 'Modifier', 'Supprimer']

  return (
    <>
      <Card title={title} open={true} h2={true} >
          <div className="informations">
            <BlocTable
              titles={titles}
              title={title}
              setDataChange={setDataChange}
              data={professions}
              setData={setProfessions}
              tableType={PP_ACTIVITY}
              children={<TableBodyJob />}
              handleAdd={() => setModalAddJob(true)}
            />
          </div>
      </Card>
      {/* modal job supplémentaire ______________________________________________________*/}
      {modalAddJob && (
        <ModaleActivity
          setModal={setModalAddJob}
          data={professions}
          setData={setProfessions}
          setDataChange={setDataChange}
        />
      )}
    </>
  )
}

export default Activity
