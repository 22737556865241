// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
import {
  getSignaturesPendingLoad,
  getSignaturesPendingSuccess,
  getSignaturesPendingError,
} from '../actions/actionGetSignaturesPending'
// actions imports

// REDUCER // ______________________________________________________________

// initial state
const initialStateGetSignaturesPending = {
  isLoading: false,
  signaturesPending: null,
  error: '',
}
// reducer
export const getSignaturesPendingReducer = createReducer(initialStateGetSignaturesPending, (builder) => {
  return builder
    .addCase(getSignaturesPendingLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getSignaturesPendingSuccess, (draft, action) => {
      draft.isLoading = false
      draft.signaturesPending = action.payload
      draft.error = ''
      return
    })
    .addCase(getSignaturesPendingError, (draft, action) => {
      draft.isLoading = false
      draft.signaturesPending = null
      draft.error = action.payload
      return
    })
})
