// IMPORTS // ______________________________________________________________

import { configureStore } from '@reduxjs/toolkit'
import { resettableRootReducer } from '../reducers/reducers'
// import { composeWithDevTools } from 'redux-devtools-extension'

// devToolsConfig // __________________________________________________________________
const devToolsConfig = {
  maxAge: 10,
  features: {
    skip: false,
  },
  actionSanitizer: (action) =>
    action.type === 'get-catalogue-commun-success' ||
    action.type === 'get-dicis-success' ||
    action.type === 'get-financier-success' ||
    action.type === 'get-social-success' ||
    action.type === 'get-properties-success'
      ? { ...action, payload: '<<LONG_BLOB>>' }
      : action,
  stateSanitizer: (state) => {
    const sanitizedState = { ...state }
    delete sanitizedState.token
    delete sanitizedState.getCatalogueCommun
    delete sanitizedState.getDicis
    // Serialize large objects
    Object.keys(sanitizedState).forEach((key) => {
      if (key === 'catalogue' || key === 'dicis') {
        sanitizedState[key] = []
      }
    })
    return sanitizedState
  },
  serialize: {
    options: {
      replacer: (key, value) => {
        if (key === 'catalogue' || key === 'dicis') {
          // Exclude large objects
          return undefined
        } else if (typeof value === 'function') {
          // Exclude functions
          return null
        } else {
          return value
        }
      },
      undefined: true, // Include 'undefined' values in the serialized state
      function: true, // Include functions in the serialized state
    },
  },
  // trace: true

  // Désactive Redux DevTools en production
  shouldCompose: process.env.NODE_ENV !== 'production',
}

// STORE // ___________________________________________________________________________

export const store = configureStore({
  reducer: resettableRootReducer,
  // development mode
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable state check
      immutableCheck: false,
      // trace: true
    }),
  devTools: devToolsConfig,
})
