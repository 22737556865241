import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FaUsers, FaUsersCog, FaClipboardList } from 'react-icons/fa'
import Card from '../../components/Components/Card'
import { beautifulDate } from '../../../services/helpers/dates'
import { espaceDatasAdmin } from '../../../data/informations/informations'

const AdminDashboard = () => {
  const selectUser = (state) => state.getUser.user
  const user = useSelector(selectUser)

  // let createdDate = new Date(user.createdAt)
  // let date = createdDate.toLocaleDateString('fr')
  const links = [
    {
      link: '/admin/utilisateurs',
      icon: <FaUsers />,
      txt: 'Utilisateurs',
    },
    {
      link: '/admin/cabinets',
      icon: <FaClipboardList />,
      txt: 'Cabinets',
    },
    {
      link: '/admin/conseillers',
      icon: <FaUsersCog />,
      txt: 'Conseillers',
    },
  ]

  return (
    <section className="section">
      <h1 className="h1">Tableau de bord Administrateur</h1>
      <div className="sousSection">
        <div className="cards">
          <Card title="Identité" open={true}>
            <div className="informations">
              {espaceDatasAdmin(user).map((data, index) => (
                <div key={index} className="informations__line">
                  <p className="informations__line__label">{data.label}</p>
                  <p
                    className={
                      data.label === ''
                        ? 'informations__line__data--important'
                        : 'informations__line__data'
                    }
                  >
                    {data.value}
                  </p>
                </div>
              ))}
            </div>
            <div className="informations">
              <p className="informations__create">Créé le {beautifulDate(user.createdAt)}</p>
            </div>
          </Card>
          <Card title="Demandes" open={true}>
            <div className="informations">
              <p>
                idée: liste des demandes des users - pourrait être considérée comme une todo list
              </p>
            </div>
          </Card>
        </div>
        <div className="cards cards--fullWidth">
          <Card title="Gérer" open={true}>
            <ul className="management">
              {links.map((link, index) => (
                <li key={index} className="management__item button button--blue-red width--12">
                  <Link to={link.link} className="management__link">
                    <div className={'management__link__content'}>{link.icon}</div>
                    <p className="management__link__txt">{link.txt}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </Card>
        </div>
      </div>
    </section>
  )
}

export default AdminDashboard
