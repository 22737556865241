import React from 'react'
import { useSelector } from 'react-redux'
import { dataAutreRevenu } from '../../../../../../data/informations/revenus'
import { REVENUS_AUTRES } from '../../../../../../constants'
import InformationsCardPrint from '../../../../Informations/InformationsCardPrint'
import BienInfoPrintDetenteurEtDoc from '../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'

const RevenuAutreInfoPrint = ({ elementToDisplay }) => {
  const properties = useSelector((state) => state.getProperties.properties)
  const { detenteurs, fichiers } = elementToDisplay || {}
  return (
    <>
      <InformationsCardPrint
        data={dataAutreRevenu(
          elementToDisplay,
          null,
          elementToDisplay.idActif ? properties : null,
          null
        )}
        title={elementToDisplay.libelle}
      />
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={REVENUS_AUTRES}
      />
    </>
  )
}

export default RevenuAutreInfoPrint
