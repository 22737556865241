import React from 'react'
import InputsInfos from '../Inputs/InputsInfos'

const Infos = ({ infosPrincipales, setInfosPrincipales, setDataChange }) => {
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      if (name === 'denominationSociale') {
        setInfosPrincipales({ ...infosPrincipales, [name]: value.toUpperCase() })
      } else {
        setInfosPrincipales({ ...infosPrincipales, [name]: value })
      }
    } else {
      const name = selectName.name
      const value = e.value
      setInfosPrincipales({ ...infosPrincipales, [name]: value })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setInfosPrincipales({ ...infosPrincipales, [name]: date })
  }

  return (
    <InputsInfos
      handleChange={handleChange}
      handleChangeDate={handleChangeDate}
      infosPrincipales={infosPrincipales}
    />
  )
}

export default Infos
