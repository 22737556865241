// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get token
 * @reduxAction
 */

export const getTokenLoad = createAction('get-token-load')

export const getTokenSuccess = createAction('get-token-success', (token) => {
  return {
    payload: { token },
  }
})

export const getTokenError = createAction('get-token-error', (error) => {
  return {
    payload: { error },
  }
})
