import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import { getCatalogues } from '../../../../../../services/API/catalogue'
import { getFinancier } from '../../../../../../services/API/financier'
import { PRODUITS_VM } from '../../../../../../constants'
import Loader from '../../../../../layout/Loader'
import usePersonData from '../../../../../layout/usePersonData'
import useCustomPrint from '../../../../../layout/useCustomPrint'
import Card from '../../../../Components/Card'
import PrintComponent from '../../../../Components/PrintComponent'
import BlocTable from '../../../../table/BlocTable'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../../Patrimoine/PatrimoineChartCard'
import ButtonsRow from '../../../Patrimoine/ButtonsRow'
import TableBodyTitres from '../../Catalogue/Table/TableBodyTitres'
import useUpdateProductIdCatalogue from '../../Produit/useUpdateProductIdCatalogue'
import VMPieChart from './VMPieChart'
import VMToPrint from './VMToPrint'
import LittleLoader from '../../../../../layout/LittleLoader'

const VMHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const financier = useSelector((state) => state.getFinancier.financier)
  const { produitsVM } = financier || {}
  const catalogues = useSelector((state) => state.getCatalogues.catalogues)
  const [cataloguesC, setCataloguesC] = useState(null)
  useEffect(() => {
    if (!catalogues) {
      dispatch(getCatalogues())
    } else {
      setCataloguesC(catalogues.filter((catalogue) => catalogue.categorie === 'VM'))
    }
  }, [catalogues, dispatch])
  // recharge
  useEffect(() => {
    if (!financier) {
      dispatch(getFinancier(person?.id, isPP))
    }
  }, [financier, person, isPP, dispatch])
  // hook
  const { postData, updateData } = useUpdateProductIdCatalogue('VM')
  useEffect(() => {
    if (cataloguesC && produitsVM) {
      if (produitsVM.filter((el) => el.idCatalogue === 0).length > 0) {
        postData(produitsVM, cataloguesC)
        updateData(produitsVM, cataloguesC)
      }
    }
  }, [produitsVM, cataloguesC])
  // redirections
  const formLink = '/user/patrimoine/actifs/financier/valeurs-mobilieres/ajouter'
  // maj bien
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // calcul valeurs
  const valeurPortefeuilles = useMemo(
    () =>
      produitsVM.map((produit) => produit.montantTotalCalcule).reduce((acc, curr) => acc + curr, 0),
    [produitsVM]
  )
  const valeurLiquidites = useMemo(
    () => produitsVM.map((produit) => produit.liquidite).reduce((acc, curr) => acc + curr, 0),
    [produitsVM]
  )
  const values = useMemo(
    () => [
      { label: 'Liquidités', value: valeurLiquidites },
      { label: 'Portefeuilles', value: valeurPortefeuilles },
    ],
    [valeurLiquidites, valeurPortefeuilles]
  )
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()
  // titles
  const titles = [
    'N°',
    'Libellé',
    'Disponibilités',
    'Portefeuille',
    'Total',
    'Gain / Perte',
    'Voir',
    'Modifier',
    'Supprimer',
  ]

  if (!financier) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <VMToPrint
              elementToDisplay={produitsVM}
              valeurPortefeuilles={valeurPortefeuilles}
              valeurLiquidites={valeurLiquidites}
              values={values}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            <PatrimoineValueCard
              totalAmount={valeurPortefeuilles + valeurLiquidites}
              values={values}
            />
            {valeurPortefeuilles + valeurLiquidites !== 0 && valeurPortefeuilles > 0 ? (
              <PatrimoineChartCard children={<VMPieChart produitsVM={produitsVM} />} />
            ) : null}
            <Card title={`Produits (${produitsVM.length})`} h5={true} open={true}>
              <div className="informations">
                <BlocTable
                  data={produitsVM}
                  titles={titles}
                  tableType={PRODUITS_VM}
                  children={<TableBodyTitres />}
                  title={`Produits (${produitsVM.length})`}
                  handleAdd={() => navigate(formLink)}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default VMHome
