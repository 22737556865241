import { recupBien } from '../../services/API/actifs'
import { beautifulDate } from '../../services/helpers/dates'
import { recupLabel } from '../../services/helpers/helpers'
import { optionsDetention } from '../options/options'

// valeur
export const dataPassifsValues = (bien) => {
  const { passifDivers, valeurTotalePassif } = bien
  return [
    {
      label: 'Valeur totale des passifs',
      value: `${
        valeurTotalePassif &&
        valeurTotalePassif.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur passif divers',
      value: `${
        passifDivers && passifDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur passif affecté',
      value: `${(
        valeurTotalePassif &&
        passifDivers &&
        valeurTotalePassif - passifDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataPassifEmpruntsValues = (bien) => {
  const { emprunts } = bien
  const { valeurEmprunts, empruntDivers } = emprunts
  return [
    {
      label: 'Valeur totale des emprunts',
      value: `${
        valeurEmprunts && valeurEmprunts.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur emprunts divers',
      value: `${
        empruntDivers &&
        empruntDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur emprunts affectés',
      value: `${(
        valeurEmprunts &&
        empruntDivers &&
        valeurEmprunts - empruntDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataPassifDettesValues = (bien) => {
  const { dettes } = bien
  const { valeurDettes, detteDivers } = dettes
  return [
    {
      label: 'Valeur totale des dettes',
      value: `${
        valeurDettes && valeurDettes.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur dettes divers',
      value: `${
        detteDivers && detteDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur dettes affectées',
      value: `${(valeurDettes && detteDivers && valeurDettes - detteDivers.montant).toLocaleString(
        undefined,
        { minimumFractionDigits: 4 }
      )} €`,
    },
  ]
}

// emprunt
export const dataEmpruntGeneral = (emprunt, categorie, properties, pmList) => {
  return [
    {
      label: 'Référence :',
      value: emprunt.refEmprunt,
    },
    {
      label: 'Montant emprunté :',
      value: `${emprunt.montantEmprunte.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
    {
      label: 'Montant restant dû :',
      value: `${emprunt.montantRestant.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
    {
      label: 'Durée :',
      value: `${emprunt.dureeMois} mois`,
    },
    {
      label: 'Mensualités :',
      value: `${emprunt.mensualite.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
    {
      label: 'Permière mensualité :',
      value: beautifulDate(emprunt.dateDebut),
    },
    {
      label: 'Dernière mensualité :',
      value: beautifulDate(emprunt.dateFin),
    },
    {
      label: 'Relié au bien :',
      value:
        emprunt.idActif && emprunt.idActif !== 0
          ? recupBien(emprunt.typeActif, emprunt.idActif, properties, false)
          : '',
      bien:
        emprunt.idActif && emprunt.idActif !== 0
          ? recupBien(emprunt.typeActif, emprunt.idActif, properties, true)
          : null,
    },
    {
      label: 'Détention :',
      value: emprunt.detention && recupLabel(emprunt.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${emprunt.tauxIndivision} %`,
    },
  ]
}

// calcul Montant interets
const calculerMontantInteret = (mensualite, dureeMois, montantEmprunte) => {
  const montantInteret = mensualite * dureeMois - montantEmprunte
  return montantInteret
}

export const dataEmpruntAdditionnal = (emprunt, categorie, properties, pmList) => {
  return [
    {
      label: "Taux d'interêt :",
      value:
        emprunt.interet && emprunt.interet !== 0
          ? `${emprunt.interet.toLocaleString(undefined, { minimumFractionDigits: 4 })} %`
          : null,
    },
    {
      label: 'Montant des intérêts :',
      value: `${calculerMontantInteret(
        parseFloat(Math.round(emprunt.mensualite * 100) / 100),
        emprunt.dureeMois,
        emprunt.montantEmprunte
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },

    {
      label: "Montant de l'assurance :",
      value:
        emprunt.montantAssurance && emprunt.montantAssurance !== 0
          ? `${emprunt.montantAssurance.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
          : null,
    },
    {
      label: "Différé d'amortissement :",
      value:
        emprunt.differeAmortissement && emprunt.differeAmortissement !== 0
          ? `${emprunt.differeAmortissement} mois`
          : null,
    },
  ]
}
// dette
export const dataDette = (emprunt, categorie, properties, pmList) => {
  return [
    {
      label: 'Référence dette :',
      value: emprunt.refDette,
    },
    {
      label: 'Montant restant :',
      value: `${emprunt.montantRestant.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
    {
      label: 'Relié au bien :',
      value:
        emprunt.idActif && emprunt.idActif !== 0
          ? recupBien(emprunt.typeActif, emprunt.idActif, properties, false)
          : '',
      bien:
        emprunt.idActif && emprunt.idActif !== 0
          ? recupBien(emprunt.typeActif, emprunt.idActif, properties, true)
          : null,
    },
    {
      label: 'Détention :',
      value: emprunt.detention && recupLabel(emprunt.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${emprunt.tauxIndivision} %`,
    },
  ]
}
