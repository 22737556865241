import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFirms } from '../../../services/API/cabinets'

const useFirms = () => {
  const dispatch = useDispatch()
  // firms
  const firms = useSelector((state) => state.getFirms.firms)
  //recuperation des cabinets créés
  useEffect(() => {
    !firms && dispatch(getFirms())
  }, [])
  // cabinets triés
  const firmsSorted = useMemo(
    () =>
      firms
        ? Object.values(firms).sort((a, b) => {
            if (a.nomCabinet < b.nomCabinet) {
              return -1
            }
            if (a.nomCabinet > b.nomCabinet) {
              return 1
            }
            return 0
          })
        : null,
    [firms]
  )
  // options
  const optionsFirms = useMemo(
    () =>
      firmsSorted
        ? Object.values(firmsSorted).map((element) => ({
            value: element.id,
            label: `${element.nomCabinet.toUpperCase()} - ${element.cpCabinet} ${
              element.villeCabinet
            }`,
          }))
        : null,
    [firmsSorted]
  )

  return optionsFirms
}

export default useFirms
