import React from 'react'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import { optionsTypeImmo } from '../../../../../../data/options/optionsActifs'
import { stringToCapitalize } from '../../../../../../services/helpers/strings'

const BodyBienImmo = ({ element, handleShow }) => {
  return (
    <>
      {/* nom */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.nom ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          stringToCapitalize(element.nom)
        )}
      </td>
      {/* valeur */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.valeur && element.valeur !== 0 ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.valeur.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
        )}
      </td>
      {/* typeImmobilier */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.typeImmobilier ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          recupLabel(element.typeImmobilier, optionsTypeImmo)
        )}
      </td>
      {/* adresse */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.adresse ? <p className="informations__line__notxt">-</p> : element.adresse}
      </td>
      {/* codePostal */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.codePostal ? <p className="informations__line__notxt">-</p> : element.codePostal}
      </td>
      {/* ville */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.ville ? <p className="informations__line__notxt">-</p> : element.ville}
      </td>
    </>
  )
}

export default BodyBienImmo
