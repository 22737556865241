import { CATEGORIE } from '../../constants'
import { beautifulDate } from '../../services/helpers/dates'
import { recupLabel } from '../../services/helpers/helpers'
import { getLibelleProduitFinancier } from '../../services/helpers/strings'
import { optionsTrueOrFalse } from '../options/options'
import { optionsBeneficiaires, optionsCategories } from '../options/optionsFinancier'

const formatCurrency = (value) =>
  value.toLocaleString(undefined, { minimumFractionDigits: 4 }) + ' €'

const createDataEntry = (label, value) => ({ label, value: formatCurrency(value) })
// clos
export const dataProduitClos = (bien) => {
  return [
    {
      label: 'Produit clos :',
      value: bien.clos && recupLabel(bien.clos, optionsTrueOrFalse),
    },
    {
      label: 'Date de clôture :',
      value: beautifulDate(bien.dateCloture),
    },
  ]
}
// financier
export const dataProduitFinancier = (bien) => {
  return [
    {
      label: 'Libellé :',
      value: getLibelleProduitFinancier(bien.libelle),
    },
    {
      label: 'Descriptif :',
      value: bien.descriptif,
    },
    {
      label: 'Etablissement :',
      value: bien.etablissement,
    },
    {
      label: 'Numéro :',
      value: bien.numero,
    },
    {
      label: 'Solde :',
      value:
        bien.solde && `${bien.solde.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
    {
      label: 'Date du solde :',
      value: beautifulDate(bien.dateSolde),
    },
  ]
}
// catalogue
export const dataCatalogue = (bien) => {
  return [
    {
      label: 'Libellé :',
      value: bien.libelle,
    },
    {
      label: 'Fournisseur :',
      value: bien.etablissement,
    },
    {
      label: 'Catégorie :',
      value: recupLabel(bien.categorie, optionsCategories),
    },
  ]
}
// produit
export const dataProduit = (bien, categorie, properties, pmList) => {
  return categorie === 'AV' || categorie === 'retraite'
    ? [
        {
          label: 'Libellé :',
          value: bien.libelle,
        },
        {
          label: 'Fournisseur :',
          value: bien.fournisseur ? bien.fournisseur : '',
        },
        {
          label: 'Numéro de contrat :',
          value: bien.numeroContrat ? bien.numeroContrat : '',
        },
        {
          label: "Date d'effet :",
          value: bien.dateEffet ? beautifulDate(bien.dateEffet) : '',
        },
        {
          label: 'Bénéficiaires :',
          value: bien.beneficiaire ? recupLabel(bien.beneficiaire, optionsBeneficiaires) : '',
        },
        {
          label: 'Frais de gestion des UC (%) :',
          value: bien.tauxFraisGestionUC ? bien.tauxFraisGestionUC : '',
        },
        {
          label: 'Frais de gestion des Fonds (%) :',
          value: bien.tauxFraisGestionFE ? bien.tauxFraisGestionFE : '',
        },
      ]
    : categorie === 'PER'
    ? [
        {
          label: 'Libellé :',
          value: bien.libelle,
        },
        {
          label: 'Fournisseur :',
          value: bien.fournisseur ? bien.fournisseur : '',
        },
        {
          label: 'Catégorie :',
          value: bien.categorie ? bien.categorie : '',
        },
        {
          label: "Date d'ouverture :",
          value: bien.dateOuverture ? beautifulDate(bien.dateOuverture) : '',
        },
      ]
    : [
        {
          label: 'Libellé :',
          value: bien.libelle,
        },
        {
          label: 'Fournisseur :',
          value: bien.fournisseur ? bien.fournisseur : '',
        },
        {
          label: "Date d'ouverture :",
          value: bien.dateOuverture ? beautifulDate(bien.dateOuverture) : '',
        },
      ]
}
// valeur
export const dataFinancierValues = (bien) => {
  const { valeurTotaleFinancier, produitsVM, produitsAV, comptesBancaires } = bien || {}
  // calcul valeurs
  const valeurLiquidites = produitsVM
    .map((produit) => produit.liquidite)
    .reduce((acc, curr) => acc + curr, 0)
  const totalVM =
    produitsVM.length > 0
      ? produitsVM
          .map((produit) => produit.montantTotalCalcule)
          .reduce((acc, current) => acc + current)
      : 0
  const totalAV =
    produitsAV.length > 0
      ? produitsAV
          .map((produit) => produit.montantTotalCalcule)
          .reduce((acc, current) => acc + current)
      : 0
  const totalPF =
    comptesBancaires.length > 0
      ? comptesBancaires
          .map((compte) => (!compte.clos ? compte.solde : 0))
          .reduce((acc, current) => acc + current)
      : 0
  return [
    {
      label: 'Total Financier',
      value: `${valeurTotaleFinancier.toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`,
    },
    {
      label: 'Liquidités',
      value: `${valeurLiquidites.toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`,
    },
    {
      label: 'Valeurs Mobilières',
      value: `${totalVM.toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`,
    },
    {
      label: 'Assurances Vie',
      value: `${totalAV.toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`,
    },
    {
      label: 'Produits Financiers',
      value: `${totalPF.toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`,
    },
  ]
}
export const dataAVRetraiteValues = (bien, categorie) => {
  // calcul valeurs
  const valeurPortefeuilles = bien
    .map((produit) => produit.montantTotalCalcule)
    .reduce((acc, curr) => acc + curr, 0)

  return [
    {
      label: `Total des ${categorie === CATEGORIE.av ? 'ASSURANCES VIES :' : 'RETRAITES :'}`,
      value: `${valeurPortefeuilles.toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`,
    },
  ]
}
export const dataVMPERValues = (bien, categorie) => {
  // calcul valeurs
  const valeurPortefeuilles = bien
    .map((produit) => produit.montantTotalCalcule)
    .reduce((acc, curr) => acc + curr, 0)
  const valeurLiquidites = bien
    .map((produit) => produit.liquidite)
    .reduce((acc, curr) => acc + curr, 0)

  return [
    {
      label: `Total des ${categorie === CATEGORIE.vm ? 'VALEURS MOBILIERES :' : 'PER :'}`,
      value: `${(valeurPortefeuilles + valeurLiquidites).toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`,
    },
    {
      label: 'Liquidites',
      value: `${valeurLiquidites.toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`,
    },
    {
      label: 'Portefeuilles',
      value: `${valeurPortefeuilles.toLocaleString(undefined, {
        minimumFractionDigits: 4,
      })} €`,
    },
  ]
}
// frais
export const dataProduitFrais = (bien = {}, categorie) => {
  const {
    totalFraisAnneeN = 0,
    totalFraisAnneeNFE = 0,
    totalFraisAnneeNUC = 0,
    totalFraisAnneeNMoins1 = 0,
    totalFraisAnneeNMoins1FE = 0,
    totalFraisAnneeNMoins1UC = 0,
    totalLiquidite = 0,
    valeurPortefeuille = 0,
  } = bien
  const anneeN = new Date().getFullYear()
  const entries = []
  if ([CATEGORIE.vm, CATEGORIE.per].includes(categorie)) {
    entries.push(createDataEntry('Disponibilités', totalLiquidite))
  }
  entries.push(createDataEntry('Valeur totale du portefeuille', valeurPortefeuille))
  if ([CATEGORIE.av, CATEGORIE.retraite].includes(categorie)) {
    entries.push(createDataEntry(`Total des frais ${anneeN}`, totalFraisAnneeN))
    entries.push(createDataEntry(`Frais Fonds Euro ${anneeN}`, totalFraisAnneeNFE))
    entries.push(createDataEntry(`Frais UC ${anneeN}`, totalFraisAnneeNUC))
    entries.push(createDataEntry(`Total des frais ${anneeN - 1}`, totalFraisAnneeNMoins1))
    entries.push(createDataEntry(`Frais Fonds Euro ${anneeN - 1}`, totalFraisAnneeNMoins1FE))
    entries.push(createDataEntry(`Frais UC ${anneeN - 1}`, totalFraisAnneeNMoins1UC))
  }

  return entries.filter((entry) => entry !== null)
}
