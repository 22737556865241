import React from 'react'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import {
  optionsTypePR,
  optionsTypeRevenuProfessionnel,
} from '../../../../../../data/options/optionsRevenus'
import {
  REVENUS_AUTRES,
  REVENUS_FONCIERS,
  REVENUS_MOBILIERS,
  REVENUS_PENSIONS,
} from '../../../../../../constants'

const BodyRevenu = ({ element, tableType, handleShow }) => {
  // categorie
  const handleSelectProperty = (element) => {
    return tableType === REVENUS_AUTRES ||
      tableType === REVENUS_FONCIERS ||
      tableType === REVENUS_MOBILIERS
      ? element.detail
      : tableType === REVENUS_PENSIONS
      ? element.typePensionRente
      : element.typeRevenuProfessionnel
  }

  return (
    <>
      {/* libelle */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.libelle ? <p className="informations__line__notxt">-</p> : element.libelle}
      </td>
      {/* Catégorie */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!handleSelectProperty(element) ? (
          <p className="informations__line__notxt">-</p>
        ) : tableType === REVENUS_AUTRES ||
          tableType === REVENUS_FONCIERS ||
          tableType === REVENUS_MOBILIERS ? (
          element.detail
        ) : tableType === REVENUS_PENSIONS ? (
          recupLabel(element.typePensionRente, optionsTypePR)
        ) : (
          recupLabel(element.typeRevenuProfessionnel, optionsTypeRevenuProfessionnel)
        )}
      </td>
      {/* Montant */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.montant && element.montant !== 0 ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
        )}
      </td>
      {/* Périodicité */}
      {/* <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.typePeriodicite ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          recupLabel(element.typePeriodicite, optionsTypePeriodicite)
        )}
      </td> */}
    </>
  )
}

export default BodyRevenu
