import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postNewSupport } from '../../../../../services/API/catalogue'
import { getDICIList } from '../../../../../services/API/financier'
import Card from '../../../Components/Card'
import Input from '../../../Components/Input'
import Modale from '../../../Components/Modale'
import CreationSupportInput from './Form/CreationSupportInput'
import CatalogueValuesInput from './CatalogueValuesInput'
import { FE } from '../../../../../constants'

const CatalogueUpdateSupport = ({ categorie }) => {
  const dispatch = useDispatch()
  // DICI _________________________________________________
  const list = useSelector((state) => state.getDicis.dicis)
  const [isin, setIsin] = useState('')
  const [message, setMessage] = useState(null)
  // VL _________________________________________________
  const [cours, setCours] = useState({
    dateVL: new Date().toISOString().split('T')[0],
    valeur: null,
    isin: isin,
  })
  // type _________________________________________________
  const [fondEuro, setFondEuro] = useState(false)
  // newSupport _________________________________________________
  const [support, setSupport] = useState({})
  const { nom, dici_url, note, m_nom, nonCote, c_nom } = support || {}
  // reponse
  const [statusSupport, setStatusSupport] = useState(null)
  // modales _________________________________________________
  const [isinOK, setIsinOK] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  // recup list _________________________________________________
  useEffect(() => {
    !list && dispatch(getDICIList())
  }, [])
  // data
  const dataSupport = {
    isin: isin,
    nom: nom,
    dici_url: dici_url,
    note: note,
    m_nom: m_nom,
    c_nom: c_nom,
    nonCote: nonCote,
  }
  // handleChange _________________________________________________
  const handleChange = (e, selectName) => {
    if (e.target !== undefined) {
      const { name, value } = e.target
      switch (name) {
        case 'valeur':
          setCours({ ...cours, [name]: value })
          break
        case 'nom':
          setSupport({ ...support, [name]: value })
          break
        default:
          setSupport({ ...support, [name]: value })
      }
    } else {
      const name = selectName.name
      const value = e.value
      if (name === 'fondEnEuro') {
        setFondEuro(value)
        setSupport({ ...support, c_nom: value ? FE : '' })
      } else {
        setSupport({ ...support, [name]: value })
      }
    }
  }
  // handleUpdate _________________________________________________
  const handleUpdate = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const result = list.filter((el) => el.isin === isin)[0]
    if (result === undefined) {
      setMessage('ISIN non reconnu')
      setOpenModal(true)
      setSupport({})
    } else {
      setSupport(list.filter((el) => el.isin === isin)[0])
      result.c_nom === FE ? setFondEuro(true) : setFondEuro(false)
      if (result.nonCote) {
        setMessage('Modifier le support')
        setIsinOK(true)
      } else {
        setMessage('Support non modifiable')
        setOpenModal(true)
      }
    }
  }
  // handleSubmitNewSupp
  const handleSubmitNewSupp = (e) => {
    e.preventDefault()
    if (isin !== '' && nom !== '' && m_nom !== '') {
      dispatch(postNewSupport(dataSupport, setStatusSupport))
    } else {
      setMessage('Veuillez remplir les champs obligatoire (fond vert)')
      setOpenModal(true)
    }
  }
  // status Support
  useEffect(() => {
    if (statusSupport) {
      if (statusSupport < 400) {
        setMessage('Modifications enregistrées.')
        dispatch(getDICIList())
        setOpenModal(true)
      } else {
        setMessage("Le support n'a pas pu être modifié, veuillez réessayer")
        setOpenModal(true)
      }
    }
  }, [statusSupport])

  // closeModale _________________________________________________
  const closeModale = () => {
    setMessage(null)
    setOpenModal(false)
    setIsinOK(false)
    setStatusSupport(null)
  }
  // maj isinOK _________________________________________________
  useEffect(() => {
    !isin && setIsinOK(false)
  }, [isin])

  return (
    <>
      <div className="cards cards--fullWidth">
        <Card title={'Modifier un support'} h5={true} open={true}>
          {/* isin */}
          <Input
            forAndId="isin"
            type="text"
            name="ISIN :"
            value={isin}
            onChange={(e) => setIsin(e.target.value)}
            search={true}
            action={handleUpdate}
          />
          <div className="informations informations--line">
            {isinOK ? (
              <div className="informations informations__card">
                <p>Informations</p>
                <CreationSupportInput
                  handleChange={handleChange}
                  handleSubmitNewSupp={handleSubmitNewSupp}
                  support={support}
                  cours={cours}
                  fondEuro={fondEuro}
                  categorie={categorie}
                  create={false}
                />
              </div>
            ) : null}
            {isinOK && !fondEuro ? (
              <div className="informations informations__card">
                <p>Valeurs</p>
                <CatalogueValuesInput
                  cours={cours}
                  setCours={setCours}
                  isin={isin}
                  categorie={categorie}
                />
              </div>
            ) : null}
          </div>
        </Card>
      </div>
      {openModal ? (
        <Modale
          close={() => closeModale()}
          title={statusSupport !== 200 ? 'Erreur !' : 'Felicitations !'}
          line1={message}
        >
          <div className="informations">
            <div className="informations__buttons">
              <div className="button button--red-red  width--18" onClick={() => closeModale()}>
                Fermer
              </div>
            </div>
          </div>
        </Modale>
      ) : null}
    </>
  )
}

export default CatalogueUpdateSupport
