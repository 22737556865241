import React, { useEffect } from 'react'
import { sortByType } from '../../../services/helpers/sort'
import BodyAddress from './BodyAddress'
import TableBodyBloc from '../table/TableBodyBloc'
import BodyAddressPrint from './BodyAddressPrint'

const TableBodyAddress = ({
  targetToSort,
  sort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Adresse':
          giveAType('info')
          break
        case 'Complément':
          giveAType('libelle')
          break
        case 'Code postal':
          giveAType('cp')
          break
        case 'Ville':
          giveAType('ville')
          break
        case 'Pays':
          giveAType('type')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyAddressPrint /> : <BodyAddress />}
      print={print}
    />
  )
}

export default TableBodyAddress
