import React from 'react'
import { COLORS } from '../../../../constants'
import BarChartCompoent from '../../Charts/BarChartCompoent'
import LegendBarChart from '../../Charts/LegendBarChart'

const ChartActifsPassifs = ({ passifsToDisplay, actifsToDisplay, print }) => {
  const {
    actifsBienProfessionnel,
    actifsDivers,
    actifsAutres,
    actifsImmobilier,
    financiers,
    socials,
  } = actifsToDisplay || {}
  const { valeurTotaleDettes, valeurTotaleEmprunts, passifDivers } = passifsToDisplay || {}
  // data & colors
  const colors = [
    {
      dataKey: 'immobilier',
      stackId: 'a',
      fill: COLORS.colorRepartition01,
      name: 'Biens Immobiliers',
      type: 'actif',
    },
    {
      dataKey: 'professionnel',
      stackId: 'a',
      fill: COLORS.colorRepartition02,
      name: 'Biens professionnels',
      type: 'actif',
    },
    {
      dataKey: 'financier',
      stackId: 'a',
      fill: COLORS.colorRepartition03,
      name: 'Actifs financiers',
      type: 'actif',
    },
    {
      dataKey: 'social',
      stackId: 'a',
      fill: COLORS.colorRepartition04,
      name: 'Actif social',
      type: 'actif',
    },
    {
      dataKey: 'autre',
      stackId: 'a',
      fill: COLORS.colorRepartition05,
      name: 'Autres biens',
      type: 'actif',
    },
    {
      dataKey: 'actifNonAffecté',
      stackId: 'a',
      fill: COLORS.colorFontLight,
      name: 'Actifs non affectés',
      type: 'actif',
    },
    {
      dataKey: 'emprunts',
      stackId: 'a',
      fill: COLORS.colorDetention01,
      name: 'Emprunts',
      type: 'passif',
    },
    {
      dataKey: 'dettes',
      stackId: 'a',
      fill: COLORS.colorDetention02,
      name: 'Dettes',
      type: 'passif',
    },
    {
      dataKey: 'passifNonAffecté',
      stackId: 'a',
      fill: COLORS.colorFontLight,
      name: 'Passifs non affectés',
      type: 'passif',
    },
  ]
  const data = [
    {
      name: 'Actifs',
      immobilier: actifsImmobilier.valeurImmobiliers,
      professionnel: actifsBienProfessionnel.valeurBiensProfesionnel,
      financier: financiers.valeurTotaleFinancier,
      socials: socials.valeurTotaleSociale,
      autre: actifsAutres.valeurAutresActifs,
      actifNonAffecté: actifsDivers.montant,
      array: colors.filter((el) => el.type === 'actif'),
    },
    {
      name: 'Passifs',
      emprunts: -valeurTotaleEmprunts,
      dettes: -valeurTotaleDettes,
      passifNonAffecté: -passifDivers.montant,
      array: colors.filter((el) => el.type === 'passif'),
    },
  ]

  return (
    <div className="informations">
      <BarChartCompoent data={data} colors={colors} print={print} />
      <hr className="hr" />
      <LegendBarChart data={data} />
    </div>
  )
}

export default ChartActifsPassifs
