import React, { useState } from 'react'
import Card from '../Components/Card'
import BlocTable from '../table/BlocTable'
import { PP_RELATIONS } from '../../../constants'
import TableBodyRelation from './Table/TableBodyRelation'
import ModaleRelationShip from './Modales/ModaleRelationShip'

const RelationShip = ({ relations, setRelations, setDataChange }) => {
  // modale
  const [modalAdd, setModalAdd] = useState(false)
  // titles
  const title = 'Relations'
  const titles = ['N°', 'Nom', 'Relation', 'A charge', 'Modifier', 'Supprimer']

  return (
    <>
      <Card title={title} open={true} h2={true}>
        <div className="informations">
          <BlocTable
            titles={titles}
            title={title}
            setDataChange={setDataChange}
            data={relations}
            setData={setRelations}
            tableType={PP_RELATIONS}
            children={<TableBodyRelation />}
            handleAdd={() => setModalAdd(true)}
          />
        </div>
      </Card>
      {/* modal relation supplémentaire ______________________________________________________*/}
      {modalAdd && (
        <ModaleRelationShip
          setModal={setModalAdd}
          data={relations}
          setData={setRelations}
          setDataChange={setDataChange}
        />
      )}
    </>
  )
}

export default RelationShip
