import React from 'react'
import { useSelector } from 'react-redux'
import { dataRevenuProGeneral } from '../../../../../../data/informations/revenus'
import { DOC_REVENU_PRO } from '../../../../../../constants'
import InformationsCardPrint from '../../../../Informations/InformationsCardPrint'
import BienInfoPrintDetenteurEtDoc from '../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'

const RevenuProInfoPrint = ({ elementToDisplay }) => {
  const properties = useSelector((state) => state.getProperties.properties)
  const { dirigeantPM } = useSelector((state) => state.getPersons.persons)
  const { detenteurs, fichiers } = elementToDisplay || {}
  return (
    <>
      <InformationsCardPrint
        data={dataRevenuProGeneral(
          elementToDisplay,
          null,
          elementToDisplay.idActif ? properties : null,
          dirigeantPM
        )}
        title={elementToDisplay.libelle}
      />
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={DOC_REVENU_PRO}
      />
    </>
  )
}

export default RevenuProInfoPrint
