// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import {
  getUserLoad,
  getUserSuccess,
  getUserError,
} from '../actions/actionGetUser'
import { logOut } from '../actions/actionLogout'

// REDUCER // ______________________________________________________________

// initial state
const initialStateUser = {
  isLoading: false,
  user: null,
  error: '',
}
// reducer
export const getUserReducer = createReducer(initialStateUser, (builder) => {
  return builder
    .addCase(getUserLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getUserSuccess, (draft, action) => {
      draft.isLoading = false
      draft.user = action.payload
      draft.error = ''
      return
    })
    .addCase(getUserError, (draft, action) => {
      draft.isLoading = false
      draft.user = null
      draft.error = action.payload
      return
    })
    .addCase(logOut, (draft) => {
      draft.isLoading = false
      draft.user = null
      draft.error = ''
      return
    })
})