import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import { FaSignature } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import Modale from '../Components/Modale'
import SelectComponent from '../Components/SelectComponent'
import { shortFormeJuridique } from '../../../services/helpers/helpers'

const AddButtonsCard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // modale
  const [toggleSelectperson, setToggleSelectPerson] = useState(false)
  // change
  const [change, setChange] = useState(false)
  // useCallBack
  const shortFJ = useCallback(shortFormeJuridique, [])
  // optionsListPersons
  const makeOptionListPersons = (persons) => {
    const { referentPP, dirigeantPM } = persons || {}
    return referentPP.concat(dirigeantPM).map((el) => ({
      value: el,
      label:
        el.denominationSociale !== undefined
          ? `${el.denominationSociale} (${shortFJ(el.formeJuridique)}) - PM`
          : `${el.nom} ${el.prenom} - PP`,
    }))
  }
  const optionsListPersons = useMemo(
    () => (persons ? makeOptionListPersons(persons) : null),
    [persons]
  )
  // personne
  const [personne, setPersonne] = useState(null)
  // handleClick
  const handleClick = (personne) => {
    dispatch(getPerson(null))
    // dispatch(getPersonId(null))
    dispatch(postPersonType(null))
    navigate(`/user/comptes/ajouter-Personne-${personne}`)
  }
  // select personne
  const handleSelectPersonne = (e) => {
    setChange(true)
    setPersonne(e.value)
    dispatch(getPerson(e.value))
    // dispatch(getPersonId(e.value.id))
    dispatch(
      postPersonType(e.value.denominationSociale !== undefined ? 'dirigeantPM' : 'referentPP')
    )
  }

  return (
    <>
      <nav className="informations">
        <ul className="secondNav__card">
          <li className="secondNav__card__item" onClick={() => handleClick('Physique')}>
            <div className="secondNav__card__item__link">
              <MdOutlineAddCircleOutline />
              <p>Personne physique</p>
            </div>
          </li>
          <li className="secondNav__card__item" onClick={() => handleClick('Morale')}>
            <div className="secondNav__card__item__link">
              <MdOutlineAddCircleOutline />
              <p>Personne morale</p>
            </div>
          </li>
          <li className="secondNav__card__item" onClick={() => setToggleSelectPerson(true)}>
            <div className="secondNav__card__item__link">
              <MdOutlineAddCircleOutline />
              <p>Document</p>
            </div>
          </li>
          <li className="secondNav__card__item" onClick={() => navigate('/user/signature')}>
            <div className="secondNav__card__item__link">
              <FaSignature />
              <p>Signer</p>
            </div>
          </li>
        </ul>
      </nav>
      {toggleSelectperson && (
        <Modale
          close={() => setToggleSelectPerson(false)}
          title={'Sélectionner une personne'}
        >
          <div className="informations">
            <SelectComponent
              forAndId="personne"
              isSearchable={true}
              options={optionsListPersons && optionsListPersons}
              placeholder="Sélectionner"
              onChange={(e) => handleSelectPersonne(e)}
              value={optionsListPersons && optionsListPersons.find((c) => c.value === personne)}
              bold={true}
              noLabel={true}
            />

            <div className="informations__buttons">
              {change ? (
                <div
                  className="button button--red-red width--12"
                  onClick={() => navigate('/user/documents')}
                >
                  Sélectionner
                </div>
              ) : (
                <div className="button button--grey width--12">Sélectionner</div>
              )}
              <div
                className="button button--blue-red width--12"
                onClick={() => setToggleSelectPerson(false)}
              >
                Annuler
              </div>
            </div>
          </div>
        </Modale>
      )}
    </>
  )
}

export default AddButtonsCard
