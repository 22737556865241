// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import {
  getCataloguesError,
  getCataloguesLoad,
  getCataloguesSuccess,
} from '../../actions/Patrimoine/actionGetCatalogues'

// REDUCER // ______________________________________________________________

// initial state
const initialStateCatalogues = {
  isLoading: false,
  catalogues: null,
  error: '',
}
// reducer
export const getCataloguesReducer = createReducer(initialStateCatalogues, (builder) => {
  return builder
    .addCase(getCataloguesLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getCataloguesSuccess, (draft, action) => {
      draft.isLoading = false
      draft.catalogues = action.payload
      draft.error = ''
      return
    })
    .addCase(getCataloguesError, (draft, action) => {
      draft.isLoading = false
      draft.catalogues = null
      draft.error = action.payload
      return
    })
})
