// social _____________________________________________________________________________

import axios from 'axios'
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants'
import {
  getSocialError,
  getSocialLoad,
  getSocialSuccess,
} from '../../redux/actions/Patrimoine/actionGetSocial'
import { toast } from 'react-toastify'
import { getFormIsActive } from '../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../redux/actions/actionSaveForm'

// recup social
export const getSocial = (id, pp) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getSocialLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/social/socials?personneId=${id}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getSocialSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getSocialError(error.message))
      })
  }
}
// post social
export const postSocial = (type, id, pp, divers, data, setStatus) => {
  const toastLoading = toast.loading('Mise à jour du produit en cours...')
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getSocialLoad())
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/social/${type}?personneId=${id}&pp=${pp}&divers=${divers}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
        dispatch(getSocialSuccess(response.data))
        dispatch(getFormIsActive(null))
        dispatch(getSaveForm(false))
        toast.dismiss(toastLoading)
        toast.success('Produit mis à jour avec succès !')
      })
      .catch((error) => {
        setStatus(error.response.status)
        dispatch(getSocialError(error.message))
        dispatch(getFormIsActive(null))
        dispatch(getSaveForm(false))
        toast.dismiss(toastLoading)
        toast.error(`Echec de la mise à jour (${error.response.status})`)
      })
  }
}
// delete produit
export const deleteSocial = (type, data, idPP, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${API_BASE_URL}/social/${type}/${data.id}/${idPP}/${pp}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setStatus(response.status)
        dispatch(getSocial(idPP, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// delete produit
export const deleteOperationSocial = (type, data, idP, idPP, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${API_BASE_URL}/social/${type}/${data.id}/${idP}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setStatus(response.status)
        dispatch(getSocial(idPP, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}

// recup frais Maj Frais depuis le debut du contrat
// ou de l'année en cours si debut du contrat est posterieur
// au 1er janvier de l annee en cours
export const getMajFraisSocial = (type, produitId, personId, pp, setStatus) => {
  const toastLoading = toast.loading('Mise à jour des frais en cours...')
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/social/${type}?idProduit=${produitId}&personneId=${personId}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setStatus(response.status)
        toast.dismiss(toastLoading)
        toast.success('Frais mis à jour avec succès')
        dispatch(getSocial(personId, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
        toast.dismiss(toastLoading)
        toast.error(`Echec de la mise à jour des frais ${error.response.status}`)
      })
  }
}
