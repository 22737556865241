import React from 'react'
import {
  dataBienAquisition,
  dataBienProSociete,
  dataBienProGeneral,
  dataBienProParts,
} from '../../../../../../data/informations/actifs'
import InformationsCardPrint from '../../../../Informations/InformationsCardPrint'
import { DOC_BIEN_PRO } from '../../../../../../constants'
import BienInfoPrintDetenteurEtDoc from '../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'
import { useSelector } from 'react-redux'

const BienProInfoPrint = ({ elementToDisplay }) => {
  const persons = useSelector((state) => state.getPersons.persons)

  const { detenteurs, fichiers } = elementToDisplay || {}

  return (
    <>
      <InformationsCardPrint
        data={dataBienProGeneral(
          elementToDisplay,
          null,
          null,
          elementToDisplay.lienPM ? persons.dirigeantPM : null
        )}
        title={elementToDisplay.nom}
      />
      <InformationsCardPrint data={dataBienProSociete(elementToDisplay)} title={'Société'} />
      <InformationsCardPrint data={dataBienProParts(elementToDisplay)} title={'Capital'} />
      <InformationsCardPrint data={dataBienAquisition(elementToDisplay)} title={'Acquisition'} />
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={DOC_BIEN_PRO}
      />
    </>
  )
}

export default BienProInfoPrint
