import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { shortFormeJuridique } from '../../../services/helpers/helpers'
import { stringToCapitalize } from '../../../services/helpers/strings'
import BlocTable from '../table/BlocTable'
import Card from '../Components/Card'
import Copy from '../Components/Copy'
import { DOC_PERSON_IDENTITY, TITLES_DOC_READ } from '../../../constants'
import TableBodyDoc from '../Files/TableBodyDoc'
import usePersonData from '../../layout/usePersonData'

const InformationsCard = ({ title, data, document, dataDoc }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const persons = useSelector((state) => state.getPersons.persons)
  const { person, personType } = usePersonData()
  // toggle
  const [toggleAdd, setToggleAdd] = useState(false)
  // redirection
  let redirection =
    personType && (personType === 'referentPP' || personType === 'relationPP')
      ? 'Personne-Physique'
      : 'Personne-Morale'
  // handleSelectConjoint
  const handleSelectConjoint = (personne) => {
    if (personne !== undefined) {
      dispatch(getPerson(personne))
      dispatch(
        postPersonType(
          persons.referentPP.filter((el) => el.id === personne.id).length > 0
            ? 'referentPP'
            : 'relationPP'
        )
      )
      navigate('/user/informations')
    }
  }
  const titles =
    document === DOC_PERSON_IDENTITY
      ? [
          'N°',
          'Type',
          'Document',
          'Date du document',
          'Date de validité',
          'Dernière mise à jour',
          'Voir',
        ]
      : TITLES_DOC_READ

  return (
    <Card title={title} open={true}>
      <div className="informations">
        {data &&
          data.map((data, index) => (
            <div key={index} className="informations__line">
              <p className="informations__line__label">{data.label}</p>
              {data.value === '' || !data.value ? (
                <p className="informations__line__undefined">à définir</p>
              ) : (
                <div className="informations__line__data">
                  {data.label === "Nom d'usage :" ||
                  data.label === 'Nom de naissance:' ||
                  data.label === 'Nom du dirigeant :' ? (
                    <div>
                      <p> {data.value.toUpperCase()}</p>
                      <Copy value={data.value.toUpperCase()} little={true} />
                    </div>
                  ) : data.label === 'Prénom :' ? (
                    <div>
                      <p>{stringToCapitalize(data.value)}</p>
                      <Copy value={stringToCapitalize(data.value)} little={true} />
                    </div>
                  ) : data.label === 'Forme Juridique :' ? (
                    <div>
                      <p>{shortFormeJuridique(data.value)}</p>
                      <Copy value={shortFormeJuridique(data.value)} little={true} />
                    </div>
                  ) : data.label === 'Conjoint / partenaire :' ? (
                    <div>
                      <div onClick={() => handleSelectConjoint(data.conjoint)}>
                        <p>{data.value}</p>
                        <BsFillInfoCircleFill />
                      </div>
                      <Copy value={data.value} little={true} />
                    </div>
                  ) : (
                    <div>
                      <p>{data.value}</p>
                      <Copy value={data.value} little={true} />
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
      {dataDoc?.length > 0 || (personType === 'relationPP' && person.document) ? (
        <div className="informations">
          {dataDoc?.length > 0 ? (
            !toggleAdd ? (
              <div
                className="informations__dropdown informations__dropdown--full"
                onClick={() => setToggleAdd(!toggleAdd)}
              >
                <p>{`Documents (${dataDoc.length})`}</p>
              </div>
            ) : (
              <div className="informations__dropdown--border">
                <p onClick={() => setToggleAdd(!toggleAdd)}>{`Documents (${dataDoc.length})`}</p>
                <div className="informations">
                  <BlocTable
                    titles={titles}
                    data={dataDoc}
                    tableType={document}
                    children={<TableBodyDoc />}
                    doc={true}
                    read={true}
                    title={`Documents (${dataDoc.length})`}
                  />
                </div>
              </div>
            )
          ) : null}
          {personType !== 'relationPP' && (
            <div className="informations__buttons">
              <div
                className="button button--update width--7"
                onClick={() => navigate(`/user/informations/modifier-${redirection}`)}
              >
                Modifier
              </div>
            </div>
          )}
        </div>
      ) : null}
    </Card>
  )
}

export default InformationsCard
