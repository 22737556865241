import React from 'react'

const BodyAddress = ({ element }) => {
  return (
    <>
      {/* type */}
      <td className="tbody__tr__td">
        {!element.info ? <p className="informations__line__notxt">-</p> : element.info}
      </td>
      {/* libelle */}
      <td className="tbody__tr__td">
        {!element.libelle ? <p className="informations__line__notxt">-</p> : element.libelle}
      </td>
      {/* cp */}
      <td className="tbody__tr__td">
        {!element.cp ? <p className="informations__line__notxt">-</p> : element.cp}
      </td>
      {/* ville */}
      <td className="tbody__tr__td">
        {!element.ville ? <p className="informations__line__notxt">-</p> : element.ville}
      </td>
      {/* pays */}
      <td className="tbody__tr__td">
        {!element.type ? <p className="informations__line__notxt">-</p> : element.type}
      </td>
    </>
  )
}

export default BodyAddress
