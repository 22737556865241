import React from 'react'

const BodyAddressPrint = ({ element, index }) => {
  return (
    <>
      {/* info */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Adresse</p>
        <p className="print__table__bloc__line--value">{!element.info ? '-' : element.info}</p>
      </div>
      {/* libelle */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Complément</p>
        <p className="print__table__bloc__line--value">
          {!element.libelle ? '-' : element.libelle}
        </p>
      </div>
      {/* cp */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Code postal</p>
        <p className="print__table__bloc__line--value">{!element.cp ? '-' : element.cp}</p>
      </div>
      {/* ville */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Ville</p>
        <p className="print__table__bloc__line--value">{!element.ville ? '-' : element.ville}</p>
      </div>
      {/* type */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Pays</p>
        <p className="print__table__bloc__line--value">{!element.type ? '-' : element.type}</p>
      </div>
    </>
  )
}

export default BodyAddressPrint
