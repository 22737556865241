import React from 'react'
import { MdLocalPrintshop } from 'react-icons/md'
import useCustomPrint from '../../../layout/useCustomPrint'
import Loader from '../../../layout/Loader'
import Card from '../../Components/Card'
import PrintComponent from '../../Components/PrintComponent'

const PatrimoineChartCard = ({ children, detenteurs }) => {
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <PrintComponent
        componentRef={componentRef}
        title={`Graphiques : ${titlePrint}`}
        children={
          <Card title="Infographies" open={true} h2={true} print={true}>
            <div className="print__charts">
              <div className="informations">{React.cloneElement(children, { print: true })}</div>
              {detenteurs ? (
                <div className="informations">
                  {React.cloneElement(children, { detenteurs: true, print: true })}
                </div>
              ) : null}
            </div>
          </Card>
        }
      />
      <Card title="Infographies" h4={true} open={true}>
        <button onClick={handlePrint} className="button--print button--print--square">
          <MdLocalPrintshop />
        </button>
        <div className={detenteurs ? 'charts' : 'informations'}>
          <div className="informations">{React.cloneElement(children, { print: false })}</div>
          {detenteurs ? (
            <div className="informations">
              {React.cloneElement(children, { detenteurs: true, print: false })}
            </div>
          ) : null}
        </div>
      </Card>
    </>
  )
}

export default PatrimoineChartCard
