import React from 'react'
import FormSubmit from '../../Components/FormSubmit'
import GeneralInformationCardFoncier from '../Revenus/Foncier/Form/GeneralInformationCardFoncier'
import GeneralInformationCardMobilier from '../Revenus/Mobilier/Form/GeneralInformationCardMobilier'
import GeneralInformationCardPR from '../Revenus/PensionRente/Form/GeneralInformationCardPR'
import GeneralInformationCardPro from '../Revenus/Professionnel/Form/GeneralInformationCardPro'
import LinkActifCard from './LinkActifCard'
import DetentionCard from '../Detention/DetentionCard'
import Card from '../../Components/Card'
import BlocTable from '../../table/BlocTable'
import {
  DOC_CHARGE_DEDUCTIBLE,
  DOC_CHARGE_EMPRUNT,
  DOC_CHARGE_GENERALE,
  DOC_CHARGE_IMPOT,
  DOC_CHARGE_PENSION,
  DOC_CHARGE_REDUCTION,
  DOC_REVENU_AUTRE,
  DOC_REVENU_FONCIER,
  DOC_REVENU_MOBILIER,
  DOC_REVENU_PENSION,
  DOC_REVENU_PRO,
  REVENUS_AUTRES,
  REVENUS_FONCIERS,
  REVENUS_MOBILIERS,
  REVENUS_PENSIONS,
  REVENUS_PROS,
  TITLES_DOC_UPDATE,
} from '../../../../constants'
import TableBodyDoc from '../../Files/TableBodyDoc'
import GeneralInformationCardAutres from '../Revenus/Autres/Form/GeneralInformationCardAutres'
import GeneralInformationCard from '../Charges/Charges/GeneralInformationCard'

const FormPatrimoineContent = ({
  dataToDisplay,
  dataChange,
  setDataChange,
  handleChange,
  handleChangeDate,
  information,
  setInformation,
  detenteursToDisplay,
  setDetenteursToDisplay,
  fichiersToDisplay,
  setFichiersToDisplay,
  setToggleAdd,
  handleSubmit,
  newValeurTotale,
  handleGiveUp,
  tableType,
  foncier,
}) => {
  const props = { handleChange, handleChangeDate, information, tableType }
  const getGoodComponent = () => {
    switch (tableType) {
      case DOC_REVENU_FONCIER:
        return <GeneralInformationCardFoncier {...props} />
      case DOC_REVENU_MOBILIER:
        return <GeneralInformationCardMobilier {...props} />
      case DOC_REVENU_PENSION:
        return <GeneralInformationCardPR {...props} />
      case DOC_REVENU_PRO:
        return <GeneralInformationCardPro {...props} />
      case DOC_REVENU_AUTRE:
        return <GeneralInformationCardAutres {...props} />
      case DOC_CHARGE_GENERALE:
      case DOC_CHARGE_IMPOT:
      case DOC_CHARGE_PENSION:
      case DOC_CHARGE_EMPRUNT:
      case DOC_CHARGE_DEDUCTIBLE:
      case DOC_CHARGE_REDUCTION:
        return <GeneralInformationCard {...props} />
      default:
        return
    }
  }

  return (
    <>
      {/* Envoi du formulaire */}
      <FormSubmit dataChange={dataChange} handleSubmit={handleSubmit} handleGiveUp={handleGiveUp} />
      {/* form */}
      <div className="sousSection">
        <div className="cards cards--fullWidth">
          {getGoodComponent()}
          <LinkActifCard
            handleChange={handleChange}
            information={information}
            setInformation={setInformation}
            type={
              tableType === DOC_REVENU_AUTRE ||
              tableType === DOC_REVENU_FONCIER ||
              tableType === DOC_REVENU_PENSION ||
              tableType === DOC_REVENU_PRO ||
              tableType === DOC_REVENU_MOBILIER
                ? 'Revenu'
                : 'Charge'
            }
            foncier={foncier}
          />
        </div>
        <div className={dataToDisplay ? 'cards' : 'cards cards--fullWidth'}>
          <DetentionCard
            information={information}
            setInformation={setInformation}
            detenteursToDisplay={detenteursToDisplay}
            setDetenteursToDisplay={setDetenteursToDisplay}
            newValeurTotale={newValeurTotale}
            setDataChange={setDataChange}
            showUS={false}
          />
          {dataToDisplay && (
            <Card title={`Documents (${fichiersToDisplay.length})`} h3={true} open={true}>
              <div className="informations">
                <BlocTable
                  titles={TITLES_DOC_UPDATE}
                  data={fichiersToDisplay}
                  setData={setFichiersToDisplay}
                  tableType={tableType}
                  children={<TableBodyDoc />}
                  setDataChange={setDataChange}
                  doc={true}
                  title={`Documents (${fichiersToDisplay.length})`}
                  handleAdd={() => setToggleAdd(true)}
                />
              </div>
            </Card>
          )}
        </div>
      </div>
      {/* Envoi du formulaire */}
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handleSubmit}
        handleGiveUp={handleGiveUp}
        down={true}
      />
    </>
  )
}

export default FormPatrimoineContent
