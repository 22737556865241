import React from 'react'
import { useSelector } from 'react-redux'
import { dataChargeGenerale } from '../../../../../../data/informations/charges'
import InformationsCardPrint from '../../../../Informations/InformationsCardPrint'
import BienInfoPrintDetenteurEtDoc from '../../../Patrimoine/print/BienInfoPrintDetenteurEtDoc'

const ChgInfoPrint = ({ elementToDisplay, tableType }) => {
  const { properties } = useSelector((state) => state.getProperties)
  const { detenteurs, fichiers } = elementToDisplay || {}

  return (
    <>
      <InformationsCardPrint
        data={dataChargeGenerale(
          elementToDisplay,
          null,
          elementToDisplay.idActif ? properties : null,
          null
        )}
        title={elementToDisplay.nom}
      />
      <BienInfoPrintDetenteurEtDoc
        detenteurs={detenteurs}
        fichiers={fichiers}
        tableType={tableType}
      />
    </>
  )
}

export default ChgInfoPrint
