import React, { useEffect, useState } from 'react'
import { dynamicSortUp } from '../../../../services/helpers/sort'
import Loader from '../../../layout/Loader'
import useCustomPrint from '../../../layout/useCustomPrint'
import Card from '../../Components/Card'
import PrintComponent from '../../Components/PrintComponent'
import TableToPrint from '../../table/TableToPrint'
import DetentionTableButtons from './DetentionTableButtons'
import DetentionTableInput from './Table/DetentionTableInput'
import TableBodyDetention from './Table/TableBodyDetention'

const DetentionCardContent = ({
  detenteursToDisplay,
  handleChangeDetenteur,
  handleAddDetenteur,
  handleSuppr,
  handleEdit,
  newValeurTotale,
  total,
  showUS,
  h5,
}) => {
  // display sort
  const [list, setList] = useState(detenteursToDisplay)
  useEffect(() => {
    if (detenteursToDisplay && detenteursToDisplay.length > 0) {
      setList(Array.from(detenteursToDisplay).sort(dynamicSortUp(`tempId`)))
    }
  }, [detenteursToDisplay])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <PrintComponent
        componentRef={componentRef}
        title={`Détention : ${titlePrint}`}
        children={
          <TableToPrint
            data={list}
            tableType={''}
            title={`Détention : ${titlePrint}`}
            children={
              <TableBodyDetention
                showUS={showUS}
                newValeurTotale={newValeurTotale}
                detenteurs={detenteursToDisplay}
                total={total}
              />
            }
          />
        }
      />
      <Card title="Détenteurs" h4={!h5} h5={h5} open={true}>
        <div className="informations">
          <DetentionTableButtons
            handlePrint={handlePrint}
            handleAddDetenteur={handleAddDetenteur}
          />
          <DetentionTableInput
            showUS={showUS}
            list={list}
            total={total}
            newValeurTotale={newValeurTotale}
            detenteursToDisplay={detenteursToDisplay}
            handleChangeDetenteur={handleChangeDetenteur}
            handleEdit={handleEdit}
            handleSuppr={handleSuppr}
          />
        </div>
      </Card>
    </>
  )
}

export default DetentionCardContent
