import React from 'react'
import Modale from '../Components/Modale'
import { AiFillFileZip } from 'react-icons/ai'

const ModalParapheur = ({ closeModal, result }) => {
  return (
    <Modale close={() => closeModal()} title="Redirection">
      <div className="informations">
        <div className="informations__blocTxt">
          <p className="informations__blocTxt__txt">
            L'onglet Parapheur doit s'ouvrir dans votre navigateur{' '}
            <span className="informations__blocTxt__txt informations__blocTxt__txt--important">
              *
            </span>
            <br />
            <span className="informations__blocTxt__span informations__blocTxt__span--important">
              Vous allez pouvoir signer votre(vos) document(s)
            </span>
          </p>
          <p className="informations__blocTxt__txt">
            N'oubliez pas de récupérer votre document dans :<br />
            <span className="informations__blocTxt__span informations__blocTxt__span--important">
              {`SIGNATURES -> Documents en INSTANCE -> Icone`}
              <AiFillFileZip />
            </span>
          </p>
          <p className="informations__blocTxt__txt informations__blocTxt__txt--note">
            <span className="informations__blocTxt__txt informations__blocTxt__txt--important">
              *
            </span>{' '}
            Si le document n'apparait pas,
            <br /> vérifiez que vous avez autorisé les pop-up pour ce site <br />
            ou cliquez{' '}
            <span
              className="informations__blocTxt__txt informations__blocTxt__txt--important pointer"
              onClick={() => window.open(result)}
            >
              ICI
            </span>
          </p>
        </div>
        <div className="informations__buttons">
          <div className="button button--blue-red width--12" onClick={() => closeModal()}>
            Fermer
          </div>
        </div>
      </div>
    </Modale>
  )
}

export default ModalParapheur
