import React, { useMemo, useState } from 'react'
import { FE } from '../../../../../constants'

const ProductTable = ({ produit, categorie, print }) => {
  const { portefeuille, transactions, operations, liquidite } = produit || {}
  const { totalLiquidite, totalTransaction, valeurPortefeuille } = portefeuille || {}
  // toggle
  const [toggleDispo, setToggleDispo] = useState(false)
  const [togglePortefeuille, setTogglePortefeuille] = useState(false)
  // calculs
  const totalRemise = useMemo(
    () =>
      transactions.length > 0
        ? transactions
            .map((el) => (el.montant > 0 ? el.montant : 0))
            .reduce((acc, curr) => acc + curr)
        : 0,
    [transactions]
  )
  const totalRetrait = useMemo(
    () =>
      transactions.length > 0
        ? transactions
            .map((el) => (el.montant < 0 ? -el.montant : 0))
            .reduce((acc, curr) => acc + curr)
        : 0,
    [transactions]
  )
  const totalOperations = useMemo(
    () =>
      operations.length > 0
        ? operations.map((el) => !el.neutralise && el.montant).reduce((acc, curr) => acc + curr)
        : 0,
    [operations]
  )
  const totalVentes = useMemo(
    () =>
      operations.length > 0
        ? operations
            .map((el) => (!el.neutralise && !el.achat ? el.montant : 0))
            .reduce((acc, curr) => acc + curr)
        : 0,
    [operations]
  )
  const totalAchats = useMemo(
    () =>
      operations.length > 0
        ? operations
            .map((el) => (!el.neutralise && el.achat ? -el.montant : 0))
            .reduce((acc, curr) => acc + curr)
        : 0,
    [operations]
  )

  return (
    <div className="table">
      <div className="scrollwrapper scrollwrapper--noNumber">
        <table className="table__content">
          <tbody className="tbody">
            <tr
              className="tbody__tr tbody__tr--dropdown"
              onClick={print ? undefined : () => setToggleDispo(!toggleDispo)}
            >
              <th className="tbody__tr__td">
                {categorie === 'AV' || categorie === 'retraite' ? 'Opérations' : 'Disponibilités'}
              </th>
              <td className="tbody__tr__td bold">
                {categorie === 'AV' || categorie === 'retraite'
                  ? `${liquidite.toLocaleString(undefined, {
                      minimumFractionDigits: 4,
                    })} €`
                  : `${totalLiquidite.toLocaleString(undefined, {
                      minimumFractionDigits: 4,
                    })} €`}
              </td>
            </tr>
            {toggleDispo || print ? (
              <>
                {categorie !== 'AV' && categorie !== 'retraite' ? (
                  <>
                    <tr className="tbody__tr">
                      <th className="tbody__tr__td">{`Remises de fonds`}</th>
                      <td className="tbody__tr__td">{`${totalRemise.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                      })} €`}</td>
                    </tr>
                    <tr className="tbody__tr">
                      <th className="tbody__tr__td">{`Retraits de fonds`}</th>
                      <td className="tbody__tr__td">{`${totalRetrait.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                      })} €`}</td>
                    </tr>
                    <tr className="tbody__tr">
                      <th className="tbody__tr__td bold">{`Total des transactions`}</th>
                      <td className="tbody__tr__td bold">{`${totalTransaction.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 4 }
                      )} €`}</td>
                    </tr>
                  </>
                ) : null}
                <tr className="tbody__tr">
                  <th className="tbody__tr__td">{`Valeur des ventes`}</th>
                  <td className="tbody__tr__td">{`${totalVentes.toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} €`}</td>
                </tr>
                <tr className="tbody__tr">
                  <th className="tbody__tr__td">{`Valeur des achats`}</th>
                  <td className="tbody__tr__td">{`${totalAchats.toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} €`}</td>
                </tr>
              </>
            ) : null}
            <tr
              className={
                portefeuille && portefeuille.supportsP.length > 0
                  ? 'tbody__tr tbody__tr--dropdown'
                  : 'tbody__tr'
              }
              onClick={print ? undefined : () => setTogglePortefeuille(!togglePortefeuille)}
            >
              <td className="tbody__tr__td">Portefeuille</td>
              <td className="tbody__tr__td bold">{`${valeurPortefeuille.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}</td>
            </tr>
            {togglePortefeuille || print ? (
              <>
                {portefeuille &&
                  portefeuille.supportsP.map((el, index) => (
                    <tr className="tbody__tr" key={index}>
                      <td className="tbody__tr__td">{`${el.nomSP}`}</td>
                      <td className="tbody__tr__td bold">{`${(el.dici && el.dici.c_nom === FE
                        ? el.montant
                        : el.quantite * el.dernierCours
                      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}</td>
                    </tr>
                  ))}
              </>
            ) : null}
          </tbody>
          <tfoot className="tfoot">
            <tr className="thead__tr">
              <th className="thead__tr__th thead__tr__th--noPointer">
                {valeurPortefeuille -
                  Math.abs(
                    categorie === 'AV' || categorie === 'retraite' ? liquidite : totalOperations
                  ) <
                0
                  ? 'Perte :'
                  : 'Gain :'}
              </th>
              <th
                className={
                  valeurPortefeuille -
                    Math.abs(
                      categorie === 'AV' || categorie === 'retraite' ? liquidite : totalOperations
                    ) <
                  0
                    ? 'thead__tr__th thead__tr__th--bold thead__tr__th--noPointer thead__tr__th--important thead__tr__th--negative'
                    : 'thead__tr__th thead__tr__th--bold thead__tr__th--noPointer thead__tr__th--important'
                }
              >
                {`${Math.abs(
                  valeurPortefeuille -
                    Math.abs(
                      categorie === 'AV' || categorie === 'retraite' ? liquidite : totalOperations
                    )
                ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default ProductTable
