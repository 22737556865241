import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import useFormIsActive from '../../../layout/useFormIsActive'

const NavComponent = ({ links, menu, h3, handleclick }) => {
  // path
  const { pathname } = useLocation()
  // formIsActive
  const formIsActive = useFormIsActive()
  // path match
  const handleMatch = (string) => {
    return pathname.includes(string) ? true : false
  }

  return (
    <ul className={`${menu}__menu`}>
      {links.map((link, index) => (
        <li className={`${menu}__menu__item`} key={index}>
          {formIsActive ? (
            <div
              className={`secondNav__link secondNav__link--${link.type} secondNav__link--${link.type}--inactive`}
            >
              {handleMatch(`${link.name}`) ? (
                h3 ? (
                  <h3>{link.name}</h3>
                ) : (
                  <h4>{link.name}</h4>
                )
              ) : (
                <p>{link.name}</p>
              )}
            </div>
          ) : (
            <NavLink
              to={link.link}
              className={({ isActive }) =>
                isActive
                  ? `secondNav__link secondNav__link--${link.type} secondNav__link--${link.type}--active`
                  : `secondNav__link secondNav__link--${link.type} secondNav__link--${link.type}--noactive `
              }
              onClick={() => (handleclick ? handleclick() : undefined)}
            >
              {({ isActive }) => (
                <>
                  {isActive ? h3 ? <h3>{link.name}</h3> : <h4>{link.name}</h4> : <p>{link.name}</p>}
                </>
              )}
            </NavLink>
          )}
        </li>
      ))}
    </ul>
  )
}

export default NavComponent
