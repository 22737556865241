import React from 'react'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import VMPieChart from './VMPieChart'
import TableToPrint from '../../../../table/TableToPrint'
import { PRODUITS_VM } from '../../../../../../constants'
import TableBodyTitres from '../../Catalogue/Table/TableBodyTitres'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'

const VMToPrint = ({ elementToDisplay, valeurPortefeuilles, valeurLiquidites, values }) => {
  
  return (
    <>
      <PatrimoineValueCard totalAmount={valeurPortefeuilles + valeurLiquidites} values={values} />
      <PatrimoineChartCardPrint children={<VMPieChart produitsVM={elementToDisplay} />} />
      <TableToPrint
        data={elementToDisplay}
        tableType={PRODUITS_VM}
        title={`Produits (${elementToDisplay.length})`}
        children={<TableBodyTitres />}
      />
    </>
  )
}

export default VMToPrint
