// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getContactsError, getContactsLoad, getContactsSuccess } from '../actions/actionGetContacts'

// REDUCER // ______________________________________________________________

// initial state
const initialStateContacts = {
  isLoading: false,
  contacts: null,
  error: '',
}
// reducer
export const getContactsReducer = createReducer(initialStateContacts, (builder) => {
  return builder
    .addCase(getContactsLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getContactsSuccess, (draft, action) => {
      draft.isLoading = false
      draft.contacts = action.payload
      draft.error = ''
      return
    })
    .addCase(getContactsError, (draft, action) => {
      draft.isLoading = false
      draft.contacts = null
      draft.error = action.payload
      return
    })
})