import axios from 'axios'
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants'
import {
  getPropertiesError,
  getPropertiesLoad,
  getPropertiesSuccess,
} from '../../redux/actions/Patrimoine/actionGetPatrimoine'

// recupération liste actifs
export const getActifs = (id, pp) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getPropertiesLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/patrimoine/actifs?personneId=${id}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getPropertiesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getPropertiesError(error.message))
      })
  }
}
// update valeur totale Actifs
export const postActifsTotalValue = (id, value, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/patrimoine/majActifsValeurTotale?personneId=${id}&montant=${value}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getPropertiesSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// update valeur totale Actif
export const postTotalValueActif = (actifValue, id, value, pp, divers, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/patrimoine/${actifValue}?personneId=${id}&montant=${value}&pp=${pp}&divers=${divers}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getPropertiesSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// post actif
export const postActif = (actifToPost, id, pp, divers, data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/patrimoine/${actifToPost}?personneId=${id}&pp=${pp}&divers=${divers}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(getPropertiesSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// delete actif
export const deleteActif = (actifToDelete, data, idPP, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${API_BASE_URL}/patrimoine/${actifToDelete}/${data.id}/${idPP}/${pp}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
        dispatch(getActifs(idPP, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// recupBien
export const recupBien = (type, id, array, link) => {
  let bien

  switch (type) {
    case 'immobilier':
      bien = array.actifsImmobilier.biensImmobilier.filter((el) => el.id === id)[0]
      break
    case 'professionnel':
      bien = array.actifsBienProfessionnel.biensProfessionnels.filter((el) => el.id === id)[0]
      break
    case 'financier':
      const arrayAV = array.financiers.produitsAV
      const arrayVM = array.financiers.produitsVM
      const arrayPF = array.financiers.comptesBancaires
      bien = Array.prototype.concat(arrayAV, arrayVM, arrayPF).filter((el) => el.id === id)[0]
      break
    case 'autre':
      bien = array.actifsAutres.autresActifs.filter((el) => el.id === id)[0]
      break
    default:
      return
  }
  if (bien) {
    return link
      ? { bien: bien, type: type }
      : type === 'immobilier'
      ? `${bien.typeBien} - ${bien.nom}`
      : type === 'professionnel'
      ? `${bien.nature} - ${bien.nom}`
      : type === 'financier'
      ? `Financier - ${bien.libelle}`
      : `${bien.nom}`
  } else {
    return 'bien non retrouvé'
  }
}
