// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getPerson } from '../actions/actionGetPerson'

// REDUCER // ______________________________________________________________

// initial state
const initialStateGetPerson = {
  person: null,
}
// reducer
export const getPersonReducer = createReducer(initialStateGetPerson, (builder) => {
  return builder.addCase(getPerson, (draft, action) => {
    draft.person = action.payload
    return
  })
})
