import React from 'react'
import { dateFormate, dateFormateComplete } from '../../../../services/helpers/dates'

const BodyBeneficiairePrint = ({ element, index }) => {
  return (
    <>
      {/* nom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">{!element.nom ? '-' : element.nom}</p>
      </div>
      {/* prenom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Prénom</p>
        <p className="print__table__bloc__line--value">{!element.prenom ? '-' : element.nom}</p>
      </div>
      {/* dateDeNaissanceFormatee */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Date de naissance</p>
        <p className="print__table__bloc__line--value">
          {!element.dateDeNaissanceFormatee
            ? '-'
            : element.dateDeNaissanceFormatee.includes('/')
            ? element.dateDeNaissanceFormatee
            : dateFormate(element.dateDeNaissanceFormatee)}
        </p>
      </div>
      {/* date_greffe */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Date greffe</p>
        <p className="print__table__bloc__line--value">
          {!element.date_greffe ? '-' : dateFormateComplete(element.date_greffe)}
        </p>
      </div>
      {/* pourcentage_parts */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Parts</p>
        <p className="print__table__bloc__line--value">
          {!element.pourcentage_parts ? '-' : `${element.pourcentage_parts}%`}
        </p>
      </div>
      {/* pourcentagePleinePropriete */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Pleine propriété</p>
        <p className="print__table__bloc__line--value">
          {!element.pourcentagePleinePropriete ? '-' : `${element.pourcentagePleinePropriete}%`}
        </p>
      </div>
      {/* pourcentageNuePropriete */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Nue propriété</p>
        <p className="print__table__bloc__line--value">
          {!element.pourcentageNuePropriete ? '-' : `${element.pourcentageNuePropriete}%`}
        </p>
      </div>
    </>
  )
}

export default BodyBeneficiairePrint
