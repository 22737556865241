import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  dataSocialPrevoyanceGeneral,
  dataSocialPrevoyanceTerms,
  dataSocialPrevoyanceValue,
} from '../../../../../../data/informations/social'
import { PREVOYANCE } from '../../../../../../constants'
import useCustomPrint from '../../../../../layout/useCustomPrint'
import Loader from '../../../../../layout/Loader'
import PrintComponent from '../../../../Components/PrintComponent'
import DetentionInfoCard from '../../../Detention/DetentionInfoCard'
import DocInfoCard from '../../../Patrimoine/DocInfoCard'
import ButtonsRow from '../../../Patrimoine/ButtonsRow'
import PrevoyanceInfoPrint from './Print/PrevoyanceInfoPrint'
import MainInformationsCard from '../../../../Components/MainInformationsCard'

const PrevoyanceInfo = () => {
  const navigate = useNavigate()
  const { bien } = useSelector((state) => state.getBien)
  // fichiers
  const [patrimoineFiles, setPatrimoineFiles] = useState([])
  // redirection
  const formRedirection = '/user/patrimoine/actifs/social/prevoyance/formulaire'
  const backRedirection = `/user/patrimoine/actifs/social/prevoyance`
  // actualisation bien
  useEffect(() => {
    bien && setPatrimoineFiles(bien.fichiers)
  }, [bien])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!bien) return <Navigate to={backRedirection} replace />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<PrevoyanceInfoPrint elementToDisplay={bien} />}
        />
        <ButtonsRow
          handlePrint={handlePrint}
          formLink={formRedirection}
          backLink={backRedirection}
          tableType={PREVOYANCE}
        />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            {/* Informations principales */}
            <MainInformationsCard
              title={'Informations principales'}
              h5={true}
              data={dataSocialPrevoyanceGeneral}
              element={bien}
              redirection={() => navigate(formRedirection)}
            />
          </div>
          <div className={'cards'}>
            {/* Modalités du contrat */}
            <MainInformationsCard
              title={'Modalités du contrat'}
              h5={true}
              data={dataSocialPrevoyanceTerms}
              element={bien}
              redirection={() => navigate(formRedirection)}
            />
            {/* Valeur du contrat */}
            <MainInformationsCard
              title={'Valeur du contrat'}
              h5={true}
              data={dataSocialPrevoyanceValue}
              element={bien}
              redirection={() => navigate(formRedirection)}
            />
          </div>
          <div className="cards">
            {/* Detenteurs */}
            <DetentionInfoCard
              bienToDisplay={bien}
              redirection={() => navigate(formRedirection)}
              showUS={true}
              h5={true}
            />
            {/* documents */}
            <DocInfoCard
              patrimoineFiles={patrimoineFiles}
              redirection={() => navigate(formRedirection)}
              h5={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PrevoyanceInfo
