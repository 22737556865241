import React from 'react'
import Card from '../../Components/Card'
import Input from '../../Components/Input'
import DateInputComponent from '../../Components/DateInputComponent'

const InputsBoss = ({ infoBoss, handleChange, handleChangeDate }) => {
  const { dirigeant, date_naissance_dirigeant } = infoBoss || {}

  return (
    <Card title="Dirigeant" open={true}>
      <div className="informations">
        {/* dirigeant */}
        <Input
          forAndId="dirigeant"
          type="text"
          name="Nom :"
          value={dirigeant}
          onChange={(e) => handleChange(e)}
          placeholder="Nom"
          copy={true}
        />
        {/* Date de naissance */}
        <DateInputComponent
          name={'date_naissance_dirigeant'}
          label={'Date de naissance :'}
          value={date_naissance_dirigeant}
          handleChange={handleChangeDate}
        />
      </div>
    </Card>
  )
}

export default InputsBoss
