import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCharges } from '../../../../../services/API/charges'
import Loader from '../../../../layout/Loader'
import useCustomPrint from '../../../../layout/useCustomPrint'
import usePersonData from '../../../../layout/usePersonData'
import PrintComponent from '../../../Components/PrintComponent'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import ChargesPieChart from './ChargesPieChart'
import ModalUpdateTotalValueCharges from './ModalUpdateTotalValueCharges'
import ChargesToPrint from './Print/ChargesToPrint'
import LittleLoader from '../../../../layout/LittleLoader'

const ChargesHome = () => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  const charges = useSelector((state) => state.getCharges.charges)
  const { valeurTotalCharge, chargeDivers } = charges || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: chargeDivers?.montant },
      { label: 'Affecté', value: valeurTotalCharge - chargeDivers?.montant },
    ],
    [chargeDivers, valeurTotalCharge]
  )
  // recharge
  useEffect(() => {
    if (!charges) {
      dispatch(getCharges(person?.id, isPP))
    }
  }, [charges, person, isPP, dispatch])
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!charges) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<ChargesToPrint elementToDisplay={charges} values={values} />}
        />
        <ButtonsRow handlePrint={handlePrint} backLink={-1} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurTotalCharge}
              update={true}
              values={values}
            />
            {valeurTotalCharge !== 0 && (
              <PatrimoineChartCard
                children={<ChargesPieChart charges={charges} />}
                detenteurs={true}
              />
            )}
          </div>
        </div>
        {toggleUpdateTotal && (
          <ModalUpdateTotalValueCharges
            setToggleUpdateTotal={setToggleUpdateTotal}
            person={person}
            montantTotalCharge={valeurTotalCharge}
            pp={isPP}
          />
        )}
      </div>
    </>
  )
}

export default ChargesHome
