import React from 'react'
import { REVENUS_AUTRES } from '../../../../../../constants'
import TableToPrint from '../../../../table/TableToPrint'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import TableBodyRevenu from '../../Revenus/Table/TableBodyRevenu'
import RevenusAutresPieChart from '../RevenusAutresPieChart'

const RevenusAutresToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurAutreRevenus } = elementToDisplay || {}

  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurAutreRevenus}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<RevenusAutresPieChart autresRevenus={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={REVENUS_AUTRES}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyRevenu />}
      />
      <TableToPrint
        data={dataSold}
        tableType={REVENUS_AUTRES}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyRevenu />}
      />
    </>
  )
}

export default RevenusAutresToPrint
