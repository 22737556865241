// ______________________________________ PP
export const optionsSexe = [
    { value: 'FEMININ', label: 'Féminin' },
    { value: 'MASCULIN', label: 'Masculin' },
  ]
  export const optionsRelationPP = [
    { value: false, label: 'Référent' },
    { value: true, label: 'Relation' },
  ]
  export const optionsCapacity = [
    { value: 'Majeur', label: 'Majeur' },
    { value: 'Mineur', label: 'Mineur' },
    { value: 'Majeur sous tutelle', label: 'Majeur sous tutelle' },
    { value: 'Majeur sous curatelle', label: 'Majeur sous curatelle' },
    { value: 'Majeur sous habilitation familiale', label: 'Majeur sous habilitation familiale' },
    { value: 'Mineur sous tutelle', label: 'Mineur sous tutelle' },
    { value: 'Mineur sous curatelle', label: 'Mineur sous curatelle' },
  ]
  export const optionsSituation = [
    { value: 'Marié(e)', label: 'Marié-e' },
    { value: 'Pacsé(e)', label: 'Pacsé-e' },
    { value: 'Concubin(e)', label: 'Concubin-e' },
    { value: 'Divorcé(e)', label: 'Divorcé-e' },
    { value: 'Separé(e)', label: 'Séparé-e' },
    { value: 'Celibataire', label: 'Célibataire' },
    { value: 'Veuf(ve)', label: 'Veuf-ve' },
  ]
  export const optionsRegime = [
    { value: 'Communauté réduite aux acquêts', label: 'Communauté réduite aux acquêts' },
    { value: 'Séparation des biens', label: 'Séparation des biens' },
    { value: "Communauté d'acquêts aménagée", label: "Communauté d'acquêts aménagée" },
    { value: 'Communauté universelle', label: 'Communauté universelle' },
    { value: 'Participation aux acquêts', label: 'Participation aux acquêts' },
  ]
  export const optionsFormeJuridique = [
    { value: 'Entrepreneur individuel', label: 'EI' },
    { value: 'EIRL, entreprise individuelle à responsabilité limitée', label: 'EIRL' },
    { value: 'SARL, société à responsabilité limitée', label: 'SARL' },
    { value: 'EURL, entreprise unipersonnelle à responsabilité limitée', label: 'EURL' },
    { value: 'SAS, société par actions simplifiée', label: 'SAS' },
    { value: 'SASU, société par actions simplifiée unipersonnelle', label: 'SASU' },
    { value: 'Société en commandite par actions', label: 'SCA' },
  ]
  export const optionsStatutJob = [
    { value: 'Apprenti', label: 'Apprenti-e' },
    { value: 'Artisan', label: 'Artisan' },
    { value: 'Auteur', label: 'Auteur-e' },
    { value: 'Commerçant', label: 'Commerçant-e' },
    { value: 'Exploitant agricole', label: 'Exploitant-e agricole' },
    { value: 'Fonctionnaire', label: 'Fonctionnaire' },
    { value: 'Intérimaire', label: 'Intérimaire' },
    { value: 'Intermittent du spectacle', label: 'Intermittent-e du spectacle' },
    { value: 'Libéral', label: 'Libéral' },
    { value: 'Pigiste', label: 'Pigiste' },
    { value: 'Saisonnier', label: 'Saisonnier-ère' },
    { value: 'Salarié', label: 'Salarié-e' },
    { value: 'Travailleur indépendant', label: 'Travailleur-euse indépendant-e' },
    { value: 'Travailleur intermittent', label: 'Travailleur-euse intermittent-e' },
  ]
  