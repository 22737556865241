import React from 'react'
import { COLORS } from '../../../../../constants'
import PieChartComponent from '../../../Charts/PieChartComponent'
import LegendPieChart from '../../../Charts/LegendPieChart'

const ChargesPieChart = ({ charges, detenteurs, print }) => {
  const {
    valeurTotalChargeGenerale,
    valeurTotalePension,
    valeurTotaleEmpruntEtDette,
    valeurTotalChargeDeductibleRI,
    valeurTotalChargeOuvrantDroitReductionRI,
    valeurTotalImpotEtTaxe,
    chargeDivers,
    // valeurTotalCharge,
    valeurTotalChargeDetenue,
  } = charges || {}

  const data = !detenteurs
    ? [
        {
          name: 'Dépenses courantes',
          value: valeurTotalChargeGenerale,
          fill: COLORS.colorRepartition01,
          color: 'repartition01',
        },
        {
          name: 'Pensions',
          value: valeurTotalePension,
          fill: COLORS.colorRepartition02,
          color: 'repartition02',
        },
        {
          name: 'Emprunts & dettes',
          value: valeurTotaleEmpruntEtDette,
          fill: COLORS.colorRepartition03,
          color: 'repartition03',
        },
        {
          name: 'Charges décuctibles',
          value: valeurTotalChargeDeductibleRI,
          fill: COLORS.colorRepartition04,
          color: 'repartition04',
        },
        {
          name: "Réductions et crédits d'impôts",
          value: valeurTotalChargeOuvrantDroitReductionRI,
          fill: COLORS.colorRepartition05,
          color: 'repartition05',
        },
        {
          name: 'Impôts & taxes',
          value: valeurTotalImpotEtTaxe,
          fill: COLORS.colorRepartition06,
          color: 'repartition06',
        },
        {
          name: 'Charges non affectées',
          value: chargeDivers.montant,
          fill: COLORS.colorFontLight,
          color: 'divers',
        },
      ]
    : [
        {
          name: "Détenu par l'utilisateur",
          value: valeurTotalChargeDetenue,
          fill: COLORS.colorSecondary,
          color: 'detenu',
        },
        {
          name: "Non détenu par l'utilisateur",
          value: valeurTotalChargeDetenue - chargeDivers.montant,
          // value: valeurTotalCharge - valeurTotalChargeDetenue - chargeDivers.montant,
          fill: COLORS.colorPrimary,
          color: 'nonDetenu',
        },
        {
          name: 'Charges non affectées',
          value: chargeDivers.montant,
          fill: COLORS.colorFontLight,
          color: 'divers',
        },
      ]
  // title
  const title = !detenteurs ? 'Répartition' : 'Détention'
  return (
    <>
      <PieChartComponent data={data} title={title} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title} />
    </>
  )
}

export default ChargesPieChart
