import React from 'react';

const BodyContacts = ({element, handleEdit}) => {
    return (
        <>
            {/* Nom */}
            <td className="tbody__tr__td" onClick={() => handleEdit(element)}>
              {!element.nom ? (
                <p className="informations__line__notxt">-</p>
              ) : (
                element.nom
              )}
            </td>
            {/* Prénom */}
            <td className="tbody__tr__td" onClick={() => handleEdit(element)}>
              {!element.prenom ? (
                <p className="informations__line__notxt">-</p>
              ) : (
                element.prenom
              )}
            </td>
            {/* Mail */}
            <td className="tbody__tr__td" onClick={() => handleEdit(element)}>
              {!element.email ? (
                <p className="informations__line__notxt">-</p>
              ) : (
                element.email
              )}
            </td>
            {/* mobile */}
            <td className="tbody__tr__td" onClick={() => handleEdit(element)}>
              {!element.telephonePortable ? (
                <p className="informations__line__notxt">-</p>
              ) : (
                `${element.telephonePortable}`
              )}
            </td>
        </>
    );
};

export default BodyContacts;