import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFirms } from '../../../services/API/cabinets'
import ModalAddFirm from '../../components/Firm/ModalAddFirm'
import ModalAddFirmNotOk from '../../components/Firm/ModalAddFirmNotOk'
import ModalAddFirmOK from '../../components/Firm/ModalAddFirmOK'
import FirmsTableCard from './FirmsTableCard'
import NotCorrect from '../../components/Components/Modales/NotCorrect'

const AdminFirmManagement = () => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(null)

  const selectFirms = (state) => state.getFirms.firms
  const firms = useSelector(selectFirms)
  const [dataFirms, setDataFirms] = useState([])

  // modales
  const [modalAddFirm, setModalAddFirm] = useState(false)
  // modales
  const [modalOk, setModalOk] = useState(false)
  const [modalNotOk, setModalNotOk] = useState(false)
  const [modalNotCorrect, setModalNotCorrect] = useState(false)

  useEffect(() => {
    dispatch(getFirms())
  }, [])

  useEffect(() => {
    if (firms) {
      setDataFirms(firms)
    }
  }, [firms])

  // response status and modal
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setModalOk(true)
      dispatch(getFirms())
    }
    if (status >= 400) {
      setModalNotOk(true)
    }
  }, [status])

  return (
    <section className="section">
      <h1 className="h1">Gestion des cabinets</h1>
      <div className="cards cards--fullWidth">
        <FirmsTableCard
          dataFirms={dataFirms}
          setDataFirms={setDataFirms}
          setModalAddFirm={setModalAddFirm}
        />
      </div>
      {modalAddFirm && (
        <ModalAddFirm
          setModalAddFirm={setModalAddFirm}
          setModalNotCorrect={setModalNotCorrect}
          setStatus={setStatus}
        />
      )}
      {/* modal response ok _________________________________________________*/}
      {modalOk && (
        <ModalAddFirmOK
          setModalOK={setModalOk}
          setModalAddFirm={setModalAddFirm}
          setStatus={setStatus}
        />
      )}
      {/* modal response not ok _____________________________________________*/}
      {modalNotOk && (
        <ModalAddFirmNotOk
          setModalNotOk={setModalNotOk}
          setModalAddFirm={setModalAddFirm}
          setStatus={setStatus}
        />
      )}
      {/* modal not correct __________________________________________________*/}
      {modalNotCorrect && <NotCorrect setNotCorrect={setModalNotCorrect} />}
    </section>
  )
}

export default AdminFirmManagement
