import React from 'react'
import Modale from '../Components/Modale'
import MyDocument from './MyDocument'
import { useDispatch, useSelector } from 'react-redux'
import { getFileSuccess } from '../../../redux/actions/actionGetFile'

const ModalShowDoc = ({ setShowDoc }) => {
  const dispatch = useDispatch()
  const file = useSelector((state) => state.getFile.file)
  const { url, name } = file || {}
  const error = useSelector((state) => state.getFile.error)

  const downloadFile = () => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${name}_MEP.pdf`)
    // Append to html link element page
    document.body.appendChild(link)
    // Start download
    link.click()
    // Clean up and remove the link
    link.parentNode.removeChild(link)
  }
  const handleClose = () => {
    dispatch(getFileSuccess(null))
    setShowDoc(false)
  }
  const getTitleDoc = (str) => {
    const name = str.includes('pdf')
      ? str.slice(0, str.indexOf('pdf') + 3)
      : str.slice(0, str.indexOf('_'))
    return name
  }
  return (
    <Modale close={() => handleClose()} title="Aperçu">
      <div className="informations informations--center">
        <MyDocument file={url} title={file ? getTitleDoc(name) : `erreur : ${error}`} />
        <div className="informations__buttons">
          <div className="button button--blue-red width--12" onClick={() => handleClose()}>
            Fermer
          </div>
          <div className="button button--blue-red width--12" onClick={() => downloadFile()}>
            télécharger
          </div>
        </div>
      </div>
    </Modale>
  )
}

export default ModalShowDoc
