// Fonction de tri améliorée qui peut accepter une fonction de calcul.
export const sortByType = (sort, array, propOrFunc, set) => {
  const newList = Array.from(array).sort(createComparer(propOrFunc, sort))
  set(newList)
}
// Utilitaire pour extraire une valeur d'un objet, qu'elle soit d'une propriété simple ou imbriquée.
const extractValue = (obj, property) => {
  return property.split('.').reduce((acc, prop) => acc && acc[prop], obj)
}
// Générateur de fonction de comparaison qui peut également accepter une fonction de calcul.
const createComparer = (propertyOrFunc, ascending = true) => {
  const isFunc = typeof propertyOrFunc === 'function'

  return function compare(a, b) {
    let valueA = isFunc ? propertyOrFunc(a) : extractValue(a, propertyOrFunc)
    let valueB = isFunc ? propertyOrFunc(b) : extractValue(b, propertyOrFunc)
    // Conversion de chaînes de caractères en minuscules pour la comparaison.
    if (typeof valueA === 'string') valueA = valueA.toLowerCase()
    if (typeof valueB === 'string') valueB = valueB.toLowerCase()
    // Comparaison directe si les deux valeurs sont des nombres.
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return valueA < valueB ? (ascending ? -1 : 1) : valueA > valueB ? (ascending ? 1 : -1) : 0
    }
    // Conversion de dates valides pour la comparaison.
    if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
      valueA = new Date(valueA)
      valueB = new Date(valueB)
    }
    // Comparaison directe en JavaScript.
    return valueA < valueB ? (ascending ? -1 : 1) : valueA > valueB ? (ascending ? 1 : -1) : 0
  }
}
// Maintenant, vous pouvez toujours utiliser dynamicSortUp et dynamicSortDown pour trier.
export const dynamicSortUp = (propertyOrFunc) => createComparer(propertyOrFunc, true)
export const dynamicSortDown = (propertyOrFunc) => createComparer(propertyOrFunc, false)
