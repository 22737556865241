import { ACCESS_TOKEN } from '../../constants'

const getAuthHeader = () => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return { Authorization: `Bearer ${token}` }
}

export const createRequestOptions = (method, body = null) => {
  let headers = new Headers({
    ...getAuthHeader(),
    Accept: 'application/json', // Pour une requête GET, il est plus approprié d'utiliser Accept
  })
  let options = {
    method: method,
    headers: headers,
    redirect: 'follow',
  }
  if (body) {
    // Pour une requête POST ou PUT, par exemple
    if (!(body instanceof FormData)) {
      // Si le corps n'est pas déjà un FormData, on suppose qu'il faut envoyer du JSON
      headers.append('Content-Type', 'application/json')
      options.body = JSON.stringify(body)
    } else {
      // Pour FormData, le navigateur définit automatiquement le Content-Type
      options.body = body
    }
  }
  return options
}
export const createAxiosConfig = (method, url, data = null) => {
  // Configuration de base pour Axios
  let config = {
    method: method,
    url: url,
    headers: {
      ...getAuthHeader(),
      'Content-Type': 'application/json', // Par défaut, mais peut être ajusté si nécessaire
    },
  }
  if (data) {
    config.data = data
  }
  return config
}

// exemple utilisation axios :
// const API_BASE_URL = process.env.API_BASE_URL; // Assurez-vous que cela est correctement défini

// export const someFunctionMakingAPostRequest = async (type, id, pp, divers, data) => {
//   const token = sessionStorage.getItem('ACCESS_TOKEN'); // Assurez-vous d'avoir accès au token ici, si nécessaire

//   const config = createAxiosConfig('POST', `${API_BASE_URL}/financier/${type}?personneId=${id}&pp=${pp}&divers=${divers}`, data);
//   // Ajouter ou écraser des en-têtes spécifiques pour cette requête, si nécessaire
//   config.headers['Authorization'] = `Bearer ${token}`;

//   try {
//     const response = await axios(config);
//     console.log(response.data);
//     // Traiter la réponse...
//   } catch (error) {
//     console.error('Error making the request:', error);
//     // Gérer l'erreur...
//   }
// };
