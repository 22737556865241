// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get revenus
 * @reduxAction
 */

export const getIncomesLoad = createAction('get-incomes-load')

export const getIncomesSuccess = createAction(
  'get-incomes-success',
  (incomes) => {
    return {
      payload: incomes,
    }
  }
)

export const getIncomesError = createAction(
  'get-incomes-error', (error) => {
  return {
    payload: error,
  }
})