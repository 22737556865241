import React from 'react'
import { stringToCapitalize } from '../../../../../../../services/helpers/strings'
import { beautifulDate } from '../../../../../../../services/helpers/dates'
import { recupLabel } from '../../../../../../../services/helpers/helpers'
import { optionsTypePeriodicite } from '../../../../../../../data/options/options'

const BodyPrevoyance = ({ element, handleShow }) => {
  return (
    <>
      {/* libelle */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.libelle ? <p className="informations__line__notxt">-</p> : element.libelle}
      </td>
      {/* assureur */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.assureur ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          stringToCapitalize(element.assureur)
        )}
      </td>
      {/* nomContrat */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.nomContrat ? <p className="informations__line__notxt">-</p> : element.nomContrat}
      </td>
      {/* Numéro */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.numero ? <p className="informations__line__notxt">-</p> : element.numero}
      </td>
      {/* categorie */}
      <td className="tbody__tr__td tbody__tr__td--pointer bold" onClick={() => handleShow(element)}>
        {!element.categorie ? <p className="informations__line__notxt">-</p> : element.categorie}
      </td>
      {/* sousCategorie */}
      <td className="tbody__tr__td tbody__tr__td--pointer bold" onClick={() => handleShow(element)}>
        {!element.sousCategorie ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.sousCategorie
        )}
      </td>
      {/* dateSouscription */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.dateSouscription ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.dateSouscription)
        )}
      </td>
      {/* duree */}
      <td className="tbody__tr__td tbody__tr__td--pointer bold" onClick={() => handleShow(element)}>
        {!element.duree ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.duree.toLocaleString(undefined, { minimumFractionDigits: 4 })} mois`
        )}
      </td>
      {/* dateFin */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.dateFin ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.dateFin)
        )}
      </td>
      {/* capital */}
      <td className="tbody__tr__td tbody__tr__td--pointer bold" onClick={() => handleShow(element)}>
        {!element.capital && element.capital !== 0 ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.capital.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
        )}
      </td>
      {/* taux */}
      <td className="tbody__tr__td tbody__tr__td--pointer bold" onClick={() => handleShow(element)}>
        {!element.taux ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.taux.toLocaleString(undefined, { minimumFractionDigits: 4 })} %`
        )}
      </td>
      {/* renteEstime */}
      <td className="tbody__tr__td tbody__tr__td--pointer bold" onClick={() => handleShow(element)}>
        {!element.renteEstime ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.renteEstime.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
        )}
      </td>
      {/* frequenceCotisation */}
      <td className="tbody__tr__td tbody__tr__td--pointer bold" onClick={() => handleShow(element)}>
        {!element.frequenceCotisation ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          recupLabel(element.frequenceCotisation, optionsTypePeriodicite)
        )}
      </td>
      {/* montantEcheance */}
      <td className="tbody__tr__td tbody__tr__td--pointer bold" onClick={() => handleShow(element)}>
        {!element.montantEcheance ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.montantEcheance.toLocaleString(undefined, {
            minimumFractionDigits: 4,
          })} €`
        )}
      </td>
    </>
  )
}

export default BodyPrevoyance
