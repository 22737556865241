import React from 'react'
import { CHARGES_EMPRUNTS } from '../../../../../constants'
import TableToPrint from '../../../table/TableToPrint'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../Patrimoine/PatrimoineChartCardPrint'
import TableBodyCharge from '../Charges/Table/TableBodyCharge'
import ChgEmpruntDettePieChart from './ChgEmpruntDettePieChart'

const ChargesEmpruntDetteToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurEmpruntEtDettes } = elementToDisplay || {}
  return (
    <>
      <PatrimoineValueCard totalAmount={valeurEmpruntEtDettes} values={values} />
      <PatrimoineChartCardPrint
        children={<ChgEmpruntDettePieChart empruntEtDettes={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={CHARGES_EMPRUNTS}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyCharge />}
      />
      <TableToPrint
        data={dataSold}
        tableType={CHARGES_EMPRUNTS}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyCharge />}
      />
    </>
  )
}

export default ChargesEmpruntDetteToPrint
