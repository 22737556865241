import React, { useState, useEffect } from 'react'
import { optionsSexe } from '../../../data/options/optionsPP'
import BlocTable from '../table/BlocTable'
import Card from '../Components/Card'
import Input from '../Components/Input'
import SelectComponent from '../Components/SelectComponent'
import DateInputComponent from '../Components/DateInputComponent'
import { DOC_PERSON_IDENTITY } from '../../../constants'
import TableBodyDoc from '../Files/TableBodyDoc'
import ModaleFiles from '../Files/ModaleFiles'

const InputsIdentity = ({
  person,
  handleChange,
  handleChangeDate,
  personInfos,
  fichiers,
  document,
  setDataChange,
}) => {
  const { nom, prenom, mail, mailUsuel, dateNaissance, sexe, telFixe, telPortable } =
    personInfos || {}
  // modale
  const [modalAdd, setModalAdd] = useState(false)
  // toggle
  const [toggleAdd, setToggleAdd] = useState(false)
  // files
  const [data, setData] = useState(fichiers)
  useEffect(() => {
    setData(fichiers.filter((el) => el.typeFichierGED === 'IDENTITE' && el.dateDocument))
  }, [fichiers])
  const titles = [
    'N°',
    'Type',
    'Document',
    'Date du document',
    'Date de validité',
    'Dernière mise à jour',
    'Voir',
    'Mettre à jour',
    'Supprimer',
  ]

  return (
    <>
      <Card title="Informations essentielles" open={true}>
        <div className="informations">
          <div className="informations informations--borderFull">
            {/* nom */}
            <Input
              forAndId="nom"
              type="text"
              name="Nom d'usage :"
              required={true}
              value={nom}
              onChange={(e) => handleChange(e)}
              placeholder="Nom"
              copy={true}
            />
            {/* prénom */}
            <Input
              forAndId="prenom"
              type="text"
              name="Prénom :"
              required={true}
              value={prenom}
              onChange={(e) => handleChange(e)}
              placeholder="Prénom"
              copy={true}
            />
            {/* mail */}
            <Input
              forAndId={!person ? 'mail' : 'mailUsuel'}
              type="email"
              name="Mail :"
              required={true}
              value={!person ? mail : mailUsuel}
              onChange={(e) => handleChange(e)}
              placeholder="exemple@gmail.com"
              copy={true}
              // disabled={person}
            />
            {/* Date de naissance */}
            <DateInputComponent
              label={'Date de naissance :'}
              value={dateNaissance}
              handleChange={handleChangeDate}
              name={'dateNaissance'}
            />
            {/* sexe */}
            <SelectComponent
              forAndId="sexe"
              name="Sexe de naissance :"
              options={optionsSexe}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={optionsSexe.find((c) => c.value === sexe)}
              copy={true}
              required={true}
            />
            {/* Tel Mobile */}
            <Input
              forAndId="telPortable"
              type="tel"
              pattern="[0-9]{10,12}"
              maxLength={12}
              minLength={10}
              name="Téléphone Mobile :"
              value={telPortable || ''}
              onChange={(e) => handleChange(e)}
              placeholder="0600000000"
              copy={true}
            />
          </div>
          <div>
            {/* Tel fixe */}
            <Input
              forAndId="telFixe"
              type="tel"
              pattern="[0-9]{10,12}"
              maxLength={12}
              minLength={10}
              name="Téléphone fixe :"
              value={telFixe}
              onChange={(e) => handleChange(e)}
              placeholder="0400000000"
              copy={true}
            />
          </div>
        </div>

        {/* fichiers */}
        {person && document === DOC_PERSON_IDENTITY ? (
          !toggleAdd ? (
            <div
              className="informations__dropdown informations__dropdown--full"
              onClick={() => setToggleAdd(!toggleAdd)}
            >
              <p>{`Documents (${data.length})`}</p>
            </div>
          ) : (
            <div className="informations__dropdown--border">
              <p onClick={() => setToggleAdd(!toggleAdd)}>{`Documents (${data.length})`}</p>
              <div className="informations">
                <BlocTable
                  titles={titles}
                  data={data}
                  setData={setData}
                  tableType={document}
                  children={<TableBodyDoc />}
                  setDataChange={setDataChange}
                  doc={true}
                  title={`Documents (${data.length})`}
                  handleAdd={() => setModalAdd(true)}
                />
              </div>
            </div>
          )
        ) : null}
      </Card>
      {modalAdd && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_PERSON_IDENTITY}
          setModal={setModalAdd}
          setDataChange={setDataChange}
        />
      )}
    </>
  )
}

export default InputsIdentity
