import React, { useState } from 'react'
import { handleChangeAddress } from '../../../services/helpers/helpers'
import Input from '../Components/Input'

const InputsAutoCompleteAdresse = ({
  adresseFiscale,
  societe,
  etablissement,
  cabinet,
  bienImmo,
  personInfos,
  data,
  setData,
  toggleAddress,
  setToggleAddress,
  toggleCode,
  setToggleCode,
  toggleCity,
  setToggleCity,
  toggleCountry,
  setToggleCountry,
  handleSelectAddress,
  handleSelectPostcode,
  handleSelectCity,
  handleSelectCountry,
  setDataChange,
}) => {
  // résultats de l'autocomplétion d'adresse
  const [addressResults, setAddressResults] = useState([])
  const [postcodeResults, setPostcodeResults] = useState([])
  const [cityResults, setCityResults] = useState([])
  const [countryResults, setCountryResults] = useState([])

  const handleCloseDropDowns = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setToggleAddress(false)
    setToggleCity(false)
    setToggleCode(false)
    setToggleCountry(false)
  }

  const handlechange = (e) => {
    setDataChange(true)
    handleChangeAddress(
      e,
      data,
      setData,
      setAddressResults,
      setToggleAddress,
      setPostcodeResults,
      setToggleCode,
      setCityResults,
      setToggleCity,
      setCountryResults,
      setToggleCountry
    )
  }

  return (
    <div className="informations" onClick={(e) => handleCloseDropDowns(e)}>
      {/* adresse_ligne_1 */}
      {!personInfos ? (
        <>
          <Input
            forAndId={
              adresseFiscale
                ? 'ad1'
                : societe
                ? 'adresse1'
                : etablissement
                ? 'adresse_ligne_1'
                : cabinet
                ? 'adresseCabinet'
                : bienImmo
                ? 'adresse'
                : 'libelle'
            }
            type="text"
            name="Adresse :"
            value={
              data
                ? adresseFiscale
                  ? data.ad1
                  : societe
                  ? data.adresse1
                  : etablissement
                  ? data.adresse_ligne_1
                  : cabinet
                  ? data.adresseCabinet
                  : bienImmo
                  ? data.adresse
                  : data.libelle
                : ''
            }
            onChange={(e) => handlechange(e)}
            placeholder="325 avenue des Orchidées"
            required={cabinet}
            copy={true}
          />
          {toggleAddress && addressResults.length > 0 && (
            <ul className="autocompleteList">
              {addressResults.map((address, index) => (
                <li
                  key={index}
                  className="autocompleteList__item"
                  onClick={() => handleSelectAddress(address)}
                >
                  {address.properties.label}
                </li>
              ))}
            </ul>
          )}
          {/* adresse_ligne_2 */}
          {(adresseFiscale || etablissement) && (
            <Input
              forAndId={adresseFiscale ? 'ad2' : 'adresse_ligne_2'}
              type="text"
              name="Complément :"
              value={data ? (adresseFiscale ? data.ad2 : data.adresse_ligne_2) : ''}
              onChange={(e) => handlechange(e)}
              placeholder="Apt. 33"
              copy={true}
            />
          )}
          {/* code_postal */}
          <Input
            forAndId={
              adresseFiscale || societe || bienImmo
                ? 'codePostal'
                : etablissement
                ? 'code_postal'
                : cabinet
                ? 'cpCabinet'
                : 'cp'
            }
            type="number"
            name="Code postal :"
            value={
              data
                ? adresseFiscale || societe || bienImmo
                  ? data.codePostal
                  : etablissement
                  ? data.code_postal
                  : cabinet
                  ? data.cpCabinet
                  : data.cp
                : ''
            }
            onChange={(e) => handlechange(e)}
            placeholder="34980"
            required={cabinet}
            copy={true}
          />
          {toggleCode && postcodeResults.length > 0 && (
            <ul className="autocompleteList">
              {postcodeResults.map((postcode, index) => (
                <li
                  key={index}
                  className="autocompleteList__item"
                  onClick={() => handleSelectPostcode(postcode)}
                >
                  {postcode.properties.postcode} {postcode.properties.city}
                </li>
              ))}
            </ul>
          )}
        </>
      ) : null}
      {/* ville */}
      <Input
        forAndId={cabinet ? 'villeCabinet' : personInfos ? 'villeNaissance' : 'ville'}
        type="text"
        name={personInfos ? 'Ville de naissance' : 'Ville :'}
        value={
          data ? (cabinet ? data.villeCabinet : personInfos ? data.villeNaissance : data.ville) : ''
        }
        onChange={(e) => handlechange(e)}
        placeholder="Saint-Clément-de-Rivière"
        required={cabinet}
        copy={true}
      />
      {toggleCity && cityResults.length > 0 && (
        <ul className="autocompleteList">
          {cityResults.map((city, index) => (
            <li
              key={index}
              className="autocompleteList__item"
              onClick={() => handleSelectCity(city)}
            >
              {city.properties.city}
            </li>
          ))}
        </ul>
      )}
      {!personInfos ? (
        <>
          {/* pays */}
          {adresseFiscale || etablissement ? (
            <>
              <Input
                forAndId="pays"
                type="text"
                name="Pays :"
                value={data ? data.pays : 'FRANCE'}
                onChange={(e) => handlechange(e)}
                placeholder="FRANCE"
                copy={true}
              />
              {toggleCountry && countryResults.length > 0 && (
                <ul className="autocompleteList">
                  {countryResults.map((country, index) => (
                    <li
                      key={index}
                      className="autocompleteList__item"
                      onClick={() => handleSelectCountry(country)}
                    >
                      {country.fields.libcog}
                    </li>
                  ))}
                </ul>
              )}
            </>
          ) : null}
          {/* code_pays */}
          {etablissement && (
            <Input
              forAndId="code_pays"
              type="text"
              name="Code pays :"
              value={data ? data.code_pays : ''}
              onChange={(e) => handlechange(e)}
              placeholder=""
              copy={true}
            />
          )}
        </>
      ) : null}
    </div>
  )
}

export default InputsAutoCompleteAdresse
