import React, { useEffect } from 'react'
import { sortByType } from '../../../services/helpers/sort'
import TableBodyBloc from '../../components/table/TableBodyBloc'
import BodyUsers from './BodyUsers'
import BodyUsersPrint from './BodyUsersPrint'

const TableBodyUsers = ({
  setNewData,
  setModalUpdate,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  // edit
  const handleEdit = (element) => {
    setModalUpdate(true)
    setNewData(element)
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Prénom':
          giveAType('prenom')
          break
        case 'Nom':
          giveAType('nom')
          break
        case 'Mail':
          giveAType('email')
          break
        case 'Date naissance':
          giveAType('dateNaissance')
          break
        case 'Rôle':
          giveAType('roles.name')
          break
        case 'Modification':
          giveAType('updatedAt')
          break
        case 'Création':
          giveAType('createdAt')
          break
        case 'Cabinet':
          giveAType('cabinet')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])
  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyUsersPrint /> : <BodyUsers />}
      handleEdit={handleEdit}
      print={print}
    />
  )
}

export default TableBodyUsers
