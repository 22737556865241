import React from 'react'
import Card from '../../../../Components/Card'
import BlocTable from '../../../../table/BlocTable'
import { PREVOYANCE } from '../../../../../../constants'
import TableBodyPrevoyance from './Table/TableBodyPrevoyance'

const PrevoyanceCard = ({ data, handleAdd, title }) => {
  const titles = [
    'N°',
    'Libellé',
    'Assureur',
    'Contrat',
    'Numéro',
    'Catégorie',
    'Sous-catégorie',
    'Souscription',
    'Durée',
    'Fin',
    'Capital',
    'Taux',
    'Rente estimée',
    'Périodicité',
    'Montant',
    'Voir',
    'Modifier',
    'Supprimer',
    'Dupliquer',
  ]

  return (
    <Card title={title} h5={true} open={true}>
      <div className="informations">
        <BlocTable
          data={data}
          titles={titles}
          tableType={PREVOYANCE}
          children={<TableBodyPrevoyance />}
          title={title}
          handleAdd={() => handleAdd()}
        />
      </div>
    </Card>
  )
}

export default PrevoyanceCard
