// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get users
 * @reduxAction
 */

export const getUsersLoad = createAction('get-users-load')

export const getUsersSuccess = createAction(
  'get-users-success',
  (users) => {
    return {
      payload: users,
    }
  }
)

export const getUsersError = createAction(
  'get-users-error', (error) => {
  return {
    payload: error,
  }
})