// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get mail mandataire
 * @reduxAction
 */

export const getUserMailLoad = createAction('get-user-mail-load')

export const getUserMailSuccess = createAction(
  'get-user-mail-success',
  (mandataire) => {
    return {
      payload: mandataire,
    }
  }
)

export const getUserMailError = createAction(
  'get-user-mail-error', (error) => {
  return {
    payload: error,
  }
})