import React from 'react'
import { COLORS } from '../../../../../../constants'
import PieChartComponent from '../../../../Charts/PieChartComponent'
import LegendPieChart from '../../../../Charts/LegendPieChart'
import { getLibelleProduitFinancier } from '../../../../../../services/helpers/strings'

const PFPieChart = ({ comptesBancaires, detenteurs, print }) => {
  // colors
  const colors = [
    { fill: COLORS.colorRepartition01, color: 'repartition01' },
    { fill: COLORS.colorRepartition02, color: 'repartition02' },
    { fill: COLORS.colorRepartition03, color: 'repartition03' },
    { fill: COLORS.colorRepartition04, color: 'repartition04' },
  ]
  // actif immo
  const arrayProduitsFinanciers = comptesBancaires
    .filter((bien) => !bien.clos)
    .map((bien) => ({ nom: getLibelleProduitFinancier(bien.libelle), valeur: bien.solde }))
  const data = arrayProduitsFinanciers.map((det, index) =>
    det.libelle !== 'Non affecté'
      ? {
          name: det.nom,
          value: det.valeur,
          fill: colors[index % colors.length].fill,
          color: colors[index % colors.length].color,
        }
      : {
          name: det.nom,
          value: det.valeur,
          fill: COLORS.colorPrimary,
          color: 'divers',
        }
  )

  const title = 'Répartition'
  return (
    <>
      <PieChartComponent data={data} title={title} detenteurs={detenteurs} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title} detenteurs={detenteurs} />
    </>
  )
}

export default PFPieChart
