import React, { useEffect, useState } from 'react'
import Card from '../../../../Components/Card'
import SelectComponent from '../../../../Components/SelectComponent'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ImArrowRight } from 'react-icons/im'
import Input from '../../../../Components/Input'

const PMLinkCard = ({ information, setInformation, bienToDisplay, setDataChange }) => {
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  const [listPersons, setListPersons] = useState([])
  const [optionsListPersons, setOptionsListPersons] = useState(null)
  const [pm, setPM] = useState(null)
  const [optionsEtabilssements, setOptionsEtablissement] = useState(null)
  const [etablissement, setEtablissement] = useState(null)
  const [personneMorale, setPersonneMorale] = useState(false)
  const [newPerson, setNewPerson] = useState(false)
  // redirectionPM
  const redirectionPM = '/user/comptes/ajouter-Personne-Morale'
  // maj listPersonnes
  useEffect(() => {
    if (personneMorale) {
      setListPersons(persons.dirigeantPM)
    } else {
      setListPersons([])
      setInformation({
        ...information,
        nom: '',
        denominationSociale: '',
        formeJuridique: '',
        montantCapitalSocial: 0,
        siret: '',
        adresse: '',
        codePostal: '',
        ville: '',
        dateCreation: '',
        closOuVendu: '',
        lienPM: false,
        idPM: 0,
      })
      setOptionsEtablissement(null)
    }
    setDataChange(true)
  }, [personneMorale])

  // maj optionsListPersons
  useEffect(() => {
    if (listPersons.length > 0) {
      setOptionsListPersons(
        listPersons.map((el) => ({
          value: el,
          label: el.denominationSociale,
        }))
      )
    } else {
      setOptionsListPersons(null)
    }
  }, [listPersons])
  // capitalToNumber
  const capitalToNumber = (str) => {
    if (str.includes('€')) {
      return str.slice(0, str.indexOf('€')).replace(',', '.').replaceAll(' ', '')
    }
  }

  useEffect(() => {
    if (pm) {
      if (pm.etablissements.length === 1) {
        setEtablissement(pm.etablissements[0])
      }
      setOptionsEtablissement(
        pm.etablissements.map((et) => ({
          label: `${et.siret} - ${et.adresse_ligne_1} ${et.code_postal} ${et.ville}`,
          value: et,
        }))
      )
      setInformation({
        ...information,
        denominationSociale: pm.denominationSociale,
        nom: pm.denominationSociale,
        formeJuridique: pm.formeJuridique,
        montantCapitalSocial: capitalToNumber(pm.capital),
        lienPM: true,
        idPM: pm.id,
      })
    } else {
      setInformation({
        ...information,
        denominationSociale: '',
        formeJuridique: '',
        montantCapitalSocial: 0,
        lienPM: false,
        idPM: 0,
      })
    }
  }, [pm])

  useEffect(() => {
    if (etablissement) {
      setInformation({
        ...information,
        siret: etablissement.siret,
        adresse: etablissement.adresse_ligne_1,
        codePostal: etablissement.code_postal,
        ville: etablissement.ville,
        dateCreation: etablissement.date_de_creation,
        closOuVendu: etablissement.etablissement_cesse,
      })
    } else {
      setInformation({
        ...information,
        siret: '',
        adresse: '',
        codePostal: '',
        ville: '',
        dateCreation: '',
        closOuVendu: '',
      })
    }
  }, [etablissement])

  useEffect(() => {
    if (bienToDisplay?.lienPM) {
      setPersonneMorale(true)
      const person = persons.dirigeantPM.filter((person) => person.id === bienToDisplay.idPM)[0]
      setPM(person)
      setEtablissement(
        person.etablissements.filter(
          (etablissement) => etablissement.siret === bienToDisplay.siret
        )[0]
      )
    }
  }, [bienToDisplay])

  return (
    <Card title="Personne morale" h4={true} open={true}>
      <div className="informations">
        {/* Personnes */}
        <Input
          forAndId="personneMorale"
          name="Bien lié à une personne morale :"
          onChange={(e) => setPersonneMorale(e.value)}
          value={personneMorale}
          check={true}
          setValue={setPersonneMorale}
        />
        {/* PP ou PM */}
        {listPersons.length > 0 ? (
          <SelectComponent
            forAndId="pm"
            name="Sélectionner une personne :"
            isSearchable={true}
            options={optionsListPersons && optionsListPersons}
            placeholder="Sélectionner"
            onChange={(e) => setPM(e.value)}
            value={optionsListPersons && optionsListPersons.find((c) => c.value === pm)}
          />
        ) : (
          <div className="informations__line">
            <Input
              forAndId="newPerson"
              name="Créer une personne morale :"
              onChange={(e) => setNewPerson(e.value)}
              value={newPerson}
              check={true}
              setValue={setNewPerson}
            />
            {newPerson ? (
              <Link to={redirectionPM} rel="noopener noreferrer" className="siren__link">
                <div>Accès au formulaire</div>
                <ImArrowRight />
              </Link>
            ) : null}
          </div>
        )}
        {optionsEtabilssements && optionsEtabilssements.length > 0 ? (
          <SelectComponent
            forAndId="etablissement"
            name="Sélectionner un établissement :"
            isSearchable={true}
            options={optionsEtabilssements}
            onChange={(e) => (e ? setEtablissement(e.value) : setEtablissement(''))}
            value={optionsEtabilssements.find((c) => c.value === etablissement)}
          />
        ) : null}
      </div>
    </Card>
  )
}

export default PMLinkCard
