import React from 'react'
import BodyDetentionPrint from './BodyDetentionPrint'
import TableBodyBloc from '../../../table/TableBodyBloc'
import BodyDetentionPrintFooter from './BodyDetentionPrintFooter'

const TableBodyDetention = ({ listToDisplay, showUS, newValeurTotale, detenteurs, total }) => {
  return (
    <>
      <TableBodyBloc
        listToDisplay={listToDisplay}
        children={
          <BodyDetentionPrint
            showUS={showUS}
            newValeurTotale={newValeurTotale}
            detenteurs={detenteurs}
          />
        }
        print={true}
      />
      <BodyDetentionPrintFooter total={total} showUS={showUS} />
    </>
  )
}

export default TableBodyDetention
