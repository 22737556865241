// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get passifs
 * @reduxAction
 */

export const getSaveForm = createAction('get-saveForm')
