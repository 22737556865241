import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Modale from '../Components/Modale'

const ModalSigninNotOk = ({ handleRetry }) => {
  const navigate = useNavigate()
  const ref = useRef(null)

  useEffect(() => {
    const curr = ref.current
    curr && curr.focus()
  }, [])
  const onkeydownFunction = (e, f) => {
    if (e.key === 'Enter') {
      f()
    }
  }
  const inscriptionFunction = () => {
    navigate('/inscription')
  }

  return (
    <Modale close={() => handleRetry()} title="Connexion non autorisée !">
      <div className="informations">
        <div className="informations__blocTxt">
          <p className='informations__blocTxt__txt'>
            L'identifiant et le mot de passe ne correspondent pas<span  className='informations__blocTxt__span informations__blocTxt__span--important'>OU</span>Votre compte n'est
            pas activé, <br />
            veuillez consulter votre boite mail ou contacter votre administrateur
          </p>
        </div>
        <div className="informations__buttons">
          <div
            className="button button--blue-red width--12"
            onClick={() => handleRetry()}
            onKeyDown={(e) => onkeydownFunction(e, handleRetry)}
            // name="retry"
            ref={ref}
            tabIndex={1}
          >
            Réessayer
          </div>
          <div
            className="button button--blue-red width--12"
            onClick={() => inscriptionFunction()}
            onKeyDown={(e) => onkeydownFunction(e, inscriptionFunction)}
            tabIndex={1}
          >
            S'inscrire
          </div>
        </div>
      </div>
    </Modale>
  )
}

export default ModalSigninNotOk
