import React, { useEffect } from 'react'
import { sortByType } from '../../../../../../services/helpers/sort'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyPortefeuille from './BodyPortefeuille'
import BodyPortefeuillePrint from './BodyPortefeuillePrint'

const TableBodyPortefeuille = ({
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
  handleClick,
}) => {
  // tri croissant/decroissant
  const giveAType = (typeOrFunc) => {
    sortByType(sort, dataToDisplay, typeOrFunc, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Nom':
          giveAType('nomSP')
          break
        case 'ISIN':
          giveAType('isin')
          break
        case 'Montant':
          giveAType('montant')
          break
        case 'Qté':
          giveAType('quantite')
          break
        case 'Cours':
          giveAType('dernierCours')
          break
        case 'Au...':
          giveAType('dateDernierCours')
          break
        case 'PAM':
          giveAType('pam')
          break
        case '+/- value':
          giveAType((element) => ((element.dernierCours - element.pam) / element.pam) * 100)
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyPortefeuillePrint /> : <BodyPortefeuille handleclick={handleClick} />}
      print={print}
    />
  )
}

export default TableBodyPortefeuille
