import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import SaveForm from './SaveForm'
import useFormIsActive from './useFormIsActive'
import HeaderNotActive from './HeaderNotActive'
import HeaderActive from './HeaderActive'

const Header = ({ links }) => {
  const { prenom, nom } = useSelector((state) => state.getUser.user)
  const formIsActive = useFormIsActive()
  // modales
  const [toggleSaveForm, setToggleSaveForm] = useState(false)
  // handleClick
  const handleClick = useCallback(() => {
    formIsActive && setToggleSaveForm(true)
  }, [formIsActive])
  // getCommitVersion
  const getCommitVersion = (str) => {
    return str.split('|')[1]
  }

  // dev/prod
  const commit = '|v3.267| mese en place admin et pm patrimoine'
  const dev = process.env.NODE_ENV !== 'production' // passe à false en prod

  return (
    <>
      <header className="header" onClick={() => handleClick()}>
        {formIsActive ? (
          <HeaderNotActive
            commit={dev ? commit : getCommitVersion(commit)}
            nom={nom}
            prenom={prenom}
          />
        ) : (
          <HeaderActive
            commit={dev ? commit : getCommitVersion(commit)}
            nom={nom}
            prenom={prenom}
            links={links}
          />
        )}
      </header>
      {toggleSaveForm && (
        <SaveForm toggleSaveForm={toggleSaveForm} setToggleSaveForm={setToggleSaveForm} />
      )}
    </>
  )
}

export default Header
