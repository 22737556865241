import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { ImArrowRight } from 'react-icons/im'
import { Link } from 'react-router-dom'
import Input from '../../Components/Input'

const InputsSiren = ({
  handleClickSiren,
  handleChangeSiren,
  siren,
  errorSiren,
  messageSiren,
  status,
  pappers,
}) => {
  const [change, setChange] = useState(false)
  useEffect(() => {
    if (siren && siren !== '' && siren.length === 9) {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [siren])

  return (
    <>
      <div className="siren informations--borderFull">
        <p className="siren__txt">
          Vous pouvez préremplir ou compléter le formulaire grâce à Pappers en saisissant votre
          SIREN
        </p>
        <Input
          forAndId="siren"
          type="text"
          name="SIREN :"
          value={siren}
          onChange={(e) => handleChangeSiren(e)}
          search={change ? true : false}
          action={handleClickSiren}
          bold={true}
        />
      </div>
      {errorSiren && <p className="siren__error">{messageSiren}</p>}
      {pappers && status < 400 && (
        <div className="siren__linkContainer">
          <Link
            to={`//pappers.fr/entreprise/${siren}`}
            target="_blank"
            rel="noopener noreferrer"
            className="siren__link"
          >
            <p>
              Accéder aux informations de {pappers ? pappers.denominationSociale : 'votre société'}{' '}
              sur Pappers.fr
            </p>
            <ImArrowRight />
          </Link>
        </div>
      )}
    </>
  )
}

export default InputsSiren
