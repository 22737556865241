import { useSelector } from 'react-redux'

const useIncomesData = () => {
  const incomes = useSelector((state) => state.getIncomes.incomes)
  const revenu = useSelector((state) => state.getBien.bien)
  const revenusDivers = incomes?.revenuDivers.montant ?? null

  return { incomes, revenusDivers, revenu }
}

export default useIncomesData
