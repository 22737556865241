import React from 'react'
import { Outlet } from 'react-router-dom'
import NavPatrimoine from './NavPatrimoine'

const PatrimoinePatrimoine = () => {

  return (
    <div className='section__content'>
      <NavPatrimoine />
      <Outlet />
    </div>
  )
}

export default PatrimoinePatrimoine
