import React from 'react'
import Title from '../../layout/Title'

const Invest = () => {
  return (
    <section className="section">
      <Title title='Investir'/>
    </section>
  )
}

export default Invest
