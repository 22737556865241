import React from 'react'
import { COLORS } from '../../../../../constants'
import PieChartComponent from '../../../Charts/PieChartComponent'
import LegendPieChart from '../../../Charts/LegendPieChart'

const PassifsEmpruntsPieChart = ({ emprunts, detenteurs, print }) => {
  const { empruntDivers, nomDetenteurs, valeurDetenteurs, lemprunts } = emprunts || {}
  // colors
  const colors = [
    { fill: COLORS.colorRepartition01, color: 'repartition01' },
    { fill: COLORS.colorRepartition02, color: 'repartition02' },
  ]
  // emprunts
  const arrayEmprunts = lemprunts
    .filter(
      (passif) => new Date(passif.dateFin) >= new Date() || passif.dateFin === '' || !passif.dateFin
    )
    .map((passif) => ({ nom: passif.refEmprunt, valeur: passif.montantRestant }))
  arrayEmprunts.push({
    nom: 'Non affecté',
    valeur: empruntDivers.montant,
  })
  const dataEmprunts = arrayEmprunts.map((det, index) =>
    det.nom !== 'Non affecté'
      ? {
          name: det.nom,
          value: det.valeur,
          fill: colors[index % colors.length].fill,
          color: colors[index % colors.length].color,
        }
      : {
          name: det.nom,
          value: det.valeur,
          fill: COLORS.colorFontLight,
          color: 'divers',
        }
  )
  // detenteurs
  const arrayDetenteurs = []
  for (let i = 0; i < nomDetenteurs.length; i++) {
    arrayDetenteurs.push({ nom: nomDetenteurs[i], valeur: valeurDetenteurs[i] })
  }
  arrayDetenteurs.push({ nom: 'Non affecté', valeur: empruntDivers.montant })
  const dataDetenteurs = arrayDetenteurs.map((det) => ({ name: det.nom, value: det.valeur }))
  const data = !detenteurs ? dataEmprunts : dataDetenteurs
  // title
  const title = !detenteurs ? 'Répartition' : 'Détenteurs'
  return (
    <>
      <PieChartComponent data={data} title={title} detenteurs={detenteurs} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title} detenteurs={detenteurs} />
    </>
  )
}

export default PassifsEmpruntsPieChart
