import React, { useState } from 'react'
import ModalShowDoc from '../Files/ModalShowDoc'
import ModalDelete from './ModalDelete'
import ModalUpdate from './ModalUpdate'
import THead from './THead'
import classNames from 'classnames'

const Table = ({
  doc,
  titles,
  sort,
  setSort,
  targetToSort,
  setTargetToSort,
  data,
  setData,
  setDataChange,
  noLineNumber,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  children,
  tableType,
  read,
}) => {
  const [newData, setNewData] = useState()
  const [modalUpdate, setModalUpdate] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [showDoc, setShowDoc] = useState(false)
  const commonProps = {
    data,
    setData,
    setNewData,
    setModalUpdate,
    setModalDelete,
    setShowDoc,
    tableType,
    sort,
    targetToSort,
    listToDisplay,
    dataToDisplay,
    setDataTodisplay,
    setDataChange,
    noLineNumber,
    read,
    titles,
  }

  return (
    <div className={doc ? 'table table--doc' : 'table'}>
      <div
        className={classNames('scrollwrapper', {
          'scrollwrapper--noNumber': noLineNumber,
        })}
      >
        <table className="table__content">
          <THead
            doc={doc}
            titles={titles}
            sort={sort}
            setSort={setSort}
            targetToSort={targetToSort}
            setTargetToSort={setTargetToSort}
          />
          {React.cloneElement(children, { ...commonProps })}
        </table>
        {modalUpdate ? (
          <ModalUpdate
            setModalUpdate={setModalUpdate}
            data={data}
            setData={setData}
            newData={newData}
            setDataChange={setDataChange}
            tableType={tableType}
          />
        ) : null}
        {modalDelete ? (
          <ModalDelete
            setModalDelete={setModalDelete}
            newData={newData}
            setDataChange={setDataChange}
            tableType={tableType}
          />
        ) : null}
        {showDoc && <ModalShowDoc setShowDoc={setShowDoc} />}
      </div>
    </div>
  )
}

export default Table
