import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers, updateUser } from '../../../services/API/connexion'
import { stringToCapitalize } from '../../../services/helpers/strings'
import InputsUser from './InputsUser'
import Modale from '../Components/Modale'
import Ok from '../Components/Modales/Ok'
import NotOk from '../Components/Modales/NotOk'
import NotCorrect from '../Components/Modales/NotCorrect'

const ModaleUpdateUser = ({ setModal, newData }) => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState()
  // firms
  const selectCabinets = (state) => state.getFirms.firms
  const firms = useSelector(selectCabinets)
  // modales
  const [modaleUpdateUserOk, setModaleUpdateUserOk] = useState(false)
  const [modalUpdateUserNotOk, setModalUpdateUserNotOk] = useState(false)
  const [modalUpdateUserNotCorrect, setModalUpdateUserNotCorrect] = useState(false)

  // fields
  const [fields, setFields] = useState({
    nom: '',
    prenom: '',
    email: '',
    dateNaissance: '',
    password: 'DEFAULT',
  })
  const { id, nom, prenom, email, mailUsuel, dateNaissance, password, roles, actif, cabinet } =
    fields || {}

  // modif sur un user si existant
  useEffect(() => {
    newData
      ? setFields(newData)
      : setFields({
          ...fields,
          nom: nom && nom,
          prenom: prenom && prenom,
          email: email && email,
          mailUsuel: mailUsuel && mailUsuel,
          dateNaissance: dateNaissance && dateNaissance,
          password: 'DEFAULT',
          actif: actif,
          role: roles && roles,
          cabinet: cabinet && cabinet,
        })
  }, [])

  // retrieve values
  const handleChange = (e, selectName) => {
    if (e) {
      if (e.target !== undefined) {
        const { name, value } = e.target
        switch (name) {
          case 'nom':
            setFields({ ...fields, [name]: value })
            break
          case 'prenom':
            setFields({ ...fields, [name]: value })
            break
          default:
            setFields({ ...fields, [name]: value })
        }
      } else {
        const name = selectName.name
        const value = e.value
        switch (name) {
          case 'roles':
            setFields({ ...fields, [name]: [value] })
            break
          case 'cabinet':
            setFields({ ...fields, [name]: firms.find((c) => c.id === value) })
            break
          default:
            setFields({ ...fields, [name]: value })
        }
      }
    } else {
      const name = selectName.name
      setFields({ ...fields, [name]: undefined })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setFields({ ...fields, [name]: date })
  }
  // submit
  const handleSubmit = (e) => {
    // Object to send
    const data = {
      id: id && id,
      nom: nom && nom.toUpperCase().trim(),
      prenom: prenom && stringToCapitalize(prenom).trim(),
      pseudo: email && email.trim(),
      email: email && email.trim(),
      mailUsuel: mailUsuel,
      dateNaissance: dateNaissance && dateNaissance.trim(),
      password: password && password.trim(),
      roles: roles && roles,
      actif: actif,
      cabinet: cabinet && cabinet,
    }
    e.preventDefault()
    if (nom !== '' && prenom !== '' && email !== '' && dateNaissance !== '') {
      dispatch(updateUser(data, id, setStatus))
    } else {
      setModalUpdateUserNotCorrect(true)
    }
  }
  // response status
  useEffect(() => {
    if (status) {
      if (status < 400) {
        setModaleUpdateUserOk(true)
        dispatch(getUsers())
      } else {
        setModalUpdateUserNotOk(true)
      }
      setStatus(null)
    }
  }, [status])
  // handleClose
  const handleClose = () => {
    setModaleUpdateUserOk(false)
    setModaleUpdateUserOk(false)
  }

  return (
    <Modale
      close={() => setModal(false)}
      title={`Modifier ${stringToCapitalize(newData.prenom)} ${newData.nom.toUpperCase()}`}
    >
      <div className="informations">
        <InputsUser
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
          fields={fields}
          update={true}
        />
        {modaleUpdateUserOk && (
          <Ok text="Modification enregistrées avec succès" close={handleClose} />
        )}
        {modalUpdateUserNotOk && (
          <NotOk setNotOK={setModalUpdateUserNotOk} closeModale={handleClose} />
        )}
        {modalUpdateUserNotCorrect && (
          <NotCorrect setNotCorrect={setModalUpdateUserNotCorrect} />
        )}
      </div>
    </Modale>
  )
}

export default ModaleUpdateUser
