import React, { useEffect } from 'react'
import { sortByType } from '../../../../services/helpers/sort'
import BodyRelation from './BodyRelation'
import TableBodyBloc from '../../table/TableBodyBloc'
import BodyRelationPrint from './BodyRelationPrint'
import { useDispatch, useSelector } from 'react-redux'
import { getPerson } from '../../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../../redux/actions/actionPostPersonType'

const TableBodyRelation = ({
  data,
  setData,
  setModalUpdate,
  setNewData,
  read,
  targetToSort,
  sort,
  setDataChange,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  const dispatch = useDispatch()
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  const listPersons = persons.referentPP.concat(persons.relationPP)
  // ouvrir infos relation
  const handleShow = (element) => {
    const relationShip =
      persons.referentPP.find((el) => el.id === element?.idPP2) ||
      persons.relationPP.find((el) => el.id === element?.idPP2)
    const isPPReferent = persons.referentPP.some((el) => el.id === element?.idPP2)
    dispatch(getPerson(relationShip))
    dispatch(postPersonType(isPPReferent ? 'referentPP' : 'relationPP'))
  }
  //suppr
  const handleDelete = (element) => {
    setDataChange(true)
    setData(data.filter((el) => el !== element))
  }
  // edit
  const handleEdit = (element) => {
    setModalUpdate(true)
    setNewData(element)
  }
  const getGoodPerson = (element) => {
    const personne = listPersons.filter((person) => person.id === element.idPP2)[0]
    if (personne !== undefined) {
      const { nom, prenom } = personne || {}
      return `${nom} ${prenom}`
    } else {
      return '-'
    }
  }

  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Nom':
          giveAType((element) => getGoodPerson(element))
          break
        case 'Relation':
          giveAType('typeRelation')
          break
        case 'A charge':
          giveAType('acharge')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])
  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      read={read}
      print={print}
    >
      {print ? <BodyRelationPrint /> : <BodyRelation handleShow={read ? handleShow : () => {}} />}
    </TableBodyBloc>
  )
}

export default TableBodyRelation
