import React from 'react'
import Card from '../../../../../Components/Card'
import BlocTable from '../../../../../table/BlocTable'
import DetentionCard from '../../../../Detention/DetentionCard'
import GeneralInformationCard from './GeneralInformationCard'
import FormSubmit from '../../../../../Components/FormSubmit'
import TermsCard from './TermsCard'
import ContractValueCard from './ContractValueCard'
import { DOC_SOCIAL_PREVOYANCE, TITLES_DOC_UPDATE } from '../../../../../../../constants'
import TableBodyDoc from '../../../../../Files/TableBodyDoc'

const FormPrevoyanceContent = ({
  bienToDisplay,
  dataChange,
  setDataChange,
  handleChange,
  handleChangeDate,
  information,
  setInformation,
  detenteursToDisplay,
  setDetenteursToDisplay,
  fichiersToDisplay,
  setFichiersToDisplay,
  setToggleAddPatrimoine,
  handleSubmit,
  newValeurTotale,
  handleGiveUp,
  toggleSubCategory,
}) => {
  return (
    <>
      {/* Envoi du formulaire */}
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handleSubmit}
        handleGiveUp={handleGiveUp}
        down={false}
      />
      <div className="sousSection">
        {/* form */}
        <div className="cards cards--fullWidth">
          <GeneralInformationCard
            handleChange={handleChange}
            information={information}
            toggleSubCategory={toggleSubCategory}
          />
        </div>
        <div className="cards">
          <TermsCard
            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            information={information}
          />
          <ContractValueCard handleChange={handleChange} information={information} />
        </div>
        <div className={bienToDisplay ? 'cards' : 'cards cards--fullWidth'}>
          <DetentionCard
            information={information}
            setInformation={setInformation}
            detenteursToDisplay={detenteursToDisplay}
            setDetenteursToDisplay={setDetenteursToDisplay}
            newValeurTotale={newValeurTotale}
            setDataChange={setDataChange}
            showUS={true}
          />
          {bienToDisplay && (
            <Card title={`Documents (${fichiersToDisplay.length})`} h3={true} open={true}>
              <div className="informations">
                <BlocTable
                  titles={TITLES_DOC_UPDATE}
                  data={fichiersToDisplay}
                  setData={setFichiersToDisplay}
                  tableType={DOC_SOCIAL_PREVOYANCE}
                  children={<TableBodyDoc />}
                  setDataChange={setDataChange}
                  doc={true}
                  title={`Documents (${fichiersToDisplay.length})`}
                  handleAdd={() => setToggleAddPatrimoine(true)}
                />
              </div>
            </Card>
          )}
        </div>
      </div>
      {/* Envoi du formulaire */}
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handleSubmit}
        handleGiveUp={handleGiveUp}
        down={true}
      />
    </>
  )
}

export default FormPrevoyanceContent
