import React from 'react'
import usePersonData from '../../layout/usePersonData'

const ConditionnalUpdateButton = ({ redirection }) => {
  const { personType } = usePersonData()

  return personType !== 'relationPP' ? (
      <div className="informations__buttons">
        <button type='button' className="button button--update width--7" onClick={redirection}>
          Modifier
        </button>
      </div>
  ) : null
}

export default ConditionnalUpdateButton
