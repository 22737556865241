// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get messages
 * @reduxAction
 */

export const getMessagesLoad = createAction('get-messages-load')

export const getMessagesSuccess = createAction(
  'get-messages-success',
  (messages) => {
    return {
      payload: messages,
    }
  }
)

export const getMessagesError = createAction(
  'get-messages-error', (error) => {
  return {
    payload: error,
  }
})