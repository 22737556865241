// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get id transaction
 * @reduxAction
 */

export const getIdTransaction = createAction(
  'get-id-transaction',
  (id) => {
    return {
      payload: id,
    }
  }
)