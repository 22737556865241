import React from 'react'
import Copy from '../../../../Components/Copy'
import { beautifulDate, dateFormateComplete } from '../../../../../../services/helpers/dates'

const ToastLastCours = ({ isin, response }) => {
  return (
    <div className="toast__bloc">
      <div className="toast__divCopy">
        <p className="toast__text">
          Isin : <span className='toast__text--title'>{isin}</span>
        </p>
        <Copy value={isin} little={true} />
      </div>
      <div className="toast__divCopy">
        <p className="toast__text ">
          Cours :{' '}
          <span className='toast__text--normal' >
            {response.data.valeur
              ? `${response.data.valeur.toLocaleString(undefined, {
                  minimumFractionDigits: 4,
                })} €`
              : '-'}
          </span>
        </p>
        <Copy value={response.data.valeur} little={true} />
      </div>
      <div className="toast__divCopy">
        <p className="toast__text ">
          Date : <span className='toast__text--normal'>{response.data.dateVL ? beautifulDate(response.data.dateVL) : '-'}</span>
        </p>
        <Copy value={dateFormateComplete(response.data.dateVL)} little={true} />
      </div>
    </div>
  )
}

export default ToastLastCours
