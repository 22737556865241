// IMPORTS // ______________________________________________________________
import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions logout
 * @reduxAction
 */

export const connectionFailed  = createAction('connection-failed');
export const connectionRestored = createAction('connection-restored');