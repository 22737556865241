import React from 'react'
import RevenusProPieChart from '../RevenusProPieChart'
import { REVENUS_PROS } from '../../../../../../constants'
import TableBodyRevenu from '../../Revenus/Table/TableBodyRevenu'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import TableToPrint from '../../../../table/TableToPrint'

const RevenusProsToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurRevenusProfessionnels } = elementToDisplay || {}

  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurRevenusProfessionnels}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<RevenusProPieChart revenusProfessionnels={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={REVENUS_PROS}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyRevenu />}
      />
      <TableToPrint
        data={dataSold}
        tableType={REVENUS_PROS}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyRevenu />}
      />
    </>
  )
}

export default RevenusProsToPrint
