import React from 'react'
import { dateFormateComplete } from '../../../../../../services/helpers/dates'

const BodyEmpruntPrint = ({ element, index }) => {
  return (
    <>
      {/* refEmprunt */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Réference</p>
        <p className="print__table__bloc__line--value">
          {!element.refEmprunt ? '-' : element.refEmprunt}
        </p>
      </div>
      {/* montantEmprunte */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Montant</p>
        <p className="print__table__bloc__line--value">
          {!element.montantEmprunte
            ? '-'
            : `${element.montantEmprunte.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
      {/* montantRestant */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Restant dû</p>
        <p className="print__table__bloc__line--value">
          {!element.montantRestant && element.montantRestant !== 0
            ? '-'
            : `${element.montantRestant.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
      {/* mensualite */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Mensualités</p>
        <p className="print__table__bloc__line--value">
          {!element.mensualite
            ? '-'
            : `${element.mensualite.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })} €`}
        </p>
      </div>
      {/* dureeMois */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Durée</p>
        <p className="print__table__bloc__line--value">
          {!element.dureeMois ? '-' : `${element.dureeMois} mois`}
        </p>
      </div>
      {/* dateDebut */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Début</p>
        <p className="print__table__bloc__line--value">
          {!element.dateDebut ? '-' : dateFormateComplete(element.dateDebut)}
        </p>
      </div>
      {/* dateFin */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Fin</p>
        <p className="print__table__bloc__line--value">
          {element.dateFin === ''
            ? '-'
            : element.dateFin
            ? dateFormateComplete(element.dateFin)
            : 'en cours'}
        </p>
      </div>
    </>
  )
}

export default BodyEmpruntPrint
