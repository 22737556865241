import React from 'react'
import InputsBirth from './InputsBirth'
import { optionsCapacity } from '../../../data/options/optionsPP'

const Birth = ({
  person,
  birth,
  setBirth,
  fichiers,
  setDataChange,
  document,
}) => {
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      switch (name) {
        case 'nomNaissance':
          setBirth({ ...birth, [name]: value })
          break
        default:
          setBirth({ ...birth, [name]: value })
      }
    } else {
      const name = selectName.name
      const value = e.value
      setBirth({ ...birth, [name]: value })
    }
  }
  return (
    <InputsBirth
      person={person}
      handleChange={handleChange}
      birth={birth}
      setBirth={setBirth}
      optionsCapacity={optionsCapacity}
      fichiers={fichiers}
      setDataChange={setDataChange}
      document={document}
    />
  )
}

export default Birth
