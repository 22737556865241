import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { sortByType } from '../../../services/helpers/sort'
import { USER_REFERENTS } from '../../../constants'
import TableBodyBloc from '../table/TableBodyBloc'
import BodyPP from './BodyPP'
import BodyPPPrint from './BodyPPPrint'
import { capitalizeFirstLetter } from '../../../services/helpers/strings'

const TableBodyPP = ({
  setModalDelete,
  setNewData,
  targetToSort,
  sort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  tableType,
  print,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // user
  const user = useSelector((state) => state.getUser.user)
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // handleDispatchNewPersonInfos
  const handleDispatchNewPersonInfos = (personne) => {
    dispatch(getPerson(personne))
    // dispatch(getPersonId(personne.id))
    dispatch(postPersonType(tableType === USER_REFERENTS ? 'referentPP' : 'relationPP'))
  }
  // ouvrir infos personne
  const handleShow = (personne) => {
    handleDispatchNewPersonInfos(personne)
    navigate('/user/informations')
  }
  // ouvrir form personne
  const handleEdit = (personne) => {
    handleDispatchNewPersonInfos(personne)
    navigate('/user/informations/modifier-Personne-Physique')
  }
  // suppr
  const handleDelete = (element) => {
    setNewData(element)
    setModalDelete(true)
  }
  // relation
  const getRelation = (personne) => {
    const titulaire = persons.referentPP.filter((el) => el.mail === user.email)[0]
    const relationsTitulaire = titulaire.relations
    if (relationsTitulaire.find((el) => el.idPP2 === personne.id)) {
      return capitalizeFirstLetter(
        relationsTitulaire.filter((el) => el.idPP2 === personne.id)[0].typeRelation
      )
    } else if (personne === titulaire) {
      return 'Titulaire'
    } else {
      return null
    }
  }
  // tri croissant/decroissant
  const giveAType = (typeOrFunc) => {
    sortByType(sort, dataToDisplay, typeOrFunc, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Nom':
          giveAType('nom')
          break
        case 'Prénom':
          giveAType('prenom')
          break
        case 'Mail':
          giveAType('mail')
          break
        case 'Lien':
          giveAType((personne) => getRelation(personne))
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyPPPrint /> : <BodyPP handleShow={handleShow} />}
      handleShow={handleShow}
      handleEdit={tableType === USER_REFERENTS ? handleEdit : null}
      handleDelete={handleDelete}
      print={print}
    />
  )
}

export default TableBodyPP
