import axios from 'axios'
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants'
import { getUserLoad, getUserSuccess, getUserError } from '../../redux/actions/actionGetUser'
import { getUsersError, getUsersLoad, getUsersSuccess } from '../../redux/actions/actionGetUsers'
import {
  getMessagesError,
  getMessagesLoad,
  getMessagesSuccess,
} from '../../redux/actions/actionGetMessages'
import {
  getContactsError,
  getContactsLoad,
  getContactsSuccess,
} from '../../redux/actions/actionGetContacts'
import {
  getUserMailError,
  getUserMailLoad,
  getUserMailSuccess,
} from '../../redux/actions/actionGetUserMail'
import { getMandatLoad, getMandatSuccess } from '../../redux/actions/actionGetMandat'
import { getPersonnes } from './personnes'
import { createRequestOptions } from './requestConfig'
import { toast } from 'react-toastify'

// API CALLS // __________________________________________________________________

// connexion
export const getToken = (identifiant, password, setStatus) => {
  const toastLoading = toast.loading('Connexion au serveur en cours ...')
  const body = {
    pseudoOrEmail: identifiant,
    password: password,
  }
  const requestOptions = createRequestOptions('POST', body)
  const url = `${API_BASE_URL}/auth/connecter`
  return (dispatch) => {
    fetch(url, requestOptions)
      .then((response) => {
        toast.dismiss(toastLoading)
        if (!response.ok) {
          // Vérifie si le statut de la réponse n'est pas une réussite
          setStatus(response.status) // Ici, vous pouvez directement traiter les codes d'erreur (401, 404, etc.)
          return Promise.reject({ httpError: true, status: response.status })
        }
        setStatus(response.status) // Ici, vous pouvez directement traiter les codes d'erreur (401, 404, etc.)
        return response.json() // Parse la réponse en JSON
      })
      .then((data) => {
        sessionStorage.setItem(ACCESS_TOKEN, data.accessToken)
        sessionStorage.setItem('identifiant', identifiant)
        dispatch(getUser(identifiant))
      })
      .catch((error) => {
        toast.dismiss(toastLoading)
        if (!error.httpError || !navigator.onLine) {
          // Gère spécifiquement les erreurs de connexion
          setStatus(500)
          toast.error('Echec de la connexion')
        }
      })
  }
}
// forgot password
export const passwordNew = (mail, setStatus) => {
  const url = `${API_BASE_URL}/auth/users/passwordNew?mail=${mail}`
  const requestOptions = createRequestOptions('POST')
  return () => {
    fetch(url, requestOptions)
      .then((response) => {
        setStatus(response.status)
        return response.json()
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }
}
// forgot newPassword
export const changePasword = (data, setStatus) => {
  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/auth/changerpassword`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}

export const getUser = (pseudo) => {
  const toastLoading = toast.loading('Récupération du compte ...')
  const requestOptions = createRequestOptions('GET')
  const url = `${API_BASE_URL}/users/${pseudo}`

  return (dispatch) => {
    dispatch(getUserLoad())
    fetch(url, requestOptions)
      .then((response) => {
        toast.dismiss(toastLoading)
        if (!response.ok) {
          toast.error('Echec de la récupération du compte')
          throw new Error('Network response was not ok')
        }
        return response.json() // Parse la réponse en JSON
      })
      .then((data) => {
        dispatch(getUserSuccess(data))
        dispatch(getPersonnes())
      })
      .catch((error) => {
        toast.dismiss(toastLoading)
        toast.error('Echec de la récupération du compte')
        dispatch(getUserError(error.message))
      })
  }
}

// update user
export const updateUser = (data, id, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/users/update/${id}`, // id ou user ?
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// inscription
export const postUser = (data, setStatus) => {
  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/auth/enregistrer`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}

// validcompte
export const valideCompte = (id, setStatus) => {
  return () => {
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/auth/validcompte/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}

// récupération liste users
export const getUsers = () => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getUsersLoad())
    axios
      .get(API_BASE_URL + `/users/users`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        dispatch(getUsersSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getUsersError(error.message))
      })
  }
}

// récupération user mandataire
export const getMandataire = (mail, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getUserMailLoad())
    axios
      .get(API_BASE_URL + `/users/users/${mail}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        dispatch(getUserMailSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        dispatch(getUserMailError(error.message))
        setStatus(error.response.status)
      })
  }
}
// post mandat
export const postMandat = (data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getMandatLoad())
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/users/mandat`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(getMandatSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        dispatch(getMandatSuccess(error.response.data))
        // dispatch(getMandatError(error.message))
        setStatus(error.response.status)
      })
  }
}

// récupération liste contacts
export const getContacts = () => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getContactsLoad())
    axios
      .get(API_BASE_URL + `/users/contacts`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        dispatch(getContactsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getContactsError(error.message))
      })
  }
}
// post contact
export const postContact = (data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/users/contacts/${data.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// delete contact
export const deleteContact = (data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'delete',
      url: `${API_BASE_URL}/users/contacts/${data.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}

// recupération liste Messages
export const getMessages = () => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getMessagesLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/users/listeMessageByUser`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getMessagesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getMessagesError(error.message))
      })
  }
}

// Supprime Message
export const deleteMessage = (id, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/users/vuemessage/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}

// pappers
export const getPappers = (siren, setPappers, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios
      .get(API_BASE_URL + `/users/getPersonneMoralePappers/${siren}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setPappers(response.data)
        setStatus(response.status)
      })
      .catch((error) => {
        setPappers(null)
        setStatus(error.response.status)
      })
  }
}

// CSP
export const getCSP = (setCSP) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios
      .get(API_BASE_URL + '/users/listescp', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setCSP(response.data)
      })
      .catch((error) => {
        setCSP(error.message)
      })
  }
}

// NAF
export const fetchNAF = async (setNAF) => {
  await fetch('/user/naf.json', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(function (response) {
      return response.json()
    })
    .then(function (myJson) {
      setNAF(myJson)
    })
}

export const calculType = (type) => {
  if (type && (type === 'referentPP' || type === 'relationPP')) {
    return 'PP'
  }
  if (type && type === 'dirigeantPM') {
    return 'PM'
  }
}
