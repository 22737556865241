import { useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import useName from './useName'
import { normalizeAndLowerCase } from '../../services/helpers/strings'
import useCustomPathName from './useCustomPathName'

const useCustomPrint = () => {
  const titlePrint = useCustomPathName()
  const componentRef = useRef(null)
  const [loadingPrint, setIsLoadingPrint] = useState(false)
  const name = useName(true)
  // handlePrint
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setIsLoadingPrint(true),
    onAfterPrint: () => setIsLoadingPrint(false),
    documentTitle: `mep-${name}-${titlePrint ? normalizeAndLowerCase(titlePrint) : ''}`,
    removeAfterPrint: true,
  })

  return { componentRef, handlePrint, loadingPrint, titlePrint }
}

export default useCustomPrint
