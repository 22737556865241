import React from 'react'

const LittleLoader = () => {
  return (
    <div className="littleLoader">
      <div className="loader"></div>
    </div>
  )
}

export default LittleLoader
