import React from 'react'
import { dataCatalogue } from '../../../../../../data/informations/financier'
import InformationsCardPrint from '../../../../Informations/InformationsCardPrint'
import TableToPrint from '../../../../table/TableToPrint'
import { SUPPORTS_INFOS } from '../../../../../../constants'
import TableBodySupports from '../Table/TableBodySupports'

const CatalogueInfoPrint = ({ elementToDisplay }) => {
  return (
    <>
      <InformationsCardPrint
        data={dataCatalogue(elementToDisplay)}
        title={elementToDisplay.libelle}
      />
      <TableToPrint
        data={elementToDisplay.dicis}
        tableType={SUPPORTS_INFOS}
        title={`Supports (${elementToDisplay.dicis?.length})`}
        children={<TableBodySupports />}
      />
    </>
  )
}

export default CatalogueInfoPrint
