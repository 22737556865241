import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { getCharges } from '../../../../../services/API/charges'
import { CHARGES_DEDUCTIBLES } from '../../../../../constants'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import ChargesCard from '../Charges/ChargesCard'
import ChgDeductiblesToPrint from './ChgDeductiblesToPrint'
import ChgDeductiblesPieChart from './ChgDeductiblesPieChart'
import ModalUpdateTotalValueChgDeductibles from './ModalUpdateTotalValueChgDeductibles'
import LittleLoader from '../../../../layout/LittleLoader'

const ChgDeductiblesHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const { charges } = useSelector((state) => state.getCharges)
  const { chargeDeductibleRIs } = charges || {}
  const { chargesDeductibleRIs, valeurChargesDeductibleRIs, chargeDeductibleRIDivers } =
    chargeDeductibleRIs || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: chargeDeductibleRIDivers?.montant },
      { label: 'Affecté', value: valeurChargesDeductibleRIs - chargeDeductibleRIDivers?.montant },
    ],
    [chargeDeductibleRIDivers, valeurChargesDeductibleRIs]
  )
  // recharge
  useEffect(() => {
    if (!charges) {
      dispatch(getCharges(person?.id, isPP))
    }
  }, [charges, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () =>
      chargesDeductibleRIs.filter(
        (revenu) => revenu.dateFin && new Date(revenu.dateFin) < new Date()
      ),
    [chargesDeductibleRIs]
  )
  const dataToDisplayNotSold = useMemo(
    () =>
      chargesDeductibleRIs.filter(
        (revenu) => new Date(revenu.dateFin) >= new Date() || !revenu.dateFin
      ),
    [chargesDeductibleRIs]
  )
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // redirections
  const formLink = '/user/patrimoine/charges/deductibles-RI/ajouter'
  // recuperation actifs
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!charges) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <ChgDeductiblesToPrint
              elementToDisplay={chargeDeductibleRIs}
              values={values}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurChargesDeductibleRIs}
              update={true}
              values={values}
            />
            {valeurChargesDeductibleRIs > 0 && (
              <PatrimoineChartCard
                children={<ChgDeductiblesPieChart chargeDeductibleRIs={chargeDeductibleRIs} />}
                detenteurs={true}
              />
            )}
          </div>
          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <ChargesCard
              data={dataToDisplayNotSold}
              handleAdd={() => navigate(formLink)}
              title={`En cours (${dataToDisplayNotSold.length})`}
              tableType={CHARGES_DEDUCTIBLES}
            />
            {dataToDisplaySold.length > 0 && (
              <ChargesCard
                data={dataToDisplaySold}
                handleAdd={() => navigate(formLink)}
                title={`Historique (${dataToDisplaySold.length})`}
                tableType={CHARGES_DEDUCTIBLES}
              />
            )}
          </div>
          {toggleUpdateTotal && (
            <ModalUpdateTotalValueChgDeductibles
              setToggleUpdateTotal={setToggleUpdateTotal}
              person={person}
              charges={charges}
              pp={isPP}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ChgDeductiblesHome
