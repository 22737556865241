// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get person
 * @reduxAction
 */

export const getPerson = createAction(
  'get-person-success',
  (person) => {
    return {
      payload: person,
    }
  }
)