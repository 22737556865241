import React from 'react'
import Copy from './Copy'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { getBienSuccess } from '../../../redux/actions/Patrimoine/actionGetBien'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { getbienType } from '../../../redux/actions/actionGetBienType'

const InformationLine = ({ data, selectBien, print, selectPM }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { properties } = useSelector((state) => state.getProperties)

  const navigateToDetail = (path) => navigate(path)

  const handleSelectBien = (element) => {
    const { bien, type } = element || {}
    dispatch(getBienSuccess(bien))
    const bienImmo = properties
      ? properties.actifsImmobilier.biensImmobilier.find(
          (item) => item.id === bien.id && item.nom === bien.nom
        )
      : null
    const bienPro = properties
      ? properties.actifsBienProfessionnel.biensProfessionnels.find(
          (item) => item.id === bien.id && item.nom === bien.nom
        )
      : null
    const bienAutre = properties
      ? properties.actifsAutres.autresActifs.find(
          (item) => item.id === bien.id && item.nom === bien.nom
        )
      : null
    const AV = properties
      ? properties.financiers.produitsAV.find(
          (item) => item.id === bien.id && item.libelle === bien.libelle
        )
      : null
    const VM = properties
      ? properties.financiers.produitsVM.find(
          (item) => item.id === bien.id && item.libelle === bien.libelle
        )
      : null
    // const PF = properties
    //   ? properties.financiers.comptesBancaires.find(
    //       (item) => item.id === bien.id && item.libelle === bien.libelle
    //     )
    //   : null

    dispatch(
      getbienType({
        categorie: type === 'financier' ? 'FINANCIER' : 'ACTIFS',
        type: bienImmo
          ? 'bien'
          : bienPro
          ? 'bienPro'
          : bienAutre
          ? 'autreActif'
          : AV
          ? 'produitFinancierAV'
          : VM
          ? 'produitFinancierVM'
          : 'comptebancaire',
      })
    )
    if (type !== 'financier') {
      navigate(`/user/patrimoine/actifs/${type}/bien`)
    } else {
      AV
        ? navigate(`/user/patrimoine/actifs/${type}/assurances-vie/produit/produit`)
        : VM
        ? navigate(`/user/patrimoine/actifs/${type}/valeurs-mobilieres/produit/produit`)
        : navigate(`/user/patrimoine/actifs/${type}/produits-financiers/compte`)
    }
  }
  const handleSelectPM = (pm) => {
    dispatch(getPerson(pm))
    dispatch(postPersonType('dirigeantPM'))
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
    navigateToDetail(`/user/informations`)
  }
  const renderValue = () => (
    <div className="informations__line__data">
      {selectBien ? (
        <div onClick={() => handleSelectBien(data.bien)}>
          <p>{data.value}</p>
          <BsFillInfoCircleFill />
          {!print && <Copy value={data.value} little={true} />}
        </div>
      ) : selectPM && data.personne ? (
        <div onClick={() => handleSelectPM(data.personne)}>
          <p>{data.value}</p>
          <BsFillInfoCircleFill />
          {!print && <Copy value={data.value} little={true} />}
        </div>
      ) : (
        <p>{data.value || `${data.value} non retrouvé`}</p>
      )}
    </div>
  )
  return (
    <div className="informations__line">
      <p className="informations__line__label">{data.label}</p>
      {data.value ? renderValue() : <p className="informations__line__undefined">non renseigné</p>}
    </div>
  )
}

export default InformationLine
