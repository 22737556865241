import React from 'react'
import { REVENUS_MOBILIERS } from '../../../../../../constants'
import TableToPrint from '../../../../table/TableToPrint'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import TableBodyRevenu from '../../Revenus/Table/TableBodyRevenu'
import RevenusMobPieChart from '../RevenusMobPieChart'

const RevenusMobiliersToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurRevenuMobilier } = elementToDisplay || {}

  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurRevenuMobilier}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<RevenusMobPieChart revenusMobiliers={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={REVENUS_MOBILIERS}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyRevenu />}
      />
      <TableToPrint
        data={dataSold}
        tableType={REVENUS_MOBILIERS}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyRevenu />}
      />
    </>
  )
}

export default RevenusMobiliersToPrint
