import React from 'react'
import { CHARGES_DEDUCTIBLES } from '../../../../../constants'
import TableToPrint from '../../../table/TableToPrint'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../Patrimoine/PatrimoineChartCardPrint'
import TableBodyCharge from '../Charges/Table/TableBodyCharge'
import ChgDeductiblesPieChart from './ChgDeductiblesPieChart'

const ChgDeductiblesToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurChargesDeductibleRIs } = elementToDisplay || {}

  return (
    <>
      <PatrimoineValueCard totalAmount={valeurChargesDeductibleRIs} values={values} />
      <PatrimoineChartCardPrint
        children={<ChgDeductiblesPieChart chargeDeductibleRIs={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={CHARGES_DEDUCTIBLES}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyCharge />}
      />
      <TableToPrint
        data={dataSold}
        tableType={CHARGES_DEDUCTIBLES}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyCharge />}
      />
    </>
  )
}

export default ChgDeductiblesToPrint
