import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  calculateNP,
  calculateUS,
  calculValeurDetenue,
} from '../../../../services/helpers/detenteurs'
import AddDetenteur from './AddDetenteur'
import DetentionCardContent from './DetentionCardContent'

const DetentionCard = ({
  information,
  setInformation,
  detenteursToDisplay,
  setDetenteursToDisplay,
  newValeurTotale,
  setDataChange,
  showUS,
  h5,
}) => {
  // person
  const person = useSelector((state) => state.getPerson.person)
  const [addDetenteur, setAddDetenteur] = useState(false)
  const [detenteurToEdit, setDetenteurToEdit] = useState(null)
  const [total, setTotal] = useState({
    totalTauxPP: 0,
    totalTauxUS: 0,
    totalTauxNP: 0,
    totalPP: 0,
    totalUS: 0,
    totalNP: 0,
    totalDetenu: 0,
  })
  const { nbPartsDetenus, nbPartsTotales, detenteurs } = information || {}
  // maj %detenteur main
  useEffect(() => {
    if (newValeurTotale !== 0 && nbPartsTotales && nbPartsDetenus) {
      const detValue = Math.round(((nbPartsDetenus * 100) / nbPartsTotales) * 100) / 100
      const detMain = detenteurs.filter((el) => el.mailDetenteur === person.mail)[0]
      setInformation({
        ...information,
        detenteurs: [
          ...detenteurs.filter((el) => el.mailDetenteur !== person.mail),
          { ...detMain, tauxPleinePropriete: detValue },
        ],
      })
    }
  }, [nbPartsTotales, nbPartsDetenus, newValeurTotale])

  // handleChangeDetenteur
  const handleChangeDetenteur = (e, det) => {
    setDataChange(true)
    e.preventDefault()
    const { name, value } = e.target
    const goodValue = value.replace(',', '.')
    const goodName = name.split('-')[0]
    setDetenteursToDisplay([
      ...detenteursToDisplay.filter((el) => el.mailDetenteur !== det.mailDetenteur),
      {
        ...det,
        [goodName]: goodValue,
        valeurPP: (newValeurTotale * parseFloat(det.tauxPleinePropriete)) / 100,
        valeurUS: calculateUS(det, newValeurTotale),
        valeurNP: calculateNP(det, newValeurTotale, detenteursToDisplay),
        valeurDetenue: calculValeurDetenue(det, newValeurTotale, detenteursToDisplay),
      },
    ])
  }

  useEffect(() => {
    if (detenteursToDisplay?.length) {
      const arrayTotalTauxPP = detenteursToDisplay.map((el) => parseFloat(el.tauxPleinePropriete))
      const resultTotalTauxPP = arrayTotalTauxPP.reduce((acc, curr) => acc + curr, 0)
      const arrayTotalTauxUS = detenteursToDisplay.map((el) => parseFloat(el.tauxUsufruit))
      const resultTotalTauxUS = arrayTotalTauxUS.reduce((acc, curr) => acc + curr, 0)
      const arrayTotalTauxNP = detenteursToDisplay.map((el) => parseFloat(el.tauxNuePropriete))
      const resultTotalTauxNP = arrayTotalTauxNP.reduce((acc, curr) => acc + curr, 0)
      const arrayTotalPP = detenteursToDisplay.map(
        (el) => (newValeurTotale * parseFloat(el.tauxPleinePropriete)) / 100
      )
      const resultTotalPP = arrayTotalPP.reduce((acc, curr) => acc + curr, 0)
      const arrayTotalUS = detenteursToDisplay.map((el) =>
        el.tauxUsufruit !== '' && el.tauxUsufruit !== 0 && el.tauxUsufruit !== '0'
          ? calculateUS(el, newValeurTotale)
          : 0
      )
      const resultTotalUS = arrayTotalUS.reduce((acc, curr) => acc + curr, 0)
      const arrayTotalNP = detenteursToDisplay.map((el) =>
        el.tauxNuePropriete !== '' && el.tauxNuePropriete !== 0 && el.tauxNuePropriete !== '0'
          ? calculateNP(el, newValeurTotale, detenteursToDisplay)
          : 0
      )
      const resultTotalNP = arrayTotalNP.reduce((acc, curr) => acc + curr, 0)
      const totalDetenuCalcule = resultTotalPP + resultTotalUS + resultTotalNP
      setTotal({
        ...total,
        totalTauxPP: resultTotalTauxPP,
        totalTauxUS: resultTotalTauxUS,
        totalTauxNP: resultTotalTauxNP,
        totalPP: resultTotalPP,
        totalUS: resultTotalUS,
        totalNP: resultTotalNP,
        totalDetenu: totalDetenuCalcule,
      })
    }
  }, [detenteursToDisplay, newValeurTotale])

  //edit
  const handleEdit = (element) => {
    setDataChange(true)
    setDetenteurToEdit(element)
    setAddDetenteur(true)
  }
  //suppr
  const handleSuppr = (element) => {
    setDataChange(true)
    setDetenteursToDisplay(
      detenteursToDisplay.filter((el) => el.mailDetenteur !== element.mailDetenteur)
    )
  }
  // handleAddDetenteur
  const handleAddDetenteur = () => {
    setDataChange(true)
    setAddDetenteur(true)
  }
  return (
    <>
      <DetentionCardContent
        detenteursToDisplay={detenteursToDisplay}
        handleChangeDetenteur={handleChangeDetenteur}
        handleAddDetenteur={handleAddDetenteur}
        handleSuppr={handleSuppr}
        handleEdit={handleEdit}
        newValeurTotale={newValeurTotale}
        total={total}
        showUS={showUS}
        h5={h5}
      />
      {addDetenteur && (
        <AddDetenteur
          setAddDetenteur={setAddDetenteur}
          newValeurTotale={newValeurTotale}
          detenteurToEdit={detenteurToEdit}
          detenteursToDisplay={detenteursToDisplay}
          setDetenteursToDisplay={setDetenteursToDisplay}
          showUS={showUS}
        />
      )}
    </>
  )
}

export default DetentionCard
