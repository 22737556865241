import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getMandatSuccess } from '../../../redux/actions/actionGetMandat'
import { getContacts, postContact } from '../../../services/API/connexion'
import { postPP } from '../../../services/API/personnes'
import Modale from '../Components/Modale'
import ModalCreatePPNotOK from '../FormPP/Modales/ModalCreatePPNotOK'
import ModalParapheur from '../Parapheur/ModalParapheur'
import InputsContact from './InputsContact'
import Ok from '../Components/Modales/Ok'
import NotOk from '../Components/Modales/NotOk'
import NotCorrect from '../Components/Modales/NotCorrect'

const ModaleUpdateContact = ({ setModal, newData }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // user
  const user = useSelector((state) => state.getUser.user)
  // url de parapher
  const url = useSelector((state) => state.getMandat.mandat)
  // status
  const [status, setStatus] = useState()
  const [statusCreatePP, setStatusCreatePP] = useState(null)
  //
  const [createPP, setCreatePP] = useState(false)
  // modales
  const [modaleUpdateContactOk, setModaleUpdateContactOk] = useState(false)
  const [modalUpdateContactNotOk, setModalUpdateContactNotOk] = useState(false)
  const [modalUpdateContactNotCorrect, setModalUpdateContactNotCorrect] = useState(false)
  const [modalUpdateContactAlreadyExist, setModalUpdateContactAlreadyExist] = useState(false)
  const [modalCreatePPOk, setModalCreatePPOk] = useState(false)
  const [modalCreatePPNotOk, setModalCreatePPNotOk] = useState(false)
  // fields
  const [contact, setContact] = useState({
    id: 0,
    nom: '',
    prenom: '',
    email: '',
    telephonePortable: '',
    fonction: '',
  })
  const { id, nom, prenom, email, telephonePortable, dateNaissance, sexe, fonction } = contact || {}
  // modif contact si existant
  useEffect(() => {
    newData
      ? setContact({
          id: newData.id,
          nom: newData.nom,
          prenom: newData.prenom,
          email: newData.email,
          telephonePortable: newData.telephonePortable,
          fonction: newData.fonction,
        })
      : setContact({
          id: 0,
          nom: nom,
          prenom: prenom,
          email: email,
          telephonePortable: telephonePortable,
          fonction: fonction,
        })
  }, [])

  // validation
  const validate = () => {
    if (nom !== '' && prenom !== '' && email !== '' && createPP !== '') {
      if (!createPP) {
        const data = {
          id: id,
          user_id: user,
          nom: nom,
          prenom: prenom,
          email: email,
          telephonePortable: telephonePortable,
          fonction: fonction,
        }
        dispatch(postContact(data, setStatus))
      } else {
        const data = {
          id: 0,
          user_id: { email: user.email },
          nom: nom,
          prenom: prenom,
          mail: email,
          dateNaissance: dateNaissance,
          sexe: sexe,
          telPortable: telephonePortable,
          villeNaissance: '',
          telFixe: '',
          adresseFiscale: {},
          coordonneesSupplementaires: [],
          nomNaissance: '',
          nationnalite1: '',
          nationnalite2: '',
          capaciteLegale: '',
          situationFam: '',
          idConjoint: 0,
          dateSituationFam: '',
          regimeFam: '',
          dateregimeFam: '',
          donationEntreEpoux: '',
          professions: [],
          relations: [],
          fichiers: [],
        }
        if (
          dateNaissance !== '' &&
          dateNaissance !== undefined &&
          sexe !== '' &&
          sexe !== undefined
        ) {
          dispatch(postPP(data, setStatusCreatePP))
        } else {
          setModalUpdateContactNotCorrect(true)
        }
      }
    } else {
      setModalUpdateContactNotCorrect(true)
    }
  }
  // response update contact
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setModaleUpdateContactOk(true)
      setStatus(null)
    }
    if (status === 409) {
      setModalUpdateContactAlreadyExist(true)
      setStatus(null)
    }
    if (status >= 400 && status !== 409) {
      setModalUpdateContactNotOk(true)
      setStatus(null)
    }
  }, [status])
  // response create PP status and modal
  useEffect(() => {
    if (statusCreatePP) {
      if (statusCreatePP <= 301) {
        if (url && url !== '') {
          setModalCreatePPOk(true)
          window.open(url)
          dispatch(getMandatSuccess(null))
        }
      } else {
        setModalCreatePPNotOk(true)
      }
      setStatusCreatePP(null)
    }
  }, [statusCreatePP])

  // close
  const handleClose = () => {
    dispatch(getContacts())
    setModaleUpdateContactOk(false)
    setModalUpdateContactNotOk(false)
    setModalUpdateContactAlreadyExist(false)
    setModal(false)
  }

  // close Modal Parapheur
  const closeModalCreateOk = () => {
    setModalCreatePPOk(false)
    setModal(false)
    navigate('/user/comptes')
  }

  return (
    <>
      <Modale close={() => setModal(false)} title={newData ? 'Modifier' : 'Ajouter'}>
        <div className="informations">
          <InputsContact
            contact={contact}
            setContact={setContact}
            validate={validate}
            setModalUpdate={setModal}
            createPP={createPP}
            setCreatePP={setCreatePP}
          />{' '}
        </div>
      </Modale>

      {modaleUpdateContactOk && (
        <Ok
          text={
            newData ? 'Modifications enregistrées' : 'Contact enregistré'
          }
          close={handleClose}
        />
      )}
      {modalUpdateContactNotOk && (
        <NotOk setNotOK={setModalUpdateContactNotOk} closeModale={handleClose} />
      )}
      {modalUpdateContactAlreadyExist && (
        <Modale
          close={() => handleClose()}
          title="Erreur !"
          line1="Une personne existe déjà avec cette adresse mail"
        >
          <div className="informations__buttons">
            <div
              className="button button--blue-red width--12"
              onClick={() => setModalUpdateContactAlreadyExist(false)}
            >
              Réessayer
            </div>
            <div className="button button--blue-red width--12" onClick={() => handleClose()}>
              Abandonner
            </div>
          </div>
        </Modale>
      )}
      {modalUpdateContactNotCorrect && (
        <NotCorrect setNotCorrect={setModalUpdateContactNotCorrect} />
      )}
      {/* modal response ok _____________________________________________________________*/}
      {modalCreatePPOk && <ModalParapheur closeModal={closeModalCreateOk} />}
      {/* modal response not ok _________________________________________________________*/}
      {modalCreatePPNotOk && (
        <ModalCreatePPNotOK
          setModalNotOk={setModalCreatePPNotOk}
          message={'Une personne existe déjà avec cette adresse mail'}
          contact={true}
          handleCloseAll={() => handleClose()}
        />
      )}
    </>
  )
}

export default ModaleUpdateContact
