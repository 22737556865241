// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get persons
 * @reduxAction
 */

export const getPersonsLoad = createAction('get-persons-load')

export const getPersonsSuccess = createAction(
  'get-persons-success',
  (persons) => {
    return {
      payload: persons,
    }
  }
)

export const getPersonsError = createAction(
  'get-persons-error', (error) => {
  return {
    payload: error,
  }
})