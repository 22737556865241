import { useState } from 'react'

const useModalStates = () => {
  const [toggleAdd, setToggleAdd] = useState(false)
  const [toggleAddOk, setToggleAddOk] = useState(false)
  const [toggleAddNotOk, setToggleAddNotOk] = useState(false)
  const [toggleAddNotCorrect, setToggleAddNotCorrect] = useState(false)
  const [toggleDivers, setToggleDivers] = useState(false)

  return {
    toggleAdd,
    setToggleAdd,
    toggleAddOk,
    setToggleAddOk,
    toggleAddNotOk,
    setToggleAddNotOk,
    toggleAddNotCorrect,
    setToggleAddNotCorrect,
    toggleDivers,
    setToggleDivers,
  }
}

export default useModalStates
