// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getPassifsError, getPassifsLoad, getPassifsSuccess } from '../../actions/Patrimoine/actionGetPassifs'

// REDUCER // ______________________________________________________________

// initial state
const initialStatePassifs = {
  isLoading: false,
  passifs: null,
  error: '',
}
// reducer
export const getPassifsReducer = createReducer(initialStatePassifs, (builder) => {
  return builder
    .addCase(getPassifsLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getPassifsSuccess, (draft, action) => {
      draft.isLoading = false
      draft.passifs = action.payload
      draft.error = ''
      return
    })
    .addCase(getPassifsError, (draft, action) => {
      draft.isLoading = false
      draft.passifs = null
      draft.error = action.payload
      return
    })
})
