import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MdRefresh } from 'react-icons/md'
import { getMajFraisFinancier } from '../../../../../services/API/financier'
import { getMajFraisSocial } from '../../../../../services/API/social'
import { CATEGORIE } from '../../../../../constants'
import SelectComponent from '../../../Components/SelectComponent'
import usePersonData from '../../../../layout/usePersonData'

const NewOperationCustomHead = ({ categorie, bienToDisplay }) => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  const { portefeuille } = bienToDisplay || {}
  const {
    totalFraisAnneeN,
    totalFraisAnneeNFE,
    totalFraisAnneeNUC,
    totalFraisAnneeNMoins1,
    totalFraisAnneeNMoins1FE,
    totalFraisAnneeNMoins1UC,
    totalFraisAnneeNMoins2,
    // totalFraisAnneeNMoins2FE,
    // totalFraisAnneeNMoins2UC,
    totalLiquidite,
    valeurPortefeuille,
  } = portefeuille || {}
  // response
  const [statusMajFrais, setStatusMajFrais] = useState(null)
  // annee
  const anneeN = new Date().getFullYear()
  const optionsYears = [
    {
      label: anneeN,
      value: anneeN,
      isDisabled: !totalFraisAnneeN,
    },
    {
      label: anneeN - 1,
      value: anneeN - 1,
      isDisabled: !totalFraisAnneeNMoins1,
    },
    {
      label: anneeN - 2,
      value: anneeN - 2,
      isDisabled: !totalFraisAnneeNMoins2,
    },
  ]
  const [anneeToDisplay, setAnneeToDisplay] = useState(anneeN)
  const [resultToDisplay, setResultToDisplay] = useState(null)
  useEffect(() => {
    if (anneeToDisplay) {
      switch (anneeToDisplay) {
        case anneeN:
          setResultToDisplay({
            total: ` ${totalFraisAnneeN.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`,
            FE: ` ${totalFraisAnneeNFE.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`,
            UC: ` ${totalFraisAnneeNUC.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`,
          })
          break
        case anneeN - 1:
          setResultToDisplay({
            total: ` ${totalFraisAnneeNMoins1.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`,
            FE: ` ${totalFraisAnneeNMoins1FE.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`,
            UC: ` ${totalFraisAnneeNMoins1UC.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`,
          })
          break
        case anneeN - 2:
          setResultToDisplay({
            total: ` ${totalFraisAnneeNMoins2.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`,
            FE: ` ${bienToDisplay.portefeuille.totalFraisAnneeNMoins2FE.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`,
            UC: ` ${bienToDisplay.portefeuille.totalFraisAnneeNMoins2UC.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`,
          })
          break
        default:
          return
      }
    }
  }, [anneeToDisplay, bienToDisplay])
  // handleclickMajFrais
  const handleclickMajFrais = () => {
    switch (categorie) {
      case 'VM':
        dispatch(
          getMajFraisFinancier(
            'majFraisproduitFinancierVM',
            bienToDisplay.id,
            person.id,
            isPP,
            setStatusMajFrais
          )
        )
        break
      case 'AV':
        dispatch(
          getMajFraisFinancier(
            'majFraisproduitFinancierAV',
            bienToDisplay.id,
            person.id,
            isPP,
            setStatusMajFrais
          )
        )
        break
      case 'PER':
        dispatch(
          getMajFraisSocial(
            'majFraisproduitSocialPER',
            bienToDisplay.id,
            person.id,
            isPP,
            setStatusMajFrais
          )
        )
        break
      case 'retraite':
        dispatch(
          getMajFraisSocial(
            'majFraisproduitSocialRetraite',
            bienToDisplay.id,
            person.id,
            isPP,
            setStatusMajFrais
          )
        )
        break
      default:
        return
    }
  }
  // reponse statusMajFrais
  useEffect(() => {
    if (statusMajFrais >= 200 && statusMajFrais < 300) {
      setStatusMajFrais(null)
    }
    if (statusMajFrais >= 400) {
      setStatusMajFrais(null)
    }
  }, [statusMajFrais])

  return (
    <div className="customHead">
      <div className="customHead--values">
        {categorie === CATEGORIE.vm || categorie === CATEGORIE.per ? (
          <p>
            Disponibilités :{' '}
            <span>{` ${totalLiquidite.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`}</span>
          </p>
        ) : null}
        <p>
          Valeur du portefeuille :
          <span>{` ${valeurPortefeuille.toLocaleString(undefined, {
            minimumFractionDigits: 4,
          })} €`}</span>
        </p>
        {categorie === CATEGORIE.av || categorie === CATEGORIE.retraite ? (
          <>
            <div>
              <p>Frais</p>
              <SelectComponent
                forAndId="anneeToDisplay"
                name="Année de référence :"
                options={optionsYears}
                placeholder="Sélectionner"
                onChange={(e) => setAnneeToDisplay(e.value)}
                value={optionsYears && optionsYears.find((c) => c.value === anneeToDisplay)}
                noLabel={true}
                widthAuto={true}
              />
              <button
                type="button"
                className="button button--circle"
                onClick={() => handleclickMajFrais()}
              >
                <MdRefresh />
              </button>
            </div>
            {anneeToDisplay && resultToDisplay ? (
              <>
                <p>
                  Total des frais : <span>{resultToDisplay.total}</span>
                </p>
                <p>
                  Frais Fond Euro :<span>{resultToDisplay.FE}</span>
                </p>
                <p>
                  Frais UC :<span>{resultToDisplay.UC}</span>
                </p>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  )
}

export default NewOperationCustomHead
