// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get patrimoine
 * @reduxAction
 */

export const getPropertiesLoad = createAction('get-properties-load')

export const getPropertiesSuccess = createAction(
  'get-properties-success',
  (properties) => {
    return {
      payload: properties,
    }
  }
)

export const getPropertiesError = createAction(
  'get-properties-error', (error) => {
  return {
    payload: error,
  }
})