import React from 'react'
import { stringToCapitalize } from '../../../../../../services/helpers/strings'

const BodyCatalogue = ({ element, handleShow }) => {
  return (
    <>
      {/* libelle */}
      <td className="tbody__tr__td bold" onClick={() => handleShow(element)}>
        {!element.libelle ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          stringToCapitalize(element.libelle)
        )}
      </td>
      {/* etablissement */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {!element.etablissement ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          stringToCapitalize(element.etablissement)
        )}
      </td>
      {/* dicis */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {!element.dicis ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.dicis.length
        )}
      </td>
    </>
  )
}

export default BodyCatalogue
