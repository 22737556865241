import React, { useState, useEffect } from 'react'
import InputsBeneficiaire from '../Inputs/InputsBeneficiaire'
import Modale from '../../Components/Modale'

const ModaleBeneficiaire = ({ newData, data, setData, setDataChange, setModal }) => {
  const [newBeneficiaire, setNewBeneficiaire] = useState({
    id: 0,
    nom: '',
    prenom: '',
    dateDeNaissanceFormatee: '',
    date_greffe: '',
    pourcentage_parts: '',
    pourcentagePleinePropriete: '',
    pourcentageNuePropriete: '',
  })

  const beneficiaireToDelete = newData

  useEffect(() => {
    newData
      ? setNewBeneficiaire({
          ...newData,
        })
      : setNewBeneficiaire({
          ...newBeneficiaire,
        })
  }, [])

  const handleAddBeneficiaire = () => {
    if (newData) {
      setData([...data.filter((el) => el !== beneficiaireToDelete), newBeneficiaire])
    } else {
      setData([...data, newBeneficiaire])
    }
    setModal(false)
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setNewBeneficiaire({ ...newBeneficiaire, [name]: date })
  }

  const handleChange = (e) => {
    setDataChange(true)
    const { name, value } = e.target
    setNewBeneficiaire({ ...newBeneficiaire, [name]: value })
  }

  return (
    <Modale close={() => setModal(false)} title={newData ? 'Modifier' : 'Ajouter'}>
      <div className="informations">
        <InputsBeneficiaire
          handleChange={handleChange}
          handleAddBeneficiaire={handleAddBeneficiaire}
          handleChangeDate={handleChangeDate}
          newBeneficiaire={newBeneficiaire}
        />
      </div>
    </Modale>
  )
}

export default ModaleBeneficiaire
