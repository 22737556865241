// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import {
  getFirmsLoad,
  getFirmsSuccess,
  getFirmsError,
} from '../actions/actionGetFirms'

// REDUCER // ______________________________________________________________

// initial state
const initialStateFirms = {
  isLoading: false,
  firms: null,
  error: '',
}
// reducer
export const getFirmsReducer = createReducer(initialStateFirms, (builder) => {
  return builder
    .addCase(getFirmsLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getFirmsSuccess, (draft, action) => {
      draft.isLoading = false
      draft.firms = action.payload
      draft.error = ''
      return
    })
    .addCase(getFirmsError, (draft, action) => {
      draft.isLoading = false
      draft.firms = null
      draft.error = action.payload
      return
    })
})