// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get selection
 * @reduxAction
 */

export const getSelection = createAction(
  'get-selection',
  (selection) => {
    return {
      payload: selection,
    }
  }
)