import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActifs } from '../../../../../services/API/actifs'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import ModalUpdateTotalValue from './ModalUpdateTotalValue'
import ActifsPieChart from './ActifsPieChart'
import ActifsToPrint from './ActifsToPrint'
import useCustomPrint from '../../../../layout/useCustomPrint'
import LittleLoader from '../../../../layout/LittleLoader'

const ActifsHome = () => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  const properties = useSelector((state) => state.getProperties.properties)
  const { valeurTotales, actifsDivers } = properties || {}
  // values
  const values = [
    { label: 'Divers', value: actifsDivers.montant },
    { label: 'Affecté', value: valeurTotales - actifsDivers.montant },
  ]
  // recharge
  useEffect(() => {
    !properties && dispatch(getActifs(person.id, isPP))
  }, [properties])
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!properties) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<ActifsToPrint elementToDisplay={properties} values={values} />}
        />
        <ButtonsRow handlePrint={handlePrint} />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurTotales}
              update={true}
              values={values}
            />
            {valeurTotales !== 0 && (
              <PatrimoineChartCard
                children={<ActifsPieChart properties={properties} />}
                detenteurs={true}
              />
            )}
          </div>
          {toggleUpdateTotal && (
            <ModalUpdateTotalValue
              setToggleUpdateTotal={setToggleUpdateTotal}
              person={person}
              valeurTotales={valeurTotales}
              pp={isPP}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ActifsHome
