import React from 'react'
import { useDispatch } from 'react-redux'
import Modale from '../../Components/Modale'
import { getFormIsActive } from '../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../redux/actions/actionSaveForm'

const ModalCreatePPNotOK = ({
  setModalNotOk,
  message,
  contact,
  conjointRelation,
  setModalRelation,
  handleCloseAll,
}) => {
  const dispatch = useDispatch()
  
  const handleRetry = () => {
    conjointRelation && setModalRelation(true)
    setModalNotOk(false)
  }
  const handleClose = () => {
    if (!contact) {
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
    } else {
      handleCloseAll()
    }
    setModalNotOk(false)
  }

  return message !== 'la personne existe deja avec cette adresse mail' ? (
    <Modale
      close={() => handleRetry()}
      title="Erreur !"
      line1={message === 500 ? 'Un problème est survenu, veuillez réessayer' : message}
    >
      <div className="button button--blue-red width--12" onClick={() => handleRetry()}>
        Modifier
      </div>
      <div className="button button--blue-red width--12" onClick={() => handleClose()}>
        Abandonner
      </div>
    </Modale>
  ) : (
    <Modale
      close={() => handleClose()}
      title="Cette personne existe"
      line1={"un mail vient d'être adressé à cette personne pour lui demander les droits d'accès"}
      line2={
        conjointRelation
          ? 'Vous pouvez enregistrer cette personne en tant que relation avec une autre adresse mail'
          : null
      }
    >
      {conjointRelation ? (
        <div className="button button--blue-red width--12" onClick={() => handleRetry()}>
          Modifier
        </div>
      ) : null}
      <div className="button button--blue-red width--12" onClick={() => handleClose()}>
        Abandonner
      </div>
    </Modale>
  )
}

export default ModalCreatePPNotOK
