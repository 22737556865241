import React from 'react'
import Card from '../../../../Components/Card'
import SelectComponent from '../../../../Components/SelectComponent'
import Input from '../../../../Components/Input'

const FormProductCard = ({
  handleChange,
  information,
  optionsLibelle,
  optionsEtablissement,
  bienToDisplay,
}) => {
  // information
  const { categorie, libelle, etablissement } = information || {}
  return (
    <Card
      title={
        bienToDisplay
          ? bienToDisplay.libelle
          : `Produit (${categorie === 'AV' || categorie === 'retraite' ? 'contrat' : 'titre'})`
      }
      h5={true}
      open={true}
    >
      <div className="informations">
        <div className="informations informations--borderFull">
          <>
            {categorie === 'AV' || categorie === 'retraite' ? (
              <>
                {/* etablissement */}
                {optionsEtablissement ? (
                  !bienToDisplay ? (
                    <SelectComponent
                      forAndId="etablissement"
                      name={'Fournisseur :'}
                      options={optionsEtablissement}
                      placeholder="Sélectionner un fournisseur"
                      onChange={(e, name) => handleChange(e, name)}
                      value={optionsEtablissement.find((c) => c.label === etablissement)}
                      copy={true}
                      required={true}
                      isClearable={true}
                      creatable={true}
                      formatCreateLabel={(value) => `Créer : ${value}`}
                    />
                  ) : (
                    <Input
                      forAndId="etablissement"
                      type="text"
                      name="Fournisseur :"
                      value={etablissement}
                      onChange={(e) => handleChange(e)}
                      required={true}
                      copy={true}
                      // disabled={true}
                    />
                  )
                ) : null}
                {/* libelle */}
                {optionsLibelle ? (
                  !bienToDisplay ? (
                    <SelectComponent
                      forAndId="libelle"
                      name={'Libellé :'}
                      options={optionsLibelle}
                      placeholder="Sélectionner un produit"
                      onChange={(e, name) => handleChange(e, name)}
                      value={optionsLibelle.find((c) => c.label === libelle)}
                      copy={true}
                      required={true}
                      isClearable={true}
                      creatable={true}
                    />
                  ) : (
                    <Input
                      forAndId="libelle"
                      type="text"
                      name="Libellé :"
                      value={libelle}
                      onChange={(e) => handleChange(e)}
                      required={true}
                      copy={true}
                      // disabled={true}
                    />
                  )
                ) : null}
              </>
            ) : (
              <>
                {/* etablissement */}
                <Input
                  forAndId="etablissement"
                  type="text"
                  name="Fournisseur :"
                  value={etablissement}
                  onChange={(e) => handleChange(e)}
                  required={true}
                  copy={true}
                />
                {/* libelle */}
                <Input
                  forAndId="libelle"
                  type="text"
                  name="Libellé :"
                  value={libelle}
                  onChange={(e) => handleChange(e)}
                  required={true}
                  copy={true}
                />
              </>
            )}
          </>
        </div>
      </div>
    </Card>
  )
}

export default FormProductCard
