import React, { useState } from 'react'
import { useEffect } from 'react'
import AutoCompleteAdresse from '../AutoCompleteAddress/AutoCompleteAdresse'
import Card from '../Components/Card'
import BlocTable from '../table/BlocTable'
import { DOC_PERSON_HOME, TITLES_DOC_UPDATE } from '../../../constants'
import TableBodyDoc from '../Files/TableBodyDoc'
import ModaleFiles from '../Files/ModaleFiles'

const FiscalAddress = ({
  person,
  adresseFiscale,
  setAdresseFiscale,
  fichiers,
  document,
  setDataChange,
}) => {
  // modale
  const [modalAdd, setModalAdd] = useState(false)
  // toggle
  const [toggleAdd, setToggleAdd] = useState(false)
  // files
  const [data, setData] = useState(fichiers)
  useEffect(() => {
    setData(fichiers.filter((el) => el.typeFichierGED === 'DOMICILE' && el.dateDocument))
  }, [fichiers])
  return (
    <>
      <Card title="Adresse fiscale" open={true}>
        <div className="informations">
          <AutoCompleteAdresse
            data={adresseFiscale}
            setData={setAdresseFiscale}
            adresseFiscale={true}
            setDataChange={setDataChange}
          />
        </div>
        {/* fichiers */}
        {person && document ? (
          !toggleAdd ? (
            <div
              className="informations__dropdown informations__dropdown--full"
              onClick={() => setToggleAdd(!toggleAdd)}
            >
              <p>{`Documents (${data.length})`}</p>
            </div>
          ) : (
            <div className="informations__dropdown--border">
              <p onClick={() => setToggleAdd(!toggleAdd)}>{`Documents (${data.length})`}</p>
              <div className="informations">
                <BlocTable
                  titles={TITLES_DOC_UPDATE}
                  data={data}
                  setData={setData}
                  tableType={DOC_PERSON_HOME}
                  children={<TableBodyDoc />}
                  setDataChange={setDataChange}
                  doc={true}
                  title={`Documents (${data.length})`}
                  handleAdd={() => setModalAdd(true)}
                />
              </div>
            </div>
          )
        ) : null}
      </Card>
      {modalAdd && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_PERSON_HOME}
          setModal={setModalAdd}
          setDataChange={setDataChange}
        />
      )}
    </>
  )
}

export default FiscalAddress
