import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteMessage, getMessages } from '../../../services/API/connexion'
import { beautifulDate } from '../../../services/helpers/dates'
import Modale from '../Components/Modale'
import NotOk from '../Components/Modales/NotOk'

const DeleteMessage = ({ setModalDelete, newData }) => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(null)
  const [modalOk, setModalOk] = useState(null)
  const [modalNotOk, setModalNotOk] = useState(null)

  const handleDelete = () => {
    dispatch(deleteMessage(newData.id, setStatus))
  }
  // response delete message
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setModalOk(true)
      setStatus(null)
    }
    if (status >= 400) {
      setModalNotOk(true)
      setStatus(null)
    }
  }, [status])
  const handleClose = () => {
    setModalOk(false)
    setModalNotOk(false)
    setModalDelete(false)
    dispatch(getMessages())
  }

  return (
    <>
      <Modale
        close={() => setModalDelete(false)}
        title="Souhaitez-vous réellement supprimer ce message ?"
      >
        <div className="informations">
          <div className="informations__blocTxt">
            <p className="informations__blocTxt__txt">
              Message en date du{' '}
              <span className="informations__blocTxt__txt informations__blocTxt__txt--important">
                {beautifulDate(newData.createdAt)}
              </span>
            </p>
            <p className="informations__blocTxt__txt">{`${newData.origineMessage}`}</p>
            <p className="informations__blocTxt__txt informations__blocTxt__txt--important">{`${newData.message} ?`}</p>
          </div>
          <div className="informations__buttons">
            <div className="button button--blue-red width--12" onClick={() => handleDelete()}>
              Supprimer
            </div>
            <div
              className="button button--blue-red width--12"
              onClick={() => setModalDelete(false)}
            >
              Annuler
            </div>
          </div>
        </div>
      </Modale>
      {modalOk && (
        <Modale close={() => handleClose()} title={`Message supprimé`}>
          <div className="informations__buttons">
            <div className="button button--blue-red width--12" onClick={() => handleClose()}>
              Fermer
            </div>
          </div>
        </Modale>
      )}
      {modalNotOk && <NotOk setNotOK={setModalNotOk} closeModale={handleClose} />}
    </>
  )
}

export default DeleteMessage
