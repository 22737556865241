// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get contacts
 * @reduxAction
 */

export const getContactsLoad = createAction('get-contacts-load')

export const getContactsSuccess = createAction(
  'get-contacts-success',
  (contacts) => {
    return {
      payload: contacts,
    }
  }
)

export const getContactsError = createAction(
  'get-contacts-error', (error) => {
  return {
    payload: error,
  }
})