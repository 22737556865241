// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getMessagesError, getMessagesLoad, getMessagesSuccess } from '../actions/actionGetMessages'

// REDUCER // ______________________________________________________________

// initial state
const initialStateMessages = {
  isLoading: false,
  messages: null,
  error: '',
}
// reducer
export const getMessagesReducer = createReducer(initialStateMessages, (builder) => {
  return builder
    .addCase(getMessagesLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getMessagesSuccess, (draft, action) => {
      draft.isLoading = false
      draft.messages = action.payload
      draft.error = ''
      return
    })
    .addCase(getMessagesError, (draft, action) => {
      draft.isLoading = false
      draft.messages = null
      draft.error = action.payload
      return
    })
})