import React from 'react'
import { Link } from 'react-router-dom'
import HeaderHomepage from '../../layout/HeaderHomepage'

const PageNotFound = () => {
  return (
    <div className="homepage">
      <HeaderHomepage />
      <main className="homepage__title">
        <h1 className="homepage__title__h1">Cette page n'existe pas !</h1>
        <section className="homepage__title__buttons">
          <Link to="/user/accueil" className="button button--red-red width--12">
            Accueil
          </Link>
        </section>
      </main>
    </div>
  )
}

export default PageNotFound
