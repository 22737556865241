import axios from 'axios'
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants'
import {
  getIncomesError,
  getIncomesLoad,
  getIncomesSuccess,
} from '../../redux/actions/Patrimoine/actionGetRevenus'

// recupération liste Revenus
export const getRevenus = (id, pp) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getIncomesLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/revenu/revenus?personneId=${id}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getIncomesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getIncomesError(error.message))
      })
  }
}
// update valeur totale Revenus
export const postRevenusTotalValue = (id, value, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/revenu/majRevenusValeurTotale?personneId=${id}&montant=${value}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getIncomesSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// update valeur totale revenu
export const postTotalValueRevenu = (revenuValue, id, value, pp, divers, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/revenu/${revenuValue}?personneId=${id}&montant=${value}&pp=${pp}&divers=${divers}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getIncomesSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// post revenu
export const postRevenu = (revenuToPost, id, pp, divers, data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/revenu/${revenuToPost}?personneId=${id}&pp=${pp}&divers=${divers}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(getIncomesSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// delete revenu
export const deleteRevenu = (deleteRevenu, data, idPP, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${API_BASE_URL}/revenu/${deleteRevenu}/${data.id}/${idPP}/${pp}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
        dispatch(getRevenus(idPP, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
