import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import InputsRelationShip from '../InputsRelationShip'
import Modale from '../../Components/Modale'

const ModaleRelationShip = ({ data, setData, newData, setDataChange, setModal }) => {
  // person
  const person = useSelector((state) => state.getPerson.person)

  const [newRelation, setNewRelation] = useState({
    id: 0,
    idPP1: person ? person.id : 0,
    idPP2: 0,
    typeRelation: '',
    acharge: false,
  })
  const linkToDelete = newData

  useEffect(() => {
    newData &&
      setNewRelation({
        id: newData.id,
        idPP1: newData.idPP1,
        idPP2: newData.idPP2,
        typeRelation: newData.typeRelation,
        acharge: newData.acharge,
      })
  }, [])

  const handleAddLink = () => {
    setDataChange(true)
    if (newData) {
      setData([...data.filter((el) => el !== linkToDelete), newRelation])
    } else {
      setData([...data, newRelation])
    }
    setModal(false)
  }

  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e) {
      if (e.target !== undefined) {
        const { name, value } = e.target
        setNewRelation({ ...newRelation, [name]: value })
      } else {
        const name = selectName.name
        const value = e.value
        setNewRelation({ ...newRelation, [name]: value })
      }
    } else {
      const name = selectName.name
      setNewRelation({ ...newRelation, [name]: undefined })
    }
  }

  return (
    <Modale close={() => setModal(false)} title={newData ? "Modifier" :"Ajouter"}>
      <div className="informations">
        <InputsRelationShip
          handleChange={handleChange}
          handleAddLink={handleAddLink}
          data={newRelation}
          setData={setNewRelation}
          setDataChange={setDataChange}
        />
      </div>
    </Modale>
  )
}

export default ModaleRelationShip
