import React from 'react'
import { COLORS } from '../../../../constants'
import BarChartCompoent from '../../Charts/BarChartCompoent'
import LegendBarChart from '../../Charts/LegendBarChart'

const ChartRevenusCharges = ({ revenusToDisplay, chargesToDisplay, print }) => {
  const {
    valeurTotaleRevenusMobiliers,
    valeurTotaleRevenusProfessionnels,
    valeurTotalePensionsRentes,
    valeurTotaleAutresRevenus,
    valeurTotaleRevenusFonciers,
    revenuDivers,
  } = revenusToDisplay || {}
  const {
    valeurTotalChargeGenerale,
    valeurTotalePension,
    valeurTotaleEmpruntEtDette,
    valeurTotalChargeDeductibleRI,
    valeurTotalChargeOuvrantDroitReductionRI,
    valeurTotalImpotEtTaxe,
    chargeDivers,
  } = chargesToDisplay || {}
  // data & colors
  const colors = [
    {
      dataKey: 'fonciers',
      stackId: 'a',
      fill: COLORS.colorRepartition01,
      name: 'Revenus fonciers',
      type: 'revenu',
    },
    {
      dataKey: 'mobiliers',
      stackId: 'a',
      fill: COLORS.colorRepartition02,
      name: 'Revenus mobiliers',
      type: 'revenu',
    },
    {
      dataKey: 'professionnels',
      stackId: 'a',
      fill: COLORS.colorRepartition03,
      name: 'Revenus professionnels',
      type: 'revenu',
    },
    {
      dataKey: 'pensionsRentes',
      stackId: 'a',
      fill: COLORS.colorRepartition04,
      name: 'Pension, rentes, retraites',
      type: 'revenu',
    },
    {
      dataKey: 'autresRevenus',
      stackId: 'a',
      fill: COLORS.colorRepartition05,
      name: 'Autres revenus',
      type: 'revenu',
    },
    {
      dataKey: 'revenusNonAffectés',
      stackId: 'a',
      fill: COLORS.colorFontLight,
      name: 'Revenus non affectés',
      type: 'revenu',
    },
    {
      dataKey: 'depensesCourantes',
      stackId: 'a',
      fill: COLORS.colorDetention01,
      name: 'Dépenses courantes',
      type: 'charge',
    },
    {
      dataKey: 'pensions',
      stackId: 'a',
      fill: COLORS.colorDetention02,
      name: 'Pensions',
      type: 'charge',
    },
    {
      dataKey: 'empruntsEtDettes',
      stackId: 'a',
      fill: COLORS.colorDetention03,
      name: 'Emprunts & dettes',
      type: 'charge',
    },
    {
      dataKey: 'chargesDeductibles',
      stackId: 'a',
      fill: COLORS.colorDetention04,
      name: 'Charges Déductibles',
      type: 'charge',
    },
    {
      dataKey: 'reductionEtCreditDimpots',
      stackId: 'a',
      fill: COLORS.colorDetention05,
      name: "Réductions et crédits d'impôts",
      type: 'charge',
    },
    {
      dataKey: 'impotsEtTaxes',
      stackId: 'a',
      fill: COLORS.colorDetention06,
      name: 'Impôts & taxes',
      type: 'charge',
    },
    {
      dataKey: 'chargesNonAffectées',
      stackId: 'a',
      fill: COLORS.colorFontLight,
      name: 'Charges non affectées',
      type: 'charge',
    },
  ]
  const data = [
    {
      name: 'Revenus annuels',
      fonciers: valeurTotaleRevenusFonciers,
      mobiliers: valeurTotaleRevenusMobiliers,
      professionnels: valeurTotaleRevenusProfessionnels,
      pensionsRentes: valeurTotalePensionsRentes,
      autresRevenus: valeurTotaleAutresRevenus,
      revenusNonAffectés: revenuDivers && revenuDivers.montant,
      array: colors.filter((el) => el.type === 'revenu'),
    },
    {
      name: 'Charges annuelles',
      depensesCourantes: -valeurTotalChargeGenerale,
      pensions: -valeurTotalePension,
      empruntsEtDettes: -valeurTotaleEmpruntEtDette,
      chargesDeductibles: -valeurTotalChargeDeductibleRI,
      reductionEtCreditDimpots: -valeurTotalChargeOuvrantDroitReductionRI,
      impotsEtTaxes: -valeurTotalImpotEtTaxe,
      chargesNonAffectées: chargeDivers && -chargeDivers.montant,
      array: colors.filter((el) => el.type === 'charge'),
    },
  ]
  return (
    <div className="informations">
      <BarChartCompoent data={data} colors={colors} print={print} />
      <hr className="hr" />
      <LegendBarChart data={data} />
    </div>
  )
}

export default ChartRevenusCharges
