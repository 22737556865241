import React from 'react'
import { useDispatch } from 'react-redux'
import Modale from '../../Components/Modale'
import { getFormIsActive } from '../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../redux/actions/actionSaveForm'

const ModalUpdatePPNotOk = ({ setModalUpdateNotOk, message }) => {
  const dispatch = useDispatch()
  const handleRetry = () => {
    setModalUpdateNotOk(false)
  }
  const handleClose = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setModalUpdateNotOk(false)
  }

  return (
    <Modale
      close={() => handleRetry()}
      title="Erreur !"
      line1={message === 500 ? 'Un problème est survenu, veuillez réessayer' : message}
    >
      <div className="button button--blue-red width--12" onClick={() => handleRetry()}>
        Modifier
      </div>
      <div className="button button--blue-red width--12" onClick={() => handleClose()}>
        Abandonner
      </div>
    </Modale>
  )
}

export default ModalUpdatePPNotOk
