import React from 'react'
import Card from '../../../../Components/Card'
import BlocTable from '../../../../table/BlocTable'
import DetentionCard from '../../../Detention/DetentionCard'
import AdditionalInformationCard from './AdditionalInformationCard'
import GeneralInformationCard from './GeneralInformationCard'
import SaleInformationsCard from '../../Actifs/SaleInformationsCard'
import FormSubmit from '../../../../Components/FormSubmit'
import { DOC_BIEN_IMMO, TITLES_DOC_UPDATE } from '../../../../../../constants'
import TableBodyDoc from '../../../../Files/TableBodyDoc'

const FormPatrimoineImmoContent = ({
  bienToDisplay,
  dataChange,
  setDataChange,
  handleChange,
  handleChangeDate,
  information,
  setInformation,
  detenteursToDisplay,
  setDetenteursToDisplay,
  fichiersToDisplay,
  setFichiersToDisplay,
  setToggleAddPatrimoine,
  handleSubmit,
  newValeurTotale,
  handleGiveUp,
}) => {
  return (
    <>
      {/* Form Bien Immo _______________________________________________________________________*/}
      {/* Envoi du formulaire */}
      <FormSubmit dataChange={dataChange} handleSubmit={handleSubmit} handleGiveUp={handleGiveUp} />
      {/* form */}
      <div className="sousSection">
        <div className="cards">
          <GeneralInformationCard
            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            information={information}
            setInformation={setInformation}
            bienToDisplay={bienToDisplay}
          />
          <div className="cards cards--column">
            <AdditionalInformationCard
              handleChange={handleChange}
              information={information}
              setInformation={setInformation}
              setDataChange={setDataChange}
            />
            <SaleInformationsCard
              handleChange={handleChange}
              handleChangeDate={handleChangeDate}
              information={information}
              immo={true}
            />
          </div>
        </div>
        <div className={bienToDisplay ? 'cards' : 'cards cards--fullWidth'}>
          <DetentionCard
            information={information}
            setInformation={setInformation}
            detenteursToDisplay={detenteursToDisplay}
            setDetenteursToDisplay={setDetenteursToDisplay}
            newValeurTotale={newValeurTotale}
            setDataChange={setDataChange}
            showUS={true}
          />
          {bienToDisplay && (
            <Card title={`Documents (${fichiersToDisplay.length})`} h4={true} open={true}>
              <div className="informations">
                <BlocTable
                  titles={TITLES_DOC_UPDATE}
                  data={fichiersToDisplay}
                  setData={setFichiersToDisplay}
                  children={<TableBodyDoc />}
                  setDataChange={setDataChange}
                  doc={true}
                  tableType={DOC_BIEN_IMMO}
                  title={`Documents (${fichiersToDisplay.length})`}
                  handleAdd={() => setToggleAddPatrimoine(true)}
                />
              </div>
            </Card>
          )}
        </div>
      </div>
      {/* Envoi du formulaire */}
      <FormSubmit
        dataChange={dataChange}
        handleSubmit={handleSubmit}
        handleGiveUp={handleGiveUp}
        down={true}
      />
    </>
  )
}

export default FormPatrimoineImmoContent
