import React from 'react'
import { NavLink } from 'react-router-dom'

const NavDashboard = () => {


  return (
    <>
      <nav className="secondNav">
          <ul className="secondNav__menu">
          <li className="secondNav__menu__bloc">
            <ul>
              <li className="secondNav__menu__item">
                <NavLink to="/user/comptes" className="secondNav__link secondNav__link--inactive">
                  Mes comptes
                </NavLink>
              </li>
              <li className="secondNav__menu__item">
                <NavLink
                  to="/user/repertoire"
                  className="secondNav__link secondNav__link--inactive"
                >
                  Mon Repertoire
                </NavLink>
              </li>
              </ul>
            </li>
            <li className="secondNav__menu__bloc">
            <ul>
              <li className="secondNav__menu__item">
                <NavLink to="/user/documents" className="secondNav__link secondNav__link--inactive">
                  Mes Documents
                </NavLink>
              </li>
              <li className="secondNav__menu__item">
                <NavLink
                  to="/user/patrimoine"
                  className="secondNav__link secondNav__link--inactive"
                >
                  Mon Patrimoine
                </NavLink>
              </li>
              </ul>
            </li>
          </ul>
      </nav>
    </>
  )
}

export default NavDashboard
