import React from 'react'
import Card from '../../../Components/Card'
import TableBodyRevenu from './Table/TableBodyRevenu'
import BlocTable from '../../../table/BlocTable'

const RevenusCard = ({ data, handleAdd, title, tableType }) => {
  // titles
  const titles = [
    'N°',
    'Libellé',
    'Catégorie',
    'Montant annuel',
    // 'Périodicité',
    'Voir',
    'Modifier',
    'Supprimer',
    'Dupliquer',
  ]
  return (
    <Card title={title} open={data.length > 0} h3={true}>
      <div className="informations">
        <BlocTable
          data={data}
          titles={titles}
          tableType={tableType}
          children={<TableBodyRevenu />}
          title={title}
          handleAdd={() => handleAdd()}
        />
      </div>
    </Card>
  )
}

export default RevenusCard
