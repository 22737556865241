import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FE } from '../../../../../../constants'
import { getDICIList } from '../../../../../../services/API/financier'
import { postNewSupport, postNewVL } from '../../../../../../services/API/catalogue'
import Modale from '../../../../Components/Modale'
import CreationSupportInput from './CreationSupportInput'

const CreationCard = ({ information, handleSelect }) => {
  const dispatch = useDispatch()
  //
  const { etablissement, dicis, categorie } = information || {}
  // initialState support
  const initialState = {
    isin: '',
    nom: '',
    dici_url: '',
    note: 0,
    m_nom: etablissement,
    c_nom: '',
    nonCote: true,
  }
  // new support
  const [support, setSupport] = useState(initialState)
  const { isin, nom, dici_url, note, m_nom, nonCote, c_nom } = support || {}
  // initialisation new support
  useEffect(() => {
    setSupport({ ...support, m_nom: etablissement })
  }, [etablissement])
  // VL
  const [cours, setCours] = useState({
    dateVL: new Date().toISOString().split('T')[0],
    valeur: 0,
    isin: isin,
  })
  const { dateVL, valeur } = cours || {}
  // type
  const [fondEuro, setFondEuro] = useState(false)
  // data
  const dataSupport = {
    isin: isin,
    nom: nom,
    dici_url: dici_url,
    note: note,
    m_nom: m_nom,
    c_nom: c_nom,
    nonCote: nonCote,
  }
  const dataCours = {
    id: 0,
    isin: isin,
    dateVL: dateVL,
    valeur: parseFloat(valeur),
  }
  // message
  const [message, setMessage] = useState('')
  // reponse
  const [statusCours, setStatusCours] = useState(null)
  const [statusSupport, setStatusSupport] = useState(null)
  // toggle
  const [toggleCreate, setToggleCreate] = useState(false)
  // handleChange
  const handleChange = (e, selectName) => {
    if (e.target !== undefined) {
      const { name, value } = e.target
      switch (name) {
        case 'valeur':
          setCours({ ...cours, [name]: value })
          break
        case 'nom':
          setSupport({ ...support, [name]: value })
          break
        default:
          setSupport({ ...support, [name]: value })
      }
    } else {
      const name = selectName.name
      const value = e.value
      if (name === 'fondEnEuro') {
        setFondEuro(value)
        setSupport({ ...support, c_nom: value ? FE : '' })
      } else {
        setSupport({ ...support, [name]: value })
      }
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setCours({ ...cours, [name]: date })
  }
  // handleSubmitNewSupp
  const handleSubmitNewSupp = (e) => {
    e.preventDefault()
    if (dicis.find((el) => el.isin === isin && el.nonCote)) {
      setMessage('Un support existe déjà avec cet isin.')
    } else {
      if (isin !== '' && nom !== '' && m_nom !== '') {
        dispatch(postNewSupport(dataSupport, setStatusSupport))
      } else {
        console.log(isin, m_nom, nom)
      }
    }
  }

  // status Support
  useEffect(() => {
    if (statusSupport) {
      if (statusSupport < 400) {
        if (!fondEuro && valeur && dateVL) {
          dispatch(postNewVL(dataCours, setStatusCours))
        }
        dispatch(getDICIList())
        handleSelect(support)
        setSupport(initialState)
        setCours({
          isin: '',
          dateVL: new Date().toISOString().split('T')[0],
          valeur: '',
        })
        setFondEuro(false)
        setToggleCreate(false)
        setStatusSupport(null)
      }
    }
  }, [statusSupport])
  // statusCours
  useEffect(() => {
    if (statusCours) {
      switch (statusCours) {
        case 200:
          setMessage(null)
          break
        case 403:
          setMessage("Il s'agit d'un support non modifiable")
          break
        case 404:
          setMessage('ISIN non reconnu')
          break
        case 409:
          setMessage('Une valeur existe déjà pour cette date')
          break
        default:
          setMessage('un problème est survenu, veuillez réessayer')
      }
    }
  }, [statusCours])
  // close Modal
  const closeModale = () => {
    setMessage(null)
    setStatusSupport(null)
    setStatusCours(null)
  }

  return (
    <>
      {!toggleCreate ? (
        <div
          className="informations__dropdown informations__dropdown--full"
          onClick={() => setToggleCreate(!toggleCreate)}
        >
          <p>Créer un nouveau support</p>
        </div>
      ) : (
        <div className="informations informations__dropdown--border">
          <p onClick={() => setToggleCreate(!toggleCreate)}>Créer un nouveau support</p>
          <CreationSupportInput
            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            handleSubmitNewSupp={handleSubmitNewSupp}
            support={support}
            cours={cours}
            fondEuro={fondEuro}
            categorie={categorie}
            create={true}
          />
        </div>
      )}
      {message ? (
        <Modale close={() => closeModale()} title={'Erreur'} line1={message}></Modale>
      ) : null}
    </>
  )
}

export default CreationCard
