import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import { sortByType } from '../../../../../../services/helpers/sort'
import { supprId } from '../../../../../../services/helpers/helpers'
import * as charge from '../../../../../../constants'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyCharge from './BodyCharge'
import BodyChargesPrint from './BodyChargesPrint'

const TableBodyCharge = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
  tableType,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [redirection, setRedirection] = useState(null)
  const [type, setType] = useState(null)
  useEffect(() => {
    switch (tableType) {
      case charge.CHARGES_GENERALES:
        setRedirection('depenses-courantes')
        setType(charge.CHARGE_GENERALE)
        break
      case charge.CHARGES_DEDUCTIBLES:
        setRedirection('deductibles-RI')
        setType(charge.CHARGE_DEDUCTIBLE)
        break
      case charge.CHARGES_REDUCTIONS:
        setRedirection('reduction-RI')
        setType(charge.CHARGE_REDUCTION)
        break
      case charge.CHARGES_EMPRUNTS:
        setRedirection('emprunts-et-dettes')
        setType(charge.CHARGE_EMRUNT)
        break
      case charge.CHARGES_PENSIONS:
        setRedirection('pensions')
        setType(charge.CHARGE_PENSION)
        break
      case charge.CHARGES_IMPOTS:
        setRedirection('impots-taxes')
        setType(charge.CHARGE_IMPOT)
        break
      default:
        return
    }
  }, [])

  // ouvrir infos charge
  const handleShow = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'CHARGES', type: type }))
    navigate(`/user/patrimoine/charges/${redirection}/info`)
  }
  // modifier bien
  const handleEdit = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'CHARGES', type: type }))
    navigate(`/user/patrimoine/charges/${redirection}/ajouter`)
  }
  // duplicate bien
  const handleDuplicate = (bien) => {
    const bienBis = {
      ...bien,
      id: 0,
      fichiers: [],
      nom: `${bien.nom} - copie`,
      detenteurs: bien.detenteurs.map((det) => supprId(det)),
    }
    dispatch(getBienSuccess(bienBis))
    dispatch(getbienType({ categorie: 'REVENUS', type: type }))
    navigate(`/user/patrimoine/charges/${redirection}/ajouter`)
  }
  // supprimer bien
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }

  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Nom':
          giveAType('nom')
          break
        case 'Montant annuel':
          giveAType('montantAnnuel')
          break
        case 'Périodicité':
          giveAType('periodicite')
          break
        case 'Début':
          giveAType('dateDebut')
          break
        case 'Fin':
          giveAType('dateFin')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyChargesPrint /> : <BodyCharge handleShow={handleShow} />}
      print={print}
      handleShow={handleShow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleDuplicate={handleDuplicate}
      tableType={tableType}
    />
  )
}

export default TableBodyCharge
