import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getSocial } from '../../../../../../services/API/social'
import NavSocial from './NavSocial'
import usePersonData from '../../../../../layout/usePersonData'
import LittleLoader from '../../../../../layout/LittleLoader'

const ActifsSocial = () => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  const social = useSelector((state) => state.getSocial.social)
  // recup social
  useEffect(() => {
    dispatch(getSocial(person.id, isPP))
  }, [person, dispatch])

  if (!social) return <LittleLoader />
  return (
    <>
      <NavSocial />
      <Outlet />
    </>
  )
}

export default ActifsSocial
