import React from 'react'
import { useSelector } from 'react-redux'
import { recupLabel } from '../../../../services/helpers/helpers'
import { optionsTypeRelation } from '../../../../data/options/options'

const BodyRelationPrint = ({ element, index }) => {
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  const listPersons = persons.referentPP.concat(persons.relationPP)

  const getGoodPerson = (id) => {
    const personne = listPersons.filter((person) => person.id === id)[0]
    if (personne !== undefined) {
      const { nom, prenom } = personne || {}
      return { nom, prenom }
    } else {
      return { nom: '-', prenom: '-' }
    }
  }

  return (
    <>
      {/* idPP2 */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">
          {!element.idPP2
            ? '-'
            : `${getGoodPerson(element.idPP2).nom} ${getGoodPerson(element.idPP2).prenom}`}
        </p>
      </div>
      {/* typeRelation */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Relation</p>
        <p className="print__table__bloc__line--value">
          {!element.typeRelation ? '-' : `${recupLabel(element.typeRelation, optionsTypeRelation)}`}
        </p>
      </div>
      {/* acharge */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">À charge</p>
        <p className="print__table__bloc__line--value">
          {element.acharge === '' ? '-' : element.acharge === true ? 'oui' : 'non'}
        </p>
      </div>
    </>
  )
}

export default BodyRelationPrint
