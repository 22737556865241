import React, { useEffect, useState } from 'react'
import { optionsSexe } from '../../../data/options/optionsPP'
import { handleChange } from '../../../services/helpers/helpers'
import Input from '../Components/Input'
import SelectComponent from '../Components/SelectComponent'
import DateInputComponent from '../Components/DateInputComponent'

const InputsContact = ({
  contact,
  setContact,
  validate,
  setModalUpdate,
  createPP,
  setCreatePP,
}) => {
  const { nom, prenom, email, telephonePortable, dateNaissance, sexe, fonction } = contact || {}

  const [change, setChange] = useState(false)
  useEffect(() => {
    if (
      nom !== undefined &&
      nom !== '' &&
      prenom !== undefined &&
      prenom !== '' &&
      email !== undefined &&
      email !== ''
    ) {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [nom, prenom, email, telephonePortable, dateNaissance, sexe, fonction])
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setContact({ ...contact, [name]: date })
  }

  return (
    <>
      {/* creationPP */}
      <div className="informations informations--borderFull">
        <Input
          forAndId="createPP"
          name="Créer une personne ?"
          onChange={(e, name) => handleChange(e, name)}
          value={createPP}
          check={true}
          setValue={setCreatePP}
        />
        {/* Nom */}
        <Input
          forAndId="nom"
          type="text"
          name="Nom  :"
          value={nom}
          onChange={(e, name) => handleChange(e, name, contact, setContact)}
          required={true}
          copy={true}
        />
        {/* prenom */}
        <Input
          forAndId="prenom"
          type="text"
          name="Prénom  :"
          value={prenom}
          onChange={(e, name) => handleChange(e, name, contact, setContact)}
          required={true}
          copy={true}
        />
        {/* Mail */}
        <Input
          forAndId="email"
          type="mail"
          name="Mail :"
          value={email}
          onChange={(e, name) => handleChange(e, name, contact, setContact)}
          required={true}
          copy={true}
        />
        {createPP && (
          <>
            {/* Date de naissance */}
            <DateInputComponent
              label={'Date de naissance :'}
              value={dateNaissance}
              handleChange={handleChangeDate}
              name={'dateNaissance'}
            />
            {/* sexe */}
            <SelectComponent
              forAndId="sexe"
              name="Sexe de naissance :"
              options={optionsSexe}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name, contact, setContact)}
              value={optionsSexe.find((c) => c.value === sexe)}
              copy={true}
              required={true}
            />
          </>
        )}
      </div>
      {/* Tel Mobile */}
      <Input
        forAndId="telephonePortable"
        type="tel"
        pattern="[0-9]{10,12}"
        maxLength={12}
        minLength={10}
        name="Mobile :"
        value={telephonePortable}
        onChange={(e, name) => handleChange(e, name, contact, setContact)}
        placeholder="0655443322"
        copy={true}
      />
      {/* Fonction */}
      <Input
        forAndId="fonction"
        type="text"
        name="Fonction  :"
        value={fonction}
        onChange={(e, name) => handleChange(e, name, contact, setContact)}
        copy={true}
      />
      {change ? (
        <div className="informations__buttons">
          <div className="button button--red-red width--12" onClick={() => validate()}>
            Enregistrer
          </div>
          <div className="button button--blue-red width--12" onClick={() => setModalUpdate(false)}>
            Annuler
          </div>
        </div>
      ) : (
        <div className="informations__buttons">
          <div className="button button--grey width--12">Enregistrer</div>
          <div className="button button--blue-red width--12" onClick={() => setModalUpdate(false)}>
            Annuler
          </div>
        </div>
      )}
    </>
  )
}

export default InputsContact
