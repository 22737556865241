import React from 'react'
import { ImArrowRight } from 'react-icons/im'
import { Link } from 'react-router-dom'

const TaxeSimulatorLink = () => {
  // today
  const date = new Date()
  const today = date.getFullYear()
  const url = `//simulateur-ir-ifi.impots.gouv.fr/calcul_impot/${today}/complet/index.htm`

  return (
    <div className="cards cards--fullWidth">
      <div className="informations">
        <Link to={url} target="_blank" rel="noopener noreferrer" className="siren__link">
          <p>{`Simulateur de calcul de l'impôt ${today} sur les revenus ${today - 1}`}</p>
          <ImArrowRight />
        </Link>
      </div>
    </div>
  )
}

export default TaxeSimulatorLink
