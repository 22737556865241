import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import { postPassif } from '../../../../../../services/API/passifs'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../services/helpers/detenteurs'
import { supprId } from '../../../../../../services/helpers/helpers'
import ModaleDivers from '../../../Patrimoine/ModaleDivers'
import FormPassifDetteContent from './FormPassifDetteContent'
import NotCorrect from '../../../../Components/Modales/NotCorrect'
import Ok from '../../../../Components/Modales/Ok'
import NotOk from '../../../../Components/Modales/NotOk'
import { DOC_PASSIF_DETTE } from '../../../../../../constants'
import ModaleFiles from '../../../../Files/ModaleFiles'
import usePersonData from '../../../../../layout/usePersonData'

const FormPassifDette = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const passifs = useSelector((state) => state.getPassifs.passifs)
  const passifsDivers = passifs ? passifs.passifDivers.montant : null
  const dettesDivers = passifs ? passifs.dettes.detteDivers.montant : null
  const dette = useSelector((state) => state.getBien.bien)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddDette, setToggleAddDette] = useState(false)
  const [toggleAddDetteOk, setToggleAddDetteOk] = useState(false)
  const [toggleAddDetteNotOk, setToggleAddDetteNotOk] = useState(false)
  const [toggleAddDetteNotCorrect, setToggleAddDetteNotCorrect] = useState(false)
  const [toggleDivers, setToggleDivers] = useState(false)

  // maj passif
  useEffect(() => {
    if (dette) {
      setFichiersToDisplay(dette.fichiers)
    }
  }, [dette])
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    montantRestant: 0,
    refDette: '',
    detention: 'CLIENT',
    tauxIndivision: 100,
    detenteurs: [],
    fichiers: [],
    typeActif: '',
    idActif: 0,
    codeImpot: '',
  })
  const {
    id,
    montantRestant,
    refDette,
    detention,
    tauxIndivision,
    detenteurs,
    fichiers,
    typeActif,
    idActif,
    codeImpot,
  } = information || {}
  //
  const [divers, setDivers] = useState(true)
  const [fichiersToDisplay, setFichiersToDisplay] = useState([])
  const [detenteursToDisplay, setDetenteursToDisplay] = useState(detenteurs)
  const [newValeurTotale, setNewValeurTotale] = useState()
  // valeur totale de calcul
  useEffect(() => {
    setNewValeurTotale((montantRestant * tauxIndivision) / 100)
  }, [montantRestant])
  // maj si passifToDisplay
  useEffect(() => {
    dette
      ? setInformation({
          ...information,
          id: dette.id,
          montantRestant: dette.montantRestant,
          refDette: dette.refDette,
          detention: dette.detention,
          tauxIndivision: dette.tauxIndivision,
          detenteurs: dette.detenteurs
            .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
            .reverse(),
          fichiers: dette.fichiers,
          typeActif: dette.typeActif,
          idActif: dette.idActif,
          codeImpot: dette.codeImpot,
        })
      : setInformation({
          ...information,
          detenteurs: [
            {
              id: 0,
              nomDetenteur:
                personType === 'referentPP'
                  ? `${person.nom} ${person.prenom}`
                  : `${person.denominationSociale}`,
              mailDetenteur: person.mail,
              idPP: personType === 'referentPP' ? 1 : 0,
              idPM: personType === 'dirigeantPM' ? 1 : 0,
              dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
              dateContitutionUsufruit: '',
              dureeDemembrement: '',
              typeDetention: 'PLEINEPROPRIETE',
              tauxPleinePropriete: 100,
              tauxUsufruit: 0,
              tauxUsufruitCalcule: handleCalculateTaux(person.dateNaissance),
              tauxNuePropriete: 0,
              valeurDetenue: 0,
              valeurPP: 0,
              valeurUS: 0,
              valeurNP: 0,
              tempId: Date.now(),
            },
          ],
        })
  }, [])
  // form dette is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Passif Dette' }))
    }
  }, [dataChange])
  // save dette
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Passif Dette') {
      handlePreSubmit()
    }
  }, [saveBien])
  // Object to send
  const data = {
    id: id,
    montantRestant: parseInt(montantRestant),
    refDette: refDette,
    detention: detention,
    tauxIndivision: tauxIndivision,
    detenteurs:
      detenteursToDisplay &&
      detenteursToDisplay.map((det) => updateDetenteurs(det, newValeurTotale, detenteursToDisplay)),
    fichiers: fichiers,
    typeActif: typeActif,
    idActif: idActif,
    codeImpot: codeImpot,
  }
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])

  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      setInformation({ ...information, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      switch (name) {
        case 'divers':
          setDivers(value)
          break
        default:
          setInformation({ ...information, [name]: value })
      }
    }
  }

  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (montantRestant !== '' && montantRestant !== 0 && refDette !== '') {
      setDetenteursToDisplay(
        detenteursToDisplay.map((det) => (det.newDetenteur ? supprId(det) : det))
      )
      passifsDivers === 0 && dettesDivers === 0 ? handleSubmit() : setToggleDivers(true)
    } else {
      setToggleAddDetteNotOk(true)
    }
  }
  // submit
  const handleSubmit = () => {
    dispatch(postPassif('dette', person.id, isPP, divers, data, setStatus))
    setToggleDivers(false)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (dette) {
      navigate('/user/patrimoine/passifs/dettes/info')
    } else {
      navigate('/user/patrimoine/passifs/dettes')
    }
  }
  // response create passif status and modal
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setStatus(null)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
      setToggleAddDetteOk(true)
    }
    if (status >= 400) {
      setStatus(null)
      setToggleAddDetteNotOk(true)
    }
  }, [status])
  // maj detenteurs
  // useEffect(() => {
  //   if (detenteursToDisplay.length > 0) {
  //     setInformation({ ...information, detenteurs: detenteursToDisplay })
  //   }
  // }, [detenteursToDisplay])

  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setToggleAddDetteOk(false)
    navigate('/user/patrimoine/passifs/dettes')
  }
  // recuperation passifs
  useEffect(() => {
    if (passifs && dette) {
      const newBien = passifs.dettes.ldettes.filter((el) => el.id === dette.id)
      dispatch(getBienSuccess(newBien[0]))
    }
  }, [passifs])

  return (
    <div className="section__content">
      {/* form */}
      <FormPassifDetteContent
        detteToDisplay={dette}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleChange={handleChange}
        information={information}
        setInformation={setInformation}
        divers={divers}
        detenteursToDisplay={detenteursToDisplay}
        setDetenteursToDisplay={setDetenteursToDisplay}
        fichiersToDisplay={fichiersToDisplay}
        setFichiersToDisplay={setFichiersToDisplay}
        setToggleAddDette={setToggleAddDette}
        handleSubmit={handlePreSubmit}
        newValeurTotale={newValeurTotale}
        handleGiveUp={handleGiveUp}
      />
      {/* modales */}
      {toggleAddDette && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_PASSIF_DETTE}
          setModal={setToggleAddDette}
          setDataChange={setDataChange}
        />
      )}
      {toggleAddDetteOk && (
        <Ok
          text={
            dette
              ? 'Votre dette a été modifiée avec succès'
              : 'Votre dette a été ajoutée avec succès'
          }
          close={closeToggleAddBien}
        />
      )}
      {toggleAddDetteNotOk && (
        <NotOk setNotOK={setToggleAddDetteNotOk} closeModale={closeToggleAddBien} />
      )}
      {toggleAddDetteNotCorrect && <NotCorrect setNotCorrect={setToggleAddDetteNotCorrect} />}
      {toggleDivers && (
        <ModaleDivers
          setToggleDivers={setToggleDivers}
          handleChange={handleChange}
          divers={divers}
          setDivers={setDivers}
          handleSubmit={handleSubmit}
          montantCategorieDivers={passifsDivers}
          montantSousCategorieDivers={dettesDivers}
          type={'passif'}
          sousType={'dettes'}
        />
      )}
    </div>
  )
}

export default FormPassifDette
