import React from 'react'
import Card from '../Components/Card'
// import BlocTable from './BlocTable'
import BlocTablePrint from './BlocTablePrint'

const TableToPrint = ({ data, title, tableType, children, read }) => {

  return (
      <Card title={title} open={true} h2={true} print={true}>
        <div className="informations">
          <BlocTablePrint data={data} tableType={tableType} children={children} read={read} />
        </div>
      </Card>
  )
}

export default TableToPrint
