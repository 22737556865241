// IMPORTS // ______________________________________________________________
import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getFormIsActive } from '../actions/actionFormIsActive'

// REDUCER // ______________________________________________________________

// initial state
const initialStateFormIsActive = {
  isActive: null,
}
// reducer
export const getFormIsActiveReducer = createReducer(initialStateFormIsActive, (builder) => {
  return builder.addCase(getFormIsActive, (draft, action) => {
    draft.isActive = action.payload
    return
  })
})
