// revenus _________________________________________________________________________________
export const optionsTypeRevenuProfessionnel = [
    { value: 'TRAITEMENTSETSALAIRES', label: 'Traitement et salaires' },
    { value: 'REVENUSNONSALARIES', label: 'Revenus non-salariés' },
    // { value: 'AUTRES', label: 'Autres revenus' },
  ]
  export const optionsTypeRevenusSalaires = [
    {
      value: 'Salaires, avantages en nature et indemnités journalières',
      label: 'Salaires, avantages en nature et indemnités journalières',
    },
    {
      value: 'Revenus des salariés des particuliers employeurs',
      label: 'Revenus des salariés des particuliers employeurs',
    },
    {
      value: 'Heures supplémentaires exonérées et jours RTT exonérés',
      label: 'Heures supplémentaires exonérées et jours RTT exonérés',
    },
    {
      value: 'Prime de partage de la valeur exonérée',
      label: 'Prime de partage de la valeur exonérée',
    },
    {
      value: 'Autres revenus imposables (indemnité de préretraite, allocation chômage...)',
      label: 'Autres revenus imposables (indemnité de préretraite, allocation chômage...)',
    },
    {
      value: 'Revenus des associés et gérants article 62 du CGI',
      label: 'Revenus des associés et gérants article 62 du CGI',
    },
    {
      value: "Droits d'auteur, fonctionnaires chercheurs",
      label: "Droits d'auteur, fonctionnaires chercheurs",
    },
    {
      value:
        "Salaires perçus par les non-résidents et salaires de source étrangère avec crédit d'impôt égal à l'impôt français",
      label:
        "Salaires perçus par les non-résidents et salaires de source étrangère avec crédit d'impôt égal à l'impôt français",
    },
    {
      value: 'Autres salaires imposables de source étrangère',
      label: 'Autres salaires imposables de source étrangère',
    },
  ]
  export const optionsTypeRevenusNonSalarie = [
    {
      value: 'Revenus Agricoles régime micro',
      label: 'Revenus Agricoles régime micro',
    },
    {
      value: 'Revenus Agricoles régime réel',
      label: 'Revenus Agricoles régime réel',
    },
    {
      value: 'BIC (Bénéfice Industriels et Commerciaux) professionnels',
      label: 'BIC (Bénéfice Industriels et Commerciaux) professionnels',
    },
    {
      value: 'BIC (Bénéfice Industriels et Commerciaux) non-professionnels',
      label: 'BIC (Bénéfice Industriels et Commerciaux) non-professionnels',
    },
    {
      value: 'BNC (Bénéfice Non Commerciaux) professionnels',
      label: 'BNC (Bénéfice Non Commerciaux) professionnels',
    },
    {
      value: 'BNC (Bénéfice Non Commerciaux) non-professionnel',
      label: 'BNC (Bénéfice Non Commerciaux) non-professionnel',
    },
    {
      value: 'Loueur en meublé professionnel',
      label: 'Loueur en meublé professionnel',
    },
    {
      value: 'Loueur en meublé non professionnel régime micro BIC',
      label: 'Loueur en meublé non professionnel régime micro BIC',
    },
    {
      value: 'Loueur en meublé non professionnel régime réel',
      label: 'Loueur en meublé non professionnel régime réel',
    },
  ]
  export const optionsTypeRevenuMobilier = [
    { value: 'REVENUMOBILIERABATTEMENT', label: 'Revenu mobilier avec abattement' },
    { value: 'REVENUMOBILIER', label: 'Revenu mobilier' },
    { value: 'AUTRESREVENUSMOBILIER', label: 'Autres revenus mobiliers' },
  ]
  export const optionsDetailsRevenuMobilier = [
    { value: 'Dividendes', label: 'Dividendes' },
    { value: 'Coupons et titres assimilés', label: 'Coupons et titres assimilés' },
    { value: 'Intérêts et produit de placement à revenu fixe', label: 'Intérêts et produit de placement à revenu fixe' },
    { value: 'Autre revenu mobilier', label: 'Autre revenu mobilier' },
  ]
  export const optionsDetailsRevenuFoncier = [
    { value: 'Régime micro foncier', label: 'Régime micro foncier' },
    { value: 'Régime réel, revenus foncier imposable', label: 'Régime réel, revenus foncier imposable' },
    { value: 'Déficit imputable sur les revenus fonciers', label: 'Déficit imputable sur les revenus fonciers' },
    { value: 'Déficit imputable sur le revenu global', label: 'Déficit imputable sur le revenu global' },
    { value: 'Déficits antérieurs non encore imputés', label: 'Déficits antérieurs non encore imputés' },
  ]
  export const optionsDetailsRevenuAutre = [
    { value: 'Retraits partiels assurance-vie', label: 'Retraits partiels assurance-vie' },
    { value: 'Allocation CAF', label: 'Allocation CAF' },
    { value: 'Autre allocation', label: 'Autre allocation' },
    { value: 'Autre revenu', label: 'Autre revenu' },
  ]
  export const optionsTypePR = [
    { value: 'PENSIONSRENTESRETRAITES', label: 'Pensions / Rentes / Retraites' },
    { value: 'RENTESVIAGERES', label: 'Rentes viagères à titre onéreux' },
    // { value: 'AUTREPENSION', label: 'Autres pensions' },
  ]
  export const optionsPensionsRentesRetraites = [
    {
      value: 'Total des pensions, des retraites et des rentes',
      label: 'Total des pensions, des retraites et des rentes',
    },
    {
      value: 'Pensions de retraite en capital taxables à 7,5 %',
      label: 'Pensions de retraite en capital taxables à 7,5 %',
    },
    {
      value: "Pensions en capital des nouveaux plans d'épargne retraite",
      label: "Pensions en capital des nouveaux plans d'épargne retraite",
    },
    {
      value: "Pensions d'invalidité perçues",
      label: "Pensions d'invalidité perçues",
    },
    {
      value: 'Pensions alimentaires perçues',
      label: 'Pensions alimentaires perçues',
    },
    {
      value:
        "Pensions perçues par les non-résidents et pensions de source étrangère avec un crédit d'impôt égal à l'impôt français",
      label:
        "Pensions perçues par les non-résidents et pensions de source étrangère avec un crédit d'impôt égal à l'impôt français",
    },
    {
      value: 'Autres pensions imposables de source étrangère',
      label: 'Autres pensions imposables de source étrangère',
    },
  ]
  export const optionsRentesViageres = [
    {
      value: 'Rente viagère à titre onéreux',
      label: 'Rente viagère à titre onéreux',
    },
    {
      value: 'Rente veuvage',
      label: 'Rente veuvage',
    },
    {
      value: "Rente d'invalidité",
      label: "Rente d'invalidité",
    },
    {
      value:
        "Rentes perçues par les non résidents. Rentes de source étrangère avec crédit d'impôt égal à l'impôt français",
      label:
        "Rentes perçues par les non résidents. Rentes de source étrangère avec crédit d'impôt égal à l'impôt français",
    },
  ]
  export const optionsPRAge = [
    {
      value: 'MOINS50',
      label: 'Moins de 50 ans',
    },
    {
      value: 'DE50A59',
      label: 'De 50 à 59 ans',
    },
    {
      value: "DE60A69",
      label: "De 60 à 69 ans",
    },
    {
      value:
        "PLUS70",
      label:
        "A partie de 70 ans",
    },
    // {
    //   value:
    //     "SANS",
    //   label:
    //     "Sans",
    // },
  ]
  