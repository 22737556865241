import React, { useEffect, useState } from 'react'
import { optionsStatutJob } from '../../../data/options/optionsPP'
import Input from '../Components/Input'
import SelectComponent from '../Components/SelectComponent'
import DateInputComponent from '../Components/DateInputComponent'

const InputsActivity = ({
  handleChange,
  handleChangeDate,
  handleAddJob,
  optionsCSP,
  optionsCodeCSP,
  data,
}) => {
  const { profession, familleCSP, codeRubriqueCSP, status, dateDebut, dateFin } = data || {}
  const [change, setChange] = useState(false)
  useEffect(() => {
    if (
      profession !== undefined &&
      profession !== '' &&
      dateDebut !== undefined &&
      dateDebut !== ''
    ) {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [profession, dateDebut])
  return (
    <div className="informations">
      {/* Profession */}
      <Input
        forAndId="profession"
        errorLabel={profession === ''}
        type="text"
        name="Profession :"
        value={profession}
        onChange={(e) => handleChange(e)}
        placeholder="Boulanger"
        required={true}
        copy={true}
      />
      {/* FamilleCSP */}
      <SelectComponent
        forAndId="familleCSP"
        name="CSP :"
        isClearable={true}
        isSearchable={false}
        options={optionsCSP}
        placeholder="Sélectionner"
        onChange={(e, name) => handleChange(e, name)}
        value={optionsCSP !== undefined && optionsCSP.find((c) => c.value === familleCSP)}
        copy={true}
      />
      {/* CodeCSP */}
      <SelectComponent
        forAndId="codeRubriqueCSP"
        name="Code CSP :"
        isClearable={true}
        isSearchable={false}
        options={optionsCodeCSP}
        placeholder="Sélectionner"
        onChange={(e, name) => handleChange(e, name)}
        value={
          optionsCodeCSP !== undefined && optionsCodeCSP.find((c) => c.value === codeRubriqueCSP)
        }
        copy={true}
      />
      {/* Statut */}
      <SelectComponent
        forAndId="status"
        name="Statut :"
        isSearchable={false}
        options={optionsStatutJob}
        placeholder="Sélectionner"
        onChange={(e, name) => handleChange(e, name)}
        value={optionsStatutJob !== undefined && optionsStatutJob.find((c) => c.value === status)}
        copy={true}
      />
      {/* Date de début */}
      <DateInputComponent
        name={'dateDebut'}
        label={'Date de début :'}
        value={dateDebut}
        handleChange={handleChangeDate}
      />
      {/* Date de fin */}
      <DateInputComponent
        name={'dateFin'}
        label={'Date de fin :'}
        value={dateFin}
        handleChange={handleChangeDate}
      />
      {change ? (
        <div className="informations__buttons">
          <div className="button button--red-red  width--12" onClick={() => handleAddJob()}>
            Enregistrer
          </div>
        </div>
      ) : (
        <div className="informations__buttons">
          <div className="button button--grey width--12">Enregistrer</div>
        </div>
      )}
    </div>
  )
}

export default InputsActivity
