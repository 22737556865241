import React from 'react'
import { useNavigate } from 'react-router-dom'
import Ok from '../../Components/Modales/Ok'

const ModalUpdatePPOk = () => {
  const navigate = useNavigate()
  const handleClose = () => {
    navigate('/user/informations')
  }
  return <Ok text={'Modifications enregistrées'} close={handleClose} />
}

export default ModalUpdatePPOk
