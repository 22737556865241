import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { getBienSuccess } from '../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../redux/actions/actionGetBienType'

const MyInformations = () => {
  const dispatch = useDispatch()

  // remise à zero
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])

  return (
    <section className="section">
      <Outlet />
    </section>
  )
}

export default MyInformations
