import React from 'react'

const ToastFileError = ({ name, response }) => {
  return (
    <div className="toast__bloc">
      <p className="toast__text">
        <span className="toast__text--titleError">Fichier non accepté</span>
      </p>
      <p className="toast__text ">
        Le fichier
        <span className='toast__text--normal'> {name}</span> {response}
      </p>
    </div>
  )
}

export default ToastFileError
