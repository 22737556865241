import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postFinancier } from '../../../../../services/API/financier'
import { postSocial } from '../../../../../services/API/social'
import usePersonData from '../../../../layout/usePersonData'

const useUpdateProductIdCatalogue = (categorie) => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  // responses
  const [status, setStatus] = useState(null)
  // darkList
  const [darkList, setDarkList] = useState([])
  const errorPostFinancier = useSelector((state) => state.getFinancier.error)
  const [errorToDisplay, setErrorToDisplay] = useState(errorPostFinancier)
  useEffect(() => {
    setErrorToDisplay(errorPostFinancier)
  }, [errorPostFinancier])
  // maj idCatalogue
  const postData = (productsToDisplay, cataloguesC) => {
    // array des idCatalogue === 0
    const prodIdCatalogueToUpdate = productsToDisplay.filter((el) => el.idCatalogue === 0)
    for (const prod of prodIdCatalogueToUpdate) {
      const matchCatalogue = cataloguesC.filter((el) => el.libelle === prod.libelle)[0]
      // idCatalogue === 0 => matchCatalogue !== undefined => libellé prod === libellé catalogue && errorToDisplay === ''
      if (matchCatalogue) {
        // pas d'error status postFinancier
        if (errorToDisplay === '') {
          // pas sur la darkList
          if (darkList.filter((el) => el === matchCatalogue).length === 0) {
            const data = {
              ...prod,
              fournisseur: matchCatalogue.etablissement,
              idCatalogue: matchCatalogue.id,
            }
            switch (categorie) {
              case 'AV':
                dispatch(
                  postFinancier('produitFinancierAV', person.id, isPP, true, data, setStatus)
                )
                break
              case 'VM':
                dispatch(
                  postFinancier('produitFinancierVM', person.id, isPP, true, data, setStatus)
                )
                break
              case 'PER':
                dispatch(postSocial('per', person.id, isPP, true, data, setStatus))
                break
              case 'retraite':
                dispatch(postSocial('retraite', person.id, isPP, true, data, setStatus))
                break
              default:
                return
            }
          } else {
            // voir comment gérer la maj idCatalogue  si postFinancier status 500,
            // comment recup le produit qui pose pb et cibler le pb
            console.log("produit sur la darkList, erreur, impossible d'update")
          }
          // error status postFinancier
        } else {
          setDarkList([...darkList, matchCatalogue])
        }
      } else {
        console.log('idCatalogue === 0 et libellé non trouvé, peut-être a-t-il été  modifié')
        console.log(prodIdCatalogueToUpdate)
        return
      }
    }
  }
  const updateData = (productsToDisplay, cataloguesC) => {
    // array des idCatalogue !== 0 && update libelle et/ou fournisseur
    const prodIdOK = productsToDisplay.filter((el) => el.idCatalogue !== 0)
    for (let i = 0; i < prodIdOK.length; i++) {
      const matchCatalogue = cataloguesC.filter(
        (el) =>
          el.id === prodIdOK[i].idCatalogue &&
          (el.libelle !== prodIdOK[i].libelle || el.etablissement !== prodIdOK[i].fournisseur)
      )[0]
      if (matchCatalogue) {
        if (errorToDisplay === '') {
          const data = {
            ...prodIdOK[i],
            fournisseur: matchCatalogue.etablissement,
            libelle: matchCatalogue.libelle,
          }
          switch (categorie) {
            case 'AV':
              dispatch(postFinancier('produitFinancierAV', person.id, isPP, true, data, setStatus))
              break
            case 'VM':
              dispatch(postFinancier('produitFinancierVM', person.id, isPP, true, data, setStatus))
              break
            case 'PER':
              dispatch(postSocial('per', person.id, isPP, true, data, setStatus))
              break
            case 'retraite':
              dispatch(postSocial('retraite', person.id, isPP, true, data, setStatus))
              break
            default:
              return
          }
        }
      }
    }
  }
  //status
  useEffect(() => {
    status && setStatus(null)
  }, [status])

  return { postData, updateData }
}

export default useUpdateProductIdCatalogue
