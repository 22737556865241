import axios from 'axios'
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants'
import {
  getChargesError,
  getChargesLoad,
  getChargesSuccess,
} from '../../redux/actions/Patrimoine/actionGetCharges'

// recupération liste charges
export const getCharges = (id, pp) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getChargesLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/charge/charges?personneId=${id}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getChargesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getChargesError(error.message))
      })
  }
}
// update valeur totale charges
export const postChargesTotalValue = (id, value, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/charge/majChargesValeurTotale?personneId=${id}&montant=${value}&pp=${pp}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getChargesSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// update valeur totale charge
export const postTotalValueCharge = (chargeValueToPost, id, value, pp, divers, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/charge/${chargeValueToPost}?personneId=${id}&montant=${value}&pp=${pp}&divers=${divers}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getChargesSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// post charge
export const postCharge = (chargeToPost, id, pp, divers, data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/charge/${chargeToPost}?personneId=${id}&pp=${pp}&divers=${divers}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(getChargesSuccess(response.data))
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// delete charge
export const deleteCharge = (chargeToDelete, data, idPP, pp, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${API_BASE_URL}/charge/${chargeToDelete}/${data.id}/${idPP}/${pp}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
        dispatch(getCharges(idPP, pp))
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
