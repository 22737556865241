import { standardizeAndLowerCase } from './strings'
import { fetchAddress, fetchCity, fetchCountry, fetchPostcode } from './autocompletion'

/**
 * It takes a string, splits it into an array, and returns the first element of the array.
 * @param string - the string to be split
 * @returns A function that takes a string and returns the first element of the array created by
 * splitting the string on the comma.
 */
export const shortFormeJuridique = (string) => {
  if (string === 'Entrepreneur individuel') {
    return 'EI'
  } else {
    const array = string.split(',')
    return array[0]
  }
}

/**
 * It takes a string, splits it into an array, and returns the first element of the array.
 * @param string - "1 à 2 personnes"
 * @returns The first word of the string.
 */
export const shortEffectif = (string) => {
  const array = string.split(' ')
  return array[0]
}

/**
 * The filter() method creates a new array with all elements that pass the test implemented by the
 * provided function.
 * @param array - the array to be filtered
 * @returns [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
 */
export const noTwice = (array) => {
  return array.filter((item, index) => array.indexOf(item) === index)
}

/**
 * If the options parameter is not undefined, then find the group that contains the typeGed value, then
 * find the value in that group.
 * @param options - [{
 * @param typeGed - '1'
 * @returns The value of the option that matches the typeGed value.
 */
export const getValue = (options, typeGed) => {
  let value
  if (typeGed && typeGed !== undefined && options !== undefined) {
    const group = options.find((c) =>
      c.options && c.options !== undefined
        ? c.options.find((val) => val.value === typeGed)
        : 'non défini'
    )
    if (group !== undefined) {
      value =
        group.options && group.options !== undefined
          ? group.options.find((c) => c.value === typeGed)
          : 'non défini'
    } else {
      value = { value: 'Type de fichier non reconnu', label: 'Type de fichier non reconnu' }
    }
    return value
  }
  return
}

/**
 * It takes an array of objects, and returns an array of strings, where each string is the
 * concatenation of the values of each object, with each value standardized and lowercased.
 * @param array - [{id: 1, name: 'John', lastName: 'Doe'}, {id: 2, name: 'Jane', lastName: 'Doe'}]
 * @returns An array of strings.
 */
export const concat = (array) => {
  const newArr = array.map((el) => {
    const valuesWithoutId = { ...el }
    delete valuesWithoutId.id
    const values = Object.values(valuesWithoutId)
    const newValues = values.map((el) =>
      typeof el === 'string' ? standardizeAndLowerCase(el) : el
    )
    const concat = newValues.join()
    return concat
  })
  return newArr
}

/**
 * It takes a string and an array of objects, and returns the label of the object whose value is the
 * string.
 * @param string - 'value1'
 * @param array - [{value: '1', label: 'One'}, {value: '2', label: 'Two'}, {value: '3', label:
 * 'Three'}]
 * @returns The label of the object in the array that has the value of the string.
 */
export const recupLabel = (string, array) => {
  return array.find((e) => e.value === string).label
}
/**
 * It takes a string and an array of objects, and returns the label of the object whose value.name
 * property is equal to the string.
 * @param string - "A"
 * @param array - [{label: 'label1', value: {name: 'name1'}}, {label: 'label2', value: {name:
 * 'name2'}}]
 * @returns The label of the object in the array that has a value.name that matches the string.
 */
export const recupLabelComplex = (string, array) => {
  return array.find((e) => e.value.name === string).label
}
export const recupLabelComplexWithOptions = (string, array) => {
  for (const group of array) {
    for (const option of group.options) {
      if (option.value === string) {
        return option.label
      }
    }
  }
  return null
}
/**
 * It takes a string and an array of objects as arguments and returns the object in the array that has
 * a property with the same value as the string.
 * @param string - "test@test.com"
 * @param array - [{mail: "test@test.com", name: "test"}, {mail: "test2@test.com", name: "test2"}]
 * @returns The first element in the array that has a mail property that matches the string.
 */
export const recupReferentPPUser = (string, array) => {
  return array.find((e) => e.mail === string)
}

/**
 * It takes an event, a selectName, a state, and a setState function, and then sets the state based on
 * the event or selectName.
 * @param e - the event object
 * @param selectName - the name of the select element
 * @param state - the state object
 * @param setState - the setState function from the component
 */
export const handleChange = (e, selectName, state, setState) => {
  if (e.target !== undefined) {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  } else {
    const name = selectName.name
    const value = e.value
    setState({ ...state, [name]: value })
  }
}
/**
 * It's a function that takes a lot of parameters and does a lot of things.
 * @param e - the event
 * @param setDataChange - a boolean to know if the data has changed
 * @param data - the data object that contains all the fields of the form
 * @param setData - is a function that sets the state of the data object
 * @param setAddressResults - setState for the address results
 * @param setToggleAddress - boolean
 * @param setPostcodeResults - setState for the postcode results
 * @param setToggleCode - boolean
 * @param setCityResults - setState for the city results
 * @param setToggleCity - boolean
 * @param setCountryResults - setState for the country results
 * @param setToggleCountry - a boolean to show/hide the dropdown
 * @returns the value of the switch statement.
 */
export const handleChangeAddress = (
  e,
  data,
  setData,
  setAddressResults,
  setToggleAddress,
  setPostcodeResults,
  setToggleCode,
  setCityResults,
  setToggleCity,
  setCountryResults,
  setToggleCountry
) => {
  const { name, value } = e.target
  setData({ ...data, [name]: value })

  switch (name) {
    case 'ad1':
      if (value.trim()?.length >= 3) {
        data.codePostal
          ? fetchAddress(value, data.codePostal, setAddressResults)
          : fetchAddress(value, '', setAddressResults)
        setToggleAddress(true)
      }
      break
    case 'adresse1':
      if (value.trim()?.length >= 3) {
        data.codePostal
          ? fetchAddress(value, data.codePostal, setAddressResults)
          : fetchAddress(value, '', setAddressResults)
        setToggleAddress(true)
      }
      break
    case 'adresse_ligne_1':
      if (value.trim()?.length >= 3) {
        data.code_postal
          ? fetchAddress(value, data.code_postal, setAddressResults)
          : fetchAddress(value, '', setAddressResults)
        setToggleAddress(true)
      }
      break
    case 'libelle':
      if (value.trim()?.length >= 3) {
        data.cp
          ? fetchAddress(value, data.cp, setAddressResults)
          : fetchAddress(value, '', setAddressResults)
        setToggleAddress(true)
      }
      break
    case 'adresseCabinet':
      if (value.trim()?.length >= 3) {
        data.cp
          ? fetchAddress(value, data.cp, setAddressResults)
          : fetchAddress(value, '', setAddressResults)
        setToggleAddress(true)
      }
      break
    case 'adresse':
      if (value.trim()?.length >= 3) {
        data.codePostal
          ? fetchAddress(value, data.codePostal, setAddressResults)
          : fetchAddress(value, '', setAddressResults)
        setToggleAddress(true)
      }
      break
    case 'codePostal':
      if (value.trim()?.length >= 1) {
        fetchPostcode(value, setPostcodeResults)
        setToggleCode(true)
      }
      break
    case 'code_postal':
      if (value.trim()?.length >= 1) {
        fetchPostcode(value, setPostcodeResults)
        setToggleCode(true)
      }
      break
    case 'cp':
      if (value.trim()?.length >= 1) {
        fetchPostcode(value, setPostcodeResults)
        setToggleCode(true)
      }
      break
    case 'cpCabinet':
      if (value.trim()?.length >= 1) {
        fetchPostcode(value, setPostcodeResults)
        setToggleCode(true)
      }
      break
    case 'ville':
      if (value.trim()?.length >= 1) {
        fetchCity(value, setCityResults)
        setToggleCity(true)
      }
      break
    case 'villeCabinet':
      if (value.trim()?.length >= 1) {
        fetchCity(value, setCityResults)
        setToggleCity(true)
      }
      break
    case 'villeNaissance':
      if (value.trim()?.length >= 1) {
        fetchCity(value, setCityResults)
        setToggleCity(true)
      }
      break
    case 'pays':
      if (value.trim()?.length >= 1) {
        setData({ ...data, pays: value && value.toUpperCase() })
        fetchCountry(value, setCountryResults)
        setToggleCountry(true)
      }
      break
    default:
      return
  }
}
/**
 * It takes a number and a string and returns a number.
 * @param montant - the amount of the contract
 * @param periodicite - 'ANNUEL' | 'TRIMESTRIEL' | 'MENSUEL' | 'JOURNALIER'
 * @returns A function that takes two arguments, montant and periodicite.
 */
export const calculateAnnualAmount = (montant, periodicite) => {
  switch (periodicite) {
    case 'ANNUEL':
      return montant
    case 'TRIMESTRIEL':
      return montant * 4
    case 'MENSUEL':
      return montant * 12
    case 'JOURNALIER':
      const date = new Date()
      const year = date.getFullYear()
      if ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) {
        return montant * 366
      } else {
        return montant * 365
      }
    case 'UNIQUE':
      return montant
    default:
      return
  }
}
/**
 * It takes a number and a string and returns a number.
 * @param montant - the amount of the contract
 * @param periodicite - ANNUEL, TRIMESTRIEL, MENSUEL, JOURNALIER
 * @returns A function that takes two arguments, montant and periodicite.
 */
export const calculateAmountReverse = (montant, periodicite) => {
  switch (periodicite) {
    case 'ANNUEL':
      return montant
    case 'TRIMESTRIEL':
      return montant / 4
    case 'MENSUEL':
      return montant / 12
    case 'JOURNALIER':
      const date = new Date()
      const year = date.getFullYear()
      if ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) {
        return montant / 366
      } else {
        return montant / 365
      }
    case 'UNIQUE':
      return montant
    default:
      return
  }
}
export const calculateAmountReverseBis = (montant, periodicite) => {
  switch (periodicite) {
    case 'ANNUEL':
      return montant
    case 'TRIMESTRIEL':
      return montant * 4
    case 'MENSUEL':
      return montant * 12
    case 'JOURNALIER':
      const date = new Date()
      const year = date.getFullYear()
      if ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) {
        return montant * 366
      } else {
        return montant * 365
      }
    case 'UNIQUE':
      return montant
    default:
      return
  }
}

/**
 * The function takes an array of objects, extracts their values, concatenates them into a string
 * separated by commas, and returns the resulting string.
 * @param array - An array of objects.
 * @returns The function `concatValuesSupportsP` is returning a string that contains all the values of
 * the objects in the input array concatenated together, separated by commas. The values are obtained
 * using the `Object.values()` method, which returns an array of the object's values. The `map()`
 * method is used to apply this to each object in the input array, and then the `join()` method is
 */
export const concatValuesSupportsP = (array) => {
  return array.map((obj) => Object.values(obj)).join(',')
}

// supprId
/**
 * The function `supprId` takes an object as input and returns a new object with the same properties as
 * the input object, but with the `id` property set to 0.
 * @param obj - The `obj` parameter is an object that will be passed to the `supprId` function.
 * @returns a new object that is a copy of the input object `obj`, but with the `id` property set to 0.
 */
export const supprId = (obj) => {
  return { ...obj, id: 0 }
}

/**
 * The `removeDuplicates` function takes an array and a property name as parameters, and returns a new
 * array with duplicate objects removed based on the specified property.
 * @param arr - The `arr` parameter is an array of objects.
 * @param property - The `property` parameter is a string that represents the property of each object
 * in the `arr` array that will be used to determine if there are duplicates.
 * @returns The function `removeDuplicates` returns a new array with duplicate objects removed based on
 * a specified property.
 */
export const removeDuplicates = (arr, property) => {
  return arr.reduce((accumulator, current) => {
    const existingItem = accumulator.find((item) => item[property] === current[property])
    if (!existingItem) {
      accumulator.push(current)
    }
    return accumulator
  }, [])
}
