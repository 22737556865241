import React, { useMemo, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { allLinks } from '../../../data/userLinks'
import BackToTheTop from '../../layout/BackToTheTop'
import Header from '../../layout/Header'
import Nav from '../../layout/Nav'
import Toast from '../../components/Components/Toast'
import usePersonData from '../../layout/usePersonData'

const User = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { person, personType } = usePersonData()
  const user = useSelector((state) => state.getUser.user)
  const persons = useSelector((state) => state.getPersons.persons)
  // links
  const links =
  personType === 'referentPP'
      ? allLinks('referentPP')
      : personType === 'relationPP'
      ? allLinks('relationPP')
      : allLinks('dirigeantPM')
  // affichage user-personne
  useEffect(() => {
    if (
      person === null &&
      persons &&
      persons.referentPP[0] !== undefined &&
      (pathname !== '/user/comptes/ajouter-personne-physique' ||
        pathname !== '/user/comptes/ajouter-personne-morale')
    ) {
      dispatch(getPerson(persons.referentPP[0]))
      dispatch(postPersonType('referentPP'))
    }
  }, [person, persons, pathname])

  let linksToDisplay = useMemo(() => (!person ? links.slice(0, 4) : links), [person])
  // toastId
  const toastId = useRef(null)

  return user ? (
    <div>
      <Header links={linksToDisplay} />
      <main className="main">
        <Nav links={linksToDisplay} />
        <BackToTheTop />
        <Outlet />
        <Toast toastId={toastId} position={'bottom-center'} duration={2000} />
      </main>
    </div>
  ) : (
    <Navigate to={'/'} replace />
  )
}

export default User
