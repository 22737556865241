import React from 'react'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'
import DateInputComponent from '../../../../Components/DateInputComponent'
import SelectComponent from '../../../../Components/SelectComponent'
import { optionsDetention } from '../../../../../../data/options/options'

const GeneralInformationCard = ({ handleChange, handleChangeDate, information }) => {
  const {
    montantEmprunte,
    montantRestant,
    mensualite,
    dureeMois,
    refEmprunt,
    dateDebut,
    dateFin,
    detention,
    tauxIndivision,
  } = information

  return (
    <Card title="Informations générales" h3={true} open={true}>
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* refEmprunt */}
          <Input
            forAndId="refEmprunt"
            errorLabel={refEmprunt === ''}
            type="text"
            name="Référence de l'emprunt :"
            value={refEmprunt}
            onChange={(e) => handleChange(e)}
            placeholder="Libellé"
            copy={true}
            required={true}
          />
          {/* montantEmprunte */}
          <Input
            forAndId="montantEmprunte"
            errorLabel={montantEmprunte === '' || montantEmprunte === 0}
            type="number"
            name="Montant emprunté :"
            value={montantEmprunte}
            onChange={(e) => handleChange(e)}
            placeholder={10000}
            copy={true}
            required={true}
          />
          {/* dureeMois */}
          <Input
            forAndId="dureeMois"
            errorLabel={dureeMois === '' || dureeMois === 0}
            type="number"
            name="Durée (mois) :"
            value={dureeMois}
            onChange={(e) => handleChange(e)}
            placeholder={12}
            copy={true}
            required={true}
          />
          {/* mensualite */}
          <Input
            forAndId="mensualite"
            errorLabel={mensualite === '' || mensualite === 0}
            type="number"
            name="Mensualité :"
            value={mensualite}
            onChange={(e) => handleChange(e)}
            placeholder={999}
            copy={true}
            disabled={true}
            required={true}
            step={0.01}
          />
          {/* dateDebut */}
          <DateInputComponent
            name={'dateDebut'}
            label={'Permière mensualité :'}
            value={dateDebut}
            handleChange={handleChangeDate}
          />
          {/* dateFin */}
          <DateInputComponent
            name={'dateFin'}
            label={'Dernière mensualité :'}
            value={dateFin}
            handleChange={handleChangeDate}
            disabled={true}
          />
          {/* montantRestant */}
          <Input
            forAndId="montantRestant"
            type="number"
            name="Montant restant dû :"
            value={montantRestant}
            onChange={(e) => handleChange(e)}
            copy={true}
            disabled={true}
          />
        </div>
        {/* detention */}
        <SelectComponent
          forAndId="detention"
          errorLabel={detention === ''}
          name="Détention :"
          isSearchable={false}
          options={optionsDetention}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsDetention.find((c) => c.value === detention)}
          required={true}
          copy={true}
        />
        {/* tauxIndivision */}
        <Input
          forAndId="tauxIndivision"
          type="number"
          name="Taux d'indivision :"
          value={tauxIndivision}
          onChange={(e) => handleChange(e)}
          copy={true}
        />
      </div>
    </Card>
  )
}

export default GeneralInformationCard
