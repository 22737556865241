// IMPORTS // ______________________________________________________________
import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions reset
 * @reduxAction
 */

export const resetStore = createAction('resetStore')