import React from 'react'
import Modale from '../Components/Modale'

const ModalAddFirmNotOk = ({ setModalNotOk, setModalAddFirm, setStatus }) => {
  const handleClick = () => {
    setModalNotOk(false)
    setModalAddFirm(false)
  }
  const handleRetry = () => {
    setStatus(null)
    setModalNotOk(false)
  }

  return (
    <Modale
      close={() => handleClick()}
      title="Erreur !"
      line1="Un problème est survenu"
    >
      <div className="button button--blue-red width--12" onClick={() => handleRetry()}>
        Modifier
      </div>
      <div className="button button--blue-red width--12" onClick={() => handleClick()}>
        Abandonner
      </div>
    </Modale>
  )
}

export default ModalAddFirmNotOk
