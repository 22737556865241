import React, { useEffect, useState } from 'react'
import { optionsTrueOrFalse } from '../../../data/options/options'
import Input from '../Components/Input'
import Modale from '../Components/Modale'
import SelectComponent from '../Components/SelectComponent'

const InputsSignataire = ({
  setModalUpdate,
  handleChange,
  validate,
  notCorrect,
  updated,
  setUpdated,
  newData,
  newTelPortable,
  update,
  title,
  isARelation,
}) => {
  const { nom, prenom, mail, telPortable, fonction } = newData || {}
  const [change, setChange] = useState(false)
  useEffect(() => {
    if (
      update
        ? newTelPortable !== undefined && newTelPortable !== ''
        : nom !== undefined &&
          nom !== '' &&
          mail !== undefined &&
          mail !== '' &&
          telPortable !== undefined &&
          telPortable !== ''
    ) {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [nom, mail, telPortable, newTelPortable])
  return (
    <Modale
      close={() => setModalUpdate(false)}
      title={title}
    >
      <div className="informations">
        <div className="informations informations--borderFull">
          {/* Nom */}
          <Input
            forAndId="nom"
            errorLabel={nom === ''}
            type="text"
            name="Nom  :"
            value={nom}
            disabled={update ? true : false}
            onChange={(e) => handleChange(e)}
            required={true}
            copy={true}
          />
          {/* prenom */}
          <Input
            forAndId="prenom"
            errorLabel={prenom === ''}
            type="text"
            name="Prénom  :"
            value={prenom}
            disabled={update ? true : false}
            onChange={(e) => handleChange(e)}
            required={true}
            copy={true}
          />

          {/* Mail */}
          <Input
            forAndId="mail"
            errorLabel={mail === ''}
            type="mail"
            name="Mail :"
            value={mail}
            disabled={update ? true : false}
            onChange={(e) => handleChange(e)}
            required={true}
            copy={true}
            placeholder="exemple@gmail.com"
          />
          {/* Tel Mobile */}
          <Input
            forAndId="telPortable"
            errorLabel={
              update
                ? newTelPortable !== undefined && newTelPortable === ''
                : telPortable !== undefined && telPortable === ''
            }
            type="tel"
            pattern="[0-9]{10,12}"
            maxLength={12}
            minLength={10}
            name="Mobile :"
            value={update ? newTelPortable : telPortable}
            onChange={(e) => handleChange(e)}
            placeholder="0655443322"
            bold={update ? true : false}
            required={true}
            copy={true}
          />
        </div>
        {/* Fonction */}
        {!update && (
          <Input
            forAndId="fonction"
            type="text"
            name="Fonction  :"
            value={fonction}
            onChange={(e) => handleChange(e)}
            copy={true}
          />
        )}
        {/* Modifier personne */}
        {update && !isARelation && (
          <SelectComponent
            forAndId="updatePerson"
            name="Mettre à jour :"
            isSearchable={false}
            options={optionsTrueOrFalse}
            placeholder="Sélectionner"
            onChange={() => setUpdated(!updated)}
            value={optionsTrueOrFalse.find((c) => c.value === updated)}
            bold={true}
            required={true}
            copy={true}
          />
        )}
        {notCorrect &&
          (update ? (
            <p className="informations__error">
              Veuillez saisir le numéro de téléphone correctement
            </p>
          ) : (
            <p className="informations__error">Veuillez remplir tous les champs correctement</p>
          ))}
        {change ? (
          <div className="informations__buttons">
            <div className="button button--red-red width--12" onClick={() => validate()}>
              Enregistrer
            </div>
            <div
              className="button button--blue-red width--12"
              onClick={() => setModalUpdate(false)}
            >
              Annuler
            </div>
          </div>
        ) : (
          <div className="informations__buttons">
            <div className="button button--grey width--12">Enregistrer</div>
            <div
              className="button button--blue-red width--12"
              onClick={() => setModalUpdate(false)}
            >
              Annuler
            </div>
          </div>
        )}
      </div>
    </Modale>
  )
}

export default InputsSignataire
