import React from 'react'
import { AiFillFileZip } from 'react-icons/ai'
import { beautifulDate } from '../../../../services/helpers/dates'

const BodySignPending = ({ element, handleFetch }) => {
  return (
    <>
      {/* recupérer */}
      <td className="tbody__tr__td" onClick={() => handleFetch(element)}>
        <div className="tbody__tr__td__button">
          <AiFillFileZip />
        </div>
      </td>
      {/* fichier */}
      <td className="tbody__tr__td">
        {!element.fichier_nom ? (
          <p className="informations__line__notxt">non défini</p>
        ) : (
          element.fichier_nom
        )}
      </td>
      {/* date signature */}
      <td className="tbody__tr__td">
        {!element.dateSignature ? (
          <p className="informations__line__notxt">non défini</p>
        ) : (
          beautifulDate(element.dateSignature)
        )}
      </td>
      {/* typeTransaction */}
      <td className="tbody__tr__td">
        {!element.typeTransaction ? (
          <p className="informations__line__notxt">non défini</p>
        ) : (
          element.typeTransaction
        )}
      </td>
    </>
  )
}

export default BodySignPending
