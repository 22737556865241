// IMPORTS // ______________________________________________________________
import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions formPMIsActive
 * @reduxAction
 */

export const copyValue = createAction('copyValue')