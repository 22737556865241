import React from 'react'
import Card from '../../../../Components/Card'
import Input from '../../../../Components/Input'

const PartsInformationsCardBienPro = ({ handleChange, information }) => {
  const { nbPartsTotales, montantPart, montantCapitalSocial, nbPartsDetenus, montantDetenu } =
    information

  return (
    <Card title={'Capital'} h4={true} open={true}>
      <div className="informations">
        {/* nbPartsTotales */}
        <Input
          forAndId="nbPartsTotales"
          type="text"
          name="Nombre total de parts :"
          value={nbPartsTotales}
          onChange={(e) => handleChange(e)}
          inputMode="decimal"
          copy={true}
        />
        {/* montantPart */}
        <Input
          forAndId="montantPart"
          type="text"
          name="Montant de la part :"
          value={montantPart}
          onChange={(e) => handleChange(e)}
          copy={true}
          inputMode="decimal"
        />
        {/* montantCapitalSocial */}
        <Input
          forAndId="montantCapitalSocial"
          type="text"
          name="Montant du Capital Social :"
          value={montantCapitalSocial}
          onChange={(e) => handleChange(e)}
          copy={true}
          inputMode="decimal"
        />
        {/* nbPartsDetenus */}
        <Input
          forAndId="nbPartsDetenus"
          type="text"
          name="Nombre de parts détenues :"
          value={nbPartsDetenus}
          onChange={(e) => handleChange(e)}
          copy={true}
          inputMode="decimal"
        />
        {/* montantDetenu */}
        <Input
          forAndId="montantDetenu"
          type="text"
          name="Montant détenu :"
          value={montantDetenu}
          onChange={(e) => handleChange(e)}
          copy={true}
          inputMode="decimal"
        />
      </div>
    </Card>
  )
}

export default PartsInformationsCardBienPro
