import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../services/helpers/detenteurs'
import { postActif } from '../../../../../../services/API/actifs'
import { supprId } from '../../../../../../services/helpers/helpers'
import ModaleDivers from '../../../Patrimoine/ModaleDivers'
import FormPatrimoineProContent from './FormPatrimoineProContent'
import NotCorrect from '../../../../Components/Modales/NotCorrect'
import Ok from '../../../../Components/Modales/Ok'
import NotOk from '../../../../Components/Modales/NotOk'
import { DOC_BIEN_PRO } from '../../../../../../constants'
import ModaleFiles from '../../../../Files/ModaleFiles'
import usePersonData from '../../../../../layout/usePersonData'

const FormPatrimoinePro = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const properties = useSelector((state) => state.getProperties.properties)
  const actifsDivers = useMemo(
    () => (properties ? properties.actifsDivers.montant : null),
    [properties]
  )
  const professionnelDivers = useMemo(
    () => (properties ? properties.actifsBienProfessionnel.bienProDivers.montant : null),
    [properties]
  )
  const bien = useSelector((state) => state.getBien.bien)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddPatrimoine, setToggleAddPatrimoine] = useState(false)
  const [toggleAddBienOk, setToggleAddBienOk] = useState(false)
  const [toggleAddBienNotOk, setToggleAddBienNotOk] = useState(false)
  const [toggleAddNotCorrect, setToggleNotCorrect] = useState(false)
  const [toggleDivers, setToggleDivers] = useState(false)
  // maj bien
  useEffect(() => {
    bien && setFichiersToDisplay(bien.fichiers)
  }, [bien])
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    typeBienPro: '',
    nom: '',
    nature: '',
    valeur: 0,
    dateValeur: '',
    risque: 'SECURITAIRE',
    denominationSociale: '',
    formeJuridique: '',
    siret: undefined,
    adresse: '',
    codePostal: undefined,
    ville: '',
    lienPM: false,
    idPM: 0,
    nbPartsTotales: 0,
    montantPart: 0,
    montantCapitalSocial: 0,
    nbPartsDetenus: 0,
    montantDetenu: 0,
    dateCreation: '',
    valeurAcquisition: 0,
    closOuVendu: false,
    valeurVente: 0,
    dateVente: '',
    detention: 'CLIENT',
    tauxIndivision: 100,
    detenteurs: [],
    fichiers: [],
    typeActif: '',
    idActif: 0,
    codeImpot: '',
  })
  const {
    id,
    typeBienPro,
    nom,
    nature,
    denominationSociale,
    formeJuridique,
    siret,
    valeur,
    dateValeur,
    risque,
    adresse,
    codePostal,
    ville,
    lienPM,
    idPM,
    nbPartsTotales,
    montantPart,
    montantCapitalSocial,
    nbPartsDetenus,
    montantDetenu,
    dateCreation,
    valeurAcquisition,
    closOuVendu,
    valeurVente,
    dateVente,
    detention,
    tauxIndivision,
    detenteurs,
    fichiers,
    typeActif,
    idActif,
    codeImpot,
  } = information || {}
  //
  const [divers, setDivers] = useState(true)
  const [fichiersToDisplay, setFichiersToDisplay] = useState([])
  const [detenteursToDisplay, setDetenteursToDisplay] = useState([])
  const [newValeurTotale, setNewValeurTotale] = useState()
  // valeur totale de calcul
  useEffect(() => {
    closOuVendu ? setNewValeurTotale(0) : setNewValeurTotale((valeur * tauxIndivision) / 100)
  }, [valeur, tauxIndivision, closOuVendu])
  // maj si bien
  useEffect(() => {
    bien
      ? setInformation({
          ...information,
          id: bien.id,
          typeBienPro: bien.typeBienPro,
          nom: bien.nom,
          nature: bien.nature,
          denominationSociale: bien.denominationSociale,
          formeJuridique: bien.formeJuridique,
          siret: bien.siret,
          valeur: bien.valeur,
          dateValeur: bien.dateValeur ? bien.dateValeur : '',
          risque: bien.risque,
          adresse: bien.adresse,
          codePostal: bien.codePostal,
          ville: bien.ville,
          lienPM: bien.lienPM,
          idPM: bien.idPM,
          nbPartsTotales: bien.nbPartsTotales,
          montantPart: bien.montantPart,
          montantCapitalSocial: bien.montantCapitalSocial,
          nbPartsDetenus: bien.nbPartsDetenus,
          montantDetenu: bien.montantDetenu,
          closOuVendu: bien.closOuVendu,
          valeurVente: bien.valeurVente,
          dateVente: bien.dateVente,
          detention: bien.detention,
          tauxIndivision: bien.tauxIndivision,
          dateCreation: bien.dateCreation ? bien.dateCreation : '',
          valeurAcquisition: bien.valeurAcquisition,
          detenteurs: bien.detenteurs
            .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
            .reverse(),
          fichiers: bien.fichiers,
          typeActif: bien.typeActif,
          idActif: bien.idActif,
          codeImpot: bien.codeImpot,
        })
      : setInformation({
          ...information,
          detenteurs: [
            {
              id: 0,
              nomDetenteur:
                personType === 'referentPP'
                  ? `${person.nom} ${person.prenom}`
                  : `${person.denominationSociale}`,
              mailDetenteur: person.mail,
              idPP: personType === 'referentPP' ? 1 : 0,
              idPM: personType === 'dirigeantPM' ? 1 : 0,
              dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
              dateContitutionUsufruit: '',
              dureeDemembrement: '',
              typeDetention: 'PLEINEPROPRIETE',
              tauxPleinePropriete: 100,
              tauxUsufruit: 0,
              tauxUsufruitCalcule: handleCalculateTaux(person.dateNaissance),
              tauxNuePropriete: 0,
              valeurDetenue: 0,
              valeurPP: 0,
              valeurUS: 0,
              valeurNP: 0,
              tempId: Date.now(),
            },
          ],
        })
  }, [])
  // maj
  useEffect(() => {
    if (properties && bien) {
      const newBien = properties.actifsBienProfessionnel.biensProfessionnels.filter(
        (el) => el.id === bien.id
      )
      dispatch(getBienSuccess(newBien[0]))
    }
  }, [properties])
  // form Bien Pro is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Bien Pro' }))
    }
  }, [dataChange])
  // save Bien Pro
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Bien Pro') {
      handleSubmit()
    }
  }, [saveBien])
  // Object to send
  const data = {
    id: id,
    typeBienPro: typeBienPro,
    nom: nom,
    nature: nature,
    valeur: valeur,
    dateValeur: dateValeur,
    risque: risque,
    denominationSociale: denominationSociale,
    formeJuridique: formeJuridique,
    siret: siret,
    adresse: adresse,
    codePostal: codePostal,
    ville: ville,
    lienPM: lienPM,
    idPM: idPM,
    nbPartsTotales: nbPartsTotales,
    montantPart: parseFloat(montantPart),
    montantCapitalSocial: montantCapitalSocial,
    nbPartsDetenus: parseFloat(nbPartsDetenus),
    montantDetenu: parseFloat(montantDetenu),
    dateCreation: dateCreation,
    valeurAcquisition: valeurAcquisition,
    closOuVendu: closOuVendu,
    valeurVente: valeurVente,
    dateVente: dateVente,
    detention: detention,
    tauxIndivision: tauxIndivision,
    detenteurs: detenteursToDisplay.map((det) =>
      updateDetenteurs(det, newValeurTotale, detenteursToDisplay)
    ),
    fichiers: fichiers,
    typeActif: typeActif,
    idActif: idActif,
    codeImpot: codeImpot,
  }
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      switch (name) {
        case 'nbPartsTotales':
          setInformation({
            ...information,
            montantCapitalSocial: Math.round(value * information.montantPart * 100) / 100,
            [name]: value,
          })
          break
        case 'montantPart':
          setInformation({
            ...information,
            montantCapitalSocial: Math.round(value * information.nbPartsTotales * 100) / 100,
            montantDetenu: Math.round(information.nbPartsDetenus * value * 100) / 100,
            [name]: value,
          })
          break
        case 'nbPartsDetenus':
          setInformation({
            ...information,
            montantDetenu: Math.round(value * information.montantPart * 100) / 100,
            [name]: value,
          })
          break

        default:
          setInformation({ ...information, [name]: value })
      }
    } else {
      const name = selectName.name
      const value = e.value
      switch (name) {
        case 'divers':
          setDivers(value)
          break
        case 'closOuVendu':
          if (value) {
            setInformation({ ...information, [name]: value })
          } else {
            setInformation({ ...information, [name]: value, valeurVente: 0, dateVente: '' })
          }
          break
        default:
          setInformation({ ...information, [name]: value })
      }
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setInformation({ ...information, [name]: date })
  }

  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (typeBienPro !== '' && nom !== '' && nature !== '' && valeur !== '') {
      setDetenteursToDisplay(
        detenteursToDisplay.map((det) => (det.newDetenteur ? supprId(det) : det))
      )
      actifsDivers === 0 && professionnelDivers === 0 ? handleSubmit() : setToggleDivers(true)
    } else {
      setToggleNotCorrect(true)
    }
  }
  // submit
  const handleSubmit = () => {
    dispatch(postActif('bienPro', person.id, isPP, divers, data, setStatus))
    setToggleDivers(false)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (bien && bien.id !== 0) {
      navigate('/user/patrimoine/actifs/professionnel/bien')
    } else {
      navigate('/user/patrimoine/actifs/professionnel')
    }
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setStatus(null)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
      setToggleAddBienOk(true)
    }
    if (status >= 400) {
      setStatus(null)
      setToggleAddBienNotOk(true)
    }
  }, [status])
  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setToggleAddBienOk(false)
    navigate('/user/patrimoine/actifs/professionnel')
  }

  return (
    <div className="section__content">
      {/* form */}
      <FormPatrimoineProContent
        bienToDisplay={bien}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        information={information}
        setInformation={setInformation}
        divers={divers}
        detenteursToDisplay={detenteursToDisplay}
        setDetenteursToDisplay={setDetenteursToDisplay}
        fichiersToDisplay={fichiersToDisplay}
        setFichiersToDisplay={setFichiersToDisplay}
        setToggleAddPatrimoine={setToggleAddPatrimoine}
        handleSubmit={handlePreSubmit}
        newValeurTotale={newValeurTotale}
        handleGiveUp={handleGiveUp}
      />
      {/* modales */}
      {toggleAddPatrimoine && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_BIEN_PRO}
          setModal={setToggleAddPatrimoine}
          setDataChange={setDataChange}
        />
      )}
      {toggleAddBienOk && (
        <Ok
          text={
            bien ? 'Votre bien a été modifié avec succès' : 'Votre bien a été ajouté avec succès'
          }
          close={closeToggleAddBien}
        />
      )}
      {toggleAddBienNotOk && (
        <NotOk setNotOK={setToggleAddBienNotOk} closeModale={closeToggleAddBien} />
      )}
      {toggleAddNotCorrect && <NotCorrect setNotCorrect={setToggleNotCorrect} />}
      {toggleDivers && (
        <ModaleDivers
          setToggleDivers={setToggleDivers}
          handleChange={handleChange}
          divers={divers}
          setDivers={setDivers}
          handleSubmit={handleSubmit}
          montantCategorieDivers={actifsDivers}
          montantSousCategorieDivers={professionnelDivers}
          type={'actif'}
          sousType={'autres'}
        />
      )}
    </div>
  )
}

export default FormPatrimoinePro
