import React from 'react'
import { COLORS } from '../../../../../constants'
import PieChartComponent from '../../../Charts/PieChartComponent'
import LegendPieChart from '../../../Charts/LegendPieChart'

const RevenusPieChart = ({ incomes, detenteurs, print }) => {
  const {
    valeurTotaleRevenusProfessionnels,
    valeurTotaleRevenusMobiliers,
    valeurTotalePensionsRentes,
    valeurTotaleAutresRevenus,
    valeurTotaleRevenusFonciers,
    revenuDivers,
    valeurTotaleRevenu,
    valeurTotaleRevenusDetenue,
  } = incomes || {}
  // data
  const data = !detenteurs
    ? [
        {
          name: 'Revenus fonciers',
          value: valeurTotaleRevenusFonciers,
          fill: COLORS.colorRepartition01,
          color: 'repartition01',
        },
        {
          name: 'Revenus mobiliers',
          value: valeurTotaleRevenusMobiliers,
          fill: COLORS.colorRepartition02,
          color: 'repartition02',
        },
        {
          name: 'Revenus professionnels',
          value: valeurTotaleRevenusProfessionnels,
          fill: COLORS.colorRepartition03,
          color: 'repartition03',
        },
        {
          name: 'Pensions & Rentes',
          value: valeurTotalePensionsRentes,
          fill: COLORS.colorRepartition04,
          color: 'repartition04',
        },
        {
          name: 'Autres revenus',
          value: valeurTotaleAutresRevenus,
          fill: COLORS.colorRepartition05,
          color: 'repartition05',
        },
        {
          name: 'Revenus non affectés',
          value: revenuDivers.montant,
          fill: COLORS.colorFontLight,
          color: 'divers',
        },
      ]
    : [
        {
          name: "Détenu par l'utilisateur",
          value: valeurTotaleRevenusDetenue,
          fill: COLORS.colorSecondary,
          color: 'detenu',
        },
        {
          name: "Non détenu par l'utilisateur",
          value: valeurTotaleRevenu - valeurTotaleRevenusDetenue - revenuDivers.montant,
          fill: COLORS.colorPrimary,
          color: 'nonDetenu',
        },
        {
          name: 'Revenus non affectés',
          value: revenuDivers.montant,
          fill: COLORS.colorFontLight,
          color: 'divers',
        },
      ]
  // title
  const title = !detenteurs ? 'Répartition' : 'Détention'
  return (
    <>
      <PieChartComponent data={data} title={title} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title} />
    </>
  )
}

export default RevenusPieChart
