import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { sortByType } from '../../../../services/helpers/sort'
import { getFile } from '../../../../services/helpers/files'
import TableBodyBloc from '../../table/TableBodyBloc'
import BodySignToClassify from './BodySignToClassify'
import BodySignToClassifyPrint from './BodySignToClassifyPrint'

const TableBodySignToClassify = ({
  setModalUpdate,
  setNewData,
  targetToSort,
  sort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  setShowDoc,
  print,
}) => {
  const dispatch = useDispatch()
  // voir
  const handleShow = (element) => {
    dispatch(getFile(element))
    setShowDoc(true)
  }
  // classer
  const handleClassify = (element) => {
    setModalUpdate(true)
    setNewData(element)
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Fichier':
          giveAType('fileName')
          break
        case 'Créé le':
          giveAType('createdAt')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={
        print ? (
          <BodySignToClassifyPrint />
        ) : (
          <BodySignToClassify handleShow={handleShow} handleClassify={handleClassify} />
        )
      }
      handleShow={handleShow}
      handleClassify={handleClassify}
      print={print}
    />
  )
}

export default TableBodySignToClassify
