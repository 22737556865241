import React from 'react'
import { dateFormateComplete } from '../../../../services/helpers/dates'

const BodyJobPrint = ({ element, index }) => {
  return (
    <>
      {/* profession */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Profession</p>
        <p className="print__table__bloc__line--value">
          {!element.profession ? '-' : element.profession}
        </p>
      </div>
      {/* familleCSP */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">CSP</p>
        <p className="print__table__bloc__line--value">
          {!element.familleCSP ? '-' : element.familleCSP}
        </p>
      </div>
      {/* codeRubriqueCSP */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Code CSP</p>
        <p className="print__table__bloc__line--value">
          {!element.codeRubriqueCSP ? '-' : element.codeRubriqueCSP}
        </p>
      </div>
      {/* status */}
        <div className={'print__table__bloc__line'}>
          <div className="print__table__bloc__line--number"></div>
          <p className="print__table__bloc__line--title">Statut</p>
          <p className="print__table__bloc__line--value">
            {!element.status ? '-' : element.status}
          </p>
        </div>
      {/* dateDebut */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Début</p>
        <p className="print__table__bloc__line--value">
          {!element.dateDebut ? '-' : dateFormateComplete(element.dateDebut)}
        </p>
      </div>
      {/* dateFin */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Fin</p>
        <p className="print__table__bloc__line--value">
          {!element.dateFin ? 'en cours' : dateFormateComplete(element.dateFin)}
        </p>
      </div>
    </>
  )
}

export default BodyJobPrint
