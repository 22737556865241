import React from 'react'

const BodyContactsPrint = ({ element, index }) => {
  return (
    <>
      {/* nom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">{!element.nom ? '-' : element.nom}</p>
      </div>
      {/* prenom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Prénom</p>
        <p className="print__table__bloc__line--value">{!element.prenom ? '-' : element.prenom}</p>
      </div>
      {/* email */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Mail</p>
        <p className="print__table__bloc__line--value">{!element.email ? '-' : element.email}</p>
      </div>
      {/* telephonePortable */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Mobile</p>
        <p className="print__table__bloc__line--value">
          {!element.telephonePortable ? '-' : element.telephonePortable}
        </p>
      </div>
    </>
  )
}

export default BodyContactsPrint
