import React from 'react'
import { getLocaleStringCustom } from '../../../../../services/helpers/strings'

const BodyDetentionPrintFooter = ({ total, showUS }) => {
  // totaux
  const { totalTauxPP, totalTauxUS, totalTauxNP, totalPP, totalUS, totalNP, totalDetenu } =
    total || {}
  return (
    <div className="print__table__bloc">
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">TOTAL</div>
        <p className="print__table__bloc__line--title">% PP</p>
        <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
          totalTauxPP
        )} %`}</p>
      </div>
      {showUS ? (
        <>
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">% US</p>
            <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
              totalTauxUS
            )} %`}</p>
          </div>
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">% NP</p>
            <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
              totalTauxNP
            )} %`}</p>
          </div>
          {totalPP || totalPP === 0 ? (
            <div className={'print__table__bloc__line'}>
              <div className="print__table__bloc__line--number"></div>
              <p className="print__table__bloc__line--title">€ PP</p>
              <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
                totalPP
              )} €`}</p>
            </div>
          ) : null}
          {totalUS || totalUS === 0 ? (
            <div className={'print__table__bloc__line'}>
              <div className="print__table__bloc__line--number"></div>
              <p className="print__table__bloc__line--title">€ US</p>
              <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
                totalUS
              )} €`}</p>
            </div>
          ) : null}
          {totalNP || totalNP === 0 ? (
            <div className={'print__table__bloc__line'}>
              <div className="print__table__bloc__line--number"></div>
              <p className="print__table__bloc__line--title">€ NP</p>
              <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
                totalNP
              )} €`}</p>
            </div>
          ) : null}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Détenu</p>
            <p className="print__table__bloc__line--value">{`${getLocaleStringCustom(
              totalDetenu
            )} €`}</p>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default BodyDetentionPrintFooter
