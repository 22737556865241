import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sortByType } from '../../../../../../services/helpers/sort'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import {
  REVENUS_AUTRES,
  REVENUS_FONCIERS,
  REVENUS_MOBILIERS,
  REVENUS_PENSIONS,
  REVENUS_PROS,
  REVENU_AUTRE,
  REVENU_FONCIER,
  REVENU_MOBILIER,
  REVENU_PR,
  REVENU_PRO,
} from '../../../../../../constants'
import BodyRevenu from './BodyRevenu'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import { supprId } from '../../../../../../services/helpers/helpers'
import BodyRevenuPrint from './BodyRevenuPrint'

const TableBodyRevenu = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
  tableType,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [redirection, setRedirection] = useState(null)
  const [type, setType] = useState(null)
  useEffect(() => {
    switch (tableType) {
      case REVENUS_AUTRES:
        setRedirection('autres')
        setType(REVENU_AUTRE)
        break
      case REVENUS_FONCIERS:
        setRedirection('foncier')
        setType(REVENU_FONCIER)
        break
      case REVENUS_MOBILIERS:
        setRedirection('mobilier')
        setType(REVENU_MOBILIER)
        break
      case REVENUS_PROS:
        setRedirection('professionnel')
        setType(REVENU_PRO)
        break
      case REVENUS_PENSIONS:
        setRedirection('pension-rente')
        setType(REVENU_PR)
        break
      default:
        return
    }
  }, [])
  // ouvrir infos bien
  const handleShow = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'REVENUS', type: type }))
    navigate(
      `/user/patrimoine/revenus/${redirection}/${
        tableType === REVENUS_AUTRES || tableType === REVENUS_FONCIERS ? 'info' : 'revenu'
      }`
    )
  }
  // modifier bien
  const handleEdit = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'REVENUS', type: type }))
    navigate(
      `/user/patrimoine/revenus/${redirection}/${
        tableType === REVENUS_AUTRES || tableType === REVENUS_FONCIERS
          ? 'ajouter'
          : 'ajouter-revenu'
      }`
    )
  }
  // duplicate bien
  const handleDuplicate = (bien) => {
    console.log('test')
    const bienBis = {
      ...bien,
      id: 0,
      fichiers: [],
      libelle: `${bien.libelle} - copie`,
      detenteurs: bien.detenteurs.map((det) => supprId(det)),
    }
    dispatch(getBienSuccess(bienBis))
    dispatch(getbienType({ categorie: 'REVENUS', type: type }))
    navigate(
      `/user/patrimoine/revenus/${redirection}/${
        tableType === REVENUS_AUTRES || tableType === REVENUS_FONCIERS
          ? 'ajouter'
          : 'ajouter-revenu'
      }`
    )
  }
  // supprimer bien
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }

  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Libellé':
          giveAType('libelle')
          break
        case 'Catégorie':
          giveAType(
            tableType === REVENUS_AUTRES ||
              tableType === REVENUS_FONCIERS ||
              tableType === REVENUS_MOBILIERS
              ? 'detail'
              : tableType === REVENUS_PENSIONS
              ? 'typePensionRente'
              : 'typeRevenuProfessionnel'
          )
          break
        case 'Montant annuel':
          giveAType('montant')
          break
        case 'Périodicité':
          giveAType('typePeriodicite')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyRevenuPrint /> : <BodyRevenu handleShow={handleShow} />}
      print={print}
      handleShow={handleShow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleDuplicate={handleDuplicate}
      tableType={tableType}
    />
  )
}

export default TableBodyRevenu
