import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { postChargesTotalValue } from '../../../../../services/API/charges'
import Input from '../../../Components/Input'
import Modale from '../../../Components/Modale'

const ModalUpdateTotalValueCharges = ({ setToggleUpdateTotal, person, montantTotalCharge, pp }) => {
  const dispatch = useDispatch()
  // valeur totale
  const [totalValue, setTotalValue] = useState()
  const [difference, setDifference] = useState(0)
  // response
  const [status, setStatus] = useState(null)
  // change value input
  const [change, setChange] = useState(false)
  useEffect(() => {
    if (totalValue) {
      setChange(true)
      setDifference(totalValue ? parseFloat(totalValue) - montantTotalCharge : 0)
    } else {
      setChange(false)
    }
  }, [totalValue])
  // handleChange
  const handleChange = (e) => {
    const { value } = e.target
    setTotalValue(value ? value : '')
  }
  // update totalValue
  const updateTotal = () => {
    difference && dispatch(postChargesTotalValue(person.id, difference, pp, setStatus))
  }
  // response update status and modal
  useEffect(() => {
    if (status) {
      if (status < 400) {
        setTotalValue()
        setDifference(0)
        setToggleUpdateTotal(false)
        setStatus(null)
      } else {
        setToggleUpdateTotal(false)
        setStatus(null)
      }
    }
  }, [status])
  // values
  const before = ` ${montantTotalCharge.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`

  return (
    <Modale
      close={() => setToggleUpdateTotal(false)}
      title={'Modifier la valeur totale des CHARGES'}
    >
      <div className="informations">
        <div className="informations--center">
          {/* montant */}
          <Input
            forAndId="totalValue"
            type="text"
            name="Nouvelle valeur :"
            value={totalValue}
            onChange={(e) => handleChange(e)}
          />
        </div>
        {/* Valeur actuelle et difference */}
        <div className="informations__patrimoine">
          <p className="informations__patrimoine__line">
            Ancienne valeur : <span>{before}</span>
          </p>
          <p className="informations__patrimoine__line">
            Différence :{' '}
            <span>{`${(Math.round(difference * 100) / 100).toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`}</span>
          </p>
        </div>
        {/* buttons */}
        <div className="informations__buttons">
          {change ? (
            <div className="button button--red-red width--12" onClick={() => updateTotal()}>
              Enregistrer
            </div>
          ) : (
            <div className="button button--grey width--12">Enregistrer</div>
          )}
          <div
            className="button button--blue-red width--12"
            onClick={() => setToggleUpdateTotal(false)}
          >
            Annuler
          </div>
        </div>
      </div>
    </Modale>
  )
}

export default ModalUpdateTotalValueCharges
