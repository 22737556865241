import React from 'react';

const BodyDette = ({element, handleShow}) => {
    return (
        <>
            {/* refDette */}
            <td
              className="tbody__tr__td tbody__tr__td--pointer"
              onClick={() => handleShow(element)}
            >
              {!element.refDette ? (
                <p className="informations__line__notxt">-</p>
              ) : (
                element.refDette
              )}
            </td>
            {/* montantRestant */}
            <td
              className="tbody__tr__td tbody__tr__td--pointer"
              onClick={() => handleShow(element)}
            >
              {!element.montantRestant ? (
                <p className="informations__line__notxt">-</p>
              ) : (
                `${element.montantRestant.toLocaleString(undefined, {
                  minimumFractionDigits: 4,
                })} €`
              )}
            </td>
        </>
    );
};

export default BodyDette;