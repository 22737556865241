import React from 'react';
import { MdAdd, MdLocalPrintshop } from 'react-icons/md';

const DetentionTableButtons = ({handlePrint, handleAddDetenteur}) => {
    return (
        <div className="bonusStart">
        <div className="bonusStart__inputs">
          <div className="bonusStart__inputs__search">
            <button onClick={handlePrint} className="button--print button--print--square">
              <MdLocalPrintshop />
            </button>
          </div>
          <button
            onClick={() => handleAddDetenteur(true)}
            className="button--print button--print--add"
          >
            <MdAdd />
          </button>
        </div>
      </div>
);
};

export default DetentionTableButtons;