import React from 'react'

const BlocTablePrint = ({ data, children, tableType, read }) => {
  const commonProps = {
    listToDisplay: data,
    children,
    tableType,
    print: true,
    read,
  }

  return (
    <div className="blocTable">
      <ul className="print__table">{React.cloneElement(children, { ...commonProps })} </ul>
    </div>
  )
}

export default BlocTablePrint
