import React from 'react'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import { optionsNatureBienPro, optionsTypePro } from '../../../../../../data/options/optionsActifs'

const BodyBienProPrint = ({ element, index }) => {
  return (
    <>
      {/* nom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">{!element.nom ? '-' : element.nom}</p>
      </div>
      {/* valeur */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Valeur</p>
        <p className="print__table__bloc__line--value">
          {!element.valeur && element.valeur !== 0
            ? '-'
            : `${element.valeur.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
        </p>
      </div>
      {/* typeBienPro */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Catégorie</p>
        <p className="print__table__bloc__line--value">
          {!element.typeBienPro ? '-' : recupLabel(element.typeBienPro, optionsTypePro)}
        </p>
      </div>
      {/* nature */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Nature</p>
        <p className="print__table__bloc__line--value">
          {!element.nature ? '-' : recupLabel(element.nature, optionsNatureBienPro)}
        </p>
      </div>
      {/* montantDetenu */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Montant détenu</p>
        <p className="print__table__bloc__line--value">
          {!element.montantDetenu && element.montantDetenu !== 0
            ? '-'
            : `${element.montantDetenu.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
        </p>
      </div>
    </>
  )
}

export default BodyBienProPrint
