import React, { useEffect } from 'react'
import { sortByType } from '../../../../../../services/helpers/sort'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyOperations from './BodyOperations'
import BodyOperationsPrint from './BodyOperationsPrint'
import { FE } from '../../../../../../constants'

const TableBodyOperations = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  // supprimer operation
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }
  // tri croissant/decroissant
  const giveAType = (typeOrFunc) => {
    sortByType(sort, dataToDisplay, typeOrFunc, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Nom':
          giveAType('nom')
          break
        case 'ISIN':
          giveAType('isin')
          break
        case 'Nature':
          giveAType((element) =>
            element.majRegularisation
              ? 'Régularisation'
              : element.support.dici && element.support.dici.c_nom !== FE
              ? element.achat
                ? 'Achat'
                : element.cession
                ? 'Cession totale'
                : 'Vente'
              : element.achat
              ? 'Versement'
              : element.cession
              ? 'Retrait total'
              : 'Retrait'
          )
          break
        case 'Montant':
          giveAType('montant')
          break
        case 'Quantité':
          giveAType('nombre')
          break
        case "Valeur d'achat":
          giveAType('valeurAchat')
          break
        case "Date d'achat":
          giveAType('support.dateAchat')
          break
        case "Date de l'opération":
          giveAType('dateOperation')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyOperationsPrint /> : <BodyOperations />}
      print={print}
      handleDelete={handleDelete}
    />
  )
}

export default TableBodyOperations
