import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Input from '../../../Components/Input'
import Modale from '../../../Components/Modale'
import { postTotalValueCharge } from '../../../../../services/API/charges'

const ModalUpdateTotalValueChgPensions = ({ setToggleUpdateTotal, charges, person, pp }) => {
  const { valeurTotalePension } = charges || {}
  const dispatch = useDispatch()
  // valeur totale
  const [totalValue, setTotalValue] = useState()
  const [difference, setDifference] = useState(0)
  const [divers, setDivers] = useState(true)
  // response
  const [status, setStatus] = useState(null)
  // change value input
  const [change, setChange] = useState(false)
  useEffect(() => {
    if (totalValue) {
      setChange(true)
      setDifference(totalValue ? parseFloat(totalValue) - valeurTotalePension : 0)
    } else {
      setChange(false)
    }
  }, [totalValue])
  // handleChange
  const handleChange = (e) => {
    if (e.target !== undefined) {
      const { value } = e.target
      setTotalValue(value ? value : '')
    } else {
      setDivers(e.value)
    }
  }
  // update totalValue
  const updateTotal = () => {
    difference &&
      dispatch(
        postTotalValueCharge('majPensionValeurTotale', person.id, difference, pp, divers, setStatus)
      )
  }
  // response update status and modal
  useEffect(() => {
    if (status) {
      if (status < 400) {
        setTotalValue()
        setDifference(0)
        setToggleUpdateTotal(false)
        setStatus(null)
      } else {
        setToggleUpdateTotal(false)
        setStatus(null)
      }
    }
  }, [status])
  // values
  const before = ` ${valeurTotalePension.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`

  return (
    <Modale
      close={() => setToggleUpdateTotal(false)}
      title={'Modifier la valeur totale'}
    >
      <div className="informations">
        <div className="informations--center">
          {/* montant */}
          <Input
            forAndId="totalValue"
            type="text"
            name="Nouvelle valeur :"
            value={totalValue}
            onChange={(e) => handleChange(e)}
          />
        </div>
        {/* Valeur actuelle et difference */}
        <div className="informations__patrimoine">
          <p className="informations__patrimoine__line">
            Ancienne valeur : <span>{before}</span>
          </p>
          <p className="informations__patrimoine__line">
            Différence :{' '}
            <span>{`${(Math.round(difference * 100) / 100).toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`}</span>
          </p>
          <div className="informations--center">
            {/* divers */}
            <Input
              forAndId="divers"
              name="Inclu dans Charges diverses ? "
              onChange={(e, name) => handleChange(e, name)}
              value={divers}
              check={true}
              setValue={setDivers}
            />
          </div>
        </div>
        {/* buttons */}
        <div className="informations__buttons">
          {change ? (
            <div className="button button--red-red width--12" onClick={() => updateTotal()}>
              Enregistrer
            </div>
          ) : (
            <div className="button button--grey width--12">Enregistrer</div>
          )}
          <div
            className="button button--blue-red width--12"
            onClick={() => setToggleUpdateTotal(false)}
          >
            Annuler
          </div>
        </div>
      </div>
    </Modale>
  )
}

export default ModalUpdateTotalValueChgPensions
