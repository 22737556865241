import React, { useEffect } from 'react'
import { sortByType } from '../../../services/helpers/sort'
import TableBodyBloc from '../table/TableBodyBloc'
import BodyContacts from './BodyContacts'
import BodyContactsPrint from './BodyContactsPrint'

const TableBodyContacts = ({
  setModalDelete,
  setModalUpdate,
  setNewData,
  targetToSort,
  sort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Nom':
          giveAType('nom')
          break
        case 'Prenom':
          giveAType('prenom')
          break
        case 'Mail':
          giveAType('email')
          break
        case 'Mobile':
          giveAType('telephonePortable')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])
  //suppr
  const handleDelete = (element) => {
    setNewData(element)
    setModalDelete(true)
  }
  //suppr
  const handleEdit = (element) => {
    setNewData(element)
    setModalUpdate(true)
  }

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyContactsPrint /> : <BodyContacts handleEdit={handleEdit} />}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      print={print}
    />
  )
}

export default TableBodyContacts
