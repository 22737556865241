// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get parapheur
 * @reduxAction
 */

export const getParapheurLoad = createAction('get-parapheur-load')

export const getParapheurSuccess = createAction(
  'get-parapheur-success',
  (parapheur) => {
    return {
      payload: parapheur,
    }
  }
)

export const getParapheurError = createAction(
  'get-parapheur-error', (error) => {
  return {
    payload: error,
  }
})