import React from 'react'
import { shortEffectif } from '../../../../services/helpers/helpers'
import { dateFormateComplete } from '../../../../services/helpers/dates'

const BodyEtablissementsPrint = ({ element, index }) => {
  return (
    <>
      {/* siret */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">SIRET</p>
        <p className="print__table__bloc__line--value">
          {!element.siret ? '-' : element.siret_formate ? element.siret_formate : element.siret}
        </p>
      </div>
      {/* nic */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">NIC</p>
        <p className="print__table__bloc__line--value">{!element.nic ? '-' : element.nic}</p>
      </div>
      {/* adresse_ligne_1 */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Adresse</p>
        <p className="print__table__bloc__line--value">
          {!element.adresse_ligne_1 ? '-' : element.adresse_ligne_1}
        </p>
      </div>
      {/* adresse_ligne_2 */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Complément</p>
        <p className="print__table__bloc__line--value">
          {!element.adresse_ligne_2 ? '-' : element.adresse_ligne_2}
        </p>
      </div>
      {/* code_postal */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Code postal</p>
        <p className="print__table__bloc__line--value">
          {!element.code_postal ? '-' : element.code_postal}
        </p>
      </div>
      {/* ville */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Ville</p>
        <p className="print__table__bloc__line--value">{!element.ville ? '-' : element.ville}</p>
      </div>
      {/* pays */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Pays</p>
        <p className="print__table__bloc__line--value">{!element.pays ? '-' : element.pays}</p>
      </div>
      {/* code_pays */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Code pays</p>
        <p className="print__table__bloc__line--value">
          {!element.code_pays ? '-' : element.code_pays}
        </p>
      </div>
      {/* code_naf */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Code NAF</p>
        <p className="print__table__bloc__line--value">
          {!element.code_naf ? '-' : element.code_naf}
        </p>
      </div>
      {/* libelle_code_naf */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Libellé code NAF</p>
        <p className="print__table__bloc__line--value">
          {!element.libelle_code_naf ? '-' : element.libelle_code_naf}
        </p>
      </div>
      {/* etablissement_employeur */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Établissement employeur</p>
        <p className="print__table__bloc__line--value">
          {element.etablissement_employeur === ''
            ? '-'
            : element.etablissement_employeur === true
            ? 'oui'
            : 'non'}
        </p>
      </div>
      {/* effectif */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Effectif</p>
        <p className="print__table__bloc__line--value">
          {!element.effectif ? '-' : shortEffectif(element.effectif)}
        </p>
      </div>
      {/* effectif_min */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Effectif min</p>
        <p className="print__table__bloc__line--value">
          {!element.effectif_min ? '-' : element.effectif_min}
        </p>
      </div>
      {/* effectif_max */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Effectif max</p>
        <p className="print__table__bloc__line--value">
          {!element.effectif_max ? '-' : element.effectif_max}
        </p>
      </div>
      {/* date_de_creation */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Date de création</p>
        <p className="print__table__bloc__line--value">
          {!element.date_de_creation ? '-' : dateFormateComplete(element.date_de_creation)}
        </p>
      </div>
      {/* etablissement_cesse */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Cessation</p>
        <p className="print__table__bloc__line--value">
          {element.etablissement_cesse === ''
            ? '-'
            : element.etablissement_cesse === true
            ? 'oui'
            : 'non'}
        </p>
      </div>
      {/* date_cessation */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Date de cessation</p>
        <p className="print__table__bloc__line--value">
          {!element.date_cessation ? '-' : dateFormateComplete(element.date_cessation)}
        </p>
      </div>
      {/* siege */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Siège</p>
        <p className="print__table__bloc__line--value">
          {element.siege === '' ? '-' : element.siege === true ? 'oui' : 'non'}
        </p>
      </div>
      {/* enseigne */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Enseigne</p>
        <p className="print__table__bloc__line--value">
          {!element.enseigne ? '-' : element.enseigne}
        </p>
      </div>
      {/* nom_commercial */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Nom commercial</p>
        <p className="print__table__bloc__line--value">
          {!element.nom_commercial ? '-' : element.nom_commercial}
        </p>
      </div>
    </>
  )
}

export default BodyEtablissementsPrint
