import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  optionsTypeFichierGed,
  optionsTypeGedBirth,
  optionsTypeGedDivers,
  optionsTypeGedFamily,
  optionsTypeGedHome,
  optionsTypeGedIdentity,
  optionsTypeGedPatrimoine,
} from '../../../data/options/optionsFiles'
import { shortFormeJuridique } from '../../../services/helpers/helpers'
import Modale from '../Components/Modale'
import SelectComponent from '../Components/SelectComponent'
import { getSignaturesToClassify, postClassify } from '../../../services/helpers/files'
import { getPersonnes } from '../../../services/API/personnes'

const ModaleClassify = ({ setModal, newData }) => {
  const dispatch = useDispatch()
  // persons
  const selectPersons = (state) => state.getPersons.persons
  const persons = useSelector(selectPersons)
  const [personsToDisplay, setPersonsToDisplay] = useState(persons)
  const [listPersons, setListPersons] = useState([])
  const [optionsListPersons, setOptionsListPersons] = useState(null)
  const [personsSelected, setPersonsSelected] = useState([])
  // response
  const [status, setStatus] = useState(null)
  const [options, setOptions] = useState()
  const [data, setData] = useState({
    pps: '',
    pms: '',
    fichiers: newData,
    typeFichierGed: '',
    typeGed: '',
  })
  const { typeFichierGed, typeGed } = data || {}
  const [change, setChange] = useState(false)
  // change
  useEffect(() => {
    if (
      typeGed !== undefined &&
      typeGed !== '' &&
      typeFichierGed !== undefined &&
      typeFichierGed !== '' &&
      personsSelected.length !== 0
    ) {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [typeGed, typeFichierGed, personsSelected])
  // typeFichierGed
  useEffect(() => {
    typeFichierGed === 'IDENTITE'
      ? setOptions(optionsTypeGedIdentity)
      : typeFichierGed === 'DOMICILE'
      ? setOptions(optionsTypeGedHome)
      : typeFichierGed === 'NAISSANCE'
      ? setOptions(optionsTypeGedBirth)
      : typeFichierGed === 'FAMILLE'
      ? setOptions(optionsTypeGedFamily)
      : typeFichierGed === 'PATRIMOINE'
      ? setOptions(optionsTypeGedPatrimoine)
      : setOptions(optionsTypeGedDivers)
  }, [typeFichierGed])
  // validate
  const validate = () => {
    const pps = personsSelected.filter((el) => el.denominationSociale === undefined)
    const pms = personsSelected.filter((el) => el.denominationSociale !== undefined)
    const dataTosend = {
      pps: pps,
      pms: pms,
      fichiers: [newData],
      typeFichierGed: typeFichierGed,
      typeGed: typeGed,
    }
    if ((pps.length > 0 || pms.length > 0) && typeFichierGed !== '' && typeGed !== '') {
      dispatch(postClassify(dataTosend, setStatus))
    }
  }
  //   handleSelect
  const handleSelect = (e, selectName) => {
    const name = selectName.name
    const value = e.value
    setData({ ...data, [name]: value })
  }
  // actualisation persons
  useEffect(() => {
    setPersonsToDisplay(persons)
  }, [persons])

  // actualisation pp ou pm à afficher
  useEffect(() => {
    setListPersons(personsToDisplay.referentPP.concat(personsToDisplay.dirigeantPM))
  }, [])
  // response status classify
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setModal(false)
      dispatch(getPersonnes())
      dispatch(getSignaturesToClassify())
    }
    if (status >= 400) {
      console.log(status, 'pas poss classify')
    }
  }, [status])
  // maj optionsListPersons
  useEffect(() => {
    if (listPersons.length > 0) {
      setOptionsListPersons(
        listPersons.map((el) => ({
          value: el,
          label:
            el.denominationSociale !== undefined
              ? `${el.denominationSociale} (${shortFormeJuridique(el.formeJuridique)}) - PM`
              : `${el.nom} ${el.prenom} - PP`,
        }))
      )
    }
  }, [listPersons])
  // handleChangeMulti
  const handleChangeMulti = (e) => {
    if (e.length > 0) {
      let array = []
      for (const item of e) {
        !personsSelected.includes((el) => el === item.value) && array.push(item.value)
      }
      setPersonsSelected(array)
    } else {
      setPersonsSelected([])
    }
  }
  return (
    <Modale
      close={() => setModal(false)}
      title={'Classer le(s) document(s)'}
    >
      <div className="informations">
        <div className="informations--border">
          <h4 className="informations__h4">1- Sélection des personnes :</h4>
          <SelectComponent
            forAndId="personsSelected"
            isSearchable={true}
            isMulti={true}
            options={optionsListPersons && optionsListPersons}
            placeholder="Sélectionner"
            onChange={(e) => handleChangeMulti(e)}
            value={
              optionsListPersons && optionsListPersons.find((c) => c.value === personsSelected)
            }
            bold={true}
            noLabel={true}
          />
        </div>
        {personsSelected.length > 0 && (
          <>
            <div className="informations--border">
              {' '}
              {/* Type fichier Ged */}
              <h4 className="informations__h4">2- Catégorie de document :</h4>
              <SelectComponent
                forAndId="typeFichierGed"
                name="Catégorie :"
                isSearchable={false}
                options={optionsTypeFichierGed}
                placeholder="Sélectionner"
                onChange={(e, selectName) => handleSelect(e, selectName)}
                value={optionsTypeFichierGed.find((c) => c.value === typeFichierGed)}
              />
            </div>
          </>
        )}
        {/* Type Ged */}
        {typeFichierGed && (
          <div className="informations--border">
            <h4 className="informations__h4">3- Type de document :</h4>
            <SelectComponent
              forAndId="typeGed"
              name="Type :"
              options={options}
              placeholder="Sélectionner"
              onChange={(e, selectName) => handleSelect(e, selectName)}
              value={options.find((c) => c.value === typeGed)}
            />
          </div>
        )}
        {change ? (
          <div className="informations__buttons">
            <div className="button button--red-red width--12" onClick={() => validate()}>
              Enregistrer
            </div>
            <div
              className="button button--blue-red width--12"
              onClick={() => setModal(false)}
            >
              Annuler
            </div>
          </div>
        ) : (
          <div className="informations__buttons">
            <div className="button button--grey width--12">Enregistrer</div>
            <div
              className="button button--blue-red width--12"
              onClick={() => setModal(false)}
            >
              Annuler
            </div>
          </div>
        )}
      </div>
    </Modale>
  )
}

export default ModaleClassify
