import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPassifs } from '../../../../../services/API/passifs'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import Loader from '../../../../layout/Loader'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import ModalUpdateTotalValue from './ModalUpdateTotalValue'
import PassifsPieChart from './PassifsPieChart'
import PassifsToPrint from './PassifsToPrint'
import LittleLoader from '../../../../layout/LittleLoader'

const PassifsHome = () => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  const passifs = useSelector((state) => state.getPassifs.passifs)
  const { valeurTotalePassif, passifDivers } = passifs || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: passifDivers?.montant },
      { label: 'Affecté', value: valeurTotalePassif - passifDivers?.montant },
    ],
    [passifDivers, valeurTotalePassif]
  )
  // recharge
  useEffect(() => {
    !passifs && dispatch(getPassifs(person?.id, isPP))
  }, [passifs, person, isPP, dispatch])
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!passifs) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<PassifsToPrint elementToDisplay={passifs} values={values} />}
        />
        <ButtonsRow handlePrint={handlePrint} backLink={-1} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurTotalePassif}
              update={true}
              values={values}
            />
            {valeurTotalePassif > 0 ? (
              <PatrimoineChartCard
                children={<PassifsPieChart passifs={passifs} />}
                detenteurs={true}
              />
            ) : null}
          </div>
        </div>
        {toggleUpdateTotal ? (
          <ModalUpdateTotalValue
            setToggleUpdateTotal={setToggleUpdateTotal}
            person={person}
            valeurTotales={valeurTotalePassif}
            pp={isPP}
          />
        ) : null}
      </div>
    </>
  )
}

export default PassifsHome
