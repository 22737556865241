import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { getCharges } from '../../../../../services/API/charges'
import { CHARGES_REDUCTIONS } from '../../../../../constants'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import ChargesCard from '../Charges/ChargesCard'
import ChgReductionPieChart from './ChgReductionPieChart'
import ModalUpdateTotalValueChgReduction from './ModalUpdateTotalValueChgReduction'
import ChargesReductionRIToPrint from './ChargesReductionRIToPrint'
import LittleLoader from '../../../../layout/LittleLoader'

const ChgReductionHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const {charges} = useSelector((state) => state.getCharges)
  const { chargeOuvrantDroitReductionRIs } = charges || {}
  const {
    chargesOuvrantDroitReductionRIs,
    valeurChargesOuvrantDroitReductionRIs,
    chargeOuvrantDroitReductionRIDivers,
  } = chargeOuvrantDroitReductionRIs || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: chargeOuvrantDroitReductionRIDivers?.montant },
      {
        label: 'Affecté',
        value: valeurChargesOuvrantDroitReductionRIs - chargeOuvrantDroitReductionRIDivers?.montant,
      },
    ],
    [chargeOuvrantDroitReductionRIDivers, valeurChargesOuvrantDroitReductionRIs]
  )
  // recharge
  useEffect(() => {
    if (!charges) {
      dispatch(getCharges(person?.id, isPP))
    }
  }, [charges, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () =>
      chargesOuvrantDroitReductionRIs.filter(
        (revenu) => revenu.dateFin && new Date(revenu.dateFin) < new Date()
      ),
    [chargesOuvrantDroitReductionRIs]
  )
  const dataToDisplayNotSold = useMemo(
    () =>
      chargesOuvrantDroitReductionRIs.filter(
        (revenu) => new Date(revenu.dateFin) >= new Date() || !revenu.dateFin
      ),
    [chargesOuvrantDroitReductionRIs]
  ) // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // redirections
  const formLink = '/user/patrimoine/charges/reduction-RI/ajouter'
  // recuperation actifs
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!charges) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<ChargesReductionRIToPrint elementToDisplay={chargeOuvrantDroitReductionRIs}
              values={values}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold} />}
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurChargesOuvrantDroitReductionRIs}
              update={true}
              values={values}
            />
            {valeurChargesOuvrantDroitReductionRIs > 0 && (
              <PatrimoineChartCard
                children={<ChgReductionPieChart chargeOuvrantDroitReductionRIs={chargeOuvrantDroitReductionRIs} />}
                detenteurs={true}
              />
            )}
          </div>
          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <ChargesCard
              data={dataToDisplayNotSold}
              handleAdd={() => navigate(formLink)}
              title={`En cours (${dataToDisplayNotSold.length})`}
              tableType={CHARGES_REDUCTIONS}
            />
            {dataToDisplaySold.length > 0 && (
              <ChargesCard
                data={dataToDisplaySold}
                handleAdd={() => navigate(formLink)}
                title={`Historique (${dataToDisplaySold.length})`}
                tableType={CHARGES_REDUCTIONS}
              />
            )}
          </div>
        </div>
        {toggleUpdateTotal && (
          <ModalUpdateTotalValueChgReduction
            setToggleUpdateTotal={setToggleUpdateTotal}
            person={person}
            charges={charges}
            pp={isPP}
          />
        )}
      </div>
    </>
  )
}

export default ChgReductionHome
