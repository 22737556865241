import React from 'react'
import { noTwice } from '../../../services/helpers/helpers'
import { deleteSeparator } from '../../../services/helpers/strings'

const BlocSignatairesList = ({ signataires }) => {
  return (
    <div className="informations--border">
      <h4 className="informations__h4">Liste des signataires sélectionnés :</h4>
      <ul className="informations">
        {noTwice(signataires).map((signataire, index) => (
          <li key={index} className="informations__item">
            <div className="informations__item__txt">
              <p>{deleteSeparator(signataire.nom)}</p>
              <p>{signataire.mail}</p>
              <p>{signataire.telPortable}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BlocSignatairesList
