import React from 'react'
import Card from '../../../../../Components/Card'
import Input from '../../../../../Components/Input'
import SelectComponent from '../../../../../Components/SelectComponent'
import { optionsTypePeriodicite } from '../../../../../../../data/options/options'
import DateInputComponent from '../../../../../Components/DateInputComponent'

const TermsCard = ({ handleChange, handleChangeDate, information }) => {
  const { dateSouscription, duree, dateFin, frequenceCotisation, montantEcheance, dateEcheance } =
    information

  return (
    <Card title="Modalités" h3={true} open={true}>
      <div className="informations">
        {/* dateSouscription */}
        <DateInputComponent
          name={'dateSouscription'}
          label={'Date de souscription :'}
          value={dateSouscription}
          handleChange={handleChangeDate}
        />
        {/* duree */}
        <Input
          forAndId="duree"
          type="number"
          name="Durée (mois) :"
          value={duree}
          onChange={(e) => handleChange(e)}
          min={0}
          max={1800}
          copy={true}
        />
        {/* dateFin */}
        <DateInputComponent
          name={'dateFin'}
          label={'Date de fin :'}
          value={dateFin}
          handleChange={handleChangeDate}
        />
        {/* frequenceCotisation */}
        <SelectComponent
          forAndId="frequenceCotisation"
          name="Periodicité des cotisations :"
          isClearable={true}
          options={optionsTypePeriodicite}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsTypePeriodicite.find((c) => c.value === frequenceCotisation)}
          copy={true}
        />
        {/* montantEcheance */}
        <Input
          forAndId="montantEcheance"
          type="number"
          name="Montant des cotisations :"
          value={montantEcheance}
          onChange={(e) => handleChange(e)}
          min={0}
          max={999999999999}
          copy={true}
        />
        {/* dateEcheance */}
        <DateInputComponent
          name={'dateEcheance'}
          label={'Echéance des cotisations :'}
          value={dateEcheance}
          handleChange={handleChangeDate}
        />
      </div>
    </Card>
  )
}

export default TermsCard
