import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContacts, postContact } from '../../../services/API/connexion'
import { getPersonnes, updatePM, updatePP } from '../../../services/API/personnes'
import Modale from '../Components/Modale'
import InputsSignataire from './InputsSignataire'
import Ok from '../Components/Modales/Ok'
import NotOk from '../Components/Modales/NotOk'

const Signataire = ({ setModalUpdate, newData, setNewData, update, title }) => {
  const dispatch = useDispatch()
  // user
  const user = useSelector((state) => state.getUser.user)
  // personne
  const person = useSelector((state) => state.getPerson.person)
  // personnes
  const persons = useSelector((state) => state.getPersons.persons)
  // contacts
  const contacts = useSelector((state) => state.getContacts.contacts)
  //
  const [newTelPortable, setNewTelPortable] = useState('')
  const [notCorrect, setNotCorrect] = useState(false)
  const [updated, setUpdated] = useState(true)
  const [updatePerson, setUpdatePerson] = useState(person)
  const [status, setStatus] = useState()
  const [statusUpdate, setStatusUpdate] = useState(null)
  // modales
  const [modaleUpdateContactOk, setModaleUpdateContactOk] = useState(false)
  const [modalUpdateContactNotOk, setModalUpdateContactNotOk] = useState(false)
  const [modalUpdateContactAlreadyExist, setModalUpdateContactAlreadyExist] = useState(false)
  //
  const [newSignataire, setNewSignataire] = useState(null)

  const { nom, prenom, mail, telPortable, fonction } = newSignataire || {}
  // data contact To send
  const data = {
    id: 0,
    user_id: user,
    nom: nom,
    prenom: prenom,
    email: mail,
    telephonePortable: telPortable,
    fonction: fonction,
  }
  // focus
  useEffect(() => {
    if (update) {
      const inputFocus = document.getElementById('telPortable')
      inputFocus.focus()
    } else {
      const inputFocus = document.getElementById('nom')
      inputFocus.focus()
    }
  }, [])

  // handleChange
  const handleChange = (e, selectName) => {
    setNotCorrect(false)
    if (e.target !== undefined) {
      const { name, value } = e.target
      update ? setNewTelPortable(value) : setNewSignataire({ ...newSignataire, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      setNewSignataire({ ...newSignataire, [name]: value })
    }
  }
  // is a contact ?
  const isAContact = (newData) => {
    const match1 = contacts.filter((contact) => contact.email === newData.mail)
    const match2 = match1.filter((contact) => contact.nom === newData.nom.split(';')[0])
    return match2.length > 0 ? true : false
  }
  // is a PM ?
  const isAPM = (newData) => {
    const match1 = persons.dirigeantPM.filter((pers) => pers.mail === newData.mail)
    const match2 = match1.filter((pers) => pers.denominationSociale === newData.nom.split(';')[2])
    return match2.length > 0 ? true : false
  }
  // is a relation ?
  const isARelation = (newData) => {
    const match1 = persons.relationPP.filter((pers) => pers.mail === newData.mail)
    const match2 = match1.filter((pers) => pers.nom === newData.nom.split(';')[0])
    return match2.length > 0 ? true : false
  }
  // validation
  const validate = () => {
    if (update) {
      if (
        newTelPortable !== '' &&
        newTelPortable.match(/\d/g).length >= 10 &&
        newTelPortable.match(/\d/g).length <= 12
      ) {
        setNewData({
          ...newData,
          nom: `${newData.nom}`,
          mail: newData.mail,
          telPortable: newTelPortable,
        })
      } else {
        setNotCorrect(true)
      }
    } else {
      if (
        nom !== '' &&
        mail !== '' &&
        telPortable !== '' &&
        telPortable !== undefined &&
        telPortable.match(/\d/g).length >= 10 &&
        telPortable.match(/\d/g).length <= 12
      ) {
        setNewData({
          ...newData,
          nom: `${nom};${prenom}`,
          mail: mail,
          telPortable: telPortable,
        })
        dispatch(postContact(data, setStatus))
      } else {
        setNotCorrect(true)
      }
    }
  }
  useEffect(() => {
    if (updated && update) {
      if (newData.telPortable) {
        const listPP = persons.referentPP.concat(persons.relationPP)
        const goodContact = contacts.filter((contact) => contact.email === newData.mail)
        const goodPM = persons.dirigeantPM.filter((pers) => pers.mail === newData.mail)
        const goodPP = listPP.filter((pers) => pers.mailUsuel === newData.mail)
        isAContact(newData)
          ? setUpdatePerson({
              ...goodContact[0],
              telephonePortable: newTelPortable,
            })
          : isAPM(newData)
          ? setUpdatePerson({
              ...goodPM[0],
              telPortable: newTelPortable,
            })
          : setUpdatePerson({
              ...goodPP[0],
              telPortable: newTelPortable,
            })
      }
    }
  }, [newData])

  // response update contact
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setModaleUpdateContactOk(true)
      setStatus(null)
    }
    if (status === 409) {
      setModalUpdateContactAlreadyExist(true)
      setStatus(null)
    }
    if (status >= 400 && status !== 409) {
      setModalUpdateContactNotOk(true)
      setStatus(null)
    }
  }, [status])
  const handleClose = () => {
    dispatch(getContacts())
    setModaleUpdateContactOk(false)
    setModalUpdateContactNotOk(false)
    setModalUpdateContactAlreadyExist(false)
    setModalUpdate(false)
  }
  // update
  const updatePPorPM = () => {
    if (updatePerson.denominationSociale === undefined) {
      updatePerson.fichiers !== undefined
        ? dispatch(updatePP(updatePerson, setStatusUpdate))
        : dispatch(postContact(updatePerson, setStatusUpdate))
    } else {
      dispatch(updatePM(updatePerson, setStatusUpdate))
    }
  }

  useEffect(() => {
    if (updatePerson !== person && (updatePerson.telPortable || updatePerson.telephonePortable)) {
      updatePPorPM()
    }
  }, [updatePerson])

  // response update PP status and modal
  useEffect(() => {
    if (statusUpdate >= 200 && statusUpdate < 300) {
      dispatch(getPersonnes())
      setModalUpdate(false)
    }
    if (statusUpdate > 300) {
      setModalUpdate(false)
    }
  }, [statusUpdate])

  const goodDataInput = (object) => {
    const array = Array.from(object.nom.split(';'))
    const nom = array[0]
    const prenom = array[1]
    return { ...object, nom: nom, prenom: prenom }
  }

  return (
    <>
      <InputsSignataire
        setModalUpdate={setModalUpdate}
        handleChange={handleChange}
        validate={validate}
        notCorrect={notCorrect}
        updated={updated}
        setUpdated={setUpdated}
        newData={update ? goodDataInput(newData) : newSignataire}
        newTelPortable={newTelPortable}
        update={update}
        title={title}
        isARelation={isARelation}
      />
      {modaleUpdateContactOk && (
        <Ok text="Modification enregistrées avec succès" close={handleClose} />
      )}
      {modalUpdateContactNotOk && (
        <NotOk setNotOK={setModalUpdateContactNotOk} closeModale={handleClose} />
      )}
      {modalUpdateContactAlreadyExist && (
        <Modale
          close={() => handleClose()}
          title="Erreur !"
          line1="Une personne existe déjà avec cette adresse mail"
        >
          <div className="informations__buttons">
            <div
              className="button button--blue-red width--12"
              onClick={() => setModalUpdateContactAlreadyExist(false)}
            >
              Réessayer
            </div>
            <div className="button button--blue-red width--12" onClick={() => handleClose()}>
              Abandonner
            </div>
          </div>
        </Modale>
      )}
    </>
  )
}

export default Signataire
