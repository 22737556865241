import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { postNewVL } from '../../../../../services/API/catalogue'
import { getDICIList, getHistoSupport } from '../../../../../services/API/financier'
import Loader from '../../../../layout/Loader'
import Input from '../../../Components/Input'
import Modale from '../../../Components/Modale'
import DateInputComponent from '../../../Components/DateInputComponent'

const CatalogueValuesInput = ({ cours, setCours, isin, categorie }) => {
  const dispatch = useDispatch()
  // data
  const [dataHisto, setDataHisto] = useState(null)
  const { dateVL, valeur } = cours || {}
  const dataCours = {
    id: 0,
    isin: isin,
    dateVL: dateVL,
    valeur: parseFloat(valeur),
  }
  const [checkedDate, setCheckedDate] = useState(dateVL)
  // states
  const [datatChange, setDataChange] = useState(false)
  const [validDate, setValidDate] = useState(false)
  // reponse
  const [statusCours, setStatusCours] = useState(null)
  // message
  const [message, setMessage] = useState('')
  // maj datatChange
  useEffect(() => {
    if (dateVL !== '' && valeur !== '' && validDate) {
      setDataChange(true)
    } else {
      setDataChange(false)
    }
  }, [dateVL, valeur, validDate])
  // handleChange
  const handleChange = (e) => {
    const { name, value } = e.target
    setCours({ ...cours, [name]: value })
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setCours({ ...cours, [name]: date })
  }

  const handleSearch = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // setCours({...cours, dateVL: new Date(dateVL).toISOString().split('T')[0]})
    dispatch(getHistoSupport(isin, setDataHisto))
  }
  useEffect(() => {
    if (dataHisto && dataHisto.data) {
      const result = dataHisto.data.filter(
        (el) => new Date(el.dateVL).toISOString().split('T')[0] === dateVL
      )[0]
      if (result !== undefined) {
        setCours({ ...cours, valeur: result.valeur })
        setMessage('Une valeur est déjà enregistrée pour cette date')
        setValidDate(false)
        setCheckedDate(result.dateVL)
      } else {
        setMessage('Veuillez saisir une valeur')
        setCours({ ...cours, valeur: 0 })
        setValidDate(true)
        setCheckedDate(dateVL)
      }
    }
  }, [dataHisto])
  //  handleSubmitNewCours
  const handleSubmitNewCours = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(postNewVL(dataCours, setStatusCours))
  }
  // statusCours
  useEffect(() => {
    if (statusCours) {
      switch (statusCours) {
        case 200:
          setCours({
            isin: isin,
            dateVL: new Date().toISOString().split('T')[0],
            valeur: null,
          })
          setMessage("Vous venez d'enregistrer une nouvelle valeur pour ce support")
          dispatch(getDICIList())
          setDataHisto({ isLoading: false, data: null, error: null })

          break
        case 403:
          setMessage("Il s'agit d'un support non modifiable")
          break
        case 404:
          setMessage('ISIN non reconnu')
          break
        case 409:
          setMessage('Une valeur existe déjà pour cette date')
          break
        default:
          setMessage('un problème est survenu, veuillez réessayer')
      }
    }
  }, [statusCours])
  // close Modal
  const closeModale = () => {
    setMessage(null)
    setStatusCours(null)
    // setValidDate(false)
  }

  return (
    <>
      <div className="informations">
        <div className={`informations informations--borderFull`}>
          {/* dateValeur */}
          <DateInputComponent
            name={'dateVL'}
            label={'Date du cours :'}
            value={dateVL}
            handleChange={handleChangeDate}
            search={true}
            action={handleSearch}
          />
          {/* valeur */}
          {valeur || valeur === 0 ? (
            <Input
              forAndId="valeur"
              type="text"
              name={`Cours au ${format(new Date(checkedDate), 'dd/MM/yyyy')}:`}
              value={valeur}
              onChange={(e) => handleChange(e)}
              inputMode="decimal"
            />
          ) : null}
        </div>
        <div className="informations__buttons">
          {datatChange ? (
            <button
              className="button button--red-red width--12"
              onClick={(e) => handleSubmitNewCours(e)}
            >
              Ajouter
            </button>
          ) : (
            <div className="button button--grey width--12">Ajouter</div>
          )}
        </div>
        {dataHisto && dataHisto.isLoading ? <Loader /> : null}
      </div>
      {message ? (
        <Modale
          close={() => closeModale()}
          title={
            statusCours === 200 ? 'Félicitation !' : !statusCours ? 'Nouvelle date' : 'Erreur !'
          }
          line1={message}
        ></Modale>
      ) : null}
    </>
  )
}

export default CatalogueValuesInput
