import React from 'react'
import { beautifulDate } from '../../../../../../services/helpers/dates'

const BodyEmprunt = ({ element, handleShow }) => {
  return (
    <>
      {/* refEmprunt */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.refEmprunt ? <p className="informations__line__notxt">-</p> : element.refEmprunt}
      </td>
      {/* montantEmprunte */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.montantEmprunte ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.montantEmprunte.toLocaleString(undefined, {
            minimumFractionDigits: 4,
          })} €`
        )}
      </td>
      {/* montantRestant */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.montantRestant && element.montantRestant !== 0 ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.montantRestant.toLocaleString(undefined, {
            minimumFractionDigits: 4,
          })} €`
        )}
      </td>
      {/* mensualite */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.mensualite ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.mensualite.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
        )}
      </td>
      {/* dureeMois */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.dureeMois ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.dureeMois} mois`
        )}
      </td>
      {/* dateDebut */}
      <td className="tbody__tr__td" onClick={() => handleShow(element)}>
        {!element.dateDebut ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.dateDebut)
        )}
      </td>
      {/* dateFin */}
      <td className="tbody__tr__td" data-label="Date fin">
        {element.dateFin === '' ? (
          <p className="informations__line__notxt">-</p>
        ) : element.dateFin ? (
          beautifulDate(element.dateFin)
        ) : (
          'en cours'
        )}
      </td>
    </>
  )
}

export default BodyEmprunt
