import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFinancier } from '../../../../../../services/API/financier'
import Loader from '../../../../../layout/Loader'
import usePersonData from '../../../../../layout/usePersonData'
import useCustomPrint from '../../../../../layout/useCustomPrint'
import PrintComponent from '../../../../Components/PrintComponent'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../../Patrimoine/PatrimoineChartCard'
import ButtonsRow from '../../../Patrimoine/ButtonsRow'
import FinancierPieChart from './FinancierPieChart'
import FinancierToPrint from './FinancierToPrint'
import LittleLoader from '../../../../../layout/LittleLoader'

const FinancierHome = () => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  const financier = useSelector((state) => state.getFinancier.financier)
  const { valeurTotaleFinancier, produitsVM, produitsAV, comptesBancaires } = financier || {}
  // recharge
  useEffect(() => {
    if (!financier) {
      dispatch(getFinancier(person?.id, isPP))
    }
  }, [financier, person, isPP, dispatch])
  // values
  const valeurLiquidites = useMemo(
    () => produitsVM.map((produit) => produit.liquidite).reduce((acc, curr) => acc + curr, 0),
    [produitsVM]
  )

  const totalVM = useMemo(
    () =>
      produitsVM.length > 0
        ? produitsVM
            .map((produit) => produit.montantTotalCalcule)
            .reduce((acc, current) => acc + current)
        : 0,
    [produitsVM]
  )
  const totalAV = useMemo(
    () =>
      produitsAV.length > 0
        ? produitsAV
            .map((produit) => produit.montantTotalCalcule)
            .reduce((acc, current) => acc + current)
        : 0,
    [produitsAV]
  )
  const totalPF = useMemo(
    () =>
      comptesBancaires.length > 0
        ? comptesBancaires
            .map((compte) => (!compte.clos ? compte.solde : 0))
            .reduce((acc, current) => acc + current)
        : 0,
    [comptesBancaires]
  )
  const values = useMemo(
    () => [
      { label: 'Liquidités', value: valeurLiquidites },
      { label: 'Valeurs Mobilières', value: totalVM },
      { label: 'Assurances Vie', value: totalAV },
      { label: 'Produits Financiers', value: totalPF },
    ],
    [valeurLiquidites, totalVM, totalAV, totalPF]
  )
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!financier) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<FinancierToPrint elementToDisplay={financier} values={values} />}
        />
        <ButtonsRow handlePrint={handlePrint} />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            <PatrimoineValueCard
              totalAmount={valeurTotaleFinancier}
              values={values}
            />
            {valeurTotaleFinancier !== 0 ? (
              <PatrimoineChartCard
                children={<FinancierPieChart financier={financier} />}
                detenteurs={true}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default FinancierHome
