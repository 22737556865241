import React from 'react'
import useTotauxDetenteurs from '../useTotauxDetenteurs'

const DetentionTable = ({ detenteurs, showUS }) => {
  // titles
  const titles = showUS
    ? ['Nom', 'Pleine propriété', 'Usufruit', 'Nue propriété', 'Valeur détenue']
    : ['Nom', 'Détenu (%)', 'Détenu (€)']

  // totaux
  const { totalTauxPP, totalTauxUS, totalTauxNP, totalDetenu } = useTotauxDetenteurs(detenteurs)

  return (
    <div className="table">
      <div className="scrollwrapper scrollwrapper--noNumber">
        <table className="table__content">
          <thead className="thead">
            <tr className="thead__tr">
              {titles.map((title, index) => (
                <th className="thead__tr__th" key={index}>
                  <p>{title}</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tbody">
            {detenteurs &&
              detenteurs.length > 0 &&
              detenteurs.map((det, index) => (
                <tr className="tbody__tr" key={index}>
                  <td className="tbody__tr__td">{det.nomDetenteur}</td>
                  <td className="tbody__tr__td">{`${det.tauxPleinePropriete} %`}</td>
                  {showUS ? (
                    <>
                      <td className="tbody__tr__td">{`${det.tauxUsufruit} %`}</td>

                      <td className="tbody__tr__td">{`${det.tauxNuePropriete} %`}</td>
                    </>
                  ) : null}
                  <td className="tbody__tr__td">{`${det.valeurDetenue.toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                  })} €`}</td>
                </tr>
              ))}
          </tbody>
          <tfoot className="tfoot">
            <tr className="thead__tr">
              <th className="thead__tr__th">
                <p>Total : </p>
              </th>
              <th className="thead__tr__th">
                <p>{`${totalTauxPP.toLocaleString(undefined, { minimumFractionDigits: 4 })} %`}</p>
              </th>
              {showUS ? (
                <>
                  <th className="thead__tr__th">
                    <p>{`${totalTauxUS.toLocaleString(undefined, {
                      minimumFractionDigits: 4,
                    })} %`}</p>
                  </th>
                  <th className="thead__tr__th">
                    <p>{`${totalTauxNP.toLocaleString(undefined, {
                      minimumFractionDigits: 4,
                    })} %`}</p>
                  </th>
                </>
              ) : null}
              <th className="thead__tr__th">
                <p>{`${totalDetenu.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}</p>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default DetentionTable
