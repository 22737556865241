import { recupBien } from '../../services/API/actifs'
import { beautifulDate } from '../../services/helpers/dates'
import { calculateAmountReverse, recupLabel } from '../../services/helpers/helpers'
import { optionsDetention, optionsTypePeriodicite } from '../options/options'

// valeur
export const dataChargesValues = (charge) => {
  const { chargeDivers, valeurTotalCharge } = charge
  return [
    {
      label: 'Valeur totale des charges',
      value: `${
        valeurTotalCharge &&
        valeurTotalCharge.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur charges divers',
      value: `${
        chargeDivers && chargeDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur charges affectées',
      value: `${(
        valeurTotalCharge &&
        chargeDivers &&
        valeurTotalCharge - chargeDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataDepensesCourantesValues = (chargesToDisplay) => {
  const { chargesGenerales } = chargesToDisplay
  const { valeurChargesGenerales, chargeGeneraleDivers } = chargesGenerales
  return [
    {
      label: 'Valeur totale des dépenses courantes',
      value: `${
        valeurChargesGenerales &&
        valeurChargesGenerales.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur dépenses courantes divers',
      value: `${
        chargeGeneraleDivers &&
        chargeGeneraleDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur dépenses courantes affectées',
      value: `${(
        valeurChargesGenerales &&
        chargeGeneraleDivers &&
        valeurChargesGenerales - chargeGeneraleDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataPensionsValues = (chargesToDisplay) => {
  const { pensions } = chargesToDisplay
  const { valeurPensions, pensionDivers } = pensions
  return [
    {
      label: 'Valeur totale des pensions',
      value: `${
        valeurPensions && valeurPensions.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur pensions divers',
      value: `${
        pensionDivers &&
        pensionDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur pensions affectées',
      value: `${(
        valeurPensions &&
        pensionDivers &&
        valeurPensions - pensionDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataEmpruntsDettesValues = (chargesToDisplay) => {
  const { empruntEtDettes } = chargesToDisplay
  const { valeurEmpruntEtDettes, empruntEtDetteDivers } = empruntEtDettes
  return [
    {
      label: 'Valeur totale des emprunts et dettes',
      value: `${
        valeurEmpruntEtDettes &&
        valeurEmpruntEtDettes.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur emprunts et dettes divers',
      value: `${
        empruntEtDetteDivers &&
        empruntEtDetteDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur emprunts et dettes affectés',
      value: `${(
        valeurEmpruntEtDettes &&
        empruntEtDetteDivers &&
        valeurEmpruntEtDettes - empruntEtDetteDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataChargesDeductiblesValues = (chargesToDisplay) => {
  const { chargeDeductibleRIs } = chargesToDisplay
  const { valeurChargesDeductibleRIs, chargeDeductibleRIDivers } = chargeDeductibleRIs
  return [
    {
      label: 'Valeur totale des charges déductibles',
      value: `${
        valeurChargesDeductibleRIs &&
        valeurChargesDeductibleRIs.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur charges déductibles divers',
      value: `${
        chargeDeductibleRIDivers &&
        chargeDeductibleRIDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur charges déductibles affectées',
      value: `${(
        valeurChargesDeductibleRIs &&
        chargeDeductibleRIDivers &&
        valeurChargesDeductibleRIs - chargeDeductibleRIDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataReductionsCreditsImpotValues = (chargesToDisplay) => {
  const { chargeOuvrantDroitReductionRIs } = chargesToDisplay
  const { valeurChargesOuvrantDroitReductionRIs, chargeOuvrantDroitReductionRIDivers } =
    chargeOuvrantDroitReductionRIs
  return [
    {
      label: "Valeur totale des réductions et crédits d'impôt",
      value: `${
        valeurChargesOuvrantDroitReductionRIs &&
        valeurChargesOuvrantDroitReductionRIs.toLocaleString(undefined, {
          minimumFractionDigits: 4,
        })
      } €`,
    },
    {
      label: "Valeur réductions et crédits d'impôt divers",
      value: `${
        chargeOuvrantDroitReductionRIDivers &&
        chargeOuvrantDroitReductionRIDivers.montant.toLocaleString(undefined, {
          minimumFractionDigits: 4,
        })
      } €`,
    },
    {
      label: "Valeur réductions et crédits d'impôt affectés",
      value: `${(
        valeurChargesOuvrantDroitReductionRIs &&
        chargeOuvrantDroitReductionRIDivers &&
        valeurChargesOuvrantDroitReductionRIs - chargeOuvrantDroitReductionRIDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}
export const dataImpotsTaxesValues = (chargesToDisplay) => {
  const { impotEtTaxes } = chargesToDisplay
  const { valeurImpotEtTaxes, impotEtTaxeDivers } = impotEtTaxes
  return [
    {
      label: 'Valeur totale des impôts et taxes',
      value: `${
        valeurImpotEtTaxes &&
        valeurImpotEtTaxes.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur impôts et taxes divers',
      value: `${
        impotEtTaxeDivers &&
        impotEtTaxeDivers.montant.toLocaleString(undefined, { minimumFractionDigits: 4 })
      } €`,
    },
    {
      label: 'Valeur impôts et taxes affectés',
      value: `${(
        valeurImpotEtTaxes &&
        impotEtTaxeDivers &&
        valeurImpotEtTaxes - impotEtTaxeDivers.montant
      ).toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
  ]
}

// charges
export const dataChargeGenerale = (charge, categorie, properties, pmList) => {
  return [
    {
      label: 'Nom :',
      value: charge.nom,
    },
    charge.detail
      ? {
          label: 'Catégorie :',
          value: charge.detail,
        }
      : null,
    {
      label: 'Montant :',
      value: `${calculateAmountReverse(charge.montantAnnuel, charge.periodicite).toLocaleString(
        undefined,
        { minimumFractionDigits: 4 }
      )} €`,
    },
    {
      label: 'Périodicité :',
      value: charge.periodicite && recupLabel(charge.periodicite, optionsTypePeriodicite),
    },
    {
      label: 'Date de début :',
      value: beautifulDate(charge.dateDebut),
    },
    {
      label: 'Date de fin :',
      value: charge.dateFin ? beautifulDate(charge.dateFin) : 'en cours',
    },
    {
      label: 'Relié au bien :',
      value:
        charge.idActif && charge.idActif !== 0
          ? recupBien(charge.typeActif, charge.idActif, properties, false)
          : '',
      bien:
        charge.idActif && charge.idActif !== 0
          ? recupBien(charge.typeActif, charge.idActif, properties, true)
          : null,
    },
    {
      label: 'Détention :',
      value: charge.detention && recupLabel(charge.detention, optionsDetention),
    },
    {
      label: "Taux d'indivision :",
      value: `${charge.tauxIndivision} %`,
    },
  ]
}
