import React from 'react'
import PatrimoineChartCardPrint from '../../Patrimoine/PatrimoineChartCardPrint'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import ActifsPieChart from './ActifsPieChart'

const ActifsToPrint = ({ elementToDisplay, values }) => {
  const {valeurTotales} = elementToDisplay|| {}
  return (
    <>
      <PatrimoineValueCard
        totalAmount={valeurTotales}
        values={values}
      />
      <PatrimoineChartCardPrint
        children={<ActifsPieChart properties={elementToDisplay} />}
        detenteurs={true}
      />
    </>
  )
}

export default ActifsToPrint
