/**
 * It takes a string, normalizes it, replaces some characters, and then lowercases it.
 * @param param - the string to be normalized
 * @returns A function that takes a parameter and returns a string.
 */
export const normalizeAndLowerCase = (param) => {
  const a = param.normalize('NFD')
  const b = replacements(a)
  const c = b.toLowerCase()
  return c
}
/**
 * It replaces all diacritics with nothing, replaces all œ with oe, and replaces all uppercase accented
 * E's with E.
 * @param str - the string to be processed
 * @returns the string with the diacritics removed.
 */
function replacements(str) {
  const a = str.replace(/[\u0300-\u036f]/g, '')
  const b = a.replace(/[œ]/g, 'oe')
  const c = b.replace(/[ÈÉÊË]/g, 'E')
  return c
}

export const standardizeAndLowerCase = (str) => {
  if (typeof str === 'string') {
    let a = str
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[œ]/g, 'oe')
      .replace(/[ÈÉÊËèéêë]/g, 'e')
      .replace(/[ÂÄÀàâä]/g, 'a')
      .replace(/[ÔÖôö]/g, 'o')
      .replace(/[ÛÜûü]/g, 'u')
      .replace(/[ÎÏîï]/g, 'i')
      .toLowerCase()
      .replace('.', '')
    return a
  }
}
/**
 * It takes a string, replaces all dashes with spaces, makes the string lowercase, splits the string
 * into an array of words, capitalizes the first letter of each word, and then joins the array back
 * into a string.
 * @param string - the string you want to capitalize
 */
export const capitalizeFirstLetter = (string) => {
  const regex = /-/gi
  const newString = string.replace(regex, ' ')
  return newString
    .toLowerCase()
    .split('-')
    .map((word) => {
      return word[0].toUpperCase() + word.substr(1)
    })
    .join(' ')
}

/**
 * It takes a string, checks if it contains a dash, if it does, it splits the string into an array of
 * words, capitalizes the first letter of each word, and joins the array back into a string. If it
 * doesn't contain a dash, it splits the string into an array of words, capitalizes the first letter of
 * each word, and joins the array back into a string.
 * @param string - the string you want to capitalize
 */
export const stringToCapitalize = (string) => {
  if (string.includes('-')) {
    return string
      .trim()
      .toLowerCase()
      .split('-')
      .map((word) => {
        return word[0].toUpperCase() + word.substr(1)
      })
      .join('-')
  } else {
    return string
      .trim()
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return `${word[0] ? word[0].toUpperCase() : ''}${word.substr(1)}`
      })
      .join(' ')
  }
}

export const deleteSeparator = (string) => {
  if (string.includes(';')) {
    return string.split(';').join(' ')
  }
}

export const getLibelleProduitFinancier = (str) => {
  if (str.includes(';')) {
    return str.split(';').join(' ')
  } else {
    return str
  }
}
export const cleanConcatString = (str) => {
  return normalizeAndLowerCase(str).replace(/[^a-z0-9]/g, '')
}
export const getLocaleStringCustom = (value) => {
  let formattedString = value.toLocaleString(undefined, { minimumFractionDigits: 4 })
  formattedString = formattedString.replace(',', ', ')
  return formattedString
}
