import { beautifulDate } from '../../services/helpers/dates'
import { recupLabel } from '../../services/helpers/helpers'
import { optionsTypePeriodicite } from '../options/options'

// prevoyance
export const dataSocialPrevoyanceGeneral = (bien) => {
  return [
    {
      label: 'Catégorie :',
      value: bien.categorie,
    },
    {
      label: 'Sous-catégorie :',
      value: bien.sousCategorie,
    },
    {
      label: 'Assureur :',
      value: bien.assureur,
    },
    {
      label: 'Contrat :',
      value: bien.nomContrat,
    },
    {
      label: 'Numéro :',
      value: bien.numero,
    },
    {
      label: 'Libellé :',
      value: bien.libelle,
    },
  ]
}
export const dataSocialPrevoyanceTerms = (bien) => {
  return [
    {
      label: 'Souscription :',
      value: beautifulDate(bien.dateSouscription),
    },
    {
      label: 'Durée :',
      value: `${bien.duree.toLocaleString(undefined, { minimumFractionDigits: 4 })} mois`,
    },
    {
      label: 'Date de fin :',
      value: beautifulDate(bien.dateFin),
    },
    {
      label: 'Periodicité :',
      value: `${
        bien.frequenceCotisation && recupLabel(bien.frequenceCotisation, optionsTypePeriodicite)
      }`,
    },
    {
      label: 'Montant :',
      value: `${bien.montantEcheance.toLocaleString(undefined, { minimumFractionDigits: 4 })}`,
    },
    {
      label: "Date d'échéance :",
      value: beautifulDate(bien.dateEcheance),
    },
  ]
}
export const dataSocialPrevoyanceValue = (bien) => {
  return [
    {
      label: 'Capital :',
      value: `${bien.capital.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
    {
      label: 'Taux :',
      value: `${bien.taux.toLocaleString(undefined, { minimumFractionDigits: 4 })} %`,
    },
    {
      label: 'Rente estimée ou versée:',
      value: `${bien.renteEstime.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`,
    },
    {
      label: 'Periodicité des versements:',
      value: `${
        bien.periodiciteRente && recupLabel(bien.periodiciteRente, optionsTypePeriodicite)
      }`,
    },
  ]
}
