// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getIncomesError, getIncomesLoad, getIncomesSuccess } from '../../actions/Patrimoine/actionGetRevenus'

// REDUCER // ______________________________________________________________

// initial state
const initialStateIncomes = {
  isLoading: false,
  incomes: null,
  error: '',
}
// reducer
export const getIncomesReducer = createReducer(initialStateIncomes, (builder) => {
  return builder
    .addCase(getIncomesLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getIncomesSuccess, (draft, action) => {
      draft.isLoading = false
      draft.incomes = action.payload
      draft.error = ''
      return
    })
    .addCase(getIncomesError, (draft, action) => {
      draft.isLoading = false
      draft.incomes = null
      draft.error = action.payload
      return
    })
})