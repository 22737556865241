import React from 'react'
import { optionsTypePeriodicite } from '../../../../../../data/options/options'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import { dateFormateComplete } from '../../../../../../services/helpers/dates'

const BodyChargesPrint = ({ element, index }) => {
  return (
    <>
      {/* nom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">{!element.nom ? '-' : element.nom}</p>
      </div>
      {/* montantAnnuel */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Montant annuel</p>
        <p className="print__table__bloc__line--value">
          {!element.montantAnnuel
            ? '-'
            : `${element.montantAnnuel.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
        </p>
      </div>
      {/* periodicite */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Periodicité</p>
        <p className="print__table__bloc__line--value">
          {!element.periodicite ? '-' : recupLabel(element.periodicite, optionsTypePeriodicite)}
        </p>
      </div>
      {/* dateDebut */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Début</p>
        <p className="print__table__bloc__line--value">
          {!element.dateDebut ? '-' : dateFormateComplete(element.dateDebut)}
        </p>
      </div>
      {/* dateFin */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Fin</p>
        <p className="print__table__bloc__line--value">
          {element.dateFin === '' ? (
          <p className="informations__line__notxt">-</p>
        ) : element.dateFin ? (
          dateFormateComplete(element.dateFin)
        ) : (
          'en cours'
        )}
        </p>
      </div>
    </>
  )
}

export default BodyChargesPrint
