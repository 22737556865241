// ______________________________________ fichiers
// options type Fichier GED
export const optionsTypeFichierGed = [
  { value: 'IDENTITE', label: 'Identité' },
  { value: 'DOMICILE', label: 'Domicile' },
  { value: 'NAISSANCE', label: 'Naissance' },
  { value: 'FAMILLE', label: 'Famille' },
  { value: 'PATRIMOINE', label: 'Patrimoine' },
  { value: 'DIVERS', label: 'Divers' },
]
// options type Fichier GED
export const optionsAddTypeFichierGed = [
  { value: 'Identité', label: 'Identité' },
  { value: 'Domicile', label: 'Domicile' },
  { value: 'Naissance', label: 'Naissance' },
  { value: 'Famille', label: 'Famille' },
  { value: 'Divers', label: 'Divers' },
]
// options type Ged
export const optionsTypeGedIdentity = [
  {
    label: '- CNI -',
    options: [
      { value: 'CNI', label: 'CNI' },
      { value: 'CNI Recto', label: 'CNI Recto' },
      { value: 'CNI Verso', label: 'CNI Verso' },
    ],
  },
  {
    label: '- Passeport -',
    options: [{ value: 'Passeport français', label: 'Passeport français' }],
  },
  {
    label: "- Pièce d'identité secondaire -",
    options: [
      { value: "Pièce d'identité secondaire", label: "Pièce d'identité secondaire" },
      { value: "Pièce d'identité secondaire Recto", label: "Pièce d'identité secondaire-Recto" },
      { value: "Pièce d'identité secondaire Verso", label: "Pièce d'identité secondaire-Verso" },
    ],
  },
  {
    label: "- Pièce d'identité étrangère -",
    options: [{ value: "Pièce d'identité étrangère", label: "Pièce d'identité étrangère" }],
  },
  {
    label: '- Titre de séjour -',
    options: [{ value: 'Titre de séjour', label: 'Titre de séjour' }],
  },
]
export const optionsTypeGedHome = [
  {
    label: '- Attestation -',
    options: [
      { value: 'Attestation de domicile', label: 'Attestation de domicile' },
      { value: "Attestation d'hébergement", label: "Attestation d'hébergement" },
    ],
  },
  {
    label: '- Facture -',
    options: [
      { value: 'EDF-Eau-Gaz', label: 'EDF-Eau-Gaz' },
      { value: 'Téléphone/fixe/mobile/internet', label: 'Téléphone/fixe/mobile/internet' },
    ],
  },
  {
    label: '- Impôts -',
    options: [
      { value: "Avis d'imposition", label: "Avis d'imposition" },
      { value: 'Avis de non imposition', label: 'Avis de non imposition' },
      { value: "Taxe d'habitation", label: "Taxe d'habitation" },
    ],
  },
  {
    label: '- Titre / Quittance -',
    options: [
      { value: 'Titre de propriété', label: 'Titre de propriété' },
      { value: 'Quittance de loyer', label: 'Quittance de loyer' },
    ],
  },
  {
    label: '- Assurance -',
    options: [
      { value: "Attestation d'assurance habitation", label: "Attestation d'assurance habitation" },
    ],
  },
  {
    label: '- CAF -',
    options: [{ value: 'Relevé CAF avec APL', label: 'Relevé CAF avec APL' }],
  },
]
export const optionsTypeGedBirth = [
  {
    label: '- Acte de naissance -',
    options: [
      { value: 'Copie intégrale', label: 'Copie intégrale' },
      { value: 'Extrait avec filiation', label: 'Extrait avec filiation' },
      { value: 'Extrait sans filiation', label: 'Extrait sans filiation' },
    ],
  },
]
export const optionsTypeGedFamily = [
  {
    label: '- Acte de mariage -',
    options: [
      { value: 'Copie intégrale', label: 'Copie intégrale' },
      { value: 'Extrait avec filiation', label: 'Extrait avec filiation' },
      { value: 'Extrait sans filiation', label: 'Extrait sans filiation' },
    ],
  },
  {
    label: '- Contrat -',
    options: [
      { value: 'Contrat de mariage', label: 'Contrat de mariage' },
      { value: 'PACS', label: 'PACS' },
      { value: 'Convention matrimoniale', label: 'Convention matrimoniale' },
    ],
  },
  {
    label: '- Livret de famille -',
    options: [{ value: 'Livret de famille', label: 'Livret de famille' }],
  },
  {
    label: '- Donation et succession -',
    options: [
      { value: 'Donation', label: 'Donation' },
      { value: 'Testament', label: 'Testament' },
      { value: 'Succession', label: 'Succession' },
    ],
  },
]
export const optionsTypeGedPatrimoine = [
  {
    label: '-  Immobilier d’usage -',
    options: [
      { value: 'residencePrincipale', label: 'Résidence principale' },
      { value: 'residenceSecondaire', label: 'Résidence secondaire' },
      { value: 'autreBienImmoJouissance', label: 'Autre bien immobilier de jouissance' },
    ],
  },
  {
    label: '-  Immobilier de rapport -',
    options: [
      { value: 'locatifNu', label: 'Locatif habitation nue' },
      { value: 'locatifMeuble', label: 'Locatif habitation meublée' },
      { value: 'locatifPro', label: 'Locatif professionnel ou commercial' },
      { value: 'stationnement', label: 'Stationnement' },
      { value: 'autreLocatif', label: 'Autre immobilier locatif' },
    ],
  },
  {
    label: '-  Documents -',
    options: [
      { value: 'docComptable', label: 'Documents comptables' },
      { value: 'docFinancier', label: 'Documents financiers' },
      { value: 'docSocial', label: 'Documents social' },
      { value: 'docJuridique', label: 'Documents juridiques' },
      { value: 'autreDoc', label: 'Autres documents' },
    ],
  },
]
export const optionsTypeGedSignature = [
  {
    label: '- à définir -',
    options: [
      { value: 'value01', label: 'value01' },
      { value: 'value02', label: 'value02' },
      { value: 'value03', label: 'value03' },
    ],
  },
]
export const optionsTypeGedDivers = [
  {
    label: '- divers -',
    options: [
      { value: 'Rapport de signature', label: 'Rapport de signature' },
      { value: 'Procuration', label: 'Procuration' },
      { value: 'Accès', label: 'Accès' },
      { value: 'Inclassable', label: 'Inclassable' },
      { value: 'A remplacer', label: 'A remplacer' },
    ],
  },
]
