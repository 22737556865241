import React from 'react'
import Ok from '../Components/Modales/Ok'

const ModalAddUserOk = ({ setModalOK, setModalAddUser, setStatus }) => {
  const handleClick = () => {
    setStatus(null)
    setModalOK(false)
    setModalAddUser(false)
  }

  return <Ok text="Vous venez de créer un nouvel utilisateur !" close={handleClick} />
}

export default ModalAddUserOk
