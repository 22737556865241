import React from 'react'
import Modale from '../Components/Modale'

const ModalAddFileNotCorrect = ({ setModalAddFileNotCorrect }) => {
  return (
    <Modale
      close={() => setModalAddFileNotCorrect(false)}
      title="Incorrect"
      line1="Merci de remplir tous les champs correctement"
      line2="N'oubliez pas d'ajouter un fichier"
    >
      <div
        className="button button--blue-red width--12"
        onClick={() => setModalAddFileNotCorrect(false)}
      >
        Fermer
      </div>
    </Modale>
  )
}

export default ModalAddFileNotCorrect
