import React from 'react'
import { useSelector } from 'react-redux'
import { dataMainInfos, dataSociety } from '../../../data/informations/infosPP'
import InformationsCard from './InformationsCard'
import InformationsTableCard from './InformationsTableCard'
import PrintComponent from '../Components/PrintComponent'
import ButtonsRow from './ButtonsRow'
import { PM_BENEFICIAIRES, PM_ETABLISSEMENTS } from '../../../constants'
import InformationsPMPrint from './InformationsPMPrint'
import Loader from '../../layout/Loader'
import PapperLink from './PapperLink'
import useCustomPrint from '../../layout/useCustomPrint'

const InformationsPM = () => {
  // person
  const person = useSelector((state) => state.getPerson.person)
  // redirection
  const formCreateLink = '/user/comptes/ajouter-Personne-Morale'
  const formEditLink = '/user/informations/modifier-Personne-Morale'
  const backRedirection = -1
  // const backRedirection = '/user/comptes'

  // destructuring person
  const { beneficiairesEffectifs, etablissements } = person || {}
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()
  // titles
  const titlesBeneficiaires = [
    'N°',
    'Nom',
    'Prénom',
    'Date de naissance',
    'Date greffe',
    'Parts',
    'Pleine propriété',
    'Nue propriété',
  ]
  const titlesEtablissements = [
    'N°',
    'Siret',
    'NIC',
    'Adresse',
    'Complément',
    'Code postal',
    'Ville',
    'Pays',
    'Code pays',
    'Code NAF',
    'Libellé code NAF',
    'Établissement employeur',
    'Effectif',
    'Effectif min',
    'Effectif max',
    'Date de création',
    'Cessation',
    'Date de cessation',
    'Siège',
    'Enseigne',
    'Nom commercial',
  ]

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<InformationsPMPrint />}
        />
        <div className="cards cards--fullWidth">
          <ButtonsRow
            handlePrint={handlePrint}
            formCreateLink={formCreateLink}
            formEditLink={formEditLink}
            backLink={backRedirection}
          />
        </div>
        <div className="sousSection">
          {person.siren !== '' ? (
            <div className="cards cards--fullWidth">
              <PapperLink person={person} />
            </div>
          ) : null}
          <div className="cards">
            <InformationsCard title="Informations principales" data={dataMainInfos(person)} />
            <InformationsCard title="Societé" data={dataSociety(person)} />
          </div>
          <div className="cards cards--fullWidth">
            <InformationsTableCard
              title="Bénéficiaires effectifs"
              nothing="Aucun bénéficiaire à afficher"
              data={beneficiairesEffectifs}
              titles={titlesBeneficiaires}
              tableType={PM_BENEFICIAIRES}
              read={true}
            />
            <InformationsTableCard
              title="Établissements"
              nothing="Aucun établissement à afficher"
              data={etablissements}
              titles={titlesEtablissements}
              tableType={PM_ETABLISSEMENTS}
              read={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default InformationsPM
