import React, { useState } from 'react'
import BlocTable from '../../../../table/BlocTable'
import { SUPPORTS_DELETE } from '../../../../../../constants'
import TableBodySupports from '../Table/TableBodySupports'

const SelectionCard = ({ dicis, handleDelete }) => {
  const [toggleSelect, setToggleSelect] = useState(true)
  const titles = [
    'N°',
    'Supprimer',
    'Fournisseur',
    'Libellé',
    'ISIN',
    'Cours',
    'DICI',
    'Note',
    'Supprimer',
  ]

  return (
    <>
      {dicis.length > 0 ? (
        !toggleSelect ? (
          <div
            className="informations__dropdown informations__dropdown--full"
            onClick={() => setToggleSelect(!toggleSelect)}
          >
            <p>Sélection</p>
          </div>
        ) : (
          <div className="informations informations__dropdown--border">
            <p onClick={() => setToggleSelect(!toggleSelect)}>Sélection</p>
            <div className="informations">
              <BlocTable
                data={dicis}
                titles={titles}
                tableType={SUPPORTS_DELETE}
                children={<TableBodySupports handleClick={handleDelete} />}
                title={'Supports selectionnés'}
              />
            </div>
          </div>
        )
      ) : null}
    </>
  )
}

export default SelectionCard
