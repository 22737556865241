import React, { useEffect } from 'react'
import Input from '../Components/Input'
import { MdOutlinePictureAsPdf } from 'react-icons/md'
import SelectComponent from '../Components/SelectComponent'
import {
  optionsTypeGedBirth,
  optionsTypeGedDivers,
  optionsTypeGedFamily,
  optionsTypeGedHome,
  optionsTypeGedIdentity,
  optionsTypeGedPatrimoine,
} from '../../../data/options/optionsFiles'
import { useState } from 'react'
import { getValue, handleChange } from '../../../services/helpers/helpers'
import DateInputComponent from '../Components/DateInputComponent'

const InputsAddFiles = ({
  handleFileEvent,
  handleDragOver,
  handleDrop,
  files,
  handleAddFiles,
  update,
  newFile,
  setNewFile,
  fichiers,
  categorie,
  libelle,
  setLibelle,
  typeFichierGED,
}) => {
  const isOptionDisabled = (option) => {
    return fichiers.find((file) => file.typeGed === option.value) ? true : false
  }
  const formattedOptions = (options) => {
    return options.map((group) => ({
      label: group.label,
      options: group.options.map((option) => ({
        ...option,
        isDisabled: isOptionDisabled(option),
      })),
    }))
  }
  const { typeGed, dateDocument, dateValidite } = newFile || {}
  const options =
    typeFichierGED === 'IDENTITE'
      ? formattedOptions(optionsTypeGedIdentity)
      : typeFichierGED === 'DOMICILE'
      ? formattedOptions(optionsTypeGedHome)
      : typeFichierGED === 'NAISSANCE'
      ? formattedOptions(optionsTypeGedBirth)
      : typeFichierGED === 'FAMILLE'
      ? formattedOptions(optionsTypeGedFamily)
      : typeFichierGED === 'PATRIMOINE'
      ? optionsTypeGedPatrimoine
      : formattedOptions(optionsTypeGedDivers)

  const [change, setChange] = useState(false)
  useEffect(() => {
    if (typeGed !== undefined && dateDocument !== undefined && files.length > 0) {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [typeGed, dateDocument, dateValidite, files])
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setNewFile({ ...newFile, [name]: date })
  }

  return (
    <div className="informations">
      <div className="informations informations--borderFull">
        {/* type */}
        {!update && (
          <SelectComponent
            forAndId="typeGed"
            name="Type :"
            isSearchable={false}
            options={options}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name, newFile, setNewFile)}
            value={getValue(options, typeGed)}
            isDisabled={
              update || categorie === 'FINANCIER' || categorie === 'SOCIAL' ? true : false
            }
            required={true}
          />
        )}
        {!update ? (
          <Input
            forAndId="libelle"
            type="text"
            name="Libelle :"
            value={libelle}
            onChange={(e) => setLibelle(e.target.value)}
            copy={true}
          />
        ) : null}
        {/* Date du document */}
        <DateInputComponent
          name={'dateDocument'}
          label={'Date du document :'}
          value={dateDocument}
          handleChange={handleChangeDate}
        />
      </div>
      {/* file */}
      <div className="drop-container" onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragOver}
      onDragLeave={handleDragOver}>
        <p className="drop-title">
          Déposer vos fichiers<span>ou</span>
        </p>
        <Input
          forAndId="fichiers"
          type="file"
          accept=".pdf"
          name={'Choisir un ou plusieurs fichiers PDF'}
          onChange={(e) => handleFileEvent(e)}
          file={true}
          required={true}
          multiple={true}
        ></Input>
      </div>
      {/* Date de Validité */}
      {typeFichierGED === 'IDENTITE' && (
        <DateInputComponent
          name={'dateValidite'}
          label={'Date de validité :'}
          value={dateValidite}
          handleChange={handleChangeDate}
        />
      )}
      {files && files.length > 0 && (
        <ul className="fichiersList">
          {Array.from(files).map((el, index) => (
            <li key={index} className="fichiersList__item">
              <div className="fichiersList__item__icon">
                <MdOutlinePictureAsPdf />
              </div>
              <p className="fichiersList__item__text">{el.name}</p>
            </li>
          ))}
        </ul>
      )}
      {change ? (
        <div className="informations__buttons">
          <div className="button button--red-red width--12" onClick={() => handleAddFiles()}>
            Enregistrer
          </div>
        </div>
      ) : (
        <div className="informations__buttons">
          <div className="button button--grey width--12">Enregistrer</div>
        </div>
      )}
    </div>
  )
}

export default InputsAddFiles
