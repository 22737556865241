import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getCoursByDate } from '../../../../../../services/API/financier'
import { transformDateForFile } from '../../../../../../services/helpers/dates'
import Modale from '../../../../Components/Modale'
import FormOperationInputs from './FormOperationInputs'
import FormOperationTitle from './FormOperationTitle'
import FormOperationValidation from './FormOperationValidation'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'

const FormOperation = ({
  setDataChange,
  setToggleBuy,
  initialNewOp,
  newOperation,
  setNewOperation,
  handleSubmitNewOp,
  supportToChange,
  disponibilite,
  categorie,
  setDateIsOk,
  oldMontant,
  fondEuro,
}) => {
  const dispatch = useDispatch()
  const { nombre, valeurAchat, achat, support, isin } = newOperation || {}
  const { dateAchat } = support || {}
  const [dispo, setDispo] = useState(null)
  const [quantity, setQuantity] = useState(null)
  const [total, setTotal] = useState(false)
  // matchisOK
  const [matchisOK, setMatchIsOK] = useState(true)
  const [message, setMessage] = useState(null)
  // dataOperationChange
  const [dataNewOperationChange, setDataNewOperationChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // cours
  const [cours, setCours] = useState(null)
  // CHANGE ___________________________________________________________________________________

  // maj quantity
  useEffect(() => {
    if (supportToChange && valeurAchat !== '') {
      setQuantity(supportToChange?.quantite)
    } else {
      setQuantity(0)
    }
  }, [])
  // dataNewOperationChange
  useEffect(() => {
    // achat
    if (achat === true) {
      if (categorie !== 'AV' && categorie !== 'retraite') {
        dispo >= 0 && nombre && dateAchat && valeurAchat && matchisOK
          ? setDataNewOperationChange(true)
          : setDataNewOperationChange(false)
      } else {
        nombre && dateAchat && valeurAchat && ((!fondEuro && matchisOK) || fondEuro)
          ? setDataNewOperationChange(true)
          : setDataNewOperationChange(false)
      }
    } else {
      // vente
      if (!fondEuro) {
        quantity >= 0 && dateAchat && valeurAchat && matchisOK
          ? setDataNewOperationChange(true)
          : setDataNewOperationChange(false)
      } else {
        dateAchat &&
        (valeurAchat || valeurAchat === 0) &&
        Math.abs(dispo) - parseFloat(valeurAchat) >= 0
          ? setDataNewOperationChange(true)
          : setDataNewOperationChange(false)
      }
    }
  }, [nombre, valeurAchat, dateAchat, matchisOK, dispo, quantity])
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    switch (name) {
      case 'dateAchat':
        setNewOperation({
          ...newOperation,
          dateOperation: date,
          dateVersement: date,
          support: {
            ...support,
            dateAchat: date,
          },
        })
        setMatchIsOK(dateAchat === date ? true : false)
        !fondEuro && setDateIsOk(false)
        break
      case 'dateOperation':
        setNewOperation({
          ...newOperation,
          dateOperation: date,
          dateVersement: fondEuro ? date : '',
        })
        break
      case 'dateVersement':
        setNewOperation({
          ...newOperation,
          dateOperation: date,
          dateVersement: date,
        })
        break
      default:
        return
    }
  }
  // handlechange
  const handlechange = (e) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      let opCours
      let opQuantite
      const newValue = value.replace(/,/g, '.')
      switch (name) {
        case 'nombre':
          opCours = valeurAchat ? parseFloat(valeurAchat) : 0
          opQuantite = newValue ? parseFloat(newValue) : 0
          setNewOperation({
            ...newOperation,
            montant:
              achat === true
                ? Math.round(-opCours * opQuantite * 100) / 100
                : Math.round(opCours * opQuantite * 100) / 100,
            [name]: newValue,
            support: { ...support, [name]: newValue },
          })
          break
        case 'valeurAchat':
          opCours = newValue ? parseFloat(newValue) : 0
          opQuantite = nombre ? parseFloat(nombre) : 0
          setNewOperation({
            ...newOperation,
            montant:
              achat === true
                ? Math.round(-opCours * opQuantite * 100) / 100
                : Math.round(opCours * opQuantite * 100) / 100,
            [name]: newValue,
            support: { ...support, [name]: newValue },
          })
          break
        default:
          setNewOperation({ ...newOperation, [name]: value })
      }
    }
  }
  useEffect(() => {
    if (total) {
      setNewOperation({
        ...newOperation,
        cession: true,
        valeurAchat: fondEuro ? dispo : valeurAchat,
        nombre: fondEuro ? 1 : quantity,
        montant: fondEuro ? dispo : quantity * valeurAchat,
        support: {
          ...newOperation.support,
          nombre: fondEuro ? 1 : quantity,
          valeurAchat: fondEuro ? dispo : valeurAchat,
        },
      })
    } else {
      setNewOperation({
        ...newOperation,
        cession: false,
      })
    }
  }, [total])

  // handleClose
  const handleClose = () => {
    setDataChange(false)
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setNewOperation(initialNewOp)
    setToggleBuy(false)
  }
  // DISPLAY ________________________________________________________________________________
  // maj dispo et quantity
  useEffect(() => {
    if (achat === true) {
      categorie !== 'AV' && categorie !== 'retraite'
        ? setDispo(disponibilite - (nombre ? parseFloat(nombre) : 0) * valeurAchat)
        : fondEuro
        ? setDispo(supportToChange ? supportToChange.montant : 0)
        : setDispo((nombre ? parseFloat(nombre) : 0) * valeurAchat)
      setQuantity(
        supportToChange
          ? supportToChange.quantite + (nombre ? parseFloat(nombre) : 0)
          : parseFloat(nombre)
      )
    } else {
      categorie !== 'AV' && categorie !== 'retraite'
        ? setDispo(disponibilite + (nombre ? parseFloat(nombre) : 0) * valeurAchat)
        : fondEuro
        ? setDispo(supportToChange ? Math.abs(supportToChange.montant) : 0)
        : setDispo((nombre ? parseFloat(nombre) : 0) * valeurAchat)
      setQuantity(supportToChange.quantite - (nombre ? parseFloat(nombre) : 0))
    }
  }, [nombre, valeurAchat])
  // maj message
  useEffect(() => {
    if (achat === true) {
      if (categorie === 'AV' || categorie === 'retraite') {
        setMessage(null)
      } else {
        dispo < 0 ? setMessage('Attention : disponibilités insuffisantes') : setMessage(null)
      }
    } else {
      if (categorie === 'AV' || categorie === 'retraite') {
        if (fondEuro) {
          Math.abs(dispo) - parseFloat(valeurAchat) < 0
            ? setMessage("Attention : retrait supérieur à l'investissement")
            : setMessage(null)
        } else {
          quantity < 0 ? setMessage('Attention : quantité détenue insuffisante') : setMessage(null)
        }
      } else {
        quantity < 0 ? setMessage('Attention : quantité détenue insuffisante') : setMessage(null)
      }
    }
  }, [dispo, quantity, categorie, valeurAchat, fondEuro])

  // SUBMIT _________________________________________________________________________________

  // handlePreSubimt
  const handlePreSubimt = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmitNewOp()
  }
  // checkDate
  const checkDate = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getCoursByDate(isin, transformDateForFile(dateAchat), setCours, setStatus))
  }
  // response getCoursByDate
  useEffect(() => {
    if (status) {
      if (status < 400) {
        setNewOperation({
          ...newOperation,
          valeurAchat: cours ? cours : valeurAchat,
          nombre: parseFloat(nombre),
          montant: cours
            ? achat
              ? -cours * parseFloat(nombre)
              : cours * parseFloat(nombre)
            : achat
            ? -valeurAchat * parseFloat(nombre)
            : valeurAchat * parseFloat(nombre),
        })
      } else {
        setNewOperation({
          ...newOperation,
          valeurAchat: 0,
          nombre: parseFloat(nombre),
          montant: 0,
        })
      }
      setStatus(null)
      setMatchIsOK(true)
      setDateIsOk(true)
    }
  }, [status])

  return (
    <Modale
      close={() => handleClose()}
      title={achat === true ? (fondEuro ? 'Versement' : 'Achat') : fondEuro ? 'Retrait' : 'Vente'}
    >
      <div className="modalContent">
        {/* title */}
        <FormOperationTitle
          newOperation={newOperation}
          categorie={categorie}
          dispo={dispo}
          quantity={quantity}
          fondEuro={fondEuro}
          achat={achat}
          valeurAchat={valeurAchat}
          oldMontant={oldMontant}
        />
        <div className="informations">
          {/* inputs */}
          <FormOperationInputs
            handlechange={handlechange}
            newOperation={newOperation}
            fondEuro={fondEuro}
            handleChangeDate={handleChangeDate}
            total={total}
            setTotal={setTotal}
            categorie={categorie}
          />
        </div>
        <div className="informations">
          {/* validation or not */}
          <FormOperationValidation
            matchisOK={matchisOK}
            handlePreSubimt={handlePreSubimt}
            handleClose={handleClose}
            dataNewOperationChange={dataNewOperationChange}
            message={message}
            checkDate={checkDate}
            newOperation={newOperation}
            fondEuro={fondEuro}
          />
        </div>
      </div>
    </Modale>
  )
}

export default FormOperation
