import React from 'react'
import { stringToCapitalize } from '../../../../../../services/helpers/strings'

const BodyBienAutre = ({ element, handleShow }) => {
  return (
    <>
      {/* nom */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.nom ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          stringToCapitalize(element.nom)
        )}
      </td>
      {/* valeur */}
      <td className="tbody__tr__td tbody__tr__td--pointer" onClick={() => handleShow(element)}>
        {!element.valeur && element.valeur !== 0 ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          `${element.valeur.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`
        )}
      </td>
    </>
  )
}

export default BodyBienAutre
