import axios from 'axios'
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants'
import { getMandatLoad, getMandatSuccess } from '../../redux/actions/actionGetMandat'
import {
  getPersonsError,
  getPersonsLoad,
  getPersonsSuccess,
} from '../../redux/actions/actionGetPersons'
import { toast } from 'react-toastify'

// création PP
export const postPP = (data, setStatus) => {
  const toastLoading = toast.loading(`Enregistrement en cours...`)
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getMandatLoad())
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/users/addPersonnePhysique`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        dispatch(getMandatSuccess(response.data))
        toast.dismiss(toastLoading)
        toast.success('Personne créée avec succès')
        dispatch(getPersonnes())
        setStatus(response.status)
      })
      .catch((error) => {
        dispatch(getMandatSuccess(error.response.data))
        toast.dismiss(toastLoading)
        error.response.status !== 301
        ? toast.error(`Erreur : ${error.message}`)
        : toast.success('Personne créée avec succès')
        dispatch(getPersonnes())
        setStatus(error.response.status)
      })
  }
}

// update PP
export const updatePP = (data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/users/updatePersonnePhysique`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}

// création PM
export const postPM = (data, setStatusPostPM) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/users/addPersonneMorale`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatusPostPM(response.status)
      })
      .catch((error) => {
        console.log(error.response)
        setStatusPostPM(error.response.status)
      })
  }
}
// update PM
export const updatePM = (data, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/users/updatePersonneMorale`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}

// recupération liste Personnes
export const getPersonnes = () => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return (dispatch) => {
    dispatch(getPersonsLoad())
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/users/personnes/user`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        dispatch(getPersonsSuccess(response.data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(getPersonsError(error.message))
      })
  }
}
// deletePP
export const deletePP = (id, setStatus) => {
  const token = sessionStorage.getItem(ACCESS_TOKEN)
  return () => {
    axios({
      method: 'DELETE',
      url: `${API_BASE_URL}/users/deletePP/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        setStatus(error.response.status)
      })
  }
}
// recupPM
export const recupPM = (id, array) => {
  let pm = array.filter((el) => el.id === id)[0]
  return pm ? pm : null
}
