import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
const BarChartCompoent = ({ data, colors, print }) => {
  return print ? (
    <BarChart
      width={800}
      height={800}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="2 2" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <ReferenceLine y={0} stroke="#000" />
      {colors.map((color, index) => (
        <Bar key={index} dataKey={color.dataKey} stackId={color.stackId} fill={color.fill} />
      ))}
    </BarChart>
  ) : (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={300}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <ReferenceLine y={0} stroke="#000" />
        {colors.map((color, index) => (
          <Bar key={index} dataKey={color.dataKey} stackId={color.stackId} fill={color.fill} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarChartCompoent
