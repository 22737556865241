import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import { postActif } from '../../../../../../services/API/actifs'
import { supprId } from '../../../../../../services/helpers/helpers'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../services/helpers/detenteurs'
import ModaleDivers from '../../../Patrimoine/ModaleDivers'
import FormPatrimoineImmoContent from './FormPatrimoineImmoContent'
import NotCorrect from '../../../../Components/Modales/NotCorrect'
import Ok from '../../../../Components/Modales/Ok'
import NotOk from '../../../../Components/Modales/NotOk'
import { DOC_BIEN_IMMO } from '../../../../../../constants'
import ModaleFiles from '../../../../Files/ModaleFiles'
import usePersonData from '../../../../../layout/usePersonData'

const FormPatrimoineImmo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const properties = useSelector((state) => state.getProperties.properties)
  const actifsDivers = properties ? properties.actifsDivers.montant : null
  const immoDivers = properties ? properties.actifsImmobilier.bienDivers.montant : null
  const bien = useSelector((state) => state.getBien.bien)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  // modales
  const [toggleAddPatrimoine, setToggleAddPatrimoine] = useState(false)
  const [toggleAddBienOk, setToggleAddBienOk] = useState(false)
  const [toggleAddBienNotOk, setToggleAddBienNotOk] = useState(false)
  const [toggleAddNotCorrect, setToggleNotCorrect] = useState(false)
  const [toggleDivers, setToggleDivers] = useState(false)
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    typeImmobilier: '',
    nom: '',
    typeBien: '',
    dispositif: 'Aucun',
    risque: 'SECURITAIRE',
    valeur: 0,
    dateValeur: '',
    adresse: '',
    codePostal: '',
    ville: '',
    surface: '',
    dateAcquisition: '',
    valeurAcquisition: 0,
    closOuVendu: false,
    valeurVente: 0,
    dateVente: '',
    detention: 'CLIENT',
    tauxIndivision: 100,
    detenteurs: [],
    fichiers: [],
    typeActif: '',
    idActif: 0,
    codeImpot: '',
  })
  const {
    id,
    typeImmobilier,
    nom,
    typeBien,
    dispositif,
    risque,
    valeur,
    dateValeur,
    adresse,
    codePostal,
    ville,
    surface,
    dateAcquisition,
    valeurAcquisition,
    closOuVendu,
    valeurVente,
    dateVente,
    detention,
    tauxIndivision,
    detenteurs,
    fichiers,
    typeActif,
    idActif,
    codeImpot,
  } = information || {}
  //
  const [divers, setDivers] = useState(true)
  const [fichiersToDisplay, setFichiersToDisplay] = useState([])
  const [detenteursToDisplay, setDetenteursToDisplay] = useState(detenteurs)
  const [newValeurTotale, setNewValeurTotale] = useState()
  // maj fichiers
  useEffect(() => {
    bien && setFichiersToDisplay(bien.fichiers)
  }, [bien])
  // valeur totale de calcul
  useEffect(() => {
    closOuVendu ? setNewValeurTotale(0) : setNewValeurTotale((valeur * tauxIndivision) / 100)
  }, [valeur, tauxIndivision, closOuVendu])
  // maj si bien
  useEffect(() => {
    bien
      ? setInformation({
          ...information,
          id: bien.id,
          typeImmobilier: bien.typeImmobilier,
          nom: bien.nom,
          typeBien: bien.typeBien,
          dispositif: bien.dispositif,
          risque: bien.risque,
          valeur: bien.valeur,
          dateValeur: bien.dateValeur ? bien.dateValeur : '',
          adresse: bien.adresse,
          codePostal: bien.codePostal,
          ville: bien.ville,
          surface: bien.surface,
          dateAcquisition: bien.dateAcquisition ? bien.dateAcquisition : '',
          valeurAcquisition: bien.valeurAcquisition,
          closOuVendu: bien.closOuVendu,
          valeurVente: bien.valeurVente,
          dateVente: bien.dateVente ? bien.dateVente : '',
          detention: bien.detention,
          tauxIndivision: bien.tauxIndivision,
          detenteurs: bien.detenteurs
            .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
            .reverse(),
          fichiers: bien.fichiers,
          typeActif: bien.typeActif,
          idActif: bien.idActif,
          codeImpot: bien.codeImpot,
        })
      : setInformation({
          ...information,
          detenteurs: [
            {
              id: 0,
              nomDetenteur:
                personType === 'referentPP'
                  ? `${person.nom} ${person.prenom}`
                  : `${person.denominationSociale}`,
              mailDetenteur: person.mail,
              idPP: personType === 'referentPP' ? 1 : 0,
              idPM: personType === 'dirigeantPM' ? 1 : 0,
              dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
              dateContitutionUsufruit: '',
              dureeDemembrement: '',
              typeDetention: 'PLEINEPROPRIETE',
              tauxPleinePropriete: 100,
              tauxUsufruit: 0,
              tauxUsufruitCalcule: handleCalculateTaux(person.dateNaissance),
              tauxNuePropriete: 0,
              valeurDetenue: 0,
              valeurPP: 0,
              valeurUS: 0,
              valeurNP: 0,
              tempId: Date.now(),
            },
          ],
        })
  }, [])
  // maj
  useEffect(() => {
    if (properties && bien && bien.id !== 0) {
      const newBien = properties.actifsImmobilier.biensImmobilier.filter((el) => el.id === bien.id)
      dispatch(getBienSuccess(newBien[0]))
    }
  }, [properties, bien])
  // form Bien Immo is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Bien Immo' }))
    }
  }, [dataChange])
  // save Bien immo
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Bien Immo') {
      handlePreSubmit()
    }
  }, [saveBien])
  // Object to send
  const data = {
    id: id,
    typeImmobilier: typeImmobilier,
    nom: nom,
    typeBien: typeBien,
    dispositif: dispositif,
    risque: risque,
    valeur: valeur,
    dateValeur: dateValeur,
    adresse: adresse,
    codePostal: codePostal,
    ville: ville,
    surface: surface,
    dateAcquisition: dateAcquisition,
    valeurAcquisition: valeurAcquisition,
    closOuVendu: closOuVendu,
    valeurVente: valeurVente,
    dateVente: dateVente,
    detention: detention,
    tauxIndivision: tauxIndivision,
    detenteurs:
      detenteursToDisplay &&
      detenteursToDisplay.map((det) => updateDetenteurs(det, newValeurTotale, detenteursToDisplay)),
    fichiers: fichiers,
    typeActif: typeActif,
    idActif: idActif,
    codeImpot: codeImpot,
  }
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      setInformation({ ...information, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      switch (name) {
        case 'divers':
          setDivers(value)
          break
        case 'closOuVendu':
          if (value) {
            setInformation({ ...information, [name]: value })
          } else {
            setInformation({ ...information, [name]: value, valeurVente: 0, dateVente: '' })
          }
          break
        default:
          setInformation({ ...information, [name]: value })
      }
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setInformation({ ...information, [name]: date })
  }

  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (
      typeImmobilier !== '' &&
      nom !== '' &&
      typeBien !== '' &&
      dispositif !== '' &&
      valeur !== ''
    ) {
      setDetenteursToDisplay(
        detenteursToDisplay.map((det) => (det.newDetenteur ? supprId(det) : det))
      )
      actifsDivers === 0 && immoDivers === 0 ? handleSubmit() : setToggleDivers(true)
    } else {
      setToggleNotCorrect(true)
    }
  }
  // submit
  const handleSubmit = () => {
    dispatch(postActif('bien', person.id, isPP, divers, data, setStatus))
    setToggleDivers(false)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (bien && bien.id !== 0) {
      navigate('/user/patrimoine/actifs/immobilier/bien')
    } else {
      navigate('/user/patrimoine/actifs/immobilier')
    }
  }
  // response create Bien status and modal
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setStatus(null)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
      setToggleAddBienOk(true)
    }
    if (status >= 400) {
      setStatus(null)
      setToggleAddBienNotOk(true)
    }
  }, [status])
  // maj detenteurs
  // useEffect(() => {
  //   if (detenteursToDisplay.length > 0) {
  //     setInformation({ ...information, detenteurs: detenteursToDisplay })
  //   }
  // }, [detenteursToDisplay])

  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setToggleAddBienOk(false)
    navigate('/user/patrimoine/actifs/immobilier')
  }

  return (
    <div className="section__content">
      {/* form */}
      <FormPatrimoineImmoContent
        bienToDisplay={bien}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        information={information}
        setInformation={setInformation}
        divers={divers}
        detenteursToDisplay={detenteursToDisplay}
        setDetenteursToDisplay={setDetenteursToDisplay}
        fichiersToDisplay={fichiersToDisplay}
        setFichiersToDisplay={setFichiersToDisplay}
        setToggleAddPatrimoine={setToggleAddPatrimoine}
        handleSubmit={handlePreSubmit}
        newValeurTotale={newValeurTotale}
        handleGiveUp={handleGiveUp}
      />
      {/* modales */}
      {toggleAddPatrimoine && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_BIEN_IMMO}
          setModal={setToggleAddPatrimoine}
          setDataChange={setDataChange}
        />
      )}
      {toggleAddBienOk && (
        <Ok
          text={
            bien ? 'Votre bien a été modifié avec succès' : 'Votre bien a été ajouté avec succès'
          }
          close={closeToggleAddBien}
        />
      )}
      {toggleAddBienNotOk && (
        <NotOk setNotOK={setToggleAddBienNotOk} closeModale={closeToggleAddBien} />
      )}
      {toggleAddNotCorrect && <NotCorrect setNotCorrect={setToggleNotCorrect} />}
      {toggleDivers && (
        <ModaleDivers
          setToggleDivers={setToggleDivers}
          handleChange={handleChange}
          divers={divers}
          setDivers={setDivers}
          handleSubmit={handleSubmit}
          montantCategorieDivers={actifsDivers}
          montantSousCategorieDivers={immoDivers}
          type={'actif'}
          sousType={'immobilier'}
        />
      )}
    </div>
  )
}

export default FormPatrimoineImmo
