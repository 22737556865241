import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { getSaveForm } from '../../../../../../redux/actions/actionSaveForm'
import { postPassif } from '../../../../../../services/API/passifs'
import { postCharge } from '../../../../../../services/API/charges'
import {
  beautifulDateNumbers,
  getDateEnd,
  getMonths,
} from '../../../../../../services/helpers/dates'
import {
  handleCalculateTaux,
  updateDetenteurs,
} from '../../../../../../services/helpers/detenteurs'
import { supprId } from '../../../../../../services/helpers/helpers'
import ModaleDivers from '../../../Patrimoine/ModaleDivers'
import FormPassifEmpruntContent from './FormPassifEmpruntContent'
import NotCorrect from '../../../../Components/Modales/NotCorrect'
import Ok from '../../../../Components/Modales/Ok'
import NotOk from '../../../../Components/Modales/NotOk'
import { DOC_PASSIF_EMPRUNT } from '../../../../../../constants'
import ModaleFiles from '../../../../Files/ModaleFiles'
import usePersonData from '../../../../../layout/usePersonData'

const FormPassifEmprunt = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, personType, isPP } = usePersonData()
  const passifs = useSelector((state) => state.getPassifs.passifs)
  const passifsDivers = passifs ? passifs.passifDivers.montant : null
  const empruntsDivers = passifs ? passifs.emprunts.empruntDivers.montant : null
  const charges = useSelector((state) => state.getCharges.charges)
  const emprunt = useSelector((state) => state.getBien.bien)
  // save
  const formIsActive = useSelector((state) => state.formIsActive.isActive)
  const saveBien = useSelector((state) => state.saveForm.save)
  const [dataChange, setDataChange] = useState(false)
  // response
  const [status, setStatus] = useState(null)
  const [statusCharge, setStatusCharge] = useState(null)
  // modales
  const [toggleAddEmprunt, setToggleAddEmprunt] = useState(false)
  const [toggleAddEmpruntOk, setToggleAddEmpruntOk] = useState(false)
  const [toggleAddEmpruntNotOk, setToggleAddEmpruntNotOk] = useState(false)
  const [toggleAddEmpruntNotCorrect, setToggleAddEmpruntNotCorrect] = useState(false)
  const [toggleDivers, setToggleDivers] = useState(false)

  // maj passif
  useEffect(() => {
    if (emprunt) {
      setFichiersToDisplay(emprunt.fichiers)
    }
  }, [emprunt])
  // Informations
  const [information, setInformation] = useState({
    id: 0,
    montantEmprunte: 0,
    montantRestant: 0,
    mensualite: 0,
    dureeMois: 0,
    refEmprunt: '',
    dateDebut: '',
    dateFin: '',
    interet: 0,
    montantAssurance: 0,
    differeAmortissement: 0,
    detention: 'CLIENT',
    tauxIndivision: 100,
    detenteurs: [],
    fichiers: [],
    typeActif: '',
    idActif: 0,
    codeImpot: '',
    montantInteret: 0,
  })
  const {
    id,
    montantEmprunte,
    montantRestant,
    mensualite,
    dureeMois,
    refEmprunt,
    dateDebut,
    dateFin,
    interet,
    montantAssurance,
    differeAmortissement,
    detention,
    tauxIndivision,
    detenteurs,
    fichiers,
    typeActif,
    idActif,
    codeImpot,
  } = information || {}
  //
  const [divers, setDivers] = useState(true)
  const [fichiersToDisplay, setFichiersToDisplay] = useState([])
  const [detenteursToDisplay, setDetenteursToDisplay] = useState([])
  const [newValeurTotale, setNewValeurTotale] = useState()
  // valeur totale de calcul
  useEffect(() => {
    if (montantRestant === 0) {
      setNewValeurTotale(0)
    } else {
      setNewValeurTotale((montantRestant * tauxIndivision) / 100)
    }
  }, [montantRestant, tauxIndivision])
  // maj si passifToDisplay
  useEffect(() => {
    emprunt
      ? setInformation({
          ...information,
          id: emprunt.id,
          montantEmprunte: emprunt.montantEmprunte,
          montantRestant: emprunt.montantRestant,
          mensualite: emprunt.mensualite,
          dureeMois: emprunt.dureeMois,
          refEmprunt: emprunt.refEmprunt,
          dateDebut: emprunt.dateDebut,
          dateFin: emprunt.dateFin,
          interet: emprunt.interet,
          montantAssurance: emprunt.montantAssurance,
          differeAmortissement: emprunt.differeAmortissement,
          detention: emprunt.detention,
          tauxIndivision: emprunt.tauxIndivision,
          detenteurs: emprunt.detenteurs
            .map((det, index) => ({ ...det, tempId: Date.now() * (index + 1) }))
            .reverse(),
          fichiers: emprunt.fichiers,
          typeActif: emprunt.typeActif,
          idActif: emprunt.idActif,
          codeImpot: emprunt.codeImpot,
          montantInteret: calculerMontantInteret(mensualite, dureeMois, montantEmprunte),
        })
      : setInformation({
          ...information,
          detenteurs: [
            {
              id: 0,
              nomDetenteur:
                personType === 'referentPP'
                  ? `${person.nom} ${person.prenom}`
                  : `${person.denominationSociale}`,
              mailDetenteur: person.mail,
              idPP: personType === 'referentPP' ? 1 : 0,
              idPM: personType === 'dirigeantPM' ? 1 : 0,
              dateNaissance: personType === 'referentPP' ? person.dateNaissance : '',
              dateContitutionUsufruit: '',
              dureeDemembrement: '',
              typeDetention: 'PLEINEPROPRIETE',
              tauxPleinePropriete: 100,
              tauxUsufruit: 0,
              tauxUsufruitCalcule: handleCalculateTaux(person.dateNaissance),
              tauxNuePropriete: 0,
              valeurDetenue: 0,
              valeurPP: 0,
              valeurUS: 0,
              valeurNP: 0,
              tempId: Date.now(),
            },
          ],
        })
  }, [])
  // form emprunt is active
  useEffect(() => {
    if (dataChange) {
      dispatch(getFormIsActive({ isActive: true, form: 'Passif Emprunt' }))
    }
  }, [dataChange])
  // save emprunt
  useEffect(() => {
    if (saveBien && formIsActive.form === 'Passif Emprunt') {
      handlePreSubmit()
    }
  }, [saveBien])
  // Object to send
  const data = {
    id: id,
    montantEmprunte: parseInt(montantEmprunte),
    montantRestant: parseFloat(montantRestant),
    mensualite: parseFloat(mensualite),
    dureeMois: parseInt(dureeMois),
    refEmprunt: refEmprunt,
    dateDebut: dateDebut,
    dateFin: dateFin,
    interet: interet,
    montantAssurance: montantAssurance,
    differeAmortissement: differeAmortissement,
    detention: detention,
    tauxIndivision: tauxIndivision,
    detenteurs:
      detenteursToDisplay &&
      detenteursToDisplay.map((det) => updateDetenteurs(det, newValeurTotale, detenteursToDisplay)),
    fichiers: fichiers,
    typeActif: typeActif,
    idActif: idActif,
    codeImpot: codeImpot,
  }
  // actualisation detenteurs
  useEffect(() => {
    setDetenteursToDisplay(detenteurs)
  }, [detenteurs])
  // calcul montant restant
  const returnMontantRestant = (dateDebut, dateFin, mensualite) => {
    const today = beautifulDateNumbers(new Date())
    const debut =
      beautifulDateNumbers(dateDebut) > beautifulDateNumbers(new Date())
        ? beautifulDateNumbers(dateDebut)
        : beautifulDateNumbers(new Date())
    const end = beautifulDateNumbers(dateFin)
    if (end < today) {
      return 0
    } else {
      return getMonths(debut, end) * mensualite
    }
  }
  // maj montant restant
  useEffect(() => {
    if (dateFin !== '' && dateFin !== 'Invalid Date' && mensualite !== '' && mensualite !== 0) {
      setInformation({
        ...information,
        montantRestant: Math.ceil(returnMontantRestant(dateDebut, dateFin, mensualite) * 100) / 100,
      })
    }
  }, [dateFin, mensualite])
  // calcul mensualités
  const calculerMensualite = (montantEmprunte, dureeMois, tauxMensuel) => {
    const mensualite =
      (montantEmprunte * ((tauxMensuel / 100) * Math.pow(1 + tauxMensuel / 100, dureeMois))) /
      (Math.pow(1 + tauxMensuel / 100, dureeMois) - 1)

    return mensualite
  }
  const calculerTauxMensuel = (interet) => {
    const tauxMensuel = Math.round((Math.pow(1 + interet / 100, 1 / 12) - 1) * 100 * 10000) / 10000
    return tauxMensuel
  }
  // calcul Montant interets
  const calculerMontantInteret = (mensualite, dureeMois, montantEmprunte) => {
    const montantInteret = mensualite * dureeMois - montantEmprunte
    return montantInteret
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    switch (name) {
      case 'dateDebut':
        setInformation({
          ...information,
          [name]: date,
          dateFin: getDateEnd(date, dureeMois),
        })
        break
      default:
        setInformation({ ...information, [name]: date })
    }
  }
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      switch (name) {
        case 'dureeMois':
          setInformation({
            ...information,
            [name]: value,
            mensualite: Math.ceil((montantEmprunte / value) * 100) / 100,
          })
          if (dateDebut !== '') {
            setInformation({
              ...information,
              [name]: value,
              dateFin: getDateEnd(dateDebut, value),
            })
          }
          break
        case 'mensualite':
          setInformation({
            ...information,
            [name]: value,
            dureeMois: Math.ceil(montantEmprunte / value),
          })
          break
        case 'montantEmprunte':
          setInformation({
            ...information,
            [name]: value,
            mensualite: Math.ceil((value / dureeMois) * 100) / 100,
          })
          break
        case 'interet':
          setInformation({ ...information, [name]: value })
          break
        default:
          setInformation({ ...information, [name]: value })
      }
    } else {
      const name = selectName.name
      const value = e.value
      switch (name) {
        case 'divers':
          setDivers(value)
          break
        default:
          setInformation({ ...information, [name]: value })
      }
    }
  }
  useEffect(() => {
    if (interet !== 0 && dureeMois !== 0 && montantEmprunte !== 0) {
      const newMonthly = calculerMensualite(
        parseFloat(montantEmprunte),
        parseFloat(dureeMois),
        calculerTauxMensuel(parseFloat(interet))
      )
      setInformation({
        ...information,
        mensualite: parseFloat(Math.round(newMonthly * 100) / 100),
        montantInteret: calculerMontantInteret(
          parseFloat(Math.round(newMonthly * 100) / 100),
          dureeMois,
          montantEmprunte
        ),
      })
    }
  }, [interet])
  // preSubmit
  const handlePreSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (
      montantEmprunte !== '' &&
      montantEmprunte !== 0 &&
      refEmprunt !== '' &&
      dureeMois !== 0 &&
      mensualite !== '' &&
      mensualite !== 0 &&
      dateDebut !== ''
    ) {
      setDetenteursToDisplay(
        detenteursToDisplay.map((det) => (det.newDetenteur ? supprId(det) : det))
      )
      passifsDivers === 0 && empruntsDivers === 0 ? handleSubmit() : setToggleDivers(true)
    } else {
      setToggleAddEmpruntNotOk(true)
    }
  }
  // submit
  const handleSubmit = () => {
    if (data.idActif !== 0) {
      const idCharge =
        charges.empruntEtDettes.empruntEtDettes.filter((el) => el.nom === refEmprunt).length > 0
          ? charges.empruntEtDettes.empruntEtDettes.filter((el) => el.nom === refEmprunt)[0].id
          : 0
      const dataChargeEmrpunt = {
        id: idCharge,
        nom: refEmprunt,
        detail: "Remboursement d'emprunt",
        montantAnnuel: 12 * mensualite,
        periodicite: 'MENSUEL',
        dateDebut: dateDebut,
        dateFin: dateFin,
        detention: detention,
        tauxIndivision: tauxIndivision,
        detenteurs: data.detenteurs,
        fichiers: fichiers,
        typeActif: typeActif,
        idActif: idActif,
      }
      dispatch(
        postCharge('empruntEtDette', person.id, isPP, true, dataChargeEmrpunt, setStatusCharge)
      )
    }
    dispatch(postPassif('emprunt', person.id, isPP, divers, data, setStatus))
    setToggleDivers(false)
  }
  // abandon
  const handleGiveUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    if (emprunt && emprunt.id !== 0) {
      navigate('/user/patrimoine/passifs/emprunts/info')
    } else {
      navigate('/user/patrimoine/passifs/emprunts')
    }
  }
  // response create passif status and modal
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setStatus(null)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
      setToggleAddEmpruntOk(true)
    }
    if (status >= 400) {
      setStatus(null)
      setToggleAddEmpruntNotOk(true)
    }
  }, [status])
  // status post charge
  useEffect(() => {
    if (statusCharge >= 200 && statusCharge < 300) {
      setStatus(null)
      dispatch(getFormIsActive(null))
      dispatch(getSaveForm(false))
    }
    if (statusCharge >= 400) {
      setStatus(null)
    }
  }, [statusCharge])
  // maj detenteurs
  // useEffect(() => {
  //   if (detenteursToDisplay.length > 0) {
  //     setInformation({ ...information, detenteurs: detenteursToDisplay })
  //   }
  // }, [detenteursToDisplay])

  // close
  const closeToggleAddBien = () => {
    dispatch(getFormIsActive(null))
    dispatch(getSaveForm(false))
    setToggleAddEmpruntOk(false)
    navigate('/user/patrimoine/passifs/emprunts')
  }
  // recuperation passifs
  useEffect(() => {
    if (passifs && emprunt) {
      const newBien = passifs.emprunts.lemprunts.filter((el) => el.id === emprunt.id)
      dispatch(getBienSuccess(newBien[0]))
    }
  }, [passifs])

  return (
    <div className="section__content">
      {/* form */}
      <FormPassifEmpruntContent
        empruntToDisplay={emprunt}
        dataChange={dataChange}
        setDataChange={setDataChange}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        information={information}
        setInformation={setInformation}
        divers={divers}
        detenteursToDisplay={detenteursToDisplay}
        setDetenteursToDisplay={setDetenteursToDisplay}
        fichiersToDisplay={fichiersToDisplay}
        setFichiersToDisplay={setFichiersToDisplay}
        setToggleAddEmprunt={setToggleAddEmprunt}
        handleSubmit={handlePreSubmit}
        newValeurTotale={newValeurTotale}
        handleGiveUp={handleGiveUp}
      />
      {/* modales */}
      {toggleAddEmprunt && (
        <ModaleFiles
          data={fichiers}
          tableType={DOC_PASSIF_EMPRUNT}
          setModal={setToggleAddEmprunt}
          setDataChange={setDataChange}
        />
      )}
      {toggleAddEmpruntOk && (
        <Ok
          text={
            emprunt
              ? 'Votre emprunt a été modifié avec succès'
              : 'Votre emprunt a été ajouté avec succès'
          }
          close={closeToggleAddBien}
        />
      )}
      {toggleAddEmpruntNotOk && (
        <NotOk setNotOK={setToggleAddEmpruntNotOk} closeModale={closeToggleAddBien} />
      )}
      {toggleAddEmpruntNotCorrect && <NotCorrect setNotCorrect={setToggleAddEmpruntNotCorrect} />}
      {toggleDivers && (
        <ModaleDivers
          setToggleDivers={setToggleDivers}
          handleChange={handleChange}
          divers={divers}
          setDivers={setDivers}
          handleSubmit={handleSubmit}
          montantCategorieDivers={passifsDivers}
          montantSousCategorieDivers={empruntsDivers}
          type={'passif'}
          sousType={'emprunts'}
        />
      )}
    </div>
  )
}

export default FormPassifEmprunt
