import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deletePassif } from '../../../../services/API/passifs'
import { deleteActif } from '../../../../services/API/actifs'
import { deleteRevenu } from '../../../../services/API/revenus'
import Modale from '../../Components/Modale'
import { deleteCharge } from '../../../../services/API/charges'
import { deleteOperationFinancier, deleteProduct } from '../../../../services/API/financier'
import { deleteCatalogue, getCatalogues } from '../../../../services/API/catalogue'
import { deleteOperationSocial, deleteSocial } from '../../../../services/API/social'
import { getBienSuccess } from '../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../redux/actions/actionGetBienType'
import NotOk from '../../Components/Modales/NotOk'
import * as data from '../../../../constants'
import usePersonData from '../../../layout/usePersonData'

const DeleteBien = ({ setModalDelete, newData, tableType }) => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  const [status, setStatus] = useState(null)
  const [modalOk, setModalOk] = useState(null)
  const [modalNotOk, setModalNotOk] = useState(null)
  const bien = useSelector((state) => state.getBien.bien)
  const bienType = useSelector((state) => state.getBienType.type)
  // texte Modale
  const [libelle, setLibelle] = useState('')
  const getLibelleFromTableType = (tableType, newData) => {
    const typesAvecNom = [
      data.ACTIFS_IMMO,
      data.ACTIFS_PRO,
      data.ACTIFS_AUTRES,
      data.CHARGES_GENERALES,
      data.CHARGES_DEDUCTIBLES,
      data.CHARGES_REDUCTIONS,
      data.CHARGES_PENSIONS,
      data.CHARGES_IMPOTS,
      data.CHARGES_EMPRUNTS,
      data.PRODUIT_OPERATIONS,
    ]
    const typesAvecLibelle = [
      data.REVENUS_MOBILIERS,
      data.REVENUS_PENSIONS,
      data.REVENUS_AUTRES,
      data.REVENUS_FONCIERS,
      data.PRODUITS_AV,
      data.PRODUITS_VM,
      data.PRODUITS_FINANCIERS,
      data.PRODUITS_PER,
      data.PRODUITS_RETRAITE,
      data.PREVOYANCE,
      data.CATALOGUES,
      data.REVENUS_PROS,
    ]
    if (typesAvecNom.includes(tableType)) {
      return newData.nom
    } else if (typesAvecLibelle.includes(tableType)) {
      return newData.libelle
    } else if (tableType === data.PASSIFS_EMPRUNTS) {
      return newData.refEmprunt
    } else if (tableType === data.PASSIFS_DETTES) {
      return newData.refDette
    } else {
      return '' // Cas par défaut
    }
  }
  useEffect(() => {
    setLibelle(getLibelleFromTableType(tableType, newData))
  }, [tableType, newData])
  // handleDispatch
  const handleDispatch = (action, actionType) => {
    dispatch(action(actionType, newData, person.id, isPP, setStatus))
  }
  const handleDispatchOperation = (action, actionType) => {
    dispatch(action(actionType, newData, bien.id, person.id, isPP, setStatus))
  }
  // actionMap
  const actionMap = {
    [data.ACTIFS_IMMO]: () => handleDispatch(deleteActif, 'deleteBien'),
    [data.ACTIFS_PRO]: () => handleDispatch(deleteActif, 'deleteBienPro'),
    [data.ACTIFS_AUTRES]: () => handleDispatch(deleteActif, 'deleteAutreActif'),
    [data.REVENUS_PROS]: () => handleDispatch(deleteRevenu, 'deleteRevenuProfesionnel'),
    [data.REVENUS_MOBILIERS]: () => handleDispatch(deleteRevenu, 'deleteRevenuMobilier'),
    [data.REVENUS_PENSIONS]: () => handleDispatch(deleteRevenu, 'deletePensionRente'),
    [data.REVENUS_AUTRES]: () => handleDispatch(deleteRevenu, 'deleteAutreRevenu'),
    [data.REVENUS_FONCIERS]: () => handleDispatch(deleteRevenu, 'deleteRevenuFoncier'),
    [data.PASSIFS_EMPRUNTS]: () => handleDispatch(deletePassif, 'deleteEmprunt'),
    [data.PASSIFS_DETTES]: () => handleDispatch(deletePassif, 'deleteDette'),
    [data.CHARGES_GENERALES]: () => handleDispatch(deleteCharge, 'deleteChargeGenerale'),
    [data.CHARGES_DEDUCTIBLES]: () => handleDispatch(deleteCharge, 'deleteChargeDeductibleRI'),
    [data.CHARGES_REDUCTIONS]: () =>
      handleDispatch(deleteCharge, 'deleteChargeOuvrantDroitReductionRI'),
    [data.CHARGES_IMPOTS]: () => handleDispatch(deleteCharge, 'deleteImpotEtTaxe'),
    [data.CHARGES_PENSIONS]: () => handleDispatch(deleteCharge, 'deletePension'),
    [data.CHARGES_EMPRUNTS]: () => handleDispatch(deleteCharge, 'deleteEmpruntEtDette'),
    [data.PRODUITS_AV]: () => handleDispatch(deleteProduct, 'deleteProduitAV'),
    [data.PRODUITS_VM]: () => handleDispatch(deleteProduct, 'deleteProduitVM'),
    [data.PRODUITS_FINANCIERS]: () => handleDispatch(deleteProduct, 'deleteProduitCB'),
    [data.PRODUITS_PER]: () => handleDispatch(deleteSocial, 'deleteSocialPER'),
    [data.PRODUITS_RETRAITE]: () => handleDispatch(deleteSocial, 'deleteSocialRetraite'),
    [data.PREVOYANCE]: () => handleDispatch(deleteSocial, 'deleteSocialPrevoyance'),
    // Ajoutez d'autres mappages ici
    [data.PRODUIT_OPERATIONS]: () => {
      switch (bienType.type) {
        case 'produitFinancierAV':
          return handleDispatchOperation(deleteOperationFinancier, 'deleteOperationProduitAV')
        case 'produitFinancierVM':
          return handleDispatchOperation(deleteOperationFinancier, 'deleteOperationProduitVM')
        case 'per':
          return handleDispatchOperation(deleteOperationSocial, 'deleteOperationSocialPER')
        case 'retraite':
          return handleDispatchOperation(deleteOperationSocial, 'deleteOperationSocialRetraite')
        default:
          return
      }
    }, // Ajoutez le cas par défaut si nécessaire
    [data.CATALOGUES]: () => dispatch(deleteCatalogue(newData.id, setStatus)),
  }
  // handleDelete
  const handleDelete = () => {
    const action = actionMap[tableType]
    if (action) {
      action()
    }
  }
  // response delete
  useEffect(() => {
    if (status >= 200 && status < 300) {
      setModalOk(true)
      setStatus(null)
    }
    if (status >= 400) {
      setModalNotOk(true)
      setStatus(null)
    }
  }, [status])
  //close
  const handleClose = () => {
    if (tableType === data.CATALOGUES) {
      dispatch(getCatalogues())
    }
    if (!tableType === data.PRODUIT_OPERATIONS) {
      dispatch(getBienSuccess(null))
      dispatch(getbienType(null))
    }
    setModalOk(false)
    setModalNotOk(false)
    setModalDelete(false)
  }

  return (
    <>
      <Modale
        close={() => setModalDelete(false)}
        title={`Souhaitez-vous réellement supprimer cet élément ?`}
      >
        <div className="informations">
          <div className="informations__blocTxt">{libelle ? <p>{libelle}</p> : null}</div>
          <div className="informations__buttons">
            <div className="button button--blue-red width--12" onClick={() => handleDelete()}>
              Supprimer
            </div>
            <div
              className="button button--blue-red width--12"
              onClick={() => setModalDelete(false)}
            >
              Annuler
            </div>
          </div>
        </div>
      </Modale>
      {modalOk && (
        <Modale
          close={() => handleClose()}
          title={`Félicitaion !`}
          line1={`Element supprimé avec succès`}
        >
          <div className="informations__buttons">
            <div className="button button--blue-red width--12" onClick={() => handleClose()}>
              Fermer
            </div>
          </div>
        </Modale>
      )}
      {modalNotOk && <NotOk setNotOK={setModalNotOk} closeModale={handleClose} />}
    </>
  )
}

export default DeleteBien
