// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions post user
 * @reduxAction
 */

export const postUserLoad = createAction('post-user-load')

export const postUserSuccess = createAction(
  'post-user-success',
  (status) => {
    return {
      payload: status,
    }
  }
)

export const postUserError = createAction(
  'post-user-error', (error) => {
  return {
    payload: error,
  }
})