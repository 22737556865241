import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FiEdit3 } from 'react-icons/fi'
import { espaceDatas } from '../../../data/informations/informations'
import { beautifulDate } from '../../../services/helpers/dates'
import Card from '../Components/Card'

const MonEspaceCard = () => {
  const navigate = useNavigate() // user
  const user = useSelector((state) => state.getUser.user)

  return (
    <Card title="Mon espace" h2={true} open={true} >
      <div className="informations">
        {espaceDatas(user).map((data, index) => (
          <div key={index} className="informations__line">
            <p className="informations__line__label">{data.label}</p>
            {data.label === '' ? (
              <div
                className="informations__line__data--important"
                onClick={() => navigate('/user/informations')}
              >
                <h3>{data.value}</h3>
                <div className="informations__line__data--edit">
                  <FiEdit3 />
                </div>
              </div>
            ) : (
              <p className="informations__line__data">{data.value}</p>
            )}
          </div>
        ))}
      </div>
      {/* Créé le */}
      <div className="informations">
        <p className="informations__create">
          Créé le{' '}
          {user.createdAt
            ? beautifulDate(user.createdAt)
            : beautifulDate(user.updatedAt)}
        </p>
      </div>
    </Card>
  )
}

export default MonEspaceCard
