// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get signatures à classer
 * @reduxAction
 */

export const getSignaturesToClassifyLoad = createAction('get-signatures-to-classify-load')

export const getSignaturesToClassifySuccess = createAction(
  'get-signatures-to-classify-success',
  (signatures) => {
    return {
      payload: signatures,
    }
  }
)

export const getSignaturesToClassifyError = createAction(
  'get-signatures-to-classify-error', (error) => {
  return {
    payload: error,
  }
})