// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getSelection } from '../actions/actionGetSelection'

// REDUCER // ______________________________________________________________

// initial state
const initialStateSelection = {
  selection: null,
}
// reducer
export const getSelectionReducer = createReducer(initialStateSelection, (builder) => {
  return builder
    .addCase(getSelection, (draft, action) => {
      draft.selection = action.payload
      return
    })
})
