import React, { useMemo, useState } from 'react'
import TableRowNumber from './TableRowNumber'
import TableActionButtons from './TableActionButtons'
import { PP_RELATIONS } from '../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { useNavigate } from 'react-router-dom'

const TableBodyBloc = ({
  listToDisplay,
  children,
  print,
  read,
  noLineNumber,
  handleShow,
  handleEdit,
  handleDelete,
  handleDuplicate,
  handleFetch,
  handleClassify,
  tableType,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const persons = useSelector((state) => state.getPersons.persons)
  const listPersons = useMemo(() => persons.referentPP.concat(persons.relationPP), [persons])
  const [selectedRow, setSelectedRow] = useState(null)
  const handleRowClick = (index, element) => {
    if (tableType !== PP_RELATIONS) {
      setSelectedRow(index === selectedRow ? null : index)
    } else {
      read && element.idPP2 !== 0
        ? handleRedirection(element)
        : setSelectedRow(index === selectedRow ? null : index)
    }
  }
  // redirection
  const handleRedirection = (element) => {
    const personne = listPersons.filter((person) => person.id === element.idPP2)[0]
    const type =
      persons.referentPP.filter((pers) => pers.id === element.idPP2).length !== 0
        ? 'referentPP'
        : 'relationPP'
    if (personne !== undefined) {
      dispatch(getPerson(personne))
      dispatch(postPersonType(type))
      navigate('/user/informations')
    }
  }

  return !print ? (
    <tbody className={'tbody'}>
      {listToDisplay &&
        listToDisplay.map((element, index) => (
          <tr
            key={index}
            className={
              tableType !== PP_RELATIONS
                ? index === selectedRow
                  ? 'tbody__tr tbody__tr--link selected'
                  : 'tbody__tr tbody__tr--link'
                : read && element.idPP2 !== 0
                ? 'tbody__tr tbody__tr--link'
                : 'tbody__tr'
            }
            onClick={() => handleRowClick(index, element)}
          >
            {/* numéro */}
            {!noLineNumber ? (
              <TableRowNumber index={index} handleRowClick={() => handleRowClick(index)} />
            ) : null}
            {/* specific body */}
            {React.cloneElement(children, { element, tableType, read })}
            {/* Voir / Modifier / Supprimer / Dupliquer */}
            {!read ? (
              <TableActionButtons
                handleShow={handleShow ? () => handleShow(element) : null}
                handleEdit={handleEdit ? () => handleEdit(element) : null}
                handleDelete={handleDelete ? () => handleDelete(element) : null}
                handleDuplicate={handleDuplicate ? () => handleDuplicate(element) : null}
                handleFetch={handleFetch ? () => handleFetch(element) : null}
                handleClassify={handleClassify ? () => handleClassify(element) : null}
              />
            ) : null}
          </tr>
        ))}
    </tbody>
  ) : (
    listToDisplay?.map((element, index) => (
      <li key={`data-${index}`} className="print__table__bloc">
        {React.cloneElement(children, { element, tableType, index, read })}
      </li>
    ))
  )
}

export default TableBodyBloc
