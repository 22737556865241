import React, { useEffect, useState } from 'react'
import Card from '../../../../../Components/Card'
import Input from '../../../../../Components/Input'
import SelectComponent from '../../../../../Components/SelectComponent'
import { optionsTypePeriodicite } from '../../../../../../../data/options/options'

const ContractValueCard = ({ handleChange, information }) => {
  const { categorie, sousCategorie, capital, taux, renteEstime, periodiciteRente } =
    information || {}
  const [rente, setRente] = useState(false)

  useEffect(() => {
    if (
      sousCategorie === 'Complémentaire santé' ||
      (categorie === 'Prévoyance Individuelle' && sousCategorie === 'Assurance emprunteur') ||
      categorie === '' ||
      sousCategorie === ''
    ) {
      setRente(false)
    } else {
      setRente(true)
    }
  }, [categorie, sousCategorie])

  return (
    <Card title="Valeur du contrat" h3={true} open={true}>
      <div className="informations">
        {/* capital */}
        <Input
          forAndId="capital"
          errorLabel={capital === ''}
          type="number"
          name="Capital :"
          value={capital}
          onChange={(e) => handleChange(e)}
          min={0}
          max={999999999999}
          copy={true}
        />
        {/* taux */}
        <Input
          forAndId="taux"
          errorLabel={taux === ''}
          type="number"
          name="Taux (%) :"
          value={taux}
          onChange={(e) => handleChange(e)}
          min={0}
          max={100}
          copy={true}
        />
        {rente ? (
          <>
            <Input
              forAndId="renteEstime"
              errorLabel={renteEstime === ''}
              type="number"
              name="Rente estimée ou versée :"
              value={renteEstime}
              onChange={(e) => handleChange(e)}
              min={0}
              max={999999999999}
              copy={true}
            />
            <SelectComponent
              forAndId="periodiciteRente"
              name="Periodicité des versements :"
              isClearable={true}
              options={optionsTypePeriodicite}
              placeholder="Sélectionner"
              onChange={(e, name) => handleChange(e, name)}
              value={optionsTypePeriodicite.find((c) => c.value === periodiciteRente)}
              copy={true}
            />
          </>
        ) : null}
      </div>
    </Card>
  )
}

export default ContractValueCard
