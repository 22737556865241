import React, { useEffect } from 'react'
import { useState } from 'react'
import { BsArrowUpSquareFill } from 'react-icons/bs'

const BackToTheTop = () => {
  const [visible, setVisible] = useState(false)

  /* A function that is called when the user scrolls. */
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop

    if (scrolled > 250) {
      setVisible(true)
    } else if (scrolled <= 250) {
      setVisible(false)
    }
  }

  const scrollToTop = () => window.scrollTo(0, 0)

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible)
    return () => window.removeEventListener('scroll', toggleVisible)
  }, [])
  // window.addEventListener('scroll', toggleVisible)

  return (
    <div onClick={scrollToTop} style={{ display: visible ? 'flex' : 'none' }} className="toTheTop">
        <BsArrowUpSquareFill />
    </div>
  )
}

export default BackToTheTop
