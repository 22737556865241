import React from 'react'
import logo from '../../assets/cap-mep-logo-couleur-contour.webp'
import { stringToCapitalize } from '../../services/helpers/strings'
import { MdOutlinePowerSettingsNew } from 'react-icons/md'
import { CgMenu } from 'react-icons/cg'

const HeaderNotActive = ({ commit, prenom, nom }) => {
  return (
    <>
      <div className="header__link">
        <div className="header__logo">
          <img src={logo} alt="logo Mon Espace Patrimoine" width={48} />
        </div>
        <p className="header__title">Mon Espace Patrimoine</p>
      </div>
      {/* début partie à supprimer en prod */}
        <div>
          <p style={{ color: 'white', fontSize: '12px', padding: '0 1rem' }}>{commit}</p>
        </div>
      {/* fin partie à supprimer en prod */}
      <div className="header__bloc">
        <p className="header__bloc__hello">
          Bonjour {stringToCapitalize(prenom)} {nom.toUpperCase()}{' '}
        </p>
        <div className="header__bloc__icon">
          <MdOutlinePowerSettingsNew />
        </div>
        <div className="header__bloc__icon">
          <CgMenu />
        </div>
      </div>
    </>
  )
}

export default HeaderNotActive
