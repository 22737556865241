import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContacts, postContact } from '../../../../services/API/connexion'
import { dateDetenteur, getAge } from '../../../../services/helpers/dates'
import { getPersonnes } from '../../../../services/API/personnes'
import ModaleAddDetenteur from './ModaleAddDetenteur'

const AddDetenteur = ({
  setAddDetenteur,
  newValeurTotale,
  detenteursToDisplay,
  setDetenteursToDisplay,
  detenteurToEdit,
  showUS,
}) => {
  const dispatch = useDispatch()
  // user
  const user = useSelector((state) => state.getUser.user)
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  // contacts
  const contacts = useSelector((state) => state.getContacts.contacts)
  // status
  const [status, setStatus] = useState()
  //
  const [listPersons, setListPersons] = useState([])
  const [personnes, setPersonnes] = useState('')
  const [personExist, setPersonExist] = useState(true)
  const [demembrement, setDemembrement] = useState(null)
  const [personType, setPersonType] = useState('PP')

  // detenteur
  const [detenteur, setDetenteur] = useState(null)

  const { mailDetenteur, dateNaissance, dateContitutionUsufruit, dureeDemembrement } =
    detenteur || {}
  const detenteurToDelete = detenteurToEdit
  const [names, setNames] = useState(null)
  const { nom, prenom } = names || {}
  // handleChangeNames
  const handleChangeNames = (e) => {
    const { name, value } = e.target
    setNames({ ...names, [name]: value })
  }
  useEffect(() => {
    if (names) {
      setDetenteur({ ...detenteur, nomDetenteur: `${nom} ${prenom}`, tempId: Date.now() })
    }
  }, [names])
  // maj edit detenteur
  useEffect(() => {
    if (detenteurToEdit) {
      setDetenteur({
        ...detenteur,
        id: detenteurToEdit.id,
        nomDetenteur: detenteurToEdit.nomDetenteur,
        mailDetenteur: detenteurToEdit.mailDetenteur,
        idPP: detenteurToEdit.idPP,
        idPM: detenteurToEdit.idPM,
        dateNaissance: detenteurToEdit.dateNaissance,
        dateContitutionUsufruit: dateDetenteur(
          new Date(detenteurToEdit.dateContitutionUsufruit).toLocaleDateString('fr-FR')
        ),
        dureeDemembrement: detenteurToEdit.dureeDemembrement,
        tauxPleinePropriete: detenteurToEdit.tauxPleinePropriete,
        tauxUsufruit: detenteurToEdit.tauxUsufruit,
        tauxUsufruitCalcule: detenteurToEdit.tauxUsufruitCalcule,
        tauxNuePropriete: detenteurToEdit.tauxNuePropriete,
        valeurDetenue: detenteurToEdit.valeurDetenue,
        valeurPP: detenteurToEdit.valeurPP,
        valeurUS: detenteurToEdit.valeurUS,
        valeurNP: detenteurToEdit.valeurNP,
      })
      setDemembrement(
        !detenteurToEdit.dateNaissance && !detenteurToEdit.dateContitutionUsufruit ? false : true
      )
      setPersonType(detenteurToEdit.dureeDemembrement ? 'PM' : 'PP')
    }
  }, [])

  // recuperation
  useEffect(() => {
    dispatch(getContacts())
    dispatch(getPersonnes())
  }, [])

  // actualisation pp ou pm à afficher
  useEffect(() => {
    switch (personnes) {
      case 'personnePhysique':
        setListPersons(persons.referentPP.concat(persons.relationPP))
        break
      case 'personneMorale':
        setListPersons(persons.dirigeantPM)
        break
      case 'contact':
        setListPersons(contacts)
        break
      default:
        setListPersons([])
    }
  }, [personnes, persons, contacts])
  // dataContact
  const dataContact = {
    id: 0,
    user_id: user,
    nom: nom,
    prenom: prenom,
    email: mailDetenteur,
    telephonePortable: '',
    fonction: '',
    // typeRelation: 'AUTRE',
  }
  const handleAddDetenteur = () => {
    let taux
    if (dateNaissance !== '') {
      let age = getAge(new Date(dateNaissance))
      if (age < 12) {
        age = 12
      }
      taux = Math.ceil(11 - ((age - 1) / 10 + 1)) / 10
    }
    if (dureeDemembrement && dureeDemembrement !== 0) {
      taux = ((dureeDemembrement / 100) * getAge(new Date(dateContitutionUsufruit))) / 10
    }
    // const lastId = detenteursToDisplay[detenteursToDisplay.length - 1].id
    !detenteurToEdit
      ? setDetenteursToDisplay([
          ...detenteursToDisplay,
          { ...detenteur, tauxUsufruitCalcule: taux, tempId: Date.now() },
        ])
      : setDetenteursToDisplay([
          ...detenteursToDisplay.filter((el) => el !== detenteurToDelete),
          { ...detenteur, tauxUsufruitCalcule: taux },
        ])
    if (!personExist) {
      dispatch(postContact(dataContact, setStatus))
    }
    setAddDetenteur(false)
  }
  // response update contact
  useEffect(() => {
    if (status >= 200 && status < 300) {
      dispatch(getContacts())
      setStatus(null)
    }
    if (status === 409) {
      setStatus(null)
    }
    if (status >= 400 && status !== 409) {
      setStatus(null)
    }
  }, [status])

  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDetenteur({ ...detenteur, [name]: date })
  }

  return (
    <ModaleAddDetenteur
      setAddDetenteur={setAddDetenteur}
      detenteurToEdit={detenteurToEdit}
      personExist={personExist}
      setPersonExist={setPersonExist}
      personnes={personnes}
      setPersonnes={setPersonnes}
      listPersons={listPersons}
      handleChangeNames={handleChangeNames}
      handleChangeDate={handleChangeDate}
      handleAddDetenteur={handleAddDetenteur}
      detenteur={detenteur}
      setDetenteur={setDetenteur}
      names={names}
      demembrement={demembrement}
      setDemembrement={setDemembrement}
      personType={personType}
      setPersonType={setPersonType}
      showUS={showUS}
      newValeurTotale={newValeurTotale}
    />
  )
}

export default AddDetenteur
