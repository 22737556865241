// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import {
  getSocialError,
  getSocialLoad,
  getSocialSuccess,
} from '../../actions/Patrimoine/actionGetSocial'

// REDUCER // ______________________________________________________________

// initial state
const initialStateSocial = {
  isLoading: false,
  social: null,
  error: '',
}
// reducer
export const getSocialReducer = createReducer(initialStateSocial, (builder) => {
  return builder
    .addCase(getSocialLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getSocialSuccess, (draft, action) => {
      draft.isLoading = false
      draft.social = action.payload
      draft.error = ''
      return
    })
    .addCase(getSocialError, (draft, action) => {
      draft.isLoading = false
      draft.social = null
      draft.error = action.payload
      return
    })
})
