import React from 'react'
import TableToPrint from '../../../../table/TableToPrint'
import TableBodyCatalogue from '../Table/TableBodyCatalogue'
import { CATALOGUES } from '../../../../../../constants'

const CatalogueToPrint = ({ catalogueVM, catalogueAV }) => {
  return (
    <>
      <TableToPrint
        data={catalogueVM}
        tableType={CATALOGUES}
        title={`Catalogue Valeurs Mobilières (${catalogueVM.length})`}
        children={<TableBodyCatalogue />}
      />
      {/* <span className="breakAfter"></span> */}
      <TableToPrint
        data={catalogueAV}
        tableType={CATALOGUES}
        title={`Catalogue Assurances Vie (${catalogueAV.length})`}
        children={<TableBodyCatalogue />}
      />
    </>
  )
}

export default CatalogueToPrint
