// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getIdTransaction } from '../actions/actionGetIdTransaction'

// REDUCER // ______________________________________________________________

// initial state
const initialStateIdTransaction = {
  id: null,
}
// reducer
export const getIdTransactionReducer = createReducer(initialStateIdTransaction, (builder) => {
  return builder
    .addCase(getIdTransaction, (draft, action) => {
      draft.id = action.payload
      return
    })
})
