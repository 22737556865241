import React from 'react'
import { stringToCapitalize } from '../../../services/helpers/strings'
import { beautifulDate } from '../../../services/helpers/dates'

const BodyUsers = ({ element }) => {
  return (
    <>
      {/* prenom */}
      <td className="tbody__tr__td">
        {!element.prenom ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          stringToCapitalize(element.prenom)
        )}
      </td>
      {/* nom */}
      <td className="tbody__tr__td">
        {!element.nom ? <p className="informations__line__notxt">-</p> : element.nom.toUpperCase()}
      </td>
      {/* email */}
      <td className="tbody__tr__td">
        {!element.email ? <p className="informations__line__notxt">-</p> : element.email}
      </td>
      {/* dateNaissance */}
      <td className="tbody__tr__td">
        {!element.dateNaissance ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.dateNaissance)
        )}
      </td>
      {/* roles */}
      <td className="tbody__tr__td">
        {element.roles.length > 0 &&
          (element.roles[0].id === '' || !element.roles[0].id ? (
            <p className="informations__line__notxt">-</p>
          ) : element.roles[0].id === 1 ? (
            'USER'
          ) : element.roles[0].id === 2 ? (
            'ADMIN'
          ) : (
            'CONSEILLER'
          ))}
      </td>
      {/* createdAt */}
      <td className="tbody__tr__td">
        {!element.createdAt ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.createdAt)
        )}
      </td>
      {/* updatedAt */}
      <td className="tbody__tr__td">
        {!element.updatedAt || element.updatedAt === element.createdAt ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          beautifulDate(element.updatedAt)
        )}
      </td>
      {/* cabinet */}
      <td className="tbody__tr__td">
        {!element.cabinet ? (
          <p className="informations__line__notxt">-</p>
        ) : (
          element.cabinet.nomCabinet.toUpperCase()
        )}
      </td>
    </>
  )
}

export default BodyUsers
