import React from 'react'

const Pagination = ({ page, setPage, pageCountRange, pageCount }) => {
  return pageCount > 1 ? (
    <div className="pagination">
      {page > 1 ? (
        <div>
          <button
            className={'pagination__button'}
            onClick={() => {
              setPage(1)
            }}
          >
            1
          </button>
          <button
            className={'pagination__button'}
            onClick={() => {
              setPage(page - 1)
            }}
          >
            ...
          </button>
        </div>
      ) : null}
      <p>
        {page}
      </p>

      {page !== pageCount ? (
        <div>
          <button
            className={'pagination__button'}
            onClick={(e) => {
              setPage(page + 1)
            }}
          >
           ...
          </button>
          <button
            className={'pagination__button'}
            onClick={(e) => {
              setPage(pageCountRange.length)
            }}
          >
            {pageCount}
          </button>
        </div>
      ) : null}
    </div>
  ) : null
}

export default Pagination
