import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import { getBienSuccess } from '../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../redux/actions/actionGetBienType'
import { getContacts } from '../../../services/API/connexion'
import {
  getSignaturesPending,
  getSignaturesToClassify,
  sign,
} from '../../../services/helpers/files'
import Signatures from '../../components/Signatures/Signatures'
import { API_BASE_URL } from '../../../constants'

const MySignatures = () => {
  const dispatch = useDispatch()
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  // contacts
  const contacts = useSelector((state) => state.getContacts.contacts)
  // parapheur
  const parapheur = useSelector((state) => state.getParapheur.parapheur)
  // signatures en instance
  const signaturesPending = useSelector((state) => state.getSignaturesPending.signaturesPending)
  // signatures à classer
  const signaturesToClassify = useSelector(
    (state) => state.getSignaturesToClassify.signaturesToClassify
  )
  // form
  const [files, setFiles] = useState([])
  const [distance, setDistance] = useState(false)
  const [personnes, setPersonnes] = useState('Physiques')
  const [signataires, setSignataires] = useState([])
  const [toggleAdd, setToggleAdd] = useState(false)
  const [toggleSign, setToggleSign] = useState(false)
  const [toggleFetchOk, setToggleFetchOk] = useState(false)
  const [toggleValidate, setToggleValidate] = useState(false)
  const [newData, setNewData] = useState({
    nom: '',
    mail: '',
    telPortable: '',
  })
  const [result, setResult] = useState(null)

  // recupération
  useEffect(() => {
    !contacts && dispatch(getContacts())
    dispatch(getSignaturesPending())
    dispatch(getSignaturesToClassify())
    if (persons && persons.referentPP[0] !== undefined) {
      dispatch(getPerson(persons.referentPP[0]))
      // dispatch(getPersonId(persons.referentPP[0].id))
      dispatch(postPersonType('referentPP'))
    }
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])

  // refresh signatures instance et à classer si recup ok + modales
  useEffect(() => {
    if (parapheur) {
      parapheur < 400 ? setToggleFetchOk(true) : setToggleSign(true)
    }
  }, [parapheur])
  // valider envoi signature

  const handleValidate = () => {
    const url = `${API_BASE_URL}/users/signe?distance=${distance}`
    sign(url, files, signataires, setResult)
    setToggleValidate(true)
    setTimeout(reset, 3000)
  }
  const reset = () => {
    setFiles([])
    setSignataires([])
  }
  // ouverture parapheur
  useEffect(() => {
    if (result) {
      window.open(result)
      dispatch(getSignaturesPending())
    }
  }, [result])

  return (
    <Signatures
      setPersonnes={setPersonnes}
      personnes={personnes}
      personsToDisplay={persons}
      contactsTodisplay={contacts}
      signataires={signataires}
      setSignataires={setSignataires}
      setToggleAdd={setToggleAdd}
      files={files}
      setFiles={setFiles}
      distance={distance}
      setDistance={setDistance}
      handleValidate={handleValidate}
      signaturesPendingToDisplay={signaturesPending}
      signaturesToClassifyToDisplay={signaturesToClassify}
      toggleAdd={toggleAdd}
      newData={newData}
      setNewData={setNewData}
      toggleSign={toggleSign}
      setToggleSign={setToggleSign}
      setToggleFetchOk={setToggleFetchOk}
      toggleFetchOk={toggleFetchOk}
      toggleValidate={toggleValidate}
      setToggleValidate={setToggleValidate}
      reset={reset}
      result={result}
    />
  )
}

export default MySignatures
