// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get financier
 * @reduxAction
 */

export const getFinancierLoad = createAction('get-financier-load')

export const getFinancierSuccess = createAction('get-financier-success', (financier) => {
  return {
    payload: financier,
  }
})

export const getFinancierError = createAction('get-financier-error', (error) => {
  return {
    payload: error,
  }
})
