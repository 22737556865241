import React from 'react'
import { stringToCapitalize } from '../../../services/helpers/strings'
import FormSubmit from '../Components/FormSubmit'
import Thread from '../../layout/Thread'
import Birth from './Birth'
import Family from './Family'
import FiscalAddress from './FiscalAddress'
import Identity from './Identity'
import Activity from './Activity'
import RelationShip from './RelationShip'
import {
  DOC_PERSON_BIRTH,
  DOC_PERSON_FAMILY,
  DOC_PERSON_HOME,
  DOC_PERSON_IDENTITY,
} from '../../../constants'

const PPComponent = ({
  handleSubmit,
  person,
  personInfos,
  setPersonInfos,
  adresseFiscale,
  setAdresseFiscale,
  birth,
  setBirth,
  family,
  setFamily,
  professions,
  setProfessions,
  relations,
  setRelations,
  fichiers,
  dataChange,
  setDataChange,
  handleGiveUp,
  setUpdateConjoint,
}) => {
  const name = person ? `${stringToCapitalize(person.prenom)} ${person.nom.toUpperCase()}` : ''

  return (
    <>
      <div className="section__title">
        <div className="section__title__content">
          <h1 className="section__title__content__h1">Formulaire Personne Physique</h1>
          {person ? <p className="section__title__content__p">{name}</p> : null}
          <Thread />
        </div>
      </div>
      <div className="section__content">
        <FormSubmit
          dataChange={dataChange}
          handleSubmit={handleSubmit}
          handleGiveUp={handleGiveUp}
          down={false}
        />
        <div className="sousSection">
          {!person ? (
            <p className="informations__info">
              Toute personne majeure ajoutée sera sollicitée pour donner son consentement par
              signature électronique
            </p>
          ) : null}

          {/* informations générales */}
          <div className="cards">
            <Identity
              person={person}
              personInfos={personInfos}
              setPersonInfos={setPersonInfos}
              fichiers={fichiers}
              setDataChange={setDataChange}
              document={DOC_PERSON_IDENTITY}
            />
            {/* Adresse Fiscale */}
            <FiscalAddress
              person={person}
              adresseFiscale={adresseFiscale}
              setAdresseFiscale={setAdresseFiscale}
              fichiers={fichiers}
              setDataChange={setDataChange}
              document={DOC_PERSON_HOME}
            />
          </div>
          <div className="cards">
            {/* informations de naissance */}
            <Birth
              person={person}
              birth={birth}
              setBirth={setBirth}
              fichiers={fichiers}
              setDataChange={setDataChange}
              document={DOC_PERSON_BIRTH}
            />
            {/* Situation maritale */}
            <Family
              person={person}
              family={family}
              setFamily={setFamily}
              fichiers={fichiers}
              setDataChange={setDataChange}
              document={DOC_PERSON_FAMILY}
              setUpdateConjoint={setUpdateConjoint}
            />
          </div>
          <div className="cards">
            {/* Relations */}
            <RelationShip
              relations={relations}
              setRelations={setRelations}
              setDataChange={setDataChange}
            />
            {/* Professions */}
            <Activity
              professions={professions}
              setProfessions={setProfessions}
              setDataChange={setDataChange}
            />
          </div>
          {!person && (
            <p className="informations__info">
              Toute personne ajoutée sera sollicitée pour donner son consentement par signature
              électronique
            </p>
          )}
        </div>
        <FormSubmit
          dataChange={dataChange}
          handleSubmit={handleSubmit}
          handleGiveUp={handleGiveUp}
          down={true}
        />
      </div>
    </>
  )
}

export default PPComponent
