import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMandatSuccess } from '../../../redux/actions/actionGetMandat'
import { getPersonnes, postPP, updatePP } from '../../../services/API/personnes'
import { optionsSexe } from '../../../data/options/optionsPP'
import { optionsTrueOrFalse, optionsTypeRelation } from '../../../data/options/options'
import Input from '../Components/Input'
import SelectComponent from '../Components/SelectComponent'
import ModalParapheur from '../Parapheur/ModalParapheur'
import ModalCreatePPNotOK from './Modales/ModalCreatePPNotOK'
import DateInputComponent from '../Components/DateInputComponent'
import NotCorrect from '../Components/Modales/NotCorrect'

const InputsRelationShip = ({ handleChange, setData, handleAddLink, data, setDataChange }) => {
  const { idPP2, typeRelation, acharge } = data || {}
  const dispatch = useDispatch()
  // user
  const user = useSelector((state) => state.getUser.user)
  // url de parapher
  const url = useSelector((state) => state.getMandat.mandat)
  // person
  const person = useSelector((state) => state.getPerson.person)
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  const listPersons = persons.referentPP.concat(persons.relationPP)
  const [optionsPersons, setOptionsPersons] = useState(null)
  const [personSelected, setPersonSelected] = useState(null)
  const [addSexe, setAddSexe] = useState(null)
  const [noSex, setNoSex] = useState(false)
  const [personne, setPersonne] = useState({
    id: 0,
    nom: '',
    prenom: '',
    mail: '',
    dateNaissance: '',
    sexe: '',
  })
  const { nom, prenom, mail, dateNaissance, sexe } = personne || {}
  // post PP
  const dataPP = {
    id: 0,
    nom: nom,
    prenom: prenom,
    mail: mail,
    dateNaissance: dateNaissance,
    sexe: sexe,
    villeNaissance: '',
    telFixe: '',
    telPortable: '',
    adresseFiscale: {},
    coordonneesSupplementaires: [],
    nomNaissance: '',
    nationnalite1: '',
    nationnalite2: '',
    capaciteLegale: '',
    situationFam: '',
    idConjoint: 0,
    dateSituationFam: '',
    regimeFam: '',
    dateregimeFam: '',
    donationEntreEpoux: '',
    user_id: {
      email: user.email,
    },
    professions: [],
    relations: [],
    fichiers: [],
  }
  // modale
  const [modalNotCorrect, setModalNotCorrect] = useState(false)
  const [modalCreateOk, setModalCreateOk] = useState(false)
  const [modalNotOk, setModalNotOk] = useState(false)
  // const [modalRelation, setModalRelation] = useState(false)
  // reponse
  const [status, setStatus] = useState(null)
  const [statusUpdate, setStatusUpdate] = useState(null)

  const [change, setChange] = useState(false)
  useEffect(() => {
    if (idPP2 !== undefined && idPP2 !== '' && typeRelation !== undefined && typeRelation !== '') {
      setChange(true)
    } else {
      setChange(false)
    }
  }, [idPP2, typeRelation, acharge])
  // optionsPersons
  useEffect(() => {
    setOptionsPersons([
      ...listPersons.map((item) => ({ value: item, label: `${item.prenom} ${item.nom}` })),
      { value: 'Ajouter', label: 'Ajouter' },
    ])
  }, [persons])
  // idPP2
  useEffect(() => {
    if (personSelected) {
      personSelected !== 'Ajouter' ? testSexePersonSelected() : setData({ ...data, idPP2: 0 })
    } else {
      setData({ ...data, idPP2: 0 })
    }
  }, [personSelected])
  // test personSelected
  const testSexePersonSelected = () => {
    if (personSelected.sexe === '' || personSelected.sexe === undefined || !personSelected.sexe) {
      setNoSex(true)
    } else {
      setNoSex(false)
    }
    setData({ ...data, idPP2: personSelected.id })
  }

  // handleSelectPerson
  const handleSelectPerson = (e) => {
    if (e) {
      setDataChange(true)
      setPersonSelected(e.value)
    } else {
      setDataChange(true)
      setPersonSelected(null)
    }
  }
  // maj affichage si person et idConjoint ______________________________________
  useEffect(() => {
    if (person && idPP2 !== 0) {
      setPersonSelected(listPersons && listPersons.filter((personne) => personne.id === idPP2)[0])
    }
  }, [idPP2])

  // maj personSelected si pers créée ____________________________________________
  useEffect(() => {
    if (nom !== '' && prenom !== '') {
      const newPartner = optionsPersons.filter((option) => option.label === `${prenom} ${nom}`)[0]
      newPartner !== undefined && setData({ ...data, idPP2: newPartner.value.id })
    }
  }, [optionsPersons])

  // handleChangeRelation
  const handleChangeRelation = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      setPersonne({ ...personne, [name]: value })
    } else {
      const name = selectName.name
      const value = e.value
      setPersonne({ ...personne, [name]: value })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setPersonne({ ...personne, [name]: date })
  }

  const handleSavePerson = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (nom !== '' && prenom !== '' && mail !== '' && dateNaissance !== '') {
      // create PP
      dispatch(postPP(dataPP, setStatus))
    } else {
      setModalNotCorrect(true)
    }
  }
  // saveSex
  const handleSaveSex = (e) => {
    setAddSexe(e.value)
    setNoSex(false)
    dispatch(
      updatePP(
        {
          ...personSelected,
          user_id: {
            id: user.id,
            email: user.email,
          },
          telPortable: personSelected.telPortable ? personSelected.telPortable : 11111111111,
          sexe: e.value,
        },
        setStatusUpdate
      )
    )
  }
  // response create PP status and modal
  useEffect(() => {
    if (status) {
      if (status <= 301) {
        if (url && url !== '') {
          setModalCreateOk(true)
          window.open(url)
          dispatch(getMandatSuccess(null))
        }
      } else {
        setModalNotOk(true)
      }
      setStatus(null)
    }
  }, [status])
  // response update relation add sex
  useEffect(() => {
    if (statusUpdate >= 200 && statusUpdate < 300) {
      setStatusUpdate(null)
      dispatch(getPersonnes())
    }
    if (statusUpdate >= 400) {
      setStatusUpdate(null)
    }
  }, [statusUpdate])

  // close Modal Parapheur
  const closeModalCreateOk = () => {
    setModalCreateOk(false)
  }

  return (
    <>
      <div className="informations">
        <div className="informations">
          {/* Relation */}
          <SelectComponent
            forAndId="personSelected"
            name="Personne :"
            isClearable={true}
            options={optionsPersons && optionsPersons}
            placeholder="Sélectionner"
            onChange={(e) => handleSelectPerson(e)}
            value={optionsPersons && optionsPersons.find((c) => c.value === personSelected)}
          />
          {personSelected && noSex && (
            /* sexe */
            <div className="informations informations--borderFull">
              <p className="siren__txt">Sexe de naissance</p>
              <SelectComponent
                forAndId="sexe"
                options={optionsSexe}
                placeholder="Sélectionner"
                onChange={(e) => handleSaveSex(e)}
                value={optionsSexe.find((c) => c.value === addSexe)}
                copy={true}
                noLabel={true}
              />
            </div>
          )}

          {personSelected && personSelected === 'Ajouter' ? (
            <div className="informations informations--borderFull">
              {/* nom */}
              <Input
                forAndId="nom"
                type="text"
                name="Nom d'usage :"
                value={nom}
                onChange={(e) => handleChangeRelation(e)}
                placeholder="Nom"
                copy={true}
              />
              {/* prénom */}
              <Input
                forAndId="prenom"
                type="text"
                name="Prénom :"
                value={prenom}
                onChange={(e) => handleChangeRelation(e)}
                placeholder="Prénom"
                copy={true}
              />
              {/* mail */}
              <Input
                forAndId="mail"
                type="email"
                name="Mail :"
                value={mail}
                onChange={(e) => handleChangeRelation(e)}
                placeholder="exemple@gmail.com"
                copy={true}
              />
              {/* Date de naissance */}
              <DateInputComponent
                name={'dateNaissance'}
                label={'Date de naissance :'}
                value={dateNaissance}
                handleChange={handleChangeDate}
              />
              {/* sexe */}
              <SelectComponent
                forAndId="sexe"
                name="Sexe de naissance :"
                options={optionsSexe}
                placeholder="Sélectionner"
                onChange={(e, name) => handleChangeRelation(e, name)}
                value={optionsSexe.find((c) => c.value === sexe)}
                copy={true}
              />
              <div className="informations__buttons">
                <div
                  className="button button--red-red width--15"
                  onClick={(e) => handleSavePerson(e)}
                >
                  Créer une personne
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* typeRelation */}
        <SelectComponent
          forAndId="typeRelation"
          name="Relation :"
          options={optionsTypeRelation}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={
            optionsTypeRelation !== undefined &&
            optionsTypeRelation.find((c) => c.value === typeRelation)
          }
          copy={true}
        />
        {/* acharge */}
        <SelectComponent
          forAndId="acharge"
          name="A charge :"
          isSearchable={false}
          options={optionsTrueOrFalse}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={
            optionsTrueOrFalse !== undefined && optionsTrueOrFalse.find((c) => c.value === acharge)
          }
          copy={true}
        />
      </div>
      {change ? (
        <div className="informations__buttons">
          <div className="button button--red-red  width--18" onClick={() => handleAddLink()}>
            Enregistrer
          </div>
        </div>
      ) : (
        <div className="informations__buttons">
          <div className="button button--grey width--12">Enregistrer</div>
        </div>
      )}
      {/* modal response ok _____________________________________________________________*/}
      {modalCreateOk && <ModalParapheur closeModal={closeModalCreateOk} />}
      {/* modal response not ok _________________________________________________________*/}
      {modalNotOk && (
        <ModalCreatePPNotOK
          setModalNotOk={setModalNotOk}
          message={'Une personne existe déjà avec cette adresse mail'}
        />
      )}
      {/* modal not correct _____________________________________________________________*/}
      {modalNotCorrect && <NotCorrect setNotCorrect={setModalNotCorrect} />}
    </>
  )
}

export default InputsRelationShip
