import React, { useEffect } from 'react'
import { sortByType } from '../../../../services/helpers/sort'
import TableBodyBloc from '../../table/TableBodyBloc'
import BodyBeneficiaire from './BodyBeneficiaire'
import BodyBeneficiairePrint from './BodyBeneficiairePrint'

const TableBodyBeneficiaire = ({
  data,
  setData,
  setModalUpdate,
  setNewData,
  read,
  targetToSort,
  sort,
  setDataChange,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  // suppr
  const handleDelete = (element) => {
    setDataChange(true)
    setData(data.filter((el) => el !== element))
  }
  // edit
  const handleEdit = (element) => {
    setModalUpdate(true)
    setNewData(element)
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Nom':
          giveAType('nom')
          break
        case 'Prénom':
          giveAType('prenom')
          break
        case 'Date de naissance':
          giveAType('dateDeNaissanceFormatee')
          break
        case 'Date greffe':
          giveAType('date_greffe')
          break
        case 'Parts':
          giveAType('pourcentage_parts')
          break
        case 'Pleine propriété':
          giveAType('pourcentagePleinePropriete')
          break
        case 'Nue propriété':
          giveAType('pourcentageNuePropriete')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyBeneficiairePrint /> : <BodyBeneficiaire />}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      read={read}
      print={print}
    />
  )
}

export default TableBodyBeneficiaire
