import React from 'react'
import { FcDocument, FcInfo } from 'react-icons/fc'
import { FaCartPlus } from 'react-icons/fa'
import { MdOutlineCheckCircle, MdAddCircle, MdOutlineRemoveCircle } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import Copy from '../../../../Components/Copy'
import { getLastCours } from '../../../../../../services/API/financier'
import { FE, SUPPORTS_BUY, SUPPORTS_DELETE, SUPPORTS_SELECT } from '../../../../../../constants'

const BodySupports = ({ element, tableType, handleclick }) => {
  const dispatch = useDispatch()
  // selection
  const selection = useSelector((state) => state.getSelection.selection)
  // handleclickDICI
  const handleclickDICI = (element) => {
    window.open(element.dici_url)
  }
  // handleclickInfo
  const handleclickInfo = (element) => {
    dispatch(getLastCours(element.isin, true))
  }
  // already selected ?
  const isAlreadySelected = (element) => {
    return selection
      ? selection.find((el) => el.isin === element.isin && el.nom === element.nom) !== undefined
        ? true
        : false
      : false
  }

  return (
    <>
      {/* Acheter ou Sélectionner */}
      {tableType === SUPPORTS_BUY ? (
        <td className="tbody__tr__td" onClick={() => handleclick(element)}>
          <div className="tbody__tr__td__button">
            <FaCartPlus />
          </div>
        </td>
      ) : tableType === SUPPORTS_SELECT ? (
        <td className="tbody__tr__td" onClick={() => handleclick(element)}>
          {isAlreadySelected(element) ? (
            <div className="tbody__tr__td__button--selected">
              <MdOutlineCheckCircle />
            </div>
          ) : (
            <div className="tbody__tr__td__button">
              <MdAddCircle />
            </div>
          )}
        </td>
      ) : tableType === SUPPORTS_DELETE ? (
        <td className="tbody__tr__td" onClick={() => handleclick(element)}>
          <div className="tbody__tr__td__button">
            <MdOutlineRemoveCircle />
          </div>
        </td>
      ) : null}
      {/* Fournisseur */}
      {element.m_nom ? (
        <td className="tbody__tr__td bold" onClick={() => handleclick(element)}>
          <div className="tbody__tr__td--divCopy">
            <Copy value={element.m_nom} little={true} />
            {element.m_nom}
          </div>
        </td>
      ) : (
        <td className="tbody__tr__td colSpan"></td>
      )}
      {/* Nom */}
      <td className="tbody__tr__td bold" onClick={() => handleclick(element)}>
        <div className="tbody__tr__td--divCopy">
          <Copy value={element.nom} little={true} />
          {element.nom}
        </div>
      </td>
      {/* ISIN */}
      <td className="tbody__tr__td" onClick={() => handleclick(element)}>
        <div className="tbody__tr__td--divCopy">
          <Copy value={element.isin} little={true} />
          {element.isin}
        </div>
      </td>
      {element.c_nom !== FE ? (
        <>
          {/* infos */}
          <td className="tbody__tr__td bold">
            <div className="tbody__tr__td__button" onClick={() => handleclickInfo(element)}>
              <FcInfo />
            </div>
          </td>
          {/* element */}
          {element.dici_url !== 'NULL' && element.dici_url ? (
            <td className="tbody__tr__td">
              <div className="tbody__tr__td__button" onClick={() => handleclickDICI(element)}>
                <FcDocument />
              </div>
            </td>
          ) : (
            <td className="tbody__tr__td colSpan"></td>
          )}
        </>
      ) : (
        <td className="tbody__tr__td bold colSpan" colSpan={2}>
          Fond Euro
        </td>
      )}
      {/* note */}
      <td className="tbody__tr__td" onClick={() => handleclick(element)}>
        {element.note}
      </td>
      {/* Acheter ou Sélectionner */}
      {tableType === SUPPORTS_BUY ? (
        <td className="tbody__tr__td" onClick={() => handleclick(element)}>
          <div className="tbody__tr__td__button">
            <FaCartPlus />
          </div>
        </td>
      ) : tableType === SUPPORTS_SELECT ? (
        <td className="tbody__tr__td" onClick={() => handleclick(element)}>
          {isAlreadySelected(element) ? (
            <div className="tbody__tr__td__button--selected">
              <MdOutlineCheckCircle />
            </div>
          ) : (
            <div className="tbody__tr__td__button">
              <MdAddCircle />
            </div>
          )}
        </td>
      ) : tableType === SUPPORTS_DELETE ? (
        <td className="tbody__tr__td" onClick={() => handleclick(element)}>
          <div className="tbody__tr__td__button">
            <MdOutlineRemoveCircle />
          </div>
        </td>
      ) : null}
    </>
  )
}

export default BodySupports
