import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSocial } from '../../../../../../services/API/social'
import usePersonData from '../../../../../layout/usePersonData'
import useCustomPrint from '../../../../../layout/useCustomPrint'
import Loader from '../../../../../layout/Loader'
import PrintComponent from '../../../../Components/PrintComponent'
import ButtonsRow from '../../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../../Patrimoine/PatrimoineChartCard'
import SocialToPrint from './SocialToPrint'
import SocialPieChart from './SocialPieChart'
import LittleLoader from '../../../../../layout/LittleLoader'

const SocialHome = () => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  const social = useSelector((state) => state.getSocial.social)
  const { valeurTotaleSociale, produitsPER, produitsRetraite, produitsPrevoyance } = social || {}
  // recharge
  useEffect(() => {
    if (!social) {
      dispatch(getSocial(person?.id, isPP))
    }
  }, [social, person, isPP, dispatch])
  // values
  const valeurLiquidites = useMemo(
    () => produitsPER.map((produit) => produit.liquidite).reduce((acc, curr) => acc + curr, 0),
    [produitsPER]
  )
  const totalPER = useMemo(
    () =>
      produitsPER.length > 0
        ? produitsPER
            .map((produit) => produit.montantTotalCalcule)
            .reduce((acc, current) => acc + current)
        : 0,
    [produitsPER]
  )
  const totalRetraite = useMemo(
    () =>
      produitsRetraite.length > 0
        ? produitsRetraite
            .map((produit) => produit.montantTotalCalcule)
            .reduce((acc, current) => acc + current)
        : 0,
    [produitsRetraite]
  )
  const totalPrevoyance = useMemo(
    () =>
      produitsPrevoyance.length > 0
        ? produitsPrevoyance
            .map((produit) => produit.capital)
            .reduce((acc, current) => acc + current)
        : 0,
    [produitsPrevoyance]
  )
  const values = useMemo(
    () => [
      { label: 'Disponibilités', value: valeurLiquidites },
      { label: 'Epargne', value: totalPER },
      { label: 'Retraite', value: totalRetraite },
      { label: 'Prévoyance', value: totalPrevoyance },
    ],
    [valeurLiquidites, totalPER, totalRetraite, totalPrevoyance]
  )
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!social) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<SocialToPrint elementToDisplay={social} values={values} />}
        />
        <ButtonsRow handlePrint={handlePrint} backLink={-1} />
        <div className="sousSection">
          <div className="cards cards--fullWidth">
            <PatrimoineValueCard
              totalAmount={valeurTotaleSociale}
              values={values}
            />
            {valeurTotaleSociale !== 0 ? (
              <PatrimoineChartCard
                children={<SocialPieChart social={social} />}
                detenteurs={true}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default SocialHome
