import React from 'react'
import Card from '../Components/Card'
import { stringToCapitalize } from '../../../services/helpers/strings'
import { shortFormeJuridique } from '../../../services/helpers/helpers'

const InformationsCardPrint = ({ data, dataDoc, title }) => {
  const renderDataValue = (label, value) => {
    if (
      label === "Nom d'usage :" ||
      label === 'Nom de naissance:' ||
      label === 'Nom du dirigeant :'
    ) {
      return value.toUpperCase()
    } else if (label === 'Prénom :') {
      return stringToCapitalize(value)
    } else if (label === 'Forme Juridique :') {
      return shortFormeJuridique(value)
    }
    return value
  }

  return (
    <>
      <Card title={title} open={true} h2={true} print={true}>
        <div className="informations">
          {data?.map(
            (item, index) =>
              item && (
                <div key={`${item.label}-${index}`} className="informations__line">
                  <p className="informations__line__label">{item.label}</p>
                  <div className="informations__line__data">
                    {item.value ? (
                      <p>{renderDataValue(item.label, item.value)}</p>
                    ) : (
                      <p className="informations__line__undefined">non renseigné</p>
                    )}
                  </div>
                </div>
              )
          )}
          {dataDoc && (
            <div className="informations__line">
              <p className="informations__line__label">Documents :</p>
              <p className="informations__line__data">{dataDoc.length}</p>
            </div>
          )}
        </div>
      </Card>
    </>
  )
}

export default InformationsCardPrint
