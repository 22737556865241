import React from 'react'
import { optionsFormeJuridique } from '../../../../data/options/optionsPP'
import Card from '../../Components/Card'
import Input from '../../Components/Input'
import SelectComponent from '../../Components/SelectComponent'
import DateInputComponent from '../../Components/DateInputComponent'

const InputsInfos = ({ handleChange, handleChangeDate, infosPrincipales }) => {
  const { denominationSociale, formeJuridique, dateCreation, mail } = infosPrincipales || {}

  return (
    <Card title="Informations principales" open={true}>
      <div className={'informations informations--borderFull'}>
        {/* Dénomination sociale */}
        <Input
          forAndId="denominationSociale"
          type="text"
          name="Denomination Sociale :"
          required={true}
          value={denominationSociale}
          onChange={(e) => handleChange(e)}
          placeholder="Entreprise"
          copy={true}
        />
        {/* Forme juridique */}
        <SelectComponent
          forAndId="formeJuridique"
          name="Forme Juridique :"
          options={optionsFormeJuridique}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsFormeJuridique.find((c) => c.value === formeJuridique)}
          required
          copy={true}
        />
        {/* Date de création */}
        <DateInputComponent
          name={'dateCreation'}
          label={'Date de création :'}
          value={dateCreation}
          handleChange={handleChangeDate}
        />
        {/* mail */}
        <Input
          forAndId="mail"
          type="email"
          name="Mail :"
          required={true}
          value={mail}
          onChange={(e) => handleChange(e)}
          placeholder="exemple@gmail.com"
          copy={true}
        />
      </div>
    </Card>
  )
}

export default InputsInfos
