import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sortByType } from '../../../../../../../services/helpers/sort'
import { getBienSuccess } from '../../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../../redux/actions/actionGetBienType'
import { supprId } from '../../../../../../../services/helpers/helpers'
import TableBodyBloc from '../../../../../table/TableBodyBloc'
import BodyPF from './BodyPF'
import BodyPFPrint from './BodyPFPrint'

const TableBodyPF = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // ouvrir infos bien
  const handleShow = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'FINANCIER', type: 'comptebancaire' }))
    navigate('/user/patrimoine/actifs/financier/produits-financiers/compte')
  }
  // modifier bien
  const handleEdit = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'FINANCIER', type: 'comptebancaire' }))
    navigate('/user/patrimoine/actifs/financier/produits-financiers/ajouter')
  }
  // duplicate bien
  const handleDuplicate = (bien) => {
    const bienBis = {
      ...bien,
      id: 0,
      fichiers: [],
      numero: '',
      detenteurs: bien.detenteurs.map((det) => supprId(det)),
    }
    dispatch(getBienSuccess(bienBis))
    dispatch(getbienType({ categorie: 'FINANCIER', type: 'comptebancaire' }))
    navigate('/user/patrimoine/actifs/financier/produits-financiers/ajouter')
  }
  // supprimer bien
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Libelle':
          giveAType('libelle')
          break
        case 'Descriptif':
          giveAType('descriptif')
          break
        case 'Etablissement':
          giveAType('etablissement')
          break
        case 'Numéro':
          giveAType('numero')
          break
        case 'Solde':
          giveAType('solde')
          break
        case 'Au...':
          giveAType('dateSolde')
          break
        case 'Date de clôture':
          giveAType('dateCloture')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyPFPrint /> : <BodyPF handleShow={handleShow} />}
      print={print}
      handleShow={handleShow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleDuplicate={handleDuplicate}
    />
  )
}

export default TableBodyPF
