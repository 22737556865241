import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCatalogues } from '../../../../../services/API/catalogue'
import NewSupportComponent from './NewSupportComponent'
import NewOperationComponent from './NewOperationComponent'
import { concatValuesSupportsP } from '../../../../../services/helpers/helpers'
import LittleLoader from '../../../../layout/LittleLoader'

const PortefeuilleComponent = ({ categorie }) => {
  const dispatch = useDispatch()
  // financier
  const financier = useSelector((state) => state.getFinancier.financier)
  const social = useSelector((state) => state.getSocial.social)
  const bien = useSelector((state) => state.getBien.bien)
  const catalogues = useSelector((state) => state.getCatalogues.catalogues)
  // recuperation bien
  const getBienUpdated = useCallback(() => {
    switch (categorie) {
      case 'VM':
        return financier.produitsVM.find((el) => el.id === bien.id)
      case 'AV':
        return financier.produitsAV.find((el) => el.id === bien.id)
      case 'PER':
        return social.produitsPER.find((el) => el.id === bien.id)
      default:
        return social.produitsRetraite.find((el) => el.id === bien.id)
    }
  }, [bien, financier, social, categorie])
  const bienToDisplay = useMemo(() => getBienUpdated(), [getBienUpdated])
  const [product, setProduct] = useState(null)
  const [list, setList] = useState([])
  const cat = useMemo(
    () =>
      ({
        VM: 'valeurs-mobilieres',
        AV: 'assurances-vie',
        PER: 'PER',
        retraite: 'retraite',
      }[categorie]),
    [categorie]
  )
  //redirection
  const redirection = `/user/patrimoine/actifs/${
    ['PER', 'retraite'].includes(categorie) ? 'social' : 'financier'
  }/${cat}`
  // recup catalogues
  useEffect(() => {
    !catalogues && dispatch(getCatalogues())
  }, [catalogues, dispatch])
  // maj product
  useEffect(() => {
    if (catalogues && bienToDisplay) {
      const newProduct = catalogues.find((el) => el.id === bienToDisplay.idCatalogue)
      setProduct(newProduct)
    }
  }, [catalogues, bienToDisplay])

  // maj list
  useEffect(() => {
    if (product && bienToDisplay?.portefeuille) {
      const updatedList = product.dicis.filter(
        (dicisItem) =>
          !concatValuesSupportsP(bienToDisplay.portefeuille.supportsP).includes(dicisItem.isin)
      )
      setList(bienToDisplay.portefeuille.supportsP.length > 0 ? updatedList : product.dicis)
    }
  }, [product, bienToDisplay])

  if (!bien) return <Navigate to={redirection} replace />
  if (!bienToDisplay) return <LittleLoader />

  return (
    <div className="cards cards--fullWidth">
      <div className="cards cards--fullWidth">
        <NewSupportComponent
          bienToDisplay={bienToDisplay}
          categorie={categorie}
          product={product}
          list={list}
        />
      </div>
      <div className="cards cards--fullWidth">
        <NewOperationComponent
          bienToDisplay={bienToDisplay}
          product={product}
          categorie={categorie}
        />
      </div>
    </div>
  )
}

export default PortefeuilleComponent
