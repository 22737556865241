// ______________________________________ Personnes
// options personne
export const optionsPerson = [
    { value: 'Physiques', label: 'Physiques' },
    { value: 'Morales', label: 'Morales' },
  ]
  // options personne ou contact
  export const optionsPersonOrContact = [
    { value: 'personnePhysique', label: 'Personnes Physiques' },
    { value: 'personneMorale', label: 'Personnes Morales' },
    { value: 'contact', label: 'Contacts' },
  ]
  