import React from 'react'
import Card from '../../../Components/Card'
import BlocTable from '../../../table/BlocTable'
import { useNavigate } from 'react-router-dom'
import { CATALOGUES } from '../../../../../constants'
import TableBodyCatalogue from './Table/TableBodyCatalogue'

const CatalogueCard = ({ catalogues, title, categorie }) => {
  const navigate = useNavigate()
  // titles
  const titles = ['N°', 'Libellé', 'Fournisseur', 'Supports', 'Voir', 'Modifier', 'Supprimer']
  // handleClickAdd
  const handleClickAdd = () => {
    const basePath = `/user/patrimoine/actifs/${
      categorie === 'VM' || categorie === 'AV' ? 'financier' : 'social'
    }/catalogue/formulaire/`
    navigate(`${basePath}${categorie}`)
  }

  return (
    <>
      <Card title={title} h5={true} open={true}>
        <div className="informations">
          <BlocTable
            data={catalogues}
            titles={titles}
            tableType={CATALOGUES}
            children={<TableBodyCatalogue />}
            title={title}
            handleAdd={() => handleClickAdd()}
          />
        </div>
      </Card>
    </>
  )
}

export default CatalogueCard
