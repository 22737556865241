import React from 'react'
import { COLORS } from '../../../../../../constants'
import PieChartComponent from '../../../../Charts/PieChartComponent'
import LegendPieChart from '../../../../Charts/LegendPieChart'

const SocialPieChart = ({ social, detenteurs, print }) => {
  const { produitsPrevoyance, produitsPER, produitsRetraite, valeurDetenteurs, nomDetenteurs } =
    social
  // data
  const dataSocial = [
    {
      name: 'Portefeuilles PER',
      value:
        produitsPER.length > 0
          ? produitsPER
              .map((produit) => produit.montantTotalCalcule)
              .reduce((acc, current) => acc + current, 0)
          : 0,
      fill: COLORS.colorRepartition01,
      color: 'repartition01',
    },
    {
      name: 'Portefeuilles Retraite',
      value:
        produitsRetraite.length > 0
          ? produitsRetraite
              .map((produit) => produit.montantTotalCalcule)
              .reduce((acc, current) => acc + current, 0)
          : 0,
      fill: COLORS.colorRepartition02,
      color: 'repartition02',
    },
    {
      name: 'Portefeuilles Prévoyance',
      value:
        produitsPrevoyance.length > 0
          ? produitsPrevoyance
              .map((produit) => produit.capital)
              .reduce((acc, current) => acc + current, 0)
          : 0,
      fill: COLORS.colorRepartition03,
      color: 'repartition03',
    },
    {
      name: 'Disponibilités',
      value: produitsPER.map((produit) => produit.liquidite).reduce((acc, curr) => acc + curr, 0),
      fill: COLORS.colorPrimary,
      color: 'divers',
    },
  ]

  // detenteurs
  const arrayDetenteurs = []
  for (let i = 0; i < nomDetenteurs.length; i++) {
    arrayDetenteurs.push({ nom: nomDetenteurs[i], valeur: valeurDetenteurs[i] })
  }
  const dataDetenteurs = arrayDetenteurs.map((det) => ({ name: det.nom, value: det.valeur }))
  const data = !detenteurs ? dataSocial : dataDetenteurs

  const title = !detenteurs ? 'Répartition' : 'Détenteurs'
  return (
    <>
      <PieChartComponent data={data} title={title} detenteurs={detenteurs} print={print} />
      <hr className="hr" />
      <LegendPieChart data={data} title={title} detenteurs={detenteurs} />
    </>
  )
}

export default SocialPieChart
