import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deletePP, getPersonnes } from '../../../services/API/personnes'
import Modale from '../Components/Modale'
import { getPerson } from '../../../redux/actions/actionGetPerson'
import { postPersonType } from '../../../redux/actions/actionPostPersonType'
import NotOk from '../Components/Modales/NotOk'

const DeletePP = ({ setModalDelete, newData }) => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(null)
  const [modalOk, setModalOk] = useState(null)
  const [modalNotOk, setModalNotOk] = useState(null)

  const handleDelete = () => {
    dispatch(deletePP(newData.id, setStatus))
  }
  // response delete contact
  useEffect(() => {
    if (status) {
      if (status < 400) {
        setModalOk(true)
      } else {
        setModalNotOk(true)
      }
      setStatus(null)
    }
  }, [status])
  //close
  const handleClose = () => {
    dispatch(getPersonnes())
    dispatch(getPerson(null))
    dispatch(postPersonType(null))
    setModalOk(false)
    setModalNotOk(false)
    setModalDelete(false)
  }

  return (
    <>
      <Modale
        close={() => setModalDelete(false)}
        title="Souhaitez-vous réellement supprimer cette personne ? ?"
      >
        <div className="informations">
          <div className="informations__blocTxt">
            <p>{`${newData.nom} ${newData.prenom}`}</p>
          </div>
          <div className="informations__buttons">
            <div className="button button--blue-red width--12" onClick={() => handleDelete()}>
              Supprimer
            </div>
            <div
              className="button button--blue-red width--12"
              onClick={() => setModalDelete(false)}
            >
              Annuler
            </div>
          </div>
        </div>
      </Modale>
      {modalOk && (
        <Modale close={() => handleClose()} title={`Personne supprimée`}>
          <div className="informations__buttons">
            <div className="button button--blue-red width--12" onClick={() => handleClose()}>
              Fermer
            </div>
          </div>
        </Modale>
      )}
      {modalNotOk && <NotOk setNotOK={setModalNotOk} closeModale={handleClose} />}
    </>
  )
}

export default DeletePP
