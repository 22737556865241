import React from 'react'
import { ACTIFS_PRO } from '../../../../../../constants'
import TableToPrint from '../../../../table/TableToPrint'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import BiensProPieChart from '../BiensProPieChart'
import TableBodyBienPro from '../Table/TableBodyBienPro'

const ActifsProToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurBiensProfesionnel } = elementToDisplay || {}
  return (
    <>
      <PatrimoineValueCard totalAmount={valeurBiensProfesionnel} values={values} />
      <PatrimoineChartCardPrint
        children={<BiensProPieChart actifsBienProfessionnel={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={ACTIFS_PRO}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyBienPro />}
      />
      <TableToPrint
        data={dataSold}
        tableType={ACTIFS_PRO}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyBienPro />}
      />
    </>
  )
}

export default ActifsProToPrint
