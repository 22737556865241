// ______________________________________ social
export const optionsPER = [
  { value: 'PERIN', label: 'PERIN' },
  { value: 'PEE / PEI', label: 'PEE / PEI' },
  {
    value: 'PERCO / PERCOI / PERECO',
    label: 'PERCO / PERCOI / PERECO',
  },
  { value: 'PERO', label: 'PERO' },
  { value: 'PERP', label: 'PERP' },
  { value: 'Contrat MADELIN', label: 'Contrat MADELIN' },
]
export const optionsCategorie = [
  { value: 'Prévoyance Individuelle', label: 'Prévoyance Individuelle' },
  {
    value: 'Prévoyance Collective',
    label: 'Prévoyance Collective',
  },
]
export const optionsSousCategorieIndividuelle = [
  { value: 'Assurance emprunteur', label: 'Assurance emprunteur' },
  {
    value: 'Décès / Incapacité / Invalidité',
    label: 'Décès / Incapacité / Invalidité',
  },
  {
    value: 'Complémentaire santé',
    label: 'Complémentaire santé',
  },
  {
    value: 'Tontine',
    label: 'Tontine',
  },
  {
    value: 'Dépendance',
    label: 'Dépendance',
  },
  {
    value: 'Obsèques',
    label: 'Obsèques',
  },
  {
    value: 'Autre type de contrat',
    label: 'Autre type de contrat',
  },
]
export const optionsSousCategorieCollective = [
  { value: 'Retraite sur-complémentaire (art 83 et 39)', label: 'Retraite sur-complémentaire (art 83 et 39)' },
  {
    value: 'Décès / Incapacité / Invalidité',
    label: 'Décès / Incapacité / Invalidité',
  },
  {
    value: 'Complémentaire santé',
    label: 'Complémentaire santé',
  },
  {
    value: 'Autre type de contrat',
    label: 'Autre type de contrat',
  },
]
