import React from 'react'
import { optionsTrueOrFalse } from '../../../../../data/options/options'
import Card from '../../../Components/Card'
import Input from '../../../Components/Input'
import SelectComponent from '../../../Components/SelectComponent'
import DateInputComponent from '../../../Components/DateInputComponent'

const SaleInformationsCard = ({ handleChange, handleChangeDate, information, immo }) => {
  const { dateCreation, valeurAcquisition, closOuVendu, valeurVente, dateVente } = information || {}

  return (
    <Card title="Acquisition / Vente" h4={true} open={true}>
      <div className="informations">
        {/* Date de création */}
        <DateInputComponent
          name={'dateCreation'}
          label={immo ? "Date d'acquisition :" : 'Date de création / acquisition :'}
          value={dateCreation}
          handleChange={handleChangeDate}
        />
        {/* valeurAcquisition */}
        <Input
          forAndId="valeurAcquisition"
          type="number"
          name="Valeur d'acquisition :"
          value={valeurAcquisition}
          onChange={(e) => handleChange(e)}
        />
        {/* closOuVendu */}
        <SelectComponent
          forAndId="closOuVendu"
          name="Bien clos ou vendu :"
          options={optionsTrueOrFalse}
          placeholder="Sélectionner"
          onChange={(e, name) => handleChange(e, name)}
          value={optionsTrueOrFalse.find((c) => c.value === closOuVendu)}
          copy={true}
        />
        {closOuVendu && (
          <>
            {/* valeurVente */}
            <Input
              forAndId="valeurVente"
              type="number"
              name="Valeur de vente :"
              value={valeurVente}
              onChange={(e) => handleChange(e)}
              copy={true}
            />
            {/* dateVente */}
            <DateInputComponent
              name={'dateVente'}
              label={'Date de vente :'}
              value={dateVente}
              handleChange={handleChangeDate}
            />
          </>
        )}
      </div>
    </Card>
  )
}

export default SaleInformationsCard
