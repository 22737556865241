import React from 'react'
import Modale from '../../Components/Modale'
import Input from '../../Components/Input'
import { calcul10000eme } from '../../../../services/helpers/numbers'

const ModaleDivers = ({
  setToggleDivers,
  handleChange,
  divers,
  setDivers,
  handleSubmit,
  montantCategorieDivers,
  montantSousCategorieDivers,
  type,
  sousType,
}) => {
  let line1
  switch (type) {
    case 'actif':
      line1 = `Valeur actif non affectée : `
      break
    case 'passif':
      line1 = `Valeur passif non affectée :`
      break
    case 'revenus':
      line1 = `Valeur revenus non affectée : `
      break
    case 'charges':
      line1 = `Valeur charges non affectée : `
      break
    default:
      return
  }
  let line2
  switch (sousType) {
    case 'immobilier':
      line2 = `Valeur actif immobilier non affectée : `
      break
    case 'professionnel':
      line2 = `Valeur actif professionnel non affectée : `
      break
    case 'autres':
      line2 = `Valeur actif autres non affectée : `
      break
    case 'emprunts':
      line2 = `Valeur passif emprunts non affectée : `
      break
    case 'dettes':
      line2 = `Valeur passif dette non affectée : `
      break
    case 'foncier':
      line2 = `Valeur revenus fonciers non affectée : `
      break
    case 'mobilier':
      line2 = `Valeur revenus mobiliers non affectée : `
      break
    case 'professionnels':
      line2 = `Valeur revenus professionnels non affectée : `
      break
    case 'pension-rente':
      line2 = `Valeur revenus pension et rente non affectée : `
      break
    case 'autre':
      line2 = `Valeur revenus autres non affectée :`
      break
    case 'generales':
      line2 = `Valeur dépenses courantes non affectée : `
      break
    case 'pension':
      line2 = `Valeur pension non affectée : `
      break
    case 'emprunt-dette':
      line2 = `Valeur emprunt et dette non affectée : `
      break
    case 'credit':
      line2 = `Valeur crédit d'impôt non affectée : `
      break
    case 'deductibles':
      line2 = `Valeur charges déductibles non affectée : `
      break
    case 'impot-taxe':
      line2 = `Valeur impôts et taxes non affectée : `
      break
    case 'comptes-bancaires':
      line2 = `Valeur comptes bancaires non affectée : `
      break
    default:
      return
  }

  return (
    <Modale
      close={() => setToggleDivers(false)}
      title="Répartition"
    >
      <div className="informations">
        <div className="informations__patrimoine">
          <p className="informations__patrimoine__line">
            {line1}
            <span>
              {calcul10000eme(montantCategorieDivers).toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })}
              €
            </span>
          </p>
          <p className="informations__patrimoine__line">
            {line2}
            <span>
              {' '}
              {calcul10000eme(montantSousCategorieDivers).toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })}
              €
            </span>
          </p>
          <div className="informations--center">
            {/* divers */}
            <Input
              forAndId="divers"
              name="Souhaitez-vous utiliser les valeurs non affectées ?"
              onChange={(e, name) => handleChange(e, name)}
              value={divers}
              check={true}
              setValue={setDivers}
            />
          </div>
        </div>
        <div className="informations__buttons">
          <div className="button button--red-red  width--18" onClick={() => handleSubmit()}>
            Enregistrer
          </div>
        </div>
      </div>
    </Modale>
  )
}

export default ModaleDivers
