import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sortByType } from '../../../../../../services/helpers/sort'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import { supprId } from '../../../../../../services/helpers/helpers'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyEmprunt from './BodyEmprunt'
import BodyEmpruntPrint from './BodyEmpruntPrint'

const TableBodyEmprunt = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // ouvrir infos passif
  const handleShow = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'PASSIFS', type: 'emprunt' }))
    navigate('/user/patrimoine/passifs/emprunts/info')
  }
  // modifier passif
  const handleEdit = (bien) => {
    dispatch(getBienSuccess(bien))
    dispatch(getbienType({ categorie: 'PASSIFS', type: 'emprunt' }))
    navigate('/user/patrimoine/passifs/emprunts/ajouter')
  }
  // duplicate bien
  const handleDuplicate = (bien) => {
    const bienBis = {
      ...bien,
      id: 0,
      fichiers: [],
      refEmprunt: `${bien.refEmprunt} - copie`,
      detenteurs: bien.detenteurs.map((det) => supprId(det)),
    }
    dispatch(getBienSuccess(bienBis))
    dispatch(getbienType({ categorie: 'PASSIFS', type: 'emprunt' }))
    navigate('/user/patrimoine/passifs/emprunts/ajouter')
  }
  // supprimer passif
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Référence':
          giveAType('refEmprunt')
          break
        case 'Emprunt':
          giveAType('montantEmprunte')
          break
        case 'Restant dû':
          giveAType('montantRestant')
          break
        case 'Mensualités':
          giveAType('mensualite')
          break
        case 'Durée':
          giveAType('dureeMois')
          break
        case 'Début':
          giveAType('dateDebut')
          break
        case 'Fin':
          giveAType('dateFin')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyEmpruntPrint /> : <BodyEmprunt handleShow={handleShow} />}
      print={print}
      handleShow={handleShow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleDuplicate={handleDuplicate}
    />
  )
}

export default TableBodyEmprunt
