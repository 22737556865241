import { useEffect, useState } from 'react'
import { standardizeAndLowerCase } from '../../../services/helpers/strings'
import { concat } from '../../../services/helpers/helpers'

const useSearch = (data, search) => {
  const [dataUse, setDataUse] = useState(data)
  useEffect(() => {
    const listArrValues = concat(data)
    if (search && search.length > 2) {
      let listMatchSearch = []
      for (let i = 0; i < listArrValues.length; i++) {
        if (listArrValues[i].includes(standardizeAndLowerCase(search))) {
          listMatchSearch.push(data[i])
        }
      }
      setDataUse(listMatchSearch)
    } else {
      return
    }
  }, [search])
  return dataUse
}

export default useSearch
