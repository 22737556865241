import React, { useRef } from 'react'
import HeaderHomepage from '../../layout/HeaderHomepage'
import Toast from '../../components/Components/Toast'

const HomePageComponent = ({ children }) => {
  const toastId = useRef(null)

  return (
    <>
      <div className="homepage">
        <HeaderHomepage />
        {children}
      </div>
      <Toast toastId={toastId} position={'bottom-center'} duration={2000} />
    </>
  )
}

export default HomePageComponent
