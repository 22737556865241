// IMPORTS // ______________________________________________________________

import { createReducer } from '@reduxjs/toolkit'
// actions imports
import {
  postUserLoad,
  postUserSuccess,
  postUserError,
} from '../actions/actionPostUser'

// REDUCER // ______________________________________________________________

// initial state
const initialStatePostUser = {
  isLoading: false,
  status: null,
  error: '',
}
// reducer
export const postUserReducer = createReducer(initialStatePostUser, (builder) => {
  return builder
    .addCase(postUserLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(postUserSuccess, (draft, action) => {
      draft.isLoading = false
      draft.status = action.payload
      draft.error = ''
      return
    })
    .addCase(postUserError, (draft, action) => {
      draft.isLoading = false
      draft.status = null
      draft.error = action.payload
      return
    })
})