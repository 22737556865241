import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sortByType } from '../../../../../../services/helpers/sort'
import { getBienSuccess } from '../../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../../redux/actions/actionGetBienType'
import TableBodyBloc from '../../../../table/TableBodyBloc'
import BodyAssurances from './BodyAssurances'
import { PRODUITS_RETRAITE } from '../../../../../../constants'
import BodyAssurancesPrint from './BodyAssurancesPrint'

const TableBodyAssurances = ({
  setNewData,
  setModalDelete,
  sort,
  targetToSort,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
  tableType,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // ouvrir infos bien
  const handleShow = (produit) => {
    dispatch(getBienSuccess(produit))
    if (tableType === PRODUITS_RETRAITE) {
      dispatch(getbienType({ categorie: 'SOCIAL', type: 'retraite' }))
      navigate('/user/patrimoine/actifs/social/retraite/produit/produit')
    } else {
      dispatch(getbienType({ categorie: 'FINANCIER', type: 'produitFinancierAV' }))
      navigate('/user/patrimoine/actifs/financier/assurances-vie/produit/produit')
    }
  }
  const handleEdit = (produit) => {
    dispatch(getBienSuccess(produit))
    if (tableType === PRODUITS_RETRAITE) {
      if (!produit.dateEffet) {
        navigate('/user/patrimoine/actifs/social/retraite/produit/produit')
      } else {
        dispatch(getbienType({ categorie: 'SOCIAL', type: 'retraite' }))
        navigate('/user/patrimoine/actifs/social/retraite/produit/portefeuille')
      }
    } else {
      if (!produit.dateEffet) {
        navigate('/user/patrimoine/actifs/financier/assurances-vie/produit/produit')
      } else {
        dispatch(getbienType({ categorie: 'FINANCIER', type: 'produitFinancierAV' }))
        navigate('/user/patrimoine/actifs/financier/assurances-vie/produit/portefeuille')
      }
    }
  }

  // supprimer bien
  const handleDelete = (bien) => {
    setNewData(bien)
    setModalDelete(true)
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Libellé':
          giveAType('libelle')
          break
        case 'Portefeuille':
          giveAType('montantTotalCalcule')
          break
        case 'Total':
          giveAType('total')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyAssurancesPrint /> : <BodyAssurances handleShow={handleShow} />}
      print={print}
      handleShow={handleShow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  )
}

export default TableBodyAssurances
