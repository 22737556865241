import React from 'react'
import { useSelector } from 'react-redux'
import { standardizeAndLowerCase } from '../../../services/helpers/strings'

const LegendPieChart = ({ data, detenteurs, title }) => {
  // person
  const person = useSelector((state) => state.getPerson.person)
  const str = `${person.nom} ${person.prenom}`
  // includes
  const includesFunction = (str) => {
    return str.includes('affecté')
  }
  return (
    <div className="legend">
      <div>
      <h5 className="legend__title">{title}</h5>
        {data.map((el, index) => (
          <div key={index}>
            <div className="legend__item" key={index}>
              <div
                className={`legend__item__color legend__item__color--${
                  includesFunction(el.name) 
                    ? 'divers'
                    : detenteurs
                    ? standardizeAndLowerCase(el.name) === `${standardizeAndLowerCase(str)}`
                      ? 'detenu'
                      : 'nonDetenu'
                    : el.color
                }`}
              ></div>
              <p className="legend__item__txt">{el.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LegendPieChart
