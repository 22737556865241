import React from 'react'
import Card from '../../../Components/Card'
import BlocTable from '../../../table/BlocTable'
import TableBodyCharge from './Table/TableBodyCharge'

const ChargesCard = ({ data, handleAdd, title, tableType }) => {
  // titles
  const titles = [
    'N°',
    'Nom',
    'Montant annuel',
    'Périodicité',
    'Début',
    'Fin',
    'Voir',
    'Modifier',
    'Supprimer',
    'Dupliquer',
  ]
  return (
    <Card title={title} open={data.length > 0} h3={true}>
      <div className="informations">
        <BlocTable
          data={data}
          titles={titles}
          tableType={tableType}
          children={<TableBodyCharge />}
          title={title}
          handleAdd={() => handleAdd()}
        />
      </div>
    </Card>
  )
}

export default ChargesCard
