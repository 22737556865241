import React from 'react'
import Card from '../../../Components/Card'
import BlocTable from '../../../table/BlocTable'
import TableBodyBienImmo from './Table/TableBodyBienImmo'
import { ACTIFS_IMMO } from '../../../../../constants'

const PatrimoineImmoCard = ({ data, handleAddImmo, title }) => {
  // titles
  const titles = [
    'N°',
    'Nom',
    'Valeur',
    'Catégorie',
    'Adresse',
    'Code postal',
    'Ville',
    'Voir',
    'Modifier',
    'Supprimer',
    'Dupliquer',
  ]

  return (
    <Card title={title} h4={true} open={true}>
      <div className="informations">
        <BlocTable
          data={data}
          titles={titles}
          tableType={ACTIFS_IMMO}
          children={<TableBodyBienImmo />}
          title={title}
          handleAdd={() => handleAddImmo()}
        />
      </div>
    </Card>
  )
}

export default PatrimoineImmoCard
