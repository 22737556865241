import React, { useEffect } from 'react'
import ButtonsRow from './ButtonsRow'
import PatrimoineBarChartCard from './PatrimoineBarChartCard'
import { useDispatch, useSelector } from 'react-redux'
import usePersonData from '../../../layout/usePersonData'
import { getActifs } from '../../../../services/API/actifs'
import { getPassifs } from '../../../../services/API/passifs'
import { getRevenus } from '../../../../services/API/revenus'
import { getCharges } from '../../../../services/API/charges'
import Loader from '../../../layout/Loader'

const PatrimoineHome = () => {
  const dispatch = useDispatch()
  const { person, isPP } = usePersonData()
  const actifs = useSelector((state) => state.getProperties.properties)
  const passifs = useSelector((state) => state.getPassifs.passifs)
  const revenus = useSelector((state) => state.getIncomes.incomes)
  const charges = useSelector((state) => state.getCharges.charges)
  // recharge
  useEffect(() => {
    dispatch(getActifs(person?.id, isPP))
    dispatch(getPassifs(person?.id, isPP))
    dispatch(getRevenus(person?.id, isPP))
    dispatch(getCharges(person?.id, isPP))
  }, [person, dispatch])

  if (!(actifs && passifs && revenus && charges)) return <Loader />

  return (
    <>
      <div className="section__content">
        <ButtonsRow />
        <div className="sousSection">
          <div className="cards">
            <PatrimoineBarChartCard
              title={'Actifs / Passifs'}
              actifsPassifs={true}
              actifs={actifs}
              passifs={passifs}
              revenus={revenus}
              charges={charges}
            />
            <PatrimoineBarChartCard
              title={'Revenus / Charges'}
              actifsPassifs={false}
              actifs={actifs}
              passifs={passifs}
              revenus={revenus}
              charges={charges}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PatrimoineHome
