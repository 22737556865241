import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getPassifs } from '../../../../../services/API/passifs'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import ModalUpdateTotalPassifEmprunt from './ModalUpdateTotalPassifEmprunt'
import PassifEmpruntCard from './PassifEmpruntCard'
import PassifsEmpruntsPieChart from './PassifsEmpruntsPieChart'
import PassifsEmpruntsToPrint from './Print/PassifsEmpruntsToPrint'
import LittleLoader from '../../../../layout/LittleLoader'

const PassifEmpruntHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const passifs = useSelector((state) => state.getPassifs.passifs)
  const { emprunts } = passifs || {}
  const { valeurEmprunts, empruntDivers, lemprunts } = emprunts || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: empruntDivers?.montant },
      { label: 'Affecté', value: valeurEmprunts - empruntDivers?.montant },
    ],
    [empruntDivers, valeurEmprunts]
  )
  // recharge
  useEffect(() => {
    if (!passifs) {
      dispatch(getPassifs(person?.id, isPP))
    }
  }, [passifs, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () => lemprunts.filter((passif) => new Date(passif?.dateFin) < new Date()),
    [lemprunts]
  )
  const dataToDisplayNotSold = useMemo(
    () =>
      lemprunts.filter(
        (passif) =>
          new Date(passif?.dateFin) >= new Date() || passif.dateFin === '' || !passif.dateFin
      ),
    [lemprunts]
  )
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // redirections
  const formLink = '/user/patrimoine/passifs/emprunts/ajouter'
  // recuperation passifs
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!passifs) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <PassifsEmpruntsToPrint
              elementToDisplay={emprunts}
              values={values}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurEmprunts}
              update={true}
              values={values}
            />
            {valeurEmprunts > 0 ? (
              <PatrimoineChartCard
                children={<PassifsEmpruntsPieChart emprunts={emprunts} />}
                detenteurs={true}
              />
            ) : null}
          </div>
          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <PassifEmpruntCard
              data={dataToDisplayNotSold}
              handleAddEmprunt={() => navigate(formLink)}
              title={`En cours (${dataToDisplayNotSold.length})`}
            />
            {dataToDisplaySold.length > 0 ? (
              <PassifEmpruntCard
                data={dataToDisplaySold}
                handleAddEmprunt={() => navigate(formLink)}
                title={`Historique (${dataToDisplaySold.length})`}
              />
            ) : null}
          </div>
        </div>
        {toggleUpdateTotal && (
          <ModalUpdateTotalPassifEmprunt
            setToggleUpdateTotal={setToggleUpdateTotal}
            person={person}
            passifs={passifs}
            pp={isPP}
          />
        )}
      </div>
    </>
  )
}

export default PassifEmpruntHome
