import React from 'react'
import { BiSearchAlt } from 'react-icons/bi'

const SearchButton = ({ action }) => {
  return (
    <button type='button' className="input__inputBloc__search" tabIndex={-1} onClick={(e) => action(e)}>
      <BiSearchAlt />
    </button>
  )
}

export default SearchButton
