import React from 'react'
import Card from '../../components/Components/Card'
import BlocTable from '../../components/table/BlocTable'
import { ADMIN_USERS } from '../../../constants'
import TableBodyUsers from './TableBodyUsers'

const UsersTableCard = ({ dataUsers, setDataUsers, setModalAddUser }) => {
  const titles = [
    'N°',
    'Prénom',
    'Nom',
    'Mail',
    'Date naissance',
    'Rôle',
    'Création',
    'Modification',
    'Cabinet',
    'Modifier',
  ]
  return (
    <Card title={'Liste des utilisateurs'} open={true}>
      {dataUsers?.length > 0 ? (
        <div className="informations">
          <BlocTable
            titles={titles}
            data={dataUsers}
            tableType={ADMIN_USERS}
            children={<TableBodyUsers />}
            title={'Liste des utilisateurs'}
            handleAdd={() => setModalAddUser(true)}
          />
        </div>
      ) : (
        <p>Aucun user à afficher</p>
      )}
    </Card>
  )
}

export default UsersTableCard
