import React from 'react'
import Modale from '../Modale'

const Ok = ({ text, close }) => {
  return (
    <Modale
      close={() => close()}
      title="Félicitation !"
    >
    <div className="informations">
      {text}

    </div>
    </Modale>
  )
}

export default Ok
