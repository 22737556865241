import React from 'react'
import TableToPrint from '../../../table/TableToPrint'
import TableBodyDetention from '../../Detention/Table/TableBodyDetention'
import useTotauxDetenteurs from '../../Detention/useTotauxDetenteurs'
import TableBodyDoc from '../../../Files/TableBodyDoc'

const BienInfoPrintDetenteurEtDoc = ({ detenteurs, fichiers, tableType }) => {
  return (
    <>
      <TableToPrint
        data={detenteurs}
        tableType={''}
        title={`Détenteurs (${detenteurs.length})`}
        children={
          <TableBodyDetention
            showUS={true}
            detenteurs={detenteurs}
            total={useTotauxDetenteurs(detenteurs)}
          />
        }
      />
      <TableToPrint
        data={fichiers}
        tableType={tableType}
        title={`Documents (${fichiers.length})`}
        children={<TableBodyDoc />}
      />
    </>
  )
}

export default BienInfoPrintDetenteurEtDoc
