import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  dataBirth,
  dataFamily,
  dataFiscalAddress,
  dataIdentity,
} from '../../../data/informations/infosPP'
import InformationsCard from './InformationsCard'
import InformationsTableCard from './InformationsTableCard'
import PrintComponent from '../Components/PrintComponent'
import ButtonsRow from './ButtonsRow'
import {
  DOC_PERSON_BIRTH,
  DOC_PERSON_FAMILY,
  DOC_PERSON_HOME,
  DOC_PERSON_IDENTITY,
  PP_ACTIVITY,
  PP_ADDRESS,
  PP_RELATIONS,
} from '../../../constants'
import Loader from '../../layout/Loader'
import InformationsPPPrint from './InformationsPPPrint'
import useCustomPrint from '../../layout/useCustomPrint'
import useName from '../../layout/useName'

const InformationsPP = ({ type }) => {
  // persons
  const persons = useSelector((state) => state.getPersons.persons)
  const listPPPersons = useMemo(() => persons.referentPP.concat(persons.relationPP), [persons])
  // person
  const person = useSelector((state) => state.getPerson.person)
  const name = useName()
  // destructuring person
  const { fichiers, relations, coordonneesSupplementaires, professions } = person || {}
  // Files
  const identityFiles = useMemo(
    () => fichiers.filter((el) => el.typeFichierGED === 'IDENTITE'),
    [fichiers]
  )
  const homeFiles = useMemo(
    () => fichiers.filter((el) => el.typeFichierGED === 'DOMICILE'),
    [fichiers]
  )
  const birthFiles = useMemo(
    () => fichiers.filter((el) => el.typeFichierGED === 'NAISSANCE'),
    [fichiers]
  )
  const familyFiles = useMemo(
    () =>
      fichiers.filter((el) => el.typeFichierGED === 'FAMILLE' || el.typeFichierGED === 'MARIAGE'),
    [fichiers]
  )
  // redirection
  const formCreateLink = '/user/comptes/ajouter-Personne-Physique'
  const formEditLink = '/user/informations/modifier-Personne-Physique'
  const backRedirection = -1
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  return type === 'referentPP' || (type === 'relationPP' && person.information) ? (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={<InformationsPPPrint />}
        />
        <div className="cards cards--fullWidth">
          <ButtonsRow
            handlePrint={handlePrint}
            formCreateLink={formCreateLink}
            formEditLink={formEditLink}
            backLink={backRedirection}
          />
        </div>
        <div className="sousSection">
          <div className="cards">
            <InformationsCard
              title="Informations essentielles"
              data={dataIdentity(person)}
              document={DOC_PERSON_IDENTITY}
              dataDoc={identityFiles && identityFiles}
            />
            <InformationsTableCard
              title="Relations"
              nothing="Aucune relation à afficher"
              data={relations}
              titles={['N°', 'Nom', 'Relation', 'A charge']}
              tableType={PP_RELATIONS}
              read={true}
            />
          </div>
          <div className="cards">
            <InformationsCard
              title="Adresse fiscale"
              data={dataFiscalAddress(person)}
              document={DOC_PERSON_HOME}
              dataDoc={homeFiles && homeFiles}
            />
            <InformationsTableCard
              title="Historique des adresses fiscales"
              nothing="Aucune adresse à afficher"
              data={coordonneesSupplementaires.filter((adresse) => adresse.cp !== '')}
              titles={['N°', 'Adresse', 'Complément', 'Code postal', 'Ville', 'Pays']}
              tableType={PP_ADDRESS}
            />
          </div>
          <div className="cards">
            <InformationsCard
              title="Naissance"
              data={dataBirth(person)}
              document={DOC_PERSON_BIRTH}
              dataDoc={birthFiles && birthFiles}
            />
            <InformationsCard
              title="Situation maritale"
              data={listPPPersons && dataFamily(person, listPPPersons)}
              document={DOC_PERSON_FAMILY}
              dataDoc={familyFiles && familyFiles}
            />
          </div>
          <div className="cards cards--fullWidth">
            <InformationsTableCard
              title="Activités"
              nothing="Aucune activité à afficher"
              data={professions}
              titles={['N°', 'Profession', 'CSP', 'Code CSP', 'Statut', 'Début', 'Fin']}
              tableType={PP_ACTIVITY}
              read={true}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="informations">
      <p className="informations__error">
        Vous n'avez pas les droits d'accès aux informations de {name}
      </p>
    </div>
  )
}

export default InformationsPP
