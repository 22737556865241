import React from 'react'

const BodyBienAutrePrint = ({ element, index }) => {
  return (
    <>
      {/* nom */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Nom</p>
        <p className="print__table__bloc__line--value">{!element.nom ? '-' : element.nom}</p>
      </div>
      {/* valeur */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number"></div>
        <p className="print__table__bloc__line--title">Valeur</p>
        <p className="print__table__bloc__line--value">
          {!element.valeur && element.valeur !== 0
            ? '-'
            : `${element.valeur.toLocaleString(undefined, { minimumFractionDigits: 4 })} €`}
        </p>
      </div>
    </>
  )
}

export default BodyBienAutrePrint
