import React from 'react'
import { useSelector } from 'react-redux'
import { recupLabel } from '../../../../../../services/helpers/helpers'
import { optionsTypePeriodicite } from '../../../../../../data/options/options'

const BodyFraisPrint = ({ element, index }) => {
  const bienType = useSelector((state) => state.getBienType.type)
  const type = bienType.type

  return (
    <>
      {/* annee */}
      <div className={'print__table__bloc__line'}>
        <div className="print__table__bloc__line--number">{index + 1}</div>
        <p className="print__table__bloc__line--title">Année</p>
        <p className="print__table__bloc__line--value">{!element.annee ? '-' : element.annee}</p>
      </div>
      {type !== 'produitFinancierAV' && type !== 'retraite' ? (
        <>
          {/* tauxFraisGestion */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Frais de gestion</p>
            <p className="print__table__bloc__line--value">
              {!element.tauxFraisGestion ? '-' : `${element.tauxFraisGestion} %`}
            </p>
          </div>
          {/* periodiciteFraisGestion */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Periodicité FG</p>
            <p className="print__table__bloc__line--value">
              {!element.periodiciteFraisGestion
                ? '-'
                : recupLabel(element.periodiciteFraisGestion, optionsTypePeriodicite)}
            </p>
          </div>
          {/* tauxDroitGarde */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Droit de garde</p>
            <p className="print__table__bloc__line--value">
              {!element.tauxDroitGarde ? '-' : `${element.tauxDroitGarde} %`}
            </p>
          </div>
          {/* periodiciteDroitGarde */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Periodicité DG</p>
            <p className="print__table__bloc__line--value">
              {!element.periodiciteDroitGarde
                ? '-'
                : recupLabel(element.periodiciteDroitGarde, optionsTypePeriodicite)}
            </p>
          </div>
        </>
      ) : (
        <>
          {/* tauxPerformanceFE */}
          <div className={'print__table__bloc__line'}>
            <div className="print__table__bloc__line--number"></div>
            <p className="print__table__bloc__line--title">Performance</p>
            <p className="print__table__bloc__line--value">
              {!element.tauxPerformanceFE ? '-' : `${element.tauxPerformanceFE} %`}
            </p>
          </div>
        </>
      )}
    </>
  )
}

export default BodyFraisPrint
