import { useEffect, useState } from 'react'
import { normalizeAndLowerCase } from '../../services/helpers/strings'
import usePersonData from './usePersonData'

const useName = (join) => {
  const { person, isPP } = usePersonData()
  // name
  const [name, setName] = useState('')
  useEffect(() => {
    if (person) {
      if (isPP) {
        setName(
          !join
            ? `${person?.prenom} ${person?.nom.toUpperCase()}`
            : `${normalizeAndLowerCase(person?.prenom)}_${normalizeAndLowerCase(person?.nom)}`
        )
      } else {
        setName(
          !join
            ? `${person?.denominationSociale.toUpperCase()}`
            : `${normalizeAndLowerCase(person?.denominationSociale.split(' ').join('_'))}`
        )
      }
    } else {
      return setName('Admin')
    }
  }, [person])

  return name
}

export default useName
