import React from 'react'
import { Outlet } from 'react-router-dom'
import ResponsiveNavComponent from './ResponsiveNavComponent'

const PatrimoineActif = () => {
  const links = [
    { name: 'Immobilier', link: '/user/patrimoine/actifs/immobilier', type: 'actif' },
    { name: 'Professionnel', link: '/user/patrimoine/actifs/professionnel', type: 'actif' },
    { name: 'Financier', link: '/user/patrimoine/actifs/financier', type: 'actif' },
    { name: 'Social', link: '/user/patrimoine/actifs/social', type: 'actif' },
    { name: 'Autre', link: '/user/patrimoine/actifs/autre', type: 'actif' },
  ]

  return (
    <>
      <ResponsiveNavComponent links={links} type={'actif'} />
      <Outlet />
    </>
  )
}

export default PatrimoineActif
