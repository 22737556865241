// IMPORTS // ______________________________________________________________
import { createReducer } from '@reduxjs/toolkit'
// actions imports
import { getBienError, getBienLoad, getBienSuccess } from '../../actions/Patrimoine/actionGetBien'

// REDUCER // ______________________________________________________________

// initial state
const initialStateBien = {
  isLoading: false,
  bien: null,
  error: '',
}
// reducer
export const getBienReducer = createReducer(initialStateBien, (builder) => {
  return builder
    .addCase(getBienLoad, (draft) => {
      draft.isLoading = true
      return
    })
    .addCase(getBienSuccess, (draft, action) => {
      draft.isLoading = false
      draft.bien = action.payload
      draft.error = ''
      return
    })
    .addCase(getBienError, (draft, action) => {
      draft.isLoading = false
      draft.bien = null
      draft.error = action.payload
      return
    })
})