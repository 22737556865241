// IMPORTS // ______________________________________________________________

import { createAction } from '@reduxjs/toolkit'

// ACTIONS // ______________________________________________________________

/**
 * Redux actions get catalogues
 * @reduxAction
 */

export const getCataloguesLoad = createAction('get-catalogues-load')

export const getCataloguesSuccess = createAction('get-catalogues-success', (catalogues) => {
  return {
    payload: catalogues,
  }
})

export const getCataloguesError = createAction('get-catalogues-error', (error) => {
  return {
    payload: error,
  }
})
