import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { getBienSuccess } from '../../../../../redux/actions/Patrimoine/actionGetBien'
import { getbienType } from '../../../../../redux/actions/actionGetBienType'
import { getRevenus } from '../../../../../services/API/revenus'
import { REVENUS_MOBILIERS } from '../../../../../constants'
import Loader from '../../../../layout/Loader'
import usePersonData from '../../../../layout/usePersonData'
import useCustomPrint from '../../../../layout/useCustomPrint'
import PrintComponent from '../../../Components/PrintComponent'
import ButtonsRow from '../../Patrimoine/ButtonsRow'
import PatrimoineValueCard from '../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCard from '../../Patrimoine/PatrimoineChartCard'
import RevenusCard from '../Revenus/RevenusCard'
import ModalUpdateTotalRevenuMob from './ModalUpdateTotalRevenuMob'
import RevenusMobPieChart from './RevenusMobPieChart'
import RevenusMobiliersToPrint from './Print/RevenusMobiliersToPrint'
import LittleLoader from '../../../../layout/LittleLoader'

const RevenuMobHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { person, isPP } = usePersonData()
  const incomes = useSelector((state) => state.getIncomes.incomes)
  const { revenusMobiliers } = incomes || {}
  const { revenuMobilier, valeurRevenuMobilier, revenuMobilierDivers } = revenusMobiliers || {}
  // values
  const values = useMemo(
    () => [
      { label: 'Divers', value: revenuMobilierDivers?.montant },
      { label: 'Affecté', value: valeurRevenuMobilier - revenuMobilierDivers?.montant },
    ],
    [revenuMobilierDivers, valeurRevenuMobilier]
  )
  // recharge
  useEffect(() => {
    if (!incomes) {
      dispatch(getRevenus(person?.id, isPP))
    }
  }, [incomes, person, isPP, dispatch])
  // dataToDisplay
  const dataToDisplaySold = useMemo(
    () =>
      revenuMobilier.filter((revenu) => revenu.dateFin && new Date(revenu.dateFin) < new Date()),
    [revenuMobilier]
  )
  const dataToDisplayNotSold = useMemo(
    () =>
      revenuMobilier.filter((revenu) => new Date(revenu.dateFin) >= new Date() || !revenu.dateFin),
    [revenuMobilier]
  )
  // modales
  const [toggleUpdateTotal, setToggleUpdateTotal] = useState(false)
  // redirections
  const formLink = '/user/patrimoine/revenus/mobilier/ajouter-revenu'
  // recuperation actifs
  useEffect(() => {
    dispatch(getBienSuccess(null))
    dispatch(getbienType(null))
  }, [])
  // print
  const { componentRef, handlePrint, loadingPrint, titlePrint } = useCustomPrint()

  if (!incomes) return <LittleLoader />

  return (
    <>
      {loadingPrint ? <Loader /> : null}
      <div className="section__content">
        <PrintComponent
          componentRef={componentRef}
          title={titlePrint}
          children={
            <RevenusMobiliersToPrint
              elementToDisplay={revenusMobiliers}
              values={values}
              dataNotSold={dataToDisplayNotSold}
              dataSold={dataToDisplaySold}
            />
          }
        />
        <ButtonsRow handlePrint={handlePrint} formLink={formLink} />
        <div className="sousSection">
          <div className={'cards cards--fullWidth'}>
            <PatrimoineValueCard
              setToggleUpdate={setToggleUpdateTotal}
              totalAmount={valeurRevenuMobilier}
              update={true}
              values={values}
            />
            {valeurRevenuMobilier ? (
              <PatrimoineChartCard
                children={<RevenusMobPieChart revenusMobiliers={revenusMobiliers} />}
                detenteurs={true}
              />
            ) : null}
          </div>
          <div
            className={classNames('cards', { 'cards--fullWidth': dataToDisplaySold.length === 0 })}
          >
            <RevenusCard
              data={dataToDisplayNotSold}
              handleAdd={() => navigate(formLink)}
              title={`Revenus mobiliers en cours  (${dataToDisplayNotSold.length})`}
              tableType={REVENUS_MOBILIERS}
            />
            {dataToDisplaySold.length > 0 ? (
              <RevenusCard
                data={dataToDisplaySold}
                handleAdd={() => navigate(formLink)}
                title={`Historique des revenus mobiliers (${dataToDisplaySold.length})`}
                tableType={REVENUS_MOBILIERS}
              />
            ) : null}
          </div>
        </div>
        {toggleUpdateTotal ? (
          <ModalUpdateTotalRevenuMob
            setToggleUpdateTotal={setToggleUpdateTotal}
            person={person}
            incomes={incomes}
            pp={isPP}
          />
        ) : null}
      </div>
    </>
  )
}

export default RevenuMobHome
