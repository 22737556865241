import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getFormIsActive } from '../../../../../../redux/actions/actionFormIsActive'
import { optionsTransaction } from '../../../../../../data/options/optionsFinancier'
import Input from '../../../../Components/Input'
import SelectComponent from '../../../../Components/SelectComponent'
import Modale from '../../../../Components/Modale'
import DateInputComponent from '../../../../Components/DateInputComponent'

const FormTransactionInputs = ({
  setDataChange,
  setToggleAddTransaction,
  disponibilite,
  initialTransaction,
  newTransaction,
  setNewTransaction,
  handlePreSubmit,
  first,
  information,
}) => {
  const dispatch = useDispatch()
  const { dateTransaction, montant, code } = newTransaction || {}
  const { libelle } = information || {}
  //
  const [dispo, setDispo] = useState(disponibilite)
  // dataTransactionChange
  const [dataTransactionChange, setDataTransactionChange] = useState(false)
  useEffect(() => {
    if (montant && dateTransaction) {
      setDataTransactionChange(true)
    } else {
      setDataTransactionChange(false)
    }
  }, [code, montant, dateTransaction])
  // handleChange
  const handleChange = (e, selectName) => {
    setDataChange(true)
    if (e.target !== undefined) {
      const { name, value } = e.target
      const newValue = value.replace(/,/g, '.')
      switch (name) {
        case 'montant':
          if (code === 999999) {
            setNewTransaction({ ...newTransaction, [name]: newValue })
          } else {
            setNewTransaction({
              ...newTransaction,
              [name]: parseFloat(value) > disponibilite ? disponibilite : newValue,
            })
          }

          break
        default:
          setNewTransaction({ ...newTransaction, [name]: newValue })
      }
    } else {
      const name = selectName.name
      const value = e.value
      setNewTransaction({
        ...newTransaction,
        [name]: value,
      })
    }
  }
  // handlechangeDate
  const handleChangeDate = (date, name) => {
    setDataChange(true)
    setNewTransaction({ ...newTransaction, [name]: date })
  }
  useEffect(() => {
    if (montant && code !== 999999) {
      setNewTransaction({
        ...newTransaction,
        montant: montant > disponibilite ? disponibilite : montant,
      })
    }
  }, [code])
  // handleSubmitTransaction
  const handleSubmitTransaction = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handlePreSubmit()
    setToggleAddTransaction(false)
  }
  // maj info
  useEffect(() => {
    if (code !== 999999) {
      setDispo(disponibilite - parseFloat(montant))
    } else {
      setDispo(disponibilite + parseFloat(montant))
    }
  }, [montant, code])
  // handleClose
  const handleClose = () => {
    setDataChange(false)
    setToggleAddTransaction(false)
    dispatch(getFormIsActive(null))
    setNewTransaction(initialTransaction)
  }
  return (
    <Modale close={() => handleClose()} title="Ajouter une transaction">
      <div className="modalContent">
        <div className="informations informations--borderFull">
          <p className="modalContent__blocTxt__p">
            <span className="modalContent__blocTxt__p__span">{libelle}</span>
          </p>
          <p className={'modalContent__blocTxt__p'}>
            Disponibilités :
            <span className="modalContent__blocTxt__p__span">{` ${dispo.toLocaleString(undefined, {
              minimumFractionDigits: 4,
            })} €`}</span>
          </p>
        </div>
        <div className="informations informations--border">
          {/* code */}
          <SelectComponent
            forAndId="code"
            name={'Nature :'}
            options={optionsTransaction}
            placeholder="Sélectionner"
            onChange={(e, name) => handleChange(e, name)}
            value={optionsTransaction.find((c) => c.value === code)}
            copy={true}
            required={true}
            isDisabled={first}
          />
          {/* montant */}
          <Input
            forAndId="montant"
            errorLabel={montant === '' || montant === 0}
            type="text"
            name="Montant :"
            value={montant}
            onChange={(e) => handleChange(e)}
            required={true}
            copy={true}
            inputMode="decimal"
          />
          {/* dateTransaction */}
          <DateInputComponent
            name={'dateTransaction'}
            label={'Date de la transaction :'}
            value={dateTransaction}
            handleChange={handleChangeDate}
          />
          {/* ) : null} */}
        </div>
        <div className="informations">
          <div className="informations__buttons">
            {dataTransactionChange ? (
              <button
                className="button button--red-red width--12"
                onClick={(e) => handleSubmitTransaction(e)}
              >
                Enregistrer
              </button>
            ) : (
              <div className="button button--grey width--12">Enregistrer</div>
            )}
            <button className="button button--blue-red width--12" onClick={() => handleClose()}>
              Abandonner
            </button>
          </div>
        </div>
      </div>
    </Modale>
  )
}

export default FormTransactionInputs
