import React, { useEffect } from 'react'
import { sortByType } from '../../../../services/helpers/sort'
import TableBodyBloc from '../../table/TableBodyBloc'
import BodyEtablissements from './BodyEtablissements'
import BodyEtablissementsPrint from './BodyEtablissementsPrint'

const TableBodyEtablissement = ({
  data,
  setData,
  setModalUpdate,
  setNewData,
  read,
  targetToSort,
  sort,
  setDataChange,
  listToDisplay,
  dataToDisplay,
  setDataTodisplay,
  print,
}) => {
  // suppr
  const handleDelete = (element) => {
    setDataChange(true)
    setData(data.filter((el) => el !== element))
  }
  // edit
  const handleEdit = (element) => {
    setModalUpdate(true)
    setNewData(element)
  }
  // tri croissant/decroissant
  const giveAType = (type) => {
    sortByType(sort, dataToDisplay, type, setDataTodisplay)
  }
  useEffect(() => {
    if (targetToSort) {
      switch (targetToSort) {
        case 'Siret':
          giveAType('siret')
          break
        case 'NIC':
          giveAType('nic')
          break
        case 'Adresse':
          giveAType('adresse_ligne_1')
          break
        case 'Code postal':
          giveAType('code_postal')
          break
        case 'Ville':
          giveAType('ville')
          break
        case 'Pays':
          giveAType('pays')
          break
        case 'Code pays':
          giveAType('code_pays')
          break
        case 'Code NAF':
          giveAType('code_naf')
          break
        case 'Libellé code NAF':
          giveAType('libelle_code_naf')
          break
        case 'Établissement employeur':
          giveAType('etablissement_employeur')
          break
        case 'Effectif':
          giveAType('effectif')
          break
        case 'Effectif min':
          giveAType('effectif_min')
          break
        case 'Effectif max':
          giveAType('effectif_max')
          break
        case 'Date de création':
          giveAType('date_de_creation')
          break
        case 'Cessation':
          giveAType('etablissement_cesse')
          break
        case 'Date de cessation':
          giveAType('date_cessation')
          break
        case 'Siège':
          giveAType('siege')
          break
        case 'Enseigne':
          giveAType('enseigne')
          break
        case 'Nom commercial':
          giveAType('nom_commercial')
          break
        default:
          return
      }
    }
  }, [targetToSort, sort])

  return (
    <TableBodyBloc
      listToDisplay={listToDisplay}
      children={print ? <BodyEtablissementsPrint /> : <BodyEtablissements />}
      read={read}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      print={print}
    />
  )
}

export default TableBodyEtablissement
