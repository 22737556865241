import React from 'react'
import { PASSIFS_EMPRUNTS } from '../../../../../../constants'
import TableToPrint from '../../../../table/TableToPrint'
import PatrimoineValueCard from '../../../Patrimoine/PatrimoineValueCard'
import PatrimoineChartCardPrint from '../../../Patrimoine/PatrimoineChartCardPrint'
import PassifsEmpruntsPieChart from '../PassifsEmpruntsPieChart'
import TableBodyEmprunt from '../Table/TableBodyEmprunt'

const PassifsEmpruntsToPrint = ({ elementToDisplay, values, dataNotSold, dataSold }) => {
  const { valeurEmprunts } = elementToDisplay || {}
  return (
    <>
      <PatrimoineValueCard totalAmount={valeurEmprunts} values={values} />
      <PatrimoineChartCardPrint
        children={<PassifsEmpruntsPieChart emprunts={elementToDisplay} />}
        detenteurs={true}
      />
      <TableToPrint
        data={dataNotSold}
        tableType={PASSIFS_EMPRUNTS}
        title={`En cours (${dataNotSold.length})`}
        children={<TableBodyEmprunt />}
      />
      <TableToPrint
        data={dataSold}
        tableType={PASSIFS_EMPRUNTS}
        title={`Historique (${dataSold.length})`}
        children={<TableBodyEmprunt />}
      />
    </>
  )
}

export default PassifsEmpruntsToPrint
